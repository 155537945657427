import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "subscribable-api-card__main-section" }
const _hoisted_2 = { class: "subscribable-api-card__header" }
const _hoisted_3 = { class: "subscribable-api-card__label-header" }
const _hoisted_4 = { class: "subscribable-api-card__api-label" }
const _hoisted_5 = { class: "subscribable-api-card__product" }
const _hoisted_6 = { class: "subscribable-api-card__action-header" }
const _hoisted_7 = { class: "subscribable-api-card__flag-list" }
const _hoisted_8 = { class: "subscribable-api-card__description" }
const _hoisted_9 = { class: "subscribable-api-card__footer" }
const _hoisted_10 = { class: "subscribable-api-card__on-demand-info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_IconButton = _resolveComponent("IconButton")!
  const _component_SubscriptionButton = _resolveComponent("SubscriptionButton")!
  const _component_MFlag = _resolveComponent("MFlag")!
  const _component_IconLabel = _resolveComponent("IconLabel")!
  const _component_CardListed = _resolveComponent("CardListed")!

  return (_openBlock(), _createBlock(_component_CardListed, { class: "subscribable-api-card" }, _createSlots({
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h6", _hoisted_4, _toDisplayString($options.getApiLabel($props.api)), 1),
            _createElementVNode("h6", _hoisted_5, [
              _createTextVNode(_toDisplayString($data.contents.from) + " \"", 1),
              _createVNode(_component_RouterLink, {
                to: {
                name: 'productDocumentationDetail',
                params: {
                  id: $props.api.product.id,
                },
              },
                target: "_blank"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($props.api.product.name), 1)
                ]),
                _: 1
              }, 8, ["to"]),
              _createTextVNode("\" ")
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_IconButton, {
              class: "subscribable-api-card__documentation-link",
              mozaicIconName: "BookStore24",
              href: 
              $options.convertRouteToHref({
                name: 'apiDocumentationDetail',
                params: { id: $props.api.id },
              })
            ,
              target: "_blank"
            }, null, 8, ["href"]),
            _createVNode(_component_SubscriptionButton, { api: $props.api }, null, 8, ["api"])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.flags, (flag, index) => {
            return (_openBlock(), _createBlock(_component_MFlag, {
              key: index,
              label: flag.label,
              theme: flag.theme
            }, null, 8, ["label", "theme"]))
          }), 128))
        ]),
        _createElementVNode("p", _hoisted_8, _toDisplayString($props.api.description), 1)
      ])
    ]),
    _: 2
  }, [
    ($options.subscriptionRequiresValidation)
      ? {
          name: "footer",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_IconLabel, {
                  iconName: "Security24",
                  label: $data.contents.requiresAuthorization,
                  size: "s"
                }, null, 8, ["label"])
              ])
            ])
          ]),
          key: "0"
        }
      : undefined
  ]), 1024))
}