export default {
  modalTitleApiKey: "Change the authentication mode to API Key",
  aboutToChangeToApiKey:
    "You are about to change your authentication mode to API Key",
  modalTitleOAuth: "Change the authentication mode to OAuth",
  aboutToChangeToOAuth:
    "You are about to change your authentication mode to OAuth",
  newSubscriptionsMessage:
    "All consumers will now subscribe using this authentication mode.",
  impactWarning:
    "Once a first consumer has subscribed, an authentication mode change will have an important impact on all consumers.",
  previousContractsRemovalNotificationTitle: "Be advised that:",
  previousContractsRemovalNotificationMessage: (hrefLinkToApiConsumers) =>
    `* Existing sandboxes on your API will be definitively removed. [Check the sandboxes of your API](${hrefLinkToApiConsumers})`,
  submitBtn: "I understand and confirm my change",
};
