module.exports = {
  // common
  fromProduct: "From product:",
  deprecated: "Deprecated",
  since: "Since",

  // API settings navbar
  goToApiDocumentation: "API Documentation",
  goToReporting: "Reporting",

  lastUpdate: "Last update on",

  // API documentation navbar
  goToApiSettings: "API Settings",
  contactApiOwners: "Contact API owners",
  contactTopicOwners: "Contact topic owners",
  downloadSwaggerLink: "Download Swagger file",
  downloadLinks: "Download links",
  howToSubscribe: "How to subscribe",
  noFoundApiOwners: "No owners found for this API",
  noFoundTopicOwners: "No owners found for this topic",
  downloadSpecTooltip: "Download API documentation",
};
