<script setup lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import { computed, reactive } from "vue";

import GroupRolesRadioButtons from "@/commons/components/ManageGroups/GroupRolesRadioButtons.vue";
import SearchGroupAutocompleteWrapper from "@/commons/components/form/SearchGroupAutocompleteWrapper.vue";

import { ERole } from "@/commons/store/types";

import contents from "@/dashboard/contents/access-settings";

defineProps({
  suggestions: {
    type: Array,
    required: true,
  },
  addGroupBtnLabel: {
    type: String,
    default: contents.addGroupButtonLabel,
  },
  manageRoles: {
    type: Boolean,
    default: false,
  },
  vertical: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["searchGroups", "addGroup", "onSelect"]);

const addGroupHandler = reactive({
  selectedGroupId: undefined,
  selectedRoleId: ERole.VIEWER,
  canAddGroup: computed(() => {
    return addGroupHandler.selectedGroupId != undefined;
  }),
  addGroup: () => {
    if (addGroupHandler.canAddGroup) {
      emit(
        "addGroup",
        addGroupHandler.selectedGroupId,
        addGroupHandler.selectedRoleId,
      );
      addGroupHandler.clear();
    }
  },
  clear: () => {
    addGroupHandler.selectedGroupId = undefined;
    addGroupHandler.selectedRoleId = ERole.VIEWER;
  },
});
</script>

<template>
  <div class="search-group">
    <div
      data-cy="search-group"
      :class="[
        { 'search-group__column': vertical },
        { 'search-group__row': !vertical },
      ]"
    >
      <SearchGroupAutocompleteWrapper
        v-model="addGroupHandler.selectedGroupId"
        :items="suggestions"
        :placeholder="contents.searchForGroupPlaceholder"
        dataTextExpr="name"
        dataValueExpr="id"
        :filter="false"
        @update:input="$emit('searchGroups', $event)"
        @update:model-value="$emit('onSelect', $event)"
      />
      <MButton
        class="search-group__button"
        :label="addGroupBtnLabel"
        :disabled="!addGroupHandler.canAddGroup"
        @click="addGroupHandler.addGroup"
      />
    </div>

    <GroupRolesRadioButtons
      v-if="manageRoles"
      v-model="addGroupHandler.selectedRoleId"
    />
  </div>
</template>

<style lang="scss">
.search-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.search-group__row {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.search-group__column {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 1rem;
}

.search-group__button {
  align-self: flex-end;
  width: 12rem;
}
</style>
