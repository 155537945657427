import {
  isAscii,
  ValidationArguments,
  ValidatorConstraint,
  ValidatorConstraintInterface,
} from "class-validator";

import { Dictionary } from "@/commons/domain/models/dictionary";
import { RoutingPolicyCondition } from "@/commons/domain/models/routing-policy-condition";

@ValidatorConstraint({
  name: "PolicyHeadersConditionValid",
  async: false,
})
export class PolicyHeadersConditionValid
  implements ValidatorConstraintInterface
{
  validate(conditions: Dictionary<RoutingPolicyCondition>) {
    let passValidationForm = true;
    for (const conditionId in conditions) {
      const condition = conditions[conditionId];
      if (
        condition.type === "headers" &&
        !(
          typeof condition.key === "string" &&
          typeof condition.value === "string" &&
          isAscii(condition.key) &&
          isAscii(condition.value as string)
        )
      ) {
        passValidationForm = false;
      }
    }
    return passValidationForm;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  defaultMessage(args: ValidationArguments) {
    return `You don't have enter a correct key and/or value`;
  }
}
