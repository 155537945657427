import { EOauthFlow } from "@/commons/types/oauth-flow-types";

import { EAuthType } from "@/commons/store/types";

import contentsOfAuthType from "@/commons/contents/authentication-type";

export default {
  from: "from",
  subscriptionButton: "Subscribe",
  subscribed: "Subscribed",
  pendingRequest: "A request is pending",
  pendingRequestTooltip:
    "This API is waiting for a blocking pending request. You cannot subscribe to it now.",
  requiresAuthorization: "Requires authorization",

  flagLabelVersion: (version: number) => `V${version}`,
  flagLabelAuthType: {
    [EAuthType.API_KEY]: contentsOfAuthType[EAuthType.API_KEY],
    [EAuthType.OAUTH]: `${contentsOfAuthType[EAuthType.OAUTH]} (Ping)`,
  },
  flagLabelOauthFlow: {
    [EOauthFlow.CLIENT_CREDENTIALS]: "Client Credentials Flow",
    [EOauthFlow.AUTHORIZATION_CODE]: "Authorization Code Flow",
  },

  contractCreationInfoModalTitle: "Subscribe to a new API",

  ipFilteringWarningTitle: "Subscription added",
  ipFilteringWarningMessage:
    "As this API has activated IP Filtering on its Public route, don't forget to configure your calling IP addresses if you want to use the Public route.",
  ipFilteringWarningLinkLabel: "Go to settings",
};
