module.exports = {
  welcome: (name) => `Welcome ${name}`,
  randomQuotes: [
    "It's good to see you here",
    "I love the smell of an API in the morning",
    "My eyes are #1292f4 and yours ?",
    "Your feedback are important to us, so let us know",
    "You are in the magical world of APIs.",
    "Feel free to request new features",
    "What about pandas ?",
  ],
};
