const { EApiPublicationStatus } = require("@/commons/store/types");

export default {
  availability: "Availability",
  apiSuccessfullyUpdated: "API updated",
  errorMessage: 'Please check "publication" tab',
  fromProduct: "From product:",
  tooltipWaitingConsumers:
    "While there is no consumer on the API, it won’t be deployed uselessly on the gateway. (See user documentation)",
  tooltipKongSynchronization: (nextPublishDate) =>
    `Your API Configuration is waiting to be deployed. Deployment planned for ${nextPublishDate}`,
  publicationStatus: {
    [EApiPublicationStatus.SYNCHRONIZED]: "Up to date",
    [EApiPublicationStatus.SYNCHRONIZING]: "Deploying",
    [EApiPublicationStatus.NOT_SYNCHRONIZED]: "Pending deployment",
    [EApiPublicationStatus.NOT_PUBLISHED]: "Not published",
    [EApiPublicationStatus.WAITING_CONSUMERS]: "Waiting for consumers",
    [EApiPublicationStatus.ERROR]: "Error",
  },
  unknownPublicationStatusLabel: "Unknown status",
};
