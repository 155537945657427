import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "delete-application-modal__undone-action-warning" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["href"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MNotification = _resolveComponent("MNotification")!
  const _component_MTextInput = _resolveComponent("MTextInput")!
  const _component_DvpField = _resolveComponent("DvpField")!
  const _component_LayerModalFormConfirm = _resolveComponent("LayerModalFormConfirm")!

  return (_openBlock(), _createBlock(_component_LayerModalFormConfirm, {
    class: "delete-application-modal",
    disabledConfirmBtn: !!$data.form.errors.length || $options.isSaving,
    onSubmit: $options.submit,
    onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('onClose')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("h4", null, _toDisplayString($data.contents.modalTitle($props.applicationName)), 1),
      _createElementVNode("p", _hoisted_1, _toDisplayString($data.contents.warning), 1),
      _createVNode(_component_MNotification, {
        type: "warning",
        title: $data.contents.credentialsWarningTitle
      }, {
        default: _withCtx(() => [
          _createElementVNode("p", null, _toDisplayString($data.contents.credentialsWarningMessage), 1),
          ($options.applicationWithEnabledSecretSynchronization)
            ? (_openBlock(), _createElementBlock("p", _hoisted_2, [
                _createTextVNode(_toDisplayString($options.getSecretSynchronizationWarningMessage()) + " ", 1),
                _createElementVNode("a", {
                  href: $props.secretSynchronization.secretUrl,
                  target: "_blank"
                }, _toDisplayString($props.secretSynchronization.secretDisplayPath), 9, _hoisted_3)
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["title"]),
      _createElementVNode("p", {
        innerHTML: $data.contents.prompt($props.applicationName)
      }, null, 8, _hoisted_4),
      _createVNode(_component_DvpField, {
        label: $data.contents.deleteApplicationLabel
      }, {
        default: _withCtx(() => [
          _createVNode(_component_MTextInput, {
            modelValue: $data.form.text,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.form.text) = $event)),
            placeholder: $props.applicationName,
            isInvalid: $options.isInvalidApplicationName
          }, null, 8, ["modelValue", "placeholder", "isInvalid"])
        ]),
        _: 1
      }, 8, ["label"])
    ]),
    _: 1
  }, 8, ["disabledConfirmBtn", "onSubmit"]))
}