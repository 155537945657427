import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-47d126e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "request-action-bar" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "request-action-bar__actions" }
const _hoisted_4 = {
  key: 0,
  class: "request-action-bar__request-actions"
}
const _hoisted_5 = { class: "request-action-bar__slack-action" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MButton = _resolveComponent("MButton")!
  const _component_RequestManagerButtonViewSlackMessages = _resolveComponent("RequestManagerButtonViewSlackMessages")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h5", {
      class: "request-action-bar__label",
      innerHTML: $options.computeLabel()
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      ($options.canCancel || $options.canDenyOrAccept)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            ($options.canDenyOrAccept)
              ? (_openBlock(), _createBlock(_component_MButton, {
                  key: 0,
                  "data-cy": "deny-request-btn",
                  theme: "bordered-danger",
                  label: _ctx.contents.buttons.denyRequest,
                  onClick: $options.denyRequest
                }, null, 8, ["label", "onClick"]))
              : _createCommentVNode("", true),
            ($options.canCancel)
              ? (_openBlock(), _createBlock(_component_MButton, {
                  key: 1,
                  "data-cy": "cancel-request-btn",
                  theme: "bordered-neutral",
                  label: _ctx.contents.buttons.cancelRequest,
                  onClick: $options.cancelRequest
                }, null, 8, ["label", "onClick"]))
              : _createCommentVNode("", true),
            ($options.canDenyOrAccept)
              ? (_openBlock(), _createBlock(_component_MButton, {
                  key: 2,
                  "data-cy": "accept-request-btn",
                  label: _ctx.contents.buttons.acceptRequest,
                  onClick: $options.acceptRequest
                }, null, 8, ["label", "onClick"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        ($options.slackUrl)
          ? (_openBlock(), _createBlock(_component_RequestManagerButtonViewSlackMessages, {
              key: 0,
              slackUrl: $options.slackUrl
            }, null, 8, ["slackUrl"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}