<template>
  <chip :class="['request-status', 'status__' + request.status]">
    {{ computeStatus(request) }}
  </chip>
</template>

<script lang="ts">
import Chip from "@/commons/components/Chip.vue";

import { requestStatus } from "@/request-manager/contents/request-manager-utils";

export default {
  name: "RequestStatus",
  components: { Chip },
  props: {
    request: {
      type: Object,
      required: true,
    },
  },
  methods: {
    computeStatus(request) {
      return requestStatus(request);
    },
  },
};
</script>

<style lang="scss">
.request-status {
  --chip-height: var(--size-300);
  --chip-text: var(--size-300);
  /* stylelint-disable selector-class-pattern */
  &.status__DENIED {
    --chip-bg-color: var(--color-danger);
  }

  &.status__APPROVAL_REQUIRED {
    --chip-bg-color: var(--color-link);
  }

  &.status__APPROVED {
    --chip-bg-color: var(--color-success);
  }

  &.status__CANCELLED {
    --chip-bg-color: var(--color-text);
  }
  /* stylelint-enable selector-class-pattern */
}
</style>
