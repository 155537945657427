import { State } from "@/commons/store/modules/user/types";

export function setHasPerformedInitialAuthentication(
  state: State,
  value: boolean,
) {
  state.hasPerformedInitialAuthentication = value;
}

export default {
  setHasPerformedInitialAuthentication,
};
