import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { "data-cy": "header-key" }
const _hoisted_2 = { "data-cy": "header-value" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MTextInput = _resolveComponent("MTextInput")!
  const _component_DvpField = _resolveComponent("DvpField")!
  const _component_ToggleButton = _resolveComponent("ToggleButton")!
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_CancelAndSubmitButtons = _resolveComponent("CancelAndSubmitButtons")!
  const _component_ButtonEdit = _resolveComponent("ButtonEdit")!
  const _component_ButtonDelete = _resolveComponent("ButtonDelete")!
  const _component_ActionButtonsWrapper = _resolveComponent("ActionButtonsWrapper")!

  return (_openBlock(), _createElementBlock("tr", {
    class: _normalizeClass(['update-or-remove-custom-proxy-header', { disabled: $props.isDisabled }])
  }, [
    _createElementVNode("td", null, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_DvpField, {
          errorMessage: $props.form.firstError('name')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_MTextInput, {
              modelValue: $props.form.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($props.form.name) = $event)),
              placeholder: $data.contents.namePlaceholder,
              disabled: !$data.isEditing,
              isInvalid: $options.isInvalidName
            }, null, 8, ["modelValue", "placeholder", "disabled", "isInvalid"])
          ]),
          _: 1
        }, 8, ["errorMessage"])
      ])
    ]),
    _createElementVNode("td", null, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_DvpField, {
          errorMessage: $props.form.firstError('value')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_MTextInput, {
              modelValue: $props.form.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($props.form.value) = $event)),
              placeholder: $data.contents.valuePlaceholder,
              disabled: !$data.isEditing,
              isInvalid: $options.isInvalidValue
            }, null, 8, ["modelValue", "placeholder", "disabled", "isInvalid"])
          ]),
          _: 1
        }, 8, ["errorMessage"])
      ])
    ]),
    ($props.allowHeaderOverride)
      ? (_openBlock(), _createElementBlock("td", _hoisted_3, [
          _createVNode(_component_FormInput, {
            error: $props.form.firstError('isOverridingClientHeader')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ToggleButton, {
                modelValue: $props.form.isOverridingClientHeader,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($props.form.isOverridingClientHeader) = $event)),
                class: "update-or-remove-custom-proxy-header__override-toggle",
                disabled: !$data.isEditing
              }, null, 8, ["modelValue", "disabled"])
            ]),
            _: 1
          }, 8, ["error"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("td", null, [
      ($data.isEditing)
        ? (_openBlock(), _createBlock(_component_CancelAndSubmitButtons, {
            key: 0,
            class: "update-or-remove-custom-proxy-header__cancel-and-submit-buttons",
            onCancel: $options.cancel,
            onSubmit: $options.update
          }, null, 8, ["onCancel", "onSubmit"]))
        : (_openBlock(), _createBlock(_component_ActionButtonsWrapper, {
            key: 1,
            class: "update-or-remove-custom-proxy-header__edit-and-delete-buttons"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ButtonEdit, { onClick: $options.edit }, null, 8, ["onClick"]),
              _createVNode(_component_ButtonDelete, { onClick: $options.remove }, null, 8, ["onClick"])
            ]),
            _: 1
          }))
    ])
  ], 2))
}