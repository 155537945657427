<template>
  <div class="api-publication-status">
    <IconTooltip
      v-if="tooltipMessage != undefined"
      :text="tooltipMessage"
      position="left"
    />
    <MBadge
      :data-publication-status="publicationStatus"
      class="api-publication-status__badge"
      :type="badgeType"
    >
      {{ statusLabel }}
    </MBadge>
  </div>
</template>

<script lang="ts">
import MBadge from "@mozaic-ds/vue-3/src/components/badge/MBadge.vue";
import { PropType } from "vue";

import IconTooltip from "@/commons/components/IconTooltip.vue";

import { toLocaleDatetime } from "@/commons/utils/date-utils";

import { Api } from "@/commons/domain/models/api";

import { EApiPublicationStatus } from "@/commons/store/types";

import contents from "@/manager/contents/settings";

export default {
  components: { IconTooltip, MBadge },
  props: {
    api: {
      type: Object as PropType<Api>,
      required: true,
    },
  },
  data() {
    return {
      contents,
    };
  },
  computed: {
    publicationStatus(): EApiPublicationStatus | undefined {
      return this.api.publicationStatus;
    },
    apiNextSynchroDate(): string {
      return toLocaleDatetime(this.api.nextPublish);
    },
    badgeType(): string {
      switch (this.publicationStatus) {
        case EApiPublicationStatus.ERROR:
          return "danger";
        case EApiPublicationStatus.SYNCHRONIZED:
          return "success";
        default:
          return "warning";
      }
    },
    statusLabel(): string {
      return (
        contents.publicationStatus[this.publicationStatus] ||
        contents.unknownPublicationStatusLabel
      );
    },
    tooltipMessage(): string | undefined {
      switch (this.publicationStatus) {
        case EApiPublicationStatus.ERROR:
          return contents.errorMessage;
        case EApiPublicationStatus.NOT_SYNCHRONIZED:
          return contents.tooltipKongSynchronization(this.apiNextSynchroDate);
        case EApiPublicationStatus.WAITING_CONSUMERS:
          return contents.tooltipWaitingConsumers;
        default:
          return undefined;
      }
    },
  },
};
</script>

<style lang="scss">
.api-publication-status {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}
</style>
