import { getApplicationRepository } from "@/commons/repositories/libs/get-application-repository";

import { IPRange } from "@/commons/domain/models/ip-range";
import { ValidationDTO } from "@/commons/dtos/validation-dto";

export function addNewIPRangeToApplication(
  ipRange: IPRange,
  applicationId: string,
): Promise<void> {
  return getApplicationRepository().addIPRange(applicationId, ipRange);
}

export function updateIPRangeOfApplication(
  ipRange: IPRange,
  applicationId: string,
): Promise<void> {
  return getApplicationRepository().updateIPRange(applicationId, ipRange);
}

export function deleteIPRangeOfApplication(
  ipRangeId: string,
  applicationId: string,
): Promise<void> {
  return getApplicationRepository().deleteIPRange(applicationId, ipRangeId);
}

export function validateIPRange(
  ipRange: IPRange,
  applicationId: string,
): Promise<ValidationDTO> {
  return getApplicationRepository().validateIPRange(ipRange, applicationId);
}
