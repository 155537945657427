<template>
  <div class="footer-menus">
    <div class="footer-menus__content">
      <FlatMenu
        v-for="(menu, index) in navigationMenus"
        v-bind="menu"
        :key="`footer-menus-menu-${index}`"
        :isDisplayImage="false"
        :isDisplayDescription="false"
      />
    </div>
  </div>
</template>

<script lang="ts">
import FlatMenu from "@/commons/components/Menu/FlatMenu.vue";

import { getSupportMenuConf } from "@/commons/libs/get-support-menu-conf";
import { getUseCasesMenuConf } from "@/commons/libs/get-use-cases-menu-conf";
import { isMobileSize } from "@/commons/libs/is-mobile-size";

import { DropdownMenuItem } from "@/commons/store/types";

export default {
  name: "FooterMenus",
  components: {
    FlatMenu,
  },
  data() {
    return { isMobileSize: isMobileSize(window.innerWidth) };
  },
  computed: {
    supportUrl() {
      return this.$store.getters["config/supportUrl"];
    },
    navigationMenus(): DropdownMenuItem[] {
      return [getUseCasesMenuConf(), getSupportMenuConf(this.supportUrl)];
    },
  },
  mounted() {
    window.addEventListener("resize", this.resize);
  },
  unmounted() {
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    resize() {
      this.isMobileSize = isMobileSize(window.innerWidth);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/commons/assets/styles/vars";

.footer-menus {
  position: relative;
  width: 100%;
  margin-top: auto;
  background-color: var(--color-leroy-merlin);

  &::before {
    position: absolute;
    top: -4vh;
    left: 0;
    width: 100%;
    height: 18vh;
    content: "";
    background-color: var(--color-leroy-merlin);
    transform: skewY(2deg);
  }
}

.footer-menus__content {
  position: relative;

  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-around;

  width: 72rem;

  margin: 5em auto 3em;

  .flat-menu__menu {
    padding: 0 0 2rem;
  }

  .flat-menu__label {
    margin: 0 0 1rem;
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: map-get($breakpoints, tablet-landscape)) {
  .footer-menus__content {
    width: 100%;
    margin: 2rem auto;
  }
}

@media only screen and (max-width: map-get($breakpoints, mobile)) {
  .footer-menus__content {
    position: relative;

    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: flex-start;

    margin: 3rem auto;

    text-align: center;
  }
}
</style>
