<template>
  <div class="api-publication-info-for-navbar">
    <ApiPublicationStatus
      class="api-publication-info-for-navbar__publication-status"
      :api="api"
    />

    <div class="api-publication-info-for-navbar__publication-status-date">
      {{ contents.lastUpdate }} {{ toLocaleDate(api.updatedAt) }}
    </div>
  </div>
</template>

<script lang="ts">
import { PropType } from "vue";

import ApiPublicationStatus from "@/manager/views/ApiDetail/ApiPublicationStatus.vue";

import { toLocaleDate } from "@/commons/utils/date-utils";

import { Api } from "@/commons/domain/models/api";

import { EApiPublicationStatus } from "@/commons/store/types";

import contents from "@/commons/contents/secondary-navbar-api";

export default {
  components: {
    ApiPublicationStatus,
  },
  props: {
    api: {
      type: Object as PropType<Api>,
      required: true,
    },
  },
  data() {
    return {
      contents,
    };
  },
  created() {
    this.EApiPublicationStatus = EApiPublicationStatus;
  },
  methods: {
    toLocaleDate,
  },
};
</script>

<style lang="scss">
.api-publication-info-for-navbar__publication-status-date {
  @include set-text-xs;
  margin-top: 0.375rem;
  color: var(--color-m-text-minor);
}
</style>
