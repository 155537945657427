import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "send-email-modal__form-footer global-layer-modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MSelect = _resolveComponent("MSelect")!
  const _component_MField = _resolveComponent("MField")!
  const _component_MTextInput = _resolveComponent("MTextInput")!
  const _component_MTextArea = _resolveComponent("MTextArea")!
  const _component_Stack = _resolveComponent("Stack")!
  const _component_MButton = _resolveComponent("MButton")!

  return (_openBlock(), _createElementBlock("form", {
    class: "send-email-modal",
    autocomplete: "false | random-string",
    onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => ($options.onSubmit && $options.onSubmit(...args)), ["prevent"]))
  }, [
    _createVNode(_component_Stack, { class: "send-email-modal__form-fields" }, {
      default: _withCtx(() => [
        ($data.form.needApiSelection)
          ? (_openBlock(), _createBlock(_component_MField, {
              key: 0,
              id: "api-field",
              class: "send-email-modal__api-field",
              label: $data.contents.apiSelectionLabel,
              requirementText: "required",
              isInvalid: $options.isInvalidSelectedApi,
              errorMessage: $data.form.firstError('api')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_MSelect, {
                  id: "api-selection",
                  modelValue: $data.form.api,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.form.api) = $event)),
                  placeholder: $data.contents.apiSelectionPlaceholder,
                  options: $options.apiOptions
                }, null, 8, ["modelValue", "placeholder", "options"])
              ]),
              _: 1
            }, 8, ["label", "isInvalid", "errorMessage"]))
          : _createCommentVNode("", true),
        _createVNode(_component_MField, {
          id: "subject",
          class: "send-email-modal__subject-field",
          label: $data.contents.subjectLabel,
          requirementText: "required",
          isInvalid: $options.isInvalidSubject,
          errorMessage: $data.form.firstError('subject')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_MTextInput, {
              modelValue: $data.form.subject,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.form.subject) = $event)),
              placeholder: $data.contents.subjectPlaceholder,
              isInvalid: $options.isInvalidSubject
            }, null, 8, ["modelValue", "placeholder", "isInvalid"])
          ]),
          _: 1
        }, 8, ["label", "isInvalid", "errorMessage"]),
        _createVNode(_component_MField, {
          id: "message",
          class: "send-email-modal__message-field",
          label: $data.contents.messageLabel,
          requirementText: "required",
          isInvalid: $options.isInvalidMessage,
          errorMessage: $data.form.firstError('body')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_MTextArea, {
              modelValue: $data.form.body,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.form.body) = $event)),
              placeholder: $data.contents.messagePlaceholder,
              isInvalid: $options.isInvalidMessage
            }, null, 8, ["modelValue", "placeholder", "isInvalid"])
          ]),
          _: 1
        }, 8, ["label", "isInvalid", "errorMessage"])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_MButton, {
        class: "send-email-modal__submit-button",
        type: "submit",
        disabled: !!$data.form.errors.length,
        label: $data.contents.submit
      }, null, 8, ["disabled", "label"])
    ])
  ], 32))
}