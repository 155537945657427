import { CorsHeadersListForm } from "./cors-headers-list-form";
import { CorsOriginsListForm } from "./cors-origins-list-form";
import { ArrayNotEmpty, IsIn, ValidateNested } from "class-validator";

import { Form } from "@/commons/domain/forms/form";
import { FormData } from "@/commons/domain/forms/form-data";
import { FormFactory } from "@/commons/domain/forms/form-factory";
import { Cors } from "@/commons/domain/models/cors";

export class CorsForm extends FormData {
  isEnabled: boolean;

  @ValidateNested()
  allowOrigins: Form<CorsOriginsListForm>;

  @ArrayNotEmpty({ message: "Allowed methods is empty" })
  @IsIn(["GET", "POST", "DELETE", "TRACE", "PUT", "HEAD", "PATCH", "OPTIONS"], {
    each: true,
  })
  allowMethods: string[];

  @ValidateNested()
  allowHeaders: Form<CorsHeadersListForm>;

  allowCredentials: boolean;

  constructor(cors: Cors) {
    super();
    this.allowOrigins = CorsOriginsListForm.create();
    this.allowHeaders = CorsHeadersListForm.create();

    this.$update(cors);
  }

  async $clear() {
    this.allowOrigins.clear();
    this.isEnabled = false;
    this.allowMethods = [];
    this.allowHeaders.clear;
    this.allowCredentials = false;
  }

  $update(partial: Partial<Cors> = {}) {
    for (const attrName in partial) {
      if (this[attrName] instanceof Form) {
        this[attrName].init({ values: partial[attrName] });
      } else {
        this[attrName] = partial[attrName];
      }
    }
  }

  $data() {
    return {
      ...super.$data(),
      allowOrigins: this.allowOrigins.data(),
      allowHeaders: this.allowHeaders.data(),
    };
  }

  static create(
    cors: Cors = {
      isEnabled: false,
      allowOrigins: [],
      allowMethods: [],
      allowHeaders: [],
      allowCredentials: false,
    },
  ) {
    return FormFactory.createForm(new CorsForm(cors));
  }
}
