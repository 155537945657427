import { EAuthType } from "@/commons/store/types";

import contentsOfAuthType from "@/commons/contents/authentication-type";

export default {
  authModeTitle: "API Authentication mode",
  pendingRequestBadge: "Pending authentication mode change request",
  viewRequest: "View request",

  apiKeyCardTitle: contentsOfAuthType[EAuthType.API_KEY],
  apiKeyCardDescription:
    "Classic use by passing in your header the value of your API key with " +
    "the key 'x-gateway-apikey'.",
  changeToOauthIntro:
    "To change the authentication mode, please make a request, and the " +
    "devportal team will contact you.",
  changeToOauthBtn: "Change to OAuth authentication",

  oAuthCardTitle: contentsOfAuthType[EAuthType.OAUTH],
  oAuthCardDescription:
    "OAuth authentication is more secure; you will have to pass the access token that you received from an authorization call.",
  changeToApiKeyIntro:
    "To change to API Key Authentication, please click below. Be careful, once confirmed, the change will be done instantly.",
  changeToApiKeyBtn: "Change to API Key Authentication",

  flowSettingsTitle: "Ping Flow settings",
  atLeastOneFlowSelected:
    "You are required to have at least one flow selected. For your information, Ping is the provider.",

  clientCredentialsTitle: "Client Credentials Flow",
  clientCredentialsDescription: "Machine-to-Machine.",

  authorizationCodeFlowTitle: "Authorization Code Flow",
  authorizationCodeFlowDescription: "User-to-Machine (Collaborator).",

  flowUpdateSuccessNotification: "Flow settings updated",

  scopesSettingsTitle: "Manage Scopes",
  addScopeButtonLabel: "Add a scope",

  scopeIdTableHeader: "Scope ID",
  scopeDescriptionTableHeader: "Description",
  scopeConsumersTableHeader: "Used by",
};
