<template>
  <Stack
    :class="[
      'custom-proxy-headers',
      { 'custom-proxy-headers--allow-header-override': allowHeaderOverride },
    ]"
  >
    <MNotification v-if="validationError" type="danger">
      {{ validationError }}
    </MNotification>
    <p>
      <span>
        {{ transformationMessage }}
      </span>
      <br />
      <RouterLink :to="documentationRoute">
        {{ documentationMessage }}
      </RouterLink>
    </p>

    <table class="table-light">
      <thead>
        <tr>
          <th>{{ contents.headerName }}</th>
          <th>
            {{ contents.headerValue }}
          </th>
          <th v-if="allowHeaderOverride">
            {{ contents.headerOverride }}
            <Tooltip :tooltipContent="overrideTooltipContent">
              <MIcon name="NotificationQuestion24" />
            </Tooltip>
          </th>
          <th>{{ contents.headerActions }}</th>
        </tr>
      </thead>
      <tbody>
        <UpdateOrRemoveCustomProxyHeader
          v-for="customProxyHeader in form.values"
          :key="customProxyHeader.id"
          :isDisabled="isSaving"
          :formParent="form"
          :form="customProxyHeader"
          :allowHeaderOverride="allowHeaderOverride"
          @update="update"
          @remove="remove"
        />
        <CreateCustomProxyHeader
          :isDisabled="isSaving"
          :hasError="hasCreationError"
          :formParent="form"
          :form="form.newValue"
          :allowHeaderOverride="allowHeaderOverride"
          @create="create"
          @close="removeCreationErrorIfExists"
        />
      </tbody>
    </table>
  </Stack>
</template>

<script lang="ts">
import MIcon from "@mozaic-ds/vue-3/src/components/icon/MIcon.vue";
import MNotification from "@mozaic-ds/vue-3/src/components/notification/MNotification.vue";

import Stack from "@/commons/components/Stack.vue";
import Tooltip from "@/commons/components/Tooltip/Tooltip.vue";

import CreateCustomProxyHeader from "./CreateCustomProxyHeader.vue";
import UpdateOrRemoveCustomProxyHeader from "./UpdateOrRemoveCustomProxyHeader.vue";

import { ECustomProxyHeaderType } from "@/commons/domain/models/custom-proxy-header";
import { CustomProxyHeadersListForm } from "@/manager/domain/forms/custom-proxy-headers-list-form";

import contents from "@/manager/contents/custom-proxy-headers";

export default {
  name: "CustomProxyHeaders",
  components: {
    Stack,
    UpdateOrRemoveCustomProxyHeader,
    CreateCustomProxyHeader,
    Tooltip,
    MNotification,
    MIcon,
  },
  props: {
    apiId: {
      type: String,
      required: true,
    },
    customProxyHeaders: {
      type: Array,
      default: () => [],
    },
    // eslint-disable-next-line vue/require-default-prop
    type: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      contents,
      form: CustomProxyHeadersListForm.create(),
    };
  },
  computed: {
    currentApi() {
      return this.$store.getters["currentApi"];
    },
    isSaving() {
      return this.$store.getters["isSaving"];
    },
    documentationRoute() {
      const documentationAnchor =
        this.type === ECustomProxyHeaderType.REQUEST
          ? "#request-headers"
          : "#response-headers";

      return { name: "docsMain", hash: documentationAnchor };
    },
    hasCreationError() {
      return this.$store.getters["actionFailing"] === "createCustomProxyHeader";
    },
    validationError() {
      return this.form.firstError("values") || this.form.firstError("newValue");
    },
    allowHeaderOverride() {
      return this.$store.getters["apiAllowHeaderOverride"];
    },
    transformationMessage() {
      return this.type === ECustomProxyHeaderType.REQUEST
        ? contents.messageTransformationRequest
        : contents.messageTransformationResponse;
    },
    documentationMessage() {
      return this.type === ECustomProxyHeaderType.REQUEST
        ? contents.requestHeaderDocumentationMessage
        : contents.responseHeaderDocumentationMessage;
    },
    overrideTooltipContent() {
      return this.type === ECustomProxyHeaderType.REQUEST
        ? contents.overrideTooltipRequest
        : contents.overrideTooltipResponse;
    },
  },
  watch: {
    customProxyHeaders: {
      async handler(customProxyHeaders) {
        await this.form.init({
          values: customProxyHeaders,
          newValue: { type: this.type },
        });
      },
      deep: true,
    },
  },
  async mounted() {
    await this.form.init({
      values: this.customProxyHeaders,
      newValue: { type: this.type },
    });
  },
  methods: {
    async update(customProxyHeader) {
      await this.$store.dispatch("updateCustomProxyHeader", {
        apiId: this.apiId,
        customProxyHeader,
      });
    },
    async create({ name, value, isOverridingClientHeader }) {
      await this.$store.dispatch("createCustomProxyHeader", {
        apiId: this.apiId,
        name,
        value,
        isOverridingClientHeader,
        type: this.type,
      });
    },
    async remove(customProxyHeaderId) {
      await this.$store.dispatch("removeCustomProxyHeader", {
        apiId: this.apiId,
        customProxyHeaderId,
      });
    },
    removeCreationErrorIfExists() {
      if (this.hasCreationError) {
        this.$store.commit("patchState", { actionFailing: "" });
      }
    },
  },
};
</script>

<style lang="scss">
.custom-proxy-headers.custom-proxy-headers--allow-header-override {
  td:nth-child(1),
  td:nth-child(2),
  th:nth-child(1),
  th:nth-child(2) {
    width: 40%;
  }
  td:nth-child(3),
  td:nth-child(4),
  th:nth-child(3),
  th:nth-child(4) {
    width: 10%;
  }
}
.custom-proxy-headers {
  p {
    white-space: pre-line;

    a {
      padding-left: 2px;
    }
  }
}
</style>
