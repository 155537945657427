<template>
  <IconButton
    class="button-edit"
    data-cy="edit-button"
    mozaicIconName="PublishEdit24"
    :color="color"
    :disabled="disabled"
    @click="$emit('click', $event)"
  />
</template>

<script lang="ts">
import IconButton from "@/commons/components/IconButton.vue";

export default {
  components: {
    IconButton,
  },
  props: {
    color: {
      type: String,
      default: "var(--color-text-primary)",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["click"],
};
</script>
