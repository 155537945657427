module.exports = {
  loadingMessage: "Loading product",

  // side menu
  backToProductsList: "Back to Products list",
  description: "Description",
  noDescription: "No description",
  providedApis: "Provided APIs",
  usedApis: "Used APIs",
  aboutPrefix: "About",

  // product apis
  noApiProvided: (productName) =>
    `${productName} does not provide any API yet.`,
  noApiUsed: (productName) => `${productName} does not use any API yet.`,
};
