import adminRoutes from "./admin/routes";
import { routerUpdatePageMeta } from "./commons/libs/router-update-page-meta";
import store from "./commons/store";
import pollingManager from "./commons/store/helpers/polling-manager";
import dashboardRoutes from "./dashboard/routes";
import documentationRoutes from "./documentation/routes";
import managerRoutes from "./manager/routes";
import requestManagerRoutes from "./request-manager/routes";
import showcaseRoutes from "./showcase/routes";
import userRoutes from "@/user/routes";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import TOS from "@/commons/views/TOS.vue";
import TOSRead from "@/commons/views/TOSRead.vue";
import SwaggerUiAcfCodeHandler from "@/documentation/components/swagger-ui-integrator/SwaggerUiAcfCodeHandler.vue";
import UserView from "@/user/views/User.vue";

import EmptyPage from "./commons/views/EmptyPage.vue";
import PageForbidden from "./commons/views/PageForbidden.vue";
import PageNotFound from "./commons/views/PageNotFound.vue";
import Login from "./login/views/Login.vue";
import Showcase from "./showcase/views/Showcase.vue";

import { getCurrentNavbarsHeightInPixels } from "@/commons/utils/navbars-utils";

// User not logged trying to access in a secured page
function getUserIsLoggedRedirection({ to, from }) {
  let redirection;

  if (to.meta.secured && !store.getters["userIsLogged"]) {
    if (from.query.redirect === to.path) {
      redirection = ["home", {}];
    } else {
      redirection = ["login", { redirect: to.fullPath }];
    }
  }

  if (store.getters["userIsLogged"]) {
    const afterLoginRedirect = localStorage.getItem("afterLoginRedirect");
    if (afterLoginRedirect) {
      localStorage.removeItem("afterLoginRedirect");
      router.push({ path: afterLoginRedirect });
    }

    if (to.meta.admin && !store.getters["userIsAdmin"]) {
      redirection = ["pageForbidden", {}];
    }
  }

  return redirection;
}

// User is logged but TOS is not accepted
function getUserHasAcceptedTOSRedirection({ to, getters }) {
  let redirection;

  if (getters["userIsLogged"] && !getters["userHasAcceptedTOS"]) {
    redirection = ["TOS", { redirect: to.fullPath }];
  }

  return redirection;
}

const routes = [
  {
    path: "/",
    children: showcaseRoutes,
    component: Showcase,
  },
  {
    path: "/docs",
    children: documentationRoutes,
    component: () =>
      import(
        /* webpackChunkName: "documentation" */ "./documentation/views/Documentation.vue"
      ),
  },
  {
    path: "/dashboard",
    meta: {
      menu: "My Apps",
    },
    children: dashboardRoutes,
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "./dashboard/views/Dashboard.vue"
      ),
  },
  {
    path: "/manager",
    meta: {
      menu: "Manager",
    },
    children: managerRoutes,
    component: () =>
      import(/* webpackChunkName: "manager" */ "./manager/views/Manager.vue"),
    beforeEnter(to, from, next) {
      // Api manager page are only available to internal users
      if (store.getters["userIsInternal"]) {
        next();
      } else {
        next({ name: "pageForbidden" });
      }
    },
  },
  {
    path: "/admin",
    meta: {
      menu: "Admin",
    },
    children: adminRoutes,
    component: () =>
      import(/* webpackChunkName: "admin" */ "./admin/views/Admin.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: "Please login",
    },
  },
  {
    path: "/TOS",
    name: "TOS",
    component: EmptyPage,
    meta: {
      title: "Request Manager",
      secured: true,
      openModal: {
        title: "You must accept terms of use to continue",
        component: TOS,
        isClosable: false,
      },
    },
  },
  {
    path: "/docs/terms-of-use",
    name: "TOSRead",
    component: TOSRead,
  },
  {
    path: "/request-manager",
    meta: {
      menu: "Request Manager",
    },
    children: requestManagerRoutes,
    component: () =>
      import(
        /* webpackChunkName: "request-manager" */ "./request-manager/views/RequestManagerMain.vue"
      ),
  },
  {
    path: "/user",
    component: UserView,
    children: userRoutes,
  },
  {
    path: "/not-found",
    name: "pageNotFound",
    component: PageNotFound,
    meta: {
      title: "Not found",
    },
  },
  {
    path: "/forbidden",
    name: "pageForbidden",
    component: PageForbidden,
    meta: {
      title: "Page forbidden",
    },
  },
  {
    path: "/swagger-ui-acf-code-handler",
    name: "swaggerUiAcfCodeHandler",
    component: SwaggerUiAcfCodeHandler,
  },
  { path: "/:pathMatch(.*)*", redirect: "/not-found" },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes as RouteRecordRaw[],
  scrollBehavior(to, from, savedPosition) {
    // Warning about scrollBehavior: it only works on body or html scrollbar, not on an embedded element with scrollbar
    // (because it uses window.scrollTo)
    if (to.hash) {
      return {
        el: to.hash,
        top: getCurrentNavbarsHeightInPixels(),
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, left: 0 };
    }
  },
});

router.beforeEach(async (to, from, next) => {
  let errorCaughtDuringBeforeEach;

  store.commit("updatePreviousPage", from);

  pollingManager.stopCurrentPollings();

  // Initial config and user loading. Code only running during the application initial loading
  // Note: A sign in with PING will force refresh the application when PING redirects to DVP
  // The application will fully reload, it will call the following code again and the user info will be fetched
  if (store.state["user"].hasPerformedInitialAuthentication === false) {
    try {
      await store.dispatch("fetchUser");
    } catch (error) {
      // If we don't handle the error, the routing will get interrupted and the page won't be rendered
      // We keep the error for throwing it after the next()
      errorCaughtDuringBeforeEach = error;
    }
    store.commit("setHasPerformedInitialAuthentication", true);
  }

  const redirection =
    getUserIsLoggedRedirection({ to, from }) ||
    getUserHasAcceptedTOSRedirection({ to, getters: store.getters });

  if (redirection && to.name !== "TOS" && to.name !== "login") {
    const [name, query] = redirection;
    next({ name, query });
  } else {
    routerUpdatePageMeta(to);
    next();
  }

  // We do the throw after the next() call so the routing doesn't get interrupted and we still display the notification
  if (errorCaughtDuringBeforeEach != undefined) {
    throw errorCaughtDuringBeforeEach;
  }
});

export default router;
