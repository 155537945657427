import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SensitiveModificationsList = _resolveComponent("SensitiveModificationsList")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, null, {
    default: _withCtx(() => [
      _createElementVNode("h4", null, _toDisplayString($data.contents.sensitiveModifications), 1),
      _createElementVNode("div", null, [
        _createVNode(_component_SensitiveModificationsList, {
          title: $data.contents.deleteAccountTitle,
          description: $data.contents.actionUndone,
          btnTitle: $data.contents.delete,
          btnAction: $options.deleteAccount
        }, null, 8, ["title", "description", "btnTitle", "btnAction"])
      ])
    ]),
    _: 1
  }))
}