import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "product-detail-side-menu" }
const _hoisted_2 = { class: "product-detail-side-menu-inner" }
const _hoisted_3 = { class: "product-detail-side-menu-link__label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavigateBeforeButton = _resolveComponent("NavigateBeforeButton")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_MAccordion = _resolveComponent("MAccordion")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NavigateBeforeButton, {
      title: $data.contents.backToProductsList,
      previousPageRoute: $data.previousPageRoute
    }, null, 8, ["title", "previousPageRoute"]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createBlock(_component_MAccordion, {
        id: $options.menuItem.id,
        key: $options.menuItem.id,
        label: $options.menuItem.label,
        title: $options.menuItem.label,
        open: true
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.menuItem.subItems, (childItem, index) => {
            return (_openBlock(), _createBlock(_component_RouterLink, {
              key: index,
              class: "product-detail-side-menu-link",
              to: { hash: `#${childItem.anchorId}` },
              title: childItem.label
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_3, _toDisplayString(childItem.label), 1)
              ]),
              _: 2
            }, 1032, ["to", "title"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["id", "label", "title"]))
    ])
  ]))
}