import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "quota-consumers" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "quota-consumers__actions"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 1,
  class: "quota-consumers__no-consumer-result"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MTextInput = _resolveComponent("MTextInput")!
  const _component_MDataTableHeader = _resolveComponent("MDataTableHeader")!
  const _component_MToggle = _resolveComponent("MToggle")!
  const _component_ButtonEdit = _resolveComponent("ButtonEdit")!
  const _component_ButtonDelete = _resolveComponent("ButtonDelete")!
  const _component_MDataTable = _resolveComponent("MDataTable")!
  const _component_MPagination = _resolveComponent("MPagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MTextInput, {
      modelValue: $data.searchApplication,
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => (($data.searchApplication) = $event)),
        _cache[1] || (_cache[1] = ($event: any) => ($options.searchContracts({ applicationName: $event, page: 1 })))
      ],
      class: "quota-consumers__search-application",
      icon: "DisplaySearch48",
      placeholder: $data.contents.searchForAnApp
    }, null, 8, ["modelValue", "placeholder"]),
    ($options.apiContractsList.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_MDataTable, {
            source: $options.apiContractsList,
            sorting: { mode: 'none' }
          }, {
            [`item.rateLimits`]: _withCtx(({ item }) => [
              _createElementVNode("div", null, _toDisplayString($options.getItemRateLimitLabel(item)), 1)
            ]),
            [`item.actions`]: _withCtx(({ item }) => [
              (item.rateLimits && item.rateLimits.global)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_MToggle, {
                      id: item.rateLimits.global.id,
                      modelValue: item.rateLimits.global.isEnabled,
                      "onUpdate:modelValue": ($event: any) => ($options.toggleRateLimit($event, item))
                    }, null, 8, ["id", "modelValue", "onUpdate:modelValue"]),
                    (item.rateLimits && item.rateLimits.global)
                      ? (_openBlock(), _createBlock(_component_ButtonEdit, {
                          key: 0,
                          onClick: ($event: any) => ($options.editContractRateLimit(item))
                        }, null, 8, ["onClick"]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_ButtonDelete, {
                      onClick: ($event: any) => ($options.deleteContractRateLimit(item))
                    }, null, 8, ["onClick"])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_ButtonEdit, {
                      onClick: ($event: any) => ($options.createContractRateLimit(item))
                    }, null, 8, ["onClick"])
                  ]))
            ]),
            default: _withCtx(() => [
              _createVNode(_component_MDataTableHeader, {
                caption: $data.contents.tableHeaderApplicationName,
                dataFieldExpr: "application.name",
                allowSorting: false
              }, null, 8, ["caption"]),
              _createVNode(_component_MDataTableHeader, {
                caption: $data.contents.tableHeaderRateLimit,
                dataFieldExpr: "rateLimits",
                allowSorting: false
              }, null, 8, ["caption"]),
              _createVNode(_component_MDataTableHeader, {
                caption: $data.contents.tableHeaderAction,
                dataFieldExpr: "actions",
                allowSorting: false
              }, null, 8, ["caption"])
            ]),
            _: 2
          }, 1032, ["source"]),
          _createVNode(_component_MPagination, {
            class: "quota-consumers__pagination",
            length: $options.contractsListTotalPages,
            value: $options.currentContractsPage,
            onOnUpdatePage: _cache[2] || (_cache[2] = ($event: any) => ($options.searchContracts({ page: $event })))
          }, null, 8, ["length", "value"])
        ]))
      : (_openBlock(), _createElementBlock("h5", _hoisted_5, _toDisplayString($data.contents.noConsumerFound), 1))
  ]))
}