module.exports = {
  modalTitle: "You are about to deprecate a zone",
  cancel: "Cancel",
  confirm: "Confirm",
  selectZone: "Select a zone",
  selectDate: "Select date",
  zoneIdLabel: "Choose which zone to deprecate",
  expirationDateLabel: "Expiration date",
  messageLabel: "Message to your consumers",
  required: "required",
  helpTooltipZoneId: "Choose the published zone to deprecate",
  helpTooltipDate: "Indicate a deadline date when you will delete the zone",
  helpTooltipMessage:
    "Add a message that will be sent to the consumers by email, to explain to them what is the good practice now",
};
