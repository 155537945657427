<template>
  <CardContainer class="authentication-mode-settings">
    <div class="authentication-mode-settings__card-header">
      <h4 class="authentication-mode-settings__card-title">
        {{ contents.authModeTitle }}
      </h4>

      <div
        v-if="pendingAuthTypeChangeRequest != undefined"
        class="authentication-mode-settings__pending-request"
      >
        <MBadge
          class="authentication-mode-settings__pending-request-badge"
          type="warning"
          >{{ contents.pendingRequestBadge }}</MBadge
        >
        <RouterLink
          class="authentication-mode-settings__pending-request-link"
          :to="getAuthTypeChangeRequestRoute(pendingAuthTypeChangeRequest)"
          ><span
            class="authentication-mode-settings__pending-request-link-label"
            >{{ contents.viewRequest }}</span
          >
          <MIcon
            name="DisplayExternalLink16"
            class="authentication-mode-settings__pending-request-link-icon"
          />
        </RouterLink>
      </div>
    </div>

    <AuthenticationModeOptionCard
      data-cy="authentication-mode-option-card"
      :title="optionCardTitle"
      :description="optionCardDescription"
      :documentationAnchor="authTypeDocAnchor"
    />

    <div v-if="pendingAuthTypeChangeRequest == undefined">
      <p class="authentication-mode-settings__change-section-description">
        {{ authChangeIntro }}
      </p>
      <MButton
        data-cy="change-authentication-mode-button"
        :label="authChangeButtonLabel"
        @click="onClickChangeAuthMode()"
      />
    </div>
  </CardContainer>
</template>

<script lang="ts">
import MBadge from "@mozaic-ds/vue-3/src/components/badge/MBadge.vue";
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import MIcon from "@mozaic-ds/vue-3/src/components/icon/MIcon.vue";
import { PropType } from "vue";
import { RouteLocationRaw } from "vue-router";

import CardContainer from "@/commons/components/CardContainer.vue";
import AuthenticationModeOptionCard from "@/manager/views/ApiDetailAuthentication/AuthenticationModeOptionCard.vue";
import ChangeAuthTypeModal from "@/manager/views/ApiDetailAuthentication/modal/ChangeAuthTypeModal.vue";
import ConsumersOnAPIErrorModal from "@/manager/views/ApiDetailAuthentication/modal/ConsumersOnAPIErrorModal.vue";

import { RequestSummary } from "@/commons/domain/models/request-summary";
import {
  apiHasAnyActiveSubscription,
  apiHasAnyActiveSubscriptionIncludingSandboxes,
  requestAuthTypeChange,
} from "@/commons/services/request/request-service";

import { EAuthType, ERequestFilteringVisibility } from "@/commons/store/types";

import contents from "@/manager/contents/authentication";
import contentsOfChangeAuthTypeModal from "@/manager/contents/change-auth-type-modal";
import contentsOfConsumersOnApiErrorModal from "@/manager/contents/consumers-on-api-error-modal";

export default {
  components: {
    CardContainer,
    AuthenticationModeOptionCard,
    MButton,
    MBadge,
    MIcon,
  },
  props: {
    apiId: {
      type: String,
      required: true,
    },
    requestedAuthType: {
      type: String as PropType<EAuthType>,
      required: true,
    },
    optionCardTitle: {
      type: String,
      required: true,
    },
    optionCardDescription: {
      type: String,
      required: true,
    },
    authTypeDocAnchor: {
      type: String,
      required: true,
    },
    authChangeIntro: {
      type: String,
      required: true,
    },
    authChangeButtonLabel: {
      type: String,
      required: true,
    },
    pendingAuthTypeChangeRequest: {
      type: Object as PropType<RequestSummary>,
      default: undefined,
    },
  },
  data() {
    return {
      contents,
    };
  },
  methods: {
    getAuthTypeChangeRequestRoute(
      authTypeChangeRequest: RequestSummary,
    ): RouteLocationRaw {
      return {
        name: "requestManager",
        query: {
          visibility: ERequestFilteringVisibility.SUBMITTED,
          requestId: authTypeChangeRequest.id,
        },
      };
    },
    async onClickChangeAuthMode() {
      if (await apiHasAnyActiveSubscription(this.apiId)) {
        this.openConsumersOnApiErrorModal();
      } else if (
        await apiHasAnyActiveSubscriptionIncludingSandboxes(this.apiId)
      ) {
        this.openChangeAuthTypeModal(true);
      } else {
        this.openChangeAuthTypeModal();
      }
    },
    async reloadCurrentApi() {
      this.$store.dispatch("reloadCurrentApi");
    },
    async changeAuthType() {
      await requestAuthTypeChange({
        apiId: this.apiId,
        authType: this.requestedAuthType,
      });
      await this.reloadCurrentApi();
    },
    openChangeAuthTypeModal(displayDeleteSandboxesWarning = false) {
      this.$store.commit("openModal", {
        title:
          this.requestedAuthType === EAuthType.OAUTH
            ? contentsOfChangeAuthTypeModal.modalTitleOAuth
            : contentsOfChangeAuthTypeModal.modalTitleApiKey,
        component: ChangeAuthTypeModal,
        props: {
          apiId: this.apiId,
          requestedAuthType: this.requestedAuthType,
          displayDeleteSandboxesWarning,
        },
        listeners: {
          onSubmit: () => {
            this.changeAuthType();
          },
        },
      });
    },
    openConsumersOnApiErrorModal() {
      this.$store.commit("openModal", {
        title: contentsOfConsumersOnApiErrorModal.modalTitle,
        component: ConsumersOnAPIErrorModal,
        props: {
          requestedAuthType: this.requestedAuthType,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.authentication-mode-settings__card-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.authentication-mode-settings__pending-request {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.authentication-mode-settings__pending-request-link {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.authentication-mode-settings__card-title {
  margin-top: 0;
}

.authentication-mode-settings__change-section-description {
  @include set-text-s;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}
</style>
