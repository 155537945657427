<template>
  <LayerModalForm class="delete-modal" @submit="onSubmit">
    <template #fields>
      <div class="delete-modal__content">
        <h4 class="text-700 weight-bold">
          {{ contents.headerConfirmRemove }}
        </h4>
        <p class="text-400">
          {{ contents.textRemove }}
        </p>
      </div>
      <DvpField
        class="delete-modal__confirm-message"
        :label="contents.confirmMessageLabel"
        required
      >
        <MTextInput
          v-model="confirmMessage"
          :placeholder="contents.confirmMessagePlaceholder"
          :isInvalid="isError()"
        />
      </DvpField>
    </template>
    <template #formFooter>
      <MButton
        class="delete-modal__cancel-button"
        type="button"
        :label="contents.cancelButtonLabel"
        theme="bordered-neutral"
        @click="$emit('onClose')"
      />
      <MButton
        class="delete-modal__confirm-button"
        theme="solid-danger"
        type="submit"
        :disabled="isError()"
        :label="contents.confirmButtonLabel"
      />
    </template>
  </LayerModalForm>
</template>

<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import MTextInput from "@mozaic-ds/vue-3/src/components/textinput/MTextInput.vue";

import DvpField from "@/commons/components/form/DvpField.vue";
import LayerModalForm from "@/commons/components/form/LayerModalForm.vue";

import contents from "@/manager/contents/delete-blacklist-route-modal";

export default {
  name: "DeleteModal",
  components: {
    LayerModalForm,
    MTextInput,
    MButton,
    DvpField,
  },
  props: {
    blackListPolicy: {
      type: Object,
      required: true,
    },
  },
  emits: ["onClose"],
  data() {
    return {
      contents,
      confirmMessage: "",
    };
  },
  methods: {
    isError() {
      return this.confirmMessage !== "DELETE";
    },
    async onSubmit() {
      if (!this.isError()) {
        await this.$store.dispatch(
          "deleteBlackListPolicy",
          this.blackListPolicy,
        );
      }
    },
  },
};
</script>

<style lang="scss">
.delete-modal {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__form-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &__cancel-button {
    margin-right: 1rem;
  }
}
</style>
