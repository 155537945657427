import { AclMapper } from "./acl-mapper";

import { GroupWithUsersAcls } from "@/commons/domain/models/group-with-users-acls";
import {
  PagedResource,
  PagedResourceList,
} from "@/commons/domain/models/paged-resource";
import { GroupDto } from "@/commons/dtos/group-dto";
import { PagedResourceDto } from "@/commons/dtos/paged-resource-dto";

export class GroupWithUsersAclsMapper {
  static toListDomain(groupsDtosPaged: PagedResourceDto<GroupDto>) {
    const groupsWithUsers = [];

    for (const groupDto of groupsDtosPaged.data) {
      const groupWithUsers = GroupWithUsersAclsMapper.toGroupDomain(groupDto);
      groupsWithUsers.push(groupWithUsers);
    }

    return groupsWithUsers;
  }

  static toDomain(groupsDtosPaged: PagedResourceDto<GroupDto>) {
    const groupsWithUsers = {};

    for (const groupDto of groupsDtosPaged.data) {
      const groupWithUsers = GroupWithUsersAclsMapper.toGroupDomain(groupDto);
      groupsWithUsers[groupWithUsers.id] = groupWithUsers;
    }

    return groupsWithUsers;
  }

  static toPagedListDomain(
    groupsDtosPaged: PagedResourceDto<GroupDto>,
  ): PagedResourceList<GroupWithUsersAcls> {
    return {
      count: groupsDtosPaged.count,
      currentPage: groupsDtosPaged.currentPage,
      data: GroupWithUsersAclsMapper.toListDomain(groupsDtosPaged),
      totalCount: groupsDtosPaged.totalCount,
      totalPages: groupsDtosPaged.totalPages,
    };
  }

  static toPagedDomain(
    groupsDtosPaged: PagedResourceDto<GroupDto>,
  ): PagedResource<GroupWithUsersAcls> {
    return {
      count: groupsDtosPaged.count,
      currentPage: groupsDtosPaged.currentPage,
      data: GroupWithUsersAclsMapper.toDomain(groupsDtosPaged),
      totalCount: groupsDtosPaged.totalCount,
      totalPages: groupsDtosPaged.totalPages,
    };
  }

  static toGroupDomain({
    id,
    isRemoved,
    updatedAt,
    name,
    description,
    users,
    currentUserActions,
    provider,
    linkMCC,
  }: GroupDto): GroupWithUsersAcls {
    return {
      id,
      isRemoved,
      updatedAt,
      name,
      description,
      users: AclMapper.toUsersDomain(users),
      currentUserActions,
      provider,
      linkMCC,
    };
  }
}
