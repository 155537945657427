import { FormData } from "@/commons/domain/forms/form-data";
import { FormFactory } from "@/commons/domain/forms/form-factory";

import { EApiStatusFilter, EContractStatusFilter } from "@/commons/store/types";

export interface FiltersMyAppsFormData {
  usedApisIds?: string[];
  platformsIds?: string[];
  subscriptionStatuses?: EContractStatusFilter[];
  apisStatuses?: EApiStatusFilter[];
}

export class FiltersMyAppsForm extends FormData {
  usedApisIds: string[] = [];
  productsIds: string[] = [];
  subscriptionStatuses: EContractStatusFilter[] = [];
  apisStatuses: EApiStatusFilter[] = [];

  $clear() {
    this.usedApisIds = [];
    this.productsIds = [];
    this.subscriptionStatuses = [];
    this.apisStatuses = [];
  }

  constructor(
    usedApisIds: string[] = [],
    productsIds: string[] = [],
    subscriptionStatuses: EContractStatusFilter[] = [],
    apisStatuses: EApiStatusFilter[] = [],
  ) {
    super();
    this.usedApisIds = usedApisIds;
    this.productsIds = productsIds;
    this.subscriptionStatuses = subscriptionStatuses;
    this.apisStatuses = apisStatuses;
  }

  static create() {
    return FormFactory.createForm(new FiltersMyAppsForm());
  }
}
