<template>
  <svg
    class="icon-restriction-partners"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="icon-restriction-partners"
    viewBox="0 0 80 65"
    height="56"
  >
    <path
      data-name="Tracé 3074"
      d="M63.1 31.3a2 2 0 0 0-2.8.5l-4.4 6.5L46 27a2 2 0 0 0-1.4-.7h-.2a2 2 0 0 0-.6.2 2 2 0 0 0-.4.2 1.5 1.5 0 0 0-.8.9c-1.6 3.7-6 10.5-7.5 10.8-.6-.1-.8-.3-.8-.4a1 1 0 0 1 0-.5l3.4-12.3a2.7 2.7 0 0 1 1.7-1.9l9.8-3.3a2.1 2.1 0 0 0 1.3-2.7 2 2 0 0 0-2.6-1.3l-7.5 2.6a2 2 0 0 0-.6-.2l-7.4-.4a2 2 0 0 0-.2 4.2h2.3a7 7 0 0 0-.7 1.8l-3.3 12a5.2 5.2 0 0 0 .1 3.9 5 5 0 0 0 3.5 2.6 3.3 3.3 0 0 0 .8 0c3.8 0 8-6.6 10-10.5L56 44.5l1 1a.6.6 0 0 1 0 .5 1.3 1.3 0 0 1-.3.8 1.2 1.2 0 0 1-.8.3.6.6 0 0 1-.4-.1l-.1-.1-6-6.5a2 2 0 0 0-3 0 2.1 2.1 0 0 0 0 2.9l5.2 5.5a1.8 1.8 0 0 0 0 .2l1 1h.2l.8.9.2.3a1.1 1.1 0 0 1 0 1.4c-.4.4-1 .5-1.2.2L51 51.3l-6-6.5a2 2 0 0 0-3 0 2.1 2.1 0 0 0 0 3l5.2 5.5.9 1h.1a1 1 0 0 1-.2 1.3c-.4.4-1 .5-1.2.3l-6.2-6.6a2 2 0 0 0-2.8 0 2.1 2.1 0 0 0 0 2.9l4.6 5v.1a.6.6 0 0 1 .2.5 1.2 1.2 0 0 1-.3.8 1.2 1.2 0 0 1-.8.3.6.6 0 0 1-.4-.1H41v-.2L24.6 42a17.6 17.6 0 0 1-4.7-9.5 2 2 0 0 0-2.3-1.8A2 2 0 0 0 16 33a21.5 21.5 0 0 0 3.2 8.4 5.2 5.2 0 0 0-3.2 4.9 5.2 5.2 0 0 0 5 5.2 4.9 4.9 0 0 0 1.2-.2 5.3 5.3 0 0 0-.1 1.2 5.2 5.2 0 0 0 5 5.2 4.9 4.9 0 0 0 1.2-.1 5.3 5.3 0 0 0-.1 1.2 5.1 5.1 0 0 0 5 5.2 5 5 0 0 0 4.5-2.8l.4.4h.1v.2a4.6 4.6 0 0 0 3.4 1.3h.2a5.2 5.2 0 0 0 3.4-1.6 5.5 5.5 0 0 0 1-1.5 4.8 4.8 0 0 0 1 .2 5.2 5.2 0 0 0 3.8-1.6 5.5 5.5 0 0 0 1-1.5 4.7 4.7 0 0 0 1 0 5.1 5.1 0 0 0 3.7-1.5 5.3 5.3 0 0 0 1.3-4.8 5.5 5.5 0 0 0 3.1-4.6 4.9 4.9 0 0 0-1.3-3.6l-1-1.2 4.8-7.2a2.1 2.1 0 0 0-.5-3Zm-42 16a1 1 0 1 1 1-1 1 1 0 0 1-1 1Zm6 6.3a1 1 0 1 1 1-1 1 1 0 0 1-1 1Zm6.1 6.2a1 1 0 1 1 1-1 1 1 0 0 1-1 1Z"
      fill="#999"
    />
  </svg>
</template>
