<template>
  <MButton
    class="contract-remove-button"
    theme="solid-danger"
    size="s"
    :label="contents.removeContract"
    @click="openRemovalConfirmModal"
  />
</template>

<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";

import MessageConfirmModal from "@/commons/components/Modal/MessageConfirmModal.vue";

import { getContractRepository } from "@/commons/repositories/libs/get-contract-repository";

import contents from "@/commons/contents/remove-contract-button";

export default {
  components: {
    MButton,
  },
  props: {
    contract: {
      type: Object,
      required: true,
    },
  },
  emits: ["contractDeleted"],
  data() {
    return {
      contents,
    };
  },
  methods: {
    openRemovalConfirmModal() {
      this.$store.commit("openModal", {
        title: contents.contractRemovalConfirmModalTitle,
        component: MessageConfirmModal,
        props: {
          title: contents.contractRemovalConfirmModalMessage,
        },
        listeners: {
          onSubmit: async () => {
            await this.deleteContract();
            this.$store.commit("closeModal");
            this.$store.commit("postSuccessNotification", {
              title: contents.subscriptionSuccessfullyRemoved,
            });
            this.$emit("contractDeleted");
          },
        },
      });
    },
    async deleteContract() {
      await getContractRepository().deleteContract(this.contract.id);
    },
  },
};
</script>
