import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["colspan"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MTextInput = _resolveComponent("MTextInput")!
  const _component_DvpField = _resolveComponent("DvpField")!
  const _component_ToggleButton = _resolveComponent("ToggleButton")!
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_CancelAndSubmitButtons = _resolveComponent("CancelAndSubmitButtons")!
  const _component_AddPolicyButton = _resolveComponent("AddPolicyButton")!

  return (_openBlock(), _createElementBlock("tr", {
    class: _normalizeClass(['create-custom-proxy-header', { disabled: $props.isDisabled }])
  }, [
    ($options.showCreationForm)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("td", null, [
            _createVNode(_component_DvpField, {
              errorMessage: $props.form.firstError('name')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_MTextInput, {
                  modelValue: $props.form.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($props.form.name) = $event)),
                  "data-cy": "create-custom-proxy-header-name-input",
                  placeholder: $data.contents.namePlaceholder,
                  disabled: $props.isDisabled,
                  isInvalid: $options.isInvalidName
                }, null, 8, ["modelValue", "placeholder", "disabled", "isInvalid"])
              ]),
              _: 1
            }, 8, ["errorMessage"])
          ]),
          _createElementVNode("td", null, [
            _createVNode(_component_DvpField, {
              errorMessage: $props.form.firstError('value')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_MTextInput, {
                  modelValue: $props.form.value,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($props.form.value) = $event)),
                  placeholder: $data.contents.valuePlaceholder,
                  disabled: $props.isDisabled,
                  isInvalid: $options.isInvalidValue
                }, null, 8, ["modelValue", "placeholder", "disabled", "isInvalid"])
              ]),
              _: 1
            }, 8, ["errorMessage"])
          ]),
          ($props.allowHeaderOverride)
            ? (_openBlock(), _createElementBlock("td", _hoisted_1, [
                _createVNode(_component_FormInput, {
                  error: $props.form.firstError('isOverridingClientHeader')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ToggleButton, {
                      modelValue: $props.form.isOverridingClientHeader,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($props.form.isOverridingClientHeader) = $event)),
                      class: "create-custom-proxy-header__override-toggle",
                      disabled: $props.isDisabled
                    }, null, 8, ["modelValue", "disabled"])
                  ]),
                  _: 1
                }, 8, ["error"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("td", null, [
            _createVNode(_component_CancelAndSubmitButtons, {
              class: "create-custom-proxy-header__cancel-and-submit-buttons",
              onCancel: $options.cancel,
              onSubmit: $options.create
            }, null, 8, ["onCancel", "onSubmit"])
          ])
        ], 64))
      : (_openBlock(), _createElementBlock("td", {
          key: 1,
          colspan: $props.allowHeaderOverride ? 4 : 3
        }, [
          _createVNode(_component_AddPolicyButton, {
            label: $data.contents.buttonAdd,
            onAdd: $options.edit
          }, null, 8, ["label", "onAdd"])
        ], 8, _hoisted_2))
  ], 2))
}