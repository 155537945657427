<template>
  <div class="expansion-panel radius">
    <div class="expansion-panel__header" @click="openPanel">
      <div class="header-title">
        <IconChevronDown v-if="!isOpen" />
        <IconChevronUp v-else />
        <h5>{{ title }}</h5>
      </div>
      <ToggleButton
        v-if="title !== 'Required'"
        :disabled="false"
        :modelValue="toggleValue"
        @update:modelValue="sendToggleValue"
        @click.prevent
      />
    </div>
    <div v-if="isOpen" class="gap-400">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import ToggleButton from "@/commons/components/ToggleButton.vue";

import IconChevronDown from "./IconChervronDown.vue";
import IconChevronUp from "./IconChevronUp.vue";

export default {
  name: "ExpansionPanel",
  components: {
    IconChevronDown,
    IconChevronUp,
    ToggleButton,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    displayToggleButton: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["toggleValue"],
  data() {
    return {
      isOpen: false,
      toggleValue: false,
    };
  },
  methods: {
    openPanel() {
      this.isOpen = !this.isOpen;
    },
    sendToggleValue(event) {
      this.toggleValue = event;
      this.$emit("toggleValue", { value: event, title: this.title });
    },
  },
};
</script>

<style lang="scss">
.expansion-panel {
  padding: 0.5rem 1rem;
  margin-bottom: var(--size-400);
  overflow: scroll;
  background-color: var(--color-white);
  border: var(--border-default);
}

.expansion-panel::-webkit-scrollbar {
  display: none;
}

.expansion-panel h5 {
  margin: 0.5rem 0 0.5rem 0.5rem;
  line-height: 1;
}
.expansion-panel__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.expansion-panel .header-title {
  display: flex;
  align-items: center;
}
.expansion-panel svg {
  overflow: visible;
}
</style>
