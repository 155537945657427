export default {
  accessSettingsTitle: "Access settings",
  searchForGroupPlaceholder: "Search for a group",
  addGroupButtonLabel: "Add group",

  defineRightsSubTitle: "Define their rights",
  tooltip: {
    MANAGER: "Manager has full rights and access to application settings.",
    VIEWER: "Viewer can read reports and see contracts.",
  },

  managerGroupHeader: "Manager group",

  cancelButtonLabel: "Cancel",
  applyChangeButtonLabel: "Apply change",
};
