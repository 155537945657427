import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "authentication-oauth-settings" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AuthenticationModeSettings = _resolveComponent("AuthenticationModeSettings")!
  const _component_OauthFlowSettings = _resolveComponent("OauthFlowSettings")!
  const _component_ScopesSettings = _resolveComponent("ScopesSettings")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AuthenticationModeSettings, {
      apiId: $options.currentApi.id,
      requestedAuthType: $data.EAuthType.API_KEY,
      optionCardTitle: $data.contents.oAuthCardTitle,
      optionCardDescription: $data.contents.oAuthCardDescription,
      authTypeDocAnchor: "#oauth2-authentication-mode",
      authChangeIntro: $data.contents.changeToApiKeyIntro,
      authChangeButtonLabel: $data.contents.changeToApiKeyBtn
    }, null, 8, ["apiId", "requestedAuthType", "optionCardTitle", "optionCardDescription", "authChangeIntro", "authChangeButtonLabel"]),
    _createVNode(_component_OauthFlowSettings),
    ($options.oauthScopesFeatureIsEnabled)
      ? (_openBlock(), _createBlock(_component_ScopesSettings, {
          key: 0,
          id: "scopes",
          apiId: $options.currentApi.id,
          hasScopes: $options.currentApi.oauthScopes?.length > 0
        }, null, 8, ["apiId", "hasScopes"]))
      : _createCommentVNode("", true)
  ]))
}