export default {
  lmfr: {
    signInButtonLabel: "Sign in & start your app !",
    navigateToDashboardButtonLabel: "Go to your dashboard",
    resumeHeaderTitle:
      "Leroy Merlin loves to <strong>interact with partners</strong> to make <strong>customers' lives easier.</strong>",
  },
  lmbr: {
    signInButtonLabel: "Sign in & start your app !",
    navigateToDashboardButtonLabel: "Go to your dashboard",
    resumeHeaderTitle:
      "Leroy Merlin loves to <strong>interact with partners</strong> to make <strong>customers' lives easier.</strong>",
  },
  adeo: {
    signInButtonLabel: "Sign in & start your app !",
    navigateToDashboardButtonLabel: "Go to your dashboard",
    resumeHeaderTitle:
      "Adeo loves to <strong>interact with partners</strong> to make <strong>customers' lives easier.</strong>",
  },
};
