<template>
  <Stack class="cors">
    <MNotification v-if="validationErrors.length" type="danger">
      <ul>
        <li v-for="(error, index) of validationErrors" :key="index">
          {{ error }}
        </li>
      </ul>
    </MNotification>
    <p>
      {{ contents.description }}
    </p>
    <table class="table-light">
      <thead>
        <tr>
          <th>{{ contents.enable }}</th>
          <th>{{ contents.allowedOrigins }}</th>
          <th>{{ contents.allowedMethods }}</th>
          <th>{{ contents.allowedHeaders }}</th>
          <th>{{ contents.allowedCredentials }}</th>
          <th>{{ contents.actions }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <template v-if="showCorsForm">
            <td>
              <FormInput :error="form.firstError('isEnabled')">
                <ToggleButton v-model="form.isEnabled" :disabled="isDisabled" />
              </FormInput>
            </td>
            <td>
              <InputTextsList
                :isDisabled="isDisabled"
                :placeholder="contents.origin"
                :form="form.allowOrigins"
              />
            </td>
            <td>
              <FormInput :error="form.firstError('allowMethods')">
                <MDropdown
                  v-model="form.allowMethods"
                  :items="httpMethods"
                  multiple
                  :disabled="isDisabled"
                />
              </FormInput>
            </td>
            <td>
              <InputTextsList
                :isDisabled="isDisabled"
                :placeholder="contents.header"
                :form="form.allowHeaders"
              />
            </td>
            <td>
              <FormInput :error="form.firstError('allowCredentials')">
                <ToggleButton
                  v-model="form.allowCredentials"
                  :disabled="isDisabled"
                />
              </FormInput>
            </td>
            <td class="text-centered">
              <!-- update and cancel buttons -->
              <template v-if="isEditing">
                <CancelAndSubmitButtons @cancel="cancel" @submit="update" />
              </template>

              <template v-else>
                <ButtonEdit @click="edit" />
              </template>
            </td>
          </template>
          <template v-else>
            <td colspan="6">
              <AddPolicyButton :label="contents.addCors" @add="edit" />
            </td>
          </template>
        </tr>
      </tbody>
    </table>
  </Stack>
</template>

<script lang="ts">
import MDropdown from "@mozaic-ds/vue-3/src/components/dropdown/MDropdown.vue";
import MNotification from "@mozaic-ds/vue-3/src/components/notification/MNotification.vue";

import ButtonEdit from "@/commons/components/Buttons/ButtonEdit.vue";
import CancelAndSubmitButtons from "@/commons/components/Buttons/CancelAndSubmitButtons.vue";
import FormInput from "@/commons/components/FormInput.vue";
import InputTextsList from "@/commons/components/InputTextsList/InputTextsList.vue";
import Stack from "@/commons/components/Stack.vue";
import ToggleButton from "@/commons/components/ToggleButton.vue";
import AddPolicyButton from "@/manager/views/ApiDetailPolicies/AddPolicyButton.vue";

import { ORDERED_HTTP_METHODS } from "@/commons/utils/http-method";

import { CorsForm } from "@/manager/domain/forms/cors-form";

import contents from "@/manager/contents/cors";

export default {
  name: "Cors",
  components: {
    ButtonEdit,
    FormInput,
    ToggleButton,
    InputTextsList,
    Stack,
    MNotification,
    AddPolicyButton,
    CancelAndSubmitButtons,
    MDropdown,
  },
  props: {
    apiId: {
      type: String,
      required: true,
    },
    // eslint-disable-next-line vue/require-default-prop
    cors: {
      type: Object,
    },
  },
  data() {
    return {
      contents,
      form: CorsForm.create(),
      persistedCors: undefined,
      isEditing: false,
    };
  },
  computed: {
    isSaving() {
      return this.$store.getters["isSaving"];
    },
    isDisabled() {
      return this.isSaving || !this.isEditing;
    },
    showCorsForm() {
      return !!this.cors || this.isEditing;
    },
    validationErrors() {
      return [
        ...this.form.allowOrigins.getErrors("values"),
        ...this.form.allowHeaders.getErrors("values"),
      ];
    },
    currentApi() {
      return this.$store.getters["currentApi"];
    },
    httpMethods() {
      const methods = ORDERED_HTTP_METHODS.map((method) => ({
        label: method,
        value: method,
      }));
      return methods.filter(
        (m) =>
          this.currentApi.allowedActions[
            "cors.method." + m.label.toLowerCase()
          ],
      );
    },
  },

  async mounted() {
    this.isEditing = false;
    let filteredCors = this.cors;
    if (this.cors) {
      filteredCors.allowMethods = this.cors.allowMethods.filter(
        (m) =>
          this.currentApi.allowedActions["routing.method." + m.toLowerCase()],
      );
    }
    await this.form.init(filteredCors);
  },
  methods: {
    async update() {
      if (!this.isSaving) {
        const allowOriginsNewValue = this.form.allowOrigins.newValue;
        const allowHeadersNewValue = this.form.allowHeaders.newValue;

        if (!allowOriginsNewValue.errors.length) {
          const { value } = allowOriginsNewValue.data();

          if (value !== "") {
            this.form.allowOrigins.$addValue(value);
            allowOriginsNewValue.clear();
          }
        }

        if (!allowHeadersNewValue.errors.length) {
          const { value } = allowHeadersNewValue.data();

          if (value !== "") {
            this.form.allowHeaders.$addValue(value);
            allowHeadersNewValue.clear();
          }
        }

        await this.form.allowOrigins.validate();
        await this.form.allowHeaders.validate();
        await this.form.validate();

        if (!this.form.errors.length) {
          const cors = this.form.data();
          await this.$store.dispatch("createUpdateCors", {
            apiId: this.apiId,
            cors,
          });
          this.persistedCors = cors;
          this.isEditing = false;
        }
      }
    },
    async cancel() {
      if (!this.isSaving) {
        await this.form.init(this.persistedCors);
        this.isEditing = false;
      }
    },
    async edit() {
      this.persistedCors = this.form.data();
      this.isEditing = true;
    },
  },
};
</script>

<style lang="scss">
.cors .table-light {
  td {
    vertical-align: top;
  }

  td:nth-child(1),
  td:nth-child(5),
  td:nth-child(6),
  th:nth-child(1),
  th:nth-child(5),
  th:nth-child(6) {
    width: 10%;
  }

  td:nth-child(3),
  th:nth-child(3) {
    width: 20%;
  }

  td:nth-child(2),
  td:nth-child(4),
  th:nth-child(2),
  th:nth-child(4) {
    width: 25%;
  }
}

.cors .select__selected-option {
  height: 38px;
}
</style>
