import mutations from "./mutations";

import { State } from "./types";

const state: State = {
  notificationToasts: [],
};

export default {
  state,
  mutations,
};
