import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "api-publication-status" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconTooltip = _resolveComponent("IconTooltip")!
  const _component_MBadge = _resolveComponent("MBadge")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($options.tooltipMessage != undefined)
      ? (_openBlock(), _createBlock(_component_IconTooltip, {
          key: 0,
          text: $options.tooltipMessage,
          position: "left"
        }, null, 8, ["text"]))
      : _createCommentVNode("", true),
    _createVNode(_component_MBadge, {
      "data-publication-status": $options.publicationStatus,
      class: "api-publication-status__badge",
      type: $options.badgeType
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString($options.statusLabel), 1)
      ]),
      _: 1
    }, 8, ["data-publication-status", "type"])
  ]))
}