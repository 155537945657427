import actions from "./actions";
import getters from "./getters";

import { State } from "./types";

export const state: State = {
  requestSummariesPage: undefined,
  pendingRequestSummariesPage: undefined,
  currentRequest: undefined,
  preselectedRequest: undefined,
  currentApiPendingAccessPolicyRequests: {
    count: 0,
    currentPage: 0,
    data: {},
    totalCount: 0,
    totalPages: 0,
  },
  currentApiPendingOtherGatewayRequests: {
    count: 0,
    currentPage: 0,
    data: {},
    totalCount: 0,
    totalPages: 0,
  },
  currentApiPendingTimeoutRequests: {
    count: 0,
    currentPage: 0,
    data: {},
    totalCount: 0,
    totalPages: 0,
  },
  requestTypes: [],
};

export default {
  state() {
    return state;
  },
  getters,
  actions,
};
