import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "delete-modal__content" }
const _hoisted_2 = { class: "text-700 weight-bold" }
const _hoisted_3 = { class: "text-400" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MTextInput = _resolveComponent("MTextInput")!
  const _component_DvpField = _resolveComponent("DvpField")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_LayerModalForm = _resolveComponent("LayerModalForm")!

  return (_openBlock(), _createBlock(_component_LayerModalForm, {
    class: "delete-modal",
    onSubmit: $options.onSubmit
  }, {
    fields: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h4", _hoisted_2, _toDisplayString($data.contents.headerConfirmRemove), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString($data.contents.textRemove), 1)
      ]),
      _createVNode(_component_DvpField, {
        class: "delete-modal__confirm-message",
        label: $data.contents.confirmMessageLabel,
        required: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_MTextInput, {
            modelValue: $data.confirmMessage,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.confirmMessage) = $event)),
            placeholder: $data.contents.confirmMessagePlaceholder,
            isInvalid: $options.isError()
          }, null, 8, ["modelValue", "placeholder", "isInvalid"])
        ]),
        _: 1
      }, 8, ["label"])
    ]),
    formFooter: _withCtx(() => [
      _createVNode(_component_MButton, {
        class: "delete-modal__cancel-button",
        type: "button",
        label: $data.contents.cancelButtonLabel,
        theme: "bordered-neutral",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('onClose')))
      }, null, 8, ["label"]),
      _createVNode(_component_MButton, {
        class: "delete-modal__confirm-button",
        theme: "solid-danger",
        type: "submit",
        disabled: $options.isError(),
        label: $data.contents.confirmButtonLabel
      }, null, 8, ["disabled", "label"])
    ]),
    _: 1
  }, 8, ["onSubmit"]))
}