export default {
  btnAcceptCookies: "Accept cookies",
  btnAcceptAllCookies: "Accept all cookies",
  btnPersonalizeCookies: "Personalize cookies",
  btnRefuseAllCookies: "Refuse all cookies",
  titleUsingCookies: "This website use cookies",
  whatIsCookiesTitle: "What's a cookie?",
  whatIsCookie:
    "Cookies are small text files that websites can use to make the user experience more efficient. The law states that we may only store cookies on your device if they are strictly necessary for the operation of this site. For all other types of cookies, we need your permission. This site uses different types of cookies. Some cookies are placed by the third-party services that appear on our pages. To find out more about who we are, how to contact us, and how we handle personal data, please see our Privacy Policy.",
  whyUsingCookiesTitle: "Why using cookies?",
  whyUsingCookies:
    "Cookies allow us to personalize content, offer the best user experience, and analyze our traffic.",
};
