<template>
  <ContractWarning
    class="contract-warning-default"
    :title="contents.subscriptionToApi(apiLabel(contract.api))"
  />
</template>

<script lang="ts">
import ContractWarning from "./ContractWarning.vue";

import { apiLabel } from "@/commons/libs/utils/apiUtils";

import contents from "@/commons/contents/contract-warning-default";

export default {
  components: { ContractWarning },
  props: {
    contract: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      contents,
    };
  },
  methods: { apiLabel },
};
</script>
