export default {
  modalTitle: (groupName) => `${groupName} memberships`,
  addUserToTheGroup: "Add user to the group",
  userList: "Users list",
  noMember: "There is no member in the group",
  userRoleSuccessfullyChanged: "User role edited",
  userSuccessfullyAdded: "User added to the group",
  areYouSureYouWantToRemove: (userName: string) =>
    `Are you sure you want to remove ${userName} from current group?`,
  userSuccessfullyRemoved: "User removed from the group",

  searchUserPlaceholder: "Search user",
  addUserButton: "Add",
  unknownLDAP: "unknown LDAP",
};
