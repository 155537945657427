<template>
  <div class="global-modal-body">
    <div class="global-modal-body__content">
      <slot name="content" />
    </div>
    <div v-if="$slots.footer !== undefined" class="global-modal-body__footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<style lang="scss">
.global-modal-body {
  display: flex;
  flex-direction: column;
  padding-bottom: var(--base-spacing);
}

.global-modal-body__footer {
  display: flex;
  align-items: center;
  justify-content: center;

  padding-top: var(--base-spacing);
  column-gap: var(--base-button-margin);
}
</style>
