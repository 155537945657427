<template>
  <div
    class="year-month-selector flex flex-direction-column"
    :class="{ dark: dark }"
  >
    <div class="flex justify-content-right">
      <CustomButton
        :color="dark ? '#757575' : '#424242'"
        :dark="dark"
        with-border
        @click="$emit('back')"
      >
        <span class="fs-16"> ✕ </span>
      </CustomButton>
    </div>
    <div class="flex-1 flex flex-wrap justify-content-between align-center">
      <CustomButton
        v-for="(m, index) in months"
        :key="index"
        :color="color"
        :selected="currentMonth === index"
        :dark="dark"
        class="month-button"
        with-border
        @click="selectMonth(index)"
      >
        {{ m }}
      </CustomButton>
      <CustomButton
        v-for="year in years"
        :key="year"
        :color="color"
        :dark="dark"
        :selected="currentYear === year"
        with-border
        @click="selectYear(year)"
      >
        {{ year }}
      </CustomButton>
    </div>
  </div>
</template>

<script lang="ts">
import CustomButton from "@/commons/components/DatePicker/_subs/CustomButton/index.vue";
import { getMonthsShort } from "@/commons/components/DatePicker/modules/month";

const ArrayRange = (start, end) => {
  return (Array(end - start + 1) as any).fill().map((_, idx) => {
    const n = start + idx;
    return n;
  });
};

export default {
  name: "YearMonthSelector",
  components: {
    CustomButton,
  },
  props: {
    locale: { type: String, default: null },
    dark: { type: Boolean, default: null },
    color: { type: String, default: null },
    mode: { type: String, default: null },
    month: { type: Object, default: null },
  },
  emits: ["back", "input"],
  data() {
    return {
      months: null,
      years: null,
    };
  },
  computed: {
    currentMonth() {
      return this.month.month;
    },
    currentYear() {
      return this.month.year;
    },
    isMonthMode() {
      return this.mode === "month";
    },
  },
  mounted() {
    if (this.isMonthMode) {
      this.getMonths();
    } else {
      this.getYears();
    }
  },
  methods: {
    getMonths() {
      this.years = null;
      this.months = getMonthsShort(this.locale);
    },
    getYears() {
      this.months = null;
      this.years = ArrayRange(this.month.year - 7, this.month.year + 7);
    },
    selectMonth(monthNumber) {
      this.$emit("input", { month: monthNumber, year: this.currentYear });
    },
    selectYear(year) {
      this.$emit("input", { month: this.currentMonth, year: year });
    },
  },
};
</script>

<style lang="scss" scoped>
.year-month-selector {
  position: absolute;
  inset: 0;
  padding: 10px;
  color: #424242;
  background-color: white;
  &.dark {
    color: white;
    background-color: #424242;
  }
  .month-button {
    text-transform: capitalize;
  }
}
</style>
