import actions from "./actions";
import { DEFAULT_FILTERS_VALUES } from "./constants";
import getters from "./getters";
import fetchReporting from "./mutations/fetch-reporting";
import fetchReportingFilterOptions from "./mutations/fetch-reporting-filters-options";
import updateHiddenDatasets from "./mutations/update-hidden-datasets";
import updateSelectedFilters from "./mutations/update-selected-filters";

import { State } from "./types";

export const state: State = {
  hits: [],
  graph: null,
  filterOptions: {
    applications: [],
    apis: [],
    status: [],
    methods: [],
    zones: [],
  },
  selectedFilters: { ...DEFAULT_FILTERS_VALUES },
  isFetchingFilters: false,
  isFetchingReporting: false,
  errorMessage: null,
};

export default {
  state,
  getters,
  mutations: {
    ...fetchReporting,
    ...fetchReportingFilterOptions,
    ...updateHiddenDatasets,
    ...updateSelectedFilters,
  },
  actions,
};
