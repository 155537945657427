<template>
  <div class="menu-label">
    <MenuLink
      v-if="urlOrRoute"
      class="menu-label__link"
      :urlOrRoute="urlOrRoute"
      :isExternalLink="isExternalLink"
    >
      <slot />
    </MenuLink>
    <slot v-else />
  </div>
</template>

<script lang="ts">
import MenuLink from "@/commons/components/MenuLink.vue";

export default {
  components: {
    MenuLink,
  },
  props: {
    urlOrRoute: {
      type: [String, Object],
      default: undefined,
    },
    isExternalLink: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.menu-label {
  display: flex;
  align-items: center;
  height: 100%;
  cursor: default;
}
</style>
