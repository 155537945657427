import moment from "moment";

moment.locale("en"); // For correct month names

export const localeDateFormats = {
  DATE: "DD/MM/YYYY",
  DATETIME: "DD/MM/YYYY - HH:mm:ss",
  VERBOSE_DATE: "DD MMMM YYYY",
  VERBOSE_DATE_MINUTE: "DD MMMM YYYY - HH:mm",
  SHORT_VERBOSE_DATE: "DD MMM YYYY",
  SHORT_VERBOSE_DATE_MINUTE: "DD MMM YYYY - HH:mm",
};

export function toDateFormat(date: Date | string, format: string): string {
  return moment(date).format(format);
}

export function toLocaleDate(date: Date | string): string {
  return toDateFormat(date, localeDateFormats.DATE);
}

export function toLocaleDatetime(date: Date | string): string {
  return toDateFormat(date, localeDateFormats.DATETIME);
}

export function isBeforeNow(date: Date | string): boolean {
  return moment(date).isBefore(moment());
}

export function expireIn(expirationDate: Date | string): number {
  return moment(expirationDate).diff(moment(), "days");
}
