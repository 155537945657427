<template>
  <Layout class="page-not-found" hide-loader
    ><template #navbar><TopNavbarEmpty /></template>
    <div class="text-centered">
      <img
        class="responsive-image"
        :src="require('@/commons/assets/images/page-forbidden.png')"
      />
      <h1 class="weight-regular gap-1300">
        {{ contents.pageNotFoundContent }}
      </h1>

      <RouterLink
        class="gap-1300"
        role="button"
        :to="{ name: 'home' }"
        :title="(userIsInternal && 'Dashboard') || 'Home'"
      >
        <template v-if="userIsInternal">
          {{ contents.backDashboard }}
        </template>
        <template v-else>
          {{ contents.backHome }}
        </template>
      </RouterLink>
    </div>
  </Layout>
</template>

<script lang="ts">
import Layout from "@/commons/components/Layout.vue";
import TopNavbarEmpty from "@/commons/components/TopNavbarEmpty.vue";

import contents from "@/commons/contents/contents.js";

export default {
  name: "PageNotFound",
  components: { Layout, TopNavbarEmpty },
  data() {
    return { contents };
  },
  computed: {
    userIsInternal() {
      const user = this.$store.getters["user"];
      return user && user.isInternal;
    },
  },
};
</script>

<style lang="scss">
.page-not-found {
  --responsive-image-max-width: 70rem;
}
</style>
