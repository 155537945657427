<template>
  <div ref="global-modal" class="global-modal">
    <MModal
      v-model:open="isOpen"
      :modalTitle="title"
      :displayCloseButton="isClosable"
      @onClose="handleClose"
    >
      <!-- v-if="isOpen" on <component/> so underlying element doesn't exist in the DOM
(would cause e2e tests crash because of element duplication on GlobalLayerModal and GlobalModal) -->
      <component
        :is="component"
        v-if="isOpen"
        v-bind="props"
        v-on="listeners"
        @onClose="handleClose"
      />
    </MModal>
  </div>
</template>

<script lang="ts">
import MModal from "@mozaic-ds/vue-3/src/components/modal/MModal.vue";

import { EModalType } from "@/commons/components/Modal/modal.type";

import { KEY } from "@/commons/utils/key-code";

import { GlobalModal } from "@/commons/store/types";

export default {
  components: { MModal },
  computed: {
    openModal(): GlobalModal | undefined {
      return this.$store.state["openModal"];
    },
    props(): any {
      return this.openModal?.props;
    },
    listeners(): Record<string, Function> | undefined {
      return this.openModal?.listeners;
    },
    title(): string | undefined {
      return this.openModal?.title || "";
    },
    component(): any {
      return this.openModal?.component;
    },
    isClosable(): boolean {
      return this.openModal?.isClosable !== undefined
        ? this.openModal.isClosable
        : true;
    },
    redirectOnClose(): string | undefined {
      return this.openModal && this.openModal.redirectOnClose;
    },
    isOpen: {
      get(): boolean {
        return (
          this.$store.state["modalType"] === EModalType.MODAL &&
          this.$store.getters["isModalOpen"]
        );
      },
      set(newValue: boolean): void {
        if (newValue === false) {
          this.handleClose();
        }
      },
    },
  },
  watch: {
    isOpen: {
      immediate: true,
      handler(newValue: boolean) {
        // To prevent from scrolling on the rest of the body when the modal is displayed
        if (newValue === true) {
          document.body.classList.add("modal--open");
        } else {
          document.body.classList.remove("modal--open");
        }
      },
    },
  },
  created() {
    document.addEventListener("keyup", this.handleKeyup);
  },
  beforeUnmount() {
    document.removeEventListener("keyup", this.handleKeyup);
  },
  methods: {
    handleClose(): void {
      if (this.isClosable) {
        if (this.redirectOnClose) {
          this.$router.push({ name: this.redirectOnClose });
        }

        this.$store.commit("closeModal");
      }
    },
    handleKeyup(e: KeyboardEvent): void {
      if (e.keyCode === KEY.ESCAPE) {
        this.handleClose();
      }
    },
  },
};
</script>

<style lang="scss">
.global-modal {
  // New stacking context to isolate .mc-layer and .mc-layer-overlay crazy z-indexes and directly handle it with .global-modal
  position: fixed;
  z-index: var(--z-index-modal);

  // Dirty trick: We disable transition on the modal closing so we don't see the modal collapse before the end of the transition
  // (the inside content would disappear during the animation because when we close we do a state.openModal = undefined and there no component to render)
  .mc-modal__dialog:not(.is-open) {
    transition: none;
  }
}
</style>
