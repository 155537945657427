import { ProductFetchParams } from "@/commons/domain/repositories/product-repository";

export function buildScopesProductsFetchParams(
  scopeName: string,
  page: number = 1,
  size: number = 10,
): ProductFetchParams {
  return {
    page,
    size,
    sort: "asc",
    scopeName,
  };
}
