<script setup lang="ts">
import { computed, markRaw, onMounted, ref } from "vue";
import { useStore } from "vuex";

import CardContainer from "@/commons/components/CardContainer.vue";
import GroupsList from "@/commons/components/ManageGroups/GroupsList.vue";
import NotificationWarningMCCGroup from "@/commons/components/ManageGroups/NotificationWarningMCCGroup.vue";
import SearchGroup from "@/commons/components/ManageGroups/SearchGroup.vue";
import MessageConfirmModal from "@/commons/components/Modal/MessageConfirmModal.vue";
import { ModalClosedError } from "@/commons/components/Modal/modal.type";
import DeleteGroupModal from "@/commons/components/userGroup/DeleteGroupModal.vue";

import { Debouncer } from "@/commons/libs/utils/debouncer";
import {
  isLocalGroup,
  isOnlyInternalsGroup,
  lastMCCGroupWillBeDeleted,
} from "@/commons/utils/group-utils";

import { Group } from "@/commons/domain/models/group";
import { GroupWithUsersAcls } from "@/commons/domain/models/group-with-users-acls";
import { searchGroupsInApiSettings } from "@/commons/services/group/group-service";

import { ERole } from "@/commons/store/types";

import contentsForDeleteGroupModal from "@/commons/contents/delete-group-modal";
import contentsWarningDeleteLastMCCGroup from "@/commons/contents/warning-delete-last-mcc-group-modal";
import contents from "@/manager/contents/manage-groups";

const store = useStore();
const debouncer = new Debouncer();

const currentApi = computed(() => {
  return store.getters["currentApi"];
});

const groups = computed(() => {
  return Object.values(currentApi.value.acl.groups).filter(
    (group: Group) => group.role.id === ERole.MANAGER,
  );
});

const suggestions = ref([]);

const addGroup = async (groupId: string) => {
  await store.dispatch("apiCreateGroupAcl", {
    apiId: currentApi.value.id,
    id: groupId,
    roleId: ERole.MANAGER,
  });
};

const searchGroups = async (searchValue: string) => {
  debouncer.debounce(async () => {
    suggestions.value = await searchGroupsInApiSettings(
      searchValue,
      currentApi.value?.product?.id,
    );
  }, 100);
};

onMounted(async () => {
  await searchGroups("");
});

const deleteGroup = async (groupId: string) => {
  if (lastMCCGroupWillBeDeleted(groups.value, groupId)) {
    await openWarningModalAndAskConfirmation();
  }

  openDeleteGroupModal(groupId);
};

const openWarningModalAndAskConfirmation = (): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    store.commit("openModal", {
      title: contentsWarningDeleteLastMCCGroup.modalTitle,
      component: markRaw(MessageConfirmModal),
      props: {
        title: contentsWarningDeleteLastMCCGroup.title,
        message: contentsWarningDeleteLastMCCGroup.messageDeleteGroupOnApi,
        confirmBtnLabel: contentsWarningDeleteLastMCCGroup.confirmBtnLabel,
      },
      listeners: {
        onSubmit: () => {
          store.commit("closeModal");
          resolve(true);
        },
        onClose: () => reject(new ModalClosedError()),
      },
    });
  });
};

const openDeleteGroupModal = (groupId: string) => {
  store.commit("openLayerModal", {
    title: contentsForDeleteGroupModal.modalTitle,
    component: markRaw(DeleteGroupModal),
    listeners: {
      async onSubmit() {
        await store.dispatch("apiRemoveGroupAcl", {
          id: groupId,
          apiId: currentApi.value.id,
        });
      },
    },
  });
};

const displayMCCWarning = ref(false);

const onGroupSelection = (groupId: string) => {
  const selectedGroup = suggestions.value.find(
    (g: GroupWithUsersAcls) => g.id === groupId,
  );
  displayMCCWarning.value =
    selectedGroup != undefined &&
    isLocalGroup(selectedGroup) &&
    isOnlyInternalsGroup(selectedGroup);
};
</script>

<template>
  <CardContainer class="manage-api-groups">
    <h4>{{ contents.titleManageGroups }}</h4>

    <SearchGroup
      :suggestions="suggestions"
      :addGroupBtnLabel="contents.submitNewGroupBtnLabel"
      buttonWidth="12rem"
      vertical
      @searchGroups="searchGroups"
      @addGroup="addGroup"
      @onSelect="onGroupSelection"
    />

    <NotificationWarningMCCGroup v-if="displayMCCWarning" />

    <GroupsList
      class="manage-api-groups__groups-list"
      :groups="groups"
      @deleteGroup="deleteGroup"
    />
  </CardContainer>
</template>

<style lang="scss">
.manage-api-groups {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.manage-api-groups__groups-list {
  margin-bottom: 1rem;
}
</style>
