export default {
  consumersTitle: "Consumers",
  noApplicationAreUsing: "No application is using",
  createAnAppToUseApi: "Create an app to use your API",
  sendAnEmailToAllUsers: "Send an email to all users",
  exportConsumers: "Export consumers (.csv)",
  searchApplicationPlaceholder: "Search for an application",
  applications: "Applications",
  sandboxes: "Sandboxes",
  pending: "PENDING",

  headerApplicationName: "Name",
  headerOauthFlow: "Flow",
  headerCredentials: "Credentials",
  headerApplicationManager: "Application managers",
  headerConsumerIPs: "Consumer IPs",

  noConsumerFound: "No consumer found",
  tooltipOauthCcf: "Client Credentials Flow (Machine-to-Machine)",
  tooltipOauthAcf: "Authorization Code Flow (User-to-Machine)",
  expirationDate: "Expiration date:",
  deprecated: "Deprecated",
  noManagerOnApplication: "No manager on application",

  ipsLabel: "IPs",
  managersLabel: "Managers",
};
