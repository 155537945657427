import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContractInformation = _resolveComponent("ContractInformation")!
  const _component_ContractRemoveButton = _resolveComponent("ContractRemoveButton")!
  const _component_MNotification = _resolveComponent("MNotification")!
  const _component_ContractWarning = _resolveComponent("ContractWarning")!
  const _component_ContractCardContainer = _resolveComponent("ContractCardContainer")!

  return (_openBlock(), _createBlock(_component_ContractCardContainer, { class: "contract-warning-api-unpublished" }, {
    default: _withCtx(() => [
      _createVNode(_component_ContractInformation, { contract: $props.contract }, null, 8, ["contract"]),
      _createVNode(_component_ContractWarning, {
        title: $data.contents.apiHasBeenUnpublished,
        message: $data.contents.notAvailable,
        iconName: "NotificationWarning24"
      }, {
        default: _withCtx(() => [
          ($options.userHasWriteAccessOnCurrentApplication)
            ? (_openBlock(), _createBlock(_component_ContractRemoveButton, {
                key: 0,
                class: "contract-warning-api-unpublished__remove-button",
                contract: $props.contract,
                onContractDeleted: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('contractDeleted')))
              }, null, 8, ["contract"]))
            : _createCommentVNode("", true),
          ($options.mustDisplayWarningMessage)
            ? (_openBlock(), _createBlock(_component_MNotification, {
                key: 1,
                class: "contract-warning-api-unpublished__warning-notification",
                type: "warning"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($options.warningMessage), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["title", "message"])
    ]),
    _: 1
  }))
}