module.exports = {
  modalTitle: "API security risks",
  exposureRiskWarning:
    "Please note that exposure of your API on the Internet may make you vulnerable to potential cyber threats.",
  policyMention:
    "You will have to respect the security policy put in place by the ADEO group.\n" +
    "      It is imperative to have your action validated by your Cyber security leader.",
  maxPublicApiTimeoutNotificationTitle: (maxPublicTimeoutInSeconds) =>
    `Your timeout will be lowered to ${maxPublicTimeoutInSeconds} sec`,
  maxPublicApiTimeoutNotificationContent: (
    currentTimeoutInSeconds,
    maxPublicTimeoutInSeconds,
  ) => `Your API will be restricted to maximum a <strong>${maxPublicTimeoutInSeconds}&nbsp;sec</strong> timeout by our CDN Fastly.<br />
    Your current <strong>${currentTimeoutInSeconds}&nbsp;sec</strong> timeout will be therefore ignored and lowered to <strong>${maxPublicTimeoutInSeconds}&nbsp;sec</strong> on your gateways.<br />
    Your API User Experience may be impacted.<br />
    Your current <strong>${currentTimeoutInSeconds}&nbsp;sec</strong> timeout will be kept in mind and re-applied if you deactivate the Internet exposure.<br />
    Nevertheless, is it the time to improve your API performance?`,
  confirm: "I understand and I publish my API on the internet",
};
