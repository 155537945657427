<template>
  <LayerModalFormConfirm
    class="multi-acf-providers-prevention-step-1-modal"
    confirmBtnTheme="solid"
    :confirmBtnLabel="contents.confirmButtonStep1"
    :disabledConfirmBtn="
      isLoadingAcfContractsOfApplication || loadingOfAcfContractsHasFailed
    "
    @submit="submit"
    @close="close"
  >
    <div class="multi-acf-providers-prevention-step-1-modal__content">
      <DvpStepper
        class="multi-acf-providers-prevention-step-1-modal__stepper"
        :steps="stepperSteps"
        :stepperDescription="contents.stepperDescription"
      />

      <MNotification type="warning">
        <Markdown
          :content="
            contents.multiProvidersNotification(
              apiACFProvider,
              applicationACFProvider,
            )
          "
        />
        <MoreAboutLink
          class="multi-acf-providers-prevention-step-1-modal__warning-more-about"
          documentation-anchor="#consume-two-authorization-code-flow-apis"
        />
      </MNotification>

      <p
        class="multi-acf-providers-prevention-step-1-modal__info-about-unsubscriptions"
      >
        {{ contents.infoAboutUnsubscriptions }}
      </p>

      <div
        class="multi-acf-providers-prevention-step-1-modal__api-list-section"
      >
        <p
          class="multi-acf-providers-prevention-step-1-modal__api-list-introduction"
        >
          {{ contents.listOfImpactedApis }}
        </p>
        <MLoader
          v-if="isLoadingAcfContractsOfApplication"
          class="multi-acf-providers-prevention-step-1-modal__api-list-loader"
          size="m"
        />
        <MNotification v-else-if="loadingOfAcfContractsHasFailed" type="danger">
          <Markdown :content="contents.errorOnImpactedApisLoading"></Markdown>
        </MNotification>
        <ul
          v-else
          class="multi-acf-providers-prevention-step-1-modal__api-list"
        >
          <li
            v-for="api in apisWithACFSubscription"
            :key="api.id"
            class="multi-acf-providers-prevention-step-1-modal__api-list-item"
          >
            {{ api.label }}
          </li>
          <li
            v-if="
              paginatedAcfContractsOfApplication.totalCount >
              paginatedAcfContractsOfApplication.count
            "
          >
            {{ contents.andMore }}
          </li>
        </ul>
      </div>

      <Markdown
        class="multi-acf-providers-prevention-step-1-modal__alternative-solution-section"
        :content="contents.alternativeSolution"
      />
    </div>
  </LayerModalFormConfirm>
</template>

<script lang="ts">
import MLoader from "@mozaic-ds/vue-3/src/components/loader/MLoader.vue";
import MNotification from "@mozaic-ds/vue-3/src/components/notification/MNotification.vue";
import { PropType } from "vue";

import DvpStepper from "@/commons/components/DvpStepper.vue";
import MoreAboutLink from "@/commons/components/UserDocumentationLinks/MoreAboutLink.vue";
import LayerModalFormConfirm from "@/commons/components/form/LayerModalFormConfirm.vue";
import MultiACFProvidersPreventionStep2Modal from "@/dashboard/views/AppDetailsContracts/modal/MultiACFProvidersPreventionStep2Modal.vue";
import Markdown from "@/documentation/components/commons/Markdown.vue";

import { getApiLabelWithVersion } from "@/commons/libs/utils/apiUtils";
import { getContractRepository } from "@/commons/repositories/libs/get-contract-repository";
import { flagLoadingProcess } from "@/commons/utils/flagProcess.utils";

import { Application } from "@/commons/domain/models/application";
import { Contract } from "@/commons/domain/models/contract";
import { EOauthFlow } from "@/commons/types/oauth-flow-types";

import { EAuthType } from "@/commons/store/types";

import contents from "@/dashboard/contents/multi-acf-providers-prevention-modal";

export default {
  components: {
    LayerModalFormConfirm,
    Markdown,
    MoreAboutLink,
    MNotification,
    DvpStepper,
    MLoader,
  },
  props: {
    application: {
      type: Object as PropType<Application>,
      required: true,
    },
    apiACFProvider: {
      type: String,
      required: true,
    },
    applicationACFProvider: {
      type: String,
      required: true,
    },
  },
  emits: ["onClose"],
  data() {
    return {
      contents,
      currentStep: 1,
      paginatedAcfContractsOfApplication: undefined,
    };
  },
  computed: {
    isLoadingAcfContractsOfApplication() {
      return this.$store.getters["isLoadingProperty"](
        "acfContractsOfApplication",
      );
    },
    loadingOfAcfContractsHasFailed() {
      return (
        !this.isLoadingAcfContractsOfApplication &&
        this.paginatedAcfContractsOfApplication == undefined
      );
    },
    stepperSteps() {
      return [
        { label: contents.step1Label, isCurrent: true },
        { label: contents.step2Label },
      ];
    },
    stepAccessibilityLabels() {
      return {
        stepperDescription: contents.stepperDescription,
      };
    },
    apisWithACFSubscription() {
      const acfContracts =
        this.paginatedAcfContractsOfApplication != undefined
          ? Object.values<Contract>(
              this.paginatedAcfContractsOfApplication.data,
            )
          : [];

      return acfContracts.map((contract: any) => ({
        label: getApiLabelWithVersion(contract.api),
        id: contract.api.id,
      }));
    },
  },
  created() {
    flagLoadingProcess("acfContractsOfApplication", async () => {
      this.paginatedAcfContractsOfApplication =
        await getContractRepository().findByApplication({
          applicationId: this.application.id,
          authType: EAuthType.OAUTH,
          oauthFlow: EOauthFlow.AUTHORIZATION_CODE,
        });
    });
  },
  methods: {
    openStep2Modal(): void {
      this.$store.commit("openLayerModal", {
        title: contents.modalTitle,
        component: MultiACFProvidersPreventionStep2Modal,
        props: {
          application: this.application,
          paginatedAcfContractsOfApplication:
            this.paginatedAcfContractsOfApplication,
        },
      });
    },
    submit(): void {
      this.openStep2Modal();
    },
    close(): void {
      this.$emit("onClose");
    },
  },
};
</script>

<style lang="scss">
.multi-acf-providers-prevention-step-1-modal__stepper {
  margin-bottom: 1.5rem;
}

.multi-acf-providers-prevention-step-1-modal__warning-more-about {
  margin-top: 0.5rem;
}

.multi-acf-providers-prevention-step-1-modal__info-about-unsubscriptions {
  margin-top: 1rem;
}

.multi-acf-providers-prevention-step-1-modal__api-list-section {
  margin-top: 1rem;
}

.multi-acf-providers-prevention-step-1-modal__api-list-loader {
  margin-left: 5rem;
}

.multi-acf-providers-prevention-step-1-modal__api-list-introduction {
  margin-top: 0;
}

.multi-acf-providers-prevention-step-1-modal__api-list {
  margin-top: 0;
}

.multi-acf-providers-prevention-step-1-modal__api-list-item {
  font-weight: var(--weight-bold);
}

.multi-acf-providers-prevention-step-1-modal__alternative-solution-section {
  margin-top: 1.5rem;
  @include set-text-s;

  // Need to had a rule on p element because p element have a basic style and we can't add a class on it as it's generated by the markdown
  p {
    @include set-text-s;
  }
}

.multi-acf-providers-prevention-step-1-modal__advanced-solution-more-about {
  margin-top: 0.5rem;
}
</style>
