import { getApplicationRepository } from "@/commons/repositories/libs/get-application-repository";
import { isEqualIgnoreCase } from "@/commons/utils/string-utils";

import { Application } from "@/commons/domain/models/application";
import { ApplicationMapper } from "@/commons/mappers/application-mapper";

export async function searchApplication(name: string) {
  const pagedApplications = await getApplicationRepository().find({ name });
  return Object.values(pagedApplications.data);
}

export async function searchMatchingApplication(
  name: string,
): Promise<Application> | undefined {
  const appList = await searchApplication(name);
  const foundApp = appList?.find((app) => isEqualIgnoreCase(app.name, name));

  return foundApp ? ApplicationMapper.toApplicationDomain(foundApp) : undefined;
}
