import { IsNotEmpty, MaxLength, Validate } from "class-validator";

import { FormData } from "@/commons/domain/forms/form-data";
import { FormFactory } from "@/commons/domain/forms/form-factory";
import {
  CustomProxyHeader,
  ECustomProxyHeaderType,
} from "@/commons/domain/models/custom-proxy-header";
import {
  IsValidCustomProxyHeaderNameValidatorConstraint,
  MAX_CUSTOM_PROXY_HEADER_NAME_LENGTH,
  MAX_CUSTOM_PROXY_HEADER_VALUE_LENGTH,
} from "@/manager/domain/validation/custom-proxy-header-validation";

export class CustomProxyHeaderForm extends FormData {
  id: string | null;

  isOverridingClientHeader: boolean;

  @IsNotEmpty({
    message: "This field cannot be empty",
  })
  @Validate(IsValidCustomProxyHeaderNameValidatorConstraint)
  @MaxLength(MAX_CUSTOM_PROXY_HEADER_NAME_LENGTH)
  name: string;

  @IsNotEmpty({
    message: "This field cannot be empty",
  })
  @MaxLength(MAX_CUSTOM_PROXY_HEADER_VALUE_LENGTH)
  value: string;

  type: ECustomProxyHeaderType;

  constructor(customProxyHeader: CustomProxyHeader) {
    super();
    this.$update(customProxyHeader);
  }

  $clear() {
    this.name = "";
    this.value = "";
    this.isOverridingClientHeader = false;
  }

  static create(
    customProxyHeader: CustomProxyHeader = {
      id: null,
      name: "",
      value: "",
      isOverridingClientHeader: false,
      type: null,
    },
  ) {
    return FormFactory.createForm(new CustomProxyHeaderForm(customProxyHeader));
  }
}
