<template>
  <Card>
    <h4>{{ contents.sensitiveModifications }}</h4>
    <div>
      <SensitiveModificationsList
        :title="contents.deleteAccountTitle"
        :description="contents.actionUndone"
        :btnTitle="contents.delete"
        :btnAction="deleteAccount"
      />
    </div>
  </Card>
</template>

<script lang="ts">
import { markRaw } from "vue";

import Card from "@/commons/components/Card.vue";
import SensitiveModificationsList from "@/manager/views/ApiDetailSettings/SensitiveModificationsList.vue";

import DeleteUserModal from "./DeleteUserModal.vue";

import contents from "@/commons/contents/user-sensitive-modifications";

export default {
  name: "UserSensitiveModifications",
  components: {
    Card,
    SensitiveModificationsList,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      contents,
    };
  },
  methods: {
    deleteAccount() {
      this.$store.commit("openLayerModal", {
        title: "Do you want to permanently delete your account?",
        component: markRaw(DeleteUserModal),
        props: { userEmail: this.user.email },
        listeners: {},
      });
    },
  },
};
</script>
