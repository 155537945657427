<template>
  <LayerModalForm class="request-timeout-modal" @submit="onSubmit">
    <template #fields>
      <MNotification
        v-if="isApiPublic(api)"
        class="timeout__limit-information"
        type="information"
        :title="contents.timeoutLimitInformationTitle"
      >
        <span
          v-html="
            contents.timeoutLimitPublicApiInformation(MAX_PUBLIC_API_TIMEOUT)
          "
        />
      </MNotification>
      <MField
        id="timeout-field"
        class="request-timeout-modal__timeout-field"
        :label="contents.timeoutLabel"
        requirementText="required"
        :isInvalid="isInvalidField('timeoutInSeconds')"
        :errorMessage="form.firstError('timeoutInSeconds')"
      >
        <MTextInput
          type="number"
          icon="TimeTimeDecline24"
          :isInvalid="isInvalidField('timeoutInSeconds')"
          :modelValue="form.timeoutInSeconds"
          @update:modelValue="form.timeoutInSeconds = Number($event)"
        />
      </MField>
      <MField
        id="message"
        class="request-timeout-modal__message-field"
        :label="contents.messageLabel"
        :helpId="contents.messageHelpId"
        :helpText="contents.messageHelpText"
        requirementText="required"
        :isInvalid="isInvalidField('message')"
        :errorMessage="form.firstError('message')"
      >
        <MTextArea
          v-model="form.message"
          :placeholder="contents.messagePlaceholder"
          :isInvalid="isInvalidField('message')"
          :rows="10"
        />
      </MField>
    </template>

    <template #formFooter>
      <MButton
        class="mc-button--bordered-neutral request-timeout-modal__cancel-button"
        :label="contents.cancel"
        @click="$emit('onClose')"
      />
      <MButton
        class="request-timeout-modal__submit-button"
        type="submit"
        :disabled="!!form.errors.length"
        :label="contents.submit"
      />
    </template>
  </LayerModalForm>
</template>

<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import MField from "@mozaic-ds/vue-3/src/components/field/MField.vue";
import MNotification from "@mozaic-ds/vue-3/src/components/notification/MNotification.vue";
import MTextArea from "@mozaic-ds/vue-3/src/components/textarea/MTextArea.vue";
import MTextInput from "@mozaic-ds/vue-3/src/components/textinput/MTextInput.vue";

import LayerModalForm from "@/commons/components/form/LayerModalForm.vue";

import { isApiPublic } from "@/commons/libs/utils/apiUtils";

import { RequestTimeoutForm } from "@/commons/forms/request-timeout-form ";
import {
  MAX_API_TIMEOUT,
  MAX_PUBLIC_API_TIMEOUT,
  MIN_API_TIMEOUT,
} from "@/manager/domain/forms/timeout";

import { EPrivacyName } from "@/commons/store/types";

import contents from "@/manager/contents/request-timeout-modal";

export default {
  components: {
    LayerModalForm,
    MTextArea,
    MField,
    MButton,
    MTextInput,
    MNotification,
  },
  props: {
    api: {
      type: Object,
      required: true,
    },
    requestedTimeout: {
      type: Number,
      required: true,
    },
  },
  emits: ["onClose"],
  data() {
    return {
      form: RequestTimeoutForm.create(
        this.isApiPublic(this.api) ? MAX_PUBLIC_API_TIMEOUT : MAX_API_TIMEOUT,
      ),
      contents,
      MAX_API_TIMEOUT,
      MIN_API_TIMEOUT,
      MAX_PUBLIC_API_TIMEOUT,
      EPrivacyName,
    };
  },
  async mounted() {
    await this.form.init({ timeoutInSeconds: this.requestedTimeout });
  },
  methods: {
    isApiPublic,
    isInvalidField(fieldName: string): boolean {
      return this.form.firstError(fieldName) != null;
    },
    async onSubmit() {
      await this.form.validate();
      if (!this.form.errors.length) {
        const { timeoutInSeconds, message } = this.form.data();

        await this.$store.dispatch("updateApi", {
          id: this.api.id,
          timeout: timeoutInSeconds,
          comment: message,
        });

        await this.$store.dispatch("fetchPendingTimeoutRequests", this.api.id);
        this.$store.commit("closeModal");
      }
    },
  },
};
</script>
