<script setup lang="ts">
import MLink from "@mozaic-ds/vue-3/src/components/link/MLink.vue";
import { markRaw, PropType } from "vue";
import { useStore } from "vuex";

import ContractMoreInfoModal from "@/dashboard/views/ContractsList/modal/ContractMoreInfoModal.vue";

import { apiLabel } from "@/commons/libs/utils/apiUtils";

import { Contract } from "@/commons/domain/models/contract";

import contents from "@/dashboard/contents/contract-item";
import contentsOfMoreInfoModal from "@/dashboard/contents/contract-more-info-modal";

const props = defineProps({
  contract: {
    type: Object as PropType<Contract>,
    required: true,
  },
});

const store = useStore();

const openMoreInfoModal = () => {
  store.commit("openLayerModal", {
    title: contentsOfMoreInfoModal.modalTitle(apiLabel(props.contract.api)),
    component: markRaw(ContractMoreInfoModal),
    props: {
      contract: props.contract,
    },
    listeners: {},
  });
};
</script>

<template>
  <div class="contract-more-info">
    <MLink icon="NotificationInformation24" @click="openMoreInfoModal">
      {{ contents.infoAndLinksLabel }}
    </MLink>
  </div>
</template>

<style lang="scss">
.contract-more-info {
  display: flex;
  gap: 0.5rem;
}

.contract-more-info__label {
  text-decoration: underline;
  cursor: pointer;
}
</style>
