import { Dictionary } from "@/commons/domain/models/dictionary";
import { GroupWithUserAction } from "@/commons/domain/models/group-with-user-action";
import { GroupDto } from "@/commons/dtos/group-dto";

export class GroupWithUserActionMapper {
  static toDomain(groupDtos: GroupDto[]) {
    const groupsWithUserAction: Dictionary<GroupWithUserAction> = {};

    for (const {
      id,
      isRemoved,
      updatedAt,
      name,
      description,
      currentUserActions,
      role,
      provider,
    } of groupDtos) {
      const groupWithUserAction = {
        id,
        isRemoved,
        updatedAt,
        name,
        description,
        currentUserActions,
        role,
        provider,
      };
      groupsWithUserAction[groupWithUserAction.id] = groupWithUserAction;
    }

    return groupsWithUserAction;
  }
}
