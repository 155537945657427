<template>
  <div class="action-zone">
    <slot />
  </div>
</template>
<script lang="ts">
export default {
  name: "ActionZone",
};
</script>
<style lang="scss">
.action-zone {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 5rem; /* based on 16px */
  background: var(--color-background-secondary-light);
  border: 1px dashed var(--color-stroke-light);
  border-radius: var(--m-border-radius-large);
}
</style>
