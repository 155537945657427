module.exports = {
  header: "Routing",
  targetUrl: "Default target URL",
  targetUrlTooltip: "Add your private application URL",
  gateways: "Gateways",
  gatewaysTooltip:
    "Gateways accessible on the internet can be called outside ADEO network and shared with partners",
  targetUrlPlaceholder: "Set default target URL",
  buttonCancelLabel: "Cancel",
  buttonSaveLabel: "Save the route",
  buttonAdd: "Add route",
  noGatewaysAvailable:
    "Currently, you aren’t allowed to add a new routing because all available gateways are used.",
};
