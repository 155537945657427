import { FormData } from "@/commons/domain/forms/form-data";
import { FormFactory } from "@/commons/domain/forms/form-factory";
import { Product } from "@/commons/domain/models/product";

import {
  EAccessPolicy,
  EPrivacyName,
  EPublicationType,
} from "@/commons/store/types";

export interface FiltersApiManagerFormData {
  productsIds?: string[];
  products?: Product[];
  platformsIds?: string[];
  businessUnitsIds?: string[];
  publicationStatuses?: string[];
  publicationZoneId?: string;
  accessTypes?: string[];
  privacies?: string[];
}

export class FiltersApiManagerForm extends FormData {
  productsIds: string[] = [];
  platformsIds: string[] = [];
  businessUnitsIds: string[] = [];
  publicationStatuses: EPublicationType[] = [];
  publicationZoneId: string = undefined;
  accessTypes: EAccessPolicy[] = [];
  privacies: EPrivacyName[] = [];

  $clear() {
    this.productsIds = [];
    this.platformsIds = [];
    this.businessUnitsIds = [];
    this.publicationStatuses = [];
    this.publicationZoneId = undefined;
    this.accessTypes = [];
    this.privacies = [];
  }

  constructor(
    productsIds: string[] = [],
    platformsIds: string[] = [],
    businessUnitsIds: string[] = [],
    publicationStatuses: EPublicationType[] = [],
    publicationZoneId: string = undefined,
    accessTypes: EAccessPolicy[] = [],
    privacies: EPrivacyName[] = [],
  ) {
    super();
    this.productsIds = productsIds;
    this.platformsIds = platformsIds;
    this.businessUnitsIds = businessUnitsIds;
    this.publicationStatuses = publicationStatuses;
    this.publicationZoneId = publicationZoneId;
    this.accessTypes = accessTypes;
    this.privacies = privacies;
  }

  static create() {
    return FormFactory.createForm(new FiltersApiManagerForm());
  }
}
