export function getAccesPolicyIcon(accessPolicy) {
  switch (accessPolicy) {
    case "PRIVATE":
      return require("@/commons/assets/icons/icon-api-private.svg");
    case "INTERNAL_ON_DEMAND":
      return require("@/commons/assets/icons/icon-api-internal-od.svg");
    case "INTERNAL_SELF_SERVICE":
      return require("@/commons/assets/icons/icon-api-internal.svg");
    case "PUBLIC_ON_DEMAND":
      return require("@/commons/assets/icons/icon-api-public-od.svg");
    case "PUBLIC_SELF_SERVICE":
      return require("@/commons/assets/icons/icon-api-public.svg");
  }
}
