<template>
  <span class="chip">
    <slot></slot>
  </span>
</template>

<script lang="ts">
export default {
  name: "Chip",
};
</script>

<style lang="scss">
.chip {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--chip-width, auto);
  padding: calc(var(--chip-space) / 2) var(--chip-space)
    calc((var(--chip-space) / 2) + 1px) var(--chip-space);
  font-size: var(--chip-text);
  font-weight: var(--chip-weight);
  line-height: var(--chip-height);
  color: var(--chip-text-color);
  background-color: var(--chip-bg-color);
  border: var(--chip-border);
  border-radius: var(--chip-border-radius, 1rem);
}
</style>
