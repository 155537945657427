<template>
  <CardContainer class="black-list-routes">
    <h4>{{ contents.title }}</h4>
    <div class="black-list-routes__subtitle">{{ contents.subtitle }}</div>
    <div v-if="hasContent" class="black-list-routes__content">
      <MDataTable
        :headers="headers"
        :source="blackListPoliciesList"
        :item-classes="itemRowClass"
      >
        <template #[`item.httpMethods`]="{ item }">
          {{ item.httpMethods.join(", ") }}
        </template>
        <template #[`item.actions`]="{ item }">
          <ActionButtonsWrapper>
            <ButtonEdit @click="createOrUpdatePolicy(item)" />
            <ButtonDelete @click="deletePolicy(item)" />
          </ActionButtonsWrapper>
        </template>
      </MDataTable>
    </div>
    <div class="black-list-routes__action">
      <ActionZone>
        <MButton
          class="black-list-routes__add-button"
          :label="contents.buttonTitle"
          icon="ControlCircleMore24"
          @click="createOrUpdatePolicy(null)"
        ></MButton>
      </ActionZone>
    </div>
  </CardContainer>
</template>

<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import MDataTable from "@mozaic-ds/vue-3/src/components/datatable/MDataTable.vue";

import ActionZone from "@/commons/components/ActionZone.vue";
import ActionButtonsWrapper from "@/commons/components/Buttons/ActionButtonsWrapper.vue";
import ButtonDelete from "@/commons/components/Buttons/ButtonDelete.vue";
import ButtonEdit from "@/commons/components/Buttons/ButtonEdit.vue";
import CardContainer from "@/commons/components/CardContainer.vue";
import CreateOrUpdateBlackListRouteModal from "@/manager/views/ApiDetailRouting/CreateOrUpdateBlackListRouteModal.vue";
import DeleteBlackListRouteModal from "@/manager/views/ApiDetailRouting/DeleteBlacklistRouteModal.vue";

import { BlackListPolicy } from "@/commons/domain/models/black-list-policy";

import contents from "@/manager/contents/black-list-routes";

export default {
  name: "BlackListRoutes",
  components: {
    ActionButtonsWrapper,
    ButtonEdit,
    ButtonDelete,
    ActionZone,
    CardContainer,
    MDataTable,
    MButton,
  },
  data() {
    return {
      contents,
    };
  },
  computed: {
    currentApi() {
      return this.$store.getters["currentApi"];
    },
    blackListPoliciesList() {
      return this.$store.getters["blackListPoliciesList"];
    },
    hasContent() {
      return this.blackListPoliciesList?.length > 0;
    },
    headers() {
      return [
        {
          caption: contents.routePatternColumnName,
          dataFieldExpr: "pathRegex",
          cssClass: "black-list-routes__table-pattern-column",
        },
        {
          caption: contents.methodsColumnName,
          dataFieldExpr: "httpMethods",
          cssClass: "black-list-routes__table-http-methods-column",
        },
        {
          caption: contents.actionsColumnName,
          dataFieldExpr: "actions",
          cssClass: "black-list-routes__table-actions-column",
        },
      ];
    },
  },
  methods: {
    itemRowClass(item: BlackListPolicy) {
      return `black-list-routes__table-row-${item.id}`;
    },
    async createOrUpdatePolicy(policy) {
      this.$store.commit("openLayerModal", {
        title: contents.createOrUpdateBlackListLayerTitle,
        props: {
          api: this.currentApi,
          submitButtonLabel: policy
            ? contents.updateButtonLabel
            : contents.addButtonLabel,
          blackListPolicy: policy,
        },
        component: CreateOrUpdateBlackListRouteModal,
      });
    },
    async deletePolicy(policy) {
      this.$store.commit("openLayerModal", {
        title: contents.deleteBlackListLayerTitle,
        props: {
          blackListPolicy: policy,
        },
        component: DeleteBlackListRouteModal,
      });
    },
  },
};
</script>

<style lang="scss">
.black-list-routes__subtitle {
  margin-bottom: var(--base-spacing);
  color: var(--color-m-text-minor);
}

.black-list-routes__action {
  width: 100%;
}

.black-list-routes__content {
  width: 100%;
  margin-bottom: var(--base-spacing);
}

.black-list-routes__table-actions-column {
  width: 7rem;
}
</style>
