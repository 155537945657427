<template>
  <CardFooter v-if="hasItems" class="card-footer-api"
    ><Cluster><slot /></Cluster
  ></CardFooter>
</template>

<script lang="ts">
import Cluster from "@/commons/components/Cluster.vue";

import CardFooter from "./CardFooter.vue";

export default {
  name: "CardFooterApi",
  components: {
    CardFooter,
    Cluster,
  },
  computed: {
    hasItems() {
      return this.$slots && this.$slots.default;
    },
  },
};
</script>

<style lang="scss">
.card-footer-api {
  --cluster-layout-glutter-top: 0;
  --chip-bg-color: var(--color-danger);
  --chip-height: var(--size-200);
  --chip-text: var(--size-200);
  border-bottom-right-radius: 0.5rem;

  border-bottom-left-radius: 0.5rem;
}
</style>
