export function isJsonString(data: string): boolean {
  return Boolean(data?.startsWith(`{`));
}

export function toDownloadableHrefContent(data: string): string {
  return "data:application/octet-stream," + encodeURIComponent(data);
}

export function downloadBlobData(blobData: Blob, filename: string) {
  const href = URL.createObjectURL(blobData);
  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}
