import {
  ValidationArguments,
  ValidatorConstraint,
  ValidatorConstraintInterface,
} from "class-validator";

import { ECustomProxyHeaderType } from "@/commons/domain/models/custom-proxy-header";
import { CustomProxyHeaderForm } from "@/manager/domain/forms/custom-proxy-header-form";

const HTTP_POLICY_NAME_REGEXP_PART = "[\\p{Alpha}\\p{digit}\\-_]+(?<!-)";

const HTTP_REQUEST_HEADER_TRANSFORMATION_REGEXP_PART = `\\$\\{request\\.headers\\.${HTTP_POLICY_NAME_REGEXP_PART}\\}`;
const HTTP_REQUEST_HEADER_REGEXP_PART = `(${HTTP_REQUEST_HEADER_TRANSFORMATION_REGEXP_PART}|${HTTP_POLICY_NAME_REGEXP_PART})`;

const HTTP_RESPONSE_HEADER_REGEXP_PART = HTTP_POLICY_NAME_REGEXP_PART;

const HTTP_REQUEST_HEADER_REGEXP = new RegExp(
  `^${HTTP_REQUEST_HEADER_REGEXP_PART}$`,
  "u",
);
const HTTP_RESPONSE_HEADER_REGEXP = new RegExp(
  `^${HTTP_RESPONSE_HEADER_REGEXP_PART}$`,
  "u",
);

export const MAX_CUSTOM_PROXY_HEADER_NAME_LENGTH = 256;
export const MAX_CUSTOM_PROXY_HEADER_VALUE_LENGTH = 5000;

@ValidatorConstraint()
export class IsValidCustomProxyHeaderNameValidatorConstraint
  implements ValidatorConstraintInterface
{
  validate(value: string, validationArguments?: ValidationArguments) {
    const form = validationArguments.object as CustomProxyHeaderForm;

    return form.type === ECustomProxyHeaderType.REQUEST
      ? HTTP_REQUEST_HEADER_REGEXP.test(value)
      : HTTP_RESPONSE_HEADER_REGEXP.test(value);
  }

  defaultMessage(validationArguments?: ValidationArguments): string {
    const form = validationArguments.object as CustomProxyHeaderForm;

    return form.type === ECustomProxyHeaderType.REQUEST
      ? "Your request header key must be alphanumeric or match the following format: ${request.headers.XXX}"
      : "Your response header key must be alphanumeric";
  }
}
