<script lang="ts" setup>
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import { computed, PropType } from "vue";
import { useStore } from "vuex";

import { Application } from "@/commons/domain/models/application";

import contents from "@/dashboard/contents/reporting-link-button";

const props = defineProps({
  application: {
    type: Object as PropType<Application>,
    required: true,
  },
});

const store = useStore();

const userIsInternal = computed(() => store.getters["userIsInternal"]);

const reportingLink = computed(() => {
  return props.application?.reportingUrl;
});
</script>

<template>
  <MButton
    v-if="userIsInternal"
    data-cy="application-reporting-button"
    class="reporting-link-button"
    :label="contents.reportingLinkLabel"
    size="s"
    icon="DisplayExternalLink24"
    iconPosition="right"
    theme="bordered-neutral"
    :href="reportingLink"
    target="_blank"
  />
</template>
