import { getApiRepository } from "@/commons/repositories/libs/get-api-repository";
import { getRequestSummaryRepository } from "@/commons/repositories/libs/get-request-summary-repository";

import { RequestSummary } from "@/commons/domain/models/request-summary";
import { RequestAuthTypeChangeParams } from "@/commons/domain/repositories/api-repository";

import { ERequestType } from "@/commons/store/types";

export async function fetchPendingNewContractRequest(
  contractId: string,
): Promise<RequestSummary | undefined> {
  return await getRequestSummaryRepository().findPendingRequestOfType(
    contractId,
    ERequestType.NEW_CONTRACT,
  );
}

export async function requestAuthTypeChange(
  params: RequestAuthTypeChangeParams,
): Promise<void> {
  await getApiRepository().requestAuthTypeChange(params);
}

export async function apiHasAnyActiveSubscription(
  apiId: string,
): Promise<boolean> {
  return await getApiRepository().hasAnyActiveSubscription(apiId);
}

export async function apiHasAnyActiveSubscriptionIncludingSandboxes(
  apiId: string,
): Promise<boolean> {
  return await getApiRepository().hasAnyActiveSubscription(apiId, true);
}
