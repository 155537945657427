<script setup lang="ts">
import { computed, PropType } from "vue";

import ContractApiKeyDisplay from "@/dashboard/views/ContractsList/contractCard/tokenDisplay/ContractApiKeyDisplay.vue";

import { getTokenActionsMenu } from "@/dashboard/utils/token-action-utils";

import { Contract } from "@/commons/domain/models/contract";

import contents from "@/dashboard/contents/contract-api-key-display";

const props = defineProps({
  contract: {
    type: Object as PropType<Contract>,
    required: true,
  },
});

const emit = defineEmits(["openActionsMenu"]);

const tokens = computed(() => {
  return Object.values(props.contract?.tokens);
});

const getLabel = (index: number) => {
  if (index === 0 && tokens.value.length === 1) {
    return contents.currentApiKeyLabel;
  }
  return isLastItem(index, tokens.value)
    ? contents.newApiKeyLabel
    : contents.oldApiKeyLabel;
};

const isLastItem = (index: number, array: any[]) => {
  return index === array.length - 1;
};
</script>

<template>
  <div class="contract-api-keys">
    <ContractApiKeyDisplay
      v-for="(token, index) in tokens"
      :key="token.id"
      :token="token"
      :label="getLabel(index)"
      :showActionsButton="getTokenActionsMenu(token)?.length > 0"
      @openActionsMenu="emit('openActionsMenu', token)"
    />
  </div>
</template>

<style lang="scss">
.contract-api-keys {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
</style>
