import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-9cc2477a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "reporting-application-filters" }
const _hoisted_2 = { class: "reporting-application-filters__filters" }
const _hoisted_3 = { class: "reporting-application-filters__date-range" }
const _hoisted_4 = { class: "reporting-application-filters__input-groups" }
const _hoisted_5 = { class: "input-group" }
const _hoisted_6 = { class: "input-group" }
const _hoisted_7 = { class: "input-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReportingDatePicker = _resolveComponent("ReportingDatePicker")!
  const _component_ReportingSelectFilter = _resolveComponent("ReportingSelectFilter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_ReportingDatePicker, {
          onRangeChange: 
            ({ start, end }) => ($options.selectedDateRange = { from: start, to: end })
          ,
          startDate: $options.selectedDateRange.from,
          endDate: $options.selectedDateRange.to,
          loading: $options.isFetchingReporting
        }, null, 8, ["onRangeChange", "startDate", "endDate", "loading"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("label", null, _toDisplayString($data.contents.labelApis), 1),
          _createVNode(_component_ReportingSelectFilter, {
            modelValue: $options.selectedApis,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($options.selectedApis) = $event)),
            placeholder: "Apis",
            onFilterClose: () => null,
            options: $options.filterOptions.apis,
            loading: $options.isFetchingReporting,
            maxLabelDisplayLength: 3
          }, null, 8, ["modelValue", "options", "loading"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("label", null, _toDisplayString($data.contents.labelStatus), 1),
          _createVNode(_component_ReportingSelectFilter, {
            modelValue: $options.selectedStatus,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($options.selectedStatus) = $event)),
            placeholder: "Status",
            onFilterClose: () => null,
            searchable: false,
            options: $options.filterOptions.status,
            loading: $options.isFetchingReporting,
            maxLabelDisplayLength: 10
          }, null, 8, ["modelValue", "options", "loading"])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("label", null, _toDisplayString($data.contents.labelMethods), 1),
          _createVNode(_component_ReportingSelectFilter, {
            modelValue: $options.selectedMethods,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($options.selectedMethods) = $event)),
            placeholder: "Methods",
            onFilterClose: () => null,
            searchable: false,
            options: $options.filterOptions.methods,
            loading: $options.isFetchingReporting,
            maxLabelDisplayLength: 10
          }, null, 8, ["modelValue", "options", "loading"])
        ])
      ])
    ])
  ]))
}