import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "contract-oauth-flow-selection-modal__description-section" }
const _hoisted_2 = { class: "contract-oauth-flow-selection-modal__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StepperApiSubscription = _resolveComponent("StepperApiSubscription")!
  const _component_Markdown = _resolveComponent("Markdown")!
  const _component_ViewHelpButton = _resolveComponent("ViewHelpButton")!
  const _component_OauthFlowOptionCard = _resolveComponent("OauthFlowOptionCard")!
  const _component_LayerModalFormConfirm = _resolveComponent("LayerModalFormConfirm")!

  return (_openBlock(), _createBlock(_component_LayerModalFormConfirm, {
    class: "contract-oauth-flow-selection-modal",
    confirmBtnTheme: "solid",
    disabledConfirmBtn: $data.selectedFlow == undefined,
    confirmBtnLabel: $options.confirmBtnLabel,
    onSubmit: $options.submit,
    onClose: $options.close
  }, {
    default: _withCtx(() => [
      ($data.selectedFlow != undefined)
        ? (_openBlock(), _createBlock(_component_StepperApiSubscription, {
            key: 0,
            stepperDescription: $data.contents.stepperDescription,
            stepperSteps: $options.stepperSteps
          }, null, 8, ["stepperDescription", "stepperSteps"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h4", _hoisted_2, _toDisplayString($data.contents.title), 1),
        _createVNode(_component_Markdown, {
          class: "contract-oauth-flow-selection-modal__description",
          content: $data.contents.description
        }, null, 8, ["content"])
      ]),
      _createVNode(_component_ViewHelpButton, {
        class: "contract-oauth-flow-selection-modal__view-help-button",
        documentationAnchor: "#oauth-flows"
      }),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.oauthFlowItems, (flowItem, index) => {
        return (_openBlock(), _createBlock(_component_OauthFlowOptionCard, {
          key: index,
          flowItem: flowItem,
          checkboxType: "radio",
          modelValue: $data.selectedFlow === flowItem.value,
          "onUpdate:modelValue": ($event: any) => ($data.selectedFlow = flowItem.value)
        }, null, 8, ["flowItem", "modelValue", "onUpdate:modelValue"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["disabledConfirmBtn", "confirmBtnLabel", "onSubmit", "onClose"]))
}