<template>
  <LayerModalForm
    class="create-group create-group__form"
    @submit="onFormSubmit"
  >
    <template #fields>
      <h4>{{ contents.title }}</h4>
      <MNotification type="danger" :title="contents.notificationTitle">
        {{ contents.notificationMessage }}
      </MNotification>
      <NotificationWarningMCCGroup v-if="userIsInternal" />
      <MField
        id="create-group__group-name"
        label="Group name"
        :isInvalid="isInvalidGroupName"
        :errorMessage="form.firstError('name')"
      >
        <MTextInput
          v-model="form.name"
          :placeholder="contents.groupNamePlaceholder"
          :isInvalid="isInvalidGroupName"
        />
      </MField>

      <MField
        id="create-group__group-description"
        label="Group description"
        :isInvalid="isInvalidGroupDescription"
        :errorMessage="form.firstError('description')"
      >
        <MTextArea
          v-model="form.description"
          placeholder="Group description"
          :isInvalid="isInvalidGroupDescription"
        />
      </MField>
    </template>
    <template #formFooter>
      <MButton
        type="submit"
        :disabled="!!form.errors.length"
        label="Create group"
      />
    </template>
  </LayerModalForm>
</template>

<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import MField from "@mozaic-ds/vue-3/src/components/field/MField.vue";
import MNotification from "@mozaic-ds/vue-3/src/components/notification/MNotification.vue";
import MTextArea from "@mozaic-ds/vue-3/src/components/textarea/MTextArea.vue";
import MTextInput from "@mozaic-ds/vue-3/src/components/textinput/MTextInput.vue";

import NotificationWarningMCCGroup from "@/commons/components/ManageGroups/NotificationWarningMCCGroup.vue";
import LayerModalForm from "@/commons/components/form/LayerModalForm.vue";

import { CreateGroupForm } from "@/commons/forms/create-group-form";

import contents from "@/commons/contents/create-group";

export default {
  components: {
    NotificationWarningMCCGroup,
    LayerModalForm,
    MNotification,
    MField,
    MTextInput,
    MTextArea,
    MButton,
  },
  emits: ["groupCreated"],
  data() {
    return {
      contents,
      form: CreateGroupForm.create(),
    };
  },
  computed: {
    user() {
      return this.$store.getters["user"];
    },
    userIsInternal() {
      return this.$store.getters["userIsInternal"];
    },
    isInvalidGroupName() {
      return this.form.firstError("name") != null;
    },
    isInvalidGroupDescription() {
      return this.form.firstError("description") != null;
    },
  },
  async mounted() {
    await this.form.init();
  },
  methods: {
    async onFormSubmit() {
      await this.form.validate();

      if (!this.form.errors.length) {
        const { name, description } = this.form.data();

        await this.$store.dispatch("createUserGroup", {
          name,
          description,
          showSuccessNotification: false,
        });
        this.$emit("groupCreated");
      }
    },
  },
};
</script>
