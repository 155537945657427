import { HttpRepository } from "@/commons/repositories/libs/http-repository";

import { Inject } from "@/commons/domain/di/inject";
import { Injectable } from "@/commons/domain/di/injectable";
import {
  GroupFetchParams,
  GroupWithUsersAclsRepository,
} from "@/commons/domain/repositories/group-with-users-acls-repository";
import { UserRepository } from "@/commons/domain/repositories/user-repository";
import { CreateGroupDto } from "@/commons/dtos/create-group-dto";
import { CreatedResourceDto } from "@/commons/dtos/created-resource-dto";
import { GroupDto } from "@/commons/dtos/group-dto";
import { PagedResourceDto } from "@/commons/dtos/paged-resource-dto";
import { GroupWithUsersAclsMapper } from "@/commons/mappers/group-with-users-acls-mapper";

import { TYPES } from "@/types";

@Injectable()
export class GroupWithUsersAclsHttpRepository
  extends HttpRepository
  implements GroupWithUsersAclsRepository
{
  @Inject(TYPES.USER_REPOSITORY)
  private userRepository: UserRepository;

  public async findById(groupId: string) {
    const response = await this.requestHandler.get<GroupDto>(
      `/groups/${groupId}`,
    );
    return GroupWithUsersAclsMapper.toGroupDomain(response.data);
  }

  public async find(params: GroupFetchParams) {
    const response = await this.requestHandler.get<PagedResourceDto<GroupDto>>(
      `/groups`,
      { params },
    );
    return GroupWithUsersAclsMapper.toPagedDomain(response.data);
  }

  public async cancelAndFind(
    params: GroupFetchParams,
    cancellationId?: string,
  ) {
    const response = await this.requestHandler.cancelAndGet<
      PagedResourceDto<GroupDto>
    >(`/groups`, { params }, cancellationId);

    return GroupWithUsersAclsMapper.toPagedListDomain(response.data);
  }

  public async create(
    name: string,
    description: string,
    memberships: Array<{ userId: string; roleId: string }>,
  ) {
    const payload: CreateGroupDto = {
      name,
      description,
      memberships,
    };
    const response = await this.requestHandler.post<CreatedResourceDto>(
      `/groups`,
      payload,
    );
    return this.findById(response.data.id);
  }

  public async update(groupId: string, name: string, description: string) {
    const response = await this.requestHandler.patch<GroupDto>(
      `/groups/${groupId}`,
      {
        name,
        description,
      },
    );
    return GroupWithUsersAclsMapper.toGroupDomain(response.data);
  }

  public async addUserAcl(groupId: string, userId: string, roleId: string) {
    await this.requestHandler.patch(`/groups/${groupId}/members`, {
      memberships: [
        {
          userId: userId,
          roleId: roleId,
        },
      ],
    });
    return this.findById(groupId);
  }

  public async updateUserAcl(groupId: string, userId: string, roleId: string) {
    await this.requestHandler.patch(`/groups/${groupId}/members/${userId}`, {
      roleId,
    });
    return this.findById(groupId);
  }

  public async removeUserAcl(groupId: string, userId: string) {
    await this.requestHandler.delete(`/groups/${groupId}/members/${userId}`);
    return this.findById(groupId);
  }

  public async remove(groupId: string) {
    await this.requestHandler.delete(`/groups/${groupId}`);
  }

  public async createAndFindMe(
    name: string,
    description: string,
    administratorId: string,
    roleId: string,
  ) {
    const payload: CreateGroupDto = {
      name,
      description,
      memberships: [],
    };

    if (administratorId) {
      payload.memberships.push({
        userId: administratorId,
        roleId,
      });
    }

    await this.requestHandler.post<GroupDto>(`/groups`, payload);
    return this.userRepository.findMe();
  }

  public async updateAndFindMe(
    groupId: string,
    name: string,
    description: string,
  ) {
    await this.requestHandler.patch(`/groups/${groupId}`, {
      name,
      description,
    });
    return this.userRepository.findMe();
  }

  public async removeAndFindMe(groupId: string) {
    await this.requestHandler.delete(`/groups/${groupId}`);
    return this.userRepository.findMe(); // For updating CurrentUser.groups
  }
}
