<template>
  <Layout class="settings">
    <template #secondaryNavbar>
      <template v-if="isLoading || currentApi === undefined">
        <SecondaryNavbarLoading />
      </template>
      <template v-else>
        <ApiSettingsSecondaryNavbar :api="currentApi" />
      </template>
    </template>

    <template #sideMenu>
      <SettingsMenu :menu-id="menuId" />
    </template>

    <Stack>
      <NotificationForKongSynchronization
        v-if="
          currentApi && displayKongInformation && hasApiStatusAsPendingDeploy
        "
        configurationType="Api"
      />

      <ApiAlerts v-if="!isLoading && checkList.length" :checkList="checkList" />

      <router-view v-if="currentApi" />
    </Stack>
  </Layout>
</template>

<script lang="ts">
import Layout from "@/commons/components/Layout.vue";
import SecondaryNavbarLoading from "@/commons/components/Navbar/SecondaryNavbarLoading.vue";
import Stack from "@/commons/components/Stack.vue";
import NotificationForKongSynchronization from "@/dashboard/components/NotificationForKongSynchronization.vue";
import ApiSettingsSecondaryNavbar from "@/manager/components/ApiSettingsSecondaryNavbar.vue";
import SettingsMenu from "@/manager/views/SettingsMenu/SettingsMenu.vue";

import ApiAlerts from "./ApiAlerts.vue";

import { getApiLabelWithVersion } from "@/commons/libs/utils/apiUtils";

import { EApiPublicationStatus } from "@/commons/store/types";

export default {
  name: "Settings",
  components: {
    NotificationForKongSynchronization,
    SecondaryNavbarLoading,
    Layout,
    SettingsMenu,
    ApiSettingsSecondaryNavbar,
    ApiAlerts,
    Stack,
  },
  props: {
    menuId: {
      default: "",
      type: String,
    },
  },
  computed: {
    hasApiStatusAsPendingDeploy() {
      return (
        this.currentApi?.publicationStatus ===
        EApiPublicationStatus.NOT_SYNCHRONIZED
      );
    },
    currentApi() {
      return this.$store.getters["currentApi"];
    },
    apiTitle() {
      return getApiLabelWithVersion(this.currentApi);
    },
    apiProduct() {
      return this.currentApi?.product;
    },
    isLoading() {
      return this.$store.getters["isLoading"];
    },
    checkList() {
      return this.$store.getters["checkList"].filter(
        (check) => check.menuPath === this.menuId && !check.checked,
      );
    },
    frontendConfig() {
      return this.$store.getters["config/frontendConfig"];
    },
    displayKongInformation() {
      return this.frontendConfig.displayKongInformation;
    },
  },
  async mounted() {
    const apiId = this.$route.params.id;
    await this.$store.dispatch("loadApi", {
      apiId,
    });
    await this.$store.dispatch("deprecatableZones", {
      apiId,
      params: {
        publishable: false,
        deprecatable: true,
      },
    });
  },
};
</script>
