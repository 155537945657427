<script setup lang="ts">
import isEqual from "lodash-es/isEqual";
import { computed, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import RedirectUriSettingsCard from "@/dashboard/views/AppDetails/RedirectUriSettingsCard.vue";
import OAuthContracts from "@/dashboard/views/AppDetailsContracts/OAuthContracts.vue";

import { Contract } from "@/commons/domain/models/contract";
import { ContractFilters } from "@/dashboard/domain/contract-filters";

import { EApplicationTab } from "@/commons/store/types";

defineProps({
  currentTab: {
    type: String,
    default: "",
  },
});

const emit = defineEmits([
  "selectMe",
  "displayMe",
  "hideMe",
  "newClientIdCreated",
]);

const store = useStore();
const route = useRoute();

const application = computed(() => store.getters["currentApplication"]);

const applicationId = computed(() => {
  return route.params?.id;
});

const oauthACFContracts = computed(
  (): Contract[] => store.getters["currentApplicationOAuthACFContracts"],
);

const oauthACFContractsCount = computed((): number => {
  return store.getters["oauthACFContractsTotalCount"];
});

const isLoadingApplicationOAuthACFContracts = computed(() =>
  store.getters["isLoadingProperty"]("oauthACFApplicationContracts"),
);

const oauthACFCreatedContract = computed(
  (): Contract => store.getters["lastCreatedOAuthACFContract"],
);

let lastCreatedOAuthACFContract = undefined as Contract | undefined;

watch(oauthACFCreatedContract, () => {
  if (oauthACFCreatedContract.value != undefined) {
    lastCreatedOAuthACFContract = oauthACFCreatedContract.value;
  }
});

watch(isLoadingApplicationOAuthACFContracts, () => {
  if (!isLoadingApplicationOAuthACFContracts.value) {
    if (oauthACFContractsCount.value > 0) {
      emit("displayMe", oauthACFContractsCount.value);
    } else {
      emit("hideMe");
    }
    if (oauthACFCreatedContract.value != undefined) {
      emit("selectMe");
      store.dispatch("resetLastOAuthACFContract");
    }
  }
});

const loadApplicationOAuthACFContracts = async () => {
  if (contractsMetrics?.value?.oauthACF > 0) {
    await store.dispatch("loadApplicationOAuthACFContracts", {
      ...contractFilters.value,
      applicationId: applicationId.value,
    });
  }
};

const loadACFTokenOfCurrentApplication = async () => {
  await store.dispatch("loadAcfTokenOfCurrentApplication", {
    applicationId: applicationId.value,
  });
};

const contractsMetrics = computed(() => {
  return application?.value?.metrics?.contracts;
});

const contractFilters = computed((): ContractFilters => {
  const query = route.query;
  return {
    deprecated: query.deprecated === "true",
    disabled: query.disabled === "true",
    aboutToExpire: query.aboutToExpire === "true",
    expired: query.expired === "true",
    apiName: (query.term as string) || "",
  };
});

watch(contractFilters, async (newValue, prevValue) => {
  if (isEqual(newValue, prevValue) === false) {
    await loadApplicationOAuthACFContracts();
  }
});

onMounted(async () => {
  await loadACFContracts();
});

const loadCurrentApplication = async () => {
  await store.dispatch("loadCurrentApplication", {
    appId: applicationId.value,
  });
};

const loadRedirectUrisOfCurrentApplication = async () => {
  await store.dispatch("loadRedirectUrisOfCurrentApplication");
};

const onContractChange = async () => {
  await loadACFContracts();
  await loadCurrentApplication();
};

const loadACFContracts = async () => {
  await loadApplicationOAuthACFContracts();
  await loadACFTokenOfCurrentApplication();
  await loadRedirectUrisOfCurrentApplication();
};

/**
 * Detect and handle new client ID creation
 */

const distinctClientIds = ref([] as string[]);

const onClientIdsChanged = (newClientIds: string[]) => {
  if (
    lastCreatedOAuthACFContract != undefined &&
    newClientIds.length > distinctClientIds.value.length
  ) {
    emit("newClientIdCreated");
    lastCreatedOAuthACFContract = undefined;
  }
  distinctClientIds.value = newClientIds;
};
</script>

<template>
  <div class="oauth-acf-contracts">
    <template v-if="currentTab === EApplicationTab.OAUTH_ACF">
      <OAuthContracts
        :contracts="oauthACFContracts"
        :isLoading="isLoadingApplicationOAuthACFContracts"
        @contractChanged="onContractChange"
        @clientIdsChanged="onClientIdsChanged"
      />
      <RedirectUriSettingsCard
        v-if="
          !isLoadingApplicationOAuthACFContracts &&
          oauthACFContracts?.length > 0
        "
      />
    </template>
  </div>
</template>

<style lang="scss">
.oauth-acf-contracts {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
</style>
