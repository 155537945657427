import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "consumers-api"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "consumers-api__title-and-action-header" }
const _hoisted_4 = { class: "consumers-api__action-header" }
const _hoisted_5 = { class: "consumers-api__tab-content" }
const _hoisted_6 = { class: "consumers-api__tab-content-header" }
const _hoisted_7 = {
  key: 1,
  class: "consumers-api__no-consumer-content"
}
const _hoisted_8 = { class: "consumers-api__no-consumer-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MButton = _resolveComponent("MButton")!
  const _component_DvpTabs = _resolveComponent("DvpTabs")!
  const _component_MTextInput = _resolveComponent("MTextInput")!
  const _component_ConsumersApiTable = _resolveComponent("ConsumersApiTable")!
  const _component_CardContainer = _resolveComponent("CardContainer")!
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (!$options.isDoingInitialLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        ($data.hasConsumer)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("h3", null, _toDisplayString($data.contents.consumersTitle), 1),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_MButton, {
                    icon: "DownloadWeb24",
                    label: $data.contents.exportConsumers,
                    theme: "bordered-neutral",
                    size: "s",
                    onClick: $options.exportConsumers
                  }, null, 8, ["label", "onClick"]),
                  _createVNode(_component_MButton, {
                    class: "consumers-api__send-email-button",
                    icon: "ContactMail24",
                    label: $data.contents.sendAnEmailToAllUsers,
                    size: "s",
                    onClick: $options.openSendEmailModal
                  }, null, 8, ["label", "onClick"])
                ])
              ]),
              _createVNode(_component_CardContainer, null, {
                default: _withCtx(() => [
                  _createVNode(_component_DvpTabs, {
                    modelValue: $data.currentTab,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.currentTab) = $event)),
                    tabs: $options.tabItems,
                    align: "full"
                  }, null, 8, ["modelValue", "tabs"]),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_MTextInput, {
                        "data-cy": "search-consumer-application",
                        class: "consumers-api__search-application",
                        type: "search",
                        icon: "DisplaySearch32",
                        size: "s",
                        modelValue: $data.searchTerm,
                        placeholder: $data.contents.searchApplicationPlaceholder,
                        "onUpdate:modelValue": $options.onSearchInputChange
                      }, null, 8, ["modelValue", "placeholder", "onUpdate:modelValue"])
                    ]),
                    _createVNode(_component_ConsumersApiTable, {
                      pagedContracts: $options.currentContracts,
                      currentTab: $data.currentTab,
                      "onUpdate:currentPage": _cache[1] || (_cache[1] = ($event: any) => ($options.loadPageForCurrentSearch($event)))
                    }, null, 8, ["pagedContracts", "currentTab"])
                  ])
                ]),
                _: 1
              })
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("h4", _hoisted_8, _toDisplayString($data.contents.noApplicationAreUsing) + " " + _toDisplayString($options.currentApi.name), 1),
              _createVNode(_component_RouterLink, { to: { name: 'applicationCreate' } }, {
                default: _withCtx(() => [
                  _createVNode(_component_MButton, {
                    label: $data.contents.createAnAppToUseApi,
                    size: "l",
                    theme: "solid"
                  }, null, 8, ["label"])
                ]),
                _: 1
              })
            ]))
      ]))
    : _createCommentVNode("", true)
}