import { Scope } from "@/commons/domain/models/scope";

/**
 * Current implementation authorizes only ONE scope selection at once.
 */
export const getSelectedScopeIndex = (
  item: Scope,
  selectedScopes: Scope[],
): number => {
  return selectedScopes.indexOf(item);
};

export const scopeIsSelected = (
  item: Scope,
  selectedScopes: Scope[],
): boolean => {
  return selectedScopes.find((i) => i.name === item.name) != undefined;
};

export const scopeIsDisabled = (
  item: Scope,
  selectedScopes: Scope[],
): boolean => {
  // Will be updated to multiselect later in OAuth Scopes Epic development, see DVP-3267
  const found = selectedScopes.find((i) => i.name === item.name);

  return selectedScopes.length == 1 && found == undefined;
};

export const isAtLeastOneSelectedScopeIsVisible = (
  visibleScopes: Scope[],
  selectedScopes: Scope[],
): boolean => {
  return visibleScopes.some((scope) => scopeIsSelected(scope, selectedScopes));
};

export interface ScopeCreation {
  element: string;
  subElement: string;
  businessUnit: string;
  action: string;
}

export const splitPredefinedScope = (
  predefinedScope: string,
  businessUnits: string[],
  actions: string[],
): ScopeCreation => {
  // scope regex: (element).(subElement):(businessUnit):(action)

  const newScope: ScopeCreation = {
    element: undefined,
    subElement: undefined,
    businessUnit: undefined,
    action: undefined,
  };

  if (predefinedScope && predefinedScope !== "") {
    const buSeparator = predefinedScope.indexOf(":");
    const actionSeparator = predefinedScope.lastIndexOf(":");

    if (buSeparator > 0 && actionSeparator > 0) {
      const element = predefinedScope.substring(0, buSeparator);
      splitScopeElement(element, newScope);
      setAction(
        predefinedScope.substring(actionSeparator + 1),
        actions,
        newScope,
      );
      if (actionSeparator != buSeparator) {
        setBusinessUnit(
          predefinedScope.substring(buSeparator + 1, actionSeparator),
          businessUnits,
          newScope,
        );
      } else {
        // buSeparator === actionSeparator
        // It could be action OR business unit
        if (newScope.action === undefined) {
          setBusinessUnit(
            predefinedScope.substring(buSeparator + 1),
            businessUnits,
            newScope,
          );
        }
      }
    } else {
      splitScopeElement(predefinedScope, newScope);
    }
  }
  return newScope;
};

function splitScopeElement(value: string, scope: ScopeCreation) {
  const element = value;
  const elementSeparator = element.indexOf(".");
  if (elementSeparator > 0) {
    scope.element = element.substring(0, elementSeparator);
    scope.subElement = element.substring(elementSeparator + 1);
  } else {
    scope.element = element;
  }
}

function setBusinessUnit(
  candidate: string,
  businessUnits: string[],
  scope: ScopeCreation,
) {
  if (businessUnits.includes(candidate)) {
    scope.businessUnit = candidate;
  }
}

function setAction(candidate: string, actions: string[], scope: ScopeCreation) {
  if (actions.includes(candidate)) {
    scope.action = candidate;
  }
}

export const listContainsAdminScope = (scopes: string[]): boolean => {
  return scopes?.some((scope) => scope.endsWith(":admin"));
};
