<template>
  <div class="slide" :data-anchor="anchor" :style="imageStyles">
    <slot />
    <div class="slide__scroll-down">
      <img :src="require('@/commons/assets/images/scroll-mouse.svg')" />
      <span>{{ contents.scrollDownButton }}</span>
    </div>
  </div>
</template>

<script lang="ts">
const contents = {
  scrollDownButton: "Scroll to discover",
};

export default {
  name: "Slide",
  props: {
    anchor: {
      type: String,
      required: true,
    },
    backgroundSrc: {
      type: String,
      default: null,
    },
  },
  data() {
    return { contents };
  },
  computed: {
    imageStyles() {
      const imageStyles: any = {};
      if (this.backgroundSrc) {
        imageStyles.backgroundImage = `url("${this.backgroundSrc}")`;
        imageStyles.backgroundRepeat = "no-repeat";
        imageStyles.backgroundSize = "cover";
      }
      return imageStyles;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/commons/assets/styles/vars";

.slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-top: 6rem;
  margin: 0 auto;
  overflow: hidden;
}

.slide__scroll-down {
  position: fixed;
  bottom: 3vh;
  display: none;
  width: 8rem;
  margin: 0 auto;
  text-align: center;

  & * {
    margin: 0 auto;
  }
}

.slide--first.slide--active .slide__scroll-down {
  display: block;
}

@media only screen and (max-width: map-get($breakpoints, tablet-portrait)) {
  .slide {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: map-get($breakpoints, mobile)) {
  .slide {
    height: auto;
    padding-top: 4rem;
  }

  .slide--first.slide--active .slide__scroll-down {
    display: none;
  }
}
</style>
