import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "message-confirm-modal__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Markdown = _resolveComponent("Markdown")!
  const _component_ConfirmModalBody = _resolveComponent("ConfirmModalBody")!

  return (_openBlock(), _createBlock(_component_ConfirmModalBody, _mergeProps({ class: "message-confirm-modal" }, _ctx.$attrs, {
    onOnSubmit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onSubmit'))),
    onOnClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('onClose')))
  }), {
    default: _withCtx(() => [
      _createElementVNode("h5", _hoisted_1, _toDisplayString($props.title), 1),
      _createVNode(_component_Markdown, {
        class: "message-confirm-modal__message",
        content: $props.message
      }, null, 8, ["content"])
    ]),
    _: 1
  }, 16))
}