<template>
  <div class="editable-route-url">
    <div v-if="!isEditing" class="editable-route-url__labeling">
      <span class="editable-route-url__url">{{ route.url }}</span>
      <ButtonCopy :content="route.url" />
      <ButtonEdit v-if="canEdit" @click="editUrl" />
    </div>
    <div v-else class="editable-route-url__editing">
      <div class="editable-route-url__input-with-error">
        <MTextInput
          v-model="urlForm.url"
          :isInvalid="urlForm.firstError('url') != undefined"
        />
        <span v-if="urlForm.firstError('url') != undefined" class="input-error">
          {{ urlForm.firstError("url") }}
        </span>
      </div>
      <CancelAndSubmitButtons
        class="editable-route-url__cancel-and-submit-buttons"
        @cancel="cancelEdition"
        @submit="updateUrl"
      />
    </div>
  </div>
</template>

<script lang="ts">
import MTextInput from "@mozaic-ds/vue-3/src/components/textinput/MTextInput.vue";
import { PropType } from "vue";

import ButtonCopy from "@/commons/components/Buttons/ButtonCopy.vue";
import ButtonEdit from "@/commons/components/Buttons/ButtonEdit.vue";
import CancelAndSubmitButtons from "@/commons/components/Buttons/CancelAndSubmitButtons.vue";

import { Form } from "@/commons/domain/forms/form";
import { Route } from "@/commons/domain/models/route";
import { UrlForm } from "@/manager/domain/forms/url-form";

export default {
  components: {
    CancelAndSubmitButtons,
    ButtonCopy,
    ButtonEdit,
    MTextInput,
  },
  props: {
    canEdit: {
      type: Boolean,
      required: true,
    },
    route: {
      type: Object as PropType<Route>,
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    apiId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      urlForm: undefined as Form<UrlForm>,
      formDataBeforeEdition: undefined as UrlForm,
      isEditing: false,
    };
  },
  async created(): Promise<void> {
    this.urlForm = UrlForm.create();
    await this.urlForm.init({ url: this.route.url });
  },
  methods: {
    async updateUrl(): Promise<void> {
      await this.urlForm.validate();

      if (this.urlForm.errors.length === 0) {
        const { url } = this.urlForm.data();

        await this.$store.dispatch("updateRoute", {
          apiId: this.apiId,
          routeId: this.route.id,
          url,
        });

        this.isEditing = false;
      }
    },
    async cancelEdition(): Promise<void> {
      await this.urlForm.init(this.formDataBeforeEdition);
      this.isEditing = false;
    },
    editUrl(): void {
      this.formDataBeforeEdition = this.urlForm.data();
      this.isEditing = true;
    },
  },
};
</script>

<style lang="scss">
.editable-route-url__labeling {
  display: flex;
  align-items: center;
}

.editable-route-url__url {
  flex: 1;
}

.editable-route-url__editing {
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
}

.editable-route-url__input-with-error {
  flex: 1;
}

.editable-route-url__cancel-and-submit-buttons {
  margin-top: 0.5rem;
}
</style>
