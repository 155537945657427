import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconArrowLeft = _resolveComponent("IconArrowLeft")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_Box = _resolveComponent("Box")!

  return (_openBlock(), _createBlock(_component_Box, { class: "navigate-before-button" }, {
    default: _withCtx(() => [
      _createVNode(_component_RouterLink, {
        "data-cy": "navigate-before-button",
        class: "navigate-before-button__link",
        to: $props.previousPageRoute
      }, {
        default: _withCtx(() => [
          _createVNode(_component_IconArrowLeft),
          _createElementVNode("span", null, _toDisplayString($props.title), 1)
        ]),
        _: 1
      }, 8, ["to"])
    ]),
    _: 1
  }))
}