import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-90b0144a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "routing-api"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MNotification = _resolveComponent("MNotification")!
  const _component_CreateTargetUrl = _resolveComponent("CreateTargetUrl")!
  const _component_UpdateOrRemoveTargetUrl = _resolveComponent("UpdateOrRemoveTargetUrl")!
  const _component_BlackListRoutes = _resolveComponent("BlackListRoutes")!

  return (!$options.isLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (!$options.isLoading && $options.validationError)
          ? (_openBlock(), _createBlock(_component_MNotification, {
              key: 0,
              type: "danger"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($options.validationError), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        ($options.apiHasPendingOtherGatewaysRequest)
          ? (_openBlock(), _createBlock(_component_MNotification, {
              key: 1,
              type: "warning"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($data.contents.alertMessagePendingOtherGateway), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createElementVNode("h4", null, _toDisplayString($data.contents.header), 1),
        (!$options.isLoading)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
              _createVNode(_component_CreateTargetUrl, {
                class: "routing-api__create",
                "is-disabled": $options.isSaving,
                "form-parent": $data.form,
                form: $data.form.newValue,
                "has-error": $options.hasCreationError
              }, null, 8, ["is-disabled", "form-parent", "form", "has-error"]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.form.values, (targetUrlForm, index) => {
                return (_openBlock(), _createBlock(_component_UpdateOrRemoveTargetUrl, {
                  key: `update-or-remove-route-${targetUrlForm.id}`,
                  class: "routing-api__update-or-remove",
                  form: targetUrlForm,
                  "form-parent": $data.form,
                  "position-in-list": index + 1,
                  "is-disabled": $options.isSaving
                }, null, 8, ["form", "form-parent", "position-in-list", "is-disabled"]))
              }), 128))
            ], 64))
          : _createCommentVNode("", true),
        _createVNode(_component_BlackListRoutes)
      ]))
    : _createCommentVNode("", true)
}