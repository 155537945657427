<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 25.78">
    <path
      d="M31.18,15.63a.6.6,0,0,0-.09-.13,4.2,4.2,0,0,0-.88-4.4A3.94,3.94,0,0,0,26.59,10,11.94,11.94,0,0,0,20,8a12.28,12.28,0,0,0-2.57.3,4.06,4.06,0,0,0-.63-.88,4,4,0,0,0-5.68,0,4.21,4.21,0,0,0-.58,5.05l.06.09a12,12,0,1,0,20.58,3.07Zm-2.41-3.14a2.23,2.23,0,0,1,.29,2.64L27.29,18l-1.62-2.81a2.23,2.23,0,0,1,.3-2.65,1.91,1.91,0,0,1,1.4-.59A1.94,1.94,0,0,1,28.77,12.49ZM12.56,8.81a2,2,0,0,1,2.8,0,2.23,2.23,0,0,1,.3,2.64l-1.78,2.83-1.61-2.82A2.23,2.23,0,0,1,12.56,8.81ZM20,30a10,10,0,0,1-8.32-15.54L13,16.72a1,1,0,0,0,.85.5h0a1,1,0,0,0,.85-.47l2.67-4.25v0A4.14,4.14,0,0,0,18,10.23a10.12,10.12,0,0,1,6.58.87h0a4.2,4.2,0,0,0-.57,5l2.43,4.26a1,1,0,0,0,.85.5h0a1,1,0,0,0,.84-.47l1.66-2.63A10,10,0,0,1,20,30Z"
      transform="translate(-8 -6.22)"
      style="fill: #020203"
    />
    <path
      d="M14,11.42a1.2,1.2,0,1,0-1.2-1.2A1.2,1.2,0,0,0,14,11.42Z"
      transform="translate(-8 -6.22)"
      style="fill: #020203"
    />
    <path
      d="M27.37,15.1a1.2,1.2,0,1,0-1.2-1.2A1.2,1.2,0,0,0,27.37,15.1Z"
      transform="translate(-8 -6.22)"
      style="fill: #020203"
    />
    <path
      d="M19.67,16.29a3.94,3.94,0,0,0-2.84,1.2,4.2,4.2,0,0,0-.57,5l2.43,4.25a1,1,0,0,0,.84.51h0a1,1,0,0,0,.85-.47l2.67-4.25v0a4.21,4.21,0,0,0-.56-5.06A4,4,0,0,0,19.67,16.29Zm1.7,5.22-1.78,2.83L18,21.52a2.25,2.25,0,0,1,.29-2.65,2,2,0,0,1,1.4-.58,1.94,1.94,0,0,1,1.4.58A2.22,2.22,0,0,1,21.37,21.51Z"
      transform="translate(-8 -6.22)"
      style="fill: #020203"
    />
    <path
      d="M19.67,19.09a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,19.67,19.09Z"
      transform="translate(-8 -6.22)"
      style="fill: #020203"
    />
  </svg>
</template>
