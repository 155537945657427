<script setup lang="ts">
import MTextInput from "@mozaic-ds/vue-3/src/components/textinput/MTextInput.vue";
import { computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import DvpField from "@/commons/components/form/DvpField.vue";
import LayerModalFormConfirm from "@/commons/components/form/LayerModalFormConfirm.vue";
import Markdown from "@/documentation/components/commons/Markdown.vue";

import { ConfirmActionForm } from "@/commons/domain/forms/confirm-action-form";

import contents from "@/commons/contents/delete-user-modal";

const props = defineProps({
  userEmail: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["onClose"]);

const store = useStore();
const router = useRouter();

const isSaving = computed(() => {
  return store.getters["isSaving"];
});

const form = ConfirmActionForm.create();

onMounted(async () => {
  await form.init({ confirmationText: props.userEmail });
});

const onDeleteConfirmationClick = async () => {
  await form.validate();

  if (!isSaving.value && !form.errors.length) {
    await store.dispatch("deleteUser");
    await router.push({ path: "/" });
  }
};
</script>

<template>
  <LayerModalFormConfirm
    class="delete-user-modal"
    :disabledConfirmBtn="!!form.errors.length || isSaving"
    @submit="onDeleteConfirmationClick"
    @close="emit('onClose')"
  >
    <h4>{{ contents.title }}</h4>

    <Markdown :content="contents.emailAddressWillBeLost(userEmail)" />

    <p>{{ contents.tapeYourEmailAddress }}</p>

    <DvpField :label="contents.confirmEmailLabel">
      <MTextInput
        v-model="form.text"
        :placeholder="userEmail"
        :isDisabled="isSaving"
        :isInvalid="form.firstError('text') != undefined"
      />
    </DvpField>
  </LayerModalFormConfirm>
</template>
