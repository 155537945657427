module.exports = {
  searchLabel: "Search APP by name and description",
  showAllAppsLabel: "Show all APPs",
  usedApisLabel: "By API used in the APP",
  searchUsedApi: "Search an API",
  productsLabel: "By product",
  searchProduct: "Search product",
  byContractStatusLabel: "By contract status",
  byApiStatusLabel: "By API status",
  onlySubscribedLabelLink: "Consult all my subscribed APIs",
  moreFiltersButtonLabel: "More filters",
  createNewApp: "Create a new APP",
};
