import { Dictionary } from "@/commons/domain/models/dictionary";
import { RateLimit } from "@/commons/domain/models/rate-limit";
import { RateLimits } from "@/commons/domain/models/rate-limits";
import { RateLimitsDto } from "@/commons/dtos/rate-limits-dto";

export class RateLimitsMapper {
  static toDomain(
    { global, specifics }: RateLimitsDto = { global: undefined, specifics: [] },
  ): RateLimits {
    const specificRateLimits: Dictionary<RateLimit> = {};
    let globalRateLimit: RateLimit = undefined;

    if (global) {
      globalRateLimit = {
        id: global.id,
        maxRequests: global.maxRequests,
        windowLength: global.windowLength,
        windowLengthUnit: global.windowLengthUnit,
        isEnabled: global.isEnabled,
        isGlobal: global.isGlobal,
      };
    }

    for (const specificRateLimitDto of specifics) {
      specificRateLimits[specificRateLimitDto.id] = {
        id: specificRateLimitDto.id,
        maxRequests: specificRateLimitDto.maxRequests,
        windowLength: specificRateLimitDto.windowLength,
        windowLengthUnit: specificRateLimitDto.windowLengthUnit,
        isEnabled: specificRateLimitDto.isEnabled,
        httpMethod: specificRateLimitDto.httpMethod,
        pathRegex: specificRateLimitDto.pathRegex,
        isGlobal: specificRateLimitDto.isGlobal,
      };
    }

    return {
      global: globalRateLimit,
      specifics: specificRateLimits,
    };
  }
}
