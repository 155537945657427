<template>
  <form
    class="layer-modal-form"
    autocomplete="false | random-string"
    @submit.prevent="$emit('submit')"
  >
    <div class="layer-modal-form__fields">
      <slot name="fields" />
    </div>
    <div class="layer-modal-form__footer" :class="globalLayerModalFooterClass">
      <slot name="formFooter" />
    </div>
  </form>
</template>

<script lang="ts">
export default {
  inject: {
    // Gets enriched this component is used in a <GlobalLayerModal> if used with it
    globalLayerModalFooterClass: {
      default: "",
    },
  },
  emits: ["submit"],
};
</script>

<style lang="scss">
.layer-modal-form {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.layer-modal-form__fields {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: var(--base-spacing);
}

.layer-modal-form__footer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--base-button-margin);
}
</style>
