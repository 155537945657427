<template>
  <MenuLink
    :urlOrRoute="urlOrRoute"
    :isExternalLink="isExternalLink"
    :class="[
      'menu-item',
      {
        'menu-item--with-description': !!description,
        'menu-item--color-white': colorWhite,
        'menu-item--without-icon': withoutIcon,
        'menu-item--with-border-bottom': withBottomBorder,
      },
    ]"
    :target="openInNewWindow ? '_blank' : '_self'"
    @click="onClick"
  >
    <div class="menu-item__inner">
      <span v-if="!!iconName" class="menu-item__icon">
        <MIcon :name="iconName" color="var(--color-brand-minor)" />
      </span>
      <div v-else-if="iconLocalPath" class="menu-item__icon">
        <img :src="iconLocalPath" />
      </div>

      <div class="menu-item__content">
        <div class="menu-item__label">
          {{ label }}
          <span v-if="isExternalLink">
            <MIcon name="DisplayExternalLink16" />
          </span>
        </div>
        <div
          v-if="!!description && isDisplayDescription"
          class="menu-item__description"
        >
          {{ description }}
        </div>
      </div>
    </div>
  </MenuLink>
</template>

<script lang="ts">
import MIcon from "@mozaic-ds/vue-3/src/components/icon/MIcon.vue";

import MenuLink from "@/commons/components/MenuLink.vue";

export default {
  name: "MenuItem",
  components: { MenuLink, MIcon },
  props: {
    label: {
      type: String,
      required: true,
    },
    openInNewWindow: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: "",
    },
    iconName: {
      type: String,
      default: undefined,
    },
    iconLocalPath: {
      type: String,
      default: undefined,
    },
    emitEventOnClick: {
      type: String,
      default: null,
    },
    urlOrRoute: {
      type: [String, Object],
      default: null,
    },
    isExternalLink: {
      type: Boolean,
      default: false,
    },
    isDisplayImage: {
      type: Boolean,
      default: true,
    },
    isDisplayDescription: {
      type: Boolean,
      default: true,
    },
    colorWhite: {
      type: Boolean,
      default: false,
    },
    withBottomBorder: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    withoutIcon(): boolean {
      return !this.isDisplayImage || !(this.iconName || this.iconLocalPath);
    },
  },
  methods: {
    onClick() {
      if (!this.emitEventOnClick) {
        return;
      }
      this.$emit(this.emitEventOnClick);
    },
  },
};
</script>

<style lang="scss">
.menu-item {
  display: flex;
  align-items: center;
  padding: 0.125rem 0.375rem; /* 2px 6px */
  color: var(--color-text-primary);
  text-align: left;
  text-decoration: none;
  text-transform: none;
  cursor: pointer;
  background-color: transparent;
  transition: 0.4s;
  @include set-text-s;
}
.menu-item--color-white {
  color: var(--color-white);

  .menu-item__inner:hover {
    background-color: transparent;
  }
}

.menu-item--with-border-bottom {
  border-bottom: var(--border-default);
}

.menu-item__label {
  margin-bottom: 0.25rem;
  font-size: 0.875rem; // 14px
  font-weight: var(--weight-bold);
  color: var(--color-text-secondary);
}

.menu-item--without-icon {
  padding: 6px 0;
}

.menu-item__icon {
  margin-right: 1rem;
}

.menu-item--with-description .menu-item__icon {
  display: flex;
  margin-right: 1.3rem;
}

.menu-item__inner {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.813rem;
}
.menu-item__inner:hover {
  background-color: var(--color-background-secondary-light);
  border-radius: var(--m-border-radius-large);
}

.menu-item__description {
  font-size: 0.875rem; // 14px
  font-weight: 400;
  color: var(--color-text-minor);
}
</style>
