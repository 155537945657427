import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "groups-listing-section__filters" }
const _hoisted_2 = { class: "groups-listing-section__no-data" }
const _hoisted_3 = { class: "groups-listing-section__icon" }
const _hoisted_4 = { class: "groups-listing-section__description" }
const _hoisted_5 = {
  key: 1,
  class: "groups-listing-section__mcc-group-actions"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchTextFilter = _resolveComponent("SearchTextFilter")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_IconGroup = _resolveComponent("IconGroup")!
  const _component_ButtonEditGroup = _resolveComponent("ButtonEditGroup")!
  const _component_ButtonEdit = _resolveComponent("ButtonEdit")!
  const _component_ButtonDelete = _resolveComponent("ButtonDelete")!
  const _component_FlexActionButtonsWrapper = _resolveComponent("FlexActionButtonsWrapper")!
  const _component_HelpLink = _resolveComponent("HelpLink")!
  const _component_MDataTable = _resolveComponent("MDataTable")!
  const _component_QueryParamPagination = _resolveComponent("QueryParamPagination")!
  const _component_LayoutSection = _resolveComponent("LayoutSection")!

  return (_openBlock(), _createBlock(_component_LayoutSection, {
    background: "secondary",
    class: "groups-listing-section"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_SearchTextFilter, {
          class: "groups-listing-section__search-filter",
          "data-cy": "search-filter",
          placeholder: $data.contents.searchGroupPlaceholder,
          queryParamName: "term",
          queryParamValue: $props.searchText,
          size: "s"
        }, null, 8, ["placeholder", "queryParamValue"]),
        _createVNode(_component_MButton, {
          size: "s",
          label: $data.contents.createNewGroup,
          onClick: $options.openGroupCreationModal
        }, null, 8, ["label", "onClick"])
      ]),
      _createVNode(_component_MDataTable, {
        class: "groups-listing-section__table",
        headers: $options.tableHeaders,
        source: $props.groups,
        sorting: { mode: 'none' }
      }, {
        "no-data": _withCtx(() => [
          _createElementVNode("div", _hoisted_2, _toDisplayString($data.contents.noGroupFound), 1)
        ]),
        [`item.icon`]: _withCtx(({ item }) => [
          _createElementVNode("div", _hoisted_3, [
            (item.provider)
              ? (_openBlock(), _createBlock(_component_IconGroup, {
                  key: 0,
                  provider: item.provider
                }, null, 8, ["provider"]))
              : _createCommentVNode("", true)
          ])
        ]),
        [`item.name`]: _withCtx(({ item }) => [
          _createElementVNode("div", null, _toDisplayString(item.name), 1)
        ]),
        [`item.description`]: _withCtx(({ item }) => [
          _createElementVNode("div", _hoisted_4, _toDisplayString(item.description), 1)
        ]),
        [`item.lastUpdate`]: _withCtx(({ item }) => [
          _createElementVNode("div", null, _toDisplayString($options.formatDate(item.updatedAt)), 1)
        ]),
        [`item.actions`]: _withCtx(({ item }) => [
          ($options.isLocalGroup(item))
            ? (_openBlock(), _createBlock(_component_FlexActionButtonsWrapper, {
                key: 0,
                class: "groups-listing-section__action-buttons-wrapper"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ButtonEditGroup, {
                    onClick: ($event: any) => ($options.openGroupMembershipsModal(item))
                  }, null, 8, ["onClick"]),
                  ($options.userHasWriteAccessOnGroup(item) || $options.userIsAdmin)
                    ? (_openBlock(), _createBlock(_component_ButtonEdit, {
                        key: 0,
                        onClick: ($event: any) => ($options.openGroupUpdateModal(item))
                      }, null, 8, ["onClick"]))
                    : _createCommentVNode("", true),
                  ($options.userHasWriteAccessOnGroup(item) || $options.userIsAdmin)
                    ? (_openBlock(), _createBlock(_component_ButtonDelete, {
                        key: 1,
                        onClick: ($event: any) => ($options.openGroupDeletionModal(item))
                      }, null, 8, ["onClick"]))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1024))
            : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_ButtonEditGroup, {
                  onClick: ($event: any) => ($options.openGroupMembershipsModal(item))
                }, null, 8, ["onClick"]),
                _createVNode(_component_HelpLink, {
                  label: $data.contents.seeInMCCLinkLabel,
                  href: item.linkMCC,
                  openInNewTab: ""
                }, null, 8, ["label", "href"])
              ]))
        ]),
        _: 2
      }, 1032, ["headers", "source"]),
      ($props.totalPages != undefined)
        ? (_openBlock(), _createBlock(_component_QueryParamPagination, {
            key: 0,
            class: "groups-listing-section__pagination",
            totalPagesNumber: $props.totalPages
          }, null, 8, ["totalPagesNumber"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}