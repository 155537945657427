module.exports = {
  moreFiltersHeader: "More filters",
  apiUsedInTheApplicationLabel: "API used in the application",
  searchApiPlaceholder: "Search an API",
  tangramProductLabel: "Tangram Product",
  searchProductPlaceholder: "Search a product",
  subscriptionStatusLabel: "Subscription Status",
  apisStatusLabel: "API Status",
  confirmButtonLabel: "Validate",
};
