<template>
  <div class="cancel-and-submit-buttons">
    <span class="cursor-pointer" data-cy="cancel-button" @click="cancel">
      <MIcon name="ControlCircleCross32" color="var(--color-status-danger)" />
    </span>

    <span class="cursor-pointer" data-cy="submit-button" @click="submit">
      <MIcon
        name="NotificationCircleAvailable32"
        color="var(--color-status-success)"
      />
    </span>
  </div>
</template>

<script lang="ts">
import MIcon from "@mozaic-ds/vue-3/src/components/icon/MIcon.vue";

export default {
  name: "CancelAndSubmitButtons",
  components: { MIcon },
  emits: ["cancel", "submit"],
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    submit() {
      this.$emit("submit");
    },
  },
};
</script>

<style lang="scss">
.cancel-and-submit-buttons {
  display: flex;
}
</style>
