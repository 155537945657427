<template>
  <div class="contact-us">
    <!--eslint-disable-next-line vue/no-v-html-->
    <div>
      <h1 class="contact-us__title">
        {{ contents.readyToGetStarted }}
        <br />
        <span class="contact-us__title--thin">
          {{ contents.contactUsOrCreateAccount }}
        </span>
      </h1>
    </div>
    <div class="contact-us__actions">
      <MButton
        v-if="!isLogged"
        :label="contents.signInButtonLabel"
        @click="
          () =>
            $router.push({
              name: 'login',
              query: { redirect: $route.fullPath },
            })
        "
      />
      <MButton
        theme="solid-neutral"
        :label="contents.helpButtonLabel"
        :href="supportUrl"
      />
    </div>
  </div>
</template>

<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";

const contents = {
  contactUsOrCreateAccount: "Contact us or create an account",
  readyToGetStarted: "Ready to get started?",
  signInButtonLabel: "Sign in",
  helpButtonLabel: "Need help ?",
};

export default {
  name: "ContactUs",
  components: {
    MButton,
  },
  data() {
    return {
      contents,
    };
  },
  computed: {
    locationSettings() {
      return this.$store.getters["config/locationSettings"];
    },
    isLogged() {
      return this.$store.getters["userIsLogged"];
    },
    supportUrl() {
      return this.$store.getters["config/supportUrl"];
    },
  },
};
</script>

<style lang="scss">
@import "~@/commons/assets/styles/vars";

.contact-us {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 91.4rem;
  margin: 10rem auto;
}

.contact-us__title {
  font-size: 2.57rem;
  line-height: 3.43rem;
}

.contact-us__title--thin {
  font-weight: 200;
}

.contact-us__actions {
  display: flex;
  margin: 0 2rem 0 3rem;
}

.contact-us__actions button {
  margin-right: var(--base-spacing);
}

@media only screen and (max-width: map-get($breakpoints, tablet-landscape)) {
  .contact-us {
    flex-direction: column;
    width: 90%;
    margin: 2rem auto;
    text-align: center;
  }

  .contact-us__actions .button {
    margin-bottom: 2rem;
  }
}

@media only screen and (max-width: map-get($breakpoints, mobile)) {
  .contact-us__actions {
    flex-direction: column;
    width: 100%;
    margin: 1rem 0 4rem;
  }

  .contact-us__actions .button {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin: 0 0 1.5rem;
  }
}
</style>
