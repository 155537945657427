<template>
  <GroupsListingSection
    class="manage-groups"
    :groups="groups"
    :totalPages="totalPages"
    :searchText="urlQuery.term"
    @groupUpdated="loadGroups"
    @groupDeleted="loadGroups"
  />
</template>

<script lang="ts">
import { ManageGroupsUrlQuery } from "@/admin/routes";
import { PropType } from "vue";

import GroupsListingSection from "@/commons/views/GroupsListingSection.vue";

import { GroupFetchParams } from "@/commons/domain/repositories/group-with-users-acls-repository";

import contents from "@/commons/contents/groups-listing-section";

export default {
  components: {
    GroupsListingSection,
  },
  props: {
    urlQuery: {
      type: Object as PropType<ManageGroupsUrlQuery>,
      required: true,
    },
  },
  data() {
    return {
      contents,
    };
  },
  computed: {
    groups() {
      return this.$store.getters["adminGroupsList"];
    },
    totalPages() {
      const pagination = this.$store.getters["adminGroupsPagination"];
      return pagination?.totalPages;
    },
  },
  watch: {
    urlQuery() {
      this.loadGroups();
    },
  },
  created() {
    this.loadGroups();
  },
  methods: {
    loadGroups() {
      this.$store.dispatch("loadGroups", this.buildFetchParams(this.urlQuery));
    },
    buildFetchParams({
      term,
      page,
      size,
    }: ManageGroupsUrlQuery): GroupFetchParams {
      return {
        name: term,
        page,
        size,
      };
    },
  },
};
</script>
