<template>
  <div class="notification-for-deactivated-secret-synchronization">
    <MNotification :title="contents.title(secretManager)" type="warning">
      <p>
        {{ contents.message(secretManager) }}
      </p>
    </MNotification>
  </div>
</template>

<script setup lang="ts">
import MNotification from "@mozaic-ds/vue-3/src/components/notification/MNotification.vue";
import { computed } from "vue";
import { useStore } from "vuex";

import contents from "@/dashboard/contents/notification-for-deactivated-secret-synchronization";

const store = useStore();

const secretManager = computed(() => {
  return store.getters["config/secretSynchronizationManager"];
});
</script>

<style lang="scss">
.notification-for-deactivated-secret-synchronization {
  margin-bottom: 1rem;
}
</style>
