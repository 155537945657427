<script setup lang="ts">
import { computed, markRaw, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import DvpStepper from "@/commons/components/DvpStepper.vue";
import Layout2 from "@/commons/components/Layout2.vue";
import LayoutContentHeader from "@/commons/components/LayoutContentHeader.vue";
import LayoutSection from "@/commons/components/LayoutSection.vue";
import CreateNewScope from "@/manager/views/AddOAuthScopes/CreateNewScope.vue";
import DocumentedScopesList from "@/manager/views/AddOAuthScopes/DocumentedScopesList.vue";
import FooterActions from "@/manager/views/AddOAuthScopes/FooterActions.vue";
import ReferenceNewScope from "@/manager/views/AddOAuthScopes/ReferenceNewScope.vue";
import ReferencedScopesList from "@/manager/views/AddOAuthScopes/ReferencedScopesList.vue";
import SelectedScopesList from "@/manager/views/AddOAuthScopes/SelectedScopesList.vue";
import ScopeProductsListModal from "@/manager/views/AddOAuthScopes/modal/ScopeProductsListModal.vue";

import { pluralize } from "@/commons/utils/contents-utils";
import {
  isAtLeastOneSelectedScopeIsVisible,
  getSelectedScopeIndex,
} from "@/manager/utils/oauth-scopes";

import { Scope } from "@/commons/domain/models/scope";

import contents from "@/manager/contents/add-oauth-scopes";
import contentsProductsModal from "@/manager/contents/scope-products-list-modal";

const props = defineProps({
  apiId: {
    type: String,
    required: true,
  },
});

const store = useStore();

/**
 * Define route for the previous page to be redirected to Api Manager -> Authentication menu
 */
const previousPageRoute = {
  name: "managerApiDetailAuthentication",
  params: {
    id: props.apiId,
  },
  hash: "#scopes",
};

const router = useRouter();

const returnToApiAuthentication = () => {
  router.push(previousPageRoute);
};

const cancel = () => {
  returnToApiAuthentication();
};

const next = () => {
  if (documentedSelectedScopes.firstNotReferencedScope != undefined) {
    newScopeCreation.predefinedScope =
      documentedSelectedScopes.firstNotReferencedScope;
    moveToReferenceNewScope();
  } else {
    stepper.moveToSecondStep();
  }
};

/**
 * Define steps:
 * - List all scopes and select one
 * - Validate selected scope
 */

const stepper = reactive({
  currentStep: 1,
  steps: computed(() => {
    return [
      {
        label: contents.selectScopeStepLabel,
        isCurrent: stepper.isFirstStep,
        onClick: false,
      },
      {
        label: contents.validateScopeStepLabel,
        isCurrent: stepper.isSecondStep,
      },
    ];
  }),
  isFirstStep: computed((): boolean => {
    return stepper.currentStep === 1;
  }),
  isSecondStep: computed((): boolean => {
    return stepper.currentStep === 2;
  }),
  moveToFirstStep: () => {
    stepper.currentStep = 1;
    newScopeCreation.inProgress = false;
  },
  moveToSecondStep: () => {
    stepper.currentStep = 2;
  },
  isDisabledSecondStep: computed(() => {
    return selectedScopes.isEmpty || !selectedScopes.couldBeValidated;
  }),
});

/**
 * Reference a new scope
 */

const newScopeCreation = reactive({
  inProgress: false,
  entity: undefined as Scope,
  predefinedScope: undefined as Scope,
  activate: () => {
    newScopeCreation.inProgress = true;
  },
  couldBeValidated: computed(
    () => newScopeCreation.inProgress && newScopeCreation.entity != undefined,
  ),
});

const moveToReferenceNewScope = () => {
  stepper.moveToSecondStep();
  newScopeCreation.activate();
};

/**
 * Handle the selected scopes
 */

const selectedScopes = reactive({
  items: computed(() => {
    return [
      ...referencedSelectedScopes.items,
      ...documentedSelectedScopes.items,
    ];
  }),
  isNotEmpty: computed(() => selectedScopes.items.length > 0),
  isEmpty: computed(() => selectedScopes.items.length === 0),
  selectionDisabled: computed(
    // Only one scope can be selected at a time
    () => selectedScopes.isNotEmpty && selectedScopes.items.length === 1,
  ),
  couldBeValidated: computed(() => {
    return (
      referencedSelectedScopes.couldBeValidated ||
      documentedSelectedScopes.couldBeValidated
    );
  }),
  names: computed(() => selectedScopes.items.map((s) => s.name)),
});

const referencedSelectedScopes = reactive({
  items: [] as Scope[],
  isNotEmpty: computed(() => referencedSelectedScopes.items.length > 0),
  isEmpty: computed(() => referencedSelectedScopes.items.length === 0),
  isVisible: false,
  handleSelectedScopesVisibility: (visibleScopes: Scope[]) => {
    if (visibleScopes != undefined) {
      referencedSelectedScopes.isVisible = isAtLeastOneSelectedScopeIsVisible(
        visibleScopes,
        referencedSelectedScopes.items,
      );
    }
  },
  select: (item: Scope) => {
    if (
      referencedSelectedScopes.items.filter((s) => s.name === item.name)
        .length === 0
    ) {
      referencedSelectedScopes.items.push(item);
    }
  },
  remove: (item: Scope) => {
    referencedSelectedScopes.items.splice(
      getSelectedScopeIndex(item, referencedSelectedScopes.items),
    );
  },
  handleSelect: (item: Scope, value: boolean) => {
    value
      ? referencedSelectedScopes.select(item)
      : referencedSelectedScopes.remove(item);
  },
  clear: () => {
    referencedSelectedScopes.items = [];
  },
  couldBeValidated: computed(() => {
    return (
      referencedSelectedScopes.isNotEmpty && referencedSelectedScopes.isVisible
    );
  }),
});

const documentedSelectedScopes = reactive({
  items: [] as Scope[],
  isNotEmpty: computed(() => documentedSelectedScopes.items.length > 0),
  isEmpty: computed(() => documentedSelectedScopes.items.length === 0),
  isVisible: false,
  handleSelectedScopesVisibility: (visibleScopes: Scope[]) => {
    if (visibleScopes != undefined) {
      documentedSelectedScopes.isVisible = isAtLeastOneSelectedScopeIsVisible(
        visibleScopes,
        documentedSelectedScopes.items,
      );
    }
  },
  select: (item: Scope) => {
    if (
      documentedSelectedScopes.items.filter((s) => s.name === item.name)
        .length === 0
    ) {
      documentedSelectedScopes.items.push(item);
    }
  },
  remove: (item: Scope) => {
    documentedSelectedScopes.items.splice(
      getSelectedScopeIndex(item, documentedSelectedScopes.items),
    );
  },
  handleSelect: (item: Scope, value: boolean) => {
    value
      ? documentedSelectedScopes.select(item)
      : documentedSelectedScopes.remove(item);
  },
  clear: () => {
    documentedSelectedScopes.items = [];
  },
  couldBeValidated: computed(() => {
    return (
      documentedSelectedScopes.isNotEmpty && documentedSelectedScopes.isVisible
    );
  }),
  firstNotReferencedScope: computed(() => {
    return documentedSelectedScopes.items.find(
      (s) => s.isAlreadyReferenced === false,
    );
  }),
});

/**
 * Validate selected scopes and save them to the API.
 */

const canValidateWholeProcess = computed(() => {
  return (
    inEditingMode.value === false &&
    stepper.isSecondStep &&
    ((!newScopeCreation.inProgress && selectedScopes.couldBeValidated) ||
      newScopeCreation.couldBeValidated)
  );
});

const validate = async () => {
  if (newScopeCreation.inProgress) {
    await store.dispatch("createScope", {
      apiId: props.apiId,
      name: newScopeCreation.entity.name,
      description: newScopeCreation.entity.description,
    });
  } else {
    await store.dispatch("saveApiScopes", {
      apiId: props.apiId,
      oauthScopes: selectedScopes.names,
    });
  }

  returnToApiAuthentication();
};

/**
 * Clicking on the number of products using the scope will open a modal with the list of products.
 * Products will be fetched by apiId while loading this modal.
 */
const showScopeProducts = (item: Scope) => {
  store.commit("openModal", {
    title: contentsProductsModal.modalTitle,
    component: markRaw(ScopeProductsListModal),
    props: {
      title: item.name,
      message: contentsProductsModal.usedByProductsMessage(
        pluralize(item.totalProductsCount, "product"),
      ),
      scopeName: item.name,
    },
  });
};

const inEditingMode = ref(false as boolean);

const switchEditMode = (editMode: boolean) => {
  inEditingMode.value = editMode;
};
</script>

<template>
  <div class="add-oauth-scopes">
    <Layout2>
      <LayoutSection background="primary" noVerticalPadding fullWidthContent>
        <LayoutContentHeader
          :title="contents.addScopeTitle"
          :previousPageRoute="previousPageRoute"
        />
      </LayoutSection>

      <LayoutSection background="secondary" class="add-oauth-scopes__stepper">
        <DvpStepper
          v-if="!inEditingMode"
          stepperDescription="Steps of adding a new scope"
          :steps="stepper.steps"
        />
      </LayoutSection>
      <LayoutSection background="secondary" noVerticalPadding fullHeight>
        <div v-if="stepper.isFirstStep" class="add-oauth-scopes__first-step">
          <DocumentedScopesList
            :selectedScopes="documentedSelectedScopes.items"
            :apiId="apiId"
            :selectionDisabled="selectedScopes.selectionDisabled"
            @selectScope="documentedSelectedScopes.handleSelect"
            @showScopeProducts="showScopeProducts"
            @changeSelectedScopesVisibility="
              documentedSelectedScopes.handleSelectedScopesVisibility
            "
          />
          <ReferencedScopesList
            :apiId="apiId"
            :selectedScopes="referencedSelectedScopes.items"
            :selectionDisabled="selectedScopes.selectionDisabled"
            @selectScope="referencedSelectedScopes.handleSelect"
            @showScopeProducts="showScopeProducts"
            @changeSelectedScopesVisibility="
              referencedSelectedScopes.handleSelectedScopesVisibility
            "
          />
          <ReferenceNewScope @referenceNewScope="moveToReferenceNewScope" />
        </div>
        <template v-else-if="stepper.isSecondStep">
          <CreateNewScope
            v-if="newScopeCreation.inProgress"
            :apiId="apiId"
            :predefinedScope="newScopeCreation.predefinedScope"
            @scopeToCreate="newScopeCreation.entity = $event"
          />
          <SelectedScopesList
            v-else
            :selectedScopes="selectedScopes.items"
            @showScopeProducts="showScopeProducts"
            @switchEditMode="switchEditMode"
          />
        </template>
      </LayoutSection>
    </Layout2>
    <FooterActions
      :currentStep="stepper.currentStep"
      :secondStepIsDisabled="!selectedScopes.couldBeValidated"
      :validationIsDisabled="!canValidateWholeProcess"
      @cancel="cancel"
      @next="next"
      @back="stepper.moveToFirstStep"
      @validate="validate"
    />
  </div>
</template>

<style lang="scss">
.add-oauth-scopes__first-step {
  display: flex;
  flex-direction: column;
  gap: var(--base-spacing);

  margin-bottom: 2rem;
}
</style>
