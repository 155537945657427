<template>
  <Layout class="tos-read">
    <Stack>
      <div class="tos__content-body tos" v-html="termsOfUse()" />
    </Stack>
  </Layout>
</template>

<script lang="ts">
import Layout from "@/commons/components/Layout.vue";
import Stack from "@/commons/components/Stack.vue";

import getTermsOfUse from "@/commons/libs/get-terms-of-use";

export default {
  name: "TOSRead",
  components: { Stack, Layout },
  computed: {
    locationSettings() {
      return this.$store.getters["config/locationSettings"];
    },
  },
  methods: {
    termsOfUse() {
      return getTermsOfUse(this.locationSettings);
    },
  },
};
</script>
