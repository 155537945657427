export abstract class FormData {
  abstract $clear(groupOfFields?: string): void;
  $update(partial?: Object) {
    for (const key in partial) {
      this[key] = partial[key];
    }
  }
  $data() {
    const data: any = {};

    for (const key in this) {
      data[key] = this[key];
    }

    return data;
  }
}
