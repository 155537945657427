import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, vShow as _vShow, withModifiers as _withModifiers, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5a657e4e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pickers-container flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderPicker = _resolveComponent("HeaderPicker")!
  const _component_DatePicker = _resolveComponent("DatePicker")!
  const _component_TimePicker = _resolveComponent("TimePicker")!
  const _component_ButtonValidate = _resolveComponent("ButtonValidate")!

  return (_openBlock(), _createBlock(_Transition, {
    name: $props.position === 'bottom' ? 'slide' : 'slideinvert'
  }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", {
        class: _normalizeClass([{ inline: $props.inline, 'is-dark': $props.dark, visible: $props.visible }, "datetimepicker flex"]),
        style: _normalizeStyle($options.responsivePosition),
        onClick: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop"]))
      }, [
        _createElementVNode("div", {
          style: _normalizeStyle([$options.responsivePosition, $options.width]),
          class: _normalizeClass(["datepicker flex flex-direction-column", { right: $props.right }])
        }, [
          (!$props.noHeader)
            ? (_openBlock(), _createBlock(_component_HeaderPicker, {
                key: $data.componentKey,
                value: $props.modelValue,
                color: $props.color,
                "only-time": $props.onlyTime,
                format: $props.format,
                "time-format": $options.timeFormat,
                "transition-name": $data.transitionName,
                "no-time": $props.onlyDate,
                dark: $props.dark,
                range: $props.range
              }, null, 8, ["value", "color", "only-time", "format", "time-format", "transition-name", "no-time", "dark", "range"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_1, [
            (!$props.onlyTime)
              ? (_openBlock(), _createBlock(_component_DatePicker, {
                  key: 0,
                  id: _ctx.$attrs.id,
                  modelValue: $options.date,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($options.date) = $event)),
                  dark: $props.dark,
                  month: $data.month,
                  inline: $props.inline,
                  "no-weekends-days": $props.noWeekendsDays,
                  "disabled-weekly": $props.disabledWeekly,
                  color: $props.color,
                  "min-date": $props.minDate,
                  "max-date": $props.maxDate,
                  "disabled-dates": $props.disabledDates,
                  "enabled-dates": $props.enabledDates,
                  range: $props.range,
                  "no-shortcuts": $props.noShortcuts,
                  height: $options.height,
                  "first-day-of-week": $props.firstDayOfWeek,
                  visible: $props.visible,
                  shortcut: $props.shortcut,
                  "custom-shortcuts": $props.customShortcuts,
                  "no-keyboard": $props.noKeyboard,
                  locale: $props.locale,
                  onChangeMonth: $options.changeMonth,
                  onChangeYearMonth: $options.changeYearMonth,
                  onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
                }, null, 8, ["id", "modelValue", "dark", "month", "inline", "no-weekends-days", "disabled-weekly", "color", "min-date", "max-date", "disabled-dates", "enabled-dates", "range", "no-shortcuts", "height", "first-day-of-week", "visible", "shortcut", "custom-shortcuts", "no-keyboard", "locale", "onChangeMonth", "onChangeYearMonth"]))
              : _createCommentVNode("", true),
            (!$props.onlyDate)
              ? (_openBlock(), _createBlock(_component_TimePicker, {
                  key: 1,
                  ref: "TimePicker",
                  modelValue: $options.time,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($options.time) = $event)),
                  dark: $props.dark,
                  color: $props.color,
                  inline: $props.inline,
                  format: $options.timeFormat,
                  "only-time": $props.onlyTime,
                  "minute-interval": $props.minuteInterval,
                  visible: $props.visible,
                  height: $options.height,
                  "disabled-hours": $props.disabledHours,
                  "min-time": $options.minTime,
                  "max-time": $options.maxTime,
                  behaviour: $props.behaviour
                }, null, 8, ["modelValue", "dark", "color", "inline", "format", "only-time", "minute-interval", "visible", "height", "disabled-hours", "min-time", "max-time", "behaviour"]))
              : _createCommentVNode("", true)
          ]),
          (!$props.hasNoButton && !($props.inline && $props.range))
            ? (_openBlock(), _createBlock(_component_ButtonValidate, {
                key: 1,
                class: "button-validate flex-fixed",
                dark: $props.dark,
                "button-color": $props.buttonColor,
                "button-now-translation": $props.buttonNowTranslation,
                "only-time": $props.onlyTime,
                "no-button-now": $props.noButtonNow,
                range: $props.range,
                "has-button-validate": $props.hasButtonValidate,
                onValidate: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('validate'))),
                onNow: $options.setNow
              }, null, 8, ["dark", "button-color", "button-now-translation", "only-time", "no-button-now", "range", "has-button-validate", "onNow"]))
            : _createCommentVNode("", true)
        ], 6)
      ], 6), [
        [_vShow, $props.visible || $props.inline]
      ])
    ]),
    _: 1
  }, 8, ["name"]))
}