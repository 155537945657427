import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "group-deletion-modal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfirmModalBody = _resolveComponent("ConfirmModalBody")!
  const _component_DvpTooltip = _resolveComponent("DvpTooltip")!
  const _component_Markdown = _resolveComponent("Markdown")!
  const _component_AlertModalBody = _resolveComponent("AlertModalBody")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($options.canDelete)
      ? (_openBlock(), _createBlock(_component_ConfirmModalBody, {
          key: 0,
          class: "group-deletion-modal",
          confirmBtnLabel: $data.contents.confirm,
          disabledConfirmBtn: $options.isSaving,
          onOnSubmit: $options.deleteGroup,
          onOnClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onClose')))
        }, {
          default: _withCtx(() => [
            _createElementVNode("h4", null, _toDisplayString($data.contents.titleCanDelete($props.group.name)), 1),
            _createElementVNode("p", null, _toDisplayString($data.contents.descriptionCanDeleteGroup), 1)
          ]),
          _: 1
        }, 8, ["confirmBtnLabel", "disabledConfirmBtn", "onOnSubmit"]))
      : (_openBlock(), _createBlock(_component_AlertModalBody, {
          key: 1,
          onOnClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('onClose')))
        }, {
          default: _withCtx(() => [
            _createElementVNode("h4", null, _toDisplayString($data.contents.titleCantDelete($props.group.name)), 1),
            _createElementVNode("p", null, _toDisplayString($data.contents.descriptionCantDeleteGroup), 1),
            _createElementVNode("ul", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.resourcesPreventingDeletion, (managedResource, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: 'managed-resource-' + index
                }, [
                  _createVNode(_component_DvpTooltip, {
                    class: "group-deletion-modal__resource-names-tooltip",
                    text: $options.formatResourceNames(managedResource.data),
                    position: "right"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(managedResource.total) + " " + _toDisplayString(managedResource.type), 1)
                    ]),
                    _: 2
                  }, 1032, ["text"]),
                  _createTextVNode(" " + _toDisplayString($data.contents.managedBy($props.group.name)), 1)
                ]))
              }), 128))
            ]),
            _createElementVNode("p", null, _toDisplayString($data.contents.transferOwnership($props.group.name)), 1),
            _createVNode(_component_Markdown, {
              content: $data.contents.contactUs
            }, null, 8, ["content"])
          ]),
          _: 1
        }))
  ]))
}