import { Form } from "./form";
import { FormData } from "./form-data";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface ArrayOfForms<T extends FormData> {
  $addValue?(newValue: unknown): void;
}

export abstract class ArrayOfForms<T extends FormData> {
  values: Array<Form<T>> = [];
  newValue: Form<T>;
  abstract $update(partial: Object): void;
  $clear() {
    this.values = [];

    if (this.newValue) {
      this.newValue.clear();
    }
  }
  $data() {
    const data: any[] = [];

    for (const valueForm of this.values) {
      data.push(valueForm.data());
    }

    return data;
  }
}
