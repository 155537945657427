import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6ff7cbe0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "cookie-banner"
}
const _hoisted_2 = { class: "cookie-banner__btn-action gap-800" }
const _hoisted_3 = {
  key: 0,
  class: "btn-action--immediate"
}
const _hoisted_4 = {
  key: 0,
  class: "gap-1000"
}
const _hoisted_5 = { class: "pushed-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CookieArray = _resolveComponent("CookieArray")!
  const _component_ExpansionPanel = _resolveComponent("ExpansionPanel")!

  return ($data.displayPanel)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createElementVNode("h3", null, _toDisplayString($data.contents.titleUsingCookies), 1),
          _createElementVNode("h5", null, _toDisplayString($data.contents.whyUsingCookiesTitle), 1),
          _createElementVNode("p", null, _toDisplayString($data.contents.whyUsingCookies), 1),
          _createElementVNode("h5", null, _toDisplayString($data.contents.whatIsCookiesTitle), 1),
          _createElementVNode("p", null, _toDisplayString($data.contents.whatIsCookie), 1)
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("button", {
            class: "btn",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.showPersonalizeCookies && $options.showPersonalizeCookies(...args)))
          }, _toDisplayString($data.contents.btnPersonalizeCookies), 1),
          (!$data.showPersonalize)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("button", {
                  class: "btn",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => ($options.refuseAllCookies()))
                }, _toDisplayString($data.contents.btnRefuseAllCookies), 1),
                _createElementVNode("button", {
                  class: "btn btn-primary",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => ($options.acceptAllCookies()))
                }, _toDisplayString($data.contents.btnAcceptAllCookies), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        ($data.showPersonalize)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.cookieCategories, (cat, index) => {
                return (_openBlock(), _createBlock(_component_ExpansionPanel, {
                  key: `${cat.type}-${index}`,
                  title: cat.title,
                  onToggleValue: ($event: any) => ($options.changeAllowedConfigurableCookies(cat.type, $event.value))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(cat.content) + " ", 1),
                    _createVNode(_component_CookieArray, {
                      data: cat.cookiesDetails,
                      class: "gap-400"
                    }, null, 8, ["data"])
                  ]),
                  _: 2
                }, 1032, ["title", "onToggleValue"]))
              }), 128)),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("button", {
                  class: "btn btn-primary",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => ($options.updateCookies()))
                }, _toDisplayString($data.contents.btnAcceptCookies), 1)
              ])
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}