<template>
  <div v-if="deploymentsOptions.length > 0" class="deployment-selector">
    <MSelect
      id="deployment-select"
      class="deployment-select"
      :modelValue="currentDeployment.value"
      :options="deploymentsOptions"
      @update:modelValue="changeEnvironment"
    />
  </div>
</template>

<script lang="ts">
import MSelect from "@mozaic-ds/vue-3/src/components/select/MSelect.vue";

import contents from "@/commons/contents/deployment-selector";

export default {
  components: {
    MSelect,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ui: contents,
    };
  },
  computed: {
    deployments() {
      return this.$store.getters["config/deployments"];
    },
    currentDeployment() {
      return this.deploymentsOptions.find((deployment) =>
        location.href.startsWith(deployment.value),
      );
    },
    deploymentsOptions() {
      return this.deployments.map(({ url, label }) => ({
        value: url,
        text: label && label.toUpperCase(),
      }));
    },
  },
  methods: {
    changeEnvironment(selectedDeploymentValue) {
      if (selectedDeploymentValue !== this.currentDeployment?.value) {
        location.href = `${selectedDeploymentValue}${this.$route.path}`;
      }
    },
  },
};
</script>

<style lang="scss">
.deployment-selector {
  margin: 0 8px;
  font-weight: 700;
}

.deployment-select {
  font-weight: 700;
}
</style>
