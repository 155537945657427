import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ 'is-dark': $props.dark }, "week-days flex"])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.weekDays, (weekDay, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "flex-1 text-muted lm-fs-12 flex justify-content-center align-center week-days-container"
      }, _toDisplayString(weekDay), 1))
    }), 128))
  ], 2))
}