export default {
  publicIPAddressesTitle: "Public IP addresses",
  publicIPAddressesDescription:
    "Your application has subscribed to API(s) published on Internet. If they have activated (or plan to activate) the IP Filtering to protect themselves and if you use (or plan to use) one of their PUBLIC routes, you have to define the Public IP Addresses your calls will be seen from. Otherwise, your calls will be blocked.",
  addNewIPAddressBtnLabel: "Add a new IP address",

  ipAddressRangeHeaderName: "IP Address Range",
  commentHeaderName: "Comment",

  ipAddressWasSuccessfullyAddedMessage: "IP address added",
  ipAddressWasSuccessfullyUpdatedMessage: "IP address updated",
  ipAddressWasSuccessfullyDeletedMessage: "IP address deleted",

  modalTitleDeleteIPRange: "Delete IP Address",
  deleteIPRangeTitle: (ipRange) =>
    `Are you sure you want to delete the IP Address ${ipRange}`,
  deleteIPRangeMessage:
    "This action is irreversible and your application will not be able to call APIs using this IP Address. Your production may be impacted.",
};
