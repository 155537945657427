<template>
  <div
    class="quota-type-option"
    :class="{ 'quota-type-option--active': isActive }"
    @click="$emit('click')"
  >
    <img :src="icon" class="quota-type-option__icon" />
    <div>
      <div class="quota-type-option__title">{{ title }}</div>
      <div class="quota-type-option__description">{{ description }}</div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["click"],
};
</script>

<style lang="scss">
.quota-type-option {
  display: flex;
  align-items: center;
  padding: 2rem;
  cursor: pointer;
  background-color: var(--color-m-secondary-light);
  border: 4px solid transparent;
}

.quota-type-option--active {
  pointer-events: none;
  background-color: var(--color-white);
  border-color: var(--color-primary);
}

.quota-type-option__icon {
  width: 4rem;
  height: 4rem;
}

.quota-type-option__title {
  font-weight: var(--weight-bold);
}

.quota-type-option__description {
  @include set-text-s;
}
</style>
