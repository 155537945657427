export default {
  modalTitle: "Delete API",
  title: (apiLabel) => `You are about to delete ${apiLabel}`,
  warning: "Be aware that this action cannot be undone",
  explanationTitle: `By validating this action, your API will be unpublished (if not already done)
and all contracts will be deleted. The gateway will refuse all traffic.`,
  applicationsWithActivatedSecretSyncMessage:
    "Your consuming applications using Vault will have their keys deleted, and their is a risk they cannot start anymore. Have they cleaned their configuration?",
  prompt: (apiName) =>
    `If you really want to proceed, type <b>${apiName}</b>, then confirm.`,
  cancel: "Cancel",
  confirm: "Confirm",
  subtitle:
    "By validating this action, your API will be unpublished (if not already done) and all contracts will be deleted. The gateway will refuse all traffic.",
  confirmApiNameLabel: "Type your api name",
};
