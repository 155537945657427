import { ApiMapper } from "./api-mapper";
import { ApplicationMapper } from "./application-mapper";

import { ConversationItem } from "@/commons/domain/models/conversation-item";
import { ConversationItemDto } from "@/commons/dtos/conversation-item-dto";
import { RequestSummaryDto } from "@/commons/dtos/request-summary-dto";

export class ConversationItemMapper {
  static toDomain(requestSummaryDto: RequestSummaryDto) {
    const conversationItems: Array<ConversationItem> = [];
    if (requestSummaryDto.conversation) {
      for (const conversationItemDto of requestSummaryDto.conversation) {
        const conversationItem =
          ConversationItemMapper.toConversationItemDomain(
            conversationItemDto,
            requestSummaryDto,
          );
        conversationItems.push(conversationItem);
      }
    }
    return conversationItems;
  }

  static toConversationItemDomain(
    conversationItemDto: ConversationItemDto,
    requestSummaryDto: RequestSummaryDto,
  ): ConversationItem {
    return {
      createdAt: conversationItemDto.createdAt,
      author: conversationItemDto.author,
      title: conversationItemDto.title,
      message: conversationItemDto.message,
      application:
        (requestSummaryDto.application &&
          ApplicationMapper.toApplicationDomain(
            requestSummaryDto.application,
          )) ||
        undefined,
      api:
        (requestSummaryDto.api &&
          ApiMapper.toApiDomain(requestSummaryDto.api)) ||
        undefined,
      type: requestSummaryDto.type,
    };
  }
}
