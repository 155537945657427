<template>
  <tr :class="['create-custom-proxy-header', { disabled: isDisabled }]">
    <template v-if="showCreationForm">
      <td>
        <DvpField :errorMessage="form.firstError('name')">
          <MTextInput
            v-model="form.name"
            data-cy="create-custom-proxy-header-name-input"
            :placeholder="contents.namePlaceholder"
            :disabled="isDisabled"
            :isInvalid="isInvalidName"
          />
        </DvpField>
      </td>
      <td>
        <DvpField :errorMessage="form.firstError('value')">
          <MTextInput
            v-model="form.value"
            :placeholder="contents.valuePlaceholder"
            :disabled="isDisabled"
            :isInvalid="isInvalidValue"
          />
        </DvpField>
      </td>
      <td v-if="allowHeaderOverride">
        <FormInput :error="form.firstError('isOverridingClientHeader')">
          <ToggleButton
            v-model="form.isOverridingClientHeader"
            class="create-custom-proxy-header__override-toggle"
            :disabled="isDisabled"
          />
        </FormInput>
      </td>
      <td>
        <CancelAndSubmitButtons
          class="create-custom-proxy-header__cancel-and-submit-buttons"
          @cancel="cancel"
          @submit="create"
        />
      </td>
    </template>
    <template v-else>
      <td :colspan="allowHeaderOverride ? 4 : 3">
        <AddPolicyButton :label="contents.buttonAdd" @add="edit" />
      </td>
    </template>
  </tr>
</template>

<script lang="ts">
import MTextInput from "@mozaic-ds/vue-3/src/components/textinput/MTextInput.vue";

import CancelAndSubmitButtons from "@/commons/components/Buttons/CancelAndSubmitButtons.vue";
import FormInput from "@/commons/components/FormInput.vue";
import ToggleButton from "@/commons/components/ToggleButton.vue";
import DvpField from "@/commons/components/form/DvpField.vue";
import AddPolicyButton from "@/manager/views/ApiDetailPolicies/AddPolicyButton.vue";

import contents from "@/manager/contents/create-custom-proxy-header";

export default {
  name: "CreateCustomProxyHeader",
  components: {
    DvpField,
    CancelAndSubmitButtons,
    ToggleButton,
    FormInput,
    AddPolicyButton,
    MTextInput,
  },
  props: {
    formParent: {
      type: Object,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    allowHeaderOverride: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["create", "close"],
  data() {
    return {
      contents,
      isEditing: false,
    };
  },
  computed: {
    showCreationForm() {
      return this.isEditing || this.hasError;
    },
    isInvalidName() {
      return this.form.firstError("name") != null;
    },
    isInvalidValue() {
      return this.form.firstError("value") != null;
    },
  },
  methods: {
    async create() {
      if (!this.isDisabled) {
        await this.form.validate();
        await this.formParent.validate();

        if (!this.form.errors.length && !this.formParent.errors.length) {
          var { name, value, isOverridingClientHeader } = this.form.data();
          if (!this.allowHeaderOverride) {
            isOverridingClientHeader = undefined;
          }
          this.$emit("create", { name, value, isOverridingClientHeader });
          this.isEditing = false;
        }
      }
    },
    async cancel() {
      if (!this.isDisabled) {
        await this.form.clear();
        await this.formParent.validate();
        this.isEditing = false;
        this.$emit("close");
      }
    },
    async edit() {
      this.isEditing = true;
      this.form.clear();
    },
  },
};
</script>

<style lang="scss">
.create-custom-proxy-header__override-toggle {
  margin-top: 0.5rem;
}

.create-custom-proxy-header__cancel-and-submit-buttons {
  margin-top: 0.5rem;
}
</style>
