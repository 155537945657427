const SlideShow = function SlideShow(instance) {
  this.instance = instance;
  this.running = false;
  this.config = this.instance.config.slideshow;
};

SlideShow.prototype.start = function () {
  const that = this;

  if (!that.running) {
    that.running = true;
    that.instance.slideIndex = that.instance.index;
    that.instance.interval = setInterval(function () {
      that.instance.config.onBeforeStart.call(
        that.instance,
        that.instance.slideIndex,
      );
      setTimeout(function () {
        if (that.instance.config.infinite) {
          that.instance._overScroll(true);
        }

        if (that.instance.index < that.instance.pages.length - 1) {
          that.instance.slideIndex++;
        } else {
          that.instance.slideIndex = 0;
        }

        that.instance.scrollToIndex(that.instance.slideIndex);
      }, that.config.delay || 0);
    }, that.config.interval);
  }
};

SlideShow.prototype.stop = function () {
  if (this.running) {
    clearInterval(this.instance.interval);
    this.instance.slideInterval = false;
    this.running = false;
  }
};

export default SlideShow;
