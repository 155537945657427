const {
  EDocumentationPageSource,
} = require("@/commons/domain/models/documentation-page");

module.exports = {
  submit: "Submit and check",
  cancel: "Cancel",
  urlMethod: "URL Method is the best one",
  preferUrlMethod: "Prefer URL Method",
  documentationUpToDate:
    "Like this, your documentation will always be up-to-date.",
  viewDocumentation: "View documentation",
  documentTitle: "Document title",
  documentMethod: "Document method",
  documentUrl: "Document URL",
  documentContent: "Documentation content",
  selectFileToUpload: "Select a file to upload",
  documentationTypes: [
    {
      text: "Url",
      value: EDocumentationPageSource.URL,
    },
    {
      text: "File",
      value: EDocumentationPageSource.FILE,
    },
    {
      text: "Content",
      value: EDocumentationPageSource.CONTENT,
    },
  ],
};
