import { OAuthDefinition } from "@/commons/domain/models/oauth-definition";
import { OAuthDefinitionDto } from "@/commons/dtos/oauth-definition-dto";

export class OauthDefinitionMapper {
  static toDomain(
    oauthDefinitionDtos: OAuthDefinitionDto[],
  ): OAuthDefinition[] {
    return (
      oauthDefinitionDtos &&
      oauthDefinitionDtos.map((oauthDefinitionDto) => {
        return {
          provider: oauthDefinitionDto.provider,
          tokenUrl: oauthDefinitionDto.tokenUrl,
          authorizationUrl: oauthDefinitionDto.authorizationUrl,
        };
      })
    );
  }
}
