<template>
  <div class="app-list-item">
    <div class="app-list-item__header">
      <div class="app-list-item__app-name">{{ application.name }}</div>
      <div v-if="application.product" class="app-list-item__product">
        {{ contents.from }} "{{ application.product.name }}"
      </div>
      <div v-if="application.forTesting" class="app-list-item__for-testing">
        {{ contents.testApplication }}
      </div>
      <div class="app-list-item__description">
        {{
          application.description
            ? shortDescription(application.description)
            : contents.descriptionPlaceholder
        }}
      </div>
    </div>
    <div class="app-list-item__footer">
      <div
        v-if="metrics.apiKeysExpired > 0"
        class="app-list-item__api-keys-expired"
      >
        <div class="app-list-item__api-keys-expired-icon">
          <MIcon name="NotificationWarning32" color="var(--color-danger)" />
        </div>

        {{ metrics.apiKeysExpired }} {{ contents.apiKeys }}
      </div>
      <div
        v-if="metrics.numberOfPendingRequest > 0"
        class="app-list-item__pending-request"
      >
        <img :src="routingIcon" />
        {{ metrics.numberOfPendingRequest }} {{ contents.pendingRequest }}
      </div>
      <div class="app-list-item__api-types">
        <img :src="apiIcon" />
        <template
          v-if="metrics.contracts.restAPI > 0 || metrics.contracts.topics > 0"
        >
          <span v-if="metrics.contracts.restAPI > 0">
            {{ metrics.contracts.restAPI }}
            {{ contents.apiRestContracts }}
          </span>
          <span
            v-if="metrics.contracts.restAPI > 0 && metrics.contracts.topics > 0"
          >
            -
          </span>
          <span v-if="metrics.contracts.topics > 0">
            {{ metrics.contracts.topics }} {{ contents.topicContracts }}
          </span>
        </template>
        <template v-else>{{ contents.noContract }}</template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import MIcon from "@mozaic-ds/vue-3/src/components/icon/MIcon.vue";

import contents from "@/dashboard/contents/app-list-item";

export default {
  name: "ApiListItem",
  components: {
    MIcon,
  },
  props: {
    application: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      contents,
      apiIcon: require("@/commons/assets/icons/icon-api.svg"),
      routingIcon: require("@/commons/assets/icons/icon-routing.svg"),
    };
  },
  computed: {
    defaultIcon() {
      return this.application.forTesting
        ? require("@/commons/assets/icons/icon-app-test.svg")
        : require("@/commons/assets/icons/icon-app-default.svg");
    },
    metrics() {
      return this.application.metrics;
    },
  },
  methods: {
    shortDescription(description) {
      if (description.length > 115) {
        return description.slice(0, 114) + "...";
      }
      return description;
    },
  },
};
</script>

<style>
.app-list-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 20rem;
  background-color: var(--color-white);
  border: var(--border-default);
  border-radius: var(--m-border-radius-large);
}

.app-list-item__header {
  padding: 1.5rem 1.5rem 1rem;
}

.app-list-item__app-name {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 700;
}

.app-list-item__product,
.app-list-item__for-testing {
  margin-bottom: 0.5rem;
  font-size: 0.875rem; /* 14px */
  font-weight: 400;
}

.app-list-item__description {
  font-size: 0.875rem; /* 14px */
  color: var(--color-text-minor);
  word-break: break-word;
}

.app-list-item__footer {
  padding: 1rem 1.5rem;
  border-top: var(--border-default);
}

.app-list-item__api-keys-expired-icon {
  display: flex;
  justify-content: center;
  width: 2.85rem;
}

.app-list-item__api-keys-expired,
.app-list-item__pending-request,
.app-list-item__api-types {
  display: flex;
  align-items: center;
  font-size: 0.813rem; /* 13px */
  font-weight: 400;
}

.app-list-item__api-types {
  font-weight: 700;
}
</style>
