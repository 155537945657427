<template>
  <div class="add-user">
    <MAutocomplete
      v-model="selectedUserId"
      class="add-user__user-selection"
      :items="userItems"
      :filter="false"
      dataValueExpr="id"
      dataTextExpr="name"
      :input="search"
      :placeholder="contents.searchUserPlaceholder"
      @update:input="debouncedSearchUsers"
    >
      <template #item="{ item }">
        <div class="add-user__user-selection-item">
          <UserRoleIcon :user="item" />
          <span class="add-user__user-selection-item-username">{{
            item.name
          }}</span>
          <span
            v-if="item.unknownLDAP"
            class="add-user__user-selection-item-unknown-ldap"
          >
            ({{ contents.unknownLDAP }})
          </span>
        </div>
      </template>
    </MAutocomplete>

    <MSelect
      v-if="canChangeAction"
      id="add-user__role-select"
      v-model="form.role"
      class="add-user__role-select"
      :options="roleOptions"
    />

    <MButton
      class="add-user__submit-button"
      :label="contents.addUserButton"
      type="button"
      :disabled="form.errors.length > 0"
      @click="submitUser"
    />
  </div>
</template>

<script lang="ts">
import MAutocomplete from "@mozaic-ds/vue-3/src/components/autocomplete/MAutocomplete.vue";
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import MSelect from "@mozaic-ds/vue-3/src/components/select/MSelect.vue";
import { PropType } from "vue";

import UserRoleIcon from "@/commons/components/UserRoleIcon.vue";

import { Debouncer } from "@/commons/libs/utils/debouncer";
import { getUserRepository } from "@/commons/repositories/libs/get-user-repository";
import { mapRolesToMSelectOptions } from "@/commons/utils/role.utils";
import {
  getUserNameWithValidLDAP,
  isValidLDAP,
} from "@/commons/utils/user-utils";

import { MSelectOption } from "@/commons/domain/models/mozaic";
import { CurrentUser } from "@/commons/domain/models/user";
import { AddUserAclForm } from "@/commons/forms/add-user-acl-form";

import { ERole } from "@/commons/store/types";

import contents from "@/commons/contents/group-memberships-modal";

export default {
  components: {
    MAutocomplete,
    MSelect,
    MButton,
    UserRoleIcon,
  },
  props: {
    defaultRole: {
      type: String as PropType<ERole>,
      default: undefined,
    },
    canChangeAction: {
      type: Boolean,
      default: true,
    },
    roles: {
      type: Array as PropType<ERole[]>,
      default: () => [],
    },
  },
  emits: ["userSubmitted"],
  data() {
    return {
      contents,
      form: AddUserAclForm.create(),
      search: "",
      userItems: [] as { id: string; name: string; unknownLDAP: boolean }[],
      debouncer: new Debouncer(),
    };
  },
  computed: {
    currentUser(): CurrentUser {
      return this.$store.getters["user"];
    },
    selectedUserId: {
      get(): string | undefined {
        return this.form.user?.id;
      },
      set(newValue: string): void {
        this.form.user = this.userItems?.find((user) => user.id === newValue);
      },
    },
    roleOptions(): MSelectOption[] {
      return mapRolesToMSelectOptions(this.roles);
    },
  },
  async created() {
    await this.reinitComponentData();
  },
  methods: {
    async reinitComponentData(): Promise<void> {
      await this.initForm();
      await this.searchUsers("", true);
    },
    async initForm(): Promise<void> {
      await this.form.init({ role: this.defaultRole });
    },
    debouncedSearchUsers(searchValue: string): void {
      this.debouncer.debounce(() => this.searchUsers(searchValue, true), 200);
    },
    async searchUsers(
      searchValue: string,
      activeOnly: boolean = false,
    ): Promise<void> {
      this.search = searchValue;
      const foundUsers = (
        await getUserRepository().findUsersBySearch(this.search, activeOnly)
      ).data;
      this.userItems = foundUsers.map((user) => ({
        id: user.id,
        name: getUserNameWithValidLDAP(
          user.name,
          user.username,
          this.currentUser.id === user.id,
        ),
        unknownLDAP: isValidLDAP(user.username) === false,
      }));
    },
    submitUser(): void {
      if (this.form.errors.length === 0) {
        this.$emit("userSubmitted", this.form.data());
        this.reinitComponentData();
      }
    },
  },
};
</script>

<style lang="scss">
.add-user {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
}

.add-user__user-selection {
  flex: 1;
}

.add-user__user-selection-item {
  display: flex;
  align-items: center;
  column-gap: 0.75rem;
}

.add-user__user-selection-item-username {
  text-transform: capitalize;
}

.add-user__user-selection-item-unknown-ldap {
  font-style: italic;
  color: var(--color-m-grey-600);
}

.add-user__role-select {
  width: 10rem !important;
}
</style>
