<template>
  <Stack
    v-if="form.newValue"
    :class="[
      'input-texts-list',
      {
        'input-texts-list--disabled': isDisabled,
      },
    ]"
  >
    <div
      v-for="(valueForm, index) in form.values"
      :key="`${computedId}-input-${index}`"
    >
      <FormInput :error="valueForm.firstError('value')">
        <TextInput
          ref="values"
          reference="input"
          :modelValue="valueForm.value"
          :placeholder="placeholder"
          :isDisabled="isDisabled"
          :suffix="!isDisabled"
          debounce
          @update:modelValue="valueForm.value = $event"
          @enter="updateAndMoveToNextValue(index)"
        >
          <template #textInputSuffix>
            <span class="input-texts-list__remove" @click="removeValue(index)">
              <MIcon name="ControlCircleCross32" />
            </span> </template
        ></TextInput>
      </FormInput>
    </div>

    <template v-if="!isDisabled">
      <FormInput :error="form.newValue.firstError('value')">
        <TextInput
          ref="newValue"
          reference="input"
          :modelValue="form.newValue.value"
          :placeholder="placeholder"
          :isDisabled="isDisabled"
          debounce
          @update:modelValue="form.newValue.value = $event"
          @enter="addNewValue"
        />
      </FormInput>

      <div @click="addNewValue">
        <SvgWithLabel
          :class="[
            'input-texts-list__add',
            {
              'input-texts-list__add--disabled': !!form.newValue.errors.length,
            },
          ]"
        >
          <IconAddInput />
          <template #right>
            {{ contents.addValueLabel }}
          </template>
        </SvgWithLabel>
      </div>
    </template>
  </Stack>
</template>

<script lang="ts">
import MIcon from "@mozaic-ds/vue-3/src/components/icon/MIcon.vue";

import FormInput from "@/commons/components/FormInput.vue";
import Stack from "@/commons/components/Stack.vue";
import SvgWithLabel from "@/commons/components/SvgWithLabel.vue";
import TextInput from "@/commons/components/TextInput.vue";

import IconAddInput from "./IconAddInput.vue";

const contents = {
  addValueLabel: "Add new value",
};

export default {
  name: "InputTextsList",
  components: {
    Stack,
    FormInput,
    TextInput,
    SvgWithLabel,
    IconAddInput,
    MIcon,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    form: {
      type: Object,
      default: null,
    },
    placeholder: {
      type: String,
      default: "",
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      contents,
    };
  },
  computed: {
    computedId() {
      return this.id ?? `input-texts-list-id-${this.$.uid}`;
    },
  },
  methods: {
    async addNewValue() {
      await this.form.newValue.validate();

      if (!this.form.newValue.errors.length) {
        const { value } = this.form.newValue.data();
        this.form.$addValue(value);
        this.form.newValue.clear();
        this.$refs.newValue.$refs.input.focus();
      }
    },
    removeValue(valueIndex: number) {
      this.form.values.splice(valueIndex, 1);
      this.moveToNextValue(valueIndex - 1);
    },
    async updateAndMoveToNextValue(valueIndex: number) {
      const valueForm = this.form.values[valueIndex];
      await valueForm.validate();

      if (!valueForm.errors.length) {
        this.moveToNextValue(valueIndex);
      }
    },
    moveToNextValue(valueIndex: number) {
      if (valueIndex === this.form.values.length - 1) {
        this.$refs.newValue.$refs.input.focus();
      } else {
        this.$refs.values[valueIndex + 1].$refs.input.focus();
      }
    },
  },
};
</script>

<style lang="scss">
.input-texts-list {
  --stack-layout-glutter: 0.5rem;

  .input-texts-list__add {
    cursor: pointer;

    --svg-with-label-color: var(--color-primary);
  }

  .input-texts-list__remove i {
    font-size: 1.5rem;
    cursor: pointer;
  }

  .input-textx-list__valid {
    position: absolute;
  }

  .input-texts-list__remove i:hover {
    opacity: 0.6;
  }

  .input-texts-list__add.input-texts-list__add--disabled {
    opacity: 0.6;
  }

  .adornment__suffix {
    background: none;
  }
}
</style>
