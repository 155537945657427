<template>
  <div class="authentication-oauth-settings">
    <AuthenticationModeSettings
      :apiId="currentApi.id"
      :requestedAuthType="EAuthType.API_KEY"
      :optionCardTitle="contents.oAuthCardTitle"
      :optionCardDescription="contents.oAuthCardDescription"
      authTypeDocAnchor="#oauth2-authentication-mode"
      :authChangeIntro="contents.changeToApiKeyIntro"
      :authChangeButtonLabel="contents.changeToApiKeyBtn"
    />
    <OauthFlowSettings />
    <ScopesSettings
      v-if="oauthScopesFeatureIsEnabled"
      id="scopes"
      :apiId="currentApi.id"
      :hasScopes="currentApi.oauthScopes?.length > 0"
    />
  </div>
</template>

<script lang="ts">
import AuthenticationModeSettings from "@/manager/views/ApiDetailAuthentication/AuthenticationModeSettings.vue";
import OauthFlowSettings from "@/manager/views/ApiDetailAuthentication/OauthFlowSettings.vue";
import ScopesSettings from "@/manager/views/ApiDetailAuthentication/ScopesSettings.vue";

import { scrollToAnchor } from "@/commons/libs/scroll-to-anchor";

import { Api } from "@/commons/domain/models/api";

import { EAuthType } from "@/commons/store/types";

import contents from "@/manager/contents/authentication";

export default {
  components: {
    AuthenticationModeSettings,
    OauthFlowSettings,
    ScopesSettings,
  },
  data() {
    return {
      contents,
      EAuthType,
    };
  },
  computed: {
    currentApi(): Api {
      return this.$store.getters["currentApi"];
    },
    frontendConfig() {
      return this.$store.getters["config/frontendConfig"];
    },
    oauthScopesFeatureIsEnabled(): boolean {
      return this.frontendConfig?.oauthScopes?.fullFeatureIsEnabled == true;
    },
  },
  mounted() {
    scrollToAnchor(this.$route.hash);
  },
};
</script>
