<template>
  <CardContainer
    v-if="appHasSubscriptionWithPublicApi"
    class="declare-ip-addresses-card"
    data-cy="ip-filtering"
  >
    <h4 class="declare-ip-addresses-card__card-title">
      {{ contents.publicIPAddressesTitle }}
    </h4>
    <Markdown :content="contents.publicIPAddressesDescription" />
    <MDataTable
      v-if="ipAddresses?.length > 0"
      data-cy="ip-filtering-datatable"
      class="declare-ip-addresses-card__ip-addresses-listing"
      :sorting="{ mode: 'none' }"
      :headers="tableHeaders"
      :source="ipAddresses"
    >
      <template #[`item.actions`]="{ item }">
        <ActionButtonsWrapper>
          <ButtonEdit
            data-cy="edit-ip-address-button"
            @click="onEditIPAddressClick(item)"
          />
          <ButtonDelete
            data-cy="delete-ip-address-button"
            @click="onDeleteIPAddressClick(item)"
          />
        </ActionButtonsWrapper>
      </template>
    </MDataTable>
    <div class="declare-ip-addresses-card__ip-addresses-listing-actions">
      <MButton
        data-cy="declare-new-ip-address"
        :label="contents.addNewIPAddressBtnLabel"
        type="button"
        @click="onAddIPAddressClick"
      />
    </div>
  </CardContainer>
</template>

<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import MDataTable from "@mozaic-ds/vue-3/src/components/datatable/MDataTable.vue";
import sortBy from "lodash-es/sortBy";
import { markRaw } from "vue";

import ActionButtonsWrapper from "@/commons/components/Buttons/ActionButtonsWrapper.vue";
import ButtonDelete from "@/commons/components/Buttons/ButtonDelete.vue";
import ButtonEdit from "@/commons/components/Buttons/ButtonEdit.vue";
import CardContainer from "@/commons/components/CardContainer.vue";
import DeleteModalForm from "@/commons/components/form/DeleteModalForm.vue";
import IPAddressesEditionModal from "@/dashboard/views/AppDetails/IPAddressesEditionModal.vue";
import Markdown from "@/documentation/components/commons/Markdown.vue";

import { Application } from "@/commons/domain/models/application";
import { IPFiltering } from "@/commons/domain/models/ip-filtering";
import { IPRange } from "@/commons/domain/models/ip-range";
import {
  addNewIPRangeToApplication,
  deleteIPRangeOfApplication,
  updateIPRangeOfApplication,
} from "@/commons/services/application/application-ip-range.service";

import contents from "@/dashboard/contents/declare-ip-addresses-card";
import contentsOfIPAddressesEditionModal from "@/dashboard/contents/ip-addresses-edition-modal";

export default {
  components: {
    CardContainer,
    Markdown,
    MDataTable,
    ActionButtonsWrapper,
    ButtonEdit,
    ButtonDelete,
    MButton,
  },
  emits: ["onClose"],
  data() {
    return {
      contents,
    };
  },
  computed: {
    currentApplication(): Application {
      return this.$store.getters["currentApplication"];
    },
    currentApplicationIPFiltering(): IPFiltering {
      return this.currentApplication?.ipFiltering || {};
    },
    appHasSubscriptionWithPublicApi(): boolean {
      return this.currentApplication?.hasSubscriptionWithPublicApi || false;
    },
    ipAddresses(): IPRange[] {
      return sortBy(
        this.currentApplicationIPFiltering?.ipRanges || [],
        "address",
      );
    },
    tableHeaders() {
      return [
        {
          caption: contents.ipAddressRangeHeaderName,
          dataFieldExpr: "address",
        },
        {
          caption: contents.commentHeaderName,
          dataFieldExpr: "comment",
        },
        {
          dataFieldExpr: "actions",
          cssClass: "declare-ip-addresses-card__action-column",
        },
      ];
    },
  },
  async mounted() {
    await this.loadApplication();
  },
  methods: {
    async loadApplication(): Promise<void> {
      await this.$store.dispatch("loadCurrentApplication", {
        appId: this.currentApplication.id,
      });
    },
    async onAddIPAddressClick(): Promise<void> {
      this.$store.commit("openLayerModal", {
        title: contentsOfIPAddressesEditionModal.createModalTitle,
        component: markRaw(IPAddressesEditionModal),
        props: {
          applicationId: this.currentApplication.id,
        },
        listeners: {
          submit: async (ipRange: IPRange) => {
            await addNewIPRangeToApplication(
              ipRange,
              this.currentApplication.id,
            );
            this.$store.commit("postSuccessNotification", {
              title: contents.ipAddressWasSuccessfullyAddedMessage,
            });
            await this.loadApplication();
          },
        },
      });
    },
    async onEditIPAddressClick(ipRange: IPRange): Promise<void> {
      this.$store.commit("openLayerModal", {
        title: contentsOfIPAddressesEditionModal.createModalTitle,
        component: markRaw(IPAddressesEditionModal),
        props: {
          applicationId: this.currentApplication.id,
          formData: ipRange,
          confirmBtnLabel:
            contentsOfIPAddressesEditionModal.updateIPAddressBtnLabel,
        },
        listeners: {
          submit: async (ipRange: IPRange) => {
            await updateIPRangeOfApplication(
              ipRange,
              this.currentApplication.id,
            );
            this.$store.commit("postSuccessNotification", {
              title: contents.ipAddressWasSuccessfullyUpdatedMessage,
            });
            await this.loadApplication();
          },
        },
      });
    },
    async onDeleteIPAddressClick(ipRange: IPRange): Promise<void> {
      this.$store.commit("openLayerModal", {
        component: markRaw(DeleteModalForm),
        title: contents.modalTitleDeleteIPRange,
        props: {
          title: contents.deleteIPRangeTitle(ipRange.address),
          content: contents.deleteIPRangeMessage,
        },
        listeners: {
          onSubmit: async () => {
            await deleteIPRangeOfApplication(
              ipRange.id,
              this.currentApplication.id,
            );
            this.$store.commit("postSuccessNotification", {
              title: contents.ipAddressWasSuccessfullyDeletedMessage,
            });
            await this.loadApplication();
          },
        },
      });
    },
  },
};
</script>

<style lang="scss">
.declare-ip-addresses-card__card-title {
  margin-top: 0;
  margin-bottom: 1.5rem;
}

.declare-ip-addresses-card__ip-addresses-listing {
  margin-top: 2rem;
}

.declare-ip-addresses-card__action-column {
  width: 0;
}

.declare-ip-addresses-card__ip-addresses-listing-actions {
  display: flex;
  justify-content: flex-end;

  margin-top: 2rem;
}
</style>
