export interface MCheckboxGroupOption {
  // id is required for MCheckbox option
  id: string;
  label: string;
  value: string;
}

export interface MSelectOption {
  text: string;
  value: string;
}

export interface MDropdownItem {
  label: string;
  value: string;
}

export interface MTagListItem {
  id: string;
  label: string;
  type?: EMTagListType;
}

export enum EMTagListType {
  REMOVABLE = "removable",
  SELECTABLE = "selectable",
}

export interface MFlagItem {
  label: string;
  theme?: string;
  htmlTag?: string;
}

export interface MDataTableHeaderItem {
  caption: string;
  dataFieldExpr: string;
  sortFieldExpr?: string;
  allowSorting?: boolean;
  sortOrder?: "asc" | "desc" | null;
}

export interface MDataTableV2HeaderItem {
  label?: string;
  value: string;
  sortable?: boolean;
  sortDirectionDefault?: "ASC" | "DESC";
  additionnalProps?: object;
  class?: string;
  e2eSelector?: string;
}

export interface MTabsV2Item {
  label: string;
  class?: string;
  icon?: string;
  href?: string;
  router?: string;
  to?: string;
  disabled?: boolean;
}

export interface MRadioButtonItem {
  id: string;
  value: string;
  label: string;
}
