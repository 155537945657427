<template>
  <div
    ref="parent"
    :class="[
      {
        'is-focused': isFocus,
        'has-value': modelValue,
        'has-error': errorHint,
        'is-disabled': isDisabled,
        'is-dark': dark,
        'no-label': noLabel,
      },
      inputSize,
    ]"
    class="field flex align-center"
    @click="focusInput"
  >
    <input
      v-bind="$attrs"
      :id="$attrs.id"
      ref="CustomInput"
      :value="modelValue"
      :placeholder="label"
      :style="[borderStyle]"
      type="text"
      class="field-input"
      :class="{ 'no-clear-button': noClearButton }"
      readonly
      @focus="$emit('focus')"
      @blur="$emit('blur')"
      @click="$emit('click')"
    />
    <label
      v-if="!noLabel"
      ref="label"
      :for="$attrs.id"
      :class="errorHint ? 'text-danger' : null"
      :style="[colorStyle]"
      class="field-label"
      @click="focusInput"
    >
      {{ hint || label }}
    </label>
    <CustomButton
      v-if="hasClearButton"
      :color="dark ? '#757575' : 'rgba(0, 0, 0, 0.54)'"
      :dark="dark"
      class="field-clear-button"
      round
      @click="$emit('clear')"
    >
      <span class="fs-16"> ✕ </span>
    </CustomButton>
  </div>
</template>

<script lang="ts">
import CustomButton from "@/commons/components/DatePicker/_subs/CustomButton/index.vue";

export default {
  name: "CustomInput",
  components: {
    CustomButton,
  },
  inheritAttrs: false,
  props: {
    isFocus: { type: Boolean, default: false },
    modelValue: { type: [String, Object], required: false, default: null },
    label: { type: String, default: "Select date & time" },
    noLabel: { type: Boolean, default: false },
    hint: { type: String, default: null },
    errorHint: { type: Boolean, default: null },
    color: { type: String, default: null },
    dark: { type: Boolean, default: false },
    inputSize: { type: String, default: null },
    noClearButton: { type: Boolean, default: false },
  },
  emits: ["focus", "blur", "click", "clear"],
  computed: {
    borderStyle() {
      const cond = this.isFocus && !this.errorHint;
      return cond ? { border: `1px solid ${this.color}` } : null;
    },
    colorStyle() {
      const cond = this.isFocus;
      return cond ? { color: `${this.color}` } : null;
    },
    hasClearButton() {
      return !this.noClearButton && !this.isDisabled && this.modelValue;
    },
    /**
     * Returns true if the field is disabled
     * @function isDisabled
     * @returns {boolean}
     */
    isDisabled() {
      return (
        typeof this.$attrs.disabled !== "undefined" &&
        this.$attrs.disabled !== false
      );
    },
  },
  methods: {
    focusInput() {
      this.$refs.CustomInput.focus();
      this.$emit("focus");
    },
  },
};
</script>

<style lang="scss" scoped>
.field {
  position: relative;
  &.is-dark {
    .field-label {
      color: rgb(255 255 255 / 70%);
    }
    .field-input {
      color: rgb(255 255 255 / 70%);
      background-color: #424242;
      border-color: rgb(255 255 255 / 70%);
    }
    &.is-disabled {
      .field-label,
      .field-input {
        color: #000;
      }
    }
  }
  &-label {
    position: absolute;
    top: 5px;
    left: 13px;
    font-size: 11px;
    color: rgb(0 0 0 / 54%);
    cursor: pointer;
    opacity: 0;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    transform: translateY(25%);
  }
  &-input {
    position: relative;
    z-index: var(--z-index-background);
    width: 100%;
    height: 42px;
    min-height: 42px;
    padding-right: 44px;
    padding-left: 12px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    background-color: var(--color-white);
    border: 1px solid rgb(0 0 0 / 20%);
    border-radius: 4px;
    outline: none;
    transition-duration: 0.3s;
    appearance: none;
    &.no-clear-button {
      padding: 0 12px;
    }
  }
  &-clear-button {
    position: absolute;
    top: 0;
    right: 12px;
    bottom: 0;
    margin: auto 0;
  }
  &.has-error {
    .field-input {
      border-color: orangered;
    }
    .field-label {
      font-size: 11px;
      opacity: 1;
      transform: translateY(0);
    }
    .field-input {
      padding-top: 14px;
    }
  }
  &.has-value {
    .field-label {
      font-size: 11px;
      opacity: 1;
      transform: translateY(0);
    }
    &:not(.no-label) {
      .field-input {
        padding-top: 14px;
      }
    }
  }
  &.is-focused {
    .field-input {
      border-color: dodgerblue;
    }
    .field-label {
      color: dodgerblue;
    }
  }
  &.is-disabled {
    .field-input {
      background: #f2f2f2;
      border-color: #ccc;
    }
    .field-label,
    .field-input {
      cursor: default;
    }
  }
  .text-danger {
    color: orangered;
  }
  &.is-dark {
    ::input-placeholder {
      /* WebKit, Blink, Edge */
      color: rgb(255 255 255 / 70%);
    }
    :placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: rgb(255 255 255 / 70%);
      opacity: 1;
    }
    ::placeholder {
      /* Mozilla Firefox 19+ */
      color: rgb(255 255 255 / 70%);
      opacity: 1;
    }
    :input-placeholder {
      /* Internet Explorer 10-11 */
      color: rgb(255 255 255 / 70%);
    }
    ::input-placeholder {
      /* Microsoft Edge */
      color: rgb(255 255 255 / 70%);
    }
    ::placeholder {
      /* Most modern browsers support this now. */
      color: rgb(255 255 255 / 70%);
    }
    &.is-disabled {
      ::input-placeholder {
        /* WebKit, Blink, Edge */
        color: #424242;
      }
      :placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #424242;
        opacity: 1;
      }
      ::placeholder {
        /* Mozilla Firefox 19+ */
        color: #424242;
        opacity: 1;
      }
      :input-placeholder {
        /* Internet Explorer 10-11 */
        color: #424242;
      }
      ::input-placeholder {
        /* Microsoft Edge */
        color: #424242;
      }
      ::placeholder {
        /* Most modern browsers support this now. */
        color: #424242;
      }
    }
  }
  &.sm {
    .field-input {
      height: 36px;
      min-height: 36px;
      font-size: 12px;
    }
    .field-label {
      font-size: 10px;
    }
    &.has-value:not(.no-label) {
      .field-input {
        padding-top: 12px;
      }
    }
  }
  &.lg {
    .field-input {
      height: 48px;
      min-height: 48px;
      font-size: 16px;
    }
    .field-label {
      font-size: 14px;
    }
    &.has-value:not(.no-label) {
      .field-input {
        padding-top: 16px;
      }
    }
  }
}
</style>
