import { ActionContext } from "vuex";

import { getApplicationRepository } from "@/commons/repositories/libs/get-application-repository";
import { buildFetchApplicationFilterOptionsParams } from "@/commons/store/modules/reporting/helpers/build-fetch-application-filter-options-params";
import { buildFetchApplicationReportingParams } from "@/commons/store/modules/reporting/helpers/build-fetch-application-reporting-params";

import { State } from "@/commons/store/modules/reporting/types";

async function loadApplicationReporting(
  { commit, getters }: ActionContext<State, any>,
  applicationId: string,
) {
  commit("fetchReporting");
  try {
    const { from, to } = getters.selectedDateRange;
    const { dateHistograms, interval, hits } =
      await getApplicationRepository().getApplicationReporting(
        applicationId,
        buildFetchApplicationReportingParams(getters.selectedFilters),
      );

    commit("fetchReportingSuccess", {
      dateHistograms,
      interval,
      from,
      to,
      hits,
    });
  } catch ({ data: error }) {
    commit("fetchReportingFail", error);
  }
}

async function loadApplicationReportingFiltersOptions(
  { commit, getters }: ActionContext<State, any>,
  applicationId: string,
) {
  commit("fetchReportingFiltersOptions");
  try {
    const data =
      await getApplicationRepository().getApplicationReportingFiltersOptions(
        applicationId,
        buildFetchApplicationFilterOptionsParams(getters.selectedDateRange),
      );

    commit("fetchReportingFiltersOptionsSuccess", data);
  } catch (error) {
    commit("fetchReportingFiltersOptionsFail", error.data);
  }
}

export default {
  loadApplicationReporting,
  loadApplicationReportingFiltersOptions,
};
