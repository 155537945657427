<template>
  <MBadge v-if="api.isAlreadySubscribed" type="success">{{
    contents.subscribed
  }}</MBadge>
  <div
    v-else-if="api.hasPendingRequestForAuthTypeChange"
    class="subscription-button__pending-request-info"
  >
    <MBadge type="warning">{{ contents.pendingRequest }}</MBadge>
    <DvpTooltip :text="contents.pendingRequestTooltip" position="left">
      <MIcon name="NotificationInformation24" />
    </DvpTooltip>
  </div>
  <MButton
    v-else
    class="subscription-button"
    size="s"
    :label="contents.subscriptionButton"
    @click="onSubscriptionClick"
  />
</template>

<script lang="ts">
import MBadge from "@mozaic-ds/vue-3/src/components/badge/MBadge.vue";
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import MIcon from "@mozaic-ds/vue-3/src/components/icon/MIcon.vue";
import { markRaw, PropType } from "vue";

import DvpTooltip from "@/commons/components/DvpTooltip.vue";
import { ModalClosedError } from "@/commons/components/Modal/modal.type";
import ApplicationFirstRedirectUriModal from "@/dashboard/views/AppDetailsContracts/modal/ApplicationFirstRedirectUriModal.vue";
import ContractAskValidationModal from "@/dashboard/views/AppDetailsContracts/modal/ContractAskValidationModal.vue";
import ContractOauthFlowSelectionModal from "@/dashboard/views/AppDetailsContracts/modal/ContractOauthFlowSelectionModal.vue";
import MultiACFProvidersPreventionStep1Modal from "@/dashboard/views/AppDetailsContracts/modal/MultiACFProvidersPreventionStep1Modal.vue";
import RefusedSubscriptionModal from "@/dashboard/views/AppDetailsContracts/modal/RefusedSubscriptionModal.vue";
import SubscribeScopesModal from "@/dashboard/views/AppDetailsContracts/modal/SubscribeScopesModal.vue";

import { getApiOauthProvider } from "@/commons/libs/utils/apiUtils";
import { convertRouteToHref } from "@/commons/utils/route-utils";
import { listContainsAdminScope } from "@/manager/utils/oauth-scopes";

import { Api } from "@/commons/domain/models/api";
import { Application } from "@/commons/domain/models/application";
import { CreateContractDto } from "@/commons/dtos/create-contract-dto";
import { EOauthFlow } from "@/commons/types/oauth-flow-types";

import { EAuthType } from "@/commons/store/types";

import contents from "@/commons/contents/subscribable-api-card";
import contentsOfMultiACFProvidersPreventionModal from "@/dashboard/contents/multi-acf-providers-prevention-modal";
import contentsOfRefusedSubscriptionModal from "@/dashboard/contents/refused-subscription-modal";
import contentSubscriptionStepper from "@/dashboard/contents/subscription-stepper";

export default {
  components: {
    DvpTooltip,
    MBadge,
    MButton,
    MIcon,
  },
  props: {
    api: {
      type: Object as PropType<Api>,
      required: true,
    },
  },
  data() {
    return {
      contents,
      passedSteps: [],
    };
  },
  computed: {
    currentApplication(): Application {
      return this.$store.getters["currentApplication"];
    },
    userIsInternal(): boolean {
      return this.$store.getters["userIsInternal"];
    },
    acfTokenOfCurrentApplication() {
      return this.$store.getters["acfTokenOfCurrentApplication"];
    },
    applicationHasAcfToken(): boolean {
      return this.acfTokenOfCurrentApplication != undefined;
    },
    acfOauthProviderOfCurrentApplication() {
      return this.acfTokenOfCurrentApplication?.oauthDefinition.provider;
    },
    subscriptionRequiresManagersValidation(): boolean {
      return (
        this.api.allowedActions["api.subscription.request.by-pass"] === false
      );
    },
    subscriptionIsBlockedBecauseOfMissingProduct(): boolean {
      return (
        this.currentApplication &&
        !this.currentApplication.forTesting &&
        !this.currentApplication.product &&
        this.userIsInternal
      );
    },
    apiProvidesIpFiltering(): boolean {
      return this.api?.isIPFilteringEnabled;
    },
    apiProvidesScopes(): boolean {
      return this.api?.oauthScopes?.length > 0;
    },
  },
  methods: {
    onSubscriptionClick(): void {
      if (this.subscriptionIsBlockedBecauseOfMissingProduct) {
        this.$store.commit("openModal", {
          title: contentsOfRefusedSubscriptionModal.modalTitle,
          component: markRaw(RefusedSubscriptionModal),
        });
      } else {
        this.startSubscriptionCreation(this.api);
      }
    },
    startSubscriptionCreation(api: Api): void {
      if (api.authType === EAuthType.OAUTH) {
        this.createOauthSubscription(api);

        //else: API KEY
      } else {
        this.createApiKeySubscription(api);
      }
    },
    async createOauthSubscription(api: Api): Promise<void> {
      const oauthFlow = await this.getOrAskOauthFlow(api);

      if (oauthFlow === EOauthFlow.CLIENT_CREDENTIALS) {
        this.createOauthCcfSubscription(api);
      } else {
        this.createOauthAcfSubscription(api);
      }
    },
    async createOauthAcfSubscription(api: Api): Promise<void> {
      if (await this.applicationHasACFTokenOnDifferentProvider(api)) {
        this.openMultiACFProvidersPreventionModal(api);
        return;
      }

      const createContractDto: CreateContractDto = {
        apiId: api.id,
        applicationId: this.currentApplication.id,
        oauthFlow: EOauthFlow.AUTHORIZATION_CODE,
      };

      if (!this.applicationHasAcfToken) {
        createContractDto.redirectUris = [
          await this.openApplicationFirstRedirectUriModal(api),
        ];
      }

      await this.enrichContractWithScopeSelection(createContractDto, api, []);

      this.handleUserRightsAndCreateContract(createContractDto, api);
    },
    async enrichContractWithScopeSelection(
      createContractDto: CreateContractDto,
      api: Api,
    ): Promise<void> {
      if (api?.oauthScopes?.length > 0) {
        createContractDto.scopes =
          await this.openOAuthScopesSelectionModal(api);
      }
    },
    async openOAuthScopesSelectionModal(api: Api): Promise<string[]> {
      return new Promise((resolve, reject) => {
        this.$store.commit("openLayerModal", {
          title: contents.contractCreationInfoModalTitle,
          component: markRaw(SubscribeScopesModal),
          props: {
            apiId: api.id,
            passedSteps: this.passedSteps,
            requiresManagersValidation:
              this.subscriptionRequiresManagersValidation,
          },
          listeners: {
            submit: (scopes: string[]) => {
              if (this.subscriptionShouldBeValidated(scopes)) {
                this.passedSteps.push({
                  label: contentSubscriptionStepper.stepSelectScopesTitle,
                  isCurrent: false,
                });
              }
              resolve(scopes);
            },
            onClose: () => {
              reject(new ModalClosedError());
            },
          },
        });
      });
    },
    async createOauthCcfSubscription(api: Api): Promise<void> {
      const createContractDto: CreateContractDto = {
        apiId: api.id,
        applicationId: this.currentApplication.id,
        oauthFlow: EOauthFlow.CLIENT_CREDENTIALS,
      };

      await this.enrichContractWithScopeSelection(createContractDto, api);

      this.handleUserRightsAndCreateContract(createContractDto, api);
    },
    async createApiKeySubscription(api: Api): Promise<void> {
      const createContractDto: CreateContractDto = {
        apiId: api.id,
        applicationId: this.currentApplication.id,
      };

      this.handleUserRightsAndCreateContract(createContractDto, api);
    },
    async getOrAskOauthFlow(api: Api): Promise<EOauthFlow> {
      if (api.oauthFlows.length == 1) {
        return api.oauthFlows[0];
      } else if (api.oauthFlows.length > 1) {
        return await this.openOauthFlowSelectionModal();
      } else {
        throw new Error(
          "Cannot subscribe to this OAuth API because it doesn't have any OAuth flow",
        );
      }
    },
    openOauthFlowSelectionModal(): Promise<EOauthFlow> {
      return new Promise((resolve, reject) => {
        this.$store.commit("openLayerModal", {
          title: contents.contractCreationInfoModalTitle,
          component: markRaw(ContractOauthFlowSelectionModal),
          props: {
            apiProvidesScopes: this.apiProvidesScopes,
            requiresManagersValidation:
              this.subscriptionRequiresManagersValidation,
          },
          listeners: {
            submit: (selectedFlow: EOauthFlow) => {
              this.passedSteps.push({
                label: contentSubscriptionStepper.stepSelectFlowTitle,
                isCurrent: false,
              });
              return resolve(selectedFlow);
            },
            onClose: () => {
              return reject(new ModalClosedError());
            },
          },
        });
      });
    },
    openMultiACFProvidersPreventionModal(api) {
      this.$store.commit("openLayerModal", {
        title: contentsOfMultiACFProvidersPreventionModal.modalTitle,
        component: markRaw(MultiACFProvidersPreventionStep1Modal),
        props: {
          application: this.currentApplication,
          apiACFProvider: getApiOauthProvider(api),
          applicationACFProvider: this.acfOauthProviderOfCurrentApplication,
        },
      });
    },
    openApplicationFirstRedirectUriModal(api: Api): Promise<string> {
      return new Promise((resolve, reject) => {
        this.$store.commit("openLayerModal", {
          title: contents.contractCreationInfoModalTitle,
          component: markRaw(ApplicationFirstRedirectUriModal),
          props: {
            oauthProvider: getApiOauthProvider(api),
            apiProvidesScopes: this.apiProvidesScopes,
            passedSteps: this.passedSteps,
            requiresManagersValidation:
              this.subscriptionRequiresManagersValidation,
          },
          listeners: {
            submit: (redirectUri: string) => {
              this.passedSteps.push({
                label: contentSubscriptionStepper.stepSetFirstRedirectUriTitle,
                isCurrent: false,
              });
              resolve(redirectUri);
            },
            onClose: () => {
              reject(new ModalClosedError());
            },
          },
        });
      });
    },
    openAskValidationModal(): Promise<string> {
      return new Promise((resolve, reject) => {
        this.$store.commit("openLayerModal", {
          title: contents.contractCreationInfoModalTitle,
          component: markRaw(ContractAskValidationModal),
          props: {
            passedSteps: this.passedSteps,
          },
          listeners: {
            submit: (comment: string) => {
              resolve(comment);
            },
            onClose: () => {
              reject(new ModalClosedError());
            },
          },
        });
      });
    },
    async handleUserRightsAndCreateContract(
      createContractDto: CreateContractDto,
    ): Promise<void> {
      if (this.subscriptionShouldBeValidated(createContractDto.scopes)) {
        createContractDto.comment = await this.openAskValidationModal();
      }

      await this.createContract(createContractDto);
      this.$store.commit("closeModal");
    },
    async createContract(createContractDto: CreateContractDto): Promise<void> {
      await this.$store.dispatch("createContract", createContractDto);

      if (this.apiProvidesIpFiltering) {
        this.$store.commit("postWarningNotification", {
          title: this.contents.ipFilteringWarningTitle,
          message: this.contents.ipFilteringWarningMessage,
          link: {
            label: this.contents.ipFilteringWarningLinkLabel,
            href: convertRouteToHref({
              name: "applicationSettings",
              params: { id: this.currentApplication?.id },
            }),
            openInNewTab: false,
          },
        });
      }
    },
    applicationHasACFTokenOnDifferentProvider(api: Api) {
      return (
        this.applicationHasAcfToken &&
        this.acfOauthProviderOfCurrentApplication != getApiOauthProvider(api)
      );
    },
    subscriptionShouldBeValidated(scopes: string[]): boolean {
      return (
        this.subscriptionRequiresManagersValidation ||
        listContainsAdminScope(scopes)
      );
    },
  },
};
</script>

<style lang="scss">
.subscription-button__pending-request-info {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
</style>
