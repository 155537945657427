<script setup lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import MCheckbox from "@mozaic-ds/vue-3/src/components/checkbox/MCheckbox.vue";
import MDataTableColumn from "@mozaic-ds/vue-3/src/components/datatablev2/MDataTableColumn.vue";
import MDataTableV2 from "@mozaic-ds/vue-3/src/components/datatablev2/MDataTableV2.vue";
import MTextInput from "@mozaic-ds/vue-3/src/components/textinput/MTextInput.vue";
import { computed, PropType, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import DvpTooltip from "@/commons/components/DvpTooltip.vue";
import IconTooltip from "@/commons/components/IconTooltip.vue";
import DvpField from "@/commons/components/form/DvpField.vue";
import ToggleExpandDescriptionButton from "@/manager/components/ToggleExpandDescriptionButton.vue";

import { Debouncer } from "@/commons/libs/utils/debouncer";
import { cropText, pluralize } from "@/commons/utils/contents-utils";
import { scopeIsDisabled, scopeIsSelected } from "@/manager/utils/oauth-scopes";

import { EDocumentationType } from "@/commons/domain/models/documentation-page";
import { Scope } from "@/commons/domain/models/scope";

import contents from "@/manager/contents/add-oauth-scopes";

const props = defineProps({
  apiId: {
    type: String,
    required: true,
  },
  selectedScopes: {
    type: Array as PropType<Scope[]>,
    required: true,
  },
  selectedScopesCount: {
    type: Number,
    default: 0,
  },
  scopes: {
    type: Array as PropType<Scope[]>,
    required: true,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  totalCount: {
    type: Number,
    required: true,
  },
  currentPage: {
    type: Number,
    default: 1,
  },
  documented: {
    type: Boolean,
    default: false,
  },
  selectionDisabled: {
    type: Boolean,
    default: false,
  },
});

const store = useStore();

const router = useRouter();

const emit = defineEmits(["loadScopes", "selectScope", "showScopeProducts"]);

const debouncer = new Debouncer();

/**
 * Params update (change page, size or search value) triggers new loading of scopes.
 */

const DEFAULT_PAGES_SIZE = 10;

const params = reactive({
  search: "",
  page: 1,
  size: DEFAULT_PAGES_SIZE,
  changePage: (page: number) => {
    params.page = page;
  },
  changeSize: (size: number) => {
    params.size = size;
  },
  changeSearchValue: (search: string) => {
    params.search = search;
  },
});

watch(params, () => {
  debouncer.debounce(() => {
    emit("loadScopes", params);
  }, 200);
});

const redirectToApiDocumentation = () => {
  router.push({
    name: "managerApiDetailDocumentation",
    params: {
      id: props.apiId,
    },
  });
};

const scopesList = computed(() => {
  return props.scopes.map((scope: Scope) => {
    return {
      ...scope,
      displayedDescription: scope.description
        ? cropText(scope.description, 200)
        : "-",
      productLink:
        scope.totalProductsCount > 0
          ? pluralize(scope.totalProductsCount, "product")
          : undefined,
      isSelected: scopeIsSelected(scope, props.selectedScopes),
      isDisabled: scopeIsDisabled(scope, props.selectedScopes),
      descriptionCouldBeExpanded:
        scope.description && scope.description.length > 200,
    };
  });
});

/**
 * Handle expanded scopes descriptions.
 */
const expandedScopes = ref([]);
const isScopeExpanded = (scopeName: string) =>
  expandedScopes.value.includes(scopeName);
const expandScope = (scopeName: string) => {
  expandedScopes.value = [...expandedScopes.value, scopeName];
};
const hideScope = (scopeName: string) => {
  expandedScopes.value = expandedScopes.value.filter(
    (expandedScopeName) => expandedScopeName !== scopeName,
  );
};

const handleSelectScope = (scope: Scope, isSelected: boolean) => {
  emit("selectScope", scope, isSelected);
};

const currentApi = computed(() => {
  return store.getters["currentApi"];
});

const apiHasNotPublishedSwagger = computed(() => {
  return currentApi.value?.documentationDetails?.missingRequiredPageTypes.includes(
    EDocumentationType.REST_API_SPECIFICATIONS,
  );
});
</script>

<template>
  <div class="scopes-list">
    <div class="scopes-list__actions-bar">
      <div>
        {{ contents.selectedItemsLabel(selectedScopes?.length) }}
      </div>
      <div class="scopes-list__actions">
        <DvpField
          :label="contents.searchScopeLabel"
          class="scopes-list__search-scope-input"
        >
          <MTextInput
            type="search"
            icon="DisplaySearch32"
            :placeholder="contents.searchScopePlaceholder"
            :modelValue="params.search"
            size="s"
            @update:modelValue="params.changeSearchValue"
          />
        </DvpField>
      </div>
    </div>
    <MDataTableV2
      class="scopes-list__datatable"
      data-cy="scopes-data-list"
      :items="scopesList"
      indexKey="name"
      :pageable="totalCount > 0"
      :itemsPerPage="params.size"
      :currentPage="currentPage"
      :totalItems="totalCount"
      showPartialPages
      hidePageCount
      :loading="isLoading"
      @update:current-page="params.changePage"
      @update:items-per-page="params.changeSize"
    >
      <MDataTableColumn
        value="mastercheckbox"
        class="mc-datatable__cell-checkbox"
      />
      <MDataTableColumn
        class="scopes-list__datatable-name-column"
        :label="contents.scopeNameTableHeader"
        value="name"
      />
      <MDataTableColumn
        class="scopes-list__datatable-description-column"
        :label="contents.scopeDescriptionTableHeader"
        value="description"
      />
      <MDataTableColumn value="expandDescription" />
      <MDataTableColumn
        class="scopes-list__datatable-products-column"
        :label="contents.scopeProductsTableHeader"
        value="usedByProducts"
      />
      <template #[`cell.mastercheckbox`]="{ item }">
        <DvpTooltip
          v-if="documented && !item.isValid"
          :text="contents.invalidFormatScopeIdentifierTooltip"
        >
          <MCheckbox
            :id="`${item.name}`"
            :data-cy="`scope-select-${item.name}`"
            :modelValue="false"
            disabled
          />
        </DvpTooltip>

        <DvpTooltip
          v-else-if="item.isAlreadyAdded"
          :text="contents.scopeAlreadyAddedTooltip(item.name)"
        >
          <MCheckbox
            :id="`${item.name}`"
            :data-cy="`scope-select-${item.name}`"
            modelValue
            disabled
          />
        </DvpTooltip>

        <MCheckbox
          v-else
          :id="`${item.name}`"
          :data-cy="`scope-select-${item.name}`"
          :modelValue="item.isSelected"
          :disabled="(selectionDisabled && !item.isSelected) || item.isDisabled"
          @update:modelValue="handleSelectScope(item, $event)"
        />
      </template>
      <template #[`cell.name`]="{ item }">
        <div :data-cy="`scope-id-${item.name}`">
          <strong>{{ item.name }}</strong>
        </div>
      </template>
      <template #[`cell.description`]="{ item }">
        <div
          :data-cy="`scope-description-${item.name}`"
          class="scopes-list__scope-description"
        >
          <IconTooltip
            v-if="item.isAlreadyReferenced && !item.isAlreadyAdded"
            :text="contents.referentialDescriptionTooltip"
            iconColor="var(--color-status-info)"
          />
          {{
            item.descriptionCouldBeExpanded && isScopeExpanded(item.name)
              ? item.description
              : item.displayedDescription
          }}
        </div>
      </template>
      <template #[`cell.expandDescription`]="{ item }">
        <ToggleExpandDescriptionButton
          v-if="item.descriptionCouldBeExpanded"
          :isExpanded="isScopeExpanded(item.name)"
          @expandScope="expandScope(item.name)"
          @hideScope="hideScope(item.name)"
        />
      </template>
      <template #[`cell.usedByProducts`]="{ item }">
        <div :data-cy="`scope-products-${item.name}`">
          <div
            v-if="item.productLink"
            data-cy="scope-products-link"
            class="scopes-list__products-link"
            @click="emit('showScopeProducts', item)"
          >
            {{ item.productLink }}
          </div>
          <span v-else> - </span>
        </div>
      </template>
      <template #no-data>
        <template v-if="props.documented">
          <div
            v-if="apiHasNotPublishedSwagger"
            class="scopes-list__no-documentation"
          >
            <div>
              <h5>{{ contents.titleNothingToDisplay }}</h5>
              <span>{{ contents.uploadDocumentation }}</span>
            </div>
            <MButton
              :label="contents.goToApiDocumentationBtnLabel"
              theme="bordered-neutral"
              size="s"
              @click="redirectToApiDocumentation"
            />
          </div>
          <div
            v-else-if="scopes.length === 0"
            class="scopes-list__no-documented-scopes"
          >
            <h5>{{ contents.titleNothingToDisplay }}</h5>
            <p>{{ contents.noScopeInSwaggerDocumentation }}</p>
            <p>{{ contents.swaggerDocumentationUpdate }}</p>
          </div>
        </template>
        <div v-else data-cy="no-scope-found">
          {{ contents.noAnyResult }}
        </div>
      </template>
    </MDataTableV2>
  </div>
</template>

<style lang="scss">
.scopes-list__actions-bar {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: var(--base-spacing);
}

.scopes-list__actions {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 1rem;
}

.scopes-list__search-scope-input {
  width: 20rem;
}

.scopes-list__reference-new-scope-btn {
  margin-top: 1.6rem;
}

.scopes-list__datatable {
  margin-top: var(--base-spacing);
}

.scopes-list__datatable-name-column {
  width: 22rem;
}

.scopes-list__datatable-description-column {
  padding: 1rem;
}

.scopes-list__datatable-products-column {
  width: 8rem;
}

.scopes-list__scope-description {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.scopes-list__products-link {
  text-decoration: underline;
  cursor: pointer;
}

.scopes-list__no-documentation {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 23rem;
  gap: 1rem;
}

.scopes-list__no-documented-scopes {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}
</style>
