import { CorsHeaderForm } from "./cors-header-form";
import { Validate, ValidateNested } from "class-validator";

import { ArrayOfForms } from "@/commons/domain/forms/array-of-forms";
import { Form } from "@/commons/domain/forms/form";
import { FormFactory } from "@/commons/domain/forms/form-factory";
import { CorsHeader } from "@/commons/domain/models/cors-header";
import { FieldValueIsUniqueInList } from "@/commons/forms/class-validator/custom-validators/field-value-is-unique-in-list";

export class CorsHeadersListForm extends ArrayOfForms<CorsHeaderForm> {
  @Validate(FieldValueIsUniqueInList, ["value", "values"], {
    message: "A header already exists with the same value",
    each: true,
  })
  @ValidateNested({ message: "One or more headers aren't valid" })
  values: Form<CorsHeaderForm>[] = [];

  constructor(corsHeadersList: {
    values: CorsHeader[];
    newValue?: CorsHeader;
  }) {
    super();
    this.values = this.createCorsHeaderForms(corsHeadersList.values);
    this.newValue = this.createCorsHeaderForm(corsHeadersList.newValue);
  }

  $update(
    partial: Partial<{
      values: CorsHeader[];
    }>,
  ) {
    this.values = this.createCorsHeaderForms(partial.values);
  }

  $addValue(corsHeaderValue: string) {
    const valueForm = this.createCorsHeaderForm({
      id: this.getNewId(),
      value: corsHeaderValue,
    });
    valueForm.validate();
    this.values.push(valueForm);
  }

  private createCorsHeaderForms(corsHeaders: CorsHeader[] = []) {
    const corsHeadersForms = [];

    for (const corsHeader of corsHeaders) {
      corsHeadersForms.push(this.createCorsHeaderForm(corsHeader));
    }

    return corsHeadersForms;
  }

  private createCorsHeaderForm(corsHeader?: CorsHeader) {
    return CorsHeaderForm.create(corsHeader);
  }

  private getNewId() {
    const lastCorsHeader = this.values[(this.values.length || 1) - 1];
    return (lastCorsHeader && lastCorsHeader.data().id + 1) || 0;
  }

  static create(
    corsHeadersList: { values: CorsHeader[]; newValue?: CorsHeader } = {
      values: [],
    },
  ) {
    return FormFactory.createForm(new CorsHeadersListForm(corsHeadersList));
  }
}
