import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "oauth-flow-option-card__container" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "oauth-flow-option-card__content" }
const _hoisted_4 = { class: "oauth-flow-option-card__title" }
const _hoisted_5 = { class: "oauth-flow-option-card__description" }
const _hoisted_6 = { class: "oauth-flow-option-card__description-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MoreAboutLink = _resolveComponent("MoreAboutLink")!
  const _component_DvpOptionCard = _resolveComponent("DvpOptionCard")!

  return (_openBlock(), _createBlock(_component_DvpOptionCard, {
    id: $options.optionCardId,
    class: "oauth-flow-option-card",
    modelValue: $props.modelValue,
    label: $props.flowItem.title,
    type: $props.checkboxType,
    inputAttrs: { disabled: $props.isDisabled },
    noPadding: "",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          class: "oauth-flow-option-card__icon",
          src: $props.flowItem.icon
        }, null, 8, _hoisted_2),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h5", _hoisted_4, _toDisplayString($props.flowItem.title), 1),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, _toDisplayString($props.flowItem.description), 1),
            _createVNode(_component_MoreAboutLink, {
              class: "oauth-flow-option-card__more-about-link",
              documentationAnchor: $props.flowItem.documentationAnchor
            }, null, 8, ["documentationAnchor"])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["id", "modelValue", "label", "type", "inputAttrs"]))
}