import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home-try-it" }
const _hoisted_2 = { class: "home-try-it__header" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "header-description" }
const _hoisted_5 = { class: "home-try-it__content" }
const _hoisted_6 = { class: "home-try-it__code-example" }
const _hoisted_7 = { class: "home-try-it__buttons home-try-it--responsive-desktop" }
const _hoisted_8 = { class: "home-try-it__steps" }
const _hoisted_9 = { class: "home-try-it__step-number" }
const _hoisted_10 = { class: "home-try-it__step-image" }
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "home-try-it__step-title" }
const _hoisted_13 = { class: "home-try-it__step-description" }
const _hoisted_14 = { class: "home-try-it__buttons home-try-it--responsive-tablet-landscape" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Editor = _resolveComponent("Editor")!
  const _component_MButton = _resolveComponent("MButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", {
        class: "header-title",
        innerHTML: $options.contents.title
      }, null, 8, _hoisted_3),
      _createElementVNode("p", _hoisted_4, _toDisplayString($options.contents.description), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_Editor, {
          value: $options.contents.codeExample
        }, null, 8, ["value"]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_MButton, {
            theme: "solid-neutral",
            label: $options.contents.helpButtonLabel,
            href: $options.supportUrl
          }, null, 8, ["label", "href"]),
          _createVNode(_component_MButton, {
            label: $options.contents.startButtonLabel,
            onClick: _cache[0] || (_cache[0] = () => _ctx.$router.push({ name: 'dashboard' }))
          }, null, 8, ["label"])
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.contents.steps, (step, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: `home-try-it-step-${index}`,
            class: "home-try-it__step"
          }, [
            _createElementVNode("div", _hoisted_9, _toDisplayString(index + 1), 1),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("img", {
                  src: step.image,
                  class: "home-try-it__img"
                }, null, 8, _hoisted_11)
              ]),
              _createElementVNode("h3", _hoisted_12, _toDisplayString(step.title), 1),
              _createElementVNode("p", _hoisted_13, _toDisplayString(step.description), 1)
            ])
          ]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createVNode(_component_MButton, {
        theme: "solid-neutral",
        label: $options.contents.helpButtonLabel,
        href: $options.supportUrl
      }, null, 8, ["label", "href"]),
      _createVNode(_component_MButton, {
        label: $options.contents.startButtonLabel,
        onClick: _cache[1] || (_cache[1] = () => _ctx.$router.push({ name: 'dashboard' }))
      }, null, 8, ["label"])
    ])
  ]))
}