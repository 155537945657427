import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "checkbox-filter" }
const _hoisted_2 = {
  key: 0,
  class: "checkbox-filter__title"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MCheckbox = _resolveComponent("MCheckbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($props.title), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_MCheckbox, {
      id: `checkbox-filter-${$props.label}`,
      modelValue: $options.selectedItem,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($options.selectedItem) = $event)),
      label: $props.label,
      disabled: $props.isLoading
    }, null, 8, ["id", "modelValue", "label", "disabled"])
  ]))
}