import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "add-user" }
const _hoisted_2 = { class: "add-user__user-selection-item" }
const _hoisted_3 = { class: "add-user__user-selection-item-username" }
const _hoisted_4 = {
  key: 0,
  class: "add-user__user-selection-item-unknown-ldap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserRoleIcon = _resolveComponent("UserRoleIcon")!
  const _component_MAutocomplete = _resolveComponent("MAutocomplete")!
  const _component_MSelect = _resolveComponent("MSelect")!
  const _component_MButton = _resolveComponent("MButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MAutocomplete, {
      modelValue: $options.selectedUserId,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($options.selectedUserId) = $event)),
      class: "add-user__user-selection",
      items: $data.userItems,
      filter: false,
      dataValueExpr: "id",
      dataTextExpr: "name",
      input: $data.search,
      placeholder: $data.contents.searchUserPlaceholder,
      "onUpdate:input": $options.debouncedSearchUsers
    }, {
      item: _withCtx(({ item }) => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_UserRoleIcon, { user: item }, null, 8, ["user"]),
          _createElementVNode("span", _hoisted_3, _toDisplayString(item.name), 1),
          (item.unknownLDAP)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, " (" + _toDisplayString($data.contents.unknownLDAP) + ") ", 1))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "items", "input", "placeholder", "onUpdate:input"]),
    ($props.canChangeAction)
      ? (_openBlock(), _createBlock(_component_MSelect, {
          key: 0,
          id: "add-user__role-select",
          modelValue: $data.form.role,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.form.role) = $event)),
          class: "add-user__role-select",
          options: $options.roleOptions
        }, null, 8, ["modelValue", "options"]))
      : _createCommentVNode("", true),
    _createVNode(_component_MButton, {
      class: "add-user__submit-button",
      label: $data.contents.addUserButton,
      type: "button",
      disabled: $data.form.errors.length > 0,
      onClick: $options.submitUser
    }, null, 8, ["label", "disabled", "onClick"])
  ]))
}