import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "overlay-loader" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MLoader = _resolveComponent("MLoader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MLoader, {
      size: "l",
      theme: "dark",
      text: $props.message
    }, null, 8, ["text"])
  ]))
}