<template>
  <Teleport to="#notifications">
    <div
      class="toast-notifications"
      :class="classNames"
      role="alert"
      aria-live="assertive"
    >
      <TransitionGroup name="fade" tag="div">
        <template v-for="item in notificationToasts" :key="item.id">
          <MNotification
            class="toast-notifications__item"
            :title="item.title"
            closable
            :type="item.type"
            @close="handleClose(item.id)"
            @click="handleToastClick($event, item.id)"
          >
            {{ item.message }}
            <template v-if="item.link" #footer>
              <HelpLink
                :label="item.link.label"
                :href="item.link.href"
                :openInNewTab="item.link.openInNewTab"
              />
            </template>
          </MNotification>
        </template>
      </TransitionGroup>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import MNotification from "@mozaic-ds/vue-3/src/components/notification/MNotification.vue";
import { computed, watch } from "vue";
import { useStore } from "vuex";

import HelpLink from "@/commons/components/UserDocumentationLinks/HelpLink.vue";

import { NotificationToast } from "@/commons/store/modules/notification/types";

export interface Props {
  verticalPosition?: "top" | "bottom";
  horizontalPosition?: "right" | "left";
  timeout?: number;
}

const props = withDefaults(defineProps<Props>(), {
  verticalPosition: "bottom",
  horizontalPosition: "right",
  timeout: 5000,
});

const timeouts: { [key: string]: ReturnType<typeof setTimeout> } = {};

const store = useStore();

const notificationToasts = computed((): NotificationToast[] => {
  return store.state.notification["notificationToasts"];
});

function handleClose(id: string) {
  store.commit("removeNotificationToast", id);
  clearTimeout(timeouts[id]);
  delete timeouts[id];
}

function handleToastClick(event, id: string) {
  /**
   * Not close toast if help link is clicked
   * "path" is for SVG icons
   */

  if (event.target.closest("path") || event.target.closest(".help-link")) {
    return;
  }
  handleClose(id);
}

watch(notificationToasts.value, () => {
  const lastElement = [...notificationToasts.value].pop();
  if (lastElement) {
    const id = lastElement.id as string;
    if (lastElement.autoClosable && !timeouts[id]) {
      timeouts[id] = setTimeout(() => {
        handleClose(id);
      }, props.timeout);
    }
  }
});

const classNames = computed(() => ({
  left: props.horizontalPosition === "left",
  right: props.horizontalPosition === "right",
  bottom: props.verticalPosition === "bottom",
  top: props.verticalPosition === "top",
}));
</script>

<style lang="scss" scoped>
:deep(.mc-notification) {
  width: 320px;
}

.toast-notifications {
  position: fixed;
  max-height: 100%;
  padding: 1rem;
  overflow-y: auto;

  & > div > * {
    margin-bottom: 1rem;
  }

  &:empty {
    padding: 0;
  }
  &.right {
    right: 0;
  }
  &.left {
    left: 0;
  }
  &.bottom {
    bottom: 0;
  }
  &.top {
    top: 0;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.toast-notifications__item {
  cursor: pointer;
  transition:
    opacity 0.3s ease-out,
    transform 0.3s ease-out;
}
</style>
