<template>
  <LayerModalFormConfirm
    class="contract-unsubscription-modal"
    :disabledConfirmBtn="form.errors.length > 0"
    @submit="submit"
    @close="close"
  >
    <h4 class="contract-unsubscription-modal__title">
      {{ contents.confirmationTitle }}
    </h4>
    <template v-if="isOAuthContractWithSharedOrMultiLinedToken">
      <Markdown
        :content="
          contents.oAuthContractWithSharedOrMultiLinedTokenConfirmationDescription(
            apiLabel,
          )
        "
      />
    </template>
    <template v-else-if="isOAuthContract">
      <Markdown
        :content="contents.oAuthContractConfirmationDescription(apiLabel)"
      />
      <MNotification
        data-cy="notification-not-shared-nor-multilined-token"
        type="warning"
        :title="contents.notSharedNorMultilinedTokenNotificationTitle"
      >
        <span>
          {{ contents.notSharedNorMultilinedTokenNotificationMessage }}
        </span>
      </MNotification>
    </template>
    <template v-else>
      <Markdown
        :content="contents.apiKeyContractConfirmationDescription(apiLabel)"
      />
      <div v-if="displaySecretSynchronizationMessage">
        {{ contents.secretSynchronizationMessage(secretManager) }}
      </div>
    </template>

    <DvpField
      class="contract-unsubscription-modal__confirmation-text"
      required
      :errorMessage="form.firstError('text')"
    >
      <MTextInput
        v-model="form.text"
        type="text"
        :placeholder="contents.unsubscribe"
        :isInvalid="form.hasError('text')"
      />
    </DvpField>
  </LayerModalFormConfirm>
</template>

<script lang="ts">
import MNotification from "@mozaic-ds/vue-3/src/components/notification/MNotification.vue";
import MTextInput from "@mozaic-ds/vue-3/src/components/textinput/MTextInput.vue";
import { PropType } from "vue";

import DvpField from "@/commons/components/form/DvpField.vue";
import LayerModalFormConfirm from "@/commons/components/form/LayerModalFormConfirm.vue";
import Markdown from "@/documentation/components/commons/Markdown.vue";

import { getApiLabel } from "@/commons/libs/utils/apiUtils";
import { getContractRepository } from "@/commons/repositories/libs/get-contract-repository";

import { ConfirmActionForm } from "@/commons/domain/forms/confirm-action-form";
import { Contract } from "@/commons/domain/models/contract";

import {
  ENotificationToastType,
  NotificationToast,
} from "@/commons/store/modules/notification/types";

import contents from "@/dashboard/contents/contract-unsubscription-modal";

export default {
  components: {
    Markdown,
    LayerModalFormConfirm,
    MTextInput,
    DvpField,
    MNotification,
  },
  props: {
    contract: {
      type: Object as PropType<Contract>,
      required: true,
    },
    isOAuthContract: {
      type: Boolean,
      default: false,
    },
    isOAuthContractWithSharedOrMultiLinedToken: {
      type: Boolean,
      default: false,
    },
    withEnabledSecretSynchronization: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["submitted", "onClose"],
  data() {
    return {
      contents,
      form: ConfirmActionForm.create(contents.unsubscribe),
    };
  },
  computed: {
    apiLabel() {
      return getApiLabel(this.contract.api);
    },
    secretSynchronizationIsEnabled(): boolean {
      return this.$store.getters["config/secretSynchronizationIsEnabled"];
    },
    displaySecretSynchronizationMessage(): boolean {
      return (
        this.secretSynchronizationIsEnabled &&
        this.withEnabledSecretSynchronization
      );
    },
    secretManager(): string {
      return this.$store.getters["config/secretSynchronizationManager"];
    },
  },
  created() {
    this.form.init();
  },
  methods: {
    close() {
      this.$emit("onClose");
    },
    async submit() {
      if (this.form.errors.length === 0) {
        await this.deleteContract();
        this.$emit("submitted");
        this.close();
      }
    },
    async deleteContract() {
      await getContractRepository().deleteContract(this.contract.id);
      await this.$store.commit("postNotification", this.getToastNotification());
    },
    getToastNotification(): NotificationToast {
      if (
        this.withEnabledSecretSynchronization &&
        !this.secretSynchronizationIsEnabled
      ) {
        return {
          title: this.contents.toastNotification.title,
          type: ENotificationToastType.INFO,
          message: this.contents.toastNotification.warningContent(
            this.secretManager,
          ),
        };
      }
      return {
        title: this.contents.toastNotification.title,
        type: ENotificationToastType.SUCCESS,
      };
    },
  },
};
</script>

<style lang="scss">
.contract-unsubscription-modal__title {
  margin-bottom: 0;
}
</style>
