import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MNotification = _resolveComponent("MNotification")!
  const _component_Stack = _resolveComponent("Stack")!

  return (_openBlock(), _createBlock(_component_Stack, { class: "api-alerts" }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.checkList, (alert, index) => {
        return (_openBlock(), _createBlock(_component_MNotification, {
          key: `alert-message-${index}`,
          title: alert.label,
          type: alert.alertType,
          link: alert.route,
          "link-content": alert.routeLabel
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(alert.message), 1)
          ]),
          _: 2
        }, 1032, ["title", "type", "link", "link-content"]))
      }), 128))
    ]),
    _: 1
  }))
}