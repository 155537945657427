<template>
  <GlobalModalBody class="alert-modal-body">
    <template #content>
      <slot />
    </template>

    <template #footer>
      <MButton
        data-cy="modal-ok-btn"
        type="button"
        :theme="confirmBtnTheme"
        :label="closeBtnLabel"
        @click="$emit('onClose')"
      />
    </template>
  </GlobalModalBody>
</template>

<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";

import GlobalModalBody from "@/commons/components/Modal/GlobalModalBody.vue";

import contents from "@/commons/contents/modal";

export default {
  components: {
    GlobalModalBody,
    MButton,
  },
  props: {
    closeBtnLabel: {
      type: String,
      default: contents.closeBtn,
    },
    confirmBtnTheme: {
      type: String,
      default: "solid",
    },
  },
  emits: ["onClose"],
};
</script>
