<template>
  <div class="auto-complete-select" :class="[cssFieldElementClass]">
    <Multiselect
      :multiple="multiple"
      :modelValue="selectedOption"
      :options="options"
      :trackBy="keyProperty"
      :label="labelProperty"
      :disabled="disabled"
      :placeholder="placeholder"
      selectLabel=""
      deselectLabel=""
      selectedLabel=""
      :maxHeight="200"
      :allowEmpty="!required"
      @update:model-value="updateSelected"
      @searchChange="asyncFind"
    >
      <template #singleLabel="{ option }">{{ option.name }} </template>
    </Multiselect>
  </div>
</template>

<script lang="ts">
import Multiselect from "vue-multiselect";

import { Debouncer } from "@/commons/libs/utils/debouncer";

export default {
  components: { Multiselect },
  inject: {
    // Gets enriched by a class managed by mozaic's MField if used with it
    cssFieldElementClass: {
      default: "",
    },
  },
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [Array, Object],
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
      required: true,
    },
    placeholder: {
      type: String,
      default: "-",
    },
    labelProperty: {
      type: String,
      default: "name",
    },
    keyProperty: {
      type: String,
      default: "id",
    },
    required: {
      type: Boolean,
      default: false,
    },
    debounce: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:model-value", "searchChange"],
  data() {
    return {
      selectedOption: this.modelValue,
      debouncer: new Debouncer(),
    };
  },
  computed: {
    debounceInterval() {
      return (
        this.$store.getters["config/frontendConfig"].debounceInterval || 500
      );
    },
  },
  watch: {
    modelValue(newValue) {
      this.selectedOption = newValue;
    },
  },
  methods: {
    updateSelected(e) {
      this.selectedOption = e;
      this.$emit("update:model-value", this.selectedOption);
    },
    asyncFind(search) {
      const emitEvent = () => {
        this.$emit("searchChange", search);
      };
      if (this.debounce) {
        this.debouncer.debounce(emitEvent, this.debounceInterval);
      } else {
        emitEvent();
      }
    },
  },
};
</script>

<style lang="scss">
.auto-complete-select {
  .multiselect {
    font-family: Roboto, sans-serif !important;

    .multiselect__select {
      &::before {
        position: absolute;
        top: 0;
        right: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 100%;
        margin-top: 0;
        overflow: hidden;
        font-family: "Material Symbols Outlined", sans-serif;
        color: var(--color-grey);
        pointer-events: none;
        content: "expand_more";
        border: none;
      }
    }

    &.multiselect--active {
      .multiselect__select {
        transform: none;
      }
    }
  }

  .multiselect__tags-wrap {
    display: inline-block;
    max-width: 100%;
    padding-top: 10px;
  }

  .multiselect__tags {
    max-width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    border-color: #666; // Waiting MAutocomplete
    border-radius: 3px;
  }

  .multiselect__tag {
    font-size: 0.9rem;
    color: var(--color-text);
    background: var(--color-white);
    border: var(--border-default);
  }

  .multiselect__tag-icon {
    line-height: 1.4rem;

    &:hover {
      background: none;

      &::after {
        color: var(--color-text);
      }
    }

    &::after {
      color: var(--color-light-grey);
    }
  }

  .multiselect__input,
  .multiselect__placeholder,
  .multiselect__input::placeholder,
  .multiselect__single {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin: 0 !important;
    font-family: Roboto, sans-serif !important; // waiting MAutocomplete
    font-size: 14px; // waiting MAutocomplete
    font-weight: 400 !important; // waiting MAutocomplete
    line-height: 40px !important;
    color: var(--color-text);
  }

  .multiselect__option {
    padding: 0 1.25rem;
    font-size: 0.9rem !important;
    line-height: 36px !important;
    color: var(--color-text);

    &--highlight {
      color: var(--color-primary);
      background: rgb(var(--color-primary-rgba) 0.2);
    }

    &--selected {
      font-weight: 400;
      color: var(--color-disabled-border);
      background: var(--color-disabled-content);
    }
  }

  .multiselect__option--selected.multiselect__option--highlight {
    color: var(--color-danger);
    background: rgb(var(--color-danger-rgba) 0.1);
  }
}
.auto-complete-select__arrow-icon {
  position: absolute;
  top: 10px;
  right: 12px;
}
</style>
