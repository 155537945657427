<template>
  <div class="group-deletion-modal">
    <ConfirmModalBody
      v-if="canDelete"
      class="group-deletion-modal"
      :confirmBtnLabel="contents.confirm"
      :disabledConfirmBtn="isSaving"
      @onSubmit="deleteGroup"
      @onClose="$emit('onClose')"
    >
      <h4>{{ contents.titleCanDelete(group.name) }}</h4>

      <p>{{ contents.descriptionCanDeleteGroup }}</p>
    </ConfirmModalBody>

    <AlertModalBody v-else @onClose="$emit('onClose')">
      <h4>{{ contents.titleCantDelete(group.name) }}</h4>

      <p>{{ contents.descriptionCantDeleteGroup }}</p>
      <ul>
        <li
          v-for="(managedResource, index) in resourcesPreventingDeletion"
          :key="'managed-resource-' + index"
        >
          <DvpTooltip
            class="group-deletion-modal__resource-names-tooltip"
            :text="formatResourceNames(managedResource.data)"
            position="right"
            >{{ managedResource.total }} {{ managedResource.type }}</DvpTooltip
          >
          {{ contents.managedBy(group.name) }}
        </li>
      </ul>
      <p>{{ contents.transferOwnership(group.name) }}</p>
      <Markdown :content="contents.contactUs" />
    </AlertModalBody>
  </div>
</template>

<script lang="ts">
import { PropType } from "vue";

import DvpTooltip from "@/commons/components/DvpTooltip.vue";
import AlertModalBody from "@/commons/components/Modal/AlertModalBody.vue";
import ConfirmModalBody from "@/commons/components/Modal/ConfirmModalBody.vue";
import Markdown from "@/documentation/components/commons/Markdown.vue";

import { Api } from "@/commons/domain/models/api";
import { Application } from "@/commons/domain/models/application";
import { Group } from "@/commons/domain/models/group";

import contents from "@/commons/contents/group-deletion-modal";

export default {
  components: {
    AlertModalBody,
    Markdown,
    DvpTooltip,
    ConfirmModalBody,
  },
  props: {
    group: {
      type: Object as PropType<Group>,
      required: true,
    },
    groupApplications: {
      type: Array as PropType<Application[]>,
      required: true,
    },
    groupApis: {
      type: Array as PropType<Api[]>,
      required: true,
    },
  },
  emits: ["groupDeleted", "onClose"],
  data() {
    return {
      contents,
    };
  },
  computed: {
    isSaving(): boolean {
      return this.$store.getters["isSaving"];
    },
    canDelete(): boolean {
      return this.resourcesPreventingDeletion.length == 0;
    },
    resourcesPreventingDeletion(): {
      data: any[];
      type: string;
      total: number;
    }[] {
      return [
        {
          data: this.groupApplications,
          type: "APPs",
          total: this.groupApplications.length,
        },
        {
          data: this.groupApis,
          type: "APIs",
          total: this.groupApis.length,
        },
      ].filter((r) => r.total > 0);
    },
  },
  methods: {
    formatResourceNames(data: Application[] | Api[]): string {
      let names = data.map((d: { name: string }) => d.name);
      if (names.length >= 10) {
        names.push("...");
      }
      return names.join(", ");
    },
    async deleteGroup(): Promise<void> {
      await this.$store.dispatch("removeUserGroup", this.group);
      this.$emit("groupDeleted");
      this.$emit("onClose");
    },
  },
};
</script>

<style lang="scss">
.group-deletion-modal__resource-names-tooltip {
  font-weight: var(--weight-bold);
  color: var(--color-status-danger);
  text-decoration: underline;

  // mozaic override so we can have the label in bold and the tooltip content in normal weight
  .mc-tooltip {
    font-weight: inherit;

    .mc-tooltip__content {
      font-weight: 400;
    }
  }
}
</style>
