import moment from "moment";

export function date(str: string, format: string = "YYYY-MM-DDTHH:mm:ssZ") {
  return str ? moment(str).format(format) : "-";
}

export function requestDate(str: string) {
  if (str && moment(str).isSame(moment(), "day")) {
    return `Today ${date(str, "LT").toLowerCase()}`;
  }
  return date(str, "L LT").toLowerCase();
}
