module.exports = {
  unpublishedTooltip: "The route should be unpublished before removing",
  header: "Routing",
  targetUrl: "Your private application url",
  gateways: "Deployed on",
  targetUrlPlaceholder: "Set default target URL",
  buttonUpdateLabel: "Save the route",
  buttonEditLabel: "Edit the route",
  buttonRemoveLabel: "Delete the route",
  buttonCancelLabel: "Cancel",
  headerConfirmRemove: "You're about to delete the route",
  targetUrlTooltip: "Add your private application URL",
  gatewaysTooltip:
    "The good practice is to choose a gateway close to your API. If you don’t find it, use a GCP gateway",
  infoAskAnotherGateway: "Feel free to ask another gateway",
  unPublishRouteModalText: "Route will be no longer publishable",
  unPublishRouteConfirmButtonText: "Delete route",
  unPublishRouteInputPlaceholder: "Type DELETE",
};
