<template>
  <Card class="sensitive-modifications">
    <h4>{{ contents.title }}</h4>
    <SensitiveModificationsList
      v-for="action in actions"
      :key="action.label"
      :title="action.label"
      :description="action.description"
      :btnTitle="action.btnTitle"
      :btnAction="action.action"
      :isDisabled="action.isDisabled"
    />
  </Card>
</template>

<script lang="ts">
import { markRaw } from "vue";

import Card from "@/commons/components/Card.vue";
import SwitchApiDeprecationModal from "@/manager/views/ApiDetailSettings/SwitchApiDeprecationModal.vue";

import DeleteApiModal from "./DeleteApiModal.vue";
import DeprecateApiZonesModal from "./DeprecateApiZonesModal.vue";
import SensitiveModificationsList from "./SensitiveModificationsList.vue";

import contentOfDeleteApiModal from "@/manager/contents/delete-api-modal";
import contentOfSwitchApiDeprecationModal from "@/manager/contents/deprecate-api-modal";
import contents from "@/manager/contents/sensitive-modifications";

export default {
  name: "SensitiveModifications",
  components: {
    SensitiveModificationsList,
    Card,
  },
  props: {
    currentApi: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      contents,
    };
  },
  computed: {
    isDepreciableZone() {
      return this.$store.getters["deprecatableZones"].length;
    },
    actions() {
      return [
        this.currentApi.isDeprecated
          ? {
              ...contents.undeprecateApi,
              action: this.switchApiDeprecation,
              isDisabled: !this.currentApi.isDeprecated,
            }
          : {
              ...contents.deprecateApi,
              action: this.switchApiDeprecation,
              isDisabled: this.currentApi.isDeprecated,
            },
        {
          ...contents.deprecateApiZone,
          action: this.deprecateApiZone,
          isDisabled: !this.isDepreciableZone,
        },
        {
          ...contents.deleteApi,
          action: this.deleteApi,
          isDisabled: false,
        },
      ];
    },
  },
  methods: {
    switchApiDeprecation() {
      this.$store.commit("openLayerModal", {
        title: !this.currentApi.isDeprecated
          ? contentOfSwitchApiDeprecationModal.deprecateModalTitle
          : contentOfSwitchApiDeprecationModal.undeprecateModalTitle,
        component: markRaw(SwitchApiDeprecationModal),
        props: {
          deprecated: !this.currentApi.isDeprecated,
        },
      });
    },
    deprecateApiZone() {
      this.$store.commit("openLayerModal", {
        title: "You are about to deprecate a zone",
        component: DeprecateApiZonesModal,
        props: { apiId: this.currentApi.id },
      });
    },
    deleteApi() {
      this.$store.commit("openLayerModal", {
        title: contentOfDeleteApiModal.modalTitle,
        component: markRaw(DeleteApiModal),
        props: {
          api: this.currentApi,
        },
        listeners: {},
      });
    },
  },
};
</script>
