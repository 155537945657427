import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MDropdown = _resolveComponent("MDropdown")!
  const _component_DvpField = _resolveComponent("DvpField")!
  const _component_MTextInput = _resolveComponent("MTextInput")!
  const _component_ViewHelpButton = _resolveComponent("ViewHelpButton")!
  const _component_MNotification = _resolveComponent("MNotification")!
  const _component_LayerModalFormConfirm = _resolveComponent("LayerModalFormConfirm")!

  return (_openBlock(), _createBlock(_component_LayerModalFormConfirm, {
    class: "create-or-update-black-list-route-modal",
    confirmBtnLabel: $props.submitButtonLabel,
    confirmBtnTheme: "solid-primary",
    disabledConfirmBtn: !!$data.form.errors.length,
    onSubmit: $options.onSubmit,
    onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('onClose')))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_DvpField, {
        class: "create-or-update-black-list-route-modal__http-methods-field",
        label: $data.contents.httpMethodsLabel,
        required: "",
        errorMessage: $data.form.firstError('httpMethods')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_MDropdown, {
            modelValue: $data.form.httpMethods,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.form.httpMethods) = $event)),
            multiple: "",
            items: $options.httpMethodsItems,
            placeholder: $data.contents.httpMethodsPlaceholder
          }, null, 8, ["modelValue", "items", "placeholder"])
        ]),
        _: 1
      }, 8, ["label", "errorMessage"]),
      _createVNode(_component_DvpField, {
        class: "create-or-update-black-list-route-modal__path-regex-field",
        label: $data.contents.pathRegexLabel
      }, {
        default: _withCtx(() => [
          _createVNode(_component_MTextInput, {
            modelValue: $data.form.pathRegex,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.form.pathRegex) = $event)),
            placeholder: $data.contents.pathRegexPlaceholder,
            isInvalid: $data.form.firstError('pathRegex') != null
          }, null, 8, ["modelValue", "placeholder", "isInvalid"])
        ]),
        _: 1
      }, 8, ["label"]),
      _createVNode(_component_MNotification, {
        class: "create-or-update-black-list-route-modal__notification",
        type: $options.notificationType,
        title: $data.contents.notificationTitle
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", {
            innerHTML: $data.contents.notificationMessage
          }, null, 8, _hoisted_1)
        ]),
        footer: _withCtx(() => [
          _createVNode(_component_ViewHelpButton, { documentationAnchor: "#manage-route-blacklisting" })
        ]),
        _: 1
      }, 8, ["type", "title"])
    ]),
    _: 1
  }, 8, ["confirmBtnLabel", "disabledConfirmBtn", "onSubmit"]))
}