<template>
  <div v-if="!isLoadingApi" class="authentication-settings">
    <AuthenticationApiKeySettings
      v-if="currentApi.authType == EAuthType.API_KEY"
    />
    <AuthenticationOauthSettings v-else />
  </div>
</template>

<script lang="ts">
import AuthenticationApiKeySettings from "@/manager/views/ApiDetailAuthentication/AuthenticationApiKeySettings.vue";
import AuthenticationOauthSettings from "@/manager/views/ApiDetailAuthentication/AuthenticationOauthSettings.vue";

import { Api } from "@/commons/domain/models/api";

import { EAuthType } from "@/commons/store/types";

export default {
  components: {
    AuthenticationOauthSettings,
    AuthenticationApiKeySettings,
  },
  data() {
    return {
      EAuthType,
    };
  },
  computed: {
    isLoadingApi(): boolean {
      return this.$store.getters["isLoadingProperty"]("api");
    },
    currentApi(): Api {
      return this.$store.getters["currentApi"];
    },
  },
  created() {
    this.reloadCurrentApi(); // if the user got his request validated during his navigation, we want to display its new authType info
  },
  methods: {
    async reloadCurrentApi() {
      this.$store.dispatch("loadApi", { apiId: this.currentApi.id });
    },
  },
};
</script>
