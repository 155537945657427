<template>
  <Layout2 v-if="user" class="user-profile">
    <LayoutSection background="primary" noVerticalPadding fullWidthContent>
      <LayoutContentHeader
        :title="contents.userProfileTitle"
        :description="description"
      />
    </LayoutSection>
    <LayoutSection
      background="secondary"
      class="user-profile__information-section"
    >
      <div class="user-profile__informations">
        <div class="user-profile__basic-information">
          <UserInformations :user="user" />
        </div>
        <div class="user-profile__other-informations">
          <UserCookies />
          <UserSensitiveModifications v-if="!user.isInternal" :user="user" />
        </div>
      </div>
    </LayoutSection>
  </Layout2>
</template>

<script lang="ts">
import Layout2 from "@/commons/components/Layout2.vue";
import LayoutContentHeader from "@/commons/components/LayoutContentHeader.vue";
import LayoutSection from "@/commons/components/LayoutSection.vue";
import UserCookies from "@/user/components/UserCookies.vue";
import UserInformations from "@/user/components/UserInformations.vue";
import UserSensitiveModifications from "@/user/components/UserSensitiveModifications.vue";

import { User } from "@/commons/domain/models/user";

import { ERole } from "@/commons/store/types";

import contents from "@/commons/contents/user-profile";

export default {
  name: "UserProfile",
  components: {
    LayoutContentHeader,
    Layout2,
    LayoutSection,
    UserCookies,
    UserInformations,
    UserSensitiveModifications,
  },
  data() {
    return {
      contents,
    };
  },
  computed: {
    user(): User {
      return this.$store.getters["user"];
    },
    hasPartnerRole(): boolean {
      return this.user.roles.some((role) => role.id === ERole.PARTNER);
    },
    description(): String | undefined {
      return this.hasPartnerRole ? contents.loginAsPartner : undefined;
    },
  },
};
</script>

<style lang="scss">
.user-profile__information-section {
  flex: 1;
}
.user-profile__informations {
  display: flex;
  max-width: 1008px;
  margin: 0 auto;
}
.user-profile__basic-information,
.user-profile__other-informations {
  flex-grow: 1;
  margin: calc(
    var(--base-spacing) / 2
  ); /* 2 because there is one next to the other */
}
.user-profile__other-informations > * {
  margin-bottom: var(--base-spacing);
}
</style>
