import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "authentication-api-key-settings" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AuthenticationModeSettings = _resolveComponent("AuthenticationModeSettings")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AuthenticationModeSettings, {
      apiId: $options.currentApi.id,
      requestedAuthType: $data.EAuthType.OAUTH,
      optionCardTitle: $data.contents.apiKeyCardTitle,
      optionCardDescription: $data.contents.apiKeyCardDescription,
      authTypeDocAnchor: "#api-key-authentication-mode",
      authChangeIntro: $data.contents.changeToOauthIntro,
      authChangeButtonLabel: $data.contents.changeToOauthBtn
    }, null, 8, ["apiId", "requestedAuthType", "optionCardTitle", "optionCardDescription", "authChangeIntro", "authChangeButtonLabel"])
  ]))
}