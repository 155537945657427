import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "text-centered" }
const _hoisted_2 = {
  key: 1,
  colspan: "3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_CancelAndSubmitButtons = _resolveComponent("CancelAndSubmitButtons")!
  const _component_AddPolicyButton = _resolveComponent("AddPolicyButton")!

  return (_openBlock(), _createElementBlock("tr", {
    class: _normalizeClass(['create-custom-query-param', { disabled: $props.isDisabled }])
  }, [
    ($options.showCreationForm)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("td", null, [
            _createVNode(_component_FormInput, {
              error: $props.form.firstError('name')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_TextInput, {
                  modelValue: $props.form.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($props.form.name) = $event)),
                  reference: "inputCustomQueryParamName",
                  placeholder: $data.contents.namePlaceholder,
                  isDisabled: $props.isDisabled
                }, null, 8, ["modelValue", "placeholder", "isDisabled"])
              ]),
              _: 1
            }, 8, ["error"])
          ]),
          _createElementVNode("td", null, [
            _createVNode(_component_FormInput, {
              error: $props.form.firstError('value')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_TextInput, {
                  modelValue: $props.form.value,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($props.form.value) = $event)),
                  placeholder: $data.contents.valuePlaceholder,
                  isDisabled: $props.isDisabled
                }, null, 8, ["modelValue", "placeholder", "isDisabled"])
              ]),
              _: 1
            }, 8, ["error"])
          ]),
          _createElementVNode("td", _hoisted_1, [
            _createVNode(_component_CancelAndSubmitButtons, {
              onCancel: $options.cancel,
              onSubmit: $options.create
            }, null, 8, ["onCancel", "onSubmit"])
          ])
        ], 64))
      : (_openBlock(), _createElementBlock("td", _hoisted_2, [
          _createVNode(_component_AddPolicyButton, {
            label: $data.contents.buttonAdd,
            onAdd: $options.edit
          }, null, 8, ["label", "onAdd"])
        ]))
  ], 2))
}