import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MIcon = _resolveComponent("MIcon")!
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_IconAddInput = _resolveComponent("IconAddInput")!
  const _component_SvgWithLabel = _resolveComponent("SvgWithLabel")!
  const _component_Stack = _resolveComponent("Stack")!

  return ($props.form.newValue)
    ? (_openBlock(), _createBlock(_component_Stack, {
        key: 0,
        class: _normalizeClass([
      'input-texts-list',
      {
        'input-texts-list--disabled': $props.isDisabled,
      },
    ])
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.form.values, (valueForm, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: `${$options.computedId}-input-${index}`
            }, [
              _createVNode(_component_FormInput, {
                error: valueForm.firstError('value')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_TextInput, {
                    ref_for: true,
                    ref: "values",
                    reference: "input",
                    modelValue: valueForm.value,
                    placeholder: $props.placeholder,
                    isDisabled: $props.isDisabled,
                    suffix: !$props.isDisabled,
                    debounce: "",
                    "onUpdate:modelValue": ($event: any) => (valueForm.value = $event),
                    onEnter: ($event: any) => ($options.updateAndMoveToNextValue(index))
                  }, {
                    textInputSuffix: _withCtx(() => [
                      _createElementVNode("span", {
                        class: "input-texts-list__remove",
                        onClick: ($event: any) => ($options.removeValue(index))
                      }, [
                        _createVNode(_component_MIcon, { name: "ControlCircleCross32" })
                      ], 8, _hoisted_1)
                    ]),
                    _: 2
                  }, 1032, ["modelValue", "placeholder", "isDisabled", "suffix", "onUpdate:modelValue", "onEnter"])
                ]),
                _: 2
              }, 1032, ["error"])
            ]))
          }), 128)),
          (!$props.isDisabled)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_component_FormInput, {
                  error: $props.form.newValue.firstError('value')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_TextInput, {
                      ref: "newValue",
                      reference: "input",
                      modelValue: $props.form.newValue.value,
                      placeholder: $props.placeholder,
                      isDisabled: $props.isDisabled,
                      debounce: "",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ($props.form.newValue.value = $event)),
                      onEnter: $options.addNewValue
                    }, null, 8, ["modelValue", "placeholder", "isDisabled", "onEnter"])
                  ]),
                  _: 1
                }, 8, ["error"]),
                _createElementVNode("div", {
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.addNewValue && $options.addNewValue(...args)))
                }, [
                  _createVNode(_component_SvgWithLabel, {
                    class: _normalizeClass([
            'input-texts-list__add',
            {
              'input-texts-list__add--disabled': !!$props.form.newValue.errors.length,
            },
          ])
                  }, {
                    right: _withCtx(() => [
                      _createTextVNode(_toDisplayString($data.contents.addValueLabel), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_IconAddInput)
                    ]),
                    _: 1
                  }, 8, ["class"])
                ])
              ], 64))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["class"]))
    : _createCommentVNode("", true)
}