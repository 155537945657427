<template>
  <div v-if="items.length > 0" class="tags-applied-filters">
    <Divider />

    <div class="tags-applied-filters__list">
      <div>
        <MTagList :items="items" :max="10" @remove-tag="removeFilter" />
      </div>

      <MLink data-cy="reset-all-filters" size="s" @click="resetFilters">
        {{ contents.resetButtonLabel }}
      </MLink>
    </div>
  </div>
</template>
<script lang="ts">
import MLink from "@mozaic-ds/vue-3/src/components/link/MLink.vue";
import MTagList from "@mozaic-ds/vue-3/src/components/taglist/MTagList.vue";
import { PropType } from "vue";

import Divider from "@/commons/components/Divider.vue";

import { replaceUrlQuery } from "@/commons/utils/route-utils";

import { MTagListItem } from "@/commons/domain/models/mozaic";

import contents from "@/commons/contents/tags-applied-filters";

export default {
  components: {
    MLink,
    MTagList,
    Divider,
  },
  props: {
    appliedFiltersTagList: {
      type: Array as PropType<MTagListItem[]>,
      required: true,
    },
  },
  emits: ["filterRemoved"],
  data() {
    return {
      contents,
      filterIdToRemove: undefined,
    };
  },
  computed: {
    items() {
      let items = this.appliedFiltersTagList;
      // Handle tag removing before being synchronized via a backend call to provide a non-interrupted display
      if (this.filterIdToRemove) {
        items = items.filter((item) => item.id !== this.filterIdToRemove);
      }
      return items;
    },
  },
  watch: {
    appliedFiltersTagList: {
      handler() {
        const elementToRemove = this.appliedFiltersTagList.find(
          (el) => el.id === this.filterIdToRemove,
        );
        // Reset filterIdToRemove when this filter was delete from appliedFiltersTagList
        if (elementToRemove == undefined) {
          this.filterIdToRemove = undefined;
        }
      },
      deep: true,
    },
  },
  methods: {
    async resetFilters() {
      await replaceUrlQuery({});
    },
    removeFilter(filterId: string) {
      this.filterIdToRemove = filterId;
      this.$emit("filterRemoved", filterId);
    },
  },
};
</script>
<style lang="scss">
.tags-applied-filters__list {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: var(--base-spacing);
  gap: 1rem;
}
</style>
