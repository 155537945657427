import { FormData } from "@/commons/domain/forms/form-data";
import { FormFactory } from "@/commons/domain/forms/form-factory";
import { Product } from "@/commons/domain/models/product";

import { EAccessPolicy, EPrivacyName } from "@/commons/store/types";

export interface FiltersApiCatalogFormData {
  productsIds?: string[];
  products?: Product[];
  platformsIds?: string[];
  businessUnitsIds?: string[];
  publicationZoneId?: string;
  accessTypes?: string[];
  privacies?: string[];
}

export class FiltersApiCatalogForm extends FormData {
  productsIds: string[] = [];
  platformsIds: string[] = [];
  businessUnitsIds: string[] = [];
  publicationZoneId: string = undefined;
  accessTypes: EAccessPolicy[] = [];
  privacies: EPrivacyName[] = [];

  $clear() {
    this.productsIds = [];
    this.platformsIds = [];
    this.businessUnitsIds = [];
    this.publicationZoneId = undefined;
    this.accessTypes = [];
    this.privacies = [];
  }

  constructor(
    productsIds: string[] = [],
    platformsIds: string[] = [],
    businessUnitsIds: string[] = [],
    publicationZoneId: string = undefined,
    accessTypes: EAccessPolicy[] = [],
    privacies: EPrivacyName[] = [],
  ) {
    super();
    this.productsIds = productsIds;
    this.platformsIds = platformsIds;
    this.businessUnitsIds = businessUnitsIds;
    this.publicationZoneId = publicationZoneId;
    this.accessTypes = accessTypes;
    this.privacies = privacies;
  }

  static create() {
    return FormFactory.createForm(new FiltersApiCatalogForm());
  }
}
