module.exports = {
  modalTitle: "Information to switch between API Key and OAuth2",
  consumersOnApi:
    "Your API has currently active consumers for which this change would break their use.",
  solutionsForConsumersOnApiForChangeToOauth:
    "**You cannot do it now. Two solutions:**\n\n" +
    "* You disable their credentials in your Consumers page (they will NOT be able to call you anymore)\n" +
    "* You create a new API with same configuration, but using OAuth Authentication.",
  solutionsForConsumersOnApiForChangeToApiKey:
    "**You cannot do it now. Two solutions:**\n\n" +
    "* You disable their credentials in your Consumers page (they will NOT be able to call you anymore)\n" +
    "* You create a new API with same configuration, but using API Key Authentication.\n",
  closeBtn: "Close",
};
