<template>
  <div v-if="!isLoading" class="routing-api">
    <MNotification v-if="!isLoading && validationError" type="danger">
      {{ validationError }}
    </MNotification>
    <MNotification v-if="apiHasPendingOtherGatewaysRequest" type="warning">{{
      contents.alertMessagePendingOtherGateway
    }}</MNotification>
    <h4>{{ contents.header }}</h4>
    <template v-if="!isLoading">
      <CreateTargetUrl
        class="routing-api__create"
        :is-disabled="isSaving"
        :form-parent="form"
        :form="form.newValue"
        :has-error="hasCreationError"
      />

      <UpdateOrRemoveTargetUrl
        v-for="(targetUrlForm, index) in form.values"
        :key="`update-or-remove-route-${targetUrlForm.id}`"
        class="routing-api__update-or-remove"
        :form="targetUrlForm"
        :form-parent="form"
        :position-in-list="index + 1"
        :is-disabled="isSaving"
      />
    </template>

    <BlackListRoutes />
  </div>
</template>

<script lang="ts">
import MNotification from "@mozaic-ds/vue-3/src/components/notification/MNotification.vue";

import BlackListRoutes from "@/manager/views/ApiDetailRouting/BlackListRoutes.vue";

import CreateTargetUrl from "./CreateTargetUrl.vue";
import UpdateOrRemoveTargetUrl from "./UpdateOrRemoveTargetUrl.vue";

import { Route } from "@/commons/domain/models/route";
import { TargetUrlsListForm } from "@/manager/domain/forms/target-urls-list-form";

import contents from "@/manager/contents/routing-api";

export default {
  name: "RoutingApi",
  components: {
    CreateTargetUrl,
    MNotification,
    UpdateOrRemoveTargetUrl,
    BlackListRoutes,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      contents,
      form: TargetUrlsListForm.create(),
    };
  },
  computed: {
    validationError() {
      return this.form.firstError("values") || this.form.firstError("newValue");
    },
    hasCreationError() {
      return this.$store.getters["actionFailing"] === "createTargetUrl";
    },
    isSaving() {
      return this.$store.getters["isSaving"];
    },
    isLoading() {
      return this.$store.getters["isLoading"];
    },
    upstreamRoutes(): Route[] {
      return this.$store.getters["upstreamRoutes"];
    },
    currentApi() {
      return this.$store.getters["currentApi"];
    },
    apiHasPendingOtherGatewaysRequest() {
      return this.$store.getters["apiHasPendingOtherGatewaysRequest"];
    },
  },
  watch: {
    upstreamRoutes: {
      immediate: true,
      deep: true,
      handler(newUpstreamRoutes) {
        this.form.init({
          newValue: { url: "", zoneIds: [] },
          values: newUpstreamRoutes,
        });
      },
    },
  },
  mounted() {
    this.$store.dispatch("availableZones", this.currentApi.id);
  },
};
</script>

<style lang="scss" scoped>
.routing-api {
  padding: 2rem 0;
  margin-bottom: 10rem;
}
.routing-api__create,
.routing-api__update-or-remove {
  margin: 2rem 0;
}
</style>
