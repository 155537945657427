import {
  IsDate,
  IsNotEmpty,
  IsOptional,
  MaxLength,
  MinLength,
} from "class-validator";

import { FormData } from "@/commons/domain/forms/form-data";
import { FormFactory } from "@/commons/domain/forms/form-factory";

export class DeprecateApiZoneForm extends FormData {
  constructor(zoneId: string, date: Date, message: string) {
    super();
    this.$update({ zoneId, date, message });
  }

  @IsNotEmpty({
    message: "The zone is required",
  })
  zoneId: string;

  @IsOptional()
  @IsDate({
    message: "This is field isn't a valid date",
  })
  date: Date;

  @MinLength(50, {
    message:
      "This field is too short. Minimal length is $constraint1 characters",
  })
  @MaxLength(200, {
    message:
      "This field is too long. Maximal length is $constraint1 characters",
  })
  message: string;

  async $clear() {
    this.zoneId = undefined;
    this.date = undefined;
    this.message = "";
  }

  static create(
    zoneId: string = undefined,
    date: Date = undefined,
    message: string = "",
  ) {
    return FormFactory.createForm(
      new DeprecateApiZoneForm(zoneId, date, message),
    );
  }
}
