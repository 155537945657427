import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "list-products-catalog" }
const _hoisted_2 = { class: "list-products-catalog__list" }
const _hoisted_3 = {
  key: 0,
  class: "list-products-catalog__no-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductListItem = _resolveComponent("ProductListItem")!
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.products, (product) => {
        return (_openBlock(), _createBlock(_component_RouterLink, {
          key: product.id,
          class: "list-products-catalog__list-item",
          to: {
          name: 'productDocumentationDetail',
          params: { id: product.id },
        }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ProductListItem, { product: product }, null, 8, ["product"])
          ]),
          _: 2
        }, 1032, ["to"]))
      }), 128))
    ]),
    ($props.products.length === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($data.contents.noProductsToDisplay), 1))
      : _createCommentVNode("", true)
  ]))
}