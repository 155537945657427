<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_3054_5196)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4 5C4 4.44772 4.44772 4 5 4H19C19.5523 4 20 4.44772 20 5V19C20 19.5523 19.5523 20 19 20H5C4.44772 20 4 19.5523 4 19V5ZM6 6V18H18V6H6ZM11.5 12C11.5 11.7239 11.7239 11.5 12 11.5H15C15.2761 11.5 15.5 11.7239 15.5 12C15.5 12.2761 15.2761 12.5 15 12.5H12C11.7239 12.5 11.5 12.2761 11.5 12ZM12 8.5C11.7239 8.5 11.5 8.72386 11.5 9C11.5 9.27614 11.7239 9.5 12 9.5H15C15.2761 9.5 15.5 9.27614 15.5 9C15.5 8.72386 15.2761 8.5 15 8.5H12ZM11.5 15C11.5 14.7239 11.7239 14.5 12 14.5H15C15.2761 14.5 15.5 14.7239 15.5 15C15.5 15.2761 15.2761 15.5 15 15.5H12C11.7239 15.5 11.5 15.2761 11.5 15ZM9 10C9.55228 10 10 9.55228 10 9C10 8.44772 9.55228 8 9 8C8.44772 8 8 8.44772 8 9C8 9.55228 8.44772 10 9 10ZM10 12C10 12.5523 9.55228 13 9 13C8.44772 13 8 12.5523 8 12C8 11.4477 8.44772 11 9 11C9.55228 11 10 11.4477 10 12ZM9 16C9.55228 16 10 15.5523 10 15C10 14.4477 9.55228 14 9 14C8.44772 14 8 14.4477 8 15C8 15.5523 8.44772 16 9 16Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_3054_5196">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
