import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconButton = _resolveComponent("IconButton")!

  return (_openBlock(), _createBlock(_component_IconButton, {
    class: "button-edit-group",
    "data-cy": "edit-group-button",
    mozaicIconName: "Group24",
    color: $props.color,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click', $event)))
  }, null, 8, ["color"]))
}