export default {
  modalTitle: "Unsubscribe from your ACF APIs",
  stepperDescription: "Subscription on another ACF provider steps",
  step1Label: "Information",
  step2Label: "Unsubscribe",

  multiProvidersNotification: (apiACFProvider, applicationACFProvider) =>
    `The API you want to subscribe to uses another Ping environment (**${apiACFProvider}**) than the one (**${applicationACFProvider}**) used for the below APIs you have already subscribed to.\n\n` +
    `It cannot work as your users connect once on a single Ping environment.`,
  infoAboutUnsubscriptions:
    "If you really want to subscribe to this API and you do not need already subscribed below APIs, you can unsubscribe from there.",
  listOfImpactedApis: "List of the impacted ACF APIs:",
  andMore: "And more...",
  errorOnImpactedApisLoading:
    "Error while loading the list of impacted subscriptions.\n" +
    "You cannot continue the action.\n" +
    "Try again later or contact an admin if the problem persists.",
  alternativeSolution:
    "*If you really need this API without unsubscribing from your current APIs, you have these advanced different solutions:*\n" +
    "+ *Ask the API Managers (see button Contact API Owners) to change their Identity Provider Environment (it may impact their other consumers and may not be possible for them),*\n" +
    "+ *Ask the API Managers (see button Contact API Owners) to create a new API, still exposing their services, but based on your current Identity Provider Environment (they may not be able to handle this other environment),*\n" +
    "+ *Create a new application to subscribe to this API. But you will have to deal with two different user login form environments (which will be hard to implement/configure and, obviously, only fit for test environments).*",
  confirmButtonStep1: "Next",

  titleStep2: "You are about to unsubscribe from all your ACF APIs.",
  actionCannotBeUndone: "Be aware that this action cannot be undone.",
  unsubscriptionInputDescription:
    "Type **'UNSUBSCRIBE'** below and confirm to apply change:",
  confirmButtonStep2: "Unsubscribe from all",
  unsubscribe: "UNSUBSCRIBE",
  acfSubscriptionSuccessfullyDeleted: "ACF subscriptions removed",
};
