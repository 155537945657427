export default {
  myGroups: "My groups",
  manageGroups: "Manage groups",

  createNewGroup: "Create a new group",
  searchGroupPlaceholder: "Search group by name",
  noGroupFound: "No group found",
  groupInformations: "Group informations",
  groupName: "Group name",
  groupDescription: "Group description",
  lastUpdate: "Last update",
  cancel: "Cancel",
  update: "Update",
  create: "Create",
  noInformation: "-",

  seeInMCCLinkLabel: "See in MCC",
};
