import {
  ArrayNotEmpty,
  IsNotEmpty,
  IsPositive,
  Matches,
  Max,
  MaxLength,
  Min,
  MinLength,
  ValidateIf,
} from "class-validator";

import { FormData } from "@/commons/domain/forms/form-data";
import { FormFactory } from "@/commons/domain/forms/form-factory";

type Step = "FIRST_STEP" | "SECOND_STEP";

export class ApiForm extends FormData {
  step: Step;

  @ValidateIf((form) => ["FIRST_STEP", "SECOND_STEP"].includes(form.step))
  @ArrayNotEmpty({
    message: "You must select at least one Business Unit",
  })
  businessUnitTagIds: string[];

  @ValidateIf((form) => ["FIRST_STEP", "SECOND_STEP"].includes(form.step))
  @IsNotEmpty({
    message: "You must specify a product",
  })
  productId: string;

  @ValidateIf((form) => ["SECOND_STEP"].includes(form.step))
  @Matches(/^[a-zA-Z0-9\s]*[a-zA-Z][a-zA-Z0-9\s]*$/, {
    message: "Only non accented latin characters and alphanumerics are allowed",
  })
  @MinLength(3, {
    message:
      "This field is too short. Minimal length is $constraint1 characters",
  })
  @MaxLength(50, {
    message:
      "This field is too long. Maximal length is $constraint1 characters",
  })
  name: string;

  @ValidateIf((form) => ["SECOND_STEP"].includes(form.step))
  internal: boolean;

  @ValidateIf((form) => ["SECOND_STEP"].includes(form.step))
  @IsPositive()
  @Min(1, {
    message: "Minimal value is $constraint1",
  })
  @Max(99, {
    message: "Maximal value is $constraint1",
  })
  version: number;

  @ValidateIf((form) => ["SECOND_STEP"].includes(form.step))
  @MinLength(50, {
    message:
      "This field is too short. Minimal length is $constraint1 characters",
  })
  @MaxLength(1000, {
    message:
      "This field is too long. Maximal length is $constraint1 characters",
  })
  description: string;

  @ValidateIf((form) => ["FIRST_STEP"].includes(form.step))
  @IsNotEmpty({
    message: "The group is required",
  })
  groupId: string;

  constructor(step: Step) {
    super();
    this.step = step;
  }

  $clear() {
    this.businessUnitTagIds = [];
    this.productId = undefined;
    this.groupId = undefined;
    this.version = 1;
    this.internal = false;

    for (const key of ["name", "description"]) {
      this[key] = "";
    }
  }

  static create(step: Step) {
    return FormFactory.createForm(new ApiForm(step));
  }
}
