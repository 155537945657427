<template>
  <LayerModalFormConfirm
    class="modal-filters-product-catalog"
    :confirmBtnLabel="contents.confirmButtonLabel"
    confirmBtnTheme="solid"
    :disabledConfirmBtn="form.pristine"
    @submit="submit"
    @close="close"
  >
    <DvpField
      data-cy="platforms-filter"
      class="modal-filters-product-catalog__platforms"
      :label="contents.platformsLabel"
    >
      <MAutocomplete
        v-model="form.platformsIds"
        :items="platforms"
        :placeholder="contents.searchPlatforms"
        dataTextExpr="description"
        dataValueExpr="id"
        :multiple="true"
        :input="defaultSearchValue"
      />
    </DvpField>
  </LayerModalFormConfirm>
</template>
<script lang="ts">
import MAutocomplete from "@mozaic-ds/vue-3/src/components/autocomplete/MAutocomplete.vue";
import { PropType } from "vue";

import DvpField from "@/commons/components/form/DvpField.vue";
import LayerModalFormConfirm from "@/commons/components/form/LayerModalFormConfirm.vue";

import { Tag } from "@/commons/domain/models/tag";
import {
  FiltersProductCatalogForm,
  FiltersProductCatalogFormData,
} from "@/commons/forms/filters-product-catalog-form";

import contents from "@/documentation/contents/modal-filters-api-catalog";

export default {
  components: {
    LayerModalFormConfirm,
    MAutocomplete,
    DvpField,
  },
  props: {
    formData: {
      type: Object as PropType<FiltersProductCatalogFormData>,
      required: true,
    },
  },
  emits: ["submitted", "onClose"],
  data() {
    return {
      form: FiltersProductCatalogForm.create(),
      contents,
      defaultSearchValue: "",
    };
  },
  computed: {
    platforms(): Tag[] {
      return this.$store.getters["platformTagsList"];
    },
  },
  async mounted() {
    await this.form.init(this.formData);
  },
  methods: {
    close() {
      this.$emit("onClose");
    },
    async submit() {
      this.$emit("submitted", this.form.data());
      this.$emit("onClose");
    },
  },
};
</script>
