<template>
  <svg
    version="1.1"
    class="icon-publication"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="icon-publication"
    viewBox="0 -5 30 30"
  >
    <g>
      <g>
        <polygon
          class="st11"
          points="10.0338364,10.1269808 10.0849361,10.1269808 10.0593863,10.1014309 			"
        />
      </g>
      <g>
        <path
          d="M5.1088719,13.637579l0.0001521,0.0001526c0.3904824,0.3904819,1.0235791,0.3904819,1.4140611,0l2.5261359-2.5261364
				l0.0005169,5.88974c0.0000477,0.5521908,0.4477005,0.9998035,0.9998922,0.9998035h0.0000019
				c0.5522594,0,0.9999409-0.4477196,0.9998922-0.999979l-0.0005169-5.9101076l2.427907,2.427907
				c0.3904829,0.3904829,1.0235796,0.3904829,1.4140615,0l0.0001526-0.0001516c0.3904819-0.3904829,0.3904819-1.0235796,0-1.4140615
				l-4.8317423-4.8317423l-4.9505143,4.9505138C4.7183895,12.6140003,4.7183895,13.247097,5.1088719,13.637579z
				 M10.0338364,10.1269808l0.0255499-0.0255499l0.0255499,0.0255499H10.0338364z"
        />
      </g>
    </g>
    <g>
      <polygon
        points="19.0000229,17 15.0000238,17 15.0000238,15 17.0000229,15 17.0000229,5 3.0000234,5 3.0000234,15 5.0000234,15
			5.0000234,17 1.0000234,17 1.0000234,3 19.0000229,3 		"
      />
    </g>
  </svg>
</template>
