export default {
  modalTitle: "Information about IP Filtering",
  title: "You are about to activate IP Filtering on your API's PUBLIC route.",
  explanation:
    "Some of your consumers have not set IP address and, if they use your PUBLIC route, they will be blocked.",
  managerChecksText:
    "Consumers using the PRIVATE route will not be impacted.\n\n" +
    "Before activating this feature, have you already notified your Public Route's users about this incoming new protection, to give them time to configure their Applications?",
  closeBtnLabel: "Close",
  consumersBtnLabel: "Go to Consumers page",
  confirmBtnLabel: "I understand and activate anyway IP Filtering",
};
