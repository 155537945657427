<template>
  <div class="quota-rate-limit-display">
    <div class="quota-rate-limit-display__number">
      <div class="quota-rate-limit-display__barometer-icon">
        <img :src="quotaIcon" />
      </div>
      <div class="quota-rate-limit-display__requests-number">
        {{ rateLimit.maxRequests }}
      </div>
      <div>
        {{
          pluralize(rateLimit.maxRequests, quotaContents.request, {
            shouldExcludeCount: true,
          })
        }}
      </div>
      <div class="quota-rate-limit-display__unit-number">
        / {{ rateLimit.windowLength }}
      </div>
      <div class="quota-rate-limit-display__unit-length">
        {{
          pluralize(
            rateLimit.windowLength,
            getWindowLengthUnitLabel(rateLimit.windowLengthUnit),
            { shouldExcludeCount: true },
          )
        }}
      </div>
    </div>
    <div
      class="quota-rate-limit-display__modify-quota cursor-pointer"
      @click="$emit('editRateLimit', rateLimit)"
    >
      <MIcon
        name="PublishEdit24"
        class="quota-rate-limit-display__modify-quota-icon"
      />
      <div>{{ contents.modifyGlobalQuota }}</div>
    </div>
    <div>
      <MToggle
        :id="toggleId"
        :disabled="isSaving"
        :modelValue="rateLimit.isEnabled"
        @update:modelValue="$emit('activationChange', $event)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import MIcon from "@mozaic-ds/vue-3/src/components/icon/MIcon.vue";
import MToggle from "@mozaic-ds/vue-3/src/components/toggle/MToggle.vue";
import { PropType } from "vue";

import { pluralize } from "@/commons/utils/contents-utils";
import { getWindowLengthUnitLabel } from "@/commons/utils/quota-utils";

import { Api } from "@/commons/domain/models/api";
import { RateLimit } from "@/commons/domain/models/rate-limit";

import quotaContents from "@/commons/contents/quotas";
import contents from "@/manager/contents/quota-rate-limit-display";

export default {
  components: {
    MIcon,
    MToggle,
  },
  props: {
    rateLimit: {
      type: Object as PropType<RateLimit>,
      required: true,
    },
  },
  emits: ["editRateLimit", "activationChange"],
  data() {
    return {
      quotaIcon: require("@/commons/assets/icons/icon-quota.svg"),
      contents,
      quotaContents,
    };
  },
  computed: {
    currentApi(): Api {
      return this.$store.getters["currentApi"];
    },
    isSaving(): boolean {
      return this.$store.getters["isSaving"];
    },
    toggleId(): string {
      return `quota-rate-limit-display-toggle_${this.$.uid}`;
    },
  },
  methods: {
    pluralize,
    getWindowLengthUnitLabel,
  },
};
</script>

<style lang="scss">
.quota-rate-limit-display {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border: var(--border-default);
  border-radius: 2.5rem;
}

.quota-rate-limit-display__number,
.quota-rate-limit-display__modify-quota {
  display: flex;
  align-items: center;
}

.quota-rate-limit-display__barometer-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  margin-right: 0.5rem;
  background-color: var(--color-m-secondary-light);
  border-radius: 2.5rem;
}

.quota-rate-limit-display__requests-number,
.quota-rate-limit-display__unit-number {
  margin-right: 0.5rem;
  font-size: 2.125rem;
  font-weight: 700;
}

.quota-rate-limit-display__modify-quota-icon {
  margin-right: 0.5rem;
}

.quota-rate-limit-display__modify-quota {
  text-decoration: underline;
}
</style>
