const INTERNAL_LDAP_REGEX = /^\d{8}$/;
const PARTNER_LDAP_REGEX = /^T\d{7}$/;

export function isValidLDAP(ldap: string): boolean {
  return (
    ldap != undefined &&
    (INTERNAL_LDAP_REGEX.test(ldap) || PARTNER_LDAP_REGEX.test(ldap))
  );
}

export function getUserName(name: string, isCurrentUser: boolean): string {
  if (isCurrentUser) {
    return `${name} (You)`;
  } else {
    return name;
  }
}

export function getUserNameWithValidLDAP(
  name: string,
  username: string,
  isCurrentUser: boolean,
): string {
  if (isCurrentUser) {
    return `${name} (You)`;
  } else if (isValidLDAP(username)) {
    return `${name} (${username})`;
  }
  return `${name}`;
}
