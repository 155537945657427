<template>
  <tr :class="['create-custom-query-param', { disabled: isDisabled }]">
    <template v-if="showCreationForm">
      <td>
        <FormInput :error="form.firstError('name')">
          <TextInput
            v-model="form.name"
            reference="inputCustomQueryParamName"
            :placeholder="contents.namePlaceholder"
            :isDisabled="isDisabled"
          />
        </FormInput>
      </td>
      <td>
        <FormInput :error="form.firstError('value')">
          <TextInput
            v-model="form.value"
            :placeholder="contents.valuePlaceholder"
            :isDisabled="isDisabled"
          />
        </FormInput>
      </td>
      <td class="text-centered">
        <CancelAndSubmitButtons @cancel="cancel" @submit="create" />
      </td>
    </template>
    <template v-else>
      <td colspan="3">
        <AddPolicyButton :label="contents.buttonAdd" @add="edit" />
      </td>
    </template>
  </tr>
</template>

<script lang="ts">
import CancelAndSubmitButtons from "@/commons/components/Buttons/CancelAndSubmitButtons.vue";
import FormInput from "@/commons/components/FormInput.vue";
import TextInput from "@/commons/components/TextInput.vue";
import AddPolicyButton from "@/manager/views/ApiDetailPolicies/AddPolicyButton.vue";

import contents from "@/manager/contents/create-custom-query-param";

export default {
  name: "CreateCustomQueryParam",
  components: { CancelAndSubmitButtons, TextInput, FormInput, AddPolicyButton },
  props: {
    formParent: {
      type: Object,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["create", "close"],
  data() {
    return {
      contents,
      isEditing: false,
    };
  },
  computed: {
    showCreationForm() {
      return this.isEditing || this.hasError;
    },
  },
  methods: {
    async create() {
      if (!this.isDisabled) {
        await this.form.validate();
        await this.formParent.validate();

        if (!this.form.errors.length && !this.formParent.errors.length) {
          var { name, value } = this.form.data();
          this.$emit("create", { name, value });
          this.isEditing = false;
        }
      }
    },
    async cancel() {
      if (!this.isDisabled) {
        await this.form.clear();
        await this.formParent.validate();
        this.isEditing = false;
        this.$emit("close");
      }
    },
    async edit() {
      this.isEditing = true;
      this.form.clear();
    },
  },
};
</script>
