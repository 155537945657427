import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "user-role-icon"
}
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MIcon = _resolveComponent("MIcon")!

  return ($props.user)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
        ($props.user.isPartner)
          ? (_openBlock(), _createBlock(_component_MIcon, {
              key: 0,
              name: "PartnerCompany24"
            }))
          : (_openBlock(), _createElementBlock("img", {
              key: 1,
              class: "user-role-icon__internal-icon",
              src: $data.devportalIcon
            }, null, 8, _hoisted_2))
      ]))
    : _createCommentVNode("", true)
}