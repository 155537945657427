import { getContractRepository } from "@/commons/repositories/libs/get-contract-repository";

/**
 * Check tokens statuses on Ping side
 * Returns list of disabled token IDs
 * @param tokenIds
 */
export async function checkOAuthTokensStatusesOnPingSide(
  tokenIds: string[],
): Promise<string[]> {
  if (tokenIds && tokenIds.length > 0) {
    return await getContractRepository().checkOAuthTokensStatusesOnPingSide(
      tokenIds,
    );
  }
  return [];
}
