<template>
  <div class="search-text-filter">
    <div v-if="label" class="search-text-filter__label">{{ label }}</div>
    <MTextInput
      type="search"
      icon="DisplaySearch32"
      :placeholder="placeholder"
      :modelValue="queryParamValue"
      :size="size"
      :isInvalid="isInvalid"
      textInputFieldClass="search-text-filter__input"
      @update:modelValue="debouncedSearch"
    />
  </div>
</template>

<script lang="ts">
import MTextInput from "@mozaic-ds/vue-3/src/components/textinput/MTextInput.vue";

import { Debouncer } from "@/commons/libs/utils/debouncer";
import { changeUrlQuery } from "@/commons/utils/route-utils";

export default {
  name: "SearchTextFilter",
  components: {
    MTextInput,
  },
  props: {
    label: {
      type: String,
      default: undefined,
    },
    placeholder: {
      type: String,
      default: "",
    },
    queryParamName: {
      type: String,
      required: true,
    },
    queryParamValue: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "m",
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      debouncer: new Debouncer(),
    };
  },
  methods: {
    debouncedSearch(value: string): void {
      this.debouncer.debounce(() => this.updateUrlQuery(value), 100);
    },
    updateUrlQuery(value: string) {
      changeUrlQuery({ [this.queryParamName]: value, page: 1 });
    },
  },
};
</script>

<style lang="scss">
.search-text-filter__label {
  @include set-text-s;
  margin-bottom: 0.5rem;
  color: var(--color-text-secondary);
}

.search-text-filter {
  min-width: 20rem;
}
</style>
