<template>
  <layout class="use-cases">
    <div class="use-cases__title">
      <div class="description">{{ contents.description }}</div>
      <!--eslint-disable-next-line vue/no-v-html-->
      <h1 class="header-title" v-html="contents.title" />
    </div>
    <div class="use-cases__content">
      <use-case-card
        v-for="(useCase, index) in useCases"
        :key="`use-case-${index}`"
        :image="useCase.image"
        :title="useCase.title"
        :description="useCase.description"
        :path="`/usecases/${useCase.slug}`"
      />
    </div>
    <contact-us />
    <template #footer>
      <footer-menus />
    </template>
  </layout>
</template>

<script lang="ts">
import ContactUs from "@/commons/components/ContactUs.vue";
import FooterMenus from "@/commons/components/FooterMenus.vue";
import Layout from "@/commons/components/Layout.vue";
import UseCaseCard from "@/commons/components/UseCaseCard.vue";

import useCases from "@/showcase/contents/use-cases";

const contents = {
  title:
    "<strong>Discover all our use cases</strong> <br />made by partners and users",
  description: "Use case studies",
};

export default {
  name: "UseCases",
  components: {
    Layout,
    UseCaseCard,
    ContactUs,
    FooterMenus,
  },
  extends: Layout,
  data() {
    return {
      contents,
      useCases,
    };
  },
  computed: {
    locationSettings() {
      return this.$store.getters["config/locationSettings"];
    },
  },
};
</script>

<style lang="scss">
@import "~@/commons/assets/styles/vars";

.use-cases .layout__content {
  position: relative;

  &::before {
    position: absolute;
    top: -20rem;
    left: 0;
    width: 100vw;
    height: 100%;
    content: "";

    background-color: var(--color-leroy-merlin);
    transform: skewX(-73deg);
  }
}

.use-cases__title {
  position: relative;
  width: 91.4rem;
  margin-top: 3rem;
}

.use-cases__title .description {
  font-weight: 200;
  color: var(--color-leroy-merlin);
  text-transform: uppercase;
}

.use-cases__content {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  width: 91.4rem;
  padding: 4rem 0;

  .use-case-card {
    flex: none;
    width: 27rem;
    margin: 0 4rem 3rem 0;

    &:nth-child(3n) {
      margin: 0 0 3rem;
    }
  }
}

@media only screen and (max-width: map-get($breakpoints, desktop-small)) {
  .use-cases__title {
    width: 90%;
  }

  .use-cases__content {
    width: 90%;
    padding: 4rem 0;

    .use-case-card {
      flex: none;
      width: calc(40% - 2.45rem);
      margin: 0 4rem 3rem 0;

      &:nth-child(3n) {
        margin: 0 0 3rem;
      }
    }
  }
}

@media only screen and (max-width: map-get($breakpoints, tablet)) {
  .use-cases__title {
    margin-top: 5rem;
    text-align: center;
  }
}

@media only screen and (max-width: map-get($breakpoints, mobile-large)) {
  .use-cases__content {
    padding: 4rem 0;

    .use-case-card {
      flex: none;
      width: calc(50% - 2rem);
      margin: 0 4rem 3rem 0;

      &:nth-child(3n) {
        margin: 0 4rem 3rem 0;
      }

      &:nth-child(2n) {
        margin: 0 0 3rem;
      }
    }
  }
}

@media only screen and (max-width: map-get($breakpoints, mobile-large)) {
  .use-cases .layout__content {
    position: relative;

    &::before {
      top: -30rem;
      width: 200vw;
    }
  }

  .use-cases__title {
    margin-top: 1rem;
  }

  .use-cases__content {
    padding: 2rem 0 4rem;
    margin: 0 auto;

    .use-case-card {
      flex: none;
      width: 100%;
      margin: 0 auto 3rem;

      &:nth-child(3n) {
        margin: 0 auto 3rem;
      }

      &:nth-child(2n) {
        margin: 0 auto 3rem;
      }
    }
  }
}
</style>
