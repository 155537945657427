<template>
  <div class="api-card">
    <div class="card-infos">
      <div class="card-icon">
        <i class="api-icon" :style="{ 'background-image': apiIcon }"></i>
      </div>
      <div class="card-body loading">
        <div class="card-title">
          <div class="loading-h3"></div>
        </div>
        <div v-if="!noDescription" class="loading-description">
          <div class="loading-p"></div>
        </div>
        <div class="card-slot">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: "ApiListItemLoadingSkeleton",
  props: {
    maxDescLength: {
      default: 120,
      type: Number,
    },
    noDescription: {
      type: Boolean,
    },
  },
  computed: {
    apiIcon(): string | null {
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/commons/assets/styles/vars";
@import "~@/commons/assets/styles/lib/skeleton-loading-mixin";

.api-card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 6rem;
  padding: 1rem;
  overflow: hidden;
  border: var(--border-default);
  border-radius: 6px;
  transition: box-shadow 0.3s ease-in-out;

  .loading::after {
    @include skeleton-loading;
  }

  .card-infos {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  &:hover {
    box-shadow: var(--m-shadow-medium);
  }

  .card-title {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 1.5rem;

    .loading-h3 {
      width: 10em;
      height: 0.9rem;
      margin: 0.7rem 0;
      background-color: var(--color-light-grey);
      border-radius: 2rem;
    }

    .access-icon {
      margin-right: 0.25rem;
      font-size: 1.4rem;
      color: var(--color-grey);
    }
  }

  .loading-p {
    display: block;
    width: 150px;
    height: 0.7rem;
    margin: 0.7rem 0;
    background-color: var(--color-light-grey);
    border-radius: 2rem;
  }

  .api-icon {
    display: block;
    flex-shrink: 0;
    float: left;
    width: 3rem;
    height: 3rem;
    background: transparent url("./default-api-icon.svg") no-repeat center
      center;
    background-size: 65%;
    border: var(--border-default);
    border-radius: 6px;
    opacity: 0.3;
  }

  .card-slot {
    overflow: hidden;
  }

  .card-slot,
  .card-body {
    font-size: 0.9rem;
    line-height: 1.25rem;
  }

  .card-icon {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
  }
}
</style>
