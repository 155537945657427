import { getApiLabelWithVersion } from "@/commons/libs/utils/apiUtils";
import { userHasWriteAccessOnApi } from "@/commons/utils/api-utils";
import { userHasReadAccessOnApplication } from "@/commons/utils/application-utils";
import { convertRouteToHref } from "@/commons/utils/route-utils";

import {
  EApplicationType,
  ERequestStatus,
  ERequestType,
} from "@/commons/store/types";

function computeLinkToApplication(application, applicationLabel) {
  return application?.id != undefined
    ? `<a href="${convertRouteToHref({
        name: "application",
        params: {
          id: application.id,
        },
      })}" target="_blank">${applicationLabel}</a>`
    : applicationLabel;
}

function computeLinkToApiDoc(api, apiLabel) {
  return api?.id != undefined
    ? `<a href="${convertRouteToHref({
        name: "apiDocumentationDetail",
        params: {
          id: api.id,
        },
      })}" target="_blank">${apiLabel}</a>`
    : apiLabel;
}

function computeLinkToApiSettings(api, apiLabel) {
  return api?.id != undefined
    ? `<a href="${convertRouteToHref({
        name: "managerApiDetailSettings",
        params: {
          id: api.id,
        },
      })}" target="_blank">${apiLabel}</a>`
    : apiLabel;
}

function computeApiLabel(api) {
  return api?.id != undefined ? getApiLabelWithVersion(api) : "an unknown API";
}

function computeApplicationLabel(application) {
  return application?.id != undefined
    ? application.name
    : "an unknown application";
}

function computeApiLinkBasedOnUserAccess(api, apiLabel) {
  return userHasWriteAccessOnApi(api)
    ? computeLinkToApiSettings(api, apiLabel)
    : computeLinkToApiDoc(api, apiLabel);
}

function computeApplicationLinkBasedOnUserAccess(
  application,
  applicationLabel,
) {
  return userHasReadAccessOnApplication(application)
    ? computeLinkToApplication(application, applicationLabel)
    : applicationLabel;
}

function computeRequestApiLabel(request, shouldCreateLinkTag) {
  const label = computeApiLabel(request.api);
  return shouldCreateLinkTag
    ? computeApiLinkBasedOnUserAccess(request.api, label)
    : label;
}

function computeRequestApplicationLabel(request, shouldCreateLinkTag) {
  const label = computeApplicationLabel(request.application);
  return shouldCreateLinkTag
    ? computeApplicationLinkBasedOnUserAccess(request.application, label)
    : label;
}

function extendTokenSummary(request, shouldCreateLinkTag) {
  const apiLabel = computeRequestApiLabel(request, shouldCreateLinkTag);
  const applicationLabel = computeRequestApplicationLabel(
    request,
    shouldCreateLinkTag,
  );

  return request.application?.type !== EApplicationType.SANDBOX
    ? `Token extension between ${applicationLabel} and ${apiLabel}`
    : `Sandbox token for ${applicationLabel}`;
}

function newTokenSummary(request, shouldCreateLinkTag) {
  const apiLabel = computeRequestApiLabel(request, shouldCreateLinkTag);
  const applicationLabel = computeRequestApplicationLabel(
    request,
    shouldCreateLinkTag,
  );

  return request.application?.type !== EApplicationType.SANDBOX
    ? `New token between ${applicationLabel} and ${apiLabel}`
    : `Sandbox contract for ${apiLabel}`;
}

function newContractSummary(request, shouldCreateLinkTag) {
  const apiLabel = computeRequestApiLabel(request, shouldCreateLinkTag);
  const applicationLabel = computeRequestApplicationLabel(
    request,
    shouldCreateLinkTag,
  );

  return request.application?.type !== EApplicationType.SANDBOX
    ? `New subscription between ${applicationLabel} and ${apiLabel}`
    : `Sandbox access to ${apiLabel}`;
}

function accessPolicyUpdateSummary(request, shouldCreateLinkTag) {
  return `Access restriction update for ${computeRequestApiLabel(
    request,
    shouldCreateLinkTag,
  )}`;
}

function getOtherGatewaysSummary(request, shouldCreateLinkTag) {
  return `Get other gateways for ${computeRequestApiLabel(
    request,
    shouldCreateLinkTag,
  )}`;
}

function timeoutSummary(request, shouldCreateLinkTag) {
  return `Timeout change for ${computeRequestApiLabel(
    request,
    shouldCreateLinkTag,
  )}`;
}

function switchToOauthSummary(request, shouldCreateLinkTag) {
  return `Change to OAuth for ${computeRequestApiLabel(
    request,
    shouldCreateLinkTag,
  )}`;
}

function switchToApiKeySummary(request, shouldCreateLinkTag) {
  return `Change to API KEY for ${computeRequestApiLabel(
    request,
    shouldCreateLinkTag,
  )}`;
}

export default {
  title: "Request Manager",
  description: "Access to user requests",
  requestTypeLabels: {
    [ERequestType.EXTEND_TOKEN]: "Token extension",
    [ERequestType.NEW_TOKEN]: "New token",
    [ERequestType.NEW_CONTRACT]: "New subscription",
    [ERequestType.UPDATE_ACCESS_POLICY]: "Update access policy",
    [ERequestType.OTHER_GATEWAYS]: "Access to other gateway",
    [ERequestType.TIMEOUT]: "Timeout change",
    [ERequestType.SWITCH_TO_API_KEY]: "Change to API KEY",
  },
  statusLabels: {
    [ERequestStatus.APPROVAL_REQUIRED]: "Pending",
    [ERequestStatus.APPROVED]: "Accepted",
    [ERequestStatus.DENIED]: "Refused",
    [ERequestStatus.CANCELLED]: "Cancelled",
  },
  ui: {
    productListTitle: "List of requests",
    searchLabel: "Search for request",
    forApplicationLabel: "For application",
    selectRequestLabel: "Select request for details",
    requestStatus: "Request is",
    otherInformation: "Other information",
    cslName: "CSL Name",
    cslMessage: "CSL Message",
    buttons: {
      denyRequest: "Deny",
      acceptRequest: "Accept",
      cancelRequest: "Cancel",
    },
    summary: {
      titles: {
        extendToken: extendTokenSummary,
        newToken: newTokenSummary,
        newContract: newContractSummary,
        accessPolicyUpdate: accessPolicyUpdateSummary,
        getOtherGateways: getOtherGatewaysSummary,
        timeout: timeoutSummary,
        switchToOAuth: switchToOauthSummary,
        switchToApiKey: switchToApiKeySummary,
      },
    },
    me_types: {
      EXTEND_TOKEN: "You have requested a token extension for",
      NEW_TOKEN: "You have requested a new token for",
      NEW_CONTRACT: "You have requested access to",
      UPDATE_ACCESS_POLICY:
        "You have requested an access restriction update for",
    },
    others_types: {
      EXTEND_TOKEN: "Request a token extension for",
      NEW_TOKEN: "Request a new token for",
      NEW_CONTRACT: "Request access to",
      UPDATE_ACCESS_POLICY: "Request an access restriction update for",
    },
    status: {
      APPROVAL_REQUIRED: "PENDING",
      APPROVED: "ACCEPTED",
      DENIED: "REFUSED",
      CANCELLED: "CANCELLED",
    },
  },
};
