import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MButton = _resolveComponent("MButton")!
  const _component_GlobalModalBody = _resolveComponent("GlobalModalBody")!

  return (_openBlock(), _createBlock(_component_GlobalModalBody, { class: "confirm-modal-body" }, {
    content: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_MButton, {
        "data-cy": "modal-cancel-btn",
        type: "button",
        theme: "bordered-neutral",
        label: $props.cancelBtnLabel,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onClose')))
      }, null, 8, ["label"]),
      _createVNode(_component_MButton, {
        "data-cy": "modal-submit-btn",
        theme: $props.confirmBtnTheme,
        label: $props.confirmBtnLabel,
        disabled: $props.disabledConfirmBtn,
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('onSubmit')))
      }, null, 8, ["theme", "label", "disabled"])
    ]),
    _: 3
  }))
}