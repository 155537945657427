import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "api-access-policy"
}
const _hoisted_2 = { class: "api-access-policy__icon" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "api-access-policy__label" }
const _hoisted_5 = {
  "data-cy": "api-privacy",
  class: "api-access-policy__privacy"
}
const _hoisted_6 = {
  "data-cy": "api-access-type",
  class: "api-access-policy__policy"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MIcon = _resolveComponent("MIcon")!

  return ($options.privacyInfo)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          ($options.privacyInfo.iconSvg)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: "api-access-policy__custom-icon",
                src: $options.privacyInfo.iconSvg
              }, null, 8, _hoisted_3))
            : (_openBlock(), _createBlock(_component_MIcon, {
                key: 1,
                class: "api-access-policy__mozaic-icon",
                name: $options.privacyInfo.iconName
              }, null, 8, ["name"]))
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString($options.privacyInfo.privacyLabel), 1),
          _createElementVNode("div", _hoisted_6, _toDisplayString($options.accessPolicyLabel), 1)
        ])
      ]))
    : _createCommentVNode("", true)
}