import { HttpRepository } from "@/commons/repositories/libs/http-repository";
import { BackendHttpError } from "@/commons/request-handlers/HttpResponseError";
import { HEADER_CONTENT_TYPE_TEXT } from "@/commons/request-handlers/request-handler-utils";

import { Injectable } from "@/commons/domain/di/injectable";
import { AclGroup } from "@/commons/domain/models/acl-group";
import { BlackListPolicy } from "@/commons/domain/models/black-list-policy";
import { Dictionary } from "@/commons/domain/models/dictionary";
import { PayloadCreateApi } from "@/commons/domain/models/payload-create-api";
import { PayloadUpdateApi } from "@/commons/domain/models/payload-update-api";
import { Quota } from "@/commons/domain/models/quota";
import { RateLimit } from "@/commons/domain/models/rate-limit";
import { Scope } from "@/commons/domain/models/scope";
import {
  ApiRepository,
  ApisFetchParams,
  RequestAuthTypeChangeParams,
} from "@/commons/domain/repositories/api-repository";
import { ApiDto } from "@/commons/dtos/api-dto";
import { CreatedResourceDto } from "@/commons/dtos/created-resource-dto";
import { PagedResourceDto } from "@/commons/dtos/paged-resource-dto";
import { PayloadUpdateRouteDTO } from "@/commons/dtos/payload-update-route-dto";
import { RoutingPolicyDto } from "@/commons/dtos/routing-policy-dto";
import { UpdateApiMonitoringDto } from "@/commons/dtos/update-api-monitoring-dto";
import { ValidationDTO } from "@/commons/dtos/validation-dto";
import { AclMapper } from "@/commons/mappers/acl-mapper";
import { ApiMapper } from "@/commons/mappers/api-mapper";
import { EOauthFlow } from "@/commons/types/oauth-flow-types";

import { EQuotaType, ERouteTypes } from "@/commons/store/types";

@Injectable()
export class ApiHttpRepository extends HttpRepository implements ApiRepository {
  public async findById(apiId: string) {
    const response = await this.requestHandler.get<ApiDto>(
      `/apis/${apiId}/_details`,
    );
    return ApiMapper.toApiDomain(response.data);
  }

  public async findByIdForDocumentation(apiId: string) {
    const response = await this.requestHandler.get<ApiDto>(`/apis/${apiId}`);
    return ApiMapper.toApiDomain(response.data);
  }

  public async find(params: ApisFetchParams) {
    return this.findApis(params);
  }

  public async cancelAndFind(params: ApisFetchParams) {
    return this.findApis(params, true);
  }

  private async findApis(params: ApisFetchParams, cancel: boolean = false) {
    const url = `/apis`;

    const response = cancel
      ? await this.requestHandler.cancelAndGet<PagedResourceDto<ApiDto>>(url, {
          params,
        })
      : await this.requestHandler.get<PagedResourceDto<ApiDto>>(url, {
          params,
        });

    return ApiMapper.toPagedDomain(response.data);
  }

  public async createApi(apiPayload: PayloadCreateApi) {
    const createdResource = await this.requestHandler.post<CreatedResourceDto>(
      `/apis`,
      ApiMapper.toCreateApiDto(apiPayload),
    );
    return this.findById(createdResource.data.id);
  }

  public async deleteApi(apiId: string) {
    await this.requestHandler.delete(`/apis/${apiId}`);
  }

  public async updateApi(apiPayload: PayloadUpdateApi) {
    await this.requestHandler.patch(
      `/apis/${apiPayload.id}`,
      ApiMapper.toUpdateApiDto(apiPayload),
    );
    return this.findById(apiPayload.id);
  }

  public async deprecateApi(apiId: string) {
    await this.requestHandler.post(`/apis/${apiId}/_deprecate`);
    return this.findById(apiId);
  }

  public async undeprecateApi(apiId: string) {
    await this.requestHandler.post(`/apis/${apiId}/_undeprecate`);
    return this.findById(apiId);
  }

  public async createRoute(
    apiId: string,
    zoneIds: string[],
    url: string,
    type: ERouteTypes,
    routingPolicies: RoutingPolicyDto[],
  ) {
    await this.requestHandler.post(`/apis/${apiId}/routes`, {
      zoneIds,
      url,
      type,
      routingPolicies,
    });

    return this.findById(apiId);
  }

  public async updateRoute(payloadUpdateRoute: PayloadUpdateRouteDTO) {
    const { apiId, routeId, ...payload } = payloadUpdateRoute;

    await this.requestHandler.patch(
      `/apis/${apiId}/routes/${routeId}`,
      payload,
    );

    return this.findById(apiId);
  }

  public async deleteRoute(apiId: string, routeId: string) {
    await this.requestHandler.delete(`/apis/${apiId}/routes/${routeId}`);
    return this.findById(apiId);
  }

  public async validateRouteTargetUrl(targetUrl: string) {
    const response = await this.requestHandler.post<ValidationDTO>(
      `/apis/route/_validateTargetUrl`,
      targetUrl,
      { headers: { ...HEADER_CONTENT_TYPE_TEXT } },
    );

    return response.data;
  }

  public async updateApiMonitoring(
    apiId: string,
    zoneId: string,
    isMonitored: boolean,
    monitoringUrl: string,
  ) {
    const updateApiMonitoringDto: UpdateApiMonitoringDto = {
      isEnabled: isMonitored,
      url: monitoringUrl,
    };
    await this.requestHandler.patch(
      `/apis/${apiId}/monitoring/${zoneId}`,
      updateApiMonitoringDto,
    );
    return this.findById(apiId);
  }

  public async publishApiInZone(apiId: string, zoneId: string) {
    await this.requestHandler.post(`/apis/${apiId}/zones/${zoneId}/_publish`);
    return this.findById(apiId);
  }

  public async unPublishApiInZone(apiId: string, zoneId: string) {
    await this.requestHandler.post(
      `/apis/${apiId}/zones/${zoneId}/_unpublish`,
      undefined,
      { params: { force: true } },
    );
    return this.findById(apiId);
  }

  public async deprecateApiZone(
    apiId: string,
    zoneId: string,
    deprecationDate: string | undefined,
    message: string,
  ) {
    await this.requestHandler.post(
      `/apis/${apiId}/zones/${zoneId}/_deprecate`,
      {
        deprecationDate,
        message,
      },
    );
    return this.findById(apiId);
  }

  public async createRateLimit(apiId: string, rateLimit: RateLimit) {
    await this.requestHandler.post(`/apis/${apiId}/ratelimits`, rateLimit);

    return this.findById(apiId);
  }

  public async updateRateLimit(apiId: string, rateLimit: RateLimit) {
    await this.requestHandler.put(
      `/apis/${apiId}/ratelimits/${rateLimit.id}`,
      rateLimit,
    );

    return this.findById(apiId);
  }

  public async deleteRateLimit(apiId: string, rateLimitId: string) {
    await this.requestHandler.delete(
      `/apis/${apiId}/ratelimits/${rateLimitId}`,
    );
    return this.findById(apiId);
  }

  public async createRateLimitForNewContracts(
    apiId: string,
    rateLimit: RateLimit,
  ) {
    await this.requestHandler.post(
      `/apis/${apiId}/ratelimits-new-contract`,
      rateLimit,
    );

    return this.findById(apiId);
  }

  public async updateRateLimitForNewContracts(
    apiId: string,
    rateLimit: RateLimit,
  ) {
    await this.requestHandler.put(
      `/apis/${apiId}/ratelimits-new-contract/${rateLimit.id}`,
      rateLimit,
    );

    return this.findById(apiId);
  }

  public async deleteRateLimitForNewContracts(
    apiId: string,
    rateLimitId: string,
  ) {
    await this.requestHandler.delete(
      `/apis/${apiId}/ratelimits-new-contract/${rateLimitId}`,
    );

    return this.findById(apiId);
  }

  public async createGroupAcl(apiId: string, groupId: string, roleId: string) {
    await this.requestHandler.post(`/apis/${apiId}/acl/groups`, {
      id: groupId,
      roleId,
    });
    return this.findById(apiId);
  }

  public async removeUserAcl(apiId: string, userId: string) {
    await this.requestHandler.delete(`/apis/${apiId}/acl/users/${userId}`);
    return this.findById(apiId);
  }

  public async removeGroupAcl(apiId: string, groupId: string) {
    await this.requestHandler.delete(`/apis/${apiId}/acl/groups/${groupId}`);
    return this.findById(apiId);
  }

  public async updateGroupAcl(
    apiId: string,
    groupId: string,
    action: "READ_ONLY" | "READ_WRITE",
  ) {
    await this.requestHandler.put(`/apis/${apiId}/acl/groups/${groupId}`, {
      action,
    });
    return this.findById(apiId);
  }

  public async updateGroupsAcl(apiId: string, groups: Dictionary<AclGroup>) {
    await this.requestHandler.put(
      `/apis/${apiId}/acl/groups`,
      AclMapper.toBulkUpdateByAccessRestrictionDto(groups),
    );
    return this.findById(apiId);
  }

  public async notifyUsers(apiId: string, subject: string, body: string) {
    await this.requestHandler.post(`/apis/${apiId}/_notifyUsers`, {
      subject,
      body,
    });
  }

  public async notifyOwners(apiId: string, subject: string, body: string) {
    await this.requestHandler.post(`/apis/${apiId}/_notifyOwners`, {
      subject,
      body,
    });
  }

  public async requestOtherGateways(apiId: string, body: string) {
    await this.requestHandler.post(`/apis/${apiId}/_requestOtherGateways`, {
      body,
      subject: "Request other gateways",
    });
  }

  public async canContactOwners(apiId: string) {
    // The endpoint doesn't return anything, it's a boolean based on (HTTP status SUCCESS => true, HTTP status ERROR => false)
    try {
      await this.requestHandler.get(`/apis/${apiId}/_canContactOwners`);
      return true; // if we arrive here, it means the request was a success, we had an HTTP 200 so we say we can contact owners
    } catch (error) {
      // if HTTP 404, it's a NOT FOUND, we cannot contact owners
      if (error instanceof BackendHttpError && error.isNotFound()) {
        return false;

        // else: it's an unknown error, we let the global error system handle it
      } else {
        throw error;
      }
    }
  }

  public async createOrUpdateBlackListPolicy(
    apiId: string,
    blackListPolicy: BlackListPolicy,
  ) {
    if (blackListPolicy.id) {
      await this.requestHandler.put(
        `/apis/${apiId}/black-list-policies/${blackListPolicy.id}`,
        blackListPolicy,
      );
    } else {
      await this.requestHandler.post(
        `/apis/${apiId}/black-list-policies`,
        blackListPolicy,
      );
    }
    return this.findById(apiId);
  }

  public async deleteBlackListPolicy(apiId: string, blackListPolicyId: string) {
    await this.requestHandler.delete(
      `/apis/${apiId}/black-list-policies/${blackListPolicyId}`,
    );

    return this.findById(apiId);
  }

  public async getActiveQuota(apiId: string) {
    const response = await this.requestHandler.get<Quota>(
      `/apis/${apiId}/active-quota`,
    );
    return response.data;
  }

  public async updateQuotaType(apiId: string, quotaType: EQuotaType) {
    await this.requestHandler.put(`/apis/${apiId}/quota-type`, quotaType, {
      headers: { ...HEADER_CONTENT_TYPE_TEXT },
    });
  }

  public async hasAnyActiveSubscription(
    apiId: string,
    includeSandboxes?: boolean,
    oauthFlow?: EOauthFlow,
  ) {
    const response = await this.requestHandler.get<boolean>(
      `/apis/${apiId}/_hasAnyActiveSubscription`,
      { params: { oauthFlow, includeSandboxes } },
    );

    return response.data;
  }

  public async requestAuthTypeChange({
    apiId,
    authType,
    comment,
    cslName,
    cslMessage,
  }: RequestAuthTypeChangeParams) {
    await this.requestHandler.post(`/apis/${apiId}/_requestAuthTypeChange`, {
      authType,
      comment,
      cslName,
      cslMessage,
    });
  }

  public async updateOauthFlows(
    apiId: string,
    oauthFlows: EOauthFlow[],
  ): Promise<void> {
    await this.requestHandler.put(`/apis/${apiId}/oauthFlows`, oauthFlows);
  }

  public async exportConsumers(apiId: string) {
    const response = (await this.requestHandler.get<Blob>(
      `/apis/${apiId}/exportConsumers`,
      { responseType: "blob" } as any,
    )) as any;

    return {
      filename: response.headers["content-disposition"].split("filename=")[1],
      data: response.data,
    };
  }

  public async getApiScopes(apiId: string) {
    const response = await this.requestHandler.get<Scope[]>(
      `/scopes/apis/${apiId}`,
    );
    return response.data;
  }

  public async updateApiScopes(apiId: string, scopes: string[]) {
    await this.requestHandler.post(`/scopes/apis/${apiId}/_add`, { scopes });
  }
}
