<template>
  <SvgWithLabel class="authorization-required">
    <IconAuthorizationRequired />
    <template #right>
      {{ contents.requiredAuthorization }}
    </template>
  </SvgWithLabel>
</template>

<script lang="ts">
import SvgWithLabel from "@/commons/components/SvgWithLabel.vue";

import IconAuthorizationRequired from "./IconAuthorizationRequired.vue";

import contents from "@/commons/contents/authorization-required";

export default {
  name: "AuthorizationRequired",
  components: { SvgWithLabel, IconAuthorizationRequired },
  data() {
    return {
      contents,
    };
  },
};
</script>

<style lang="scss">
.authorization-required svg {
  width: 1rem;
  height: auto;
  margin-right: 0.4rem;
  overflow: visible;
}
</style>
