import { sortHttpMethods } from "@/commons/utils/http-method";

import { BlackListPolicy } from "@/commons/domain/models/black-list-policy";
import { BlackListPolicyDto } from "@/commons/dtos/black-list-policy-dto";

export class BlackListPolicyMapper {
  static toDomain(
    blackListPoliciesDtos: BlackListPolicyDto[],
  ): BlackListPolicy[] {
    return blackListPoliciesDtos.map((dto) =>
      this.toBlackListPolicyDomain(dto),
    );
  }

  static toBlackListPolicyDomain(
    blackListPolicyDto: BlackListPolicyDto,
  ): BlackListPolicy {
    return {
      id: blackListPolicyDto.id,
      pathRegex: blackListPolicyDto.pathRegex,
      httpMethods: sortHttpMethods(blackListPolicyDto.httpMethods),
    };
  }
}
