import { MSelectOption } from "@/commons/domain/models/mozaic";

import { ERole } from "@/commons/store/types";

export function mapRolesToMSelectOptions(roles: ERole[]): MSelectOption[] {
  return roles.map((role) => ({
    value: role,
    text: role,
  }));
}

export const ROLES_APPLICABLE_TO_GROUP = [ERole.MANAGER, ERole.VIEWER];
export const ROLES_APPLICABLE_TO_APPLICATION = [ERole.MANAGER, ERole.VIEWER];
