export default {
  // common labels
  title: "Create a new API",
  back: "Back",
  next: "Next",
  cancel: "Cancel",
  previous: "Previous",

  fillGlobalInformationStepLabel: "Fill global information",
  fillApiInformationStepLabel: "Fill API information",

  // step 1 labels
  relatedProduct: "Related product",
  relatedProductHelpText:
    "Products come from Tangram. Make sure it is created in Tangram.",
  relatedProductPlaceholder: "Search for related product",
  businessUnit: "Business Unit",
  businessUnitHelpText:
    "Indicate which BU will use your API. Multiple choice is possible.",
  businessUnitPlaceholder: "Search for Business Unit",
  managerGroup: "Manager group",
  managerGroupHelpText: "Choose the group responsible for the API.",
  managerGroupPlaceholder: "Choose your group",
  linkToGroupsSettings: "Click here to manage groups",
  groupCreationSuccessNotification: "Group created",

  // step2 labels
  apiName: "API name",
  apiNameHelpText:
    "The API type is automatically added in front of the API name",
  apiNamePlaceholder: "Enter the name",
  apiType: "API type",
  apiTypeHelpText:
    "The API type is for a micro-service. The BACKEND type is for a BFF (backend for frontend)",
  apiTypePlaceholder: "API type",
  apiApiType: "API",
  backendApiType: "Backend",
  apiVersion: "API version",
  apiDescription: "API Description",
  apiDescriptionPlaceholder: "Type the description",
};
