<template>
  <div class="reporting-application-filters">
    <div class="reporting-application-filters__filters">
      <div class="reporting-application-filters__date-range">
        <ReportingDatePicker
          :onRangeChange="
            ({ start, end }) => (selectedDateRange = { from: start, to: end })
          "
          :startDate="selectedDateRange.from"
          :endDate="selectedDateRange.to"
          :loading="isFetchingReporting"
        />
      </div>
      <div class="reporting-application-filters__input-groups">
        <div class="input-group">
          <label>{{ contents.labelApis }}</label>
          <ReportingSelectFilter
            v-model="selectedApis"
            placeholder="Apis"
            :onFilterClose="() => null"
            :options="filterOptions.apis"
            :loading="isFetchingReporting"
            :maxLabelDisplayLength="3"
          />
        </div>
        <div class="input-group">
          <label>{{ contents.labelStatus }}</label>
          <ReportingSelectFilter
            v-model="selectedStatus"
            placeholder="Status"
            :onFilterClose="() => null"
            :searchable="false"
            :options="filterOptions.status"
            :loading="isFetchingReporting"
            :maxLabelDisplayLength="10"
          />
        </div>
        <div class="input-group">
          <label>{{ contents.labelMethods }}</label>
          <ReportingSelectFilter
            v-model="selectedMethods"
            placeholder="Methods"
            :onFilterClose="() => null"
            :searchable="false"
            :options="filterOptions.methods"
            :loading="isFetchingReporting"
            :maxLabelDisplayLength="10"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import ReportingDatePicker from "@/commons/components/Graph/ReportingDatePicker.vue";
import ReportingSelectFilter from "@/commons/components/Graph/ReportingSelectFilter.vue";

import contents from "@/dashboard/contents/reporting-application-filters";

export default {
  name: "ReportingApplicationFilters",
  components: {
    ReportingDatePicker,
    ReportingSelectFilter,
  },
  props: {
    applicationId: {
      type: String,
      default: null,
    },
  },
  emits: ["updateSelectedFilters"],
  data() {
    return {
      contents,
    };
  },
  computed: {
    isFetchingReporting() {
      return this.$store.getters["reporting/isFetchingReporting"];
    },
    filterOptions() {
      return this.$store.getters["reporting/filterOptions"];
    },
    selectedFilters() {
      return this.$store.getters["reporting/selectedFilters"];
    },
    selectedDateRange: {
      get: function () {
        return this.$store.getters["reporting/selectedDateRange"];
      },
      set: function ({ from, to }) {
        this.updateSelectedFilters({ from, to });
        this.$store.dispatch(
          "reporting/loadApplicationReportingFiltersOptions",
          this.applicationId,
        );
      },
    },
    selectedApis: {
      get: function () {
        return this.$store.getters["reporting/selectedApisOptions"];
      },
      set: function (apis) {
        this.updateSelectedFilters({ apis });
      },
    },
    selectedStatus: {
      get: function () {
        return this.$store.getters["reporting/selectedStatusOptions"];
      },
      set: function (status) {
        this.updateSelectedFilters({ status });
      },
    },
    selectedMethods: {
      get: function () {
        return this.$store.getters["reporting/selectedMethodsOptions"];
      },
      set: function (methods) {
        this.updateSelectedFilters({ methods });
      },
    },
  },
  methods: {
    updateSelectedFilters(filters) {
      this.$emit("updateSelectedFilters", filters);
    },
  },
};
</script>

<style lang="scss" scoped>
.reporting-application-filters {
  padding: 0 1rem 1rem;
  margin: 1rem 0;
  border: var(--border-default);
  border-radius: 5px;

  .reporting-application-filters__filters {
    display: flex;
    flex-wrap: wrap;
  }

  .reporting-application-filters__date-range,
  .reporting-application-filters__input-groups {
    width: 100%;
  }

  .datepicker-trigger {
    width: 25rem;
  }

  .reporting-application-filters__input-groups {
    display: flex;
    justify-content: space-between;
  }

  .reporting-application-filters__input-groups .input-group {
    width: 32%;
  }
}
</style>
