<template>
  <LayerModalFormConfirm
    class="application-managers-modal"
    confirmBtnIsHidden
    cancelBtnTheme="solid"
    :cancelBtnLabel="contents.closeBtnLabel"
    @close="emit('onClose')"
  >
    <div class="application-managers-modal__users-list">
      <UsersDataList
        v-for="group in groups"
        :key="group.id"
        :groupId="group.id"
        withTitle
        :group="group"
        hideTableHeaders
      />
    </div>
  </LayerModalFormConfirm>
</template>

<script setup lang="ts">
import UsersDataList from "@/commons/components/ManageGroups/UsersDataList.vue";
import LayerModalFormConfirm from "@/commons/components/form/LayerModalFormConfirm.vue";

import { AclGroup } from "@/commons/domain/models/acl-group";

import contents from "@/manager/contents/application-managers-modal";

interface Props {
  groups: AclGroup[];
}

withDefaults(defineProps<Props>(), { groups: () => [] });

const emit = defineEmits(["onClose"]);
</script>

<style lang="scss">
.application-managers-modal {
  display: flex;
  flex-direction: column;
}

.application-managers-modal__users-list {
  display: flex;
  flex-basis: 0;
  flex-direction: column;
  flex-grow: 1;
  padding-right: 0.6rem;
  overflow-y: auto;
  gap: 1rem;
}
</style>
