import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "publication-zone-card__general-info" }
const _hoisted_2 = {
  key: 0,
  class: "publication-zone-card__deprecated-container"
}
const _hoisted_3 = { class: "publication-zone-card__deprecated-date" }
const _hoisted_4 = { class: "publication-zone-card__status-container" }
const _hoisted_5 = {
  key: 0,
  class: "publication-zone-card__last-deploy"
}
const _hoisted_6 = { class: "publication-zone-card__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MBadge = _resolveComponent("MBadge")!
  const _component_IconTooltip = _resolveComponent("IconTooltip")!
  const _component_DvpToggle = _resolveComponent("DvpToggle")!
  const _component_ToggleField = _resolveComponent("ToggleField")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_PublicationZoneAccessUrls = _resolveComponent("PublicationZoneAccessUrls")!
  const _component_CardContainer = _resolveComponent("CardContainer")!

  return (_openBlock(), _createBlock(_component_CardContainer, { class: "publication-zone-card" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        ($options.zoneIsDeprecated)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("span", _hoisted_3, _toDisplayString($options.deprecationDateMessage), 1),
              _createVNode(_component_MBadge, {
                class: "publication-zone-card__deprecated-badge",
                type: "danger"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($data.contents.deprecatedLabel), 1)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, [
          ($options.formattedLastPublicationDate)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString($data.contents.publicationDateMessage($options.formattedLastPublicationDate)), 1))
            : _createCommentVNode("", true),
          ($options.zonePublicationStatus)
            ? (_openBlock(), _createBlock(_component_MBadge, {
                key: 1,
                type: $options.statusBadgeType
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($data.contents.publicationStatus[$options.zonePublicationStatus]) + " ", 1),
                  ($options.errorMessage)
                    ? (_openBlock(), _createBlock(_component_IconTooltip, {
                        key: 0,
                        position: "bottom",
                        text: $options.errorMessage,
                        iconName: "NotificationQuestion24"
                      }, null, 8, ["text"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["type"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("h5", _hoisted_6, _toDisplayString($props.zone.name), 1),
        _createVNode(_component_ToggleField, { class: "publication-zone-card__publish" }, {
          label: _withCtx(() => [
            _createElementVNode("label", null, _toDisplayString($data.contents.publishLabel), 1)
          ]),
          toggleButton: _withCtx(() => [
            _createVNode(_component_DvpToggle, {
              checked: $options.zoneIsPublished,
              disabled: $options.publicationToggleIsDisabled,
              onClick: $options.togglePublication
            }, null, 8, ["checked", "disabled", "onClick"])
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_Divider, { class: "publication-zone-card__divider" }),
      _createVNode(_component_PublicationZoneAccessUrls, {
        class: "publication-zone-card__zone-access-urls",
        api: $props.api,
        privateRoutes: $options.privateRoutes,
        publicRoutes: $options.publicRoutes,
        isDisabled: 
        $options.isChangingPublicationState || !$options.canPublish || !$options.zoneIsPublished
      
      }, null, 8, ["api", "privateRoutes", "publicRoutes", "isDisabled"])
    ]),
    _: 1
  }))
}