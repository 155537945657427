import { Converter } from "showdown";
import filterXSS, { whiteList } from "xss";

import { decodeHTML } from "@/commons/libs/utils/html";

function getCustomXssFilter() {
  return [
    {
      type: "output",
      filter: function (text) {
        const xssOptions = {
          whiteList: {
            ...whiteList,
            h1: ["id"],
            h2: ["id"],
            h3: ["id"],
            h4: ["id"],
            h5: ["id"],
            h6: ["id"],
          },
        };
        return filterXSS(text, xssOptions);
      },
    },
  ];
}

export class MarkdownRenderer extends Converter {
  [x: string]: any;
  private metadataString: string;

  constructor(private content: string) {
    super({
      tables: true,
      tasklists: true,
      simpleLineBreaks: true,
      openLinksInNewWindow: true,
      extensions: [getCustomXssFilter()],
    });
    this.setFlavor("github");
    this.content = this.reworkMarkdownSyntax(content);
  }

  private reworkMarkdownSyntax(rawContent: string) {
    // It's best practise to have an empty line before and after a horizontal line symbol ('---') to prevent from a header title interpretation
    return rawContent.replace(/([^\n]?)\n---\n([^\n]?)/g, "$1\n\n---\n\n$2");
  }

  toHtml(): string {
    return `<div>
      ${this.makeHtml(this.content.replace(/^---([\s\S]*?)---$/m, "\n"))}
    </div>`;
  }

  toHtmlInline(): string {
    return this.makeHtml(this.content);
  }
}

export function transformMarkdownToHtml(
  escapedMarkdownContent: string,
): string {
  if (!escapedMarkdownContent) return "";
  return new MarkdownRenderer(
    decodeHTML(escapedMarkdownContent),
  ).toHtmlInline();
}
