<script setup lang="ts">
import MLink from "@mozaic-ds/vue-3/src/components/link/MLink.vue";
import { computed } from "vue";

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  href: {
    type: String,
    required: true,
  },
  openInNewTab: {
    type: Boolean,
    default: false,
  },
  withIcon: {
    type: Boolean,
    default: true,
  },
  iconName: {
    type: String,
    default: "DisplayExternalLink16",
  },
  iconPosition: {
    type: String,
    default: "right",
    validator: (value: string) => ["left", "right"].includes(value),
  },
});

const linkTarget = computed(() => (props.openInNewTab ? "_blank" : "_self"));
</script>

<template>
  <MLink
    class="help-link"
    :href="href"
    :target="linkTarget"
    :icon="iconName"
    :iconPosition="iconPosition"
  >
    <span class="help-link__label">{{ label }}</span>
  </MLink>
</template>

<style lang="scss">
.help-link {
  display: inline-flex;
  align-items: center;
}
</style>
