import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-centered" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_CancelAndSubmitButtons = _resolveComponent("CancelAndSubmitButtons")!
  const _component_ButtonEdit = _resolveComponent("ButtonEdit")!
  const _component_ButtonDelete = _resolveComponent("ButtonDelete")!
  const _component_ActionButtonsWrapper = _resolveComponent("ActionButtonsWrapper")!

  return (_openBlock(), _createElementBlock("tr", {
    class: _normalizeClass(['update-or-remove-custom-query-param', { disabled: $props.isDisabled }])
  }, [
    _createElementVNode("td", null, [
      _createVNode(_component_FormInput, {
        error: $props.form.firstError('name')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_TextInput, {
            modelValue: $props.form.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($props.form.name) = $event)),
            reference: "inputCustomQueryParamName",
            placeholder: $data.contents.namePlaceholder,
            isDisabled: !$data.isEditing
          }, null, 8, ["modelValue", "placeholder", "isDisabled"])
        ]),
        _: 1
      }, 8, ["error"])
    ]),
    _createElementVNode("td", null, [
      _createVNode(_component_FormInput, {
        error: $props.form.firstError('value')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_TextInput, {
            modelValue: $props.form.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($props.form.value) = $event)),
            placeholder: $data.contents.valuePlaceholder,
            isDisabled: !$data.isEditing
          }, null, 8, ["modelValue", "placeholder", "isDisabled"])
        ]),
        _: 1
      }, 8, ["error"])
    ]),
    _createElementVNode("td", _hoisted_1, [
      ($data.isEditing)
        ? (_openBlock(), _createBlock(_component_CancelAndSubmitButtons, {
            key: 0,
            onCancel: $options.cancel,
            onSubmit: $options.update
          }, null, 8, ["onCancel", "onSubmit"]))
        : (_openBlock(), _createBlock(_component_ActionButtonsWrapper, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_ButtonEdit, { onClick: $options.edit }, null, 8, ["onClick"]),
              _createVNode(_component_ButtonDelete, { onClick: $options.remove }, null, 8, ["onClick"])
            ]),
            _: 1
          }))
    ])
  ], 2))
}