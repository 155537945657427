<template>
  <IconButton
    class="copy-icon-button"
    :mozaicIconName="iconName"
    @click="copyToClipboard(content)"
  />
</template>

<script lang="ts">
import IconButton from "@/commons/components/IconButton.vue";

import { writeText } from "@/commons/libs/write-text";

export default {
  components: {
    IconButton,
  },
  props: {
    content: {
      type: String,
      required: true,
    },
    iconName: {
      type: String,
      default: "PublishCopy24",
    },
  },
  methods: {
    copyToClipboard(value: string) {
      writeText(value).then(() => {
        this.$store.commit("postSuccessNotification", {
          title: "Copied to clipboard",
        });
      });
    },
  },
};
</script>
