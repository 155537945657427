import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "black-list-routes__subtitle" }
const _hoisted_2 = {
  key: 0,
  class: "black-list-routes__content"
}
const _hoisted_3 = { class: "black-list-routes__action" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonEdit = _resolveComponent("ButtonEdit")!
  const _component_ButtonDelete = _resolveComponent("ButtonDelete")!
  const _component_ActionButtonsWrapper = _resolveComponent("ActionButtonsWrapper")!
  const _component_MDataTable = _resolveComponent("MDataTable")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_ActionZone = _resolveComponent("ActionZone")!
  const _component_CardContainer = _resolveComponent("CardContainer")!

  return (_openBlock(), _createBlock(_component_CardContainer, { class: "black-list-routes" }, {
    default: _withCtx(() => [
      _createElementVNode("h4", null, _toDisplayString($data.contents.title), 1),
      _createElementVNode("div", _hoisted_1, _toDisplayString($data.contents.subtitle), 1),
      ($options.hasContent)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_MDataTable, {
              headers: $options.headers,
              source: $options.blackListPoliciesList,
              "item-classes": $options.itemRowClass
            }, {
              [`item.httpMethods`]: _withCtx(({ item }) => [
                _createTextVNode(_toDisplayString(item.httpMethods.join(", ")), 1)
              ]),
              [`item.actions`]: _withCtx(({ item }) => [
                _createVNode(_component_ActionButtonsWrapper, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ButtonEdit, {
                      onClick: ($event: any) => ($options.createOrUpdatePolicy(item))
                    }, null, 8, ["onClick"]),
                    _createVNode(_component_ButtonDelete, {
                      onClick: ($event: any) => ($options.deletePolicy(item))
                    }, null, 8, ["onClick"])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["headers", "source", "item-classes"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_ActionZone, null, {
          default: _withCtx(() => [
            _createVNode(_component_MButton, {
              class: "black-list-routes__add-button",
              label: $data.contents.buttonTitle,
              icon: "ControlCircleMore24",
              onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.createOrUpdatePolicy(null)))
            }, null, 8, ["label"])
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}