export default {
  loadingRedirectUris: "Loading redirect URIs...",

  redirectUrisTitle: "Redirect URIs",
  redirectUrisDescription:
    "When you apply the changes, all URIs will be applied to all your Authorization Code Flow OAuth Subscriptions immediately, and impact your application.",
  redirectUriHeaderName: "REDIRECT URI",
  addRedirectUriButton: "Add a new Redirect URI",

  deletionConfirmModalTitle: "Delete a redirect URI",
  deletionConfirmModalMessageTitle:
    "You are going to delete a redirect URI. Are you sure ?",
  deletionConfirmModalMessage:
    "This will apply to all your Authorization Code Flow OAuth Subscriptions.",
  deletionConfirmModalConfirmButtonLabel: "I understand and confirm",

  errorRedirectUriAlreadyInList: "Redirect URI already exists in the list",
};
