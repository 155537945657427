<script setup lang="ts">
import { computed } from "vue";

import IconLocalGroup from "@/commons/components/Icons/IconLocalGroup.vue";
import IconMCCGroup from "@/commons/components/Icons/IconMCCGroup.vue";

import { EGroupProvider } from "@/commons/domain/models/group";

const props = defineProps({
  provider: {
    type: String,
    required: true,
    validator: (value: EGroupProvider) => {
      return Object.values(EGroupProvider).includes(value);
    },
  },
});

const isMCCProvider = computed(() => {
  return props.provider != undefined && props.provider === EGroupProvider.MCC;
});

const isLocalProvider = computed(() => {
  return props.provider != undefined && props.provider === EGroupProvider.LOCAL;
});
</script>

<template>
  <div class="icon-group">
    <IconMCCGroup v-if="isMCCProvider" />
    <IconLocalGroup v-else-if="isLocalProvider" />
  </div>
</template>

<style lang="scss">
.icon-group {
  width: 24px;
  height: 24px;
}
</style>
