import PackageJson from "../package.json";

import { FrontendConfig } from "@/commons/domain/models/frontendConfig.type";

export function startDatadogRecording(conf?: Partial<FrontendConfig>) {
  if (conf?.env == undefined) {
    return;
  }

  import("@datadog/browser-rum").then((pkg) => {
    pkg.datadogRum.init({
      applicationId: "91631720-a036-4128-b05e-c647b87a57c2",
      clientToken: "pub54e4b4936c2f29a23b268bb2b32b3ad4",
      site: "datadoghq.eu",
      service: "developer-portal-ui",
      env: conf.env,
      datacenter: "eu",
      version: PackageJson.version,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      defaultPrivacyLevel:
        window.location.hostname === "localhost" ? "allow" : "mask",
    });

    pkg.datadogRum.startSessionReplayRecording();
  });
}
