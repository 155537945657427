<template>
  <ConfirmModalBody
    class="confirm-internet-unexposure-modal"
    :confirmBtnLabel="contents.confirmBtn"
    @onSubmit="$emit('onSubmit')"
    @onClose="$emit('onClose')"
  >
    <h5>{{ contents.confirmTitle }}</h5>
    <Markdown :content="contents.confirmContent(hrefToApiConsumers)" />
  </ConfirmModalBody>
</template>

<script lang="ts">
import ConfirmModalBody from "@/commons/components/Modal/ConfirmModalBody.vue";
import Markdown from "@/documentation/components/commons/Markdown.vue";

import { convertRouteToHref } from "@/commons/utils/route-utils";

import contents from "@/manager/contents/confirm-internet-unexposure-modal";

export default {
  components: {
    ConfirmModalBody,
    Markdown,
  },
  props: {
    apiId: {
      type: String,
      required: true,
    },
  },
  emits: ["onSubmit", "onClose"],
  data() {
    return {
      contents,
    };
  },
  computed: {
    hrefToApiConsumers(): string {
      return convertRouteToHref({
        name: "managerApiDetailConsumers",
        params: { id: this.apiId },
      });
    },
  },
};
</script>
