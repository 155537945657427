import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MAutocomplete = _resolveComponent("MAutocomplete")!
  const _component_DvpField = _resolveComponent("DvpField")!
  const _component_MCheckboxGroup = _resolveComponent("MCheckboxGroup")!
  const _component_MSelect = _resolveComponent("MSelect")!
  const _component_LayerModalFormConfirm = _resolveComponent("LayerModalFormConfirm")!

  return (_openBlock(), _createBlock(_component_LayerModalFormConfirm, {
    class: "modal-filters-api-manager",
    confirmBtnLabel: $data.contents.confirmButtonLabel,
    confirmBtnTheme: "solid",
    disabledConfirmBtn: $data.form.pristine,
    onSubmit: $options.submit,
    onClose: $options.close
  }, {
    default: _withCtx(() => [
      _createVNode(_component_DvpField, {
        "data-cy": "products-filter",
        class: "modal-filters-api-manager__products",
        label: $data.contents.productsLabel
      }, {
        default: _withCtx(() => [
          _createVNode(_component_MAutocomplete, {
            modelValue: $options.selectedProductIds,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($options.selectedProductIds) = $event)),
            items: $options.products,
            placeholder: $data.contents.searchProduct,
            dataTextExpr: "name",
            dataValueExpr: "id",
            multiple: true,
            filter: false,
            input: $data.defaultInput,
            "onUpdate:input": $options.debouncedSearchProducts
          }, null, 8, ["modelValue", "items", "placeholder", "input", "onUpdate:input"])
        ]),
        _: 1
      }, 8, ["label"]),
      ($options.userIsInternal)
        ? (_openBlock(), _createBlock(_component_DvpField, {
            key: 0,
            "data-cy": "platforms-filter",
            class: "modal-filters-api-manager__platforms",
            label: $data.contents.platformsLabel
          }, {
            default: _withCtx(() => [
              _createVNode(_component_MAutocomplete, {
                modelValue: $data.form.platformsIds,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.form.platformsIds) = $event)),
                items: $options.platforms,
                placeholder: $data.contents.searchPlatforms,
                dataTextExpr: "description",
                dataValueExpr: "id",
                multiple: true,
                input: $data.defaultInput
              }, null, 8, ["modelValue", "items", "placeholder", "input"])
            ]),
            _: 1
          }, 8, ["label"]))
        : _createCommentVNode("", true),
      ($options.userIsInternal)
        ? (_openBlock(), _createBlock(_component_DvpField, {
            key: 1,
            "data-cy": "business-units-filter",
            class: "modal-filters-api-manager__business-units",
            label: $data.contents.buLabel
          }, {
            default: _withCtx(() => [
              _createVNode(_component_MAutocomplete, {
                modelValue: $data.form.businessUnitsIds,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.form.businessUnitsIds) = $event)),
                items: $options.businessUnits,
                placeholder: $data.contents.searchBusinessUnit,
                dataTextExpr: "description",
                dataValueExpr: "id",
                multiple: true,
                input: $data.defaultInput
              }, null, 8, ["modelValue", "items", "placeholder", "input"])
            ]),
            _: 1
          }, 8, ["label"]))
        : _createCommentVNode("", true),
      ($options.userIsAdmin)
        ? (_openBlock(), _createBlock(_component_DvpField, {
            key: 2,
            "data-cy": "publication-status-filter",
            class: "modal-filters-api-manager__publication-status"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_MCheckboxGroup, {
                modelValue: $data.form.publicationStatuses,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($data.form.publicationStatuses) = $event)),
                legend: $data.contents.publicationStatusLabel,
                options: $options.publicationStatuses,
                inline: true
              }, null, 8, ["modelValue", "legend", "options"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      ($options.userIsAdmin)
        ? (_openBlock(), _createBlock(_component_DvpField, {
            key: 3,
            "data-cy": "publication-zone-filter",
            class: "modal-filters-api-manager__publication-zones",
            label: $data.contents.publicationZoneLabel
          }, {
            default: _withCtx(() => [
              _createVNode(_component_MSelect, {
                id: "publication-zone-filter",
                modelValue: $options.selectedPublicationZone,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($options.selectedPublicationZone) = $event)),
                options: $options.publicationZones,
                placeholder: $data.contents.searchPublicationZones
              }, null, 8, ["modelValue", "options", "placeholder"])
            ]),
            _: 1
          }, 8, ["label"]))
        : _createCommentVNode("", true),
      ($options.userIsAdmin)
        ? (_openBlock(), _createBlock(_component_DvpField, {
            key: 4,
            "data-cy": "access-types-filter",
            class: "modal-filters-api-manager__access-types"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_MCheckboxGroup, {
                modelValue: $data.form.accessTypes,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($data.form.accessTypes) = $event)),
                legend: $data.contents.accessTypeLabel,
                options: $options.accessTypes,
                inline: true
              }, null, 8, ["modelValue", "legend", "options"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      ($options.userIsAdmin)
        ? (_openBlock(), _createBlock(_component_DvpField, {
            key: 5,
            "data-cy": "privacies-filter",
            class: "modal-filters-api-manager__privacy"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_MCheckboxGroup, {
                modelValue: $data.form.privacies,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($data.form.privacies) = $event)),
                legend: $data.contents.privacyLabel,
                options: $options.privacies,
                inline: true
              }, null, 8, ["modelValue", "legend", "options"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["confirmBtnLabel", "disabledConfirmBtn", "onSubmit", "onClose"]))
}