import { EntryPointsMapper } from "./entry-points-mapper";
import { getDatacenterFromZoneName } from "./zones-mapper";

import { Dictionary } from "@/commons/domain/models/dictionary";
import { ZoneWithRoutes } from "@/commons/domain/models/zone-with-routes";
import { ZoneWithRoutesDto } from "@/commons/dtos/zone-with-routes-dto";
import { TagsMapper } from "@/commons/mappers/tags-mapper";

export class ZonesWithRoutesMapper {
  static toDomain(zonesWithRoutesDto: ZoneWithRoutesDto[] = []) {
    const zonesWithRoutes: Dictionary<ZoneWithRoutes> = {};

    for (const zoneWithRoutesDto of zonesWithRoutesDto) {
      zonesWithRoutes[zoneWithRoutesDto.id] = {
        id: zoneWithRoutesDto.id,
        connectorType: zoneWithRoutesDto.connectorType,
        connectorEndpoint: zoneWithRoutesDto.connectorEndpoint,
        isRemoved: zoneWithRoutesDto.isRemoved,
        updatedAt: zoneWithRoutesDto.updatedAt,
        name: zoneWithRoutesDto.name,
        description: zoneWithRoutesDto.description,
        isInternal: zoneWithRoutesDto.isInternal,
        routes: zoneWithRoutesDto.routes.map((route) => route.id),
        isMonitored: zoneWithRoutesDto.monitored,
        monitoringUrl: zoneWithRoutesDto.monitoringUrl || "",
        features: zoneWithRoutesDto.features,
        datacenter: getDatacenterFromZoneName(zoneWithRoutesDto.name),
        isDeprecated: zoneWithRoutesDto.deprecated,
        isPublished: zoneWithRoutesDto.isPublished,
        tags: zoneWithRoutesDto.tags.map(TagsMapper.toTagDomain),
        entryPoints: EntryPointsMapper.toDomain(zoneWithRoutesDto),
      };
    }

    return zonesWithRoutes;
  }
}
