import {
  DateHistograms,
  Hit,
  State,
} from "@/commons/store/modules/reporting/types";

export function fetchReporting(state: State) {
  state.hits = [];
  state.graph = null;
  state.errorMessage = null;
  state.isFetchingReporting = true;
}

export function fetchReportingSuccess(
  state: State,
  {
    dateHistograms,
    interval,
    from,
    to,
    hits,
  }: {
    dateHistograms: DateHistograms;
    interval: string;
    from: Date;
    to: Date;
    hits: Hit[];
  },
) {
  state.graph = {
    dateHistograms,
    interval,
    from,
    to,
    hiddenDatasets: [],
  };
  state.hits = hits;
  state.isFetchingReporting = false;
}

export function fetchReportingFail(state: State, error: Error) {
  if (error && error.message) {
    state.errorMessage = error.message;
  }
  state.isFetchingReporting = false;
}

export default {
  fetchReporting,
  fetchReportingSuccess,
  fetchReportingFail,
};
