export default {
  modalTitle: "Redirect URI edition",
  titleForUpdate: "Update a redirect URI",
  titleForCreation: "Add a new redirect URI",
  redirectUriExplanation:
    "**The Authorization Code Flow requires to know to which application URL you want your application to be called to continue the authentication process.**\n\n" +
    "Indeed, your URL's endpoint will be in charge to retrieve the final access token for your authenticated user.\n\n" +
    "**This URL is common to all your application's subscriptions.**",
  redirectUriField: "Your redirect URI",
  redirectUriPlaceholder: "https://yourApp/callback",
};
