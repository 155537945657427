import {
  ValidationError as ClassValidatorError,
  validate,
} from "class-validator";

import { Inject } from "@/commons/domain/di/inject";
import { Injectable } from "@/commons/domain/di/injectable";
import { FormData } from "@/commons/domain/forms/form-data";
import { ValidationErrorMapper } from "@/commons/domain/validation/validation-error-mapper";
import { Validator } from "@/commons/domain/validation/validator";

import { TYPES } from "@/types";

@Injectable()
export class ClassValidator implements Validator {
  constructor(
    @Inject(TYPES.VALIDATION_ERROR_MAPPER)
    private readonly validationErrorMapper: ValidationErrorMapper,
  ) {}

  async validate(model: FormData) {
    const classValidationErrors: ClassValidatorError[] = await validate(model);

    return classValidationErrors.map(
      this.validationErrorMapper.toValidationError,
    );
  }
}
