import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "custom-notification" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "custom-notification__content" }
const _hoisted_4 = { class: "custom-notification__title" }
const _hoisted_5 = { class: "custom-notification__description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MIcon = _resolveComponent("MIcon")!
  const _component_ViewHelpButton = _resolveComponent("ViewHelpButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: $data.pandaNotificationImg,
      class: "custom-notification__panda"
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        ($props.iconName)
          ? (_openBlock(), _createBlock(_component_MIcon, {
              key: 0,
              name: $props.iconName
            }, null, 8, ["name"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, _toDisplayString($props.title), 1)
      ]),
      _createElementVNode("div", _hoisted_5, _toDisplayString($props.description), 1),
      ($props.documentationAnchor)
        ? (_openBlock(), _createBlock(_component_ViewHelpButton, {
            key: 0,
            documentationAnchor: $props.documentationAnchor
          }, null, 8, ["documentationAnchor"]))
        : _createCommentVNode("", true)
    ])
  ]))
}