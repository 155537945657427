import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "multi-acf-providers-prevention-step-1-modal__content" }
const _hoisted_2 = { class: "multi-acf-providers-prevention-step-1-modal__info-about-unsubscriptions" }
const _hoisted_3 = { class: "multi-acf-providers-prevention-step-1-modal__api-list-section" }
const _hoisted_4 = { class: "multi-acf-providers-prevention-step-1-modal__api-list-introduction" }
const _hoisted_5 = {
  key: 2,
  class: "multi-acf-providers-prevention-step-1-modal__api-list"
}
const _hoisted_6 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DvpStepper = _resolveComponent("DvpStepper")!
  const _component_Markdown = _resolveComponent("Markdown")!
  const _component_MoreAboutLink = _resolveComponent("MoreAboutLink")!
  const _component_MNotification = _resolveComponent("MNotification")!
  const _component_MLoader = _resolveComponent("MLoader")!
  const _component_LayerModalFormConfirm = _resolveComponent("LayerModalFormConfirm")!

  return (_openBlock(), _createBlock(_component_LayerModalFormConfirm, {
    class: "multi-acf-providers-prevention-step-1-modal",
    confirmBtnTheme: "solid",
    confirmBtnLabel: $data.contents.confirmButtonStep1,
    disabledConfirmBtn: 
      $options.isLoadingAcfContractsOfApplication || $options.loadingOfAcfContractsHasFailed
    ,
    onSubmit: $options.submit,
    onClose: $options.close
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_DvpStepper, {
          class: "multi-acf-providers-prevention-step-1-modal__stepper",
          steps: $options.stepperSteps,
          stepperDescription: $data.contents.stepperDescription
        }, null, 8, ["steps", "stepperDescription"]),
        _createVNode(_component_MNotification, { type: "warning" }, {
          default: _withCtx(() => [
            _createVNode(_component_Markdown, {
              content: 
            $data.contents.multiProvidersNotification(
              $props.apiACFProvider,
              $props.applicationACFProvider,
            )
          
            }, null, 8, ["content"]),
            _createVNode(_component_MoreAboutLink, {
              class: "multi-acf-providers-prevention-step-1-modal__warning-more-about",
              "documentation-anchor": "#consume-two-authorization-code-flow-apis"
            })
          ]),
          _: 1
        }),
        _createElementVNode("p", _hoisted_2, _toDisplayString($data.contents.infoAboutUnsubscriptions), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("p", _hoisted_4, _toDisplayString($data.contents.listOfImpactedApis), 1),
          ($options.isLoadingAcfContractsOfApplication)
            ? (_openBlock(), _createBlock(_component_MLoader, {
                key: 0,
                class: "multi-acf-providers-prevention-step-1-modal__api-list-loader",
                size: "m"
              }))
            : ($options.loadingOfAcfContractsHasFailed)
              ? (_openBlock(), _createBlock(_component_MNotification, {
                  key: 1,
                  type: "danger"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Markdown, {
                      content: $data.contents.errorOnImpactedApisLoading
                    }, null, 8, ["content"])
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createElementBlock("ul", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.apisWithACFSubscription, (api) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: api.id,
                      class: "multi-acf-providers-prevention-step-1-modal__api-list-item"
                    }, _toDisplayString(api.label), 1))
                  }), 128)),
                  (
              $data.paginatedAcfContractsOfApplication.totalCount >
              $data.paginatedAcfContractsOfApplication.count
            )
                    ? (_openBlock(), _createElementBlock("li", _hoisted_6, _toDisplayString($data.contents.andMore), 1))
                    : _createCommentVNode("", true)
                ]))
        ]),
        _createVNode(_component_Markdown, {
          class: "multi-acf-providers-prevention-step-1-modal__alternative-solution-section",
          content: $data.contents.alternativeSolution
        }, null, 8, ["content"])
      ])
    ]),
    _: 1
  }, 8, ["confirmBtnLabel", "disabledConfirmBtn", "onSubmit", "onClose"]))
}