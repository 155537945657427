<template>
  <MButton
    data-cy="view-help-button"
    class="view-help-button"
    theme="bordered-neutral"
    :size="size"
    icon="Read24"
    :label="contents.viewHelp"
    :href="routeHref"
    target="_blank"
  />
</template>

<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";

import { convertRouteToHref } from "@/commons/utils/route-utils";

import contents from "@/commons/contents/documentation";

export default {
  components: {
    MButton,
  },
  props: {
    documentationAnchor: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "s",
    },
  },
  data() {
    return {
      contents,
    };
  },
  computed: {
    routeHref() {
      return convertRouteToHref({
        name: "docsMain",
        hash: this.documentationAnchor,
      });
    },
  },
};
</script>
