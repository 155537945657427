import { Dictionary } from "@/commons/domain/models/dictionary";
import { Zone } from "@/commons/domain/models/zone";
import { PagedResourceDto } from "@/commons/dtos/paged-resource-dto";
import { ZoneDTO } from "@/commons/dtos/zone-dto";
import { RoutesMapper } from "@/commons/mappers/routes-mapper";
import { TagsMapper } from "@/commons/mappers/tags-mapper";

export function getDatacenterFromZoneName(zoneName: string) {
  let datacenter = "";

  if (zoneName.includes("ADEO")) {
    datacenter = "ADEO";
  } else if (zoneName.includes("GCP")) {
    datacenter = "Google Cloud Platform";
  }

  return datacenter;
}

export class ZonesMapper {
  static toDomain(zonesDtos: PagedResourceDto<ZoneDTO>): Dictionary<Zone> {
    return ZonesMapper.toDomainCollection(zonesDtos.data);
  }

  static toDomainCollection(zonesDtos: ZoneDTO[]): Dictionary<Zone> {
    return zonesDtos.reduce((zoneList, zoneDto) => {
      zoneList[zoneDto.id] = ZonesMapper.toZoneDomain(zoneDto);
      return zoneList;
    }, {});
  }

  static toZoneDomain(zoneDTO: ZoneDTO): Zone {
    return {
      id: zoneDTO.id,
      name: zoneDTO.name,
      description: zoneDTO.description,
      tags: zoneDTO.tags.map((tag) => TagsMapper.toTagDomain(tag)),
      routes: zoneDTO.routes && RoutesMapper.toDomain(zoneDTO.routes),
      deprecated: zoneDTO.deprecated,
    };
  }
}
