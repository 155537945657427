import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "publication-zone-access-urls" }
const _hoisted_2 = { class: "publication-zone-access-urls__title" }
const _hoisted_3 = { class: "publication-zone-access-urls__private-routes" }
const _hoisted_4 = { class: "publication-zone-access-urls__route-title" }
const _hoisted_5 = {
  key: 1,
  class: "publication-zone-access-urls__public-routes"
}
const _hoisted_6 = { class: "publication-zone-access-urls__route-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TitleWithIconTooltip = _resolveComponent("TitleWithIconTooltip")!
  const _component_MNotification = _resolveComponent("MNotification")!
  const _component_EditableRouteUrl = _resolveComponent("EditableRouteUrl")!
  const _component_DvpToggle = _resolveComponent("DvpToggle")!
  const _component_ToggleField = _resolveComponent("ToggleField")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TitleWithIconTooltip, {
      tooltipContent: $data.contents.accessUrlsMoreInfo
    }, {
      default: _withCtx(() => [
        _createElementVNode("h5", _hoisted_2, _toDisplayString($data.contents.accessUrls), 1)
      ]),
      _: 1
    }, 8, ["tooltipContent"]),
    ($data.showIncompatibleAccessRestrictionWarning)
      ? (_openBlock(), _createBlock(_component_MNotification, {
          key: 0,
          class: "publication-zone-access-urls__access-restriction-warning",
          type: "warning",
          title: $data.contents.accessRestrictionWarningTitle,
          closable: "",
          link: $options.accessRestrictionHref,
          linkContent: $data.contents.accessRestrictionLinkLabel,
          onClose: _cache[0] || (_cache[0] = ($event: any) => ($data.showIncompatibleAccessRestrictionWarning = false))
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", null, _toDisplayString($data.contents.accessRestrictionWarningText), 1)
          ]),
          _: 1
        }, 8, ["title", "link", "linkContent"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("h6", _hoisted_4, _toDisplayString($data.contents.privateUrlTitle), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.privateRoutes, (privateRoute) => {
        return (_openBlock(), _createBlock(_component_EditableRouteUrl, {
          key: `publication-zone-access-urls__route-${privateRoute.id}`,
          route: privateRoute,
          canEdit: $options.userIsAdmin,
          apiId: $props.api.id
        }, null, 8, ["route", "canEdit", "apiId"]))
      }), 128))
    ]),
    _createVNode(_component_ToggleField, { class: "publication-zone-access-urls__internet-exposure-section" }, {
      label: _withCtx(() => [
        _createVNode(_component_TitleWithIconTooltip, {
          tooltipContent: $data.contents.internetExposureExplanation
        }, {
          default: _withCtx(() => [
            _createElementVNode("label", null, _toDisplayString($data.contents.internetExposureLabel), 1)
          ]),
          _: 1
        }, 8, ["tooltipContent"])
      ]),
      toggleButton: _withCtx(() => [
        _createVNode(_component_DvpToggle, {
          checked: $options.isExposedOnInternet,
          disabled: $props.isDisabled,
          onClick: _cache[1] || (_cache[1] = ($event: any) => ($options.toggleInternetExposure($props.publicRoutes[0], !$options.isExposedOnInternet)))
        }, null, 8, ["checked", "disabled"])
      ]),
      _: 1
    }),
    ($options.isExposedOnInternet)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("h6", _hoisted_6, _toDisplayString($data.contents.publicUrlTitle), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.publicPublishedRoutes, (publicRoute) => {
            return (_openBlock(), _createBlock(_component_EditableRouteUrl, {
              key: `publication-zone-access-urls__route-${publicRoute.id}`,
              route: publicRoute,
              canEdit: $options.userIsAdmin,
              apiId: $props.api.id
            }, null, 8, ["route", "canEdit", "apiId"]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    ($options.fullFeatureIpFilteringIsEnabled && $options.isExposedOnInternet)
      ? (_openBlock(), _createBlock(_component_ToggleField, {
          key: 2,
          class: "publication-zone-access-urls__ip-filtering-section"
        }, {
          label: _withCtx(() => [
            _createVNode(_component_TitleWithIconTooltip, {
              tooltipContent: $data.contents.ipFilteringExplanation
            }, {
              default: _withCtx(() => [
                _createElementVNode("label", null, _toDisplayString($data.contents.ipFilteringLabel), 1)
              ]),
              _: 1
            }, 8, ["tooltipContent"])
          ]),
          toggleButton: _withCtx(() => [
            _createVNode(_component_DvpToggle, {
              checked: $options.isIpFilteringEnabled,
              disabled: $props.isDisabled,
              onClick: _cache[2] || (_cache[2] = ($event: any) => ($options.toggleIpFiltering($props.publicRoutes[0], !$options.isIpFilteringEnabled)))
            }, null, 8, ["checked", "disabled"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}