<script setup lang="ts">
import isEqual from "lodash-es/isEqual";
import { computed, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import OAuthContracts from "@/dashboard/views/AppDetailsContracts/OAuthContracts.vue";

import { Contract } from "@/commons/domain/models/contract";
import { ContractFilters } from "@/dashboard/domain/contract-filters";

import { EApplicationTab } from "@/commons/store/types";

defineProps({
  currentTab: {
    type: String,
    default: "",
  },
});

const emit = defineEmits([
  "selectMe",
  "displayMe",
  "hideMe",
  "newClientIdCreated",
]);

const store = useStore();
const route = useRoute();

const application = computed(() => store.getters["currentApplication"]);

const applicationId = computed(() => {
  return route.params?.id;
});

const oauthCCFContracts = computed(
  (): Contract[] => store.getters["currentApplicationOAuthCCFContracts"],
);

const oauthCCFContractsCount = computed((): number => {
  return store.getters["oauthCCFContractsTotalCount"];
});

const isLoadingApplicationOAuthCCFContracts = computed(() =>
  store.getters["isLoadingProperty"]("oauthCCFApplicationContracts"),
);

const oauthCCFCreatedContract = computed(
  (): Contract => store.getters["lastCreatedOAuthCCFContract"],
);

let lastCreatedOAuthCCFContract = undefined as Contract | undefined;

watch(oauthCCFCreatedContract, () => {
  if (oauthCCFCreatedContract.value != undefined) {
    lastCreatedOAuthCCFContract = oauthCCFCreatedContract.value;
  }
});

watch(isLoadingApplicationOAuthCCFContracts, () => {
  if (!isLoadingApplicationOAuthCCFContracts.value) {
    if (oauthCCFContractsCount.value > 0) {
      emit("displayMe", oauthCCFContractsCount.value);
    } else {
      emit("hideMe");
    }
    if (oauthCCFCreatedContract.value != undefined) {
      emit("selectMe");
      store.dispatch("resetLastOAuthCCFContract");
    }
  }
});

const contractsMetrics = computed(() => {
  return application?.value?.metrics?.contracts;
});

const loadApplicationOAuthCCFContracts = async () => {
  if (contractsMetrics?.value?.oauthCCF > 0) {
    await store.dispatch("loadApplicationOAuthCCFContracts", {
      ...contractFilters.value,
      applicationId: applicationId.value,
    });
  }
};

const contractFilters = computed((): ContractFilters => {
  const query = route.query;
  return {
    deprecated: query.deprecated === "true",
    disabled: query.disabled === "true",
    aboutToExpire: query.aboutToExpire === "true",
    expired: query.expired === "true",
    apiName: (query.term as string) || "",
  };
});

watch(contractFilters, async (newValue, prevValue) => {
  if (isEqual(newValue, prevValue) === false) {
    await loadApplicationOAuthCCFContracts();
  }
});

onMounted(async () => {
  await loadApplicationOAuthCCFContracts();
});

const loadCurrentApplication = async () => {
  await store.dispatch("loadCurrentApplication", {
    appId: applicationId.value,
  });
};

const onContractChanged = async () => {
  await loadApplicationOAuthCCFContracts();
  await loadCurrentApplication();
};

/**
 * Detect and handle new client ID creation
 */

const distinctClientIds = ref([] as string[]);

const onClientIdsChanged = (newClientIds: string[]) => {
  if (
    lastCreatedOAuthCCFContract != undefined &&
    newClientIds.length > distinctClientIds.value.length
  ) {
    emit("newClientIdCreated");
    lastCreatedOAuthCCFContract = undefined;
  }
  distinctClientIds.value = newClientIds;
};
</script>

<template>
  <div class="oauth-ccf-contracts">
    <template v-if="currentTab === EApplicationTab.OAUTH_CCF">
      <OAuthContracts
        :contracts="oauthCCFContracts"
        :isLoading="isLoadingApplicationOAuthCCFContracts"
        @contractChanged="onContractChanged"
        @clientIdsChanged="onClientIdsChanged"
      />
    </template>
  </div>
</template>
