<template>
  <div class="checkboxes-list-filter">
    <MCheckboxGroup
      :legend="label"
      :disabled="isLoading"
      :options="items"
      :modelValue="queryParamValue"
      :inline="inline"
      @update:modelValue="onChange"
    />
  </div>
</template>

<script lang="ts">
import MCheckboxGroup from "@mozaic-ds/vue-3/src/components/checkbox/MCheckboxGroup.vue";
import { PropType } from "vue";

import { changeUrlQuery } from "@/commons/utils/route-utils";

import { MCheckboxGroupOption } from "@/commons/domain/models/mozaic";

export default {
  name: "CheckboxesListFilter",
  components: {
    MCheckboxGroup,
  },
  props: {
    label: {
      type: String,
      default: undefined,
    },
    queryParamName: {
      type: String,
      required: true,
    },
    queryParamValue: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array as PropType<MCheckboxGroupOption[]>,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onChange(values) {
      changeUrlQuery({
        [this.queryParamName]: values,
        page: 1,
      });
    },
  },
};
</script>
