<script setup lang="ts">
import MCheckbox from "@mozaic-ds/vue-3/src/components/checkbox/MCheckbox.vue";
import { computed } from "vue";
import { useRoute } from "vue-router";

import Divider from "@/commons/components/Divider.vue";

import { changeUrlQuery } from "@/commons/utils/route-utils";

import { ContractFilters } from "@/dashboard/domain/contract-filters";

import contents from "@/dashboard/contents/contracts-filters";

defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
});

const route = useRoute();

const contractFilters = computed((): ContractFilters => {
  const query = route.query;
  return {
    deprecated: query.deprecated === "true",
    disabled: query.disabled === "true",
    aboutToExpire: query.aboutToExpire === "true",
    expired: query.expired === "true",
  };
});

const updateContractFilters = (newFilterParams: ContractFilters) => {
  const filterQueryParams = {};
  for (const filterName in newFilterParams) {
    const filterValue = newFilterParams[filterName];
    // Any "" value is removed when updating the URL query params
    filterQueryParams[filterName] =
      filterValue === false ? "" : String(filterValue);
  }

  changeUrlQuery(filterQueryParams);
};
</script>

<template>
  <div class="contracts-filters">
    <MCheckbox
      id="contract-filter-expired"
      data-cy="contract-filter-expired"
      :modelValue="contractFilters.expired"
      :label="contents.expired"
      :disabled="disabled"
      @update:modelValue="updateContractFilters({ expired: $event })"
    />

    <MCheckbox
      id="contract-filter-about-to-expire"
      data-cy="contract-filter-about-to-expire"
      :modelValue="contractFilters.aboutToExpire"
      :label="contents.aboutToExpire"
      :disabled="disabled"
      @update:modelValue="updateContractFilters({ aboutToExpire: $event })"
    />

    <MCheckbox
      id="contract-filter-deactivated"
      data-cy="contract-filter-deactivated"
      :modelValue="contractFilters.disabled"
      :label="contents.disabled"
      :disabled="disabled"
      @update:modelValue="updateContractFilters({ disabled: $event })"
    />

    <Divider vertical />

    <MCheckbox
      id="contract-filter-deprecated"
      data-cy="contract-filter-deprecated"
      :modelValue="contractFilters.deprecated"
      :label="contents.deprecated"
      :disabled="disabled"
      @update:modelValue="updateContractFilters({ deprecated: $event })"
    />
  </div>
</template>
