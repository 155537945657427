<template>
  <div class="sensitive-modifications-list">
    <div class="sensitive-modifications-list__description">
      <h5 class="sensitive-modifications-list__header">{{ title }}</h5>
      <p class="sensitive-modifications-list__text">{{ description }}</p>
    </div>
    <MButton
      data-cy="sensitive-modification-btn"
      theme="bordered-danger"
      :label="btnTitle"
      :disabled="isDisabled"
      @click="btnAction"
    />
  </div>
</template>

<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";

export default {
  name: "SensitiveModificationsList",
  components: {
    MButton,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    btnTitle: {
      type: String,
      default: "",
    },
    btnAction: {
      type: Function,
      default: () => {},
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.sensitive-modifications-list {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: var(--border-default);
}

.sensitive-modifications-list:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}

.sensitive-modifications-list__action {
  min-width: 155px;
  max-width: 155px;
}

.sensitive-modifications-list__header {
  margin: 0;
  line-height: 1.5;
}

.sensitive-modifications-list__text {
  margin: 0;
  font-size: 12px;
  font-style: italic;
  line-height: 1.5;
  color: var(--color-grey);
}

.sensitive-modifications-list__description {
  width: 65%;
  margin-right: 1rem;
}
</style>
