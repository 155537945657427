import { Dictionary } from "@/commons/domain/models/dictionary";
import { PagedResource } from "@/commons/domain/models/paged-resource";
import { RequestSummary } from "@/commons/domain/models/request-summary";

import { State } from "./types";

function currentApiPendingAccessPolicyRequests(state: State) {
  return state.currentApiPendingAccessPolicyRequests.data;
}

function currentApiPendingOtherGatewayRequests(state: State) {
  return state.currentApiPendingOtherGatewayRequests.data;
}

function currentApiPendingTimeoutRequest(state: State) {
  return state.currentApiPendingTimeoutRequests?.data
    ? Object.values(state.currentApiPendingTimeoutRequests.data)[0]
    : undefined;
}

function apiHasPendingOtherGatewaysRequest(state: State, getters) {
  return !!Object.keys(getters.currentApiPendingOtherGatewayRequests).length;
}

function apiHasPendingTimeoutRequest(state: State, getters) {
  return getters.currentApiPendingTimeoutRequest !== undefined;
}

function requestSummariesPage(state: State) {
  return state.requestSummariesPage;
}

function preselectedRequest(state: State) {
  return state.preselectedRequest;
}
function pendingRequestSummariesPage(state: State) {
  return state.pendingRequestSummariesPage;
}

function requestSummaries(state: State, getters) {
  const requestSummariesPage: PagedResource<RequestSummary> =
    getters.requestSummariesPage;
  return (requestSummariesPage && requestSummariesPage.data) || {};
}

function requestSummariesList(state: State, getters) {
  const requestSummaries: Dictionary<RequestSummary> = getters.requestSummaries;
  return Object.values(requestSummaries);
}

function currentRequestsMaxCount(state: State, getters) {
  const requestSummariesPage: PagedResource<RequestSummary> =
    getters.requestSummariesPage;
  return (requestSummariesPage && requestSummariesPage.totalPages) || 0;
}

function currentRequest(state: State) {
  return state.currentRequest;
}

function requestAllowedActions(state: State, getters) {
  const currentRequest: RequestSummary = getters.currentRequest;
  return (currentRequest && currentRequest.allowedActions) || {};
}

function totalCountOfPendingRequests(state: State, getters) {
  const pendingRequestSummariesPage: PagedResource<RequestSummary> =
    getters.pendingRequestSummariesPage;

  return pendingRequestSummariesPage && pendingRequestSummariesPage.totalCount;
}

export default {
  requestSummariesPage,
  pendingRequestSummariesPage,
  currentRequest,
  requestSummaries,
  requestSummariesList,
  preselectedRequest,
  requestAllowedActions,
  currentRequestsMaxCount,
  totalCountOfPendingRequests,
  currentApiPendingAccessPolicyRequests,
  currentApiPendingOtherGatewayRequests,
  currentApiPendingTimeoutRequest,
  apiHasPendingOtherGatewaysRequest,
  apiHasPendingTimeoutRequest,
};
