<script setup lang="ts">
import MSelect from "@mozaic-ds/vue-3/src/components/select/MSelect.vue";
import { computed, PropType } from "vue";

import { valuesUpperCaseToMSelectOptions } from "@/commons/utils/mozaic-utils";
import { ROLES_APPLICABLE_TO_GROUP } from "@/commons/utils/role.utils";

import { Group } from "@/commons/domain/models/group";

defineProps({
  group: {
    type: Object as PropType<Group>,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

defineEmits(["updateGroupRole"]);

const applicableRoles = computed(() => {
  return valuesUpperCaseToMSelectOptions(ROLES_APPLICABLE_TO_GROUP);
});
</script>

<template>
  <MSelect
    class="group-role-select"
    size="s"
    :modelValue="group.role.id"
    :options="applicableRoles"
    :disabled="disabled"
    @update:modelValue="$emit('updateGroupRole', $event)"
  />
</template>
