import { v4 as uuid } from "uuid";

import { CustomProxyHeader } from "@/commons/domain/models/custom-proxy-header";
import { Dictionary } from "@/commons/domain/models/dictionary";
import { CustomProxyHeaderDto } from "@/commons/dtos/custom-proxy-header-dto";

export class CustomProxyHeadersMapper {
  static toDomain(customProxyHeaderDtos: CustomProxyHeaderDto[] = []) {
    const customProxyHeaders: Dictionary<CustomProxyHeader> = {};

    for (const customProxyHeaderDto of customProxyHeaderDtos) {
      const customProxyHeaderId = uuid();

      customProxyHeaders[customProxyHeaderId] = {
        id: customProxyHeaderId,
        name: customProxyHeaderDto.name,
        value: customProxyHeaderDto.value,
        isOverridingClientHeader: customProxyHeaderDto.isOverridingClientHeader,
        order: customProxyHeaderDto.order,
        type: customProxyHeaderDto.type,
      };
    }

    return customProxyHeaders;
  }

  static toDto(customProxyHeaders: Dictionary<CustomProxyHeader>) {
    const customProxyHeaderDtos: CustomProxyHeaderDto[] = [];

    for (const customProxyHeader of Object.values(customProxyHeaders)) {
      customProxyHeaderDtos.push({
        name: customProxyHeader.name,
        value: customProxyHeader.value,
        isOverridingClientHeader: customProxyHeader.isOverridingClientHeader,
        order: customProxyHeader.order,
        type: customProxyHeader.type,
      });
    }

    return customProxyHeaderDtos;
  }
}
