import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "quota-type-option__title" }
const _hoisted_3 = { class: "quota-type-option__description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["quota-type-option", { 'quota-type-option--active': $props.isActive }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
  }, [
    _createElementVNode("img", {
      src: $props.icon,
      class: "quota-type-option__icon"
    }, null, 8, _hoisted_1),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, _toDisplayString($props.title), 1),
      _createElementVNode("div", _hoisted_3, _toDisplayString($props.description), 1)
    ])
  ], 2))
}