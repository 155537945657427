import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-562d5b24"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "footer-menus" }
const _hoisted_2 = { class: "footer-menus__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FlatMenu = _resolveComponent("FlatMenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.navigationMenus, (menu, index) => {
        return (_openBlock(), _createBlock(_component_FlatMenu, _mergeProps({ ref_for: true }, menu, {
          key: `footer-menus-menu-${index}`,
          isDisplayImage: false,
          isDisplayDescription: false
        }), null, 16))
      }), 128))
    ])
  ]))
}