import { HttpRepository } from "@/commons/repositories/libs/http-repository";

import { Injectable } from "@/commons/domain/di/injectable";
import { GatewayRepository } from "@/commons/domain/repositories/gateway-repository";

@Injectable()
export class GatewayHttpRepository
  extends HttpRepository
  implements GatewayRepository
{
  async triggerGatewaySynchronization(tenant: string) {
    await this.requestHandler.get(`/kong/sync`, { params: { tenant } });
  }
}
