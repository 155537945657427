<script setup lang="ts">
import MNotification from "@mozaic-ds/vue-3/src/components/notification/MNotification.vue";
import MTextInput from "@mozaic-ds/vue-3/src/components/textinput/MTextInput.vue";
import { computed, onMounted, PropType, ref } from "vue";
import { useStore } from "vuex";

import DvpField from "@/commons/components/form/DvpField.vue";
import LayerModalFormConfirm from "@/commons/components/form/LayerModalFormConfirm.vue";
import Markdown from "@/documentation/components/commons/Markdown.vue";

import { ConfirmActionForm } from "@/commons/domain/forms/confirm-action-form";
import { Application } from "@/commons/domain/models/application";

import contents from "@/dashboard/contents/deactivate-secret-synchronization-modal";

const props = defineProps({
  application: {
    type: Object as PropType<Application>,
    required: true,
  },
  secretManager: {
    type: String,
    required: true,
  },
  secretPath: {
    type: String,
    required: true,
  },
  secretUrl: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["submit", "onClose"]);

const store = useStore();

const currentEnvironment = computed(
  () => store.getters["config/currentEnvironment"],
);
const currentEnvName = computed(() =>
  currentEnvironment.value ? currentEnvironment.value.toUpperCase() : "",
);

const appName = computed(() => props.application?.name);

const form = ref(ConfirmActionForm.create(appName.value));

onMounted(() => {
  form.value.init();
});
</script>

<template>
  <LayerModalFormConfirm
    class="deactivate-secret-synchronization-confirmation"
    :disabledConfirmBtn="form.errors.length > 0"
    @submit="emit('submit')"
    @close="emit('onClose')"
  >
    <div class="deactivate-secret-synchronization-confirmation__header">
      <h4>{{ contents.title(secretManager) }}</h4>
      <span class="deactivate-secret-synchronization-confirmation__subtitle">
        {{ contents.subtitle }}</span
      >
    </div>

    <MNotification type="warning" :title="contents.notificationTitle">
      <p>
        {{ contents.notificationDescription(secretManager, currentEnvName) }}
      </p>
      <p>
        <a :href="secretUrl" target="_blank">{{ secretPath }}</a>
      </p>
    </MNotification>

    <Markdown :content="contents.confirmationTitle(appName)" />

    <DvpField
      :label="contents.confirmationLabel"
      :errorMessage="form.firstError('text')"
      required
    >
      <MTextInput
        v-model="form.text"
        :placeholder="appName"
        :isInvalid="form.hasError('text')"
      />
    </DvpField>
  </LayerModalFormConfirm>
</template>

<style lang="scss">
.deactivate-secret-synchronization-confirmation {
  display: flex;
  flex-direction: column;

  gap: var(--base-spacing);
}

.deactivate-secret-synchronization-confirmation__header {
  display: flex;
  flex-direction: column;
}

.deactivate-secret-synchronization-confirmation__subtitle {
  color: var(--color-status-danger);
}
</style>
