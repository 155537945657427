<script setup lang="ts">
import MTextArea from "@mozaic-ds/vue-3/src/components/textarea/MTextArea.vue";
import MTextInput from "@mozaic-ds/vue-3/src/components/textinput/MTextInput.vue";
import { onMounted } from "vue";
import { useStore } from "vuex";

import DvpField from "@/commons/components/form/DvpField.vue";
import LayerModalFormConfirm from "@/commons/components/form/LayerModalFormConfirm.vue";

import { getApiRepository } from "@/commons/repositories/libs/get-api-repository";

import { EmailForm } from "@/commons/forms/email-form";

import contents from "@/manager/contents/send-email-layer-modal";

const props = defineProps({
  apiId: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["onClose"]);

const store = useStore();

const form = EmailForm.create();

onMounted(async () => {
  await form.init();
});

const submit = async () => {
  await form.validate();
  if (!form.errors.length) {
    const { subject, body } = form.data();
    await getApiRepository().notifyUsers(props.apiId, subject, body);

    store.commit("postSuccessNotification", {
      title: contents.sendEmailSuccessNotification,
    });
    emit("onClose");
  }
};
</script>

<template>
  <LayerModalFormConfirm
    :disabledConfirmBtn="!!form.errors.length"
    @submit="submit"
    @close="emit('onClose')"
  >
    <h4>{{ contents.title }}</h4>

    <DvpField required :error-message="form.firstError('subject')">
      <MTextInput
        v-model="form.subject"
        :placeholder="contents.emailSubjectPlaceholder"
        :isInvalid="form.firstError('text') != undefined"
      />
    </DvpField>

    <DvpField required :errorMessage="form.firstError('body')">
      <MTextArea
        v-model="form.body"
        :placeholder="contents.emailBodyPlaceholder"
        :isInvalid="form.firstError('body') != undefined"
        :rows="10"
      />
    </DvpField>
  </LayerModalFormConfirm>
</template>
