import store from "@/commons/store";

export async function flagLoadingProcess<T>(
  flagName: string,
  processFunction: () => Promise<T>,
  minimumFlagDurationInMilliseconds: number = 1000, // Used for preventing loaders to disappear almost instantly
): Promise<T> {
  const startTime = new Date().getTime();
  store.commit("initFetchLoader", flagName);

  try {
    return await processFunction();
  } finally {
    setTimeout(
      () => {
        store.commit("finishFetchLoader", flagName);
      },
      getTimeRemainingBeforeFlagRelease(
        startTime,
        new Date().getTime(),
        minimumFlagDurationInMilliseconds,
      ),
    );
  }
}

export async function flagSavingProcess<T>(
  flagName: string,
  processFunction: () => Promise<T>,
  minimumFlagDurationInMilliseconds: number = 0, // Used for preventing loaders to disappear almost instantly
): Promise<T> {
  const startTime = new Date().getTime();
  store.commit("initSaveLoader", flagName);

  try {
    return await processFunction();
  } finally {
    setTimeout(
      () => {
        store.commit("finishSaveLoader", flagName);
      },
      getTimeRemainingBeforeFlagRelease(
        startTime,
        new Date().getTime(),
        minimumFlagDurationInMilliseconds,
      ),
    );
  }
}

function getTimeRemainingBeforeFlagRelease(
  startTime,
  endTime,
  minimumFlaDurationInMilliseconds,
) {
  const operationDuration = endTime - startTime;

  return operationDuration < minimumFlaDurationInMilliseconds
    ? minimumFlaDurationInMilliseconds - operationDuration
    : 0;
}
