import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MLink = _resolveComponent("MLink")!
  const _component_MNotification = _resolveComponent("MNotification")!
  const _component_MTextInput = _resolveComponent("MTextInput")!
  const _component_DvpField = _resolveComponent("DvpField")!
  const _component_MSelect = _resolveComponent("MSelect")!
  const _component_MTextArea = _resolveComponent("MTextArea")!
  const _component_MFileUploader = _resolveComponent("MFileUploader")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_LayerModalForm = _resolveComponent("LayerModalForm")!

  return (_openBlock(), _createBlock(_component_LayerModalForm, {
    class: "add-or-update-documentation-second-step",
    onSubmit: $options.submit
  }, {
    fields: _withCtx(() => [
      _createVNode(_component_MNotification, {
        title: 
          $data.documentMethod === $data.EDocumentationPageSource.URL
            ? $data.contents.urlMethod
            : $data.contents.preferUrlMethod
        ,
        type: 
          $data.documentMethod === $data.EDocumentationPageSource.URL
            ? 'success'
            : 'warning'
        
      }, {
        footer: _withCtx(() => [
          _createVNode(_component_MLink, {
            size: "s",
            href: 
              $options.convertRouteToHref({
                name: 'docsMain',
                hash: '#add-documentation',
              })
            ,
            target: "_blank"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($data.contents.viewDocumentation), 1)
            ]),
            _: 1
          }, 8, ["href"])
        ]),
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($data.contents.documentationUpToDate) + " ", 1)
        ]),
        _: 1
      }, 8, ["title", "type"]),
      ($options.documentationType !== $data.EDocumentationType.REST_API_SPECIFICATIONS)
        ? (_openBlock(), _createBlock(_component_DvpField, {
            key: 0,
            label: $data.contents.documentTitle,
            required: "",
            errorMessage: $data.form.firstError('title')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_MTextInput, {
                modelValue: $data.form.title,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.form.title) = $event)),
                isInvalid: $options.isInvalidDocTitle
              }, null, 8, ["modelValue", "isInvalid"])
            ]),
            _: 1
          }, 8, ["label", "errorMessage"]))
        : _createCommentVNode("", true),
      _createVNode(_component_DvpField, {
        label: $data.contents.documentMethod,
        required: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_MSelect, {
            id: "select-documentation-method",
            modelValue: $data.documentMethod,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.documentMethod) = $event)),
            "data-cy": "select-documentation-method",
            options: $data.contents.documentationTypes
          }, null, 8, ["modelValue", "options"])
        ]),
        _: 1
      }, 8, ["label"]),
      ($data.documentMethod === $data.EDocumentationPageSource.URL)
        ? (_openBlock(), _createBlock(_component_DvpField, {
            key: 1,
            label: $data.contents.documentUrl,
            required: "",
            errorMessage: $data.form.firstError('url')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_MTextInput, {
                modelValue: $data.form.url,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.form.url) = $event)),
                isInvalid: $options.isInvalidDocUrl
              }, null, 8, ["modelValue", "isInvalid"])
            ]),
            _: 1
          }, 8, ["label", "errorMessage"]))
        : _createCommentVNode("", true),
      ($data.documentMethod === $data.EDocumentationPageSource.CONTENT)
        ? (_openBlock(), _createBlock(_component_DvpField, {
            key: 2,
            label: $data.contents.documentContent,
            required: "",
            errorMessage: $data.form.firstError('content')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_MTextArea, {
                modelValue: $data.form.content,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($data.form.content) = $event)),
                class: "add-or-update-documentation-second-step__content-textarea",
                spellcheck: false,
                placeholder: `${$options.typeLabel} content`,
                isInvalid: $options.isInvalidDocContent
              }, null, 8, ["modelValue", "placeholder", "isInvalid"])
            ]),
            _: 1
          }, 8, ["label", "errorMessage"]))
        : _createCommentVNode("", true),
      ($data.documentMethod === $data.EDocumentationPageSource.FILE)
        ? (_openBlock(), _createBlock(_component_MFileUploader, {
            key: 3,
            id: "file-uploader-button",
            label: $data.contents.selectFileToUpload,
            accept: 
            [
              $data.EDocumentationType.CHANGELOG,
              $data.EDocumentationType.MARKDOWN,
            ].includes($options.documentationType)
              ? '.md'
              : '.json,.yml,.yaml'
          ,
            onFileAdded: $options.filesChange
          }, null, 8, ["label", "accept", "onFileAdded"]))
        : _createCommentVNode("", true)
    ]),
    formFooter: _withCtx(() => [
      _createVNode(_component_MButton, {
        type: "button",
        theme: "bordered-neutral",
        label: $data.contents.cancel,
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('onClose')))
      }, null, 8, ["label"]),
      _createVNode(_component_MButton, {
        type: "submit",
        label: $data.contents.submit,
        disabled: $data.form.errors.length > 0
      }, null, 8, ["label", "disabled"])
    ]),
    _: 1
  }, 8, ["onSubmit"]))
}