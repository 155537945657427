<template>
  <div class="app-item">
    <div class="app-item__body loading">
      <div class="app-item__icon">
        <img :src="defaultIcon" />
      </div>
      <div class="app-item__content">
        <div class="loading-h4"></div>
        <div class="loading-p"></div>
      </div>
    </div>
    <div class="app-item__footer footer loading">
      <div class="app-item__footer__title">
        <span class="text">
          {{ contents.footerTitle }}
        </span>
      </div>
      <div class="app-item__footer__body">
        <div class="loading-p"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import contents from "@/dashboard/contents/app-list-item-loading-skeleton";

export default {
  name: "AppListItemLoadingSkeleton",
  data() {
    return { contents };
  },
  computed: {
    defaultIcon() {
      return require("@/commons/assets/icons/icon-app-default.svg");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/commons/assets/styles/vars";
@import "~@/commons/assets/styles/lib/skeleton-loading-mixin";

.app-item {
  position: relative;
  display: block;
  height: 20rem;
  overflow: hidden;
  background-color: var(--color-background-interface);
  border: var(--border-default);
  border-radius: 5px;

  .loading::after {
    @include skeleton-loading;
  }
}

.app-item__body,
.app-item__footer {
  width: 100%;
  overflow: hidden;
  font-size: 0.9rem;
  line-height: 1.3;
}

.app-item .app-item__body {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  text-align: center;
}

.app-item .app-item__body .app-item__icon {
  display: block;
  flex: 0 0;
  width: 3rem;
  height: 25%;
  min-height: 15%;
  max-height: 15%;
  margin: 1em auto;
}

.app-item .app-item__body .app-item__content {
  display: block;
  height: 60%;
  min-height: 60%;
  max-height: 60%;

  padding: 0 1em;

  .loading-h4 {
    width: 100px;
    height: 0.9rem;
    margin: 1.55rem auto;
    background-color: var(--color-light-grey);
    border-radius: 2rem;
  }
  .loading-p {
    width: 150px;
    height: 0.7rem;
    margin: 0.7rem 0;
    background-color: var(--color-light-grey);
    border-radius: 2rem;
  }
}

.app-item__footer {
  position: absolute;
  bottom: 0;
  height: 6rem;
  min-height: 25%;

  max-height: 25%;
  cursor: pointer;
  background-color: var(--color-background-interface);
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  transition: all 0.25s ease-in-out;

  /* stylelint-disable selector-class-pattern */
  &__title {
    width: 100%;
  }

  &__body {
    max-height: 80%;
    margin-top: 1.5em;
    overflow: hidden;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;

    .loading-p {
      width: 150px;
      height: 0.7rem;
      margin: 1em auto;
      background-color: var(--color-light-grey);
      border-radius: 2rem;
    }
  }
  /* stylelint-enable selector-class-pattern */

  .text {
    position: absolute;
    left: 1rem;
    padding: 0.3rem;
    color: var(--color-light-grey);
    background-color: var(--color-background-interface);
  }

  &::before {
    position: absolute;
    top: 0.9rem;
    width: 100%;
    height: 1px;
    content: "";
    background-color: var(--color-light-grey);
  }

  &::after {
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    height: 2rem;
    pointer-events: none;
    content: "";
    background: linear-gradient(
      rgb(var(--color-background-interface-rgba) 0),
      rgb(var(--color-background-interface-rgba) 1)
    );
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .add-manage {
    display: inline-block;
    padding-left: 1.5rem;
    margin: 1rem 0;
    margin-left: 2rem;
    text-decoration: none;
    list-style-type: none;
    background: transparent url("~@/commons/assets/icons/list-arrow.svg")
      no-repeat center left;
    background-size: auto 50%;
  }
}
</style>
