<template>
  <LayerModalForm
    class="create-api-second-step create-api-second-step__form"
    @submit="submit"
  >
    <template #fields>
      <DvpStepper :steps="stepperSteps" :stepperDescription="contents.title" />
      <DvpField
        class="create-api-second-step__api-type-field"
        required
        :label="contents.apiType"
        :helpText="contents.apiTypeHelpText"
        :errorMessage="form.firstError('type')"
        data-cy="api-type-field"
      >
        <MSelect
          id="api-type-selection"
          :placeholder="contents.apiTypePlaceholder"
          :options="apiTypeInternalOptions"
          :modelValue="String(form.internal)"
          :isInvalid="isInvalidField('type')"
          @update:modelValue="form.internal = Boolean($event)"
        />
      </DvpField>

      <DvpField
        class="create-api-second-step__api-version-field"
        required
        :label="contents.apiVersion"
        :errorMessage="form.firstError('version')"
        data-cy="api-version-field"
      >
        <MTextInput
          v-model.number="form.version"
          type="number"
          min="1"
          :isInvalid="isInvalidField('version')"
        />
      </DvpField>

      <DvpField
        class="create-api-second-step__api-name-field"
        required
        :label="contents.apiName"
        :helpText="contents.apiNameHelpText"
        :errorMessage="form.firstError('name')"
        data-cy="api-name-field"
      >
        <MTextInput
          v-model="form.name"
          type="text"
          :placeholder="contents.apiNamePlaceholder"
          :isInvalid="isInvalidField('name')"
        />
      </DvpField>

      <DvpField
        class="create-api-second-step__api-description-field"
        required
        :label="contents.apiDescription"
        :errorMessage="form.firstError('description')"
        data-cy="api-description-field"
      >
        <MTextArea
          v-model="form.description"
          class="create-api-second-step__api-description-textarea"
          :placeholder="contents.apiDescriptionPlaceholder"
          :isInvalid="isInvalidField('description')"
        />
      </DvpField>
    </template>
    <template #formFooter>
      <MButton
        type="button"
        theme="bordered-neutral"
        :label="contents.previous"
        @click="goToPreviousStep"
      />
      <MButton
        class="create-api-second-step__submit-button"
        type="submit"
        :label="contents.next"
        :disabled="!!form.errors.length || isSaving"
      />
    </template>
  </LayerModalForm>
</template>

<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import MSelect from "@mozaic-ds/vue-3/src/components/select/MSelect.vue";
import MTextArea from "@mozaic-ds/vue-3/src/components/textarea/MTextArea.vue";
import MTextInput from "@mozaic-ds/vue-3/src/components/textinput/MTextInput.vue";

import DvpStepper from "@/commons/components/DvpStepper.vue";
import DvpField from "@/commons/components/form/DvpField.vue";
import LayerModalForm from "@/commons/components/form/LayerModalForm.vue";
import CreateApiFirstStep from "@/manager/views/ApiRegisterNew/CreateApiFirstStep.vue";

import { ApiForm } from "@/manager/domain/forms/api-form";

import { EApiType, EApiTypeLabel } from "@/commons/store/types";

import contents from "@/manager/contents/create-api";

export default {
  components: {
    DvpStepper,
    LayerModalForm,
    DvpField,
    MSelect,
    MTextInput,
    MTextArea,
    MButton,
  },
  props: {
    initialFormData: {
      type: Object,
      required: true,
    },
    shouldShowFormErrorsOnInit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      contents,
      form: ApiForm.create("SECOND_STEP"),
      EApiTypeLabel,
    };
  },
  computed: {
    stepperSteps() {
      return [
        { label: contents.fillGlobalInformationStepLabel },
        { label: contents.fillApiInformationStepLabel, isCurrent: true },
      ];
    },
    isSaving() {
      return this.$store.getters["isSaving"];
    },
    apiTypeInternalOptions() {
      return [
        { value: "true", text: contents.backendApiType },
        { value: "false", text: contents.apiApiType },
      ];
    },
  },
  async mounted() {
    await this.form.init(this.initialFormData);

    if (this.shouldShowFormErrorsOnInit) {
      this.form.validate();
    }
  },
  methods: {
    isInvalidField(fieldName: string): boolean {
      return this.form.firstError(fieldName) != null;
    },
    goToPreviousStep(): void {
      this.$store.commit("openLayerModal", {
        title: contents.title,
        component: CreateApiFirstStep,
        props: { initialFormData: this.form.data() },
      });
    },
    async submit() {
      this.form.validate();

      if (!this.form.errors.length && !this.isSaving) {
        const {
          businessUnitTagIds,
          productId,
          name,
          internal,
          version,
          description,
          groupId,
        } = this.form.data();

        await this.$store.dispatch("createApi", {
          name,
          type: EApiType.API_REST,
          internal,
          description,
          version,
          productId,
          tagsIds: businessUnitTagIds,
          groupId,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.create-api-second-step__api-version-field {
  max-width: 10rem;
}

.create-api-second-step__api-description-textarea {
  height: 9rem;
}
</style>
