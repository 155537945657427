import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "product-detail__body" }
const _hoisted_2 = { class: "product-detail__page-title" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductDetailSecondaryNavbar = _resolveComponent("ProductDetailSecondaryNavbar")!
  const _component_ProductDetailSideMenu = _resolveComponent("ProductDetailSideMenu")!
  const _component_CardContainer = _resolveComponent("CardContainer")!
  const _component_ProductApisList = _resolveComponent("ProductApisList")!
  const _component_Layout2 = _resolveComponent("Layout2")!

  return (_openBlock(), _createBlock(_component_Layout2, {
    class: "product-detail",
    isLoading: $data.isLoadingProduct,
    loadingMessage: $data.contents.loadingMessage
  }, {
    secondaryNavbar: _withCtx(() => [
      ($data.productDetail)
        ? (_openBlock(), _createBlock(_component_ProductDetailSecondaryNavbar, {
            key: 0,
            product: $data.productDetail
          }, null, 8, ["product"]))
        : _createCommentVNode("", true)
    ]),
    sideMenu: _withCtx(() => [
      ($data.productDetail)
        ? (_openBlock(), _createBlock(_component_ProductDetailSideMenu, {
            key: 0,
            productTitle: $data.productDetail.name
          }, null, 8, ["productTitle"]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_CardContainer, { id: "product-description" }, {
          default: _withCtx(() => [
            _createElementVNode("h2", _hoisted_2, _toDisplayString($options.pageTitle), 1),
            _createElementVNode("h3", null, _toDisplayString($data.contents.description), 1),
            ($data.productDetail && $data.productDetail.description)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString($data.productDetail.description), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString($data.contents.noDescription), 1))
          ]),
          _: 1
        }),
        ($data.productDetail && $data.productDetail.providedApis)
          ? (_openBlock(), _createBlock(_component_ProductApisList, {
              key: 0,
              title: $data.contents.providedApis,
              anchorId: "product-provided-apis",
              apis: $data.productDetail.providedApis,
              noItemsMessage: $options.noProvidedApisMessage
            }, null, 8, ["title", "apis", "noItemsMessage"]))
          : _createCommentVNode("", true),
        ($data.productDetail && $data.productDetail.usedApis)
          ? (_openBlock(), _createBlock(_component_ProductApisList, {
              key: 1,
              title: $data.contents.usedApis,
              anchorId: "product-used-apis",
              apis: $data.productDetail.usedApis,
              noItemsMessage: $options.noUsedApisMessage
            }, null, 8, ["title", "apis", "noItemsMessage"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["isLoading", "loadingMessage"]))
}