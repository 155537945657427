<template>
  <div class="product-detail-side-menu">
    <NavigateBeforeButton
      :title="contents.backToProductsList"
      :previousPageRoute="previousPageRoute"
    />
    <div class="product-detail-side-menu-inner">
      <MAccordion
        :id="menuItem.id"
        :key="menuItem.id"
        :label="menuItem.label"
        :title="menuItem.label"
        :open="true"
      >
        <RouterLink
          v-for="(childItem, index) in menuItem.subItems"
          :key="index"
          class="product-detail-side-menu-link"
          :to="{ hash: `#${childItem.anchorId}` }"
          :title="childItem.label"
        >
          <span class="product-detail-side-menu-link__label">{{
            childItem.label
          }}</span>
        </RouterLink>
      </MAccordion>
    </div>
  </div>
</template>

<script lang="ts">
import MAccordion from "@mozaic-ds/vue-3/src/components/accordion/MAccordion.vue";

import NavigateBeforeButton from "@/documentation/views/ApiDetail/NavigateBeforeButton.vue";

import {
  getPreviousPageRoute,
  PRODUCT_CATALOG_ROUTE_NAME,
} from "@/commons/utils/route-utils";

import contents from "@/documentation/contents/product-detail";

export interface ProductSideMenuItem {
  label: string;
  id: string;
  subItems: ProductSideMenuSubItem[];
}

export interface ProductSideMenuSubItem {
  label: string;
  anchorId: string;
}

export default {
  components: {
    NavigateBeforeButton,
    MAccordion,
  },
  props: {
    productTitle: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      contents,
      previousPageRoute: getPreviousPageRoute(PRODUCT_CATALOG_ROUTE_NAME),
    };
  },
  computed: {
    menuItem(): ProductSideMenuItem {
      return {
        id: "product-side-menu",
        label: `${contents.aboutPrefix} ${this.productTitle}`,
        subItems: [
          {
            label: contents.description,
            anchorId: "product-description",
          },
          {
            label: contents.providedApis,
            anchorId: "product-provided-apis",
          },
          {
            label: contents.usedApis,
            anchorId: "product-used-apis",
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss">
.product-detail-side-menu {
  width: 19rem;
  padding: 1rem;

  // mozaic overrides
  /* stylelint-disable selector-class-pattern */
  .mc-accordion__header__label {
    white-space: normal;
  }
}

.product-detail-side-menu-link {
  display: block;
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;

  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.product-detail-side-menu-link:hover {
  background-color: var(--color-background-secondary-light);
}
</style>
