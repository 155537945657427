import { MaxLength, MinLength } from "class-validator";

import { FormData } from "@/commons/domain/forms/form-data";
import { FormFactory } from "@/commons/domain/forms/form-factory";

export class CreateGroupForm extends FormData {
  @MinLength(3, {
    message:
      "This field is too short. Minimal length is $constraint1 characters",
  })
  @MaxLength(50, {
    message:
      "This field is too long. Maximal length is $constraint1 characters",
  })
  name: string = "";

  @MaxLength(200, {
    message:
      "This field is too long. Maximal length is $constraint1 characters",
  })
  description: string = "";

  $clear() {
    this.name = "";
    this.description = "";
  }

  static create() {
    return FormFactory.createForm(new CreateGroupForm());
  }
}
