<script setup lang="ts">
import { computed, onMounted, PropType, reactive } from "vue";
import { useStore } from "vuex";

import CardContainer from "@/commons/components/CardContainer.vue";
import IconSwagger from "@/commons/components/Icons/IconSwagger.vue";
import ScopesList from "@/manager/views/AddOAuthScopes/ScopesList.vue";

import { Scope } from "@/commons/domain/models/scope";

import contents from "@/manager/contents/add-oauth-scopes";

const props = defineProps({
  apiId: {
    type: String,
    required: true,
  },
  selectedScopes: {
    type: Array as PropType<Scope[]>,
    required: true,
  },
  selectionDisabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  "selectScope",
  "showScopeProducts",
  "changeSelectedScopesVisibility",
]);

const store = useStore();

/**
 * Load scopes parsed from API's Swagger documentation.
 * Descriptions are not saved on devportal's side, they are fetched each time from PING.
 */

const scopes = reactive({
  items: computed(() => {
    return store.getters["documentedScopes"];
  }),
  filteredItems: [],
  currentPage: 1,
  size: 10,
  isLoading: computed(() =>
    store.getters["isLoadingProperty"]("documentedScopes"),
  ),
  filterScopes: (
    params: { search?: string; page?: number; size?: number } = {},
  ) => {
    if (params.search != undefined && params.search !== "") {
      scopes.filteredItems = scopes.items.filter((scope: Scope) =>
        scope.name.toLowerCase().includes(params.search?.toLowerCase() ?? ""),
      );
    } else {
      scopes.filteredItems = scopes.items;
    }

    scopes.size = params.size != undefined ? params.size : 10;
    scopes.currentPage = params.page != undefined ? params.page : 1;
  },
  displayedItems: computed(() => {
    return scopes.filteredItems.slice(
      scopes.size * (scopes.currentPage - 1),
      scopes.size * scopes.currentPage,
    );
  }),
  load: async () => {
    await store.dispatch("loadDocumentedScopes", {
      apiId: props.apiId,
    });
    scopes.filteredItems = scopes.items;
    emit("changeSelectedScopesVisibility", scopes.items);
  },
  totalCount: computed(() =>
    scopes.filteredItems != undefined ? scopes.filteredItems.length : 0,
  ),
});

onMounted(async () => {
  await scopes.load();
});

const handleSelectScope = (scope: Scope, isSelected: boolean) => {
  emit("selectScope", scope, isSelected);
  emit("changeSelectedScopesVisibility", scopes.items);
};
</script>

<template>
  <CardContainer class="documented-scopes-list">
    <div class="documented-scopes-list__header">
      <IconSwagger />
      <h5>{{ contents.documentedScopesHeader }}</h5>
    </div>

    <ScopesList
      :apiId="apiId"
      :selectedScopes="selectedScopes"
      :scopes="scopes.displayedItems"
      :isLoading="scopes.isLoading"
      :currentPage="scopes.currentPage"
      :totalCount="scopes.totalCount"
      :selectionDisabled="selectionDisabled"
      documented
      @loadScopes="scopes.filterScopes($event)"
      @selectScope="handleSelectScope"
      @showScopeProducts="emit('showScopeProducts', $event)"
    />
  </CardContainer>
</template>

<style lang="scss">
.documented-scopes-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  &__header {
    display: flex;
    gap: 0.5rem;

    align-items: center;
  }
}
</style>
