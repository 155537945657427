import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "subscribable-apis-modal__title" }
const _hoisted_2 = { class: "subscribable-apis-modal__filters" }
const _hoisted_3 = { class: "subscribable-apis-modal__authentication-filter" }
const _hoisted_4 = { class: "subscribable-apis-modal__authentication-filter-legend" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MTextInput = _resolveComponent("MTextInput")!
  const _component_MCheckboxGroup = _resolveComponent("MCheckboxGroup")!
  const _component_MPagination = _resolveComponent("MPagination")!
  const _component_SubscribableApiCardList = _resolveComponent("SubscribableApiCardList")!
  const _component_LayerModalFormConfirm = _resolveComponent("LayerModalFormConfirm")!

  return (_openBlock(), _createBlock(_component_LayerModalFormConfirm, {
    class: "subscribable-apis-modal",
    confirmBtnIsHidden: "",
    onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('onClose')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("h4", _hoisted_1, _toDisplayString(`${$data.contents.availableApiTitle} (${$options.nbrOfAvailableApis})`), 1),
      _createVNode(_component_MTextInput, {
        "data-cy": "subscribable-apis-search-input",
        type: "search",
        icon: "DisplaySearch32",
        placeholder: $data.contents.searchTermPlaceholder,
        modelValue: $data.apiSearchTerm,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.debounceChangeApiSearchTerm($event)))
      }, null, 8, ["placeholder", "modelValue"]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("label", _hoisted_4, _toDisplayString($data.contents.authenticationFilterLegend), 1),
          _createVNode(_component_MCheckboxGroup, {
            disabled: $options.isLoading,
            options: $options.authenticationTypeOptions,
            modelValue: $data.selectedAuthenticationTypes,
            inline: "",
            "onUpdate:modelValue": $options.selectAuthenticationTypes
          }, null, 8, ["disabled", "options", "modelValue", "onUpdate:modelValue"])
        ]),
        ($options.subscribableApisTotalPages > 0)
          ? (_openBlock(), _createBlock(_component_MPagination, {
              key: 0,
              class: "subscribable-apis-modal__pagination",
              length: $options.subscribableApisTotalPages,
              value: $options.currentPage,
              onOnUpdatePage: $options.changePage
            }, null, 8, ["length", "value", "onOnUpdatePage"]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_SubscribableApiCardList, {
        class: "subscribable-apis-modal__api-card-list",
        apisList: $options.subscribableApisList
      }, null, 8, ["apisList"])
    ]),
    _: 1
  }))
}