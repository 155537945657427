import { IsNotEmpty, MaxLength, ValidateIf } from "class-validator";

import { FormData } from "@/commons/domain/forms/form-data";
import { FormFactory } from "@/commons/domain/forms/form-factory";

export class EmailForm extends FormData {
  @ValidateIf((emailForm) => emailForm.needApiSelection === true)
  @IsNotEmpty()
  api?: string;

  @IsNotEmpty()
  @MaxLength(30)
  subject: string = "";

  @IsNotEmpty()
  @MaxLength(500)
  body: string = "";

  needApiSelection: boolean = false;

  constructor(needApiSelection: boolean = false) {
    super();
    this.needApiSelection = needApiSelection;
  }

  $clear() {
    this.api = undefined;
    this.subject = "";
    this.body = "";
  }

  static create(needApiSelection: boolean = false) {
    return FormFactory.createForm(new EmailForm(needApiSelection));
  }
}
