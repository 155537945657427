import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconButton = _resolveComponent("IconButton")!

  return (_openBlock(), _createBlock(_component_IconButton, {
    class: "copy-icon-button",
    mozaicIconName: $props.iconName,
    onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.copyToClipboard($props.content)))
  }, null, 8, ["mozaicIconName"]))
}