import { HttpRepository } from "@/commons/repositories/libs/http-repository";

import { Injectable } from "@/commons/domain/di/injectable";
import { ConfigRepository } from "@/commons/domain/repositories/config-repository";
import { EnvDto } from "@/commons/dtos/env.dto";

@Injectable()
export class ConfigHttpRepository
  extends HttpRepository
  implements ConfigRepository
{
  async getEnvironmentsConfig() {
    const response = await this.requestHandler.get<EnvDto>(`/env`);
    return response.data;
  }
}
