<script setup lang="ts">
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import CardContainer from "@/commons/components/CardContainer.vue";
import { DvpTabsItem } from "@/commons/components/DvpTabs/DvpTabs.type";
import DvpTabs from "@/commons/components/DvpTabs/DvpTabs.vue";
import Grid from "@/commons/components/Grid.vue";
import ApiKeyContracts from "@/dashboard/views/AppDetailsContracts/ApiKeyContracts.vue";
import OAuthACFContracts from "@/dashboard/views/AppDetailsContracts/OAuthACFContracts.vue";
import OAuthCCFContracts from "@/dashboard/views/AppDetailsContracts/OAuthCCFContracts.vue";
import ContractLoadingSkeleton from "@/dashboard/views/ContractsList/ContractLoadingSkeleton.vue";

import { changeUrlQuery } from "@/commons/utils/route-utils";
import {
  applicationHasContractsOfType,
  defaultFirstTab,
  getTabTypeFromUrlQuery,
  getUrlQueryFromTabType,
} from "@/dashboard/utils/tabs-utils";

import { ContractFilters } from "@/dashboard/domain/contract-filters";

import { EApplicationTab } from "@/commons/store/types";

import contents from "@/dashboard/contents/application";

const store = useStore();
const route = useRoute();

const isLoading = computed((): boolean => {
  return (
    store.getters["isLoadingProperty"]("oauthACFApplicationContracts") ||
    store.getters["isLoadingProperty"]("oauthCCFApplicationContracts") ||
    store.getters["isLoadingProperty"]("apiKeyApplicationContracts")
  );
});

const tabs = reactive({
  itemsMap: new Map<EApplicationTab, DvpTabsItem>([
    [EApplicationTab.API_KEY, undefined],
    [EApplicationTab.OAUTH_CCF, undefined],
    [EApplicationTab.OAUTH_ACF, undefined],
  ]) as Map<EApplicationTab, DvpTabsItem>,
  items: computed(() =>
    Array.from(tabs.itemsMap.keys()).filter(
      (k: EApplicationTab) => tabs.itemsMap.get(k) != undefined,
    ),
  ),
  itemsValues: computed(() => {
    return tabs.items.map((item) => tabs.itemsMap.get(item));
  }),
  current: "",
  updateTab: (tab: EApplicationTab, label: string) => {
    tabs.itemsMap.set(tab, {
      label,
      value: tab,
    });
    if (tabs.current === "") {
      if (predefinedTab.value == undefined) {
        tabs.selectFirstTab();
      } else if (tab === predefinedTab.value) {
        tabs.current = tab;
        predefinedTab.value = undefined;
      }
    }
  },
  hideTab: (tab: EApplicationTab) => {
    tabs.itemsMap.set(tab, undefined);
    tabs.selectFirstTab();
  },
  addApiKeyTab: (contractsNumber: number) => {
    tabs.updateTab(
      EApplicationTab.API_KEY,
      contents.apiKeyTabLabel(contractsNumber),
    );
  },
  addOAuthACFTab: (contractsNumber: number) => {
    tabs.updateTab(
      EApplicationTab.OAUTH_ACF,
      contents.oauthACFTabLabel(contractsNumber),
    );
  },
  addOAuthCCFTab: (contractsNumber: number) => {
    tabs.updateTab(
      EApplicationTab.OAUTH_CCF,
      contents.oauthCCFTabLabel(contractsNumber),
    );
  },
  selectFirstTab: () => {
    if (tabs.items.length > 0) {
      tabs.current = tabs.items[0];
    } else {
      tabs.current = "";
    }
  },
  updateCurrentTab: (value: string) => {
    if (tabs.items.includes(value)) {
      tabs.current = value;
    }
  },
});

const contractFilters = computed((): ContractFilters => {
  const query = route.query;
  return {
    deprecated: query.deprecated === "true",
    disabled: query.disabled === "true",
    aboutToExpire: query.aboutToExpire === "true",
    expired: query.expired === "true",
    apiName: (query.term as string) || "",
  };
});

const anyFilterIsActivated = computed(() => {
  return (
    contractFilters.value.deprecated ||
    contractFilters.value.disabled ||
    contractFilters.value.aboutToExpire ||
    contractFilters.value.expired ||
    contractFilters.value.apiName !== ""
  );
});

const newClientIdCreatedToast = () => {
  store.commit("postInformationNotification", {
    title: contents.newClientIdToastTitle,
    message: contents.newClientIdToastContent,
  });
};

const predefinedTab = ref(undefined as EApplicationTab);

const application = computed(() => store.getters["currentApplication"]);

const contractsMetrics = computed(() => application.value?.metrics?.contracts);

const handlePredefinedTab = () => {
  const tabRouteQuery = route.query["type"] as string;

  if (tabRouteQuery != undefined) {
    const tabType = getTabTypeFromUrlQuery(tabRouteQuery);

    if (applicationHasContractsOfType(tabType, contractsMetrics.value)) {
      predefinedTab.value = tabType;
    }
  } else {
    predefinedTab.value = defaultFirstTab(contractsMetrics.value);
  }
};

onMounted(() => {
  handlePredefinedTab();
});

watch(
  () => tabs.current,
  (newValue, prevValue) => {
    if (predefinedTab.value == undefined && newValue !== prevValue) {
      changeUrlQuery({ type: getUrlQueryFromTabType(newValue) });
    }
  },
);
</script>

<template>
  <div class="contracts-tabs">
    <DvpTabs
      v-if="tabs.items?.length > 0"
      v-model="tabs.current"
      :tabs="tabs.itemsValues"
      align="full"
    />

    <CardContainer>
      <div v-if="anyFilterIsActivated && tabs.items?.length === 0">
        <span> {{ contents.noApiFoundForSelectedFilters }} </span>
      </div>

      <template v-if="isLoading && tabs.current === ''">
        <Grid class="contracts-tabs__loading-contracts">
          <ContractLoadingSkeleton
            v-for="(item, index) in new Array(8)"
            :key="`contract-loading-skeleton-${index}`"
          />
        </Grid>
      </template>

      <ApiKeyContracts
        :currentTab="tabs.current"
        @selectMe="tabs.updateCurrentTab(EApplicationTab.API_KEY)"
        @displayMe="tabs.addApiKeyTab"
        @hideMe="tabs.hideTab(EApplicationTab.API_KEY)"
      />
      <OAuthCCFContracts
        :currentTab="tabs.current"
        @selectMe="tabs.updateCurrentTab(EApplicationTab.OAUTH_CCF)"
        @newClientIdCreated="newClientIdCreatedToast"
        @displayMe="tabs.addOAuthCCFTab"
        @hideMe="tabs.hideTab(EApplicationTab.OAUTH_CCF)"
      />
      <OAuthACFContracts
        :currentTab="tabs.current"
        @selectMe="tabs.updateCurrentTab(EApplicationTab.OAUTH_ACF)"
        @newClientIdCreated="newClientIdCreatedToast"
        @displayMe="tabs.addOAuthACFTab"
        @hideMe="tabs.hideTab(EApplicationTab.OAUTH_ACF)"
      />
    </CardContainer>
  </div>
</template>

<style lang="scss">
.contracts-tabs__loading-contracts {
  --grid-auto-rows: auto;
}
</style>
