import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chip = _resolveComponent("chip")!

  return (_openBlock(), _createBlock(_component_chip, {
    class: _normalizeClass(['request-status', 'status__' + $props.request.status])
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString($options.computeStatus($props.request)), 1)
    ]),
    _: 1
  }, 8, ["class"]))
}