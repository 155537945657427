<template>
  <div class="api-deprecation-info-for-navbar">
    <MBadge type="danger">{{ contents.deprecated }}</MBadge>
    <div
      v-if="api.deprecationDate"
      class="api-deprecation-info-for-navbar__api-deprecation-date"
    >
      {{ contents.since }} {{ toLocaleDate(api.deprecationDate) }}
    </div>
  </div>
</template>

<script lang="ts">
import MBadge from "@mozaic-ds/vue-3/src/components/badge/MBadge.vue";
import { PropType } from "vue";

import { toLocaleDate } from "@/commons/utils/date-utils";

import { Api } from "@/commons/domain/models/api";

import contents from "@/commons/contents/secondary-navbar-api";

export default {
  components: {
    MBadge,
  },
  props: {
    api: {
      type: Object as PropType<Api>,
      required: true,
    },
  },
  data() {
    return {
      contents,
    };
  },
  methods: {
    toLocaleDate,
  },
};
</script>

<style lang="scss">
.api-deprecation-info-for-navbar__api-deprecation-date {
  @include set-text-s;
  margin-top: 0.375rem;
  color: var(--color-m-text-minor);
}
</style>
