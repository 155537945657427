<template>
  <GlobalModalBody class="modal-consumers-prevent-oauth-flow-change">
    <template #content>
      <h6 class="modal-consumers-prevent-oauth-flow-change__title">
        {{ activeSubscriptionContents.title }}
      </h6>
      <Markdown :content="activeSubscriptionContents.explanation" />
    </template>
    <template #footer>
      <MButton
        :label="contents.buttonClose"
        theme="bordered-neutral"
        @click="emit('onClose')"
      />
      <MButton
        :label="contents.buttonGoToConsumers"
        :href="hrefToConsumersPage"
      />
    </template>
  </GlobalModalBody>
</template>

<script setup lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import { computed, reactive } from "vue";

import GlobalModalBody from "@/commons/components/Modal/GlobalModalBody.vue";
import Markdown from "@/documentation/components/commons/Markdown.vue";

import { convertRouteToHref } from "@/commons/utils/route-utils";

import { Api } from "@/commons/domain/models/api";
import { EOauthFlow } from "@/commons/types/oauth-flow-types";

import contents from "@/manager/contents/modal-consumers-preventing-oauth-flow-change";

interface Props {
  api: Api;
  oauthFlowRelatedToChange: EOauthFlow;
}

const props = defineProps<Props>();

const emit = defineEmits(["onClose"]);

const hrefToConsumersPage = computed((): string =>
  convertRouteToHref({
    name: "managerApiDetailConsumers",
    params: {
      id: props.api.id,
    },
  }),
);

const activeSubscriptionContents = reactive({
  title: computed((): string =>
    props.oauthFlowRelatedToChange == EOauthFlow.CLIENT_CREDENTIALS
      ? contents.activeCCFSubscriptionTitle
      : contents.activeACFSubscriptionTitle,
  ),
  explanation: computed((): string =>
    props.oauthFlowRelatedToChange == EOauthFlow.CLIENT_CREDENTIALS
      ? contents.activeCCFSubscriptionExplanation(hrefToConsumersPage.value)
      : contents.activeACFSubscriptionExplanation(hrefToConsumersPage.value),
  ),
});
</script>

<style lang="scss">
.modal-consumers-prevent-oauth-flow-change__title {
  color: var(--color-status-danger);
}
</style>
