<template>
  <div class="markdown" v-html="contentAsHtml"></div>
</template>

<script lang="ts">
import { transformMarkdownToHtml } from "@/commons/utils/markdown-utils";

export default {
  props: {
    content: {
      type: String,
      default: "",
    },
  },
  computed: {
    contentAsHtml(): string {
      return transformMarkdownToHtml(this.content);
    },
  },
};
</script>

<style lang="scss">
// override markdown style so h1 doesn't get too big compared to text, etc...
.markdown {
  h1 {
    font-size: 1.5rem; // h3 equivalent ?
  }
  h2 {
    font-size: 1.2rem; // h4 equivalent ?
  }
  h3 {
    font-size: 1rem; // h5 equivalent ?
  }
  h4 {
    font-size: 0.9rem;
  }
  h5 {
    font-size: 0.8rem;
  }
  h6 {
    font-size: 0.7rem;
  }

  :last-child {
    margin-bottom: 0;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  tr {
    height: 2.5rem;
  }

  td,
  th {
    padding: 0 16px;
    border: 1px solid var(--color-stroke-very-light);
  }

  thead tr {
    background-color: var(--color-background-primary);

    th {
      font-weight: 700;
      text-align: left;
    }
  }

  tbody tr {
    &:nth-child(odd) {
      background-color: var(--color-background-secondary-light);
    }

    &:nth-child(even) {
      background-color: var(--color-background-primary);
    }
  }

  :first-child {
    margin-top: 0;
  }
}
</style>
