<template>
  <div
    :class="[
      'cluster',
      'cluster-layout',
      { 'cluster-layout--right': clusterToRight },
    ]"
  >
    <div>
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: "Cluster",
  props: {
    clusterToRight: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
