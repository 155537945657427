<template>
  <div class="use-case-main">
    <div class="use-case-main__description">
      <div class="use-case-main__title">{{ useCase.title }}</div>
      <!--eslint-disable-next-line vue/no-v-html-->
      <h1 class="use-case-main__headline" v-html="useCase.headline" />
      <div class="use-case-main__kicker">{{ useCase.kicker }}</div>
      <div class="use-case-main__actions">
        <use-case-actions is-first />
      </div>
    </div>
    <div class="use-case-main__list-features">
      <use-case-list-features
        :features="useCase.features"
        @navigateToAnchor="$emit('navigateToAnchor', $event)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import UseCaseActions from "./UseCaseActions.vue";
import UseCaseListFeatures from "./UseCaseListFeatures.vue";

export default {
  name: "UseCaseMain",
  components: {
    UseCaseActions,
    UseCaseListFeatures,
  },
  props: {
    useCase: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["navigateToAnchor"],
};
</script>

<style lang="scss">
@import "~@/commons/assets/styles/vars";

.use-case-main {
  display: flex;
  flex-direction: row;
  width: 91.4rem;
  margin: 0 0 10rem;
}

.use-case-main__description {
  display: flex;
  flex: 0.5;
  flex-direction: column;
  margin: 0 4rem 0 0;
}

.use-case-main__title {
  margin: 0 0 2rem;
  font-size: 1.29rem;
  font-weight: 200;
  line-height: 2.29rem;
  color: var(--color-white);
  text-transform: uppercase;
}

.use-case-main__headline {
  margin: 0 0 2rem;
  font-size: 3.71rem;
  font-weight: 200;
  line-height: 4.57rem;
}

.use-cases__headline--bold {
  font-size: 3.71rem;
  font-weight: 700;
  line-height: 4.57rem;
  color: var(--color-white);
}

.use-case-main__kicker {
  margin: 0 0 2rem;
  font-size: 1.29rem;
  font-weight: 300;
  line-height: 2.29rem;
}

.use-case-main__list-features {
  display: flex;
  flex: 0.5;
  flex-direction: column;
}

.use-case-list-features {
  padding: 0 8rem;
}

@media only screen and (max-width: map-get($breakpoints, desktop-small)) {
  .use-case-main {
    width: 90%;
  }

  .use-case-list-features {
    padding: 0 7rem;
  }
}

@media only screen and (max-width: map-get($breakpoints, tablet-landscape)) {
  .use-case-main {
    margin: 0 0 6rem;
  }

  .use-case-list-features {
    padding: 0 3rem;
  }

  .use-case-main__headline {
    font-size: 3rem;
    line-height: 4rem;
  }

  .use-cases__headline--bold {
    font-size: 3rem;
    line-height: 4rem;
  }

  .use-case-main__kicker {
    font-size: 1.2rem;
    line-height: 2rem;
  }
}

@media only screen and (max-width: map-get($breakpoints, tablet-portrait)) {
  .use-case-main {
    margin: 7rem 0 0;
  }

  .use-case-main__description {
    margin: 0 2rem 0 0;
  }

  .use-case-list-features {
    padding: 0 1.5rem;
  }
}

@media only screen and (max-width: map-get($breakpoints, mobile)) {
  .use-case-main {
    margin: 4rem 0 5rem;
  }

  .use-case-main__title {
    margin: 0 0 1rem;
    font-size: 1.1rem;
    font-weight: 200;
    line-height: 2rem;
  }

  .use-case-main__headline {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  .use-cases__headline--bold {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  .use-case-main__description {
    flex: 1 100%;
    margin: 0;
  }

  .use-case-main__list-features {
    display: none;
  }

  .use-case-main__kicker {
    margin: 0 0 1rem;
  }
}
</style>
