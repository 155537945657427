import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "app" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_CookieBanner = _resolveComponent("CookieBanner")!
  const _component_GlobalLayerModal = _resolveComponent("GlobalLayerModal")!
  const _component_GlobalModal = _resolveComponent("GlobalModal")!
  const _component_ToastNotifications = _resolveComponent("ToastNotifications")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_RouterView),
    _createVNode(_component_CookieBanner, { onCookieIsReady: $options.onCookieIsReady }, null, 8, ["onCookieIsReady"]),
    _createVNode(_component_GlobalLayerModal),
    _createVNode(_component_GlobalModal),
    _createVNode(_component_ToastNotifications, {
      horizontalPosition: "right",
      verticalPosition: "bottom"
    })
  ]))
}