import { markRaw } from "vue";
import { LocationQuery } from "vue-router";

import CreateApp from "@/dashboard/components/CreateApp.vue";
import Application from "@/dashboard/views/AppDetails/Application.vue";
import ApplicationSettings from "@/dashboard/views/AppDetails/ApplicationSettings.vue";
import ContractsApplication from "@/dashboard/views/AppDetailsContracts/ContractsApplication.vue";
import ReportingApplication from "@/dashboard/views/AppDetailsReporting/ReportingApplication.vue";
import MyApps from "@/dashboard/views/AppList/MyApps.vue";

import { onlyUrlChange } from "@/commons/libs/only-change-url";

import { Dictionary } from "@/commons/domain/models/dictionary";

import contentsCreateAppModal from "@/dashboard/contents/create-or-update-app";

export interface AppsUrlQuery {
  showAllApps: boolean;
  term: string;
  productsIds: string[];
  page: number;
  usedApisIds: string[];
  subscriptionStatuses: string[];
  apisStatuses: string[];
}

function mapRouteQueryToAppsListUrlQuery(
  routeQuery: LocationQuery,
  previousRouteQuery?: LocationQuery,
): AppsUrlQuery {
  const query = routeQuery as Dictionary<string>;

  return {
    showAllApps: query.showAllApps === "true",
    term: query.term || "",
    productsIds: query.productsIds ? query.productsIds.split(",") : [],
    page:
      query.page !== undefined && onlyUrlChange(previousRouteQuery, query)
        ? Number(query.page)
        : 1,
    usedApisIds: query.usedApisIds ? query.usedApisIds.split(",") : [],
    subscriptionStatuses: query.subscriptionStatuses
      ? query.subscriptionStatuses.split(",")
      : [],
    apisStatuses: query.apisStatuses ? query.apisStatuses.split(",") : [],
  };
}

let cachedQuery = null;

const routes = [
  {
    path: "",
    component: MyApps,
    name: "dashboard",
    meta: {
      secured: true,
      realm: "dashboard",
      crossEnv: true,
    },
    props: (route) => {
      const urlQuery = mapRouteQueryToAppsListUrlQuery(
        route.query,
        cachedQuery,
      );

      cachedQuery = route.query;

      return { urlQuery };
    },
  },
  {
    path: "applications/create",
    component: MyApps,
    name: "applicationCreate",
    meta: {
      secured: true,
      menu: "Register new application",
      realm: "dashboard",
      openLayerModal: {
        title: contentsCreateAppModal.modalTitle,
        component: markRaw(CreateApp),
        redirectOnClose: "dashboard",
      },
    },
    props: () => {
      return { urlQuery: mapRouteQueryToAppsListUrlQuery({}) }; // urlQuery is required by the MyApps component
    },
  },
  {
    path: "applications/:id",
    component: Application,
    props: true,
    meta: {
      secured: true,
      realm: "dashboard",
    },
    children: [
      {
        path: "",
        name: "application",
        component: ContractsApplication,
        meta: {
          secured: true,
        },
      },
      {
        path: "reporting",
        name: "applicationReporting",
        component: ReportingApplication,
        props: (route) => {
          const query = route.query as unknown as Dictionary<string>;
          const filters = {
            from: "one_hour_ago",
            to: "now",
            apis: [],
            status: [],
            methods: [],
            numHits: 100,
          };

          for (const filterName of ["from", "to"]) {
            if (!isNaN(Date.parse(query[filterName]))) {
              filters[filterName] = new Date(query[filterName]);
            }
          }

          for (const filterName of ["apis", "status", "methods"]) {
            if (query[filterName] !== undefined && query[filterName] !== "") {
              filters[filterName] = query[filterName].split(",");
            }
          }

          if (
            query.numHits !== undefined &&
            query.numHits !== "" &&
            !isNaN(Number(query.numHits))
          ) {
            filters.numHits = Math.floor(Number(query.numHits));
          }

          return { filters, id: route.params.id };
        },
        meta: {
          secured: true,
        },
      },
      {
        path: "settings",
        name: "applicationSettings",
        component: ApplicationSettings,
        props: true,
        meta: {
          secured: true,
        },
      },
      {
        path: "contracts/:contractId/delete-token/:tokenId",
        component: ContractsApplication,
        props: true,
        name: "deleteToken",
        meta: {
          secured: true,
          realm: "dashboard",
        },
      },
    ],
  },
];

export default routes;
