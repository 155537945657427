<template>
  <div class="checkbox-filter">
    <div v-if="title" class="checkbox-filter__title">
      {{ title }}
    </div>
    <MCheckbox
      :id="`checkbox-filter-${label}`"
      v-model="selectedItem"
      :label="label"
      :disabled="isLoading"
    />
  </div>
</template>

<script lang="ts">
import MCheckbox from "@mozaic-ds/vue-3/src/components/checkbox/MCheckbox.vue";

import { changeUrlQuery } from "@/commons/utils/route-utils";

export default {
  name: "CheckboxFilter",
  components: {
    MCheckbox,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: undefined,
    },
    queryParamName: {
      type: String,
      required: true,
    },
    queryParamValue: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selectedItem: {
      get() {
        return this.queryParamValue;
      },
      set(value: boolean) {
        changeUrlQuery({
          [this.queryParamName]: value,
          page: 1,
        });
      },
    },
  },
};
</script>

<style lang="scss">
.checkbox-filter__title {
  @include set-text-s;
  margin-bottom: 1rem;
  color: var(--color-text-secondary);
}
</style>
