import RequestManager from "@/request-manager/views/RequestManager.vue";

import { Dictionary } from "@/commons/domain/models/dictionary";

import { ERequestFilteringVisibility } from "@/commons/store/types";

export interface RequestSummaryListUrlQuery {
  types: string[];
  statuses: string[];
  visibility: ERequestFilteringVisibility;
  page: number;
  size: number;
  requestId?: string;
}

const routes = [
  {
    path: "",
    name: "requestManager",
    component: RequestManager,
    meta: {
      title: "Request Manager",
      secured: true,
      realm: "requestManager",
    },
    props: (route) => {
      const query = route.query as unknown as Dictionary<string>;
      const urlQuery: RequestSummaryListUrlQuery = {
        types: query.types ? query.types.split(",") : [],
        statuses: query.statuses ? query.statuses.split(",") : [],
        visibility:
          ERequestFilteringVisibility[query.visibility] ||
          ERequestFilteringVisibility.RECEIVED,
        page: query.page !== undefined ? Number(query.page) : 1,
        size: query.size !== undefined ? Number(query.size) : 10,
        requestId: query.requestId,
      };

      return { urlQuery };
    },
  },
];

export default routes;
