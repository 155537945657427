import { RequestHandlerAxios } from "@/commons/request-handlers/request-handler-axios";

import { Inject } from "@/commons/domain/di/inject";

import { TYPES } from "@/types";

export class HttpRepository {
  @Inject(TYPES.REQUEST_HANDLER)
  protected requestHandler: RequestHandlerAxios;
}
