<template>
  <div v-if="displayPanel" class="cookie-banner">
    <div>
      <h3>{{ contents.titleUsingCookies }}</h3>
      <h5>{{ contents.whyUsingCookiesTitle }}</h5>
      <p>{{ contents.whyUsingCookies }}</p>
      <h5>{{ contents.whatIsCookiesTitle }}</h5>
      <p>{{ contents.whatIsCookie }}</p>
    </div>
    <!-- ACTIONS -->
    <div class="cookie-banner__btn-action gap-800">
      <button class="btn" @click="showPersonalizeCookies">
        {{ contents.btnPersonalizeCookies }}
      </button>
      <div v-if="!showPersonalize" class="btn-action--immediate">
        <button class="btn" @click="refuseAllCookies()">
          {{ contents.btnRefuseAllCookies }}
        </button>
        <button class="btn btn-primary" @click="acceptAllCookies()">
          {{ contents.btnAcceptAllCookies }}
        </button>
      </div>
    </div>
    <!-- ABOUT COOKIES -->
    <div v-if="showPersonalize" class="gap-1000">
      <ExpansionPanel
        v-for="(cat, index) in cookieCategories"
        :key="`${cat.type}-${index}`"
        :title="cat.title"
        @toggleValue="changeAllowedConfigurableCookies(cat.type, $event.value)"
      >
        {{ cat.content }}
        <CookieArray :data="cat.cookiesDetails" class="gap-400" />
      </ExpansionPanel>

      <div class="pushed-right">
        <button class="btn btn-primary" @click="updateCookies()">
          {{ contents.btnAcceptCookies }}
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  LOCAL_STORAGE_COOKIE_CONSENT_EXPIRY_KEY,
  LOCAL_STORAGE_COOKIE_CONSENT_KEY,
} from "./CookieBannerConstants";
import contents from "./cookie-contents";
import { cookieCategories } from "./cookies-banner";
import {
  applyCookieAcceptance,
  getConfigurableCookieAcceptanceByCategoryType,
  hasCookieAcceptance,
} from "./cookies-utils";

import CookieArray from "./CookieArray.vue";
import ExpansionPanel from "./ExpansionPanel.vue";

export default {
  components: { ExpansionPanel, CookieArray },
  emits: ["cookieIsReady"],
  data() {
    return {
      contents,
      showPersonalize: false,
      cookieCategories,
      acceptanceByCategory: getConfigurableCookieAcceptanceByCategoryType(),
      displayPanel: !hasCookieAcceptance(),
    };
  },
  mounted() {
    if (hasCookieAcceptance()) {
      this.notifyCookieIsReady();
    }
  },
  methods: {
    onClose() {
      localStorage.setItem(LOCAL_STORAGE_COOKIE_CONSENT_KEY, "true");
      const today = new Date();
      let expiry = new Date() as any;
      expiry.setYear(today.getFullYear() + 1);
      localStorage.setItem(LOCAL_STORAGE_COOKIE_CONSENT_EXPIRY_KEY, expiry);
      this.displayPanel = false;
    },
    showPersonalizeCookies() {
      this.showPersonalize = !this.showPersonalize;
    },
    acceptAllCookies() {
      this.updateAllWithValue(true);

      this.updateCookies();
    },
    refuseAllCookies() {
      this.updateAllWithValue(false);

      this.updateCookies();
    },
    updateAllWithValue(value: boolean) {
      for (const cookieCategory in this.acceptanceByCategory) {
        this.acceptanceByCategory[cookieCategory] = value;
      }
    },
    updateCookies() {
      applyCookieAcceptance(this.acceptanceByCategory);

      this.notifyCookieIsReady();
      this.onClose();
    },
    changeAllowedConfigurableCookies(categoryType, isAccepted) {
      this.acceptanceByCategory[categoryType] = isAccepted;
    },
    notifyCookieIsReady() {
      this.$emit("cookieIsReady");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/commons/assets/styles/vars";

.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: var(--z-index-foreground);
  width: 100%;
  padding: 3rem 10rem;
  color: var(--color-text);
  background-color: var(--color-white);
  background-image: url("./panda-cookies.png");
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 10rem;
  box-shadow: 0 0 20px var(--color-text);
}

.cookie-banner h3,
.cookie-banner h5 {
  margin-top: 0;
}

.cookie-banner__btn-action {
  display: flex;
  justify-content: space-between;
}

.cookie-banner .btn-action--immediate {
  display: flex;
}

.cookie-banner .btn-action--immediate button {
  margin-left: 1rem;
}

@media screen and (width <= 575px) {
  .cookie-banner {
    padding: 1rem 2rem;
    background-image: none;
  }

  .cookie-banner__btn-action,
  .btn-action--immediate {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .cookie-banner .btn-action--immediate button {
    margin-left: 0;
  }

  .cookie-banner button {
    margin-bottom: 1rem;
  }
}
</style>
