<template>
  <h1 class="title-animated">
    <slot />
  </h1>
</template>

<script lang="ts">
export default {
  name: "TitleAnimated",
  props: {
    emphaseWords: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      textAnimation: false,
      currentTextIndex: 0,
    };
  },
  mounted() {
    this.turnText();
    this.textAnimation = true;
  },
  unmounted() {
    this.textAnimation = false;
  },
  methods: {
    turnText() {
      const animatedText = this.$el.querySelector("span");

      Object.assign(animatedText.style, {
        opacity: "0",
        transform: "translateY(-50px)",
        "font-weight": "bold",
        color: "var(--color-white)",
      });

      if (this.currentTextIndex < this.emphaseWords.length - 1) {
        this.currentTextIndex++;
      } else {
        this.currentTextIndex = 0;
      }

      setTimeout(() => {
        animatedText.innerHTML = this.emphaseWords[this.currentTextIndex];
        Object.assign(animatedText.style, {
          opacity: "1",
          transform: "translateY(0)",
          "font-weight": "bold",
          color: "var(--color-white)",
        });

        if (this.textAnimation) {
          setTimeout(() => this.turnText(), 3000);
        }
      }, 300);
    },
  },
};
</script>

<style scoped>
.title-animated {
  margin-bottom: 2rem;
  font-size: 3.71rem;
  font-weight: 200;
  line-height: 4.57rem;
}
</style>
