module.exports = {
  request: "request",
  windowLengthUnit: {
    SECONDS: "second",
    MINUTES: "minute",
    HOURS: "hour",
    DAYS: "day",
  },
  windowLengthUnitShortLabel: {
    SECONDS: "s",
    MINUTES: "m",
    HOURS: "h",
    DAYS: "d",
  },
  noQuotaLimit: "No quota limit",
  rateLimitLabel: (requestsCountLabel, windowLengthUnitLabel) =>
    `${requestsCountLabel} every ${windowLengthUnitLabel}`,
  rateLimitShortLabel: (requestsCountLabel, windowLengthUnitLabel) =>
    `${requestsCountLabel}/${windowLengthUnitLabel}`,
};
