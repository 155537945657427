import { RequestSummaryListUrlQuery } from "@/request-manager/routes";

import { RequestFetchParams } from "@/commons/domain/repositories/request-summary-repository";

export function buildFetchParams({
  page,
  size,
  types,
  statuses,
  visibility,
  requestId,
}: RequestSummaryListUrlQuery): RequestFetchParams {
  return {
    page,
    size,
    types: types.join(","),
    statuses: statuses.join(","),
    filteringVisibility: visibility,
    requestId: requestId || undefined,
  };
}
