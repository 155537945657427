import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Markdown = _resolveComponent("Markdown")!
  const _component_MTextInput = _resolveComponent("MTextInput")!
  const _component_DvpField = _resolveComponent("DvpField")!
  const _component_LayerModalFormConfirm = _resolveComponent("LayerModalFormConfirm")!

  return (_openBlock(), _createBlock(_component_LayerModalFormConfirm, {
    class: "switch-api-deprecation-modal",
    disabledConfirmBtn: !!$data.form.errors.length || $options.isSaving,
    onSubmit: $options.submit,
    onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('onClose')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("h4", null, _toDisplayString($options.title), 1),
      _createVNode(_component_Markdown, {
        content: $data.contents.prompt($options.currentApiName)
      }, null, 8, ["content"]),
      _createVNode(_component_DvpField, {
        label: $data.contents.confirmApiNameLabel
      }, {
        default: _withCtx(() => [
          _createVNode(_component_MTextInput, {
            modelValue: $data.form.text,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.form.text) = $event)),
            placeholder: $options.currentApiName,
            isInvalid: $options.isInvalidConfirmationApiName
          }, null, 8, ["modelValue", "placeholder", "isInvalid"])
        ]),
        _: 1
      }, 8, ["label"])
    ]),
    _: 1
  }, 8, ["disabledConfirmBtn", "onSubmit"]))
}