import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "settings-form__title" }
const _hoisted_2 = { class: "pushed-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Chip = _resolveComponent("Chip")!
  const _component_Cluster = _resolveComponent("Cluster")!
  const _component_AutoCompleteSelect = _resolveComponent("AutoCompleteSelect")!
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_TextArea = _resolveComponent("TextArea")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { class: "settings-form" }, {
    default: _withCtx(() => [
      _createElementVNode("h4", null, [
        _createVNode(_component_Cluster, { "cluster-to-right": "" }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_1, _toDisplayString($options.apiRestTypeLabel) + " " + _toDisplayString($data.contents.settings), 1),
            ($props.currentApi.isDeprecated)
              ? (_openBlock(), _createBlock(_component_Chip, {
                  key: 0,
                  class: "settings-form__deprecated"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString($data.contents.deprecated), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("form", {
        autocomplete: "false | random-string",
        class: "settings-form__wrapper",
        onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => ($options.submit && $options.submit(...args)), ["prevent"]))
      }, [
        _createElementVNode("div", null, [
          _createVNode(_component_FormInput, {
            tooltip: $data.contents.productsComeFromTangram,
            label: $data.contents.relatedProduct,
            error: $data.form.firstError('product')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AutoCompleteSelect, {
                id: "appRelatedProduct",
                modelValue: $props.currentApi.product,
                disabled: $options.isLoading,
                options: $data.productsList,
                placeholder: $data.contents.applicationProductLabel,
                "key-property": "id",
                "label-property": "name",
                "onUpdate:modelValue": $options.selectProduct,
                onSearchChange: $options.searchProduct
              }, null, 8, ["modelValue", "disabled", "options", "placeholder", "onUpdate:modelValue", "onSearchChange"])
            ]),
            _: 1
          }, 8, ["tooltip", "label", "error"]),
          _createVNode(_component_FormInput, {
            class: "split-after",
            label: $data.contents.description,
            error: $data.form.defaultError('description')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_TextArea, {
                modelValue: $data.form.description,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.form.description) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["label", "error"])
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_MButton, {
            theme: "bordered-neutral",
            label: $data.contents.cancel,
            onClick: $options.cancel
          }, null, 8, ["label", "onClick"]),
          _createVNode(_component_MButton, {
            type: "submit",
            label: $data.contents.applyChanges,
            disabled: !!$data.form.errors.length || $data.form.pristine,
            onClick: $options.submit
          }, null, 8, ["label", "disabled", "onClick"])
        ])
      ], 32)
    ]),
    _: 1
  }))
}