import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "top-navbar-empty" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createVNode(_component_RouterLink, {
      to: { name: 'home' },
      title: "Home"
    }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          class: "top-navbar-empty__logo",
          src: $data.logo
        }, null, 8, _hoisted_2)
      ]),
      _: 1
    })
  ]))
}