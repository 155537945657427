<template>
  <svg
    version="1.1"
    class="icon-back"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="icon-back"
    viewBox="0 -3 30 30"
  >
    <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
  </svg>
</template>
