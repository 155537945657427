import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "layout" }
const _hoisted_2 = {
  key: 1,
  class: "layout__wrapper"
}
const _hoisted_3 = {
  key: 0,
  class: "layout__side-menu"
}
const _hoisted_4 = {
  key: 0,
  class: "layout__side-menu-header"
}
const _hoisted_5 = { class: "layout__side-menu-content" }
const _hoisted_6 = { class: "layout__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopNavbar = _resolveComponent("TopNavbar")!
  const _component_OverlayLoader = _resolveComponent("OverlayLoader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([
        'layout__navbars',
        { 'layout__navbars--highlighted': !$data.isBegginingOfScreen },
        $data.navbarsElementClass,
      ])
    }, [
      _createVNode(_component_TopNavbar),
      _renderSlot(_ctx.$slots, "secondaryNavbar")
    ], 2),
    ($props.isLoading)
      ? (_openBlock(), _createBlock(_component_OverlayLoader, {
          key: 0,
          class: "layout__overlay-loader",
          message: $props.loadingMessage
        }, null, 8, ["message"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (!$props.hideSideMenu && _ctx.$slots.sideMenu != undefined)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_ctx.$slots.sideMenuHeader != undefined)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _renderSlot(_ctx.$slots, "sideMenuHeader")
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_5, [
                  _renderSlot(_ctx.$slots, "sideMenu")
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_6, [
            _renderSlot(_ctx.$slots, "default")
          ])
        ]))
  ]))
}