import { CustomQueryParamForm } from "./custom-query-param-form";
import { Validate, ValidateNested } from "class-validator";

import { ArrayOfForms } from "@/commons/domain/forms/array-of-forms";
import { Form } from "@/commons/domain/forms/form";
import { FormFactory } from "@/commons/domain/forms/form-factory";
import { CustomQueryParam } from "@/commons/domain/models/custom-query-param";
import { FieldValueIsUniqueInList } from "@/commons/forms/class-validator/custom-validators/field-value-is-unique-in-list";

export class CustomQueryParamsListForm extends ArrayOfForms<CustomQueryParamForm> {
  @Validate(FieldValueIsUniqueInList, ["name", "values"], {
    message: "You are trying to add an already existing custom query parameter",
    each: true,
  })
  @ValidateNested()
  values: Form<CustomQueryParamForm>[] = [];

  @Validate(FieldValueIsUniqueInList, ["name", "values"], {
    message: "You are trying to add an already existing custom query parameter",
  })
  newValue: Form<CustomQueryParamForm> = undefined;

  constructor(customQueryParamsList: {
    values: CustomQueryParam[];
    newValue?: CustomQueryParam;
  }) {
    super();
    this.values = this.createCustomQueryParamsForms(
      customQueryParamsList.values,
    );
    this.newValue = this.createCustomQueryParamForm(
      customQueryParamsList.newValue,
    );
  }

  $update(
    partial: Partial<{
      values: CustomQueryParam[];
      newValue: CustomQueryParam;
    }>,
  ) {
    const { values, newValue } = partial;

    if (values) {
      this.values = this.createCustomQueryParamsForms(partial.values);
    }

    if (newValue) {
      this.newValue.update(newValue);
    }
  }

  private createCustomQueryParamsForms(values: CustomQueryParam[]) {
    const customQueryParamsForms = [];

    for (const customQueryParam of values) {
      customQueryParamsForms.push(
        this.createCustomQueryParamForm(customQueryParam),
      );
    }

    return customQueryParamsForms;
  }

  private createCustomQueryParamForm(customQueryParam?: CustomQueryParam) {
    return CustomQueryParamForm.create(customQueryParam);
  }

  static create(values: CustomQueryParam[] = [], newValue?: CustomQueryParam) {
    return FormFactory.createForm(
      new CustomQueryParamsListForm({ values, newValue }),
    );
  }
}
