import { AxiosResponse } from "axios";

import { HttpRepository } from "@/commons/repositories/libs/http-repository";

import { Injectable } from "@/commons/domain/di/injectable";
import {
  ProductFetchParams,
  ProductRepository,
} from "@/commons/domain/repositories/product-repository";
import { PagedResourceDto } from "@/commons/dtos/paged-resource-dto";
import { ProductDto } from "@/commons/dtos/product-dto";
import { ProductMapper } from "@/commons/mappers/product-mapper";

@Injectable()
export class ProductHttpRepository
  extends HttpRepository
  implements ProductRepository
{
  public async findById(productId: string) {
    const response = await this.requestHandler.get<ProductDto>(
      `/products/${productId}`,
    );
    return ProductMapper.toProductDomain(response.data);
  }

  public async findByIds(productsIds: string[]) {
    const promises: Promise<AxiosResponse<ProductDto>>[] = [];

    for (const id of productsIds) {
      promises.push(this.requestHandler.get<ProductDto>(`/products/${id}`));
    }

    const responses = await Promise.all(promises);

    return responses.map((response) =>
      ProductMapper.toProductDomain(response.data),
    );
  }

  public async find(params: ProductFetchParams = {}) {
    const response = await this.requestHandler.get<
      PagedResourceDto<ProductDto>
    >(`/products`, { params });

    return ProductMapper.toPagedDomain(response.data);
  }

  public async cancelAndFind(params: ProductFetchParams) {
    const response = await this.requestHandler.cancelAndGet<
      PagedResourceDto<ProductDto>
    >(`/products`, { params });
    return ProductMapper.toPagedDomain(response.data);
  }
}
