import { AccessPolicyPendingRequestMapper } from "./access-policy-pending-request-mapper";
import { AclMapper } from "./acl-mapper";
import { CorsMapper } from "./cors-mapper";
import { CustomProxyHeadersMapper } from "./custom-proxy-headers-mapper";
import { CustomQueryParamsMapper } from "./custom-query-params-mapper";
import { PoliciesOrderMapper } from "./policies-order-mapper";
import { RateLimitsMapper } from "./rate-limits-mapper";
import { RoutesMapper } from "./routes-mapper";
import { TagsMapper } from "./tags-mapper";
import { ZonesWithRoutesMapper } from "./zones-with-routes-mapper";

import { Api } from "@/commons/domain/models/api";
import { Dictionary } from "@/commons/domain/models/dictionary";
import { PagedResource } from "@/commons/domain/models/paged-resource";
import { PayloadUpdateApi } from "@/commons/domain/models/payload-update-api";
import { ApiDto } from "@/commons/dtos/api-dto";
import { CreateApiDto } from "@/commons/dtos/create-api-dto";
import { PagedResourceDto } from "@/commons/dtos/paged-resource-dto";
import { UpdateApiDto } from "@/commons/dtos/update-api-dto";
import { BlackListPolicyMapper } from "@/commons/mappers/black-list-policy-mapper";

import { EAccessPolicy } from "@/commons/store/types";

export class ApiMapper {
  static toDomain(apisDtosPaged: PagedResourceDto<ApiDto>) {
    const apis: Dictionary<Api> = {};

    for (const apiDto of apisDtosPaged.data) {
      const api = ApiMapper.toApiDomain(apiDto);
      apis[api.id] = api;
    }

    return apis;
  }

  static toPagedDomain(
    apisDtosPaged: PagedResourceDto<ApiDto>,
  ): PagedResource<Api> {
    return {
      count: apisDtosPaged.count,
      currentPage: apisDtosPaged.currentPage,
      data: ApiMapper.toDomain(apisDtosPaged),
      totalCount: apisDtosPaged.totalCount,
      totalPages: apisDtosPaged.totalPages,
    };
  }

  static toApiDomain(apiDto: ApiDto): Api {
    const product =
      (apiDto.product && {
        id: apiDto.product.id,
        name: apiDto.product.name,
      }) ||
      null;

    return {
      id: apiDto.id,
      name: apiDto.name,
      createdAt: apiDto.createdAt,
      updatedAt: apiDto.updatedAt,
      description: apiDto.description,
      accessPolicy: apiDto.accessPolicy,
      privacy: apiDto.privacy,
      version: apiDto.version,
      authType: apiDto.authType,
      oauthFlows: apiDto.oauthFlows,
      oauthDefinitions: apiDto.oauthDefinitions,
      oauthScopes: apiDto.oauthScopes,
      label: apiDto.label,
      deprecationDate: apiDto.deprecationDate || null,
      type: apiDto.type,
      subtype: apiDto.subtype,
      product,
      routes: apiDto.routes ? RoutesMapper.toDomain(apiDto.routes) : {},
      routeEntryPoints: {},
      cors: (apiDto.cors && CorsMapper.toDomain(apiDto.cors)) || null,
      customProxyHeaders: CustomProxyHeadersMapper.toDomain(
        apiDto.customHeaders,
      ),
      customQueryParams: CustomQueryParamsMapper.toDomain(
        apiDto.customQueryParams,
      ),
      policiesOrder: PoliciesOrderMapper.toDomain(apiDto.policiesOrder),
      isDeprecated: apiDto.isDeprecated,
      zones: ZonesWithRoutesMapper.toDomain(apiDto.zones),
      rateLimits: RateLimitsMapper.toDomain(apiDto.rateLimits),
      rateLimitsForNewContracts: RateLimitsMapper.toDomain(
        apiDto.rateLimitsForNewContracts,
      ),
      currentUserActions: apiDto.currentUserActions,
      isValidationRequired: apiDto.isValidationRequired,
      allowedActions: apiDto.allowedActions,
      publicationStatus: apiDto.publicationStatus,
      tags: TagsMapper.toApiDomain(apiDto.tags),
      acl: AclMapper.toDomain(apiDto.acl),
      accessPolicyPendingRequest: AccessPolicyPendingRequestMapper.toDomain(
        apiDto.pendingRequests,
      ),
      isOnDemandAccess: ["ON_DEMAND"].includes(apiDto.accessPolicy),
      timeoutInSeconds: apiDto.timeout,
      deployedZoneNames: apiDto.deployedZoneNames,
      internal: apiDto.internal,
      reportingUrl: apiDto.reportingUrl,
      documentationDetails: apiDto.documentationDetails,
      quotaType: apiDto.quotaType,
      blackListPolicies: apiDto.blackListPolicies
        ? BlackListPolicyMapper.toDomain(apiDto.blackListPolicies)
        : [],
      nextPublish: apiDto.nextPublish,
      isAlreadySubscribed: apiDto.isAlreadySubscribed,
      hasPendingRequestForAuthTypeChange:
        apiDto.hasPendingRequestForAuthTypeChange,
      isIPFilteringEnabled: apiDto.isIPFilteringEnabled,
      zonesWithPublishedRoutes: apiDto.zonesWithPublishedRoutes,
    };
  }

  static toCreateApiDto({
    name,
    type,
    version,
    productId,
    description,
    tagsIds,
    groupId,
    internal,
  }): CreateApiDto {
    return {
      name,
      description,
      type,
      version,
      productId: productId !== "" ? productId : null,
      accessPolicy: EAccessPolicy.PRIVATE,
      tags: tagsIds,
      groupId: groupId !== "" ? groupId : null,
      internal,
    };
  }

  static toUpdateApiDto({
    description,
    productId,
    deprecationDate,
    buTagIds,
    customProxyHeaders,
    customQueryParams,
    policiesOrder,
    cors,
    privacy,
    accessPolicy,
    comment,
    timeout,
    oauthScopes,
  }: PayloadUpdateApi): UpdateApiDto {
    return {
      description,
      productId,
      deprecationDate,
      buTagIds,
      customHeaders:
        customProxyHeaders &&
        CustomProxyHeadersMapper.toDto(customProxyHeaders),
      customQueryParams:
        customQueryParams && CustomQueryParamsMapper.toDto(customQueryParams),
      policiesOrder: policiesOrder && PoliciesOrderMapper.toDto(policiesOrder),
      cors: cors && CorsMapper.toDto(cors),
      privacy,
      accessPolicy,
      comment,
      timeout,
      oauthScopes,
    };
  }
}
