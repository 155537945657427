export default {
  title: "Route Blacklisting",
  subtitle:
    "You can prevent access to an endpoint. You just have to blacklist it by specifying its pattern.",
  routePatternColumnName: "ROUTE PATTERN",
  methodsColumnName: "METHODS",
  actionsColumnName: "ACTIONS",
  buttonTitle: "Blacklist a new pattern",
  createOrUpdateBlackListLayerTitle: "Route Blacklisting",
  deleteBlackListLayerTitle: "Delete blacklisted route",
  deleteButtonLabel: "Delete",
  deletePlaceholder: "Type DELETE",
  addButtonLabel: "Add",
  updateButtonLabel: "Update",
};
