<script setup lang="ts">
import { computed, PropType } from "vue";

import IconGroup from "@/commons/components/Icons/IconGroup.vue";

import { Group } from "@/commons/domain/models/group";

const props = defineProps({
  group: {
    type: Object as PropType<Group>,
    required: true,
  },
  usersNumber: {
    type: Number,
    required: true,
  },
});

const groupTitle = computed(() => `${props.group.name} (${props.usersNumber})`);
</script>

<template>
  <div class="group-title-with-icon-and-users-number">
    <IconGroup :provider="group.provider" />
    <h5>{{ groupTitle }}</h5>
  </div>
</template>

<style lang="scss">
.group-title-with-icon-and-users-number {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
</style>
