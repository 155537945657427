<template>
  <div class="adornment">
    <span v-if="!!$slots.prefix" class="adornment__prefix">
      <slot name="prefix" />
    </span>
    <slot />
    <span v-if="!!$slots.suffix" class="adornment__suffix">
      <slot name="suffix" />
    </span>
  </div>
</template>

<script lang="ts">
export default {
  name: "Adornment",
};
</script>

<style lang="scss" scoped>
.adornment {
  display: flex;
  width: 100%;
}
.adornment__prefix,
.adornment__suffix {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  background-color: var(--color-light-grey);
}
.adornment__prefix {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.adornment__suffix {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
</style>
