<template>
  <CardContainer :id="anchorId" class="product-apis-list">
    <h3>{{ title }}</h3>
    <div v-if="apis && apis.length > 0">
      <div class="product-apis-list__body">
        <CardApiCatalog
          v-for="api of apisList"
          :key="api.id"
          class="product-apis-list__api-card"
          :api="api"
          hideProduct
        />
      </div>
      <MPagination
        v-if="apisTotalPages > 1"
        class="product-apis-list__pagination"
        :length="apisTotalPages"
        :value="currentPage"
        @on-update-page="changePage"
      />
    </div>
    <div v-else>
      <span>{{ noItemsMessage }}</span>
    </div>
  </CardContainer>
</template>

<script lang="ts">
import MPagination from "@mozaic-ds/vue-3/src/components/pagination/MPagination.vue";
import { PropType } from "vue";

import CardContainer from "@/commons/components/CardContainer.vue";
import CardApiCatalog from "@/commons/components/CardListed/CardApiCatalog.vue";

import { scrollToAnchor } from "@/commons/libs/scroll-to-anchor";

import { Api } from "@/commons/domain/models/api";

export default {
  components: {
    CardApiCatalog,
    CardContainer,
    MPagination,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    anchorId: {
      type: String,
      required: true,
    },
    apis: {
      type: Array as PropType<Api[]>,
      required: true,
    },
    apisPerPage: {
      type: Number,
      default: 12,
    },
    noItemsMessage: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  computed: {
    apisTotalPages() {
      return Math.ceil(this.apis.length / this.apisPerPage);
    },
    apisList() {
      const firstElementIndex = this.apisPerPage * (this.currentPage - 1);
      return this.apis.slice(
        firstElementIndex,
        firstElementIndex + this.apisPerPage,
      );
    },
  },
  methods: {
    changePage(newPage) {
      this.currentPage = newPage;
      scrollToAnchor(this.anchorId);
    },
  },
};
</script>

<style lang="scss">
.product-apis-list__body {
  display: flex;
  flex-wrap: wrap;
}

.product-apis-list__api-card {
  width: 34.5rem;
  margin: 1rem;
}

.product-apis-list__pagination {
  justify-content: center;
}
</style>
