import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "notification-for-kong-synchronization" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MLink = _resolveComponent("MLink")!
  const _component_MNotification = _resolveComponent("MNotification")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MNotification, {
      title: $data.contents.kongNotificationTitle($props.configurationType),
      type: "warning"
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_MLink, {
          size: "s",
          href: $options.routeHref,
          target: "_blank"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($data.contents.kongNotifLinkTitle), 1)
          ]),
          _: 1
        }, 8, ["href"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("p", null, _toDisplayString($data.contents.kongNotifSychronizeText), 1),
        _createElementVNode("p", null, _toDisplayString($data.contents.kongNotifContactText), 1)
      ]),
      _: 1
    }, 8, ["title"])
  ]))
}