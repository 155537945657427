<template>
  <svg
    id="Calque_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 17.9485493 10.2454062"
    style="enable-background: new 0 0 17.9485493 10.2454062"
    xml:space="preserve"
  >
    <g>
      <path
        d="M0.898766,0c0.2299578,0,0.4599155,0.0877701,0.6354557,0.2633103l7.4398336,7.4402728l7.4402723-7.4402728
		c0.3510818-0.3510805,0.9198322-0.3510805,1.2709122,0s0.3510799,0.9198309,0,1.2709113l-8.7111845,8.7111845L0.2633103,1.5342216
		c-0.3510805-0.3510804-0.3510805-0.9198308,0-1.2709113C0.4388506,0.0877701,0.6688083,0,0.898766,0z"
      />
    </g>
  </svg>
</template>
