import { FilterOption } from "@/commons/store/modules/reporting/types";

export function removeNonExistingOptions(
  selectedFiltersIds: string[],
  filterOptions: FilterOption[],
) {
  const filterOptionsIds = filterOptions.map((filterOption) => filterOption.id);
  return selectedFiltersIds.filter((selectedFilterId) =>
    filterOptionsIds.includes(selectedFilterId),
  );
}
