<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="35px" viewBox="0 0 42 42">
    <title>Empty content</title>
    <path
      fill="#bbbbbb"
      d="M32,20.43H29.49V18.72a1.6,1.6,0,0,0-1.6-1.6H23.17a1.6,1.6,0,0,0-1.6,1.6v1.71H20.48V18.72a1.6,1.6,0,0,0-1.59-1.6H14.16a1.6,1.6,0,0,0-1.6,1.6v1.71h-2.5A1.6,1.6,0,0,0,8.46,22v8.35a1.6,1.6,0,0,0,.18.72,1.91,1.91,0,0,0-.18.79v7.74a1.9,1.9,0,0,0,1.91,1.9H31.69a1.9,1.9,0,0,0,1.9-1.9V31.89a1.9,1.9,0,0,0-.17-.79,1.59,1.59,0,0,0,.17-.72V22A1.6,1.6,0,0,0,32,20.43Zm-8.43-1.31h3.92v1.31H23.57Zm-9,0h3.92v1.31H14.56Zm-4.1,3.31H31.59V30H10.46Zm21.13,17.1H10.46V32H31.59Z"
    />
    <path
      fill="#bbbbbb"
      d="M32,3.76H29.49V2.05a1.6,1.6,0,0,0-1.6-1.6H23.17a1.59,1.59,0,0,0-1.6,1.6V3.76H20.48V2.05A1.59,1.59,0,0,0,18.89.45H14.16a1.6,1.6,0,0,0-1.6,1.6V3.76h-2.5a1.6,1.6,0,0,0-1.6,1.59v8.36a1.6,1.6,0,0,0,1.6,1.6H32a1.6,1.6,0,0,0,1.59-1.6V5.35A1.59,1.59,0,0,0,32,3.76ZM23.57,2.45h3.92V3.76H23.57Zm-9,0h3.92V3.76H14.56Zm17,10.86H10.46V5.76H31.59Z"
    />
  </svg>
</template>
