<template>
  <a v-if="isExternalLink || !urlOrRoute" class="menu-link" :href="urlOrRoute"
    ><slot
  /></a>
  <RouterLink v-else class="menu-link" :to="urlOrRoute"><slot /></RouterLink>
</template>

<script lang="ts">
export default {
  props: {
    urlOrRoute: {
      type: [String, Object],
      default: null,
    },
    isExternalLink: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.menu-link {
  display: flex;
  align-items: center;
  height: 100%;
  text-decoration: none;
  white-space: nowrap;
}
</style>
