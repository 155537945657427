import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["custom-button flex align-center justify-content-center", {
      'is-dark': $props.dark,
      'with-border': $props.withBorder,
      'is-hover': $props.hover,
      'is-selected': $props.selected,
      round: $props.round,
    }]),
    tabindex: "-1",
    type: "button",
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('click')), ["stop"])),
    onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('focus'))),
    onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('blur'))),
    onMouseover: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('mouseover'))),
    onMouseleave: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('mouseleave')))
  }, [
    _createElementVNode("span", {
      style: _normalizeStyle([$options.bgStyle]),
      class: "custom-button-effect"
    }, null, 4),
    _createElementVNode("span", {
      class: "custom-button-content flex align-center justify-content-center",
      style: _normalizeStyle([$options.colorStyle])
    }, [
      _renderSlot(_ctx.$slots, "default", {
        style: _normalizeStyle([$options.colorStyle])
      }, undefined, true)
    ], 4)
  ], 34))
}