<template>
  <SecondaryNavbar class="api-settings-secondary-navbar">
    <template #secondary-navbar-header>
      <ApiInfoForNavbar :api="api" />
    </template>
    <template #secondary-navbar-body>
      <div class="api-settings-secondary-navbar__body">
        <ResponsiveIconButton
          class="api-settings-secondary-navbar__api-doc-link"
          theme="bordered-neutral"
          icon="BookStore24"
          size="s"
          :label="contents.goToApiDocumentation"
          :href="linkToApiDocumentation"
          :breakpointWidth="getCssBreakpointAsNumber('notebook')"
        />

        <ResponsiveIconButton
          v-if="isRestApi(api)"
          data-cy="reporting-button"
          :label="contents.goToReporting"
          icon="DisplayExternalLink24"
          theme="bordered-neutral"
          size="s"
          :href="linkToDatadogReporting"
          target="_blank"
        />

        <Divider class="api-settings-secondary-navbar__divider" vertical />

        <template v-if="api.isDeprecated">
          <ApiDeprecationInfoForNavbar :api="api" />
          <Divider vertical class="api-settings-secondary-navbar__divider" />
        </template>

        <ApiPublicationInfoForNavbar :api="api" />

        <Divider class="api-settings-secondary-navbar__divider" vertical />

        <ApiAccessPolicy v-if="api" :api="api" />
      </div>
    </template>
  </SecondaryNavbar>
</template>

<script lang="ts">
import { PropType } from "vue";

import ApiAccessPolicy from "@/commons/components/ApiAccessPolicy/ApiAccessPolicy.vue";
import Divider from "@/commons/components/Divider.vue";
import ApiDeprecationInfoForNavbar from "@/commons/components/Navbar/ApiSecondaryNavbar/ApiDeprecationInfoForNavbar.vue";
import ApiInfoForNavbar from "@/commons/components/Navbar/ApiSecondaryNavbar/ApiInfoForNavbar.vue";
import ApiPublicationInfoForNavbar from "@/commons/components/Navbar/ApiSecondaryNavbar/ApiPublicationInfoForNavbar.vue";
import SecondaryNavbar from "@/commons/components/Navbar/SecondaryNavbar.vue";
import ResponsiveIconButton from "@/commons/components/ResponsiveIconButton.vue";

import { isRestApi } from "@/commons/libs/utils/apiUtils";
import { getCssBreakpointAsNumber } from "@/commons/utils/css-breakpoints-utils";
import { convertRouteToHref } from "@/commons/utils/route-utils";

import { Api } from "@/commons/domain/models/api";

import { EApiPublicationStatus } from "@/commons/store/types";

import contents from "@/commons/contents/secondary-navbar-api";

export default {
  components: {
    ApiInfoForNavbar,
    ApiDeprecationInfoForNavbar,
    ApiPublicationInfoForNavbar,
    SecondaryNavbar,
    ApiAccessPolicy,
    ResponsiveIconButton,
    Divider,
  },
  props: {
    api: {
      type: Object as PropType<Api>,
      required: true,
    },
  },
  data() {
    return {
      contents,
    };
  },
  computed: {
    linkToDatadogReporting(): string {
      return this.api.reportingUrl;
    },
    linkToApiDocumentation(): string {
      return convertRouteToHref({
        name: "apiDocumentationDetail",
        params: { id: this.api.id },
      });
    },
  },
  created() {
    this.EApiPublicationStatus = EApiPublicationStatus;
  },
  methods: {
    getCssBreakpointAsNumber,
    isRestApi,
  },
};
</script>

<style lang="scss">
.api-settings-secondary-navbar__body {
  display: flex;
  align-items: center;
  height: 100%;
  column-gap: 1.25rem;
}

.api-settings-secondary-navbar__divider {
  height: 2rem;
}
</style>
