export function dragOrder(
  list: [{ order: number }],
  targetOrder: number,
  sourceOrder: number,
) {
  return list.map((item) => {
    const isOutbounds =
      (item.order < targetOrder && item.order < sourceOrder) ||
      (item.order > targetOrder && item.order > sourceOrder);

    if (item.order === sourceOrder) {
      item.order = targetOrder;
    } else if (!isOutbounds) {
      item.order += Math.sign(sourceOrder - targetOrder);
      item.order = Math.max(1, Math.min(item.order, list.length));
    }
    return item;
  });
}
