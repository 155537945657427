import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "policies"
}
const _hoisted_2 = { class: "pad-800" }
const _hoisted_3 = { class: "tabs-light pad-1200" }
const _hoisted_4 = { class: "tabs-menu" }
const _hoisted_5 = { class: "tab-btn" }
const _hoisted_6 = { class: "tab-btn" }
const _hoisted_7 = {
  key: 0,
  class: "tab-btn"
}
const _hoisted_8 = {
  key: 1,
  class: "tab-btn"
}
const _hoisted_9 = { class: "tab-btn" }
const _hoisted_10 = {
  key: 0,
  class: "tab-content"
}
const _hoisted_11 = {
  key: 1,
  class: "tab-content"
}
const _hoisted_12 = {
  key: 2,
  class: "tab-content"
}
const _hoisted_13 = {
  key: 3,
  class: "tab-content"
}
const _hoisted_14 = {
  key: 4,
  class: "tab-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomProxyHeaders = _resolveComponent("CustomProxyHeaders")!
  const _component_Cors = _resolveComponent("Cors")!
  const _component_CustomQueryParams = _resolveComponent("CustomQueryParams")!
  const _component_PoliciesOrder = _resolveComponent("PoliciesOrder")!
  const _component_Stack = _resolveComponent("Stack")!

  return (!$options.isLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Stack, null, {
          default: _withCtx(() => [
            _createElementVNode("h4", _hoisted_2, _toDisplayString($data.contents.policies), 1),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("ul", _hoisted_4, [
                _createElementVNode("li", _hoisted_5, [
                  _createElementVNode("a", {
                    class: _normalizeClass({
                current: $data.currentTab === $data.tabs.REQUEST_CUSTOM_HEADERS,
              }),
                    onClick: _cache[0] || (_cache[0] = ($event: any) => ($data.currentTab = $data.tabs.REQUEST_CUSTOM_HEADERS))
                  }, _toDisplayString($data.contents.requestHeaders) + " (" + _toDisplayString($options.numRequestCustomHeaders) + ") ", 3)
                ]),
                _createElementVNode("li", _hoisted_6, [
                  _createElementVNode("a", {
                    class: _normalizeClass({
                current: $data.currentTab === $data.tabs.RESPONSE_CUSTOM_HEADERS,
              }),
                    onClick: _cache[1] || (_cache[1] = ($event: any) => ($data.currentTab = $data.tabs.RESPONSE_CUSTOM_HEADERS))
                  }, _toDisplayString($data.contents.responseHeaders) + " (" + _toDisplayString($options.numResponseCustomHeaders) + ") ", 3)
                ]),
                ($options.allowQueryParamsTransformation)
                  ? (_openBlock(), _createElementBlock("li", _hoisted_7, [
                      _createElementVNode("a", {
                        class: _normalizeClass({
                current: $data.currentTab === $data.tabs.CUSTOM_QUERY_PARAMS,
              }),
                        onClick: _cache[2] || (_cache[2] = ($event: any) => ($data.currentTab = $data.tabs.CUSTOM_QUERY_PARAMS))
                      }, _toDisplayString($data.contents.queryParams) + " (" + _toDisplayString($options.numCustomQueryParams) + ") ", 3)
                    ]))
                  : _createCommentVNode("", true),
                ($options.allowTransformationPoliciesOrder)
                  ? (_openBlock(), _createElementBlock("li", _hoisted_8, [
                      _createElementVNode("a", {
                        class: _normalizeClass({
                current: $data.currentTab === $data.tabs.ORDER_POLICIES,
              }),
                        onClick: _cache[3] || (_cache[3] = ($event: any) => ($data.currentTab = $data.tabs.ORDER_POLICIES))
                      }, _toDisplayString($data.contents.policiesOrder) + " (" + _toDisplayString($options.numPoliciesOrder) + ") ", 3)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("li", _hoisted_9, [
                  _createElementVNode("a", {
                    class: _normalizeClass({
                current: $data.currentTab === $data.tabs.CORS,
              }),
                    onClick: _cache[4] || (_cache[4] = ($event: any) => ($data.currentTab = $data.tabs.CORS))
                  }, _toDisplayString($data.contents.cors), 3)
                ])
              ]),
              ($data.currentTab === $data.tabs.REQUEST_CUSTOM_HEADERS)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createVNode(_component_CustomProxyHeaders, {
                      "api-id": $options.apiId,
                      "custom-proxy-headers": $options.customRequestHeadersList,
                      type: $data.ECustomProxyHeaderType.REQUEST
                    }, null, 8, ["api-id", "custom-proxy-headers", "type"])
                  ]))
                : _createCommentVNode("", true),
              ($data.currentTab === $data.tabs.RESPONSE_CUSTOM_HEADERS)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _createVNode(_component_CustomProxyHeaders, {
                      "api-id": $options.apiId,
                      "custom-proxy-headers": $options.customResponseHeadersList,
                      type: $data.ECustomProxyHeaderType.RESPONSE
                    }, null, 8, ["api-id", "custom-proxy-headers", "type"])
                  ]))
                : _createCommentVNode("", true),
              ($data.currentTab === $data.tabs.CORS)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _createVNode(_component_Cors, {
                      "api-id": $options.apiId,
                      cors: $options.cors
                    }, null, 8, ["api-id", "cors"])
                  ]))
                : _createCommentVNode("", true),
              ($data.currentTab === $data.tabs.CUSTOM_QUERY_PARAMS)
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _createVNode(_component_CustomQueryParams, {
                      "api-id": $options.apiId,
                      "custom-query-params": $options.customQueryParams
                    }, null, 8, ["api-id", "custom-query-params"])
                  ]))
                : _createCommentVNode("", true),
              ($data.currentTab === $data.tabs.ORDER_POLICIES)
                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                    _createVNode(_component_PoliciesOrder, { "api-id": $options.apiId }, null, 8, ["api-id"])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}