export enum EHttpMethod {
  GET = "GET",
  HEAD = "HEAD",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  OPTIONS = "OPTIONS",
  TRACE = "TRACE",
  PATCH = "PATCH",
}

export const ORDERED_HTTP_METHODS: EHttpMethod[] = Object.values(EHttpMethod);

export function parseToEHttpMethod(
  stringValue: string,
): EHttpMethod | undefined {
  return EHttpMethod[stringValue.toUpperCase()];
}

export function parseListToEHttpMethods(list: string[]): EHttpMethod[] {
  return list.map(parseToEHttpMethod);
}

export function sortHttpMethods(
  givenHttpMethods: EHttpMethod[],
): EHttpMethod[] {
  return givenHttpMethods.sort(
    (a, b) => ORDERED_HTTP_METHODS.indexOf(a) - ORDERED_HTTP_METHODS.indexOf(b),
  );
}

export function parseAndSortHttpMethods(list: string[]): EHttpMethod[] {
  return sortHttpMethods(parseListToEHttpMethods(list));
}
