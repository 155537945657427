const { ERoutingPolicyType } = require("@/commons/store/types");

module.exports = {
  combineWithAnotherCondition: "Combine with an another condition",
  selectCondition: "Select condition",

  conditionTypeLabels: {
    [ERoutingPolicyType.HEADERS]: {
      option: "HEADER",
      keyPlaceholder: "Header key",
      valuePlaceholder: "Header value",
    },
    [ERoutingPolicyType.HTTP_METHODS]: {
      option: "METHOD",
      valuePlaceholder: "Select a method",
    },
    [ERoutingPolicyType.PATH]: {
      option: "PATH",
      valuePlaceholder: "/Path",
    },
  },
};
