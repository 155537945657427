import { ERouteStatus } from "@/commons/store/types";

export default {
  publishLabel: "Publish",
  deprecatedLabel: "Deprecated",
  messageForPastDeprecation: (formattedDate) =>
    `Deprecated since ${formattedDate}`,
  messageForFutureDeprecation: (formattedDate) =>
    `Expiring on ${formattedDate}`,
  publicationDateMessage: (formattedDate) => `Last deploy on ${formattedDate}`,
  accessUrls: "ACCESS URLS",
  accessUrlsMoreInfo:
    "These URLs are visible on your API documentation and are the ones that your API consumers will use",
  internetExposureLabel: "Accessible on the internet",
  internetExposureExplanation:
    "By default, your API is only available on a private route, accessible only from the ADEO network. Activate your API on the internet to share your API outside the ADEO network, with partners for example",
  ipFilteringLabel: "IP filtering",
  ipFilteringExplanation:
    "By default, all your API PUBLIC routes are callable from anywhere. By activating this feature, calls to your PUBLIC route in this zone will be restricted to IP Addresses declared by your consumers in their Application settings.",
  accessRestrictionWarningTitle: "Change your access restriction",
  accessRestrictionWarningText:
    "If you want to remove your API from internet, you must change your access restriction to private or internal.",
  accessRestrictionLinkLabel: "Modify access restriction",
  privateUrlTitle: "Private",
  publicUrlTitle: "Public",
  publicationStatus: {
    [ERouteStatus.ENABLED]: "enabled",
    [ERouteStatus.DISABLED]: "disabled",
    [ERouteStatus.ERROR]: "error",
    [ERouteStatus.PENDING_GATEWAY]: "pending",
  },
};
