import { getApplicationRepository } from "@/commons/repositories/libs/get-application-repository";

export async function getSecretSynchronizationRequestUrl(
  applicationId: string,
  namespace: string,
): Promise<string> {
  const response =
    await getApplicationRepository().getSecretSynchronizationRequestDetails(
      applicationId,
      namespace,
    );
  return response.requestUrl;
}

export async function enableSecretSynchronization(
  applicationId: string,
  namespace: string,
): Promise<string> {
  return await getApplicationRepository().enableSecretSynchronization(
    applicationId,
    namespace,
  );
}

export async function disableSecretSynchronization(
  applicationId: string,
): Promise<boolean> {
  return await getApplicationRepository().disableSecretSynchronization(
    applicationId,
  );
}

export async function forcedDisableSecretSynchronization(
  applicationId: string,
): Promise<void> {
  await getApplicationRepository().forcedDisableSecretSynchronization(
    applicationId,
  );
}
