import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "change-to-api-key-modal__content" }
const _hoisted_2 = { class: "change-to-api-key-modal__context-info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Markdown = _resolveComponent("Markdown")!
  const _component_MNotification = _resolveComponent("MNotification")!
  const _component_ConfirmModalBody = _resolveComponent("ConfirmModalBody")!

  return (_openBlock(), _createBlock(_component_ConfirmModalBody, {
    class: "change-to-api-key-modal",
    confirmBtnLabel: $data.contents.submitBtn,
    onOnSubmit: _cache[0] || (_cache[0] = ($event: any) => ($options.onSubmit())),
    onOnClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('onClose')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h5", _hoisted_2, _toDisplayString($options.aboutToChangeDescription), 1),
        _createElementVNode("div", null, [
          _createElementVNode("p", null, _toDisplayString($data.contents.newSubscriptionsMessage), 1),
          _createElementVNode("p", null, _toDisplayString($data.contents.impactWarning), 1)
        ]),
        ($props.displayDeleteSandboxesWarning)
          ? (_openBlock(), _createBlock(_component_MNotification, {
              key: 0,
              title: $data.contents.previousContractsRemovalNotificationTitle,
              type: "warning"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Markdown, {
                  content: 
            $data.contents.previousContractsRemovalNotificationMessage(
              $options.hrefLinkToApiConsumers,
            )
          
                }, null, 8, ["content"])
              ]),
              _: 1
            }, 8, ["title"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["confirmBtnLabel"]))
}