import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MButton = _resolveComponent("MButton")!
  const _component_LayerModalForm = _resolveComponent("LayerModalForm")!

  return (_openBlock(), _createBlock(_component_LayerModalForm, {
    "data-cy": "modal-form",
    class: "layer-modal-form-confirm"
  }, {
    fields: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    formFooter: _withCtx(() => [
      _createVNode(_component_MButton, {
        "data-cy": "cancel-button",
        type: "button",
        theme: $props.cancelBtnTheme,
        label: $props.cancelBtnLabel,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
      }, null, 8, ["theme", "label"]),
      (!$props.confirmBtnIsHidden)
        ? (_openBlock(), _createBlock(_component_MButton, {
            key: 0,
            "data-cy": "submit-button",
            type: "submit",
            theme: $props.confirmBtnTheme,
            label: $props.confirmBtnLabel,
            disabled: $props.disabledConfirmBtn,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('submit')))
          }, null, 8, ["theme", "label", "disabled"]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}