import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MIcon = _resolveComponent("MIcon")!
  const _component_MTooltip = _resolveComponent("MTooltip")!

  return (_openBlock(), _createBlock(_component_MTooltip, {
    id: $options.tooltipId,
    class: "icon-tooltip",
    position: $props.position,
    label: $props.text
  }, {
    default: _withCtx(() => [
      _createVNode(_component_MIcon, {
        name: $props.iconName,
        color: $props.iconColor
      }, null, 8, ["name", "color"])
    ]),
    _: 1
  }, 8, ["id", "position", "label"]))
}