import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "app-list" }
const _hoisted_2 = { class: "app-list__item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppListItemLoadingSkeleton = _resolveComponent("AppListItemLoadingSkeleton")!
  const _component_AppListEmptyItem = _resolveComponent("AppListEmptyItem")!
  const _component_AppListItem = _resolveComponent("AppListItem")!
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.isLoading)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(new Array(5), (item) => {
          return (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_AppListItemLoadingSkeleton)
          ]))
        }), 256))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          ($props.applications.length === 0)
            ? (_openBlock(), _createBlock(_component_AppListEmptyItem, { key: 0 }))
            : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList($props.applications, (application, index) => {
                return (_openBlock(), _createBlock(_component_RouterLink, {
                  class: "app-list__item",
                  key: index,
                  to: $options.getAppLink(application.id)
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_AppListItem, { application: application }, null, 8, ["application"])
                  ]),
                  _: 2
                }, 1032, ["to"]))
              }), 128))
        ], 64))
  ]))
}