import { HttpRepository } from "@/commons/repositories/libs/http-repository";

import { Injectable } from "@/commons/domain/di/injectable";
import { RequestSummary } from "@/commons/domain/models/request-summary";
import {
  RequestFetchParams,
  RequestSummaryRepository,
} from "@/commons/domain/repositories/request-summary-repository";
import { PagedResourceDto } from "@/commons/dtos/paged-resource-dto";
import { RequestSummaryDto } from "@/commons/dtos/request-summary-dto";
import { RequestSummaryMapper } from "@/commons/mappers/request-summary-mapper";

import { ERequestType } from "@/commons/store/types";

@Injectable()
export class RequestSummaryHttpRepository
  extends HttpRepository
  implements RequestSummaryRepository
{
  public async findById(requestId: string) {
    const response = await this.requestHandler.get<RequestSummaryDto>(
      `/requests/${requestId}`,
    );

    return RequestSummaryMapper.toRequestSummaryDomain(response.data);
  }

  public async findRequests(params: RequestFetchParams) {
    const response = await this.requestHandler.get<
      PagedResourceDto<RequestSummaryDto>
    >(`/requests`, { params });

    return RequestSummaryMapper.toPagedResource(response.data);
  }

  public async approveRequest(requestId: string, params: RequestFetchParams) {
    await this.requestHandler.post<RequestSummaryDto>(
      `/requests/${requestId}/_approve`,
    );

    return this.findRequests(params);
  }

  public async denyRequest(requestId: string, params: RequestFetchParams) {
    await this.requestHandler.post<RequestSummaryDto>(
      `/requests/${requestId}/_deny`,
    );

    return this.findRequests(params);
  }

  public async cancelRequest(requestId: string, params: RequestFetchParams) {
    await this.requestHandler.post<RequestSummaryDto>(
      `/requests/${requestId}/_cancel`,
    );

    return this.findRequests(params);
  }

  public async findRequestTypes(): Promise<ERequestType[]> {
    const response =
      await this.requestHandler.get<ERequestType[]>(`/requests/types`);

    return response.data;
  }

  public async findPendingRequestOfType(
    targetId: string,
    type: ERequestType,
  ): Promise<RequestSummary | undefined> {
    const response = await this.requestHandler.get<
      RequestSummaryDto | undefined
    >(`/request/pending`, {
      params: {
        targetId,
        type,
      },
    });

    return RequestSummaryMapper.toRequestSummaryDomain(response.data);
  }
}
