<template>
  <div class="card-wrapper bg-white"><slot /></div>
</template>

<script lang="ts">
export default {
  name: "Card",
};
</script>

<style lang="scss">
.card-wrapper {
  display: flex;
  flex-direction: column;
  padding: 40px;
  border-radius: var(--m-border-radius-medium);
  box-shadow: 0 2px 10px rgb(0 0 0 / 20%);
}

.card-wrapper > :last-child {
  flex-grow: 1;
}

.card-wrapper > h4 {
  margin-top: 0;
}
</style>
