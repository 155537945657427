<template>
  <Grid class="contract-list">
    <template v-if="!isLoading">
      <ContractCardWrapper
        v-for="contract in contracts"
        :key="contract.id"
        :contract="contract"
        @contractChanged="$emit('contractChanged')"
      />
    </template>

    <template v-else>
      <ContractLoadingSkeleton
        v-for="(item, index) in new Array(8)"
        :key="`contract-loading-skeleton-${index}`"
      />
    </template>
  </Grid>
</template>

<script lang="ts">
import { PropType } from "vue";

import Grid from "@/commons/components/Grid.vue";
import ContractCardWrapper from "@/dashboard/views/ContractsList/ContractCardWrapper.vue";
import ContractLoadingSkeleton from "@/dashboard/views/ContractsList/ContractLoadingSkeleton.vue";

import { Contract } from "@/commons/domain/models/contract";

export default {
  components: {
    ContractCardWrapper,
    Grid,
    ContractLoadingSkeleton,
  },
  props: {
    contracts: {
      type: Array as PropType<Contract[]>,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["contractChanged"],
};
</script>

<style lang="scss">
.contract-list {
  --grid-auto-rows: auto;
}
</style>
