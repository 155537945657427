export default {
  lmfr: {
    title: "Apps are <span>beautiful</span> <br /> using Leroy Merlin APIs",
    description:
      "Designed for developers and businesses who want a simple way to use Leroy Merlin data and intelligence.",
    useCasesButtonLabel: "Discover our use cases",
    tryItButtonLabel: "How it works",
    emphaseWords: ["beautiful", "awesome", "great", "disruptive"],
  },
  lmbr: {
    title: "Apps are <span>beautiful</span> <br /> using Leroy Merlin APIs",
    description:
      "Designed for developers and businesses who want a simple way to use Leroy Merlin data and intelligence.",
    useCasesButtonLabel: "Discover our use cases",
    tryItButtonLabel: "How it works",
    emphaseWords: ["beautiful", "awesome", "great", "disruptive"],
  },
  adeo: {
    title: "Apps are <span>beautiful</span> <br /> using Adeo APIs",
    description:
      "Designed for developers and businesses who want a simple way to use Adeo data and intelligence.",
    useCasesButtonLabel: "Discover our use cases",
    customUiClass: "adeo",
    tryItButtonLabel: "How it works",
    emphaseWords: [
      "beautiful",
      "awesome",
      "great",
      "disruptive",
      "magnificent",
      "marvellous",
      "outstanding",
    ],
  },
};
