import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "card-api-summary--margin-top-bottom"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardContentApi = _resolveComponent("CardContentApi")!
  const _component_AuthorizationRequired = _resolveComponent("AuthorizationRequired")!
  const _component_Chip = _resolveComponent("Chip")!
  const _component_ZonesList = _resolveComponent("ZonesList")!
  const _component_BusinessUnitDeployment = _resolveComponent("BusinessUnitDeployment")!
  const _component_CardFooterApi = _resolveComponent("CardFooterApi")!
  const _component_CardLink = _resolveComponent("CardLink")!

  return (_openBlock(), _createBlock(_component_CardLink, {
    "data-type": "card-api-summary",
    "data-id": $props.api.id,
    class: "card-api-summary bg-white",
    to: { name: 'managerApiDetailSettings', params: { id: $props.api.id } }
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_CardFooterApi, null, {
        default: _withCtx(() => [
          ($options.authorizationRequired)
            ? (_openBlock(), _createBlock(_component_AuthorizationRequired, {
                key: 0,
                class: "card-api-summary--margin-top-bottom"
              }))
            : _createCommentVNode("", true),
          ($options.isDeprecated)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_Chip, null, {
                  default: _withCtx(() => [
                    _createTextVNode("DEPRECATED")
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_ZonesList, {
            "data-cy": "api-published-zones",
            zones: $options.enabledZonesNames,
            class: "card-api-summary--margin-top-bottom"
          }, null, 8, ["zones"]),
          ($options.isLogged)
            ? (_openBlock(), _createBlock(_component_BusinessUnitDeployment, {
                key: 2,
                tags: $options.buTags,
                label: $options.businessUnitsLabel,
                class: "card-api-summary--margin-top-bottom"
              }, null, 8, ["tags", "label"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_CardContentApi, {
        name: $options.apiLabel,
        apiType: $props.api.type,
        version: $props.api.version,
        description: $props.api.description,
        "product-name": !$props.hideProduct && $props.api.product && $props.api.product.name
      }, null, 8, ["name", "apiType", "version", "description", "product-name"])
    ]),
    _: 1
  }, 8, ["data-id", "to"]))
}