<template>
  <div class="datepicker-trigger">
    <DatePicker
      :onlyDate="false"
      :modelValue="rangeValues"
      initialValue="rangeValues"
      range
      color="#38ada9"
      format="YYYY-MM-DD hh:mm a"
      formatted="YYYY-MM-DD hh:mm a"
      autoClose
      noHeader
      noKeyboard
      :customShortcuts="customShortcuts"
      :minDate="minDate.toISOString()"
      :maxDate="maxDate.toISOString()"
      @is-hidden="() => onPickerShown(false)"
      @is-shown="() => onPickerShown(true)"
      @update:modelValue="onChange"
    >
      <div :class="['reporting-datepicker', { loading }]">
        <div class="start-input input-group">
          <label>Select date and time</label>
          <input name="start" :value="displayStartDate" @blur="onDateBlur" />
        </div>
        <span class="reporting-datepicker__separator">></span>
        <div :class="['end-input', 'input-group', { loading }]">
          <input name="end" :value="displayEndDate" @blur="onDateBlur" />
          <div class="spinner"></div>
        </div>
      </div>
    </DatePicker>
  </div>
</template>

<script lang="ts">
import moment from "moment";

import DatePicker from "@/commons/components/DatePicker/index.vue";

const maxDaysOfLogRetention = 15;

const displayDateFormat = "YYYY/MM/DD HH:mm";

const currentTime = () => {
  const t = new Date();
  t.setSeconds(0);
  return t;
};

const now = currentTime();

const dateNormalizer = (format) => {
  return {
    normalize: (date) => {
      if (date) {
        const normalizedDate = moment(date, format);
        if (normalizedDate.isValid()) {
          return moment(date, format).toDate();
        }
        throw new Error("Invalid Date");
      }
    },
  };
};

export default {
  name: "ReportingDatePicker",
  components: {
    DatePicker,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    onRangeChange: {
      type: Function,
    },
    // eslint-disable-next-line vue/require-default-prop
    startDate: {
      type: Date,
    },
    loading: {
      type: Boolean,
    },
    // eslint-disable-next-line vue/require-default-prop
    endDate: {
      type: Date,
    },
  },
  data() {
    return {
      rangeValues: {
        start: this.startDate || now,
        end: this.endDate || now,
      },
      pickerVisible: false,
      maxDate: new Date(),
      minDate: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - maxDaysOfLogRetention,
        now.getHours(),
        now.getMinutes(),
      ),
      customShortcuts: [
        {
          key: "lastHour",
          label: "Last hour",
          value: () => {
            return {
              start: moment(now, displayDateFormat).subtract(1, "hour"),
              end: moment(now, displayDateFormat),
              notRoundRange: true,
            };
          },
        },
        {
          key: "lastThreeHours",
          label: "Last 3 hours",
          value: () => {
            return {
              start: moment(now, displayDateFormat).subtract(3, "hour"),
              end: moment(now, displayDateFormat),
              notRoundRange: true,
            };
          },
        },
        {
          key: "lastTwelveHours",
          label: "Last 12 hours",
          value: () => {
            return {
              start: moment(now, displayDateFormat).subtract(12, "hour"),
              end: moment(now, displayDateFormat),
              notRoundRange: true,
            };
          },
        },
        {
          key: "lastTwentyFourHours",
          label: "Last 24 hours",
          value: () => {
            return {
              start: moment(now, displayDateFormat).subtract(24, "hour"),
              end: moment(now, displayDateFormat),
              notRoundRange: true,
            };
          },
        },
        {
          key: "yesterday",
          label: "Yesterday",
          value: () => {
            return {
              start: moment(now, displayDateFormat).subtract(1, "day"),
              end: moment(now, displayDateFormat).subtract(1, "day"),
            };
          },
        },
        {
          key: "lastSevenDays",
          label: "Last 7 days",
          value: 7,
        },
        {
          key: "lastMaxDaysOfLogRetention",
          label: `Last ${maxDaysOfLogRetention} days`,
          value: maxDaysOfLogRetention,
        },
      ],
    };
  },
  computed: {
    displayStartDate() {
      return this.rangeValues.start
        ? moment(this.rangeValues.start).format(displayDateFormat)
        : "...";
    },
    displayEndDate() {
      return this.rangeValues.end
        ? moment(this.rangeValues.end).format(displayDateFormat)
        : "...";
    },
  },
  watch: {
    startDate: {
      handler() {
        this.rangeValues = {
          start: this.startDate,
          end: this.endDate,
        };
      },
    },
    endDate: {
      handler() {
        this.rangeValues = {
          start: this.startDate,
          end: this.endDate,
        };
      },
    },
  },
  methods: {
    onPickerShown(value) {
      this.pickerVisible = value;
    },
    submitRangeValues() {
      if (this.rangeValues.start && this.rangeValues.end) {
        this.onRangeChange(this.rangeValues);
      }
    },
    onChange(values) {
      const normalizeDate = dateNormalizer("YYYY-MM-DD hh:mm A").normalize;
      const { start, end } = values;

      this.rangeValues = {
        start: normalizeDate(start),
        end: normalizeDate(end),
      };
      this.shortcutSelected = "";
      this.submitRangeValues();
    },
    onDateBlur(e) {
      if (!this.pickerVisible) {
        return;
      }

      const normalizeDate = dateNormalizer("YYYY/MM/DD hh:mm").normalize;
      const previousValue = this.rangeValues[e.target.name];

      try {
        const newValue = normalizeDate(e.target.value);
        const isUnChanged = moment(newValue).isSame(previousValue);

        if (isUnChanged) {
          return;
        }

        this.rangeValues[e.target.name] = newValue;
        this.validateValues();
        this.submitRangeValues();
      } catch (error) {
        this.rangeValues[e.target.name] = previousValue;
      }
    },
    validateValues() {
      const hasValidValues =
        !!this.rangeValues.start &&
        !!this.rangeValues.end &&
        this.rangeValues.end > this.rangeValues.start;
      if (!hasValidValues) {
        throw new Error("Invalid Values");
      }
    },
    onFocus(e) {
      e.preventDefault();
      e.stopPropagation();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/commons/assets/styles/vars";

.reporting-datepicker {
  display: flex;
  align-items: flex-end;
  &.loading {
    pointer-events: none;
    cursor: not-allowed;
    input,
    span {
      color: var(--color-grey);
      background-color: var(--color-background-interface);
    }
  }
  .reporting-datepicker__separator {
    position: relative;
    top: 2px;
    width: 5rem;
    font-size: 1rem;
    text-align: center;
  }
  span {
    margin: 1rem 0;
    font-size: 2rem;
    line-height: 28px;
    background-color: var(--color-white);
    border-top: var(--border-default);
    border-bottom: var(--border-default);

    &.disabled {
      opacity: var(--default-opacity-disabled);
    }
  }
  .start-input,
  .end-input {
    padding: 0;
    input {
      text-align: center;
      border: var(--border-default);
    }
  }
  .input-group.start-input {
    input {
      padding-right: 0;
      padding-left: 15px;
      line-height: 28px;
      border-right: none;
      border-radius: 6px 0 0 6px;
    }
  }
  .input-group.end-input {
    input {
      padding-right: 15px;
      padding-left: 0;
      line-height: 28px;
      border-left: none;
      border-radius: 0 6px 6px 0;
    }
    &.loading {
      position: relative;
      .spinner {
        position: absolute;
        right: 4px;
        bottom: 1px;
        display: block;
        width: 28px;
        height: 28px;
        background: var(--color-white);
        background-color: transparent;
        &::before,
        &::after {
          animation: spinning 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
          animation-iteration-count: infinite;
        }
        &::before {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 20px;
          height: 20px;
          margin: -1rem 0 0 -1rem;
          content: "";
          border: 2px solid transparent;
          border-top-color: var(--color-primary);
          border-radius: 100%;
          box-shadow: 0 0 0 1px transparent;
        }
      }
    }
  }
}
</style>
