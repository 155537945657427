<template>
  <div
    class="conversation-item"
    :class="[
      fromMe(conversationItem)
        ? 'conversation-item--from-me'
        : 'conversation-item--from-them',
    ]"
  >
    <div class="conversation-item__text-and-date">
      <div class="conversation-item__text">
        <p v-if="conversationItem.title" class="conversation-item__text-title">
          {{ conversationItem.title }}
        </p>
        <span
          v-if="conversationItem.message"
          class="conversation-item__message"
          >{{ conversationItem.message }}</span
        >
      </div>
      <div class="conversation-item__author-and-timestamp">
        <a
          v-if="conversationItem.author && conversationItem.author.email"
          :href="`mailto:${conversationItem.author.email}`"
          target="_blank"
        >
          {{ conversationItem.author.name }}
        </a>
        <span v-else>
          {{ conversationItem.author.name }}
        </span>
        ,
        <span class="conversation-item__date">
          {{ requestDate(conversationItem.createdAt) }}</span
        >
      </div>
    </div>
    <div class="conversation-item__avatar">{{ authorInitial }}</div>
  </div>
</template>

<script lang="ts">
import { requestDate } from "@/commons/libs/utils/dateUtils";

export default {
  props: {
    conversationItem: {
      type: Object,
      required: true,
    },
    currentUser: {
      type: Object,
      required: true,
    },
  },
  computed: {
    authorInitial() {
      return this.conversationItem.author != undefined
        ? this.computeInitials(this.conversationItem.author)
        : "?";
    },
  },
  methods: {
    requestDate,
    computeInitials(author) {
      return (
        author &&
        author.name
          .split(" ")
          .map((term) => term[0])
          .join("")
          .toUpperCase()
      );
    },
    fromMe(conversationItem) {
      return (
        conversationItem.author &&
        this.currentUser.id === conversationItem.author.id
      );
    },
  },
};
</script>

<style lang="scss">
.conversation-item {
  display: flex;
  flex-direction: row;
  padding: 0 1rem;

  margin-top: 1.3rem;
  margin-bottom: 0;
  gap: 1rem;
}

.conversation-item--from-me {
  flex-direction: row;
  align-self: flex-end;

  .conversation-item__author-and-timestamp {
    text-align: right;
  }
}

.conversation-item--from-them {
  flex-direction: row-reverse;
  align-self: flex-start;

  .conversation-item__author-and-timestamp {
    text-align: left;
  }
}

.conversation-item__avatar {
  display: flex;

  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  width: 3rem;
  height: 3rem;

  margin-top: 0.3rem;

  font-size: 1.1rem;
  font-weight: 700;

  background: var(--color-background-primary);
  border: 1px solid var(--color-stroke-light);
  border-radius: 100rem;
}

.conversation-item__text-and-date {
  flex: 1;
}

.conversation-item__text {
  display: flex;
  flex-direction: column;

  padding: 0.8rem 1.2rem;

  background-color: var(--color-background-primary);
  border-radius: 0.5rem;
  box-shadow: var(--m-shadow-small);
  gap: 0.5rem;
}

.conversation-item__text-title {
  margin: 0;
  font-weight: 700;
}

.conversation-item__message {
  word-break: break-all;
  white-space: pre-wrap;
}

.conversation-item__author-and-timestamp {
  @include set-text-xs;
  width: 100%;

  margin-top: 0.25rem;
  color: var(--color-text-minor);
}
</style>
