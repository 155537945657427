<template>
  <div :class="['reporting-select-filter', { loading }]">
    <Multiselect
      :modelValue="modelValue"
      :options="options"
      :closeOnSelect="false"
      clearOnSelect
      preserveSearch
      selectedLabel=""
      deselectedLabel=""
      deselectLabel=""
      selectLabel=""
      :searchable="searchable"
      :placeholder="placeholder"
      :label="label"
      :trackBy="trackBy"
      :loading="loading"
      multiple
      isOpen
      @close="onFilterClose"
      @update:model-value="updateSelected"
    >
      <template #selection="{ values, isOpen }">
        <span
          v-if="(isOpen && !searchable) || (values.length && !isOpen)"
          class="multiselect__single"
        >
          {{ selectedLabelsList(values, isOpen) }}
        </span>
      </template>
      <template #option="props">
        <span class="reporting-select-filter__option">
          {{ props.option[label] }}
        </span>
        <span v-if="optionIsSelected(props.option)">
          <MIcon
            name="NotificationAvailable16"
            color="var(--color-brand-primary)"
          />
        </span>
      </template>
    </Multiselect>
  </div>
</template>

<script lang="ts">
import MIcon from "@mozaic-ds/vue-3/src/components/icon/MIcon.vue";
import some from "lodash-es/some";
import Multiselect from "vue-multiselect";

export default {
  name: "ReportingFilter",
  components: {
    Multiselect,
    MIcon,
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types, vue/require-default-prop
    modelValue: {},
    // eslint-disable-next-line vue/require-prop-types, vue/require-default-prop
    options: {},
    // eslint-disable-next-line vue/require-default-prop
    onFilterClose: {
      type: Function,
    },
    placeholder: {
      type: String,
      default: "Select",
    },
    label: {
      type: String,
      default: "label",
    },
    trackBy: {
      type: String,
      default: "id",
    },
    maxLabelDisplayLength: {
      type: Number,
      default: 3,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
    },
  },
  emits: ["update:modelValue"],
  methods: {
    optionIsSelected(option) {
      return some(this.modelValue, { [this.trackBy]: option[this.trackBy] });
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    selectedLabelsList(values, isOpen) {
      if (values.length < this.maxLabelDisplayLength + 1) {
        let labelsList = "";
        values.map((value, index) => {
          labelsList = `${labelsList}${index === 0 ? "" : ", "}${
            value[this.label]
          }`;
        });
        return labelsList;
      } else {
        return `${this.placeholder} (${values.length})`;
      }
    },
    updateSelected(selectedValues) {
      const selectedValuesIds = selectedValues.map(
        (value) => value[this.trackBy],
      );
      this.$emit("update:modelValue", selectedValuesIds);
    },
  },
};
</script>

<style lang="scss">
.reporting-select-filter {
  width: 100%;

  .reporting-select-filter__option {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & .multiselect {
    min-height: 0;
    .multiselect__select {
      height: 30px;
      padding: 8px;
      &::before {
        top: 16px;
      }
    }
    .multiselect__tags {
      min-height: 0;
      padding: 0.25rem 3rem 0.25rem 1rem;
      .multiselect__input,
      .multiselect__single,
      .multiselect__placeholder {
        padding: 0;
        margin: 0;
        font-size: 1rem;
        line-height: 2;
      }
      .multiselect__spinner {
        right: 4px;
        height: 28px;
        &::after,
        &::before {
          margin: -0.5rem 0 0 -0.5rem;
          border-top-color: var(--color-primary);
        }
      }
    }

    .multiselect__content-wrapper {
      .multiselect__option {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 0;
        padding: 0.25rem 1rem;
        margin: 0;
        font-size: 1rem;
        line-height: 2;
        color: black;
        background-color: transparent;
        &.multiselect__option--selected {
          color: var(--color-primary);
        }
      }
    }
  }
}
</style>
