<template>
  <div class="home-main">
    <div class="home-main__message">
      <div>
        <!--eslint-disable vue/no-v-html-->
        <title-animated
          :emphase-words="contents.emphaseWords"
          v-html="contents.title"
        />
        <p class="home-main__description">{{ contents.description }}</p>
        <div class="home-main__buttons">
          <MButton
            theme="bordered"
            :label="contents.useCasesButtonLabel"
            @click="() => $emit('navigateToAnchor', '#home-use-cases')"
          />
          <MButton
            theme="bordered-neutral"
            :label="contents.tryItButtonLabel"
            @click="() => $emit('navigateToAnchor', '#home-try-it')"
          />
        </div>
      </div>
    </div>
    <div class="home-main__picture" />
  </div>
</template>

<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";

import TitleAnimated from "@/commons/components/TitleAnimated.vue";

import content from "@/showcase/contents/home-main";

export default {
  name: "HomeMain",
  components: {
    TitleAnimated,
    MButton,
  },
  emits: ["navigateToAnchor"],
  computed: {
    locationSettings() {
      return this.$store.getters["config/locationSettings"];
    },
    contents() {
      return content[this.locationSettings.name];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/commons/assets/styles/vars";
.home-main,
.home-main__message,
.home-main__picture,
.home-main__buttons {
  display: flex;
}

.home-main {
  position: relative;
  width: 91.4rem;
}

.home-main button {
  width: auto;
  padding: 0 2.14rem;
  margin: 3.57rem 1.79rem 1.79rem 0;
}

.home-main__picture {
  flex: auto;
  align-items: center;
  justify-content: center;
  background-image: url("~@/commons/assets/images/phone.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.home-main__message {
  align-items: center;
  width: 50%;

  padding: 2rem 2.5rem 2rem 2rem;
}

.home-main__buttons {
  margin: 2rem 0;
}

.home-main__description {
  font-size: 1.29rem;
  font-weight: 300;
  line-height: 2rem;
}

@media only screen and (max-width: map-get($breakpoints, desktop-small)) {
  .home-main {
    width: 92%;
  }

  .home-main__message {
    width: 50%;
  }

  .home-main__picture {
    width: 50%;
  }

  .home-main__buttons {
    display: block;
  }

  .home-main .button {
    margin: 2rem 1.5rem 0.5rem 0;
  }
}

@media only screen and (max-width: map-get($breakpoints, tablet-landscape)) {
  .home-main {
    width: 80%;
  }

  .home-main__message {
    width: 50%;
    padding: 2rem 0;
  }

  .home-main__picture {
    width: 50%;
  }

  .home-main__description {
    font-size: 1.2rem;
    line-height: 2rem;
  }

  .home-main .button {
    width: 100%;
    margin: 2rem 0 0.5rem;
  }
}

@media only screen and (max-width: map-get($breakpoints, tablet-portrait)) {
  .home-main {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    width: 80%;
  }

  .home-main__message {
    order: 2;

    width: 100%;
    padding: 0;

    text-align: center;
  }

  .home-main__picture {
    order: 1;
    width: 100%;
  }

  .home-main .button {
    margin: 2rem 1rem 0.5rem;
  }
}

@media only screen and (max-width: map-get($breakpoints, mobile)) {
  .home-main {
    flex-direction: column;
    align-items: center;
    width: calc(100% - 2rem);
    margin: 2rem 0 3rem;
  }

  .home-main__message {
    width: 100%;
    text-align: center;
  }

  .home-main .button {
    margin: 2rem 0 0;
  }
}
</style>
