import { capitalize } from "@/commons/libs/utils/stringUtils";

import {
  Aggregation,
  FilterOption,
} from "@/commons/store/modules/reporting/types";
import { EApplicationType } from "@/commons/store/types";

function buildObjectValueLabel(value) {
  if (value.type && value.type === EApplicationType.SANDBOX) {
    const user = value.createdBy;
    return `${capitalize(user.firstname)} ${capitalize(
      user.lastname,
    )} (Sandbox)`;
  }
  return value.name || value.label;
}

export function buildFilterOptions(
  aggregationName: string,
  aggregations: Aggregation[],
): FilterOption[] {
  const options: FilterOption[] = [];

  for (const { [aggregationName]: value } of aggregations) {
    if (typeof value === "string") {
      options.push({
        label: value,
        id: value,
      });
    } else if (typeof value === "number") {
      options.push({
        label: `${value}`,
        id: `${value}`,
      });
    } else if (value) {
      options.push({
        label: buildObjectValueLabel(value),
        id: value.id,
      });
    }
  }
  return options;
}
