import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "api-settings-secondary-navbar__body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ApiInfoForNavbar = _resolveComponent("ApiInfoForNavbar")!
  const _component_ResponsiveIconButton = _resolveComponent("ResponsiveIconButton")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_ApiDeprecationInfoForNavbar = _resolveComponent("ApiDeprecationInfoForNavbar")!
  const _component_ApiPublicationInfoForNavbar = _resolveComponent("ApiPublicationInfoForNavbar")!
  const _component_ApiAccessPolicy = _resolveComponent("ApiAccessPolicy")!
  const _component_SecondaryNavbar = _resolveComponent("SecondaryNavbar")!

  return (_openBlock(), _createBlock(_component_SecondaryNavbar, { class: "api-settings-secondary-navbar" }, {
    "secondary-navbar-header": _withCtx(() => [
      _createVNode(_component_ApiInfoForNavbar, { api: $props.api }, null, 8, ["api"])
    ]),
    "secondary-navbar-body": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ResponsiveIconButton, {
          class: "api-settings-secondary-navbar__api-doc-link",
          theme: "bordered-neutral",
          icon: "BookStore24",
          size: "s",
          label: $data.contents.goToApiDocumentation,
          href: $options.linkToApiDocumentation,
          breakpointWidth: $options.getCssBreakpointAsNumber('notebook')
        }, null, 8, ["label", "href", "breakpointWidth"]),
        ($options.isRestApi($props.api))
          ? (_openBlock(), _createBlock(_component_ResponsiveIconButton, {
              key: 0,
              "data-cy": "reporting-button",
              label: $data.contents.goToReporting,
              icon: "DisplayExternalLink24",
              theme: "bordered-neutral",
              size: "s",
              href: $options.linkToDatadogReporting,
              target: "_blank"
            }, null, 8, ["label", "href"]))
          : _createCommentVNode("", true),
        _createVNode(_component_Divider, {
          class: "api-settings-secondary-navbar__divider",
          vertical: ""
        }),
        ($props.api.isDeprecated)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createVNode(_component_ApiDeprecationInfoForNavbar, { api: $props.api }, null, 8, ["api"]),
              _createVNode(_component_Divider, {
                vertical: "",
                class: "api-settings-secondary-navbar__divider"
              })
            ], 64))
          : _createCommentVNode("", true),
        _createVNode(_component_ApiPublicationInfoForNavbar, { api: $props.api }, null, 8, ["api"]),
        _createVNode(_component_Divider, {
          class: "api-settings-secondary-navbar__divider",
          vertical: ""
        }),
        ($props.api)
          ? (_openBlock(), _createBlock(_component_ApiAccessPolicy, {
              key: 2,
              api: $props.api
            }, null, 8, ["api"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}