<script setup lang="ts">
import { PropType, toRaw } from "vue";
import { useStore } from "vuex";

import ConfirmModalBody from "@/commons/components/Modal/ConfirmModalBody.vue";

import { writeText } from "@/commons/libs/write-text";

import { Hit } from "@/commons/store/modules/reporting/types";

import contents from "@/commons/contents/hit-details-modal";

const props = defineProps({
  hit: {
    type: Object as PropType<Hit>,
    default: undefined,
  },
});

const store = useStore();

const copy = () => {
  if (props.hit != undefined) {
    const value = JSON.stringify(toRaw(props.hit));
    writeText(value).then(() => {
      store.commit("postSuccessNotification", {
        title: contents.successMessage,
      });
    });
  }
};
</script>

<template>
  <ConfirmModalBody
    class="hit-details-modal"
    :confirmBtnLabel="contents.copy"
    confirmBtnTheme="solid"
    :cancelBtnLabel="contents.close"
    @onSubmit="copy"
  >
    <div class="hit-details-modal__content">
      <pre class="hit-detail-modal__code">{{ hit }}</pre>
    </div>
  </ConfirmModalBody>
</template>
