export default {
  createModalTitle: "Add a new IP address",
  updateModalTitle: "Update an IP address",

  ipFilteringUsageMessage:
    "Your application has subscribed to API using IP Filtering",
  publicIPAddressesMessage:
    "If you use one of their PUBLIC routes, you have to define the Public IP Addresses your calls will be seen from.",
  blockedRequestsMessage: "Otherwise, your calls will be blocked.",

  ipAddressRangeLabel: "IP address range (CIDR block)",
  ipAddressRangeCIDRLabel: "CIDR block must be between /24 and /32",
  ipAddressRangePlaceholder: "123.156.0.7/30",
  ipAddressRangeAlreadyExistErrorMessage: "IP address already exists",

  cidrBlockNotificationMessage:
    "If the CIDR block is not filled, the value /32 will be automatically added to your IP address.",

  commentLabel: "Comment",
  commentRequirementText: "optional",

  addNewIPAddressBtnLabel: "Add a new IP address",
  updateIPAddressBtnLabel: "Update the IP address",
};
