export class Debouncer {
  private debounceTimeout = setTimeout(() => {}, 0);

  public debounce(cb: () => void, delay = 20) {
    clearTimeout(this.debounceTimeout);
    this.debounceTimeout = setTimeout(cb, delay);
  }
}

// Debounce alternative as a function (more standard usage)
export function debounce(
  func: (...args: any[]) => void,
  delay: number = 200,
): (...args: any[]) => void {
  let timer: number | undefined;

  return (...args: any[]): void => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}
