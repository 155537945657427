import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return ($props.isExternalLink || !$props.urlOrRoute)
    ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        class: "menu-link",
        href: $props.urlOrRoute
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 8, _hoisted_1))
    : (_openBlock(), _createBlock(_component_RouterLink, {
        key: 1,
        class: "menu-link",
        to: $props.urlOrRoute
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["to"]))
}