import { EHttpMethod } from "@/commons/utils/http-method";

export enum EWindowLengthUnit {
  SECONDS = "SECONDS",
  MINUTES = "MINUTES",
  HOURS = "HOURS",
  DAYS = "DAYS",
}

export interface RateLimit {
  id?: string;
  maxRequests: number;
  windowLength: number;
  windowLengthUnit: EWindowLengthUnit;
  isEnabled: boolean;
  isGlobal: boolean;
  httpMethod?: EHttpMethod;
  pathRegex?: string;
}
