<template>
  <div class="conversation">
    <div v-if="!currentRequest" class="conversation__no-request">
      <MIcon class="conversation__no-request-icon" name="CommentCommunity32" />
      <div>{{ contents.selectRequestLabel }}</div>
    </div>
    <div v-if="currentRequest" class="conversation__selected-request">
      <RequestManagerActionBar
        data-cy="request-manager-action-bar"
        class="conversation__request-manager-action-bar"
        :urlQuery="urlQuery"
      />
      <div ref="messages" class="conversation__messages">
        <ConversationItem
          v-for="conversationItem in currentRequest.conversation"
          :key="conversationItem.createdAt"
          :current-user="currentUser"
          :conversationItem="conversationItem"
          :request="currentRequest"
        />
      </div>
      <div class="conversation__request-status-bar">
        {{ contents.requestStatus }}
        <request-status :request="currentRequest" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import MIcon from "@mozaic-ds/vue-3/src/components/icon/MIcon.vue";

import ConversationItem from "@/request-manager/views/ConversationItem.vue";
import RequestStatus from "@/request-manager/views/RequestStatus.vue";

import RequestManagerActionBar from "./RequestManagerActionBar.vue";

import requestManagerContents from "@/request-manager/contents/request-manager-contents.js";

export default {
  name: "Conversation",
  components: {
    RequestManagerActionBar,
    RequestStatus,
    ConversationItem,
    MIcon,
  },
  props: {
    urlQuery: {
      type: Object,
      required: true,
    },
    currentUser: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      contents: requestManagerContents.ui,
    };
  },
  computed: {
    currentRequest() {
      return this.$store.getters["currentRequest"];
    },
  },
};
</script>

<style lang="scss" scoped>
.conversation {
  height: 100%;
  overflow: auto;
}

.conversation__no-request {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  font-weight: 700;
  color: var(--color-text-primary);
}

.conversation__no-request-icon {
  font-size: 2.5rem;
}

.conversation__selected-request {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.conversation__messages {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;

  padding: 1rem 0;
}

.conversation__other-info {
  padding: 0.5rem 1rem;
  margin: 1rem;
  margin-top: 0;
  background-color: var(--color-background-primary);
}

.conversation__other-info-title {
  margin-top: 0;
}

.conversation__request-status-bar {
  height: 5rem;
  text-align: center;
}
</style>
