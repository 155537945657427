import {
  APPLICATION_REPORTING_AGGREGATIONS,
  PARAMS_SEPARATOR,
} from "@/commons/store/modules/reporting/constants";

import {
  DateRange,
  FetchFilterOptionsParams,
} from "@/commons/store/modules/reporting/types";

export function buildFetchApplicationFilterOptionsParams(
  dateRange: DateRange,
  separator: string = PARAMS_SEPARATOR,
): FetchFilterOptionsParams {
  const { from, to } = dateRange;

  return {
    from: from.toISOString(),
    to: to.toISOString(),
    aggregations: APPLICATION_REPORTING_AGGREGATIONS.join(separator),
  };
}
