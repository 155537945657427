<template>
  <Box class="navigate-before-button">
    <RouterLink
      data-cy="navigate-before-button"
      class="navigate-before-button__link"
      :to="previousPageRoute"
    >
      <IconArrowLeft />
      <span>{{ title }}</span>
    </RouterLink>
  </Box>
</template>

<script lang="ts">
import Box from "@/commons/components/Box.vue";
import IconArrowLeft from "@/commons/components/Icons/IconArrowLeft.vue";

export default {
  name: "NavigateBeforeButton",
  components: {
    Box,
    IconArrowLeft,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    previousPageRoute: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.navigate-before-button {
  border-bottom: var(--border-default);
}

.navigate-before-button__link {
  display: inline-flex;
  justify-items: center;
}
</style>
