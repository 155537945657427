<script setup lang="ts">
import MIcon from "@mozaic-ds/vue-3/src/components/icon/MIcon.vue";
import { computed, PropType } from "vue";
import { useStore } from "vuex";

import ZoneInformation from "@/commons/components/ZoneInformation.vue";
import LayerModalFormConfirm from "@/commons/components/form/LayerModalFormConfirm.vue";

import { getRateLimitLabel } from "@/commons/utils/quota-utils";

import { Contract } from "@/commons/domain/models/contract";
import { RateLimit } from "@/commons/domain/models/rate-limit";
import { Route } from "@/commons/domain/models/route";
import { Zone } from "@/commons/domain/models/zone";

import contents from "@/dashboard/contents/contract-more-info-modal";

const props = defineProps({
  contract: {
    type: Object as PropType<Contract>,
    required: true,
  },
});

const emit = defineEmits(["onClose"]);

const store = useStore();

// Display zones and routes

const zonesList = computed((): Zone[] => {
  return store.getters["zonesList"];
});

const zonesWithPublishedRoutes = computed((): Map<string, Route[]> => {
  return props.contract?.api?.zonesWithPublishedRoutes;
});

const zoneIds = computed((): string[] => {
  return Object.keys(zonesWithPublishedRoutes.value);
});

const getZoneById = (zoneId: string): Zone | undefined => {
  return zonesList.value.find((zone) => zone.id === zoneId);
};

// Display quota and timeout
const enabledApiRateLimit = computed((): RateLimit | undefined => {
  return props.contract?.api.rateLimits?.global?.isEnabled
    ? props.contract?.api.rateLimits?.global
    : undefined;
});

const enabledContractRateLimit = computed((): RateLimit | undefined => {
  return props.contract?.rateLimits?.global?.isEnabled
    ? props.contract?.rateLimits?.global
    : undefined;
});

const quotaDisplay = computed(() => {
  if (enabledApiRateLimit.value != undefined) {
    return contents.apiRateLimit(getRateLimitLabel(enabledApiRateLimit.value));
  } else if (enabledContractRateLimit.value != undefined) {
    return contents.appRateLimit(
      getRateLimitLabel(enabledContractRateLimit.value),
    );
  } else {
    return contents.noQuota;
  }
});

const timeoutDisplay = computed(() => {
  return `${props.contract?.api?.timeoutInSeconds}s`;
});
</script>

<template>
  <LayerModalFormConfirm
    class="contract-more-info-modal"
    confirmBtnIsHidden
    :cancelBtnLabel="contents.closeBtnLabel"
    @close="emit('onClose')"
  >
    <div v-if="zoneIds?.length > 0" class="contract-more-info-modal__links">
      <h5>{{ contents.linksTitle }}</h5>
      <ZoneInformation
        v-for="zoneId in zoneIds"
        :key="zoneId"
        :zone="getZoneById(zoneId)"
        :routes="zonesWithPublishedRoutes[zoneId]"
      />
    </div>

    <div class="contract-more-info-modal__limitations">
      <h5>{{ contents.limitationsTitle }}</h5>
      <div class="contract-more-info-modal__quota-limitations">
        <MIcon name="Gauge24" />
        <span>{{ contents.quotaLabel(quotaDisplay) }}</span>
      </div>
      <div class="contract-more-info-modal__timeout-limitations">
        <MIcon name="TimeRead24" />
        <span>{{ contents.timeoutLabel(timeoutDisplay) }}</span>
      </div>
    </div>
  </LayerModalFormConfirm>
</template>

<style lang="scss">
.contract-more-info-modal {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contract-more-info-modal__links {
  display: flex;
  flex-direction: column;
  gap: var(--base-spacing);
}

.contract-more-info-modal__limitations {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contract-more-info-modal__quota-limitations,
.contract-more-info-modal__timeout-limitations {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
</style>
