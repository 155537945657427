import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "product-apis-list__body" }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardApiCatalog = _resolveComponent("CardApiCatalog")!
  const _component_MPagination = _resolveComponent("MPagination")!
  const _component_CardContainer = _resolveComponent("CardContainer")!

  return (_openBlock(), _createBlock(_component_CardContainer, {
    id: $props.anchorId,
    class: "product-apis-list"
  }, {
    default: _withCtx(() => [
      _createElementVNode("h3", null, _toDisplayString($props.title), 1),
      ($props.apis && $props.apis.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.apisList, (api) => {
                return (_openBlock(), _createBlock(_component_CardApiCatalog, {
                  key: api.id,
                  class: "product-apis-list__api-card",
                  api: api,
                  hideProduct: ""
                }, null, 8, ["api"]))
              }), 128))
            ]),
            ($options.apisTotalPages > 1)
              ? (_openBlock(), _createBlock(_component_MPagination, {
                  key: 0,
                  class: "product-apis-list__pagination",
                  length: $options.apisTotalPages,
                  value: $data.currentPage,
                  onOnUpdatePage: $options.changePage
                }, null, 8, ["length", "value", "onOnUpdatePage"]))
              : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("span", null, _toDisplayString($props.noItemsMessage), 1)
          ]))
    ]),
    _: 1
  }, 8, ["id"]))
}