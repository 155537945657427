import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'cluster',
      'cluster-layout',
      { 'cluster-layout--right': $props.clusterToRight },
    ])
  }, [
    _createElementVNode("div", null, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 2))
}