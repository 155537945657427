import { State as RootState } from "@/commons/store/types";

export function updatePayload(
  payload: Partial<RootState>,
  propertyName: string,
  updatedProperty: any,
  path: string[] = [],
) {
  let pointer = payload;

  for (const key of path) {
    pointer = pointer[key];
  }

  pointer[propertyName] = updatedProperty;

  return payload;
}
