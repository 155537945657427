import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "api-publication-info-for-navbar" }
const _hoisted_2 = { class: "api-publication-info-for-navbar__publication-status-date" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ApiPublicationStatus = _resolveComponent("ApiPublicationStatus")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ApiPublicationStatus, {
      class: "api-publication-info-for-navbar__publication-status",
      api: $props.api
    }, null, 8, ["api"]),
    _createElementVNode("div", _hoisted_2, _toDisplayString($data.contents.lastUpdate) + " " + _toDisplayString($options.toLocaleDate($props.api.updatedAt)), 1)
  ]))
}