import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContractInformation = _resolveComponent("ContractInformation")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_ContractWarning = _resolveComponent("ContractWarning")!
  const _component_ContractCardContainer = _resolveComponent("ContractCardContainer")!

  return (_openBlock(), _createBlock(_component_ContractCardContainer, { class: "contract-warning-pending-validation" }, {
    default: _withCtx(() => [
      _createVNode(_component_ContractInformation, { contract: $props.contract }, null, 8, ["contract"]),
      _createVNode(_component_ContractWarning, {
        title: $data.contents.pendingValidation,
        subtitle: $data.contents.subscriptionApi,
        message: $data.contents.message,
        iconName: "NotificationBell24"
      }, {
        default: _withCtx(() => [
          (
          $options.pendingRequestRoute != undefined &&
          $options.userHasWriteAccessOnCurrentApplication
        )
            ? (_openBlock(), _createBlock(_component_RouterLink, {
                key: 0,
                to: $options.pendingRequestRoute,
                class: "contract-warning-pending-validation__request-link"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($data.contents.viewRequest), 1)
                ]),
                _: 1
              }, 8, ["to"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["title", "subtitle", "message"])
    ]),
    _: 1
  }))
}