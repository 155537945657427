import {
  isURL,
  ValidationArguments,
  ValidatorConstraint,
  ValidatorConstraintInterface,
} from "class-validator";

@ValidatorConstraint({ async: true })
export class URLPatternConstraint implements ValidatorConstraintInterface {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  validate(value: string, args: ValidationArguments) {
    const isURLOptions = {
      require_protocol: true,
      require_valid_protocol: true,
      protocols: ["http", "https"],
      host_blacklist: ["localhost", "127.0.0.1"],
      disallow_auth: true,
      allow_trailing_dot: true,
      require_tld: false,
    };

    return isURL(value, isURLOptions);
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  defaultMessage(args: ValidationArguments) {
    return "Invalid URL";
  }
}
