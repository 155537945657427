import { GroupWithUserActionMapper } from "./group-with-user-action-mapper";

import { CurrentUser, User } from "@/commons/domain/models/user";
import { CurrentUserDto, UserDto } from "@/commons/dtos/user-dto";

import { ERole } from "@/commons/store/types";

export class UserMapper {
  static toCurrentUserDomain(userDto: CurrentUserDto) {
    const user: CurrentUser = {
      ...userDto,
      isAdmin:
        userDto.roles &&
        !!userDto.roles.find((role) => role.name === ERole.ADMIN),
      isInternal:
        userDto.roles &&
        !!userDto.roles.find((role) => role.name === ERole.INTERNAL),
      limits: {
        applicationCount:
          (userDto.limits && userDto.limits.applicationCount) || 0,
        maxApplications:
          (userDto.limits && userDto.limits.maxApplications) || 10,
      },
      groups: GroupWithUserActionMapper.toDomain(userDto.groups),
    };

    return user;
  }

  static toUserDomain(userDto: UserDto): User {
    return {
      ...userDto,
    };
  }
}
