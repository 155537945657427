import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SensitiveModificationsList = _resolveComponent("SensitiveModificationsList")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { class: "sensitive-modifications" }, {
    default: _withCtx(() => [
      _createElementVNode("h4", null, _toDisplayString($data.contents.title), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.actions, (action) => {
        return (_openBlock(), _createBlock(_component_SensitiveModificationsList, {
          key: action.label,
          title: action.label,
          description: action.description,
          btnTitle: action.btnTitle,
          btnAction: action.action,
          isDisabled: action.isDisabled
        }, null, 8, ["title", "description", "btnTitle", "btnAction", "isDisabled"]))
      }), 128))
    ]),
    _: 1
  }))
}