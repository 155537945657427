/* eslint-disable no-useless-escape */
import { Contains, IsNotEmpty } from "class-validator";

import { FormData } from "@/commons/domain/forms/form-data";
import { FormFactory } from "@/commons/domain/forms/form-factory";

export class DeletionGroupForm extends FormData {
  @Contains("DELETE", { message: "You must write DELETE" })
  @IsNotEmpty({ message: "This field cannot be empty" })
  deletionText: string;

  $clear() {
    this.deletionText = "";
  }

  static create() {
    return FormFactory.createForm(new DeletionGroupForm());
  }
}
