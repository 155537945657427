export default {
  subscriptionMenuTitle: "Subscription - actions",
  apiKeyMenuTitle: "API Key - actions",

  renewApiKey: "Renew",
  extendApiKey: "Extend",
  deleteApiKey: "Delete this API Key",
  unsubscribe: "Unsubscribe",
  recommended: "Recommended",
  manageApiSettings: "Manage API settings",
};
