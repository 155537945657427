<script setup lang="ts">
import { computed, markRaw, onMounted, ref } from "vue";
import { useStore } from "vuex";

import CardContainer from "@/commons/components/CardContainer.vue";
import GroupsList from "@/commons/components/ManageGroups/GroupsList.vue";
import NotificationWarningMCCGroup from "@/commons/components/ManageGroups/NotificationWarningMCCGroup.vue";
import SearchGroup from "@/commons/components/ManageGroups/SearchGroup.vue";
import MessageConfirmModal from "@/commons/components/Modal/MessageConfirmModal.vue";
import { ModalClosedError } from "@/commons/components/Modal/modal.type";
import DeleteGroupModal from "@/commons/components/userGroup/DeleteGroupModal.vue";

import { Debouncer } from "@/commons/libs/utils/debouncer";
import {
  isLocalGroup,
  isOnlyInternalsGroup,
  lastMCCGroupWillBeDeleted,
} from "@/commons/utils/group-utils";

import { Application } from "@/commons/domain/models/application";
import { GroupWithUsersAcls } from "@/commons/domain/models/group-with-users-acls";
import { searchGroupsInApplicationSettings } from "@/commons/services/group/group-service";

import contentsForDeleteGroupModal from "@/commons/contents/delete-group-modal";
import contentsWarningDeleteLastMCCGroup from "@/commons/contents/warning-delete-last-mcc-group-modal";
import contents from "@/dashboard/contents/access-settings";

const store = useStore();
const debouncer = new Debouncer();

const application = computed(
  (): Application => store.getters["currentApplication"],
);

const applicationGroups = computed(
  () => store.getters["applicationGroupAclsList"],
);

const suggestions = ref([]);

const searchGroups = async (searchValue: string) => {
  debouncer.debounce(async () => {
    suggestions.value = await searchGroupsInApplicationSettings(
      searchValue,
      application.value?.product?.id,
    );
  }, 200);
};

onMounted(async () => {
  await searchGroups("");
});

const addGroup = (groupId: string, roleId: string) => {
  store.dispatch("applicationCreateGroupAcl", {
    id: groupId,
    roleId: roleId,
    appId: application.value.id,
  });
};

const updateGroupRole = (roleId, groupId) => {
  store.dispatch("applicationUpdateGroupAcl", {
    id: groupId,
    roleId: roleId,
    appId: application.value.id,
  });
};

/**
 * Delete group from application
 */

const userIsInternal = computed(() => store.getters["userIsInternal"]);

const deleteGroup = async (groupId: string) => {
  if (userIsInternal.value) {
    await deleteGroupAsInternal(groupId);
  } else {
    // User is PARTNER
    deleteGroupAsPartner(groupId);
  }
};

const deleteGroupAsPartner = (groupId: string) => {
  openDeleteGroupModal(groupId);
};

const deleteGroupAsInternal = async (groupId: string) => {
  if (lastMCCGroupWillBeDeleted(applicationGroups.value, groupId)) {
    await openWarningModalAndAskConfirmation();
  }

  openDeleteGroupModal(groupId);
};

const openWarningModalAndAskConfirmation = (): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    store.commit("openModal", {
      title: contentsWarningDeleteLastMCCGroup.modalTitle,
      component: markRaw(MessageConfirmModal),
      props: {
        title: contentsWarningDeleteLastMCCGroup.title,
        message:
          contentsWarningDeleteLastMCCGroup.messageDeleteGroupOnApplication,
        confirmBtnLabel: contentsWarningDeleteLastMCCGroup.confirmBtnLabel,
      },
      listeners: {
        onSubmit: () => {
          store.commit("closeModal");
          resolve(true);
        },
        onClose: () => reject(new ModalClosedError()),
      },
    });
  });
};

const openDeleteGroupModal = (groupId: string) => {
  store.commit("openLayerModal", {
    title: contentsForDeleteGroupModal.modalTitle,
    component: markRaw(DeleteGroupModal),
    listeners: {
      async onSubmit() {
        await store.dispatch("applicationRemoveGroupAcl", {
          id: groupId,
          appId: application.value.id,
        });
      },
    },
  });
};

const displayMCCWarning = ref(false);

const onGroupSelection = (groupId: string) => {
  const selectedGroup = suggestions.value.find(
    (g: GroupWithUsersAcls) => g.id === groupId,
  );
  displayMCCWarning.value =
    selectedGroup != undefined &&
    userIsInternal.value &&
    isLocalGroup(selectedGroup) &&
    isOnlyInternalsGroup(selectedGroup);
};
</script>

<template>
  <CardContainer class="access-settings">
    <h4>{{ contents.accessSettingsTitle }}</h4>

    <SearchGroup
      manageRoles
      :suggestions="suggestions"
      @searchGroups="searchGroups"
      @addGroup="addGroup"
      @onSelect="onGroupSelection"
    />

    <NotificationWarningMCCGroup v-if="displayMCCWarning" />

    <GroupsList
      class="access-settings__groups-list"
      manageRoles
      :groups="applicationGroups"
      @updateGroupRole="updateGroupRole"
      @deleteGroup="deleteGroup"
    />
  </CardContainer>
</template>

<style lang="scss">
.access-settings {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.access-settings__groups-list {
  margin-bottom: 1rem;
}
</style>
