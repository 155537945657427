import { Application } from "@/commons/domain/models/application";

import { EAccessLevel } from "@/commons/store/types";

export function userHasWriteAccessOnApplication(
  application: Application,
): boolean {
  return application?.currentUserAction === EAccessLevel.READ_WRITE;
}

export function userHasReadAccessOnApplication(
  application: Application,
): boolean {
  return (
    userHasWriteAccessOnApplication(application) ||
    application?.currentUserAction === EAccessLevel.READ_ONLY
  );
}
