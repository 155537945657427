import { IsUrl } from "class-validator";

import { FormData } from "@/commons/domain/forms/form-data";
import { FormFactory } from "@/commons/domain/forms/form-factory";
import { CorsOrigin } from "@/commons/domain/models/cors-origin";

export class CorsOriginForm extends FormData {
  id: string;

  @IsUrl(
    {
      require_protocol: true,
      require_valid_protocol: true,
      protocols: ["http", "https"],
      host_blacklist: [],
      disallow_auth: true,
      allow_trailing_dot: true,
      require_tld: false,
    },
    { message: "This URL must be a valid URL address" },
  )
  value: string;

  constructor(corsOrigin: CorsOrigin) {
    super();
    this.$update(corsOrigin);
  }

  async $clear() {
    this.value = "";
  }

  static create(
    corsOrigin: CorsOrigin = {
      id: null,
      value: "",
    },
  ) {
    return FormFactory.createForm(new CorsOriginForm(corsOrigin));
  }
}
