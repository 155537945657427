<template>
  <MPagination
    v-if="totalPagesNumber > 1"
    class="query-param-pagination"
    :length="totalPagesNumber"
    :value="currentPage"
    @on-update-page="changePage"
  />
</template>

<script lang="ts">
import MPagination from "@mozaic-ds/vue-3/src/components/pagination/MPagination.vue";

import { changeUrlQuery } from "@/commons/utils/route-utils";

export default {
  components: {
    MPagination,
  },
  props: {
    totalPagesNumber: {
      type: Number,
      required: true,
    },
  },
  computed: {
    currentPage(): number {
      return parseInt(this.$route.query["page"]) || 1;
    },
  },
  methods: {
    changePage(newPage) {
      changeUrlQuery({ page: newPage });
    },
  },
};
</script>
