import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GroupsListingSection = _resolveComponent("GroupsListingSection")!

  return (_openBlock(), _createBlock(_component_GroupsListingSection, {
    class: "manage-groups",
    groups: $options.groups,
    totalPages: $options.totalPages,
    searchText: $props.urlQuery.term,
    onGroupUpdated: $options.loadGroups,
    onGroupDeleted: $options.loadGroups
  }, null, 8, ["groups", "totalPages", "searchText", "onGroupUpdated", "onGroupDeleted"]))
}