module.exports = {
  businessUnit: "Business unit",
  platformLabel: "Platform/Community",
  title: "Organization",
  tooltipBu:
    "Indicate which BU will use your API. Multiple choice is possible.",
  tooltipPlatform: "Your digital platform or your community",
  yes: "Yes",
  no: "No",
  apply: "Apply changes",
  cancel: "Cancel",
  businessUnitsPlaceholder: "Search for Business Unit",
};
