import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "quota-api-settings" }
const _hoisted_2 = { class: "quota-api-settings__quota-type-options-container" }
const _hoisted_3 = {
  key: 0,
  class: "quota-api-settings__rate-limits-section"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  class: "quota-api-settings__add-new-quota"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 1,
  class: "quota-api-settings__add-new-quota"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TitleWithNumber = _resolveComponent("TitleWithNumber")!
  const _component_QuotaType = _resolveComponent("QuotaType")!
  const _component_QuotaRateLimitDisplay = _resolveComponent("QuotaRateLimitDisplay")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_QuotaConsumers = _resolveComponent("QuotaConsumers")!
  const _component_CardContainer = _resolveComponent("CardContainer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", null, _toDisplayString($data.contents.title), 1),
    _createVNode(_component_CardContainer, null, {
      default: _withCtx(() => [
        _createVNode(_component_TitleWithNumber, {
          number: $data.contents.numberOne,
          title: $data.contents.chooseQuotaType
        }, null, 8, ["number", "title"]),
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.quotaTypeOptions, (option, index) => {
            return (_openBlock(), _createBlock(_component_QuotaType, {
              key: index,
              icon: option.icon,
              title: option.title,
              description: option.description,
              isActive: option.value === $data.selectedQuotaType,
              onClick: ($event: any) => ($options.onClickOnQuotaTypeOption(option.value))
            }, null, 8, ["icon", "title", "description", "isActive", "onClick"]))
          }), 128))
        ]),
        (!$options.isSavingQuotaTypeChange)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              ($data.selectedQuotaType === $data.EQuotaType.API_QUOTA)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_TitleWithNumber, {
                      number: $data.contents.numberTwo,
                      title: $data.contents.defineQuotaYouNeed,
                      description: $data.contents.apiQuotaExplanation
                    }, null, 8, ["number", "title", "description"]),
                    ($options.rateLimits && $options.rateLimits.global)
                      ? (_openBlock(), _createBlock(_component_QuotaRateLimitDisplay, {
                          key: 0,
                          rateLimit: $options.rateLimits.global,
                          onEditRateLimit: $options.onEditApiRateLimit,
                          onActivationChange: $options.onApiRateLimitActivationChange
                        }, null, 8, ["rateLimit", "onEditRateLimit", "onActivationChange"]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                          _createVNode(_component_MButton, {
                            label: $data.contents.addQuotaForApi,
                            icon: "PublishEdit48",
                            iconPosition: "left",
                            onClick: $options.onClickOnAddApiRateLimit
                          }, null, 8, ["label", "onClick"])
                        ]))
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createVNode(_component_TitleWithNumber, {
                      number: $data.contents.numberTwo,
                      title: $data.contents.defineQuotaYouNeed,
                      description: $data.contents.consumerQuotaExplanation
                    }, null, 8, ["number", "title", "description"]),
                    ($options.rateLimitsForNewContracts && $options.rateLimitsForNewContracts.global)
                      ? (_openBlock(), _createBlock(_component_QuotaRateLimitDisplay, {
                          key: 0,
                          rateLimit: $options.rateLimitsForNewContracts.global,
                          onEditRateLimit: $options.onEditRateLimitForNewContracts,
                          onActivationChange: $options.onRateLimitForNewContractsActivationChange
                        }, null, 8, ["rateLimit", "onEditRateLimit", "onActivationChange"]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                          _createVNode(_component_MButton, {
                            label: $data.contents.addQuotaForNewConsumer,
                            icon: "PublishEdit48",
                            iconPosition: "left",
                            onClick: $options.onClickOnAddRateLimitForNewContracts
                          }, null, 8, ["label", "onClick"])
                        ])),
                    _createVNode(_component_QuotaConsumers, { allowedWindowLengthUnits: $options.allowedWindowLengthUnits }, null, 8, ["allowedWindowLengthUnits"])
                  ]))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}