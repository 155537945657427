import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "form-input__error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Label = _resolveComponent("Label")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['form-input', { 'form-input--has-error': !!$props.error }])
  }, [
    ($props.label)
      ? (_openBlock(), _createBlock(_component_Label, {
          key: 0,
          class: "form-input__label",
          isRequired: $props.isRequired,
          tooltip: $props.tooltip
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($props.label), 1)
          ]),
          _: 1
        }, 8, ["isRequired", "tooltip"]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "label"),
    _renderSlot(_ctx.$slots, "default"),
    ($props.error)
      ? (_openBlock(), _createElementBlock("small", _hoisted_1, _toDisplayString($props.error), 1))
      : _createCommentVNode("", true)
  ], 2))
}