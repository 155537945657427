export function addDatadomeScriptElement() {
  const datadome = document.createElement("script");
  datadome.id = "datadome-script";
  datadome.innerHTML = `
         !function (a, b, c, d, e, f){
    a.ddjskey = e;
    a.ddoptions = f || null;
    var m = b.createElement(c), n = b.getElementsByTagName(c)[0];
    m.async = 1, m.src = d, n.parentNode.insertBefore(m, n);
  }(window,
    document,
    "script",
    "https://js.datadome.co/tags.js",
    "C9CE496959AACDB222ABA40CED8468",
    { ajaxListenerPath: true, allowHtmlContentTypeOnCaptcha : true }
  );`;
  document.head.appendChild(datadome);
}
