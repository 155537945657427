import { AclUser } from "@/commons/domain/models/acl-user";
import { Dictionary } from "@/commons/domain/models/dictionary";
import { Role } from "@/commons/domain/models/role";

export enum EGroupProvider {
  LOCAL = "LOCAL",
  MCC = "MCC",
}

export interface Group {
  id: string;
  isRemoved: boolean;
  updatedAt: string;
  name: string;
  description: string;
  role?: Role;
  users?: Dictionary<AclUser>;
  currentUserActions: string[];
  provider: EGroupProvider;
  linkMCC?: string;
}
