<template>
  <div v-if="!isLoading" class="access-restriction">
    <Card>
      <div class="access-restriction__title">
        <h4>{{ contents.titleAccessRestrictions }}</h4>
        <HelpLink
          :href="
            convertRouteToHref({
              name: 'docsMain',
              hash: contents.viewHelpDocAnchor,
            })
          "
          :label="contents.viewHelpLinkLabel"
          iconName="Read24"
          iconPosition="left"
          withIcon
          openInNewTab
        />
      </div>

      <Stack>
        <MNotification
          v-if="
            !userIsAdmin &&
            !apiHasPendingChangeToPublic &&
            selectedPrivacy === EPrivacyName.PUBLIC
          "
          type="warning"
        >
          {{ contents.alerteMessageAdministratorApproval }}
        </MNotification>

        <MNotification
          v-if="
            selectedPrivacy === EPrivacyName.PARTNER &&
            Object.values(groupsAclsSelected).length === 0
          "
          type="warning"
        >
          {{ contents.alertMessageAddPartners }}
        </MNotification>

        <p class="pad-500">
          <span class="list-bullet bg-text color-white">{{
            contents.numberOne
          }}</span
          >{{ contents.titleStepOne }}
        </p>
        <AccessRestrictionSelector
          :apiPublicAccessRestriction="apiPublicAccessRestriction"
          :selectedPrivacy="selectedPrivacy"
          :apiHasPendingChangeToPublic="apiHasPendingChangeToPublic"
          @change="changeAccessRestriction"
        />
        <template v-if="canSetAccessType">
          <p class="pad-1200">
            <span class="list-bullet bg-text color-white">2</span
            >{{ contents.titleStepTwoValidateAccess }}
          </p>

          <MRadioGroup
            v-model="selectedAccessPolicy"
            class="access-restriction__on-demand-access"
            inline
            :options="[
              {
                label: contents.yes,
                value: EAccessPolicy.ON_DEMAND,
              },
              {
                label: contents.no,
                value: EAccessPolicy.SELF_SERVICE,
              },
            ]"
          />
        </template>

        <template v-if="canHoldAcl">
          <p class="pad-1200">
            <span class="list-bullet bg-text color-white">{{
              canSetAccessType ? 3 : 2
            }}</span
            >{{
              selectedPrivacy === EPrivacyName.PRIVATE
                ? contents.titleStepTwoInternalGroups
                : contents.titleStepTwoGroups
            }}
          </p>
          <MNotification
            v-if="
              (selectedPrivacy === EPrivacyName.PRIVATE &&
                Object.values(groupsAclsSelected).length == 0) ||
              selectedPrivacy === EPrivacyName.PARTNER
            "
            type="information"
          >
            {{
              selectedPrivacy === EPrivacyName.PRIVATE
                ? contents.alertMessagePrivate
                : contents.alertMessagePartner
            }}
          </MNotification>

          <ACLsManager
            :acls="Object.values(currentApi.acl.groups)"
            :listHeader="contents.aclManagerListHeader"
            :listEmptyStateMessage="contents.aclManagerListEmptyStateMessage"
            :withPartners="selectedPrivacy === EPrivacyName.PARTNER"
            @submit="addGroupAcl"
          >
            <ACL
              v-for="acl in Object.values(groupsAclsSelected)"
              :id="acl.id"
              :key="acl.id"
              :name="acl.name"
              :provider="acl.provider"
              @remove="removeGroupAcl"
            />
          </ACLsManager>
        </template>

        <MNotification
          v-if="
            selectedPrivacy === EPrivacyName.PUBLIC &&
            !userIsAdmin &&
            apiHasPendingChangeToPublic
          "
          type="information"
        >
          {{ contents.alertMessageRequestPending }}
        </MNotification>

        <div class="access-restriction__btn-validate centered pad-1200">
          <RouterLink
            v-if="
              selectedPrivacy === EPrivacyName.PUBLIC &&
              apiHasPendingChangeToPublic
            "
            :to="publicPolicyPendingRequestsRoute"
          >
            <MButton theme="bordered" :label="contents.viewYourRequest" />
          </RouterLink>
          <MButton
            v-else
            :label="contents.validateAccessPreferences"
            :disabled="!canUpdateApiAccessRestriction"
            @click="updateApiAclsAndAccessRestriction"
          />
        </div>
      </Stack>
    </Card>
  </div>
</template>

<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import MNotification from "@mozaic-ds/vue-3/src/components/notification/MNotification.vue";
import MRadioGroup from "@mozaic-ds/vue-3/src/components/radio/MRadioGroup.vue";
import { markRaw } from "vue";

import ACL from "@/commons/components/ACLsManager/ACL.vue";
import ACLsManager from "@/commons/components/ACLsManager/ACLsManager.vue";
import Card from "@/commons/components/Card.vue";
import Stack from "@/commons/components/Stack.vue";
import HelpLink from "@/commons/components/UserDocumentationLinks/HelpLink.vue";

import AccessRestrictionSelector from "./AccessRestrictionSelector.vue";
import RequestApiPublicAccessModal from "./RequestApiPublicAccessModal.vue";

import { convertRouteToHref } from "@/commons/utils/route-utils";

import { AclGroup } from "@/commons/domain/models/acl-group";
import { Group } from "@/commons/domain/models/group";

import {
  EAccessPolicy,
  EPrivacyName,
  ERequestFilteringVisibility,
  ERequestType,
  ERole,
} from "@/commons/store/types";

import contents from "@/manager/contents/access-restriction";
import contentsRequestApiPublicAccessModal from "@/manager/contents/request-api-public-access-modal";

export default {
  name: "AccessRestriction",
  components: {
    HelpLink,
    Card,
    ACLsManager,
    Stack,
    MRadioGroup,
    AccessRestrictionSelector,
    ACL,
    MNotification,
    MButton,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      contents,
      EPrivacyName,
      aclsSuggestions: [],
      selectedPrivacy: null,
      EAccessPolicy,
      selectedAccessPolicy: EAccessPolicy.ON_DEMAND,
      groupsAclsSelected: {},
    };
  },
  computed: {
    canSetAccessType() {
      return (
        [EPrivacyName.INTERNAL, EPrivacyName.PARTNER].includes(
          this.selectedPrivacy,
        ) ||
        (this.selectedPrivacy === EPrivacyName.PUBLIC &&
          (this.userIsAdmin || !this.apiHasPendingChangeToPublic))
      );
    },
    canHoldAcl() {
      return (
        this.selectedPrivacy === EPrivacyName.PRIVATE ||
        this.selectedPrivacy === EPrivacyName.PARTNER
      );
    },
    currentApi() {
      return this.$store.getters["currentApi"];
    },
    isLoading() {
      return this.$store.getters["isLoading"];
    },
    user() {
      return this.$store.getters["user"];
    },
    pendingAccessPolicyRequests() {
      return this.$store.getters["currentApiPendingAccessPolicyRequests"];
    },
    userIsAdmin() {
      return this.user && this.user.isAdmin;
    },
    apiPublicAccessRestriction() {
      return this.$store.getters["apiPublicAccessRestriction"];
    },
    canUpdateApiAccessRestriction() {
      return (
        !this.apiHasPendingChangeToPublic &&
        (this.currentApi.privacy !== this.selectedPrivacy ||
          (this.currentApi.accessPolicy !== this.selectedAccessPolicy &&
            this.currentApi.accessPolicy !== EAccessPolicy.PRIVATE) ||
          this.hasAclChanged)
      );
    },
    hasAclChanged() {
      let currentGroupIds = Object.values(this.groupAcls).map(
        (group: AclGroup) => group.id,
      );
      let selectedGroupIds = Object.values(this.groupsAclsSelected).map(
        (group: AclGroup) => group.id,
      );

      return (
        currentGroupIds.filter((groupId) => !selectedGroupIds.includes(groupId))
          .length > 0 ||
        selectedGroupIds.filter((groupId) => !currentGroupIds.includes(groupId))
          .length > 0
      );
    },
    apiHasPendingChangeToPublic() {
      return !!Object.keys(this.pendingAccessPolicyRequests).length;
    },
    groupAcls() {
      return Object.values(this.currentApi.acl.groups)
        .filter(
          (group: Group) => group.role.id === this.roleForAccessRestriction,
        )
        .reduce((acc, group: Group) => {
          acc[group.id] = group;
          return acc;
        }, {});
    },
    immutableGroupAcls() {
      return Object.values(this.currentApi.acl.groups)
        .filter(
          (group: Group) => group.role.id !== this.roleForAccessRestriction,
        )
        .reduce((acc, group: Group) => {
          acc[group.id] = group;
          return acc;
        }, {});
    },
    roleForAccessRestriction() {
      return ERole.VIEWER;
    },
    publicPolicyPendingRequestsRoute() {
      return {
        name: "requestManager",
        query: {
          types: ERequestType.UPDATE_ACCESS_POLICY,
          visibility: ERequestFilteringVisibility.SUBMITTED,
          page: 1,
        },
      };
    },
    publicPolicyRequestShouldBeValidated(): boolean {
      return !this.userIsAdmin && this.selectedPrivacy === EPrivacyName.PUBLIC;
    },
  },
  watch: {
    currentApi() {
      this.init();
    },
  },
  async mounted() {
    this.init();
  },
  methods: {
    convertRouteToHref,
    async updateApiAclsAndAccessRestriction() {
      if (this.canUpdateApiAccessRestriction) {
        if (this.publicPolicyRequestShouldBeValidated) {
          this.openRequestApiPublicAccessModalAndUpdate();
        } else {
          let groups = {
            ...this.immutableGroupAcls,
            ...this.groupsAclsSelected,
          };
          await this.$store.dispatch("updateApiAndAcl", {
            payloadUpdateApi: {
              id: this.currentApi.id,
              privacy: this.selectedPrivacy,
              accessPolicy: this.canSetAccessType
                ? this.selectedAccessPolicy
                : EAccessPolicy.PRIVATE,
            },
            groups: this.hasAclChanged && groups,
          });
        }
      }
    },
    openRequestApiPublicAccessModalAndUpdate() {
      this.$store.commit("openLayerModal", {
        title: contentsRequestApiPublicAccessModal.modalTitle,
        component: markRaw(RequestApiPublicAccessModal),
        listeners: {
          onSubmit: (comment: string) => {
            this.updatePrivacyAndAccessPolicy(comment);
          },
        },
      });
    },
    async updatePrivacyAndAccessPolicy(comment: string) {
      if (
        this.currentApi.privacy !== this.selectedPrivacy ||
        this.currentApi.accessPolicy !== this.selectedAccessPolicy
      ) {
        await this.$store.dispatch("updateApi", {
          id: this.currentApi.id,
          privacy: this.selectedPrivacy,
          accessPolicy: this.canSetAccessType
            ? this.selectedAccessPolicy
            : EAccessPolicy.PRIVATE,
          comment,
        });
      }
    },
    changeAccessRestriction(newAccessRestriction) {
      this.aclsSuggestions = [];
      this.selectedPrivacy = newAccessRestriction;
    },
    addGroupAcl(group) {
      this.groupsAclsSelected = {
        ...this.groupsAclsSelected,
        [group.id]: {
          id: group.id,
          role: { id: this.roleForAccessRestriction },
          name: group.name,
          provider: group.provider,
        },
      };
    },
    async removeGroupAcl(id) {
      const result = await this.$swal({
        titleText: this.contents.removeGroupAccessModalTitle,
        text: this.contents.removeGroupAccessModalText,
      });
      if (result.isConfirmed) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [id]: aclToRemove, ...groupsAclsSelected } =
          this.groupsAclsSelected;
        this.groupsAclsSelected = groupsAclsSelected;
      }
    },
    init() {
      this.$store.dispatch(
        "fetchPendingAccessPolicyRequests",
        this.currentApi.id,
      );
      this.selectedPrivacy = this.currentApi.privacy;
      this.selectedAccessPolicy =
        this.currentApi.privacy === EPrivacyName.PRIVATE
          ? EAccessPolicy.SELF_SERVICE
          : this.currentApi.accessPolicy;
      this.groupsAclsSelected = this.groupAcls;
    },
  },
};
</script>

<style>
.access-restriction {
  max-width: 80rem;
}

.access-restriction__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.access-restriction i {
  color: var(--color-grey);
}

.access-restriction__on-demand-access {
  padding: 0 3rem;
  margin-top: var(--size-800);
}

.access-restriction__btn-validate {
  max-width: 80rem;
}
</style>
