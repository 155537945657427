<template>
  <LayerModalFormConfirm
    class="contract-delete-token-modal"
    :disabledConfirmBtn="form.errors.length > 0"
    @submit="submit"
    @close="close"
  >
    <div v-if="contract && token">
      <h4>{{ contents.informationTitle }}</h4>
      <ul>
        <li>{{ contents.applicationName(contract?.application?.name) }}</li>
        <li>{{ contents.apiName(getApiLabelWithVersion(contract?.api)) }}</li>
        <li>{{ expirationInformation }}</li>
      </ul>
    </div>
    <NotificationForKongSynchronization v-if="displayKongInformation" />
    <h4 class="contract-delete-token-modal__title">
      {{ contents.deletionTitle }}
    </h4>
    <Markdown :content="contents.subtitle" />
    <DvpField :errorMessage="form.firstError('text')" required>
      <MTextInput
        v-model="form.text"
        :placeholder="contents.delete"
        :isInvalid="form.hasError('text')"
      />
    </DvpField>
  </LayerModalFormConfirm>
</template>

<script lang="ts">
import MTextInput from "@mozaic-ds/vue-3/src/components/textinput/MTextInput.vue";
import { PropType } from "vue";

import DvpField from "@/commons/components/form/DvpField.vue";
import LayerModalFormConfirm from "@/commons/components/form/LayerModalFormConfirm.vue";
import NotificationForKongSynchronization from "@/dashboard/components/NotificationForKongSynchronization.vue";
import Markdown from "@/documentation/components/commons/Markdown.vue";

import { getApiLabelWithVersion } from "@/commons/libs/utils/apiUtils";
import { expireIn, toLocaleDate } from "@/commons/utils/date-utils";

import { ConfirmActionForm } from "@/commons/domain/forms/confirm-action-form";
import { Contract } from "@/commons/domain/models/contract";
import { Token } from "@/commons/domain/models/token";

import {
  ENotificationToastType,
  NotificationToast,
} from "@/commons/store/modules/notification/types";

import contents from "@/dashboard/contents/contract-delete-token-modal";

export default {
  components: {
    DvpField,
    MTextInput,
    NotificationForKongSynchronization,
    LayerModalFormConfirm,
    Markdown,
  },
  props: {
    token: {
      type: Object as PropType<Token>,
      required: true,
    },
    contract: {
      type: Object as PropType<Contract>,
      required: true,
    },
    withEnabledSecretSynchronization: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["submitted", "onClose"],
  data() {
    return {
      contents,
      form: ConfirmActionForm.create(contents.delete),
    };
  },
  computed: {
    frontendConfig() {
      return this.$store.getters["config/frontendConfig"];
    },
    displayKongInformation() {
      return this.frontendConfig.displayKongInformation;
    },
    secretManager() {
      return this.$store.getters["config/secretSynchronizationManager"];
    },
    secretSynchronizationIsEnabled(): boolean {
      return this.$store.getters["config/secretSynchronizationIsEnabled"];
    },
    displaySecretSynchronizationInformation(): boolean {
      return (
        !this.secretSynchronizationIsEnabled &&
        this.withEnabledSecretSynchronization
      );
    },
    expirationInformation(): string {
      const expirationDate = toLocaleDate(this.token?.expireAt);
      const expireInDays = expireIn(this.token?.expireAt);
      return contents.expirationDate(expirationDate, expireInDays);
    },
  },
  async mounted() {
    await this.form.init();
  },
  methods: {
    getApiLabelWithVersion,
    close() {
      this.$emit("onClose");
    },
    async submit() {
      if (this.form.errors.length === 0) {
        await this.deleteToken();
        await this.reloadContract();
        this.$emit("submitted");
        this.close();
      }
    },
    async deleteToken() {
      await this.$store.dispatch("deleteToken", {
        tokenId: this.token.id,
        contractId: this.contract.id,
        toastNotification: this.getToastNotification(),
      });
    },
    getToastNotification(): NotificationToast {
      if (this.displaySecretSynchronizationInformation) {
        return {
          title: this.contents.toastNotification.title,
          type: ENotificationToastType.INFO,
          message: this.contents.toastNotification.warningContent(
            this.secretManager,
          ),
        };
      }
      return {
        title: this.contents.toastNotification.title,
        type: ENotificationToastType.SUCCESS,
      };
    },
    async reloadContract() {
      const appId = this.contract.application.id;
      await this.$store.dispatch("loadApplicationApiKeyContracts", {
        applicationId: appId,
      });
      await this.$store.dispatch("loadCurrentApplication", {
        appId,
      });
    },
  },
};
</script>

<style lang="scss">
.contract-delete-token-modal {
  display: flex;
  flex-direction: column;
  gap: var(--base-spacing);
}
.contract-delete-token-modal__title {
  margin-bottom: 0;
}
</style>
