<template>
  <header class="layout-content-header">
    <div class="layout-content-header__content">
      <div v-if="previousPageRoute" class="layout-content-header__back-arrow">
        <router-link data-cy="navigate-before-button" :to="previousPageRoute">
          <MIcon name="ArrowBack24" />
        </router-link>
      </div>
      <div class="layout-content-header__title">
        <h4 data-cy="header-title">{{ title }}</h4>
        <slot />
        <div v-if="description" class="layout-content-header__description">
          {{ description }}
        </div>
      </div>
    </div>
    <div class="layout-content-header__actions">
      <slot name="actions" />
    </div>
  </header>
</template>

<script lang="ts">
import MIcon from "@mozaic-ds/vue-3/src/components/icon/MIcon.vue";

export default {
  name: "LayoutContentHeader",
  components: {
    MIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: undefined,
    },
    previousPageRoute: {
      type: Object,
      default: undefined,
    },
  },
};
</script>

<style scoped lang="scss">
.layout-content-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.layout-content-header__description {
  padding-bottom: 1rem;
}

.layout-content-header__back-arrow {
  display: flex;
  align-items: center;
}

.layout-content-header__content {
  display: flex;
  gap: 1rem;
}

.layout-content-header__title {
  display: flex;
  flex-direction: column;
}

.layout-content-header__actions {
  display: flex;
  align-items: center;
  gap: 1rem;
}
</style>
