import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, renderList as _renderList, createVNode as _createVNode, withModifiers as _withModifiers, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "dynamic-routing__title" }
const _hoisted_2 = { class: "dynamic-routing__title-text" }
const _hoisted_3 = { class: "dynamic-routing__headers" }
const _hoisted_4 = { class: "dynamic-routing__header" }
const _hoisted_5 = { class: "dynamic-routing__header" }
const _hoisted_6 = { class: "dynamic-routing__header" }
const _hoisted_7 = { class: "dynamic-routing__header" }
const _hoisted_8 = {
  key: 0,
  class: "dynamic-routing__header"
}
const _hoisted_9 = ["draggable", "onDragstart", "onDrop"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ViewHelpButton = _resolveComponent("ViewHelpButton")!
  const _component_MNotification = _resolveComponent("MNotification")!
  const _component_UpdateOrRemoveRoutingPolicy = _resolveComponent("UpdateOrRemoveRoutingPolicy")!
  const _component_CreateRoutingPolicy = _resolveComponent("CreateRoutingPolicy")!
  const _component_MIcon = _resolveComponent("MIcon")!

  return (!($props.isDisabled && $options.routingPoliciesSortedByOrderList.length == 0))
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass([
      'dynamic-routing',
      $props.isDisabled
        ? 'dynamic-routing--is-disabled'
        : 'dynamic-routing--is-enabled',
    ])
      }, [
        ($options.routingPoliciesSortedByOrderList.length || $data.isAddingNewCondition)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("h4", _hoisted_2, _toDisplayString($data.contents.dynamicRouting), 1),
                (!$props.isDisabled)
                  ? (_openBlock(), _createBlock(_component_ViewHelpButton, {
                      key: 0,
                      documentationAnchor: "#manage-dynamic-routing"
                    }))
                  : _createCommentVNode("", true)
              ]),
              ($props.isDisplayDynRoutingNotification)
                ? (_openBlock(), _createBlock(_component_MNotification, {
                    key: 0,
                    class: "dynamic-routing__notification",
                    type: "warning",
                    title: $data.contents.notificationTitle
                  }, null, 8, ["title"]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, _toDisplayString($data.contents.conditions), 1),
                _createElementVNode("div", _hoisted_5, _toDisplayString($data.contents.key), 1),
                _createElementVNode("div", _hoisted_6, _toDisplayString($data.contents.value), 1),
                _createElementVNode("div", _hoisted_7, _toDisplayString($data.contents.targetUrl), 1),
                (!$props.isDisabled)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString($data.contents.actions), 1))
                  : _createCommentVNode("", true)
              ])
            ], 64))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.routingPoliciesSortedByOrderList, (routingPolicy) => {
          return (_openBlock(), _createElementBlock("div", {
            key: routingPolicy.id,
            draggable: !$props.isDisabled && $data.stopDrag,
            onDragstart: ($event: any) => ($options.dragStart(routingPolicy, $event)),
            onDragover: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"])),
            onDrop: _withModifiers(($event: any) => ($options.drop(routingPolicy, $event)), ["prevent"])
          }, [
            _createVNode(_component_UpdateOrRemoveRoutingPolicy, {
              routingPolicy: routingPolicy,
              isDisabled: $props.isDisabled,
              routeEntryPoints: $props.routeEntryPoints,
              onUpdate: $options.update,
              onRemove: $options.remove,
              onStopDraggable: $options.stopDraggable,
              onAllowDraggable: $options.allowDraggable,
              onDisplayNotification: $options.displayNotification
            }, null, 8, ["routingPolicy", "isDisabled", "routeEntryPoints", "onUpdate", "onRemove", "onStopDraggable", "onAllowDraggable", "onDisplayNotification"])
          ], 40, _hoisted_9))
        }), 128)),
        ($options.routingPoliciesSortedByOrderList.length || $data.isAddingNewCondition)
          ? (_openBlock(), _createBlock(_component_CreateRoutingPolicy, {
              key: 1,
              isDisabled: $props.isDisabled,
              isEditing: $data.isAddingNewCondition,
              routeEntryPoints: $props.routeEntryPoints,
              onCreate: $options.create,
              onClose: _cache[1] || (_cache[1] = ($event: any) => ($data.isAddingNewCondition = false)),
              onEdit: $options.createOrUpdateDynamicRoutingCondition,
              onStopDraggable: $options.stopDraggable,
              onAllowDraggable: $options.allowDraggable
            }, null, 8, ["isDisabled", "isEditing", "routeEntryPoints", "onCreate", "onEdit", "onStopDraggable", "onAllowDraggable"]))
          : _createCommentVNode("", true),
        (!$options.routingPoliciesSortedByOrderList.length && !$data.isAddingNewCondition)
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              class: "dynamic-routing__btn",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($options.createOrUpdateDynamicRoutingCondition && $options.createOrUpdateDynamicRoutingCondition(...args)))
            }, [
              _createVNode(_component_MIcon, {
                class: "dynamic-routing__icon",
                name: "ControlCircleMore32",
                color: "var(--color-primary)"
              }),
              _createTextVNode(" " + _toDisplayString($data.contents.addDynamicRoute), 1)
            ]))
          : _createCommentVNode("", true)
      ], 2))
    : _createCommentVNode("", true)
}