import { EOauthFlow } from "@/commons/types/oauth-flow-types";

import contents from "@/manager/contents/authentication";

export interface OauthFlowItem {
  value: EOauthFlow;
  icon: string;
  title: string;
  description: string;
  documentationAnchor: string;
}

export const OAUTH_FLOW_ICON = {
  [EOauthFlow.CLIENT_CREDENTIALS]: require("@/commons/assets/icons/icon-auth-client-credentials.svg"),
  [EOauthFlow.AUTHORIZATION_CODE]: require("@/commons/assets/icons/icon-auth-authorization-code-flow.svg"),
};

export const OAUTH_FLOW_ITEMS: OauthFlowItem[] = [
  {
    value: EOauthFlow.CLIENT_CREDENTIALS,
    icon: OAUTH_FLOW_ICON[EOauthFlow.CLIENT_CREDENTIALS],
    title: contents.clientCredentialsTitle,
    description: contents.clientCredentialsDescription,
    documentationAnchor: "#oauth-flows",
  },
  {
    value: EOauthFlow.AUTHORIZATION_CODE,
    icon: OAUTH_FLOW_ICON[EOauthFlow.AUTHORIZATION_CODE],
    title: contents.authorizationCodeFlowTitle,
    description: contents.authorizationCodeFlowDescription,
    documentationAnchor: "#oauth-flows",
  },
];
