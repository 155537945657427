<template>
  <div class="authentication-api-key-settings">
    <AuthenticationModeSettings
      :apiId="currentApi.id"
      :requestedAuthType="EAuthType.OAUTH"
      :optionCardTitle="contents.apiKeyCardTitle"
      :optionCardDescription="contents.apiKeyCardDescription"
      authTypeDocAnchor="#api-key-authentication-mode"
      :authChangeIntro="contents.changeToOauthIntro"
      :authChangeButtonLabel="contents.changeToOauthBtn"
    />
  </div>
</template>

<script lang="ts">
import AuthenticationModeSettings from "@/manager/views/ApiDetailAuthentication/AuthenticationModeSettings.vue";

import { Api } from "@/commons/domain/models/api";

import { EAuthType } from "@/commons/store/types";

import contents from "@/manager/contents/authentication";

export default {
  components: {
    AuthenticationModeSettings,
  },
  data() {
    return {
      contents,
      EAuthType,
    };
  },
  computed: {
    currentApi(): Api {
      return this.$store.getters["currentApi"];
    },
  },
};
</script>
