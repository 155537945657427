<template>
  <table class="cookie-array">
    <thead>
      <tr>
        <th>{{ contents.name }}</th>
        <th>{{ contents.provider }}</th>
        <th>{{ contents.finality }}</th>
        <th>{{ contents.expiration }}</th>
        <th>{{ contents.type }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(cookie, index) in data" :key="`${cookie.name}-${index}`">
        <td v-for="(infoCookie, i) in cookie" :key="`cell-${i}`">
          {{ infoCookie }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import contents from "./cookie-array";

export default {
  name: "CookieArray",
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      contents,
    };
  },
  methods: {
    getTitles() {
      return Object.keys(this.data);
    },
  },
};
</script>

<style lang="scss">
table.cookie-array {
  width: 100%;
  border-collapse: collapse;
  border: var(--border-default);
}
.cookie-array th,
.cookie-array td {
  padding: 0.2rem 0.5rem;
  text-align: left;
  border: var(--border-default);
}
.cookie-array th {
  color: var(--color-white);
  background-color: var(--color-text);
}
</style>
