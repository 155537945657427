<template>
  <LayerModalFormConfirm
    class="contract-ask-validation-modal"
    confirmBtnTheme="solid"
    :disabledConfirmBtn="form.errors.length > 0"
    @submit="submit"
    @close="close"
  >
    <StepperApiSubscription
      :stepperDescription="contents.stepperDescription"
      :stepperSteps="stepperSteps"
    />

    <h4 class="contract-ask-validation-modal__title">{{ contents.title }}</h4>

    <div>{{ contents.description }}</div>

    <DvpField
      required
      :label="contents.commentField"
      :errorMessage="form.firstError('comment')"
    >
      <MTextArea
        v-model="form.comment"
        class="contract-ask-validation-modal__textarea"
        :placeholder="contents.placeholder"
        :isInvalid="form.firstError('comment') != undefined"
      />
    </DvpField>
  </LayerModalFormConfirm>
</template>

<script lang="ts">
import MTextArea from "@mozaic-ds/vue-3/src/components/textarea/MTextArea.vue";
import { PropType } from "vue";

import DvpField from "@/commons/components/form/DvpField.vue";
import LayerModalFormConfirm from "@/commons/components/form/LayerModalFormConfirm.vue";
import StepperApiSubscription from "@/dashboard/views/AppDetailsContracts/StepperApiSubscription.vue";

import { CommentForm } from "@/commons/forms/comment-form";

import contents from "@/dashboard/contents/contract-ask-validation-modal";
import contentSubscriptionStepper from "@/dashboard/contents/subscription-stepper";

export default {
  components: {
    StepperApiSubscription,
    DvpField,
    MTextArea,
    LayerModalFormConfirm,
  },
  props: {
    passedSteps: {
      type: Array as PropType<{ label: string; isCurrent: boolean }[]>,
      required: true,
    },
  },
  emits: ["submit", "onClose"],
  data() {
    return {
      contents,
      form: CommentForm.create(),
    };
  },
  computed: {
    isSaving() {
      return this.$store.getters["isSaving"];
    },
    stepperSteps() {
      const steps = [...this.passedSteps];
      steps.push({
        label: contentSubscriptionStepper.stepSendValidationRequestTitle,
        isCurrent: true,
      });

      return steps;
    },
  },
  async mounted() {
    await this.form.init();
  },
  methods: {
    submit(): void {
      const { comment } = this.form.data();
      this.$emit("submit", comment);
      this.close();
    },
    close(): void {
      this.$emit("onClose");
    },
  },
};
</script>

<style lang="scss">
.contract-ask-validation-modal__title {
  margin: 0;
}

.contract-ask-validation-modal__textarea {
  min-height: 10rem;
}
</style>
