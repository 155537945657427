<template>
  <div class="grid grid-layout">
    <slot />
  </div>
</template>

<script lang="ts">
export default {
  name: "Grid",
};
</script>

<style lang="scss">
.grid-layout {
  display: grid;
  grid-template-columns: repeat(
    var(--grid-layout-num-columns, auto-fill),
    minmax(var(--grid-layout-item-width, 25rem), 1fr)
  );
  grid-auto-rows: var(--grid-auto-rows, 1fr);
  grid-gap: var(--grid-layout-gap, 1rem);
}
</style>
