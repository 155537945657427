<template>
  <div class="api-card">
    <div class="card-infos">
      <div class="card-icon">
        <img :src="accesPolicyIcon" />
      </div>
      <div class="card-body">
        <div class="card-body__content">
          <Tooltip v-if="tooltipTitle" :tooltipContent="tooltipTitle">
            <div class="card-title">
              <h3 class="api-card__name">{{ title }}</h3>
            </div>
          </Tooltip>
          <router-link
            v-if="showProductName && api.product"
            class="product-name"
            :to="'/docs/product/' + api.product.id"
          >
            {{ `from "${api.product.name}"` }}
          </router-link>
          <p>{{ description }}</p>
          <div class="card-slot">
            <slot />
          </div>
        </div>
        <div class="card-body__version">
          <h4>{{ version }}</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { getAccesPolicyIcon } from "./getAccessPolicyIcon";

import Tooltip from "@/commons/components/Tooltip/Tooltip.vue";

import { apiLabel } from "@/commons/libs/utils/apiUtils";
import { shortenString, shortenWords } from "@/commons/libs/utils/stringUtils";

export default {
  name: "ApiListItem",
  components: { Tooltip },
  props: {
    api: {
      type: Object,
      required: true,
    },
    showProductName: {
      type: Boolean,
      default: true,
    },
    maxDescLength: {
      type: Number,
      default: 120,
    },
    maxTitleLength: {
      type: Number,
      default: 30,
    },
  },
  computed: {
    accesPolicyIcon() {
      return getAccesPolicyIcon(this.api.accessPolicy);
    },
    title() {
      return shortenString(apiLabel(this.api), this.maxTitleLength);
    },
    tooltipTitle() {
      return apiLabel(this.api);
    },
    description() {
      return !this.api.description
        ? ""
        : shortenWords(this.api.description, this.maxDescLength);
    },
    version() {
      return `v ${this.api.version || "1"}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/commons/assets/styles/vars";

.api-card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
  border: var(--border-default);
  border-radius: 6px;
  transition: box-shadow 0.3s ease-in-out;

  .card-infos {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  &:hover {
    box-shadow: var(--m-shadow-medium);
  }

  .card-title {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    height: 1.5rem;

    h3 {
      display: inline;
      padding: 0;
      margin: 0;
      font-size: 1.1rem;
      line-height: 1rem;
    }

    .access-icon {
      margin-right: 0.25rem;
      font-size: 1.4rem;
      color: var(--color-grey);
    }
  }

  p {
    display: block;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    margin-left: 3px;
    overflow: hidden;
    color: var(--color-grey);
    overflow-wrap: anywhere;
  }

  .product-name {
    display: table;
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
    margin-left: 3px;
    font-size: 0.925rem;
    font-style: italic;
    font-weight: 700;
    &:hover {
      text-decoration: none;
    }
  }

  .card-slot {
    overflow: hidden;
  }

  .card-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .card-body__version {
    display: block;
    flex-shrink: 0;
    width: 2.1rem;
    height: 1.3rem;
    margin-right: 3px;
    text-align: center;
    border: var(--border-default);
    border-radius: 10px;

    h4 {
      margin: 0.5rem 0;
      font-size: 0.8rem;
      line-height: 0.1rem;
    }
  }

  .card-slot,
  .card-body {
    font-size: 0.9rem;
    line-height: 1.25rem;
  }

  .card-icon {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
  }
}
</style>
