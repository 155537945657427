<template><div class="swagger-ui-acf-code-handler"></div></template>

<script lang="ts">
export default {
  created() {
    const code = this.$route.query["code"];

    if (
      code == undefined ||
      window.opener?.retrieveAcfAccessToken == undefined
    ) {
      this.$router.replace({ name: "pageForbidden" });
    } else {
      window.opener.retrieveAcfAccessToken(code);
      window.close();
    }
  },
};
</script>
