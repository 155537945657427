<script setup lang="ts">
import MNotification from "@mozaic-ds/vue-3/src/components/notification/MNotification.vue";
import MSelect from "@mozaic-ds/vue-3/src/components/select/MSelect.vue";
import MTextArea from "@mozaic-ds/vue-3/src/components/textarea/MTextArea.vue";
import MTextInput from "@mozaic-ds/vue-3/src/components/textinput/MTextInput.vue";
import { computed, onMounted, PropType, reactive, watch } from "vue";
import { useStore } from "vuex";

import CardContainer from "@/commons/components/CardContainer.vue";
import ViewHelpButton from "@/commons/components/UserDocumentationLinks/ViewHelpButton.vue";
import DvpField from "@/commons/components/form/DvpField.vue";
import Markdown from "@/documentation/components/commons/Markdown.vue";

import { getScopeRepository } from "@/commons/repositories/libs/get-scope-repository";
import { splitPredefinedScope } from "@/manager/utils/oauth-scopes";

import { Scope } from "@/commons/domain/models/scope";
import { Tag } from "@/commons/domain/models/tag";
import {
  CreateOAuthScopeForm,
  CreateScopeNameForm,
} from "@/commons/forms/create-oauth-scope-form";

import { EOAuthScopeAction } from "@/commons/store/types";

import contents from "@/manager/contents/add-oauth-scopes";

const props = defineProps({
  apiId: {
    type: String,
    required: true,
  },
  predefinedScope: {
    type: Object as PropType<Scope>,
    default: undefined,
  },
});

const emit = defineEmits(["scopeToCreate"]);

const store = useStore();

/**
 * DO NOT CHANGE THE ORDER OF THE ACTIONS
 */
const actions = [
  EOAuthScopeAction.READ,
  EOAuthScopeAction.WRITE,
  EOAuthScopeAction.ADMIN,
];

/**
 * Create new scope form and its fields: name, description
 * Name is composed of element, subElement, businessUnit, action that are handled by separate ref newScope
 */

const form = reactive(CreateOAuthScopeForm.create());

const scopeNameForm = reactive(CreateScopeNameForm.create());

const calculatedScopeName = computed(() => {
  const subElement =
    scopeNameForm.subElement !== "" ? `.${scopeNameForm.subElement}` : "";
  const businessUnit =
    scopeNameForm.businessUnit !== "" ? `:${scopeNameForm.businessUnit}` : "";

  return `${scopeNameForm.element}${subElement}${businessUnit}:${scopeNameForm.action}`;
});

onMounted(async () => {
  if (props.predefinedScope != undefined) {
    const { element, subElement, businessUnit, action } = splitPredefinedScope(
      props.predefinedScope.name,
      businessUnitsList.value.map((bu) => bu.value),
      actions,
    );
    await scopeNameForm.init({
      element: element ?? "",
      subElement: subElement ?? "",
      businessUnit: businessUnit ?? "",
      action: action ?? "",
    });
    await scopeNameForm.validate();
    await form.init({
      name: calculatedScopeName.value,
      description: props.predefinedScope.description,
    });
    await form.validate();
  } else {
    await scopeNameForm.init();
    await form.init();
  }
});

const errorHandler = reactive({
  backendErrorMessage: undefined,
  updateBackendErrorMessage: async (scopeName: string) => {
    errorHandler.backendErrorMessage = (
      await getScopeRepository().validateScopeName(scopeName, props.apiId)
    ).message;
  },
  localErrorMessage: computed(() => {
    if (scopeNameForm.pristine) {
      return undefined;
    } else if (scopeNameForm.errors.length > 0) {
      return scopeNameForm.errors[0].messages[0];
    } else if (form.firstError("name") != undefined) {
      return form.firstError("name");
    }
    return undefined;
  }),
  scopeNameErrorMessage: computed(() => {
    if (errorHandler.localErrorMessage != undefined) {
      return errorHandler.localErrorMessage;
    } else if (errorHandler.backendErrorMessage != undefined) {
      return errorHandler.backendErrorMessage;
    }
    return undefined;
  }),
});

watch(
  calculatedScopeName,
  async () => {
    await form.update({
      name: calculatedScopeName.value,
    });
    if (
      scopeNameForm.errors.length === 0 &&
      form.firstError("name") == undefined
    ) {
      await errorHandler.updateBackendErrorMessage(calculatedScopeName.value);
    }
  },
  { deep: true },
);

const isValidNewScope = computed(() => {
  return (
    form.errors.length === 0 && errorHandler.scopeNameErrorMessage == undefined
  );
});

watch(form, async () => {
  emit("scopeToCreate", isValidNewScope.value ? form.data() : undefined);
});

/**
 * Select options for business units and actions
 */

const businessUnitsList = computed(() => {
  // BU "ALL" should not be proposed while scope creation => one BU per scope
  const transformedList = store.getters["buTagsList"]
    .filter((bu: Tag) => bu.label !== "ALL")
    .map((bu: Tag) => {
      const lowerCaseDescription = bu.label.toLowerCase();
      return {
        text: lowerCaseDescription,
        value: lowerCaseDescription,
      };
    });

  return [
    {
      text: "None",
      value: "none",
      disabled: scopeNameForm.businessUnit === "",
    },
    { text: "-----------------", value: "separator", disabled: true },
    ...transformedList,
  ];
});

const updateBusinessUnit = async (value: string) => {
  if (value === "none" || value === "separator") {
    await scopeNameForm.update({ businessUnit: "" });
  }
};

const actionOptions = computed(() => {
  return actions.map((action) => {
    return {
      value: action,
      text: action,
    };
  });
});
</script>

<template>
  <CardContainer class="create-new-scope">
    <div class="create-new-scope__configure-new-scope">
      <div
        data-cy="configure-scope-title"
        class="create-new-scope__configure-new-scope-header"
      >
        <h5>{{ contents.configureYourScopeTitle }}</h5>
        <span>{{ contents.typeYourScopeSubTitle }}</span>
      </div>

      <form class="create-new-scope__configure-new-scope-form">
        <div class="create-new-scope__configure-new-scope-form-name">
          <DvpField
            class="create-new-scope__configure-new-scope-form-field"
            :label="contents.elementLabel"
            required
          >
            <MTextInput
              v-model="scopeNameForm.element"
              data-cy="configure-scope-element"
              :placeholder="contents.elementPlaceholder"
            />
          </DvpField>
          <DvpField
            class="create-new-scope__configure-new-scope-form-field"
            :label="contents.subElementLabel"
          >
            <MTextInput
              v-model="scopeNameForm.subElement"
              data-cy="configure-scope-sub-element"
              :placeholder="contents.subElementPlaceholder"
            />
          </DvpField>
          <DvpField :label="contents.businessUnitsLabel">
            <MSelect
              v-model="scopeNameForm.businessUnit"
              data-cy="configure-scope-business-unit"
              :placeholder="contents.businessUnitsPlaceholder"
              :options="businessUnitsList"
              @update:model-value="updateBusinessUnit"
            />
          </DvpField>
          <DvpField required :label="contents.actionLabel">
            <MSelect
              v-model="scopeNameForm.action"
              data-cy="configure-scope-action"
              :placeholder="contents.actionPlaceholder"
              :options="actionOptions"
            />
          </DvpField>
        </div>
        <DvpField
          :label="contents.yourScopeIdWillBeLabel"
          :errorMessage="errorHandler.scopeNameErrorMessage"
        >
          <MTextInput
            data-cy="configure-scope-summary"
            disabled
            :modelValue="form.name"
            :isInvalid="errorHandler.scopeNameErrorMessage != undefined"
          />
        </DvpField>
        <DvpField
          required
          :label="contents.descriptionLabel"
          :errorMessage="form.firstError('description')"
          :isInvalid="form.firstError('description') != undefined"
        >
          <MTextArea
            v-model="form.description"
            data-cy="configure-scope-description"
            :placeholder="contents.descriptionPlaceholder"
            :isInvalid="form.firstError('description') != undefined"
            :rows="10"
          />
        </DvpField>
      </form>
    </div>
    <div class="create-new-scope__scope-best-practices-notification">
      <MNotification
        data-cy="scope-best-practices-notification"
        type="information"
        :title="contents.scopeBestPracticeNotificationTitle"
      >
        <Markdown :content="contents.scopeBestPracticeNotificationContent" />
        <template #footer>
          <ViewHelpButton
            data-cy="view-help-scopes-link"
            documentationAnchor="#configure-supported-scopes"
          />
        </template>
      </MNotification>
    </div>
  </CardContainer>
</template>

<style lang="scss">
.create-new-scope {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  padding: 2rem;
}

.create-new-scope__configure-new-scope,
.create-new-scope__configure-new-scope-form {
  display: flex;
  flex-direction: column;
  gap: var(--base-spacing);
}

.create-new-scope__configure-new-scope {
  flex: 3;
}

.create-new-scope__scope-best-practices-notification {
  width: 23rem;
}

.create-new-scope__configure-new-scope-form-name {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
}

.create-new-scope__configure-new-scope-form-field {
  width: 11rem;
}
</style>
