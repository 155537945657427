import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "multiselect__single"
}
const _hoisted_2 = { class: "reporting-select-filter__option" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MIcon = _resolveComponent("MIcon")!
  const _component_Multiselect = _resolveComponent("Multiselect")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['reporting-select-filter', { loading: $props.loading }])
  }, [
    _createVNode(_component_Multiselect, {
      modelValue: $props.modelValue,
      options: $props.options,
      closeOnSelect: false,
      clearOnSelect: "",
      preserveSearch: "",
      selectedLabel: "",
      deselectedLabel: "",
      deselectLabel: "",
      selectLabel: "",
      searchable: $props.searchable,
      placeholder: $props.placeholder,
      label: $props.label,
      trackBy: $props.trackBy,
      loading: $props.loading,
      multiple: "",
      isOpen: "",
      onClose: $props.onFilterClose,
      "onUpdate:modelValue": $options.updateSelected
    }, {
      selection: _withCtx(({ values, isOpen }) => [
        ((isOpen && !$props.searchable) || (values.length && !isOpen))
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString($options.selectedLabelsList(values, isOpen)), 1))
          : _createCommentVNode("", true)
      ]),
      option: _withCtx((props) => [
        _createElementVNode("span", _hoisted_2, _toDisplayString(props.option[$props.label]), 1),
        ($options.optionIsSelected(props.option))
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
              _createVNode(_component_MIcon, {
                name: "NotificationAvailable16",
                color: "var(--color-brand-primary)"
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue", "options", "searchable", "placeholder", "label", "trackBy", "loading", "onClose", "onUpdate:modelValue"])
  ], 2))
}