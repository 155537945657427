import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "redirect-uri-settings-card__card-title" }
const _hoisted_2 = {
  key: 0,
  class: "redirect-uri-settings-card__redirect-uris-listing-actions"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MLoader = _resolveComponent("MLoader")!
  const _component_Markdown = _resolveComponent("Markdown")!
  const _component_ButtonEdit = _resolveComponent("ButtonEdit")!
  const _component_ButtonDelete = _resolveComponent("ButtonDelete")!
  const _component_ActionButtonsWrapper = _resolveComponent("ActionButtonsWrapper")!
  const _component_MDataTable = _resolveComponent("MDataTable")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_CardContainer = _resolveComponent("CardContainer")!

  return ($options.isLoadingRedirectUrisOfCurrentApplication)
    ? (_openBlock(), _createBlock(_component_MLoader, {
        key: 0,
        class: "redirect-uri-settings-card__loader",
        size: "l",
        text: $data.contents.loadingRedirectUris
      }, null, 8, ["text"]))
    : ($options.redirectUrisOfCurrentApplication?.length > 0)
      ? (_openBlock(), _createBlock(_component_CardContainer, {
          key: 1,
          class: "redirect-uri-settings-card"
        }, {
          default: _withCtx(() => [
            _createElementVNode("h4", _hoisted_1, _toDisplayString($data.contents.redirectUrisTitle), 1),
            _createVNode(_component_Markdown, {
              content: $data.contents.redirectUrisDescription
            }, null, 8, ["content"]),
            _createVNode(_component_MDataTable, {
              class: "redirect-uri-settings-card__redirect-uris-listing",
              sorting: { mode: 'none' },
              headers: $options.tableHeaders,
              source: $options.redirectUriItems
            }, {
              [`item.actions`]: _withCtx(({ item }) => [
                _createVNode(_component_ActionButtonsWrapper, null, {
                  default: _withCtx(() => [
                    ($options.userHasWriteAccessOnCurrentApplication)
                      ? (_openBlock(), _createBlock(_component_ButtonEdit, {
                          key: 0,
                          onClick: ($event: any) => ($options.onEditUriClick(item.value))
                        }, null, 8, ["onClick"]))
                      : _createCommentVNode("", true),
                    (
              $options.userHasWriteAccessOnCurrentApplication &&
              $options.redirectUriItems.length > 1
            )
                      ? (_openBlock(), _createBlock(_component_ButtonDelete, {
                          key: 1,
                          onClick: ($event: any) => ($options.onDeleteUriClick(item.value))
                        }, null, 8, ["onClick"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["headers", "source"]),
            ($options.userHasWriteAccessOnCurrentApplication)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_MButton, {
                    label: $data.contents.addRedirectUriButton,
                    type: "button",
                    theme: "bordered",
                    size: "s",
                    onClick: $options.onAddUriClick
                  }, null, 8, ["label", "onClick"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
}