<template>
  <Layout2 class="groups-settings">
    <LayoutSection background="primary" noVerticalPadding fullWidthContent>
      <LayoutContentHeader :title="contents.myGroups" />
    </LayoutSection>
    <GroupsListingSection
      v-if="pagedPersonalGroups != undefined"
      :groups="personalGroups"
      :totalPages="personalGroupsTotalPages"
      :searchText="urlQuery.term"
      @groupUpdated="loadPersonalGroups"
      @groupDeleted="onGroupDeleted"
    />
  </Layout2>
</template>

<script lang="ts">
import { GroupsSettingsUrlQuery } from "@/user/routes";
import { PropType } from "vue";

import Layout2 from "@/commons/components/Layout2.vue";
import LayoutContentHeader from "@/commons/components/LayoutContentHeader.vue";
import LayoutSection from "@/commons/components/LayoutSection.vue";
import GroupsListingSection from "@/commons/views/GroupsListingSection.vue";

import { getGroupWithUsersAclsRepository } from "@/commons/repositories/libs/get-group-with-users-acls-repository";

import { GroupFetchParams } from "@/commons/domain/repositories/group-with-users-acls-repository";

import contents from "@/commons/contents/groups-listing-section";

export default {
  components: {
    GroupsListingSection,
    Layout2,
    LayoutSection,
    LayoutContentHeader,
  },
  props: {
    urlQuery: {
      type: Object as PropType<GroupsSettingsUrlQuery>,
      required: true,
    },
  },
  data() {
    return {
      contents,
      pagedPersonalGroups: undefined,
    };
  },
  computed: {
    personalGroups() {
      return (
        this.pagedPersonalGroups?.data &&
        Object.values(this.pagedPersonalGroups?.data)
      );
    },
    personalGroupsTotalPages() {
      return this.pagedPersonalGroups?.totalPages;
    },
  },
  watch: {
    urlQuery() {
      this.loadPersonalGroups();
    },
  },
  async created() {
    await this.loadPersonalGroups();
  },
  methods: {
    buildFetchParams({ term, page }: GroupsSettingsUrlQuery): GroupFetchParams {
      return {
        page,
        name: term,
      };
    },
    async loadPersonalGroups(): Promise<void> {
      this.pagedPersonalGroups =
        await getGroupWithUsersAclsRepository().cancelAndFind({
          ...this.buildFetchParams(this.urlQuery),
          computeActions: true,
        });
    },
    onGroupDeleted(): void {
      this.loadPersonalGroups();
    },
  },
};
</script>
