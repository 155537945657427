import { CorsOriginForm } from "./cors-origin-form";
import { ArrayNotEmpty, Validate, ValidateNested } from "class-validator";

import { ArrayOfForms } from "@/commons/domain/forms/array-of-forms";
import { Form } from "@/commons/domain/forms/form";
import { FormFactory } from "@/commons/domain/forms/form-factory";
import { CorsOrigin } from "@/commons/domain/models/cors-origin";
import { FieldValueIsUniqueInList } from "@/commons/forms/class-validator/custom-validators/field-value-is-unique-in-list";

export class CorsOriginsListForm extends ArrayOfForms<CorsOriginForm> {
  @Validate(FieldValueIsUniqueInList, ["value", "values"], {
    message: "An origin already exists with the same value",
    each: true,
  })
  @ArrayNotEmpty({ message: "Allowed origins is empty" })
  @ValidateNested({ message: "One or more origins aren't valid" })
  values: Form<CorsOriginForm>[] = [];

  constructor(corsOriginsList: {
    values: CorsOrigin[];
    newValue?: CorsOrigin;
  }) {
    super();
    this.values = this.createCorsOriginForms(corsOriginsList.values);
    this.newValue = this.createCorsOriginForm(corsOriginsList.newValue);
  }

  $update(
    partial: Partial<{
      values: CorsOrigin[];
    }>,
  ) {
    const { values } = partial;

    if (values) {
      this.values = this.createCorsOriginForms(values);
    }
  }

  $addValue(corsOriginValue: string) {
    const valueForm = this.createCorsOriginForm({
      id: this.getNewId(),
      value: corsOriginValue,
    });
    valueForm.validate();
    this.values.push(valueForm);
  }

  private createCorsOriginForms(corsOrigins: CorsOrigin[] = []) {
    const corsOriginsForms = [];

    for (const corsOrigin of corsOrigins) {
      corsOriginsForms.push(this.createCorsOriginForm(corsOrigin));
    }

    return corsOriginsForms;
  }

  private createCorsOriginForm(corsOrigin?: CorsOrigin) {
    return CorsOriginForm.create(corsOrigin);
  }

  private getNewId() {
    const lastCorsOrigin = this.values[(this.values.length || 1) - 1];
    return (lastCorsOrigin && lastCorsOrigin.data().id + 1) || 0;
  }

  static create(
    corsOriginsList: { values: CorsOrigin[]; newValue?: CorsOrigin } = {
      values: [],
    },
  ) {
    return FormFactory.createForm(new CorsOriginsListForm(corsOriginsList));
  }
}
