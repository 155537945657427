import {
  ValidationArguments,
  ValidatorConstraint,
  ValidatorConstraintInterface,
} from "class-validator";

import { Dictionary } from "@/commons/domain/models/dictionary";
import { RoutingPolicyCondition } from "@/commons/domain/models/routing-policy-condition";

@ValidatorConstraint({
  name: "PolicyHttpMethodsConditionValid",
  async: false,
})
export class PolicyHttpMethodsConditionValid
  implements ValidatorConstraintInterface
{
  validate(conditions: Dictionary<RoutingPolicyCondition>) {
    let passValidationForm = true;
    for (const conditionId in conditions) {
      const condition = conditions[conditionId];
      if (condition.type === "httpMethods" && condition.value.length === 0) {
        passValidationForm = false;
      }
    }
    return passValidationForm;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  defaultMessage(args: ValidationArguments) {
    return `You don't have select a method`;
  }
}
