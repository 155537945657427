import { TargetUrlForm } from "./target-url-form";
import { ValidateNested } from "class-validator";

import { ArrayOfForms } from "@/commons/domain/forms/array-of-forms";
import { Form } from "@/commons/domain/forms/form";
import { FormFactory } from "@/commons/domain/forms/form-factory";

export interface TargetUrlFormValue {
  url: string;
  zoneIds: string[];
}

export class TargetUrlsListForm extends ArrayOfForms<TargetUrlForm> {
  @ValidateNested()
  values: Form<TargetUrlForm>[] = [];

  newValue: Form<TargetUrlForm> = undefined;

  constructor() {
    super();
    this.newValue = TargetUrlForm.create();
    this.values = [];
  }

  $update({
    newValue,
    values,
  }: {
    newValue: TargetUrlFormValue;
    values: TargetUrlFormValue[];
  }) {
    if (newValue) {
      const newTargetUrlForm = TargetUrlForm.create();
      newTargetUrlForm.update(newValue);
      this.newValue = newTargetUrlForm;
    }

    if (values) {
      const targetUrlsForms = [];

      for (const value of values) {
        const targetUrlForm = TargetUrlForm.create();
        targetUrlForm.update(value);
        targetUrlsForms.push(targetUrlForm);
      }
      this.values = targetUrlsForms;
    }
  }

  $data() {
    return this.values.reduce((result, form) => [...result, form.data()], []);
  }

  static create() {
    return FormFactory.createForm(new TargetUrlsListForm());
  }
}
