import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "multi-acf-providers-prevention-step-2-modal__content" }
const _hoisted_2 = { class: "multi-acf-providers-prevention-step-2-modal__irrevocable-info" }
const _hoisted_3 = { class: "multi-acf-providers-prevention-step-2-modal__api-list-section" }
const _hoisted_4 = { class: "multi-acf-providers-prevention-step-2-modal__api-list" }
const _hoisted_5 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DvpStepper = _resolveComponent("DvpStepper")!
  const _component_Markdown = _resolveComponent("Markdown")!
  const _component_MTextInput = _resolveComponent("MTextInput")!
  const _component_DvpField = _resolveComponent("DvpField")!
  const _component_LayerModalFormConfirm = _resolveComponent("LayerModalFormConfirm")!

  return (_openBlock(), _createBlock(_component_LayerModalFormConfirm, {
    class: "multi-acf-providers-prevention-step2-modal",
    confirmBtnTheme: "solid-danger",
    confirmBtnLabel: $data.contents.confirmButtonStep2,
    disabledConfirmBtn: 
      $data.form.pristine || $data.form.errors.length > 0 || $options.isSavingSubmission
    ,
    onSubmit: $options.submit,
    onClose: $options.close
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_DvpStepper, {
          class: "multi-acf-providers-prevention-step-2-modal__stepper",
          steps: $options.stepperSteps,
          stepperDescription: $data.contents.stepperDescription
        }, null, 8, ["steps", "stepperDescription"]),
        _createElementVNode("h4", null, _toDisplayString($data.contents.titleStep2), 1),
        _createElementVNode("p", _hoisted_2, _toDisplayString($data.contents.actionCannotBeUndone), 1),
        _createVNode(_component_Markdown, {
          class: "multi-acf-providers-prevention-step-2-modal__confirmation-field-description",
          content: $data.contents.unsubscriptionInputDescription
        }, null, 8, ["content"]),
        _createVNode(_component_DvpField, {
          class: "multi-acf-providers-prevention-step-2-modal__confirmation-field",
          required: "",
          errorMessage: $data.form.firstError('text')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_MTextInput, {
              modelValue: $data.form.text,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.form.text) = $event)),
              type: "text",
              placeholder: $data.contents.unsubscribe,
              isInvalid: $data.form.hasError('text')
            }, null, 8, ["modelValue", "placeholder", "isInvalid"])
          ]),
          _: 1
        }, 8, ["errorMessage"]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("p", null, _toDisplayString($data.contents.listOfImpactedApis), 1),
          _createElementVNode("ul", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.apisWithACFSubscription, (api) => {
              return (_openBlock(), _createElementBlock("li", {
                key: api.id,
                class: "multi-acf-providers-prevention-step-2-modal__api-list-item"
              }, _toDisplayString(api.label), 1))
            }), 128)),
            (
              $props.paginatedAcfContractsOfApplication.totalCount >
              $props.paginatedAcfContractsOfApplication.count
            )
              ? (_openBlock(), _createElementBlock("li", _hoisted_5, _toDisplayString($data.contents.andMore), 1))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["confirmBtnLabel", "disabledConfirmBtn", "onSubmit", "onClose"]))
}