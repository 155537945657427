<template>
  <LayoutContentHeader
    :title="contents.welcome(user.firstname)"
    :description="randomQuote"
  />
</template>

<script lang="ts">
import LayoutContentHeader from "@/commons/components/LayoutContentHeader.vue";

import { User } from "@/commons/domain/models/user";

import contents from "@/dashboard/contents/my-apps-heading";

export default {
  name: "MyAppsHeading",
  components: {
    LayoutContentHeader,
  },
  data() {
    return {
      contents,
    };
  },
  computed: {
    user(): User {
      return this.$store.getters["user"];
    },
    randomQuote(): string {
      return this.getRandomQuote(contents.randomQuotes);
    },
  },
  methods: {
    getRandomQuote(quotes: string[]): string {
      return quotes[Math.round(Math.random() * (quotes.length - 1))] || "";
    },
  },
};
</script>
