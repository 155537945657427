<template>
  <CardListed class="subscribable-api-card">
    <div class="subscribable-api-card__main-section">
      <div class="subscribable-api-card__header">
        <div class="subscribable-api-card__label-header">
          <h6 class="subscribable-api-card__api-label">
            {{ getApiLabel(api) }}
          </h6>
          <h6 class="subscribable-api-card__product">
            {{ contents.from }}
            "<RouterLink
              :to="{
                name: 'productDocumentationDetail',
                params: {
                  id: api.product.id,
                },
              }"
              target="_blank"
              >{{ api.product.name }}</RouterLink
            >"
          </h6>
        </div>
        <div class="subscribable-api-card__action-header">
          <IconButton
            class="subscribable-api-card__documentation-link"
            mozaicIconName="BookStore24"
            :href="
              convertRouteToHref({
                name: 'apiDocumentationDetail',
                params: { id: api.id },
              })
            "
            target="_blank"
          />
          <SubscriptionButton :api="api" />
        </div>
      </div>
      <div class="subscribable-api-card__flag-list">
        <MFlag
          v-for="(flag, index) in flags"
          :key="index"
          :label="flag.label"
          :theme="flag.theme"
        />
      </div>
      <p class="subscribable-api-card__description">{{ api.description }}</p>
    </div>
    <template v-if="subscriptionRequiresValidation" #footer>
      <div class="subscribable-api-card__footer">
        <div class="subscribable-api-card__on-demand-info">
          <IconLabel
            iconName="Security24"
            :label="contents.requiresAuthorization"
            size="s"
          />
        </div>
      </div>
    </template>
  </CardListed>
</template>

<script lang="ts">
import MFlag from "@mozaic-ds/vue-3/src/components/flag/MFlag.vue";
import { PropType } from "vue";

import CardListed from "@/commons/components/CardListed/CardListed.vue";
import IconButton from "@/commons/components/IconButton.vue";
import IconLabel from "@/commons/components/IconLabel.vue";
import SubscriptionButton from "@/dashboard/views/AppDetailsContracts/SubscriptionButton.vue";

import { getApiLabel } from "@/commons/libs/utils/apiUtils";
import { convertRouteToHref } from "@/commons/utils/route-utils";

import { Api } from "@/commons/domain/models/api";
import { MFlagItem } from "@/commons/domain/models/mozaic";

import { EAuthType } from "@/commons/store/types";

import contents from "@/commons/contents/subscribable-api-card";

export default {
  components: {
    CardListed,
    MFlag,
    IconLabel,
    SubscriptionButton,
    IconButton,
  },
  props: {
    api: {
      type: Object as PropType<Api>,
      required: true,
    },
  },
  data() {
    return {
      contents,
    };
  },
  computed: {
    flags(): MFlagItem[] {
      return [this.versionFlag, this.authTypeFlag, ...this.oauthFlowFlags];
    },
    versionFlag(): MFlagItem {
      return {
        label: contents.flagLabelVersion(this.api.version),
        theme: "bordered-dark",
      };
    },
    authTypeFlag(): MFlagItem {
      return {
        label: contents.flagLabelAuthType[this.api.authType],
        theme: "bordered",
      };
    },
    oauthFlowFlags(): MFlagItem[] {
      if (this.api.authType === EAuthType.OAUTH) {
        return this.api.oauthFlows.map((oauthFlow) => ({
          label: contents.flagLabelOauthFlow[oauthFlow],
          theme: "bordered-primary-02",
        }));
      } else {
        return [];
      }
    },
    subscriptionRequiresValidation(): boolean {
      return (
        this.api.allowedActions["api.subscription.request.by-pass"] === false
      );
    },
  },
  methods: {
    getApiLabel,
    convertRouteToHref,
  },
};
</script>

<style lang="scss">
.subscribable-api-card__main-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.subscribable-api-card__header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;
}

.subscribable-api-card__action-header {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.subscribable-api-card__footer {
  padding: 1rem;
  border-top: var(--border-default);
}

.subscribable-api-card__api-label {
  margin: 0;
  margin-bottom: 0.25rem;
}

.subscribable-api-card__product {
  margin: 0;
  font-weight: var(--weight-regular);
  color: var(--color-text-minor);

  @include set-text-s;
}

.subscribable-api-card__flag-list {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.subscribable-api-card__description {
  @include set-text-s;
  margin: 0;
  color: var(--color-text-minor);
}
</style>
