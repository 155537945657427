import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MAutocomplete = _resolveComponent("MAutocomplete")!
  const _component_DvpField = _resolveComponent("DvpField")!
  const _component_MCheckboxGroup = _resolveComponent("MCheckboxGroup")!
  const _component_LayerModalFormConfirm = _resolveComponent("LayerModalFormConfirm")!

  return (_openBlock(), _createBlock(_component_LayerModalFormConfirm, {
    class: "modal-filters-my-apps",
    confirmBtnLabel: $data.contents.confirmButtonLabel,
    confirmBtnTheme: "solid",
    disabledConfirmBtn: $data.form.pristine,
    onSubmit: $options.submit,
    onClose: $options.close
  }, {
    default: _withCtx(() => [
      _createVNode(_component_DvpField, {
        "data-cy": "used-apis-filter",
        class: "modal-filters-api-catalog__platforms",
        label: $data.contents.apiUsedInTheApplicationLabel
      }, {
        default: _withCtx(() => [
          _createVNode(_component_MAutocomplete, {
            modelValue: $options.selectedUsedApisIds,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($options.selectedUsedApisIds) = $event)),
            items: $options.usedApis,
            placeholder: $data.contents.searchApiPlaceholder,
            dataTextExpr: "name",
            dataValueExpr: "id",
            filter: false,
            multiple: true,
            input: $data.defaultInputValue,
            disabled: $data.noAnyUsedApis,
            "onUpdate:input": $options.debouncedSearchUsedApis
          }, null, 8, ["modelValue", "items", "placeholder", "input", "disabled", "onUpdate:input"])
        ]),
        _: 1
      }, 8, ["label"]),
      _createVNode(_component_DvpField, {
        "data-cy": "products-filter",
        class: "modal-filters-api-catalog__products",
        label: $data.contents.tangramProductLabel
      }, {
        default: _withCtx(() => [
          _createVNode(_component_MAutocomplete, {
            modelValue: $options.selectedProductIds,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($options.selectedProductIds) = $event)),
            items: $options.products,
            placeholder: $data.contents.searchProductPlaceholder,
            dataTextExpr: "name",
            dataValueExpr: "id",
            multiple: true,
            filter: false,
            input: $data.defaultInputValue,
            "onUpdate:input": $options.debouncedSearchProducts
          }, null, 8, ["modelValue", "items", "placeholder", "input", "onUpdate:input"])
        ]),
        _: 1
      }, 8, ["label"]),
      _createVNode(_component_DvpField, {
        "data-cy": "subscription-status-filter",
        class: "modal-filters-my-apps__subscriptions-status"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_MCheckboxGroup, {
            modelValue: $data.form.subscriptionStatuses,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.form.subscriptionStatuses) = $event)),
            legend: $data.contents.subscriptionStatusLabel,
            options: $options.subscriptionStatuses
          }, null, 8, ["modelValue", "legend", "options"])
        ]),
        _: 1
      }),
      _createVNode(_component_DvpField, {
        "data-cy": "apis-status-filter",
        class: "modal-filters-my-apps__apis-status"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_MCheckboxGroup, {
            modelValue: $data.form.apisStatuses,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($data.form.apisStatuses) = $event)),
            legend: $data.contents.apisStatusLabel,
            options: $options.apisStatuses
          }, null, 8, ["modelValue", "legend", "options"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["confirmBtnLabel", "disabledConfirmBtn", "onSubmit", "onClose"]))
}