import { ApiMapper } from "./api-mapper";

import { Dictionary } from "@/commons/domain/models/dictionary";
import { PagedResource } from "@/commons/domain/models/paged-resource";
import { Product } from "@/commons/domain/models/product";
import { PagedResourceDto } from "@/commons/dtos/paged-resource-dto";
import { ProductDto } from "@/commons/dtos/product-dto";
import { TagsMapper } from "@/commons/mappers/tags-mapper";

export class ProductMapper {
  static toDomain(productsDtosPaged: PagedResourceDto<ProductDto>) {
    const products: Dictionary<Product> = {};

    for (const productDto of productsDtosPaged.data) {
      const product = ProductMapper.toProductDomain(productDto);
      products[product.id] = product;
    }

    return products;
  }

  static toPagedDomain(
    productsDtosPaged: PagedResourceDto<ProductDto>,
  ): PagedResource<Product> {
    return {
      count: productsDtosPaged.count,
      currentPage: productsDtosPaged.currentPage,
      data: ProductMapper.toDomain(productsDtosPaged),
      totalCount: productsDtosPaged.totalCount,
      totalPages: productsDtosPaged.totalPages,
    };
  }

  static toProductDomain(productDto: ProductDto): Product {
    const providedApis = [];
    const usedApis = [];

    for (const apiDto of productDto.providedApis || []) {
      providedApis.push(ApiMapper.toApiDomain(apiDto));
    }

    for (const apiDto of productDto.usedApis || []) {
      usedApis.push(ApiMapper.toApiDomain(apiDto));
    }

    return {
      id: productDto.id,
      name: productDto.name,
      providedApis,
      usedApis,
      description: productDto.description || "",
      logoUrl: productDto.logoUrl || "",
      pageUrl: productDto.pageUrl || "",
      tags: TagsMapper.toApiDomain(productDto.tags),
    };
  }
}
