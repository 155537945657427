<template>
  <DvpOptionCard
    :id="optionCardId"
    class="oauth-flow-option-card"
    :modelValue="modelValue"
    :label="flowItem.title"
    :type="checkboxType"
    :inputAttrs="{ disabled: isDisabled }"
    noPadding
    @update:modelValue="$emit('update:modelValue', $event)"
  >
    <div class="oauth-flow-option-card__container">
      <img class="oauth-flow-option-card__icon" :src="flowItem.icon" />
      <div class="oauth-flow-option-card__content">
        <h5 class="oauth-flow-option-card__title">
          {{ flowItem.title }}
        </h5>
        <div class="oauth-flow-option-card__description">
          <span class="oauth-flow-option-card__description-text">{{
            flowItem.description
          }}</span>
          <MoreAboutLink
            class="oauth-flow-option-card__more-about-link"
            :documentationAnchor="flowItem.documentationAnchor"
          />
        </div>
      </div>
    </div>
  </DvpOptionCard>
</template>

<script lang="ts">
import { PropType } from "vue";

import DvpOptionCard from "@/commons/components/DvpOptionCard.vue";
import MoreAboutLink from "@/commons/components/UserDocumentationLinks/MoreAboutLink.vue";

import { OauthFlowItem } from "@/manager/utils/oauth-flow-items";

export default {
  components: {
    DvpOptionCard,
    MoreAboutLink,
  },
  props: {
    checkboxType: {
      type: String,
      default: "checkbox",
      validator: (value: string) => ["checkbox", "radio"].includes(value),
    },
    flowItem: {
      type: Object as PropType<OauthFlowItem>,
      required: true,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    optionCardId(): string {
      return `oauth-flow-option-card-${this.$.uid}`;
    },
  },
};
</script>

<style lang="scss">
.oauth-flow-option-card__container {
  display: flex;
  align-items: center;
  gap: 1rem;

  padding: 1.5rem;
}

.oauth-flow-option-card__title {
  margin-top: 0;
}

.oauth-flow-option-card__description {
  display: flex;
  align-items: center;
}

.oauth-flow-option-card__description-text {
  @include set-text-s;
}

.oauth-flow-option-card__more-about-link {
  margin-left: 0.5rem;
}
</style>
