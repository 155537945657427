<template>
  <div class="acl last-pushed">
    <div class="acl__item">
      <IconGroup :provider="provider" />
      <a href="#" @click="showUserModal">{{ name }}</a>
    </div>
    <ButtonDelete @click="$emit('remove', id)" />
  </div>
</template>

<script lang="ts">
import { markRaw } from "vue";

import ButtonDelete from "@/commons/components/Buttons/ButtonDelete.vue";
import IconGroup from "@/commons/components/Icons/IconGroup.vue";
import GroupUsersModal from "@/commons/components/ManageGroups/GroupUsersModal.vue";

import contentsGroupUsersModal from "@/commons/contents/group-users-modal";

export default {
  name: "ACL",
  components: {
    IconGroup,
    ButtonDelete,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    provider: {
      type: String,
      required: true,
    },
  },
  emits: ["remove"],
  methods: {
    async showUserModal() {
      this.$store.commit("openModal", {
        title: contentsGroupUsersModal.modalTitle,
        component: markRaw(GroupUsersModal),
        props: {
          groupName: this.name,
          groupId: this.id,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.acl button {
  background: none;
}

.acl__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
</style>
