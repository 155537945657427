<template>
  <RequestManagerTemplate :urlQuery="urlQuery" />
</template>

<script lang="ts">
import { buildFetchParams } from "./build-fetch-params";
import { RequestSummaryListUrlQuery } from "@/request-manager/routes";

import RequestManagerTemplate from "./RequestManagerTemplate.vue";

import { ERequestFilteringVisibility } from "@/commons/store/types";

export default {
  name: "RequestManager",
  components: { RequestManagerTemplate },
  props: {
    urlQuery: {
      type: Object,
      default: () => ({
        visibility: ERequestFilteringVisibility.RECEIVED,
        types: [],
        statuses: [],
        page: 1,
        size: 10,
      }),
    },
  },
  computed: {
    user: function () {
      return this.$store.getters["user"];
    },
  },
  watch: {
    urlQuery(
      newUrlQuery: RequestSummaryListUrlQuery,
      currentUrlQuery: RequestSummaryListUrlQuery,
    ) {
      if (this.shouldReloadRequests(newUrlQuery, currentUrlQuery)) {
        this.loadRequests();
      }
    },
  },
  created() {
    this.loadRequestsTypes();
    this.loadRequests();
  },
  methods: {
    loadRequests(): void {
      this.$store.dispatch("loadRequests", {
        params: buildFetchParams(this.urlQuery),
      });
    },
    loadRequestsTypes(): void {
      this.$store.dispatch("loadRequestTypes");
    },
    shouldReloadRequests(
      newUrlQuery: RequestSummaryListUrlQuery,
      currentUrlQuery: RequestSummaryListUrlQuery,
    ) {
      for (const prop of ["visibility", "page"]) {
        if (newUrlQuery[prop] !== currentUrlQuery[prop]) {
          return true;
        }
      }

      const newUrlQueryTypes = newUrlQuery.types.sort().join("");
      const currentUrlQueryTypes = currentUrlQuery.types.sort().join("");
      const newUrlQueryStatuses = newUrlQuery.statuses.sort().join("");
      const currentUrlQueryStatuses = currentUrlQuery.statuses.sort().join("");

      return (
        newUrlQueryTypes !== currentUrlQueryTypes ||
        newUrlQueryStatuses !== currentUrlQueryStatuses
      );
    },
  },
};
</script>
