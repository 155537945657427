import { EAuthType } from "@/commons/store/types";

import contentsOfAuthType from "@/commons/contents/authentication-type";

export default {
  modalTitle: "Subscribe to a new API",
  availableApiTitle: "Available APIs",
  searchTermPlaceholder: "Search APIs by name and description",
  authenticationFilterLegend: "Authentication",
  authenticationFilterOptionLabel: {
    [EAuthType.API_KEY]: contentsOfAuthType[EAuthType.API_KEY],
    [EAuthType.OAUTH]: contentsOfAuthType[EAuthType.OAUTH],
  },
  emptySubscribableApisList: "No api found",

  contractCreationInfoModalTitle: "Subscribe to a new API",
};
