import { IsInt, IsNotEmpty, Max, Min } from "class-validator";

import { FormData } from "@/commons/domain/forms/form-data";
import { FormFactory } from "@/commons/domain/forms/form-factory";
import {
  MAX_API_TIMEOUT,
  MIN_API_TIMEOUT,
} from "@/manager/domain/forms/timeout";

export class TimeoutForm extends FormData {
  @Min(MIN_API_TIMEOUT, { message: `The minimum value is ${MIN_API_TIMEOUT}` })
  @Max(MAX_API_TIMEOUT, { message: `The maximum value is ${MAX_API_TIMEOUT}` })
  @IsInt({ message: "This value should be an integer" })
  @IsNotEmpty({ message: "This field should not be empty" })
  timeoutInSeconds: number;

  $clear() {
    this["timeoutInSeconds"] = 1;
  }

  static create() {
    return FormFactory.createForm(new TimeoutForm());
  }
}
