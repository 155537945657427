import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "acl last-pushed" }
const _hoisted_2 = { class: "acl__item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconGroup = _resolveComponent("IconGroup")!
  const _component_ButtonDelete = _resolveComponent("ButtonDelete")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_IconGroup, { provider: $props.provider }, null, 8, ["provider"]),
      _createElementVNode("a", {
        href: "#",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.showUserModal && $options.showUserModal(...args)))
      }, _toDisplayString($props.name), 1)
    ]),
    _createVNode(_component_ButtonDelete, {
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('remove', $props.id)))
    })
  ]))
}