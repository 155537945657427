import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MButton = _resolveComponent("MButton")!

  return (_openBlock(), _createBlock(_component_MButton, {
    class: "contract-remove-button",
    theme: "solid-danger",
    size: "s",
    label: $data.contents.removeContract,
    onClick: $options.openRemovalConfirmModal
  }, null, 8, ["label", "onClick"]))
}