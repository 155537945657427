import { MAX_PAGE_COUNT } from "@/documentation/store/constants";
import { AxiosResponse } from "axios";

import { HttpRepository } from "@/commons/repositories/libs/http-repository";

import { Injectable } from "@/commons/domain/di/injectable";
import { Dictionary } from "@/commons/domain/models/dictionary";
import {
  DocumentationPage,
  EDocumentationType,
} from "@/commons/domain/models/documentation-page";
import { ApiDocumentationPageRepository } from "@/commons/domain/repositories/api-documentation-page-repository";
import { CreatedResourceDto } from "@/commons/dtos/created-resource-dto";
import {
  AsyncApiDocumentation,
  DocumentationPageDto,
} from "@/commons/dtos/documentation-page-dto";
import { PagedResourceDto } from "@/commons/dtos/paged-resource-dto";
import { DocumentationPageMapper } from "@/commons/mappers/documentation-page-mapper";

@Injectable()
export class ApiDocumentationPageHttpRepository
  extends HttpRepository
  implements ApiDocumentationPageRepository
{
  public async findAllByApi(apiId: string) {
    const params = {
      apiId,
      size: MAX_PAGE_COUNT,
    };
    const response = await this.requestHandler.get<
      PagedResourceDto<DocumentationPageDto>
    >(`/pages`, {
      params,
    });
    return DocumentationPageMapper.toDomain(response.data.data);
  }

  public async findAllWithContentByApi(apiId: string) {
    const promises: Promise<AxiosResponse<DocumentationPageDto>>[] = [];

    const params = { apiId, size: MAX_PAGE_COUNT };
    const response = await this.requestHandler.get<
      PagedResourceDto<DocumentationPageDto>
    >(`/pages`, { params });

    for (const documentationPageDto of Object.values(response.data.data)) {
      promises.push(
        this.requestHandler.get<DocumentationPageDto>(
          `/pages/${documentationPageDto.id}`,
        ),
      );
    }

    const documentationPagesWithContent: Dictionary<DocumentationPage> = {};
    const responses = await Promise.all(promises);

    for (const responseWithContent of responses) {
      const documentationPage = DocumentationPageMapper.toPageDomain(
        responseWithContent.data,
      );
      documentationPagesWithContent[documentationPage.id] = documentationPage;
    }

    return documentationPagesWithContent;
  }

  public async findById(pageId: string) {
    const response = await this.requestHandler.get<DocumentationPageDto>(
      `/pages/${pageId}`,
    );
    return DocumentationPageMapper.toPageDomain(response.data);
  }

  public async findAsyncApiContentByApiId(apiId: string) {
    const response = await this.requestHandler.get<AsyncApiDocumentation>(
      `/apis/${apiId}/async-api-specification`,
    );
    return response.data.content;
  }

  public async createPage(
    apiId: string,
    type: EDocumentationType,
    title: string,
    description: string,
    content: string,
    url: string,
  ) {
    const response = await this.requestHandler.post<CreatedResourceDto>(
      `/pages`,
      DocumentationPageMapper.toCreateDocumentationPageDto(
        apiId,
        type,
        title,
        description,
        content,
        url,
      ),
    );

    return this.findById(response.data.id);
  }

  public async refreshPage(pageId: string) {
    await this.requestHandler.post(`/pages/${pageId}/_refresh`);
    return this.findById(pageId);
  }

  public async updatePage(
    apiId: string,
    pageId: string,
    type: EDocumentationType,
    title: string,
    description: string,
    content: string,
    url: string,
    isPublished: boolean,
    order: number,
  ) {
    await this.requestHandler.patch(
      `/pages/${pageId}`,
      DocumentationPageMapper.toUpdateDocumentationPageDto(
        type,
        title,
        description,
        content,
        url,
        isPublished,
        order,
      ),
    );

    return this.findById(pageId);
  }

  public async removePage(apiId: string, pageId: string) {
    await this.requestHandler.delete(`/pages/${pageId}`);
    return this.findAllByApi(apiId);
  }

  public async switchPagesOrder(
    apiId: string,
    pageToSwitch: DocumentationPage,
    draggedPage: DocumentationPage,
  ) {
    await this.requestHandler.patch(`/pages/${pageToSwitch.id}`, {
      order: draggedPage.order,
    });

    await this.requestHandler.patch(`/pages/${draggedPage.id}`, {
      order: pageToSwitch.order,
    });

    return this.findAllByApi(apiId);
  }
}
