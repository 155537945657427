import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

import { State } from "./types";

export const state: State = {
  user: undefined,
  hasPerformedInitialAuthentication: false,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
