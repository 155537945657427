import { ApiMapper } from "./api-mapper";
import { ApplicationMapper } from "./application-mapper";
import { ConversationItemMapper } from "./conversation-item-mapper";

import { PagedResource } from "@/commons/domain/models/paged-resource";
import { RequestSummary } from "@/commons/domain/models/request-summary";
import { PagedResourceDto } from "@/commons/dtos/paged-resource-dto";
import { RequestSummaryDto } from "@/commons/dtos/request-summary-dto";

export class RequestSummaryMapper {
  static toPagedResource({
    count,
    currentPage,
    data,
    totalCount,
    totalPages,
  }: PagedResourceDto<RequestSummaryDto>) {
    const requestSummariesPaged: PagedResource<RequestSummary> = {
      count,
      currentPage,
      data: {},
      totalCount,
      totalPages,
    };

    for (const requestSummaryDto of data) {
      const requestSummary =
        RequestSummaryMapper.toRequestSummaryDomain(requestSummaryDto);
      requestSummariesPaged.data[requestSummary.id] = requestSummary;
    }

    return requestSummariesPaged;
  }

  static toRequestSummaryDomain(
    requestSummaryDto: RequestSummaryDto,
  ): RequestSummary | undefined {
    return requestSummaryDto
      ? {
          id: requestSummaryDto.id,
          createdAt: requestSummaryDto.createdAt,
          application:
            (requestSummaryDto.application &&
              ApplicationMapper.toApplicationDomain(
                requestSummaryDto.application,
              )) ||
            undefined,
          api:
            (requestSummaryDto.api &&
              ApiMapper.toApiDomain(requestSummaryDto.api)) ||
            undefined,
          author: requestSummaryDto.createdBy,
          type: requestSummaryDto.type,
          status: requestSummaryDto.status,
          approveCode: requestSummaryDto.approveCode,
          denyCode: requestSummaryDto.denyCode,
          allowedActions: requestSummaryDto.allowedActions || {},
          conversation: ConversationItemMapper.toDomain(requestSummaryDto),
          requestedTimeout: requestSummaryDto.requestedTimeout,
          cslName: requestSummaryDto.cslName,
          cslMessage: requestSummaryDto.cslMessage,
          slackUrl: requestSummaryDto.slackUrl,
          oauthFlow: requestSummaryDto.oauthFlow,
        }
      : undefined;
  }
}
