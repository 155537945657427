const providerAuthToken = document.location.hostname;

export enum ECookieCategoryType {
  REQUIRED = "REQUIRED",
  STATISTIC = "STATISTIC",
}

export type ConfigurableCookieAcceptanceMapType = {
  [K in ECookieCategoryType]?: boolean;
};

export interface CookieCategory {
  type: ECookieCategoryType;
  title: string;
  content: string;
  configurable: boolean;
  acceptButtonLabel?: string;
  cookiesDetails: CookieDefinition[];
}

interface CookieDefinition {
  name: string;
  provider: string;
  finality: string;
  expiration: string;
  type: string;
}

export const cookieCategories: CookieCategory[] = [
  {
    type: ECookieCategoryType.REQUIRED,
    title: "Required",
    content:
      "The necessary cookies help make a website usable by activating essential functions like page navigation and access to secure areas of the website. The website can not function properly without these cookies.",
    configurable: false,
    cookiesDetails: [
      {
        name: "AUTH-TOKEN",
        provider: providerAuthToken,
        finality: "Cookie used for authentication",
        expiration: "30 days",
        type: "HTTP",
      },
      {
        name: "PING",
        provider: providerAuthToken,
        finality: "Cookie used for PING authentication",
        expiration: "20 min - 4 h",
        type: "HTTP",
      },
      {
        name: "datadome",
        provider: "Datadome",
        finality: "Cookie used for bot detection",
        expiration: "1 year",
        type: "HTTP",
      },
    ],
  },
  {
    type: ECookieCategoryType.STATISTIC,
    title: "Statistic",
    content:
      "Statistical cookies help website owners gather and share information anonymously to understand how visitors interact with websites.",
    configurable: true,
    acceptButtonLabel: "Accept statistics cookies",
    cookiesDetails: [
      {
        name: "_dd_s",
        provider: "Datadog",
        finality: "Cookie used for statistic data",
        expiration: "15 minutes",
        type: "-",
      },
    ],
  },
];
