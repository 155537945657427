<template>
  <ConfirmModalBody
    class="message-confirm-modal"
    v-bind="$attrs"
    @onSubmit="$emit('onSubmit')"
    @onClose="$emit('onClose')"
  >
    <h5 class="message-confirm-modal__title">{{ title }}</h5>
    <Markdown class="message-confirm-modal__message" :content="message" />
  </ConfirmModalBody>
</template>

<script lang="ts">
import ConfirmModalBody from "@/commons/components/Modal/ConfirmModalBody.vue";
import Markdown from "@/documentation/components/commons/Markdown.vue";

export default {
  components: {
    ConfirmModalBody,
    Markdown,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      default: "",
    },
  },
  emits: ["onSubmit", "onClose"],
};
</script>
