import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "table-light" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MNotification = _resolveComponent("MNotification")!
  const _component_UpdateOrRemoveCustomQueryParam = _resolveComponent("UpdateOrRemoveCustomQueryParam")!
  const _component_CreateCustomQueryParam = _resolveComponent("CreateCustomQueryParam")!
  const _component_Stack = _resolveComponent("Stack")!

  return (_openBlock(), _createBlock(_component_Stack, { class: "custom-query-params" }, {
    default: _withCtx(() => [
      ($options.validationError)
        ? (_openBlock(), _createBlock(_component_MNotification, {
            key: 0,
            type: "danger"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($options.validationError), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createElementVNode("p", null, [
        _createElementVNode("span", null, _toDisplayString($options.message), 1),
        ($options.allowQueryParamTransformation)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              href: $options.documentationUrl
            }, _toDisplayString($options.documentationMessage), 9, _hoisted_1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("table", _hoisted_2, [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _createElementVNode("th", null, _toDisplayString($data.contents.queryParamName), 1),
            _createElementVNode("th", null, _toDisplayString($data.contents.queryParamValue), 1),
            _createElementVNode("th", null, _toDisplayString($data.contents.queryParamActions), 1)
          ])
        ]),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.form.values, (customQueryParam) => {
            return (_openBlock(), _createBlock(_component_UpdateOrRemoveCustomQueryParam, {
              key: customQueryParam.id,
              "is-disabled": $options.isSaving,
              "form-parent": $data.form,
              form: customQueryParam,
              onUpdate: $options.update,
              onRemove: $options.remove
            }, null, 8, ["is-disabled", "form-parent", "form", "onUpdate", "onRemove"]))
          }), 128)),
          _createVNode(_component_CreateCustomQueryParam, {
            "is-disabled": $options.isSaving,
            "has-error": $options.hasCreationError,
            "form-parent": $data.form,
            form: $data.form.newValue,
            onCreate: $options.create,
            onClose: $options.removeCreationErrorIfExists
          }, null, 8, ["is-disabled", "has-error", "form-parent", "form", "onCreate", "onClose"])
        ])
      ])
    ]),
    _: 1
  }))
}