export default {
  modalTitle: "Delete an MCC team",
  title: "You are deleting the last MCC team",
  messageDeleteGroupOnApplication:
    "There will be no more MyCloudConsole team on this application, and it could lead to an application without managers anymore.\n\n" +
    "You will have to manually manage the local groups' members.\n\n" +
    "The deletion is still possible but not advised.",
  messageDeleteGroupOnApi:
    "There will be no more MyCloudConsole team on this API, and it could lead to an API without managers anymore.\n\n" +
    "You will have to manually manage the local groups' members.\n\n" +
    "The deletion is still possible but not advised.",
  confirmBtnLabel: "Delete this team",
};
