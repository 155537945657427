<script setup lang="ts">
import MDataTableV2 from "@mozaic-ds/vue-3/src/components/datatablev2/MDataTableV2.vue";
import { computed, PropType, reactive } from "vue";
import { useStore } from "vuex";

import ButtonDeleteGroup from "@/commons/components/ManageGroups/ButtonDeleteGroup.vue";
import GroupNameWithLinkToUsers from "@/commons/components/ManageGroups/GroupNameWithLinkToUsers.vue";
import UpdateGroupRoleSelect from "@/commons/components/ManageGroups/UpdateGroupRoleSelect.vue";

import { Group } from "@/commons/domain/models/group";
import { MDataTableV2HeaderItem } from "@/commons/domain/models/mozaic";

import { ERole } from "@/commons/store/types";

import contents from "@/dashboard/contents/access-settings";

const props = defineProps({
  groups: {
    type: Array as PropType<Group[]>,
    required: true,
  },
  manageRoles: {
    type: Boolean,
    default: false,
  },
});

defineEmits(["updateGroupRole", "deleteGroup"]);

const store = useStore();

const tableHeaders: MDataTableV2HeaderItem[] = [
  {
    label: contents.managerGroupHeader,
    value: "name",
  },
  {
    value: "groupRole",
    class: "groups-list__role-select",
  },
  {
    value: "deleteGroup",
    class: "groups-list__delete-button",
  },
];

const currentUserGroupsList = computed(
  () => store.getters["currentUserGroupsList"],
);

const managerGroupsIds = computed(() => {
  return props.groups
    .filter((groupAcl) => groupAcl.role.id === ERole.MANAGER)
    .map((groupAcl) => groupAcl.id);
});

const personalManagerGroupsIds = computed(() => {
  return currentUserGroupsList.value
    .filter((group) => managerGroupsIds.value.includes(group.id))
    .map((group) => group.id);
});

const aclChangeHandler = reactive({
  isDisabled: (group) => {
    return (
      aclChangeHandler.groupIsOnlyManagerGroup(group) ||
      aclChangeHandler.groupIsOnlyPersonalManagerGroup(group)
    );
  },
  groupIsOnlyManagerGroup: (group) => {
    return (
      managerGroupsIds.value.includes(group.id) &&
      managerGroupsIds.value.length === 1
    );
  },
  groupIsOnlyPersonalManagerGroup: (group) => {
    return (
      personalManagerGroupsIds.value.includes(group.id) &&
      personalManagerGroupsIds.value.length === 1
    );
  },
});

const disabledDeleteGroup = (group) => {
  return aclChangeHandler.isDisabled(group);
};
</script>

<template>
  <MDataTableV2
    v-if="groups.length > 0"
    class="groups-list"
    :headers="tableHeaders"
    :items="groups"
  >
    <template #[`cell.name`]="{ item: group }">
      <GroupNameWithLinkToUsers
        :provider="group.provider"
        :groupId="group.id"
        :groupName="group.name"
        :isUserGroup="disabledDeleteGroup(group)"
      />
    </template>
    <template #[`cell.groupRole`]="{ item: group }">
      <UpdateGroupRoleSelect
        v-if="manageRoles"
        :disabled="disabledDeleteGroup(group)"
        :group="group"
        @updateGroupRole="$emit('updateGroupRole', $event, group.id)"
      />
    </template>
    <template #[`cell.deleteGroup`]="{ item: group }">
      <ButtonDeleteGroup
        :isDisabled="disabledDeleteGroup(group)"
        @deleteGroup="$emit('deleteGroup', group.id)"
      />
    </template>
  </MDataTableV2>
</template>

<style lang="scss">
.groups-list .mc-datatable__container {
  box-shadow: none;
}

.groups-list__role-select {
  width: 9rem;
}

.groups-list__delete-button {
  width: 2rem;
}
</style>
