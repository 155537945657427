<template>
  <span v-if="user" class="user-role-icon">
    <MIcon v-if="user.isPartner" name="PartnerCompany24" />
    <img v-else class="user-role-icon__internal-icon" :src="devportalIcon" />
  </span>
</template>

<script lang="ts">
import MIcon from "@mozaic-ds/vue-3/src/components/icon/MIcon.vue";
import { PropType } from "vue";

import { User } from "@/commons/domain/models/user";

export default {
  components: {
    MIcon,
  },
  props: {
    user: {
      type: Object as PropType<User>,
      default: undefined,
    },
  },
  data() {
    return {
      devportalIcon: require("@/commons/assets/icons/icon-adeo.svg"),
    };
  },
};
</script>

<style lang="scss">
.user-role-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px; // we set a fixed width so the 18px width img and the 24px mozaic's icon take the same width and are aligned
}

.user-role-icon__internal-icon {
  width: 18px; // the internal icon seems a bit too big compared to mozaic's icons so we make it a bit smaller
}
</style>
