<script setup lang="ts">
import MFlag from "@mozaic-ds/vue-3/src/components/flag/MFlag.vue";
import { computed, PropType } from "vue";

import DvpTooltip from "@/commons/components/DvpTooltip.vue";

import { toLocaleDate } from "@/commons/utils/date-utils";

import { Contract } from "@/commons/domain/models/contract";

import contents from "@/dashboard/contents/contract-item";

const props = defineProps({
  contract: {
    type: Object as PropType<Contract>,
    required: true,
  },
});

const deprecationDate = computed(() => props.contract?.api?.deprecationDate);

const hasDeprecationDate = computed(() => deprecationDate.value != undefined);

const apiVersion = computed(() => props.contract?.api?.version);

const isOAuthContract = computed(
  () => Object.values(props.contract?.oAuthTokens).length > 0,
);

const contractFlags = computed(() => {
  return [
    {
      label: hasDeprecationDate.value
        ? contents.deprecatedVersion(apiVersion.value)
        : `V${apiVersion.value}`,
      theme: hasDeprecationDate.value ? "solid-danger" : "bordered-dark",
      tooltipText: hasDeprecationDate.value
        ? `Since ${toLocaleDate(deprecationDate.value)}`
        : undefined,
    },
    {
      label: contents.flagKongGateway,
      theme: "bordered-dark",
    },
    {
      label: isOAuthContract.value
        ? contents.flagOAuthPing
        : contents.flagApiKey,
      theme: "bordered",
    },
  ];
});
</script>

<template>
  <div class="contract-card-flags">
    <template v-for="flag in contractFlags" :key="flag.label">
      <DvpTooltip
        :disabled="flag.tooltipText == undefined"
        :text="flag.tooltipText"
        position="bottom"
      >
        <MFlag :label="flag.label" :theme="flag.theme" />
      </DvpTooltip>
    </template>
  </div>
</template>

<style lang="scss">
.contract-card-flags {
  display: flex;
  flex-flow: row wrap;
  gap: 0.5rem;
}
</style>
