<template>
  <div class="notification-for-kong-synchronization">
    <MNotification
      :title="contents.kongNotificationTitle(configurationType)"
      type="warning"
    >
      <p>
        {{ contents.kongNotifSychronizeText }}
      </p>
      <p>
        {{ contents.kongNotifContactText }}
      </p>
      <template #footer>
        <MLink size="s" :href="routeHref" target="_blank">
          {{ contents.kongNotifLinkTitle }}
        </MLink>
      </template>
    </MNotification>
  </div>
</template>

<script lang="ts">
import MLink from "@mozaic-ds/vue-3/src/components/link/MLink.vue";
import MNotification from "@mozaic-ds/vue-3/src/components/notification/MNotification.vue";

import { convertRouteToHref } from "@/commons/utils/route-utils";

import contents from "@/dashboard/contents/notification-for-kong-synchronization";

export default {
  name: "NotificationForKongSynchronization",
  components: {
    MNotification,
    MLink,
  },
  props: {
    configurationType: {
      type: String,
      default: "Application",
    },
  },
  data() {
    return {
      contents,
    };
  },
  computed: {
    routeHref() {
      return convertRouteToHref({
        name: "docsMain",
        hash: "#publish-your-api",
      });
    },
  },
};
</script>

<style lang="scss">
.notification-for-kong-synchronization {
  margin-bottom: 1rem;
}
</style>
