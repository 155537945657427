<template>
  <LayoutSection background="secondary" class="groups-listing-section">
    <div class="groups-listing-section__filters">
      <SearchTextFilter
        class="groups-listing-section__search-filter"
        data-cy="search-filter"
        :placeholder="contents.searchGroupPlaceholder"
        queryParamName="term"
        :queryParamValue="searchText"
        size="s"
      />
      <MButton
        size="s"
        :label="contents.createNewGroup"
        @click="openGroupCreationModal"
      />
    </div>

    <MDataTable
      class="groups-listing-section__table"
      :headers="tableHeaders"
      :source="groups"
      :sorting="{ mode: 'none' }"
    >
      <template #no-data>
        <div class="groups-listing-section__no-data">
          {{ contents.noGroupFound }}
        </div>
      </template>
      <template #[`item.icon`]="{ item }">
        <div class="groups-listing-section__icon">
          <IconGroup v-if="item.provider" :provider="item.provider" />
        </div>
      </template>
      <template #[`item.name`]="{ item }">
        <div>{{ item.name }}</div>
      </template>
      <template #[`item.description`]="{ item }">
        <div class="groups-listing-section__description">
          {{ item.description }}
        </div>
      </template>
      <template #[`item.lastUpdate`]="{ item }">
        <div>{{ formatDate(item.updatedAt) }}</div>
      </template>
      <template #[`item.actions`]="{ item }">
        <FlexActionButtonsWrapper
          v-if="isLocalGroup(item)"
          class="groups-listing-section__action-buttons-wrapper"
        >
          <ButtonEditGroup @click="openGroupMembershipsModal(item)" />
          <ButtonEdit
            v-if="userHasWriteAccessOnGroup(item) || userIsAdmin"
            @click="openGroupUpdateModal(item)"
          />
          <ButtonDelete
            v-if="userHasWriteAccessOnGroup(item) || userIsAdmin"
            @click="openGroupDeletionModal(item)"
          />
        </FlexActionButtonsWrapper>
        <div v-else class="groups-listing-section__mcc-group-actions">
          <ButtonEditGroup @click="openGroupMembershipsModal(item)" />
          <HelpLink
            :label="contents.seeInMCCLinkLabel"
            :href="item.linkMCC"
            openInNewTab
          />
        </div>
      </template>
    </MDataTable>

    <QueryParamPagination
      v-if="totalPages != undefined"
      class="groups-listing-section__pagination"
      :totalPagesNumber="totalPages"
    />
  </LayoutSection>
</template>

<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import MDataTable from "@mozaic-ds/vue-3/src/components/datatable/MDataTable.vue";
import { markRaw, PropType } from "vue";

import ButtonDelete from "@/commons/components/Buttons/ButtonDelete.vue";
import ButtonEdit from "@/commons/components/Buttons/ButtonEdit.vue";
import ButtonEditGroup from "@/commons/components/Buttons/ButtonEditGroup.vue";
import FlexActionButtonsWrapper from "@/commons/components/Buttons/FlexActionButtonsWrapper.vue";
import SearchTextFilter from "@/commons/components/Filters/SearchTextFilter.vue";
import IconGroup from "@/commons/components/Icons/IconGroup.vue";
import LayoutSection from "@/commons/components/LayoutSection.vue";
import QueryParamPagination from "@/commons/components/QueryParamPagination.vue";
import HelpLink from "@/commons/components/UserDocumentationLinks/HelpLink.vue";
import GroupDeletionModal from "@/commons/components/userGroup/GroupDeletionModal.vue";
import GroupEditionModal from "@/commons/components/userGroup/GroupEditionModal.vue";
import GroupMembershipsModal from "@/commons/components/userGroup/GroupMembershipsModal.vue";

import { getApiRepository } from "@/commons/repositories/libs/get-api-repository";
import { getApplicationRepository } from "@/commons/repositories/libs/get-application-repository";
import { localeDateFormats, toDateFormat } from "@/commons/utils/date-utils";
import {
  isLocalGroup,
  userHasWriteAccessOnGroup,
} from "@/commons/utils/group-utils";
import { changeUrlQuery } from "@/commons/utils/route-utils";

import { Api } from "@/commons/domain/models/api";
import { Group } from "@/commons/domain/models/group";
import { ApplicationDto } from "@/commons/dtos/application-dto";

import contentsOfGroupDeletionModal from "@/commons/contents/group-deletion-modal";
import contentsOfGroupEditionModal from "@/commons/contents/group-edition-modal";
import contentsOfGroupMembershipsModal from "@/commons/contents/group-memberships-modal";
import contents from "@/commons/contents/groups-listing-section";

export default {
  components: {
    FlexActionButtonsWrapper,
    ButtonEdit,
    ButtonEditGroup,
    ButtonDelete,
    MButton,
    LayoutSection,
    SearchTextFilter,
    MDataTable,
    QueryParamPagination,
    IconGroup,
    HelpLink,
  },
  props: {
    groups: {
      type: Array as PropType<Group[]>,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    searchText: {
      type: String,
      default: "",
    },
  },
  emits: ["groupUpdated", "groupDeleted"],
  data() {
    return {
      contents,
    };
  },
  computed: {
    user() {
      return this.$store.getters["user"];
    },
    userIsAdmin() {
      return this.$store.getters["userIsAdmin"];
    },
    tableHeaders() {
      return [
        {
          dataFieldExpr: "icon",
        },
        {
          caption: contents.groupName,
          dataFieldExpr: "name",
        },
        {
          caption: contents.groupDescription,
          dataFieldExpr: "description",
        },
        {
          caption: contents.lastUpdate,
          dataFieldExpr: "lastUpdate",
        },
        {
          dataFieldExpr: "actions",
          cssClass: "groups-listing-section__action-column",
        },
      ];
    },
  },
  methods: {
    userHasWriteAccessOnGroup,
    isLocalGroup,
    formatDate(date: string): string {
      return date != undefined
        ? toDateFormat(date, localeDateFormats.VERBOSE_DATE_MINUTE)
        : contents.noInformation;
    },
    changePage(newPage): void {
      changeUrlQuery({ page: newPage });
    },
    openGroupCreationModal() {
      this.openGroupEditionModal(
        contentsOfGroupEditionModal.creationModalTitle,
      );
    },
    openGroupUpdateModal(group: Group) {
      this.openGroupEditionModal(
        contentsOfGroupEditionModal.updateModalTitle(group.name),
        group,
      );
    },
    openGroupEditionModal(modalTitle: string, group?: Group) {
      this.$store.commit("openLayerModal", {
        title: modalTitle,
        component: GroupEditionModal,
        props: {
          group,
        },
        listeners: {
          groupUpdated: () => this.$emit("groupUpdated"),
        },
      });
    },
    openGroupMembershipsModal(group: Group): void {
      this.$store.commit("openLayerModal", {
        title: contentsOfGroupMembershipsModal.modalTitle(group.name),
        component: markRaw(GroupMembershipsModal),
        props: {
          groupId: group.id,
        },
        listeners: {
          onSelfRemoval: () => this.$emit("groupUpdated"),
        },
      });
    },
    async openGroupDeletionModal(group) {
      const { groupApplications, groupApis } =
        await this.loadApisAndApplicationsManagedByGroup(group);

      this.$store.commit("openModal", {
        title: contentsOfGroupDeletionModal.modalTitle(group.name),
        component: markRaw(GroupDeletionModal),
        props: {
          group: group,
          groupApplications,
          groupApis,
        },
        listeners: {
          groupDeleted: () => this.$emit("groupDeleted"),
        },
      });
    },
    async loadApisAndApplicationsManagedByGroup(group: Group): Promise<{
      groupApplications: ApplicationDto[];
      groupApis: Api[];
    }> {
      const applicationsFetch = getApplicationRepository().find({
        managedByGroups: group.id,
      });
      const apisFetch = getApiRepository().cancelAndFind({
        managedByGroups: group.id,
      });

      const [pagedGroupApplications, pagedGroupApis] = await Promise.all([
        applicationsFetch,
        apisFetch,
      ]);

      return {
        groupApplications: Object.values(pagedGroupApplications.data),
        groupApis: Object.values(pagedGroupApis.data),
      };
    },
  },
};
</script>

<style lang="scss">
.groups-listing-section {
  flex: 1;
}

.groups-listing-section__filters {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-bottom: 1rem;
}

.groups-listing-section__action-column {
  width: 0;
}

.groups-listing-section__description {
  max-width: 20rem;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.groups-listing-section__icon {
  display: flex;
  justify-content: center;
}

.groups-listing-section__no-data {
  text-align: center;
}

.groups-listing-section__pagination {
  margin-top: var(--base-spacing);
}

.groups-listing-section__mcc-group-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 9rem;
}

.groups-listing-section__action-buttons-wrapper {
  min-width: 7rem;
}
</style>
