<template>
  <MenuLabel :urlOrRoute="{ name: 'requestManager' }">
    <MenuIcon
      :withNotifications="numberOfRequestPending > 0"
      mIconName="NotificationBell32"
    />
  </MenuLabel>
</template>

<script lang="ts">
import MenuIcon from "@/commons/components/Menu/MenuIcon.vue";
import MenuLabel from "@/commons/components/Menu/MenuLabel.vue";

export default {
  components: {
    MenuLabel,
    MenuIcon,
  },
  computed: {
    numberOfRequestPending() {
      return this.$store.getters["totalCountOfPendingRequests"];
    },
  },
  mounted() {
    this.$store.dispatch("findPendingRequests");
  },
};
</script>
