<template>
  <svg
    id="Calque_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24.7 24.5"
    style="enable-background: new 0 0 24.7 24.5"
    xml:space="preserve"
  >
    <path
      d="M13.5,0c-6.1,0-11,4.9-11,11c0,2.7,1,5.1,2.6,7l-4.8,4.8c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3
	l4.8-4.8c1.9,1.6,4.4,2.6,7,2.6c6.1,0,11-4.9,11-11S19.6,0,13.5,0z M13.5,20c-5,0-9-4-9-9s4-9,9-9s9,4,9,9S18.5,20,13.5,20z"
    />
  </svg>
</template>
