import { LocationQuery } from "vue-router";

import AccessRestriction from "@/manager/views/AccessRestriction/AccessRestriction.vue";
import AddOAuthScopes from "@/manager/views/AddOAuthScopes/AddOAuthScopes.vue";
import Settings from "@/manager/views/ApiDetail/Settings.vue";
import AuthenticationSettings from "@/manager/views/ApiDetailAuthentication/AuthenticationSettings.vue";
import ConsumersApi from "@/manager/views/ApiDetailConsumers/ConsumersApi.vue";
import DocumentationApi from "@/manager/views/ApiDetailDocumentation/DocumentationApi.vue";
import PoliciesApi from "@/manager/views/ApiDetailPolicies/PoliciesApi.vue";
import PublishingApi from "@/manager/views/ApiDetailPublishing/PublishingApi.vue";
import QuotaApiSettings from "@/manager/views/ApiDetailQuotas/QuotaApiSettings.vue";
import RoutingApi from "@/manager/views/ApiDetailRouting/RoutingApi.vue";
import SettingsApi from "@/manager/views/ApiDetailSettings/SettingsApi.vue";
import CreateApiFirstStep from "@/manager/views/ApiRegisterNew/CreateApiFirstStep.vue";
import ApisList from "@/manager/views/ApisList.vue";

import { onlyUrlChange } from "@/commons/libs/only-change-url";

import { Dictionary } from "@/commons/domain/models/dictionary";

export interface ApiListUrlQuery {
  showAllApis: boolean;
  term: string;
  productsIds: string[];
  page: number;
  accessTypes: string[];
  privacies: string[];
  publicationStatuses: string[];
  platformsIds: string[];
  businessUnitsIds: string[];
  publicationZoneId: string;
  types: string[];
}

function mapRouteQueryToApiListUrlQuery(
  routeQuery: LocationQuery,
  previousRouteQuery?: LocationQuery,
): ApiListUrlQuery {
  const query = routeQuery as Dictionary<string>;

  return {
    showAllApis: query.showAllApis === "true",
    term: query.term || "",
    productsIds: query.productsIds ? query.productsIds.split(",") : [],
    accessTypes: query.accessTypes ? query.accessTypes.split(",") : [],
    privacies: query.privacies ? query.privacies.split(",") : [],
    publicationStatuses: query.publicationStatuses
      ? query.publicationStatuses.split(",")
      : [],
    publicationZoneId: query.publicationZoneId || "",
    platformsIds: query.platformsIds ? query.platformsIds.split(",") : [],
    businessUnitsIds: query.businessUnitsIds
      ? query.businessUnitsIds.split(",")
      : [],
    page:
      query.page !== undefined && onlyUrlChange(previousRouteQuery, query)
        ? Number(query.page)
        : 1,
    types: query.types ? query.types.split(",") : [],
  };
}

let cachedQuery = null;

const routes = [
  {
    path: "",
    name: "managerApiList",
    component: ApisList,
    meta: {
      title: "API Manager",
      secured: true,
      realm: "manager",
      crossEnv: true,
    },
    props: (route) => {
      const urlQuery = mapRouteQueryToApiListUrlQuery(route.query, cachedQuery);

      cachedQuery = route.query;

      return { urlQuery };
    },
  },
  {
    path: "api/:id",
    component: Settings,
    props: (route) => {
      return { id: route.params.id, menuId: route.meta.menuId };
    },
    meta: {
      secured: true,
      realm: "manager",
    },
    children: [
      {
        path: "settings",
        name: "managerApiDetailSettings",
        component: SettingsApi,
        props: true,
        meta: {
          secured: true,
          title: "Settings | API Manager",
          menuId: "settings",
          realm: "manager",
        },
      },
      {
        path: "authentication",
        name: "managerApiDetailAuthentication",
        component: AuthenticationSettings,
        props: true,
        meta: {
          secured: true,
          title: "Authentication | API Manager",
          menuId: "authentication",
          realm: "manager",
        },
      },
      {
        path: "routing",
        name: "managerApiRouting",
        component: RoutingApi,
        props: true,
        meta: {
          secured: true,
          title: "Routing | API Manager",
          menuId: "routing",
          realm: "manager",
        },
      },
      {
        path: "access-restriction",
        name: "managerAccessRestriction",
        component: AccessRestriction,
        props: true,
        meta: {
          secured: true,
          title: "Access restriction | API Manager",
          menuId: "access-restriction",
          realm: "manager",
        },
      },
      {
        path: "documentation",
        name: "managerApiDetailDocumentation",
        component: DocumentationApi,
        meta: {
          secured: true,
          title: "Documentation | API Manager",
          menuId: "documentation",
          realm: "manager",
        },
      },
      {
        path: "consumers",
        name: "managerApiDetailConsumers",
        component: ConsumersApi,
        meta: {
          secured: true,
          title: "Consumers | API Manager",
          menuId: "consumers",
          realm: "manager",
        },
      },
      {
        path: "quotas",
        name: "QuotaApiSettings",
        component: QuotaApiSettings,
        props: true,
        meta: {
          secured: true,
          title: "Quotas | API Manager",
          menuId: "quotas",
          realm: "manager",
        },
      },
      {
        path: "policies",
        name: "managerApiDetailPolicies",
        component: PoliciesApi,
        meta: {
          secured: true,
          title: "Policies | API Manager",
          menuId: "policies",
          realm: "manager",
        },
      },
      {
        path: "publication",
        name: "managerApiPublishing",
        component: PublishingApi,
        props: true,
        meta: {
          secured: true,
          title: "Publication | API Manager",
          menuId: "publication",
          realm: "manager",
        },
      },
      {
        path: "",
        redirect: { name: "managerApiDetailSettings" },
      },
    ],
  },
  {
    path: "scopes/:apiId/add",
    name: "addOAuthScopes",
    component: AddOAuthScopes,
    props: true,
    meta: {
      secured: true,
      title: "Add OAuth Scopes",
      realm: "manager",
    },
  },
  {
    path: "create-api",
    name: "apiCreate",
    component: ApisList,
    meta: {
      title: "Register a new API",
      secured: true,
      realm: "manager",
      openLayerModal: {
        title: "Create a new API 1/2",
        component: CreateApiFirstStep,
        redirectOnClose: "managerApiList",
      },
    },
    props: () => {
      return { urlQuery: mapRouteQueryToApiListUrlQuery({}) }; // urlQuery is required by the ApisList component
    },
  },
];

export default routes;
