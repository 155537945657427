<script setup lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";

import contents from "@/manager/contents/add-oauth-scopes";

const props = defineProps({
  currentStep: {
    type: Number,
    required: true,
  },
  secondStepIsDisabled: {
    type: Boolean,
    required: true,
  },
  validationIsDisabled: {
    type: Boolean,
    required: true,
  },
});

defineEmits(["cancel", "next", "back", "validate"]);
</script>

<template>
  <div class="footer-actions">
    <MButton
      data-cy="cancel-btn"
      size="s"
      theme="bordered-danger"
      :label="contents.cancelBtnLabel"
      @click="$emit('cancel')"
    />

    <div class="add-oauth-scopes__footer-actions-right-side">
      <MButton
        v-if="props.currentStep === 1"
        data-cy="next-btn"
        size="s"
        :label="contents.nextBtnLabel"
        :disabled="secondStepIsDisabled"
        @click="$emit('next')"
      />
      <template v-else-if="props.currentStep === 2">
        <MButton
          data-cy="back-btn"
          size="s"
          theme="bordered-neutral"
          :label="contents.backBtnLabel"
          @click="$emit('back')"
        />
        <MButton
          data-cy="validate-btn"
          size="s"
          :label="contents.validateBtnLabel"
          :disabled="validationIsDisabled"
          @click="$emit('validate')"
        />
      </template>
    </div>
  </div>
</template>

<style lang="scss">
.footer-actions {
  position: sticky;
  bottom: 0;
  z-index: var(--z-index-foreground);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  padding: 1rem 2rem;

  background-color: var(--color-white);
}

.add-oauth-scopes__footer-actions-right-side {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
</style>
