import { Api } from "@/commons/domain/models/api";
import { Route } from "@/commons/domain/models/route";

import {
  EApiInternalLabel,
  EApiType,
  ERouteTypes,
  IApi,
} from "@/commons/store/types";

export function getApiLabelWithVersion(api: IApi | Api): string {
  return api && api.type == EApiType.API_REST
    ? `${getApiRestLabel(api)} v${api.version}`
    : getTopicLabel(api);
}

export function getApiLabel(api: IApi | Api): string {
  return api && api.type == EApiType.API_REST
    ? getApiRestLabel(api)
    : getTopicLabel(api);
}

export function getApiRestLabel(api: IApi | Api): string {
  return api && api.internal
    ? `${EApiInternalLabel.BACKEND} ${api.name}`
    : `${EApiInternalLabel.API} ${api.name}`;
}

export function getTopicLabel(api: IApi | Api): string {
  return api && api.name && `${EApiType.TOPIC} ${api.name}`;
}

export function getApiRestTypeLabel(isInternal: boolean) {
  return isInternal ? EApiInternalLabel.BACKEND : EApiInternalLabel.API;
}

export function isRestApi(api: IApi) {
  return api && api.type === EApiType.API_REST;
}

export function isTopic(api: IApi) {
  return api && api.type === EApiType.TOPIC;
}

export function isApiPublic(api: Api): boolean {
  return Object.values<Route>(api.routes).some(
    (r) => r.isPublishable && r.type == ERouteTypes.DOWNSTREAM && r.isPublic,
  );
}

export function apiLabel(api: IApi | Api): string {
  return api ? getApiRestLabel(api) : "";
}

export function getApiOauthProvider(api: Api): string | undefined {
  return api.oauthDefinitions[0]?.provider;
}
