import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DvpStepper = _resolveComponent("DvpStepper")!
  const _component_MSelect = _resolveComponent("MSelect")!
  const _component_DvpField = _resolveComponent("DvpField")!
  const _component_MTextInput = _resolveComponent("MTextInput")!
  const _component_MTextArea = _resolveComponent("MTextArea")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_LayerModalForm = _resolveComponent("LayerModalForm")!

  return (_openBlock(), _createBlock(_component_LayerModalForm, {
    class: "create-api-second-step create-api-second-step__form",
    onSubmit: $options.submit
  }, {
    fields: _withCtx(() => [
      _createVNode(_component_DvpStepper, {
        steps: $options.stepperSteps,
        stepperDescription: $data.contents.title
      }, null, 8, ["steps", "stepperDescription"]),
      _createVNode(_component_DvpField, {
        class: "create-api-second-step__api-type-field",
        required: "",
        label: $data.contents.apiType,
        helpText: $data.contents.apiTypeHelpText,
        errorMessage: $data.form.firstError('type'),
        "data-cy": "api-type-field"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_MSelect, {
            id: "api-type-selection",
            placeholder: $data.contents.apiTypePlaceholder,
            options: $options.apiTypeInternalOptions,
            modelValue: String($data.form.internal),
            isInvalid: $options.isInvalidField('type'),
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ($data.form.internal = Boolean($event)))
          }, null, 8, ["placeholder", "options", "modelValue", "isInvalid"])
        ]),
        _: 1
      }, 8, ["label", "helpText", "errorMessage"]),
      _createVNode(_component_DvpField, {
        class: "create-api-second-step__api-version-field",
        required: "",
        label: $data.contents.apiVersion,
        errorMessage: $data.form.firstError('version'),
        "data-cy": "api-version-field"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_MTextInput, {
            modelValue: $data.form.version,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.form.version) = $event)),
            modelModifiers: { number: true },
            type: "number",
            min: "1",
            isInvalid: $options.isInvalidField('version')
          }, null, 8, ["modelValue", "isInvalid"])
        ]),
        _: 1
      }, 8, ["label", "errorMessage"]),
      _createVNode(_component_DvpField, {
        class: "create-api-second-step__api-name-field",
        required: "",
        label: $data.contents.apiName,
        helpText: $data.contents.apiNameHelpText,
        errorMessage: $data.form.firstError('name'),
        "data-cy": "api-name-field"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_MTextInput, {
            modelValue: $data.form.name,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.form.name) = $event)),
            type: "text",
            placeholder: $data.contents.apiNamePlaceholder,
            isInvalid: $options.isInvalidField('name')
          }, null, 8, ["modelValue", "placeholder", "isInvalid"])
        ]),
        _: 1
      }, 8, ["label", "helpText", "errorMessage"]),
      _createVNode(_component_DvpField, {
        class: "create-api-second-step__api-description-field",
        required: "",
        label: $data.contents.apiDescription,
        errorMessage: $data.form.firstError('description'),
        "data-cy": "api-description-field"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_MTextArea, {
            modelValue: $data.form.description,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($data.form.description) = $event)),
            class: "create-api-second-step__api-description-textarea",
            placeholder: $data.contents.apiDescriptionPlaceholder,
            isInvalid: $options.isInvalidField('description')
          }, null, 8, ["modelValue", "placeholder", "isInvalid"])
        ]),
        _: 1
      }, 8, ["label", "errorMessage"])
    ]),
    formFooter: _withCtx(() => [
      _createVNode(_component_MButton, {
        type: "button",
        theme: "bordered-neutral",
        label: $data.contents.previous,
        onClick: $options.goToPreviousStep
      }, null, 8, ["label", "onClick"]),
      _createVNode(_component_MButton, {
        class: "create-api-second-step__submit-button",
        type: "submit",
        label: $data.contents.next,
        disabled: !!$data.form.errors.length || $options.isSaving
      }, null, 8, ["label", "disabled"])
    ]),
    _: 1
  }, 8, ["onSubmit"]))
}