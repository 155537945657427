<template>
  <tr
    :class="['update-or-remove-custom-proxy-header', { disabled: isDisabled }]"
  >
    <td>
      <div data-cy="header-key">
        <DvpField :errorMessage="form.firstError('name')">
          <MTextInput
            v-model="form.name"
            :placeholder="contents.namePlaceholder"
            :disabled="!isEditing"
            :isInvalid="isInvalidName"
          />
        </DvpField>
      </div>
    </td>
    <td>
      <div data-cy="header-value">
        <DvpField :errorMessage="form.firstError('value')">
          <MTextInput
            v-model="form.value"
            :placeholder="contents.valuePlaceholder"
            :disabled="!isEditing"
            :isInvalid="isInvalidValue"
          />
        </DvpField>
      </div>
    </td>
    <td v-if="allowHeaderOverride">
      <FormInput :error="form.firstError('isOverridingClientHeader')">
        <ToggleButton
          v-model="form.isOverridingClientHeader"
          class="update-or-remove-custom-proxy-header__override-toggle"
          :disabled="!isEditing"
        />
      </FormInput>
    </td>
    <td>
      <!-- update and cancel buttons -->
      <template v-if="isEditing">
        <CancelAndSubmitButtons
          class="update-or-remove-custom-proxy-header__cancel-and-submit-buttons"
          @cancel="cancel"
          @submit="update"
        />
      </template>

      <!-- Edit and remove buttons -->
      <template v-else>
        <ActionButtonsWrapper
          class="update-or-remove-custom-proxy-header__edit-and-delete-buttons"
        >
          <ButtonEdit @click="edit" />
          <ButtonDelete @click="remove" />
        </ActionButtonsWrapper>
      </template>
    </td>
  </tr>
</template>

<script lang="ts">
import MTextInput from "@mozaic-ds/vue-3/src/components/textinput/MTextInput.vue";

import ActionButtonsWrapper from "@/commons/components/Buttons/ActionButtonsWrapper.vue";
import ButtonDelete from "@/commons/components/Buttons/ButtonDelete.vue";
import ButtonEdit from "@/commons/components/Buttons/ButtonEdit.vue";
import CancelAndSubmitButtons from "@/commons/components/Buttons/CancelAndSubmitButtons.vue";
import FormInput from "@/commons/components/FormInput.vue";
import ToggleButton from "@/commons/components/ToggleButton.vue";
import DvpField from "@/commons/components/form/DvpField.vue";

import contents from "@/manager/contents/update-or-remove-custom-proxy-header";

export default {
  name: "UpdateOrRemoveCustomProxyHeader",
  components: {
    ActionButtonsWrapper,
    ButtonEdit,
    ButtonDelete,
    CancelAndSubmitButtons,
    ToggleButton,
    FormInput,
    DvpField,
    MTextInput,
  },
  props: {
    formParent: {
      type: Object,
      required: true,
    },
    form: {
      type: Object,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    allowHeaderOverride: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update", "remove"],
  data() {
    return {
      contents,
      isEditing: false,
      persistedCustomProxyHeader: null,
    };
  },
  computed: {
    isInvalidName() {
      return this.form.firstError("name") != null;
    },
    isInvalidValue() {
      return this.form.firstError("value") != null;
    },
  },
  methods: {
    async update() {
      await this.form.validate();
      await this.formParent.validate();

      if (!this.form.errors.length && !this.formParent.errors.length) {
        const customProxyHeader = this.form.data();
        this.$emit("update", customProxyHeader);
        this.persistedCustomProxyHeader = customProxyHeader;
        this.isEditing = false;
      }
    },
    async cancel() {
      await this.form.init(this.persistedCustomProxyHeader);
      await this.formParent.validate();
      this.isEditing = false;
    },
    async remove() {
      if (!this.isDisabled) {
        const result = await this.$swal({
          titleText: this.contents.confirmRemoveMessage,
        });
        if (result.isConfirmed) {
          this.$emit("remove", this.form.id);
        }
      }
    },
    edit() {
      if (!this.isDisabled) {
        this.persistedCustomProxyHeader = this.form.data();
        this.isEditing = true;
      }
    },
  },
};
</script>

<style lang="scss">
.update-or-remove-custom-proxy-header__override-toggle {
  margin-top: 0.5rem;
}

.update-or-remove-custom-proxy-header__cancel-and-submit-buttons {
  margin-top: 0.5rem;
}

.update-or-remove-custom-proxy-header__edit-and-delete-buttons {
  margin-top: 0.5rem;
}
</style>
