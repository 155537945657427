import AdminSettings from "@/admin/views/AdminSettings.vue";
import BatchesManagement from "@/admin/views/BatchesManagement/BatchesManagement.vue";
import GatewaySynchronization from "@/admin/views/GatewaySynchronization/GatewaySynchronization.vue";
import ManageGroups from "@/admin/views/ManageGroup/ManageGroups.vue";

import { Dictionary } from "@/commons/domain/models/dictionary";

export interface ManageGroupsUrlQuery {
  term: string;
  page: number;
  size: number;
}

const routes = [
  {
    path: "",
    component: AdminSettings,
    props: (route) => {
      return { menuId: route.meta.menuId };
    },
    children: [
      {
        path: "manage-groups",
        name: "adminManageGroups",
        component: ManageGroups,
        props: (route) => {
          const query = route.query as unknown as Dictionary<string>;
          const urlQuery: ManageGroupsUrlQuery = {
            term: query.term || "",
            page: query.page !== undefined ? Number(query.page) : 1,
            size: query.size !== undefined ? Number(query.size) : 25,
          };

          return { urlQuery };
        },
        meta: {
          secured: true,
          admin: true,
          title: "Administration | Manage groups",
          realm: "admin",
          menuId: "manageGroups",
        },
      },
      {
        path: "",
        name: "admin",
        redirect: { name: "adminManageGroups" },
      },
      {
        path: "sync",
        name: "adminGatewaySynchronization",
        component: GatewaySynchronization,
        meta: {
          secured: true,
          admin: true,
          title: "Administration | Gateway synchronization",
          realm: "admin",
          menuId: "gatewaySynchronization",
        },
      },
      {
        path: "batches-management",
        name: "adminBatchesManagement",
        component: BatchesManagement,
        meta: {
          secured: true,
          admin: true,
          title: "Administration | Batches Management",
          realm: "admin",
          menuId: "batchesManagement",
        },
      },
    ],
  },
];

export default routes;
