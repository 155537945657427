module.exports = {
  messageTransformationRequest:
    "If you want to set a fixed value to a request header, just specify your header key and value.\n" +
    "The override option allows you to change a value set on client-side.\n" +
    "If you want to set a header value from another header or a query parameter, use this specific syntax : ${request.headers.XXX}.",
  messageTransformationResponse:
    "If you want to set a fixed value to a response header, just specify your header key and value.\n" +
    "The override option allows you to change a value set on api-side.",
  requestHeaderDocumentationMessage: "cf. Request headers Documentation",
  responseHeaderDocumentationMessage: "cf. Response headers Documentation",
  headerName: "Header key",
  headerValue: "Header value",
  headerOverride: "Override",
  headerActions: "Actions",
  overrideTooltipResponse:
    "You can override headers that have already been defined on API side by using toggle on this column",
  overrideTooltipRequest:
    "You can override headers that have already been defined on the client side by using toggle on this column",
  routingHeadersTooltip:
    "The gateway will add the headers X-Gateway-Protocol, X-Gateway-Host and X-Gateway-BasePath before calling the backend",
  scopeHeadersTooltip:
    "The gateway will add the header X-OAuth-Scopes before calling the backend",
};
