import ApisCatalog from "@/documentation/views/ApisCatalog.vue";
import ProductDetail from "@/documentation/views/ProductDetail/ProductDetail.vue";
import ProductsCatalog from "@/documentation/views/ProductsCatalog/ProductsCatalog.vue";

import { onlyUrlChange } from "@/commons/libs/only-change-url";

import { Dictionary } from "@/commons/domain/models/dictionary";

const ApiDocumentationDetail = () =>
  import("@/documentation/views/ApiDetail/ApiDocumentationDetail.vue");
const DocsMain = () => import("@/documentation/views/Docs/DocsMain.vue");

export interface ApiCatalogListUrlQuery {
  term: string;
  productsIds: string[];
  page: number;
  accessTypes: string[];
  privacies: string[];
  platformsIds: string[];
  businessUnitsIds: string[];
  publicationZoneId: string;
  types: string[];
  onlySubscribed: boolean;
}

let cachedApiCatalogListUrlQuery = null;

export interface ProductListUrlQuery {
  term: string;
  page: number;
  platformsIds: string[];
}

let cachedProductListUrlQuery = null;

const routes = [
  {
    path: "",
    name: "docsMain",
    component: DocsMain,
    meta: {
      title: "Documentation",
      realm: "more",
      crossEnv: true,
      secured: true,
    },
  },
  {
    path: "api",
    name: "apiDocumentation",
    component: ApisCatalog,
    props: (route) => {
      const query = route.query as unknown as Dictionary<string>;
      const urlQuery: ApiCatalogListUrlQuery = {
        term: query.term || "",
        productsIds: query.productsIds ? query.productsIds.split(",") : [],
        page:
          query.page !== undefined &&
          onlyUrlChange(cachedApiCatalogListUrlQuery, query)
            ? Number(query.page)
            : 1,
        platformsIds:
          query.platformsIds !== undefined ? query.platformsIds.split(",") : [],
        businessUnitsIds:
          query.businessUnitsIds !== undefined
            ? query.businessUnitsIds.split(",")
            : [],
        accessTypes: query.accessTypes ? query.accessTypes.split(",") : [],
        privacies: query.privacies ? query.privacies.split(",") : [],
        publicationZoneId: query.publicationZoneId || "",
        types: query.types ? query.types.split(",") : [],
        onlySubscribed: query.onlySubscribed === "true",
      };

      cachedApiCatalogListUrlQuery = query;

      return { urlQuery };
    },
    meta: {
      title: "Documentation",
      realm: "docs",
      crossEnv: true,
      secured: true,
    },
  },
  {
    path: "product",
    name: "productDocumentation",
    component: ProductsCatalog,
    props: (route) => {
      const query = route.query as unknown as Dictionary<string>;
      const urlQuery: ProductListUrlQuery = {
        term: query.term || "",
        page:
          query.page !== undefined &&
          onlyUrlChange(cachedProductListUrlQuery, query)
            ? Number(query.page)
            : 1,
        platformsIds:
          query.platformsIds !== undefined ? query.platformsIds.split(",") : [],
      };

      cachedProductListUrlQuery = query;

      return { urlQuery };
    },
    meta: {
      title: "Documentation",
      realm: "docs",
      crossEnv: true,
      secured: true,
    },
  },
  {
    path: "api/:id",
    name: "apiDocumentationDetail",
    component: ApiDocumentationDetail,
    props: true,
    meta: {
      realm: "docs",
      secured: true,
    },
  },
  {
    path: "product/:id",
    name: "productDocumentationDetail",
    component: ProductDetail,
    meta: {
      title: "Product",
      realm: "docs",
      secured: true,
    },
  },
];

export default routes;
