<template>
  <div class="login">
    <Layout2>
      <MHeader
        :title="contents.accessYourAccount"
        :items="breadcrumbItems"
        showBreadcrumb
      />
      <LayoutSection class="login__layout-section" fullWidthContent>
        <CardContainer class="login__adeo-card">
          <img :src="adeoLogo" class="login__adeo-logo" />

          <div class="login__adeo-card-text">
            <h4 class="login__adeo-card-title">
              {{ contents.adeoEmployees }}
            </h4>
            <p class="login__adeo-card-subtitle">
              {{ contents.workingForAdeo }}
            </p>
            <MButton
              class="login__adeo-card-login-btn"
              :label="contents.loginWithAdeo"
              :href="pingLoginUrl"
            />
            <p class="login__adeo-card-partner-info">
              {{ contents.externalToAdeo }}
              <MLink
                class="login__adeo-card-partner-doc-link"
                size="s"
                @click="showPartnerLoginHelpModal()"
              >
                {{ contents.linkToPartnerLoginHelp }}
              </MLink>
            </p>
          </div>
        </CardContainer>
      </LayoutSection>
    </Layout2>
  </div>
</template>

<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import MHeader from "@mozaic-ds/vue-3/src/components/header/MHeader.vue";
import MLink from "@mozaic-ds/vue-3/src/components/link/MLink.vue";

import CardContainer from "@/commons/components/CardContainer.vue";
import Layout2 from "@/commons/components/Layout2.vue";
import LayoutSection from "@/commons/components/LayoutSection.vue";
import PartnerLoginHelpModal from "@/login/components/PartnerLoginHelpModal.vue";

import {
  BreadcrumbItem,
  getHomeItem,
  getLoginItem,
} from "@/commons/utils/breadcrumb-utils";

import contents from "@/commons/contents/login";

export default {
  components: {
    Layout2,
    LayoutSection,
    MHeader,
    MButton,
    CardContainer,
    MLink,
  },
  data() {
    return {
      contents,
      adeoLogo: require("@/commons/assets/images/logo-adeo.svg"),
    };
  },
  computed: {
    frontendConfig() {
      return this.$store.getters["config/frontendConfig"];
    },
    pingLoginUrl() {
      return `${this.frontendConfig.backendUrl}/auth/ping`;
    },
    breadcrumbItems(): BreadcrumbItem[] {
      return [getHomeItem(), getLoginItem()];
    },
  },
  mounted(): void {
    localStorage.removeItem("afterLoginRedirect");
    if (this.$route.query.redirect) {
      localStorage.setItem("afterLoginRedirect", this.$route.query.redirect);
    }
  },
  methods: {
    showPartnerLoginHelpModal(): void {
      this.$store.commit("openModal", {
        title: contents.accessYourAccount,
        component: PartnerLoginHelpModal,
      });
    },
  },
};
</script>

<style lang="scss">
.login {
  height: 100vh;
  background-color: var(--color-m-secondary-light);
}

.login__layout-section {
  padding-top: 5rem;
}

.login__adeo-card {
  display: flex;
  flex-direction: row;
  width: fit-content;
  margin: auto;
  column-gap: var(--base-spacing);
}

.login__adeo-logo {
  width: 84px;
  height: 84px;
  background-color: var(--color-m-secondary-light);
  border-radius: var(--m-border-radius-large);
}

.login__adeo-card-title {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.login__adeo-card-subtitle {
  margin: 0;
}

.login__adeo-card-login-btn {
  width: 100%;

  margin-top: 1rem;
}

.login__adeo-card-partner-info {
  @include set-text-s;

  margin-top: 1rem;
  margin-bottom: 0;
}

.login__adeo-card-partner-doc-link {
  margin-left: 0.5rem;
}
</style>
