<template>
  <form
    autocomplete="false | random-string"
    class="update-app"
    @submit.prevent="submit"
  >
    <div class="update-app__fields">
      <div
        v-if="user.isInternal"
        data-cy="update-app-for-testing"
        class="update-app__test-application"
      >
        <MCheckbox
          id="testing-value"
          class="update-app__test-application-value"
          :modelValue="form.forTesting"
          :disabled="isLoading || unableSwitchForTesting"
          :label="contents.testApplication"
          @update:modelValue="changeForTesting"
        />
        <IconTooltip
          :text="forTestingTooltipText"
          iconName="NotificationQuestion24"
        />
      </div>

      <DvpField
        data-cy="update-app-name"
        class="update-app__application-name"
        :label="contents.applicationName"
        :errorMessage="form.firstError('name')"
      >
        <MTextInput v-model="form.name" :isInvalid="isInvalidApplicationName" />
      </DvpField>

      <DvpField
        v-if="form.type"
        data-cy="update-app-type"
        class="update-app__app-type"
        :label="contents.applicationType"
        :errorMessage="form.firstError('type')"
      >
        <MSelect
          id="update-app__app-type-select"
          v-model="form.type"
          :placeholder="contents.applicationTypeLabel"
          :options="typeOptions"
        />
      </DvpField>

      <DvpField
        v-if="user.isInternal && !form.forTesting"
        data-cy="update-app-product"
        class="update-app__product"
        :label="contents.relatedProduct"
        :errorMessage="form.firstError('product')"
        :helpText="contents.applicationProductTooltip"
      >
        <AutoCompleteSelect
          id="appRelatedProduct"
          v-model="form.product"
          :disabled="isLoading"
          :options="productsList"
          :placeholder="contents.applicationProductPlaceholder"
          keyProperty="id"
          labelProperty="name"
          @searchChange="searchTerm"
        />
      </DvpField>

      <DvpField
        data-cy="update-app-description"
        class="update-app__description"
        :label="contents.description"
        :errorMessage="form.firstError('description')"
      >
        <MTextArea
          v-model="form.description"
          :disabled="isLoading"
          :placeholder="contents.applicationDescLabel"
          :isInvalid="isInvalidDescription"
        />
      </DvpField>
    </div>

    <div class="update-app__form-actions">
      <MButton
        data-cy="cancel-btn"
        type="button"
        theme="bordered-neutral"
        :label="contents.cancel"
        :disabled="isActionButtonDisabled()"
        @click="cancel"
      />
      <MButton
        data-cy="submit-btn"
        type="submit"
        :label="contents.updateButtonLabel"
        :disabled="isActionButtonDisabled()"
      />
    </div>
  </form>
</template>

<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import MCheckbox from "@mozaic-ds/vue-3/src/components/checkbox/MCheckbox.vue";
import MSelect from "@mozaic-ds/vue-3/src/components/select/MSelect.vue";
import MTextArea from "@mozaic-ds/vue-3/src/components/textarea/MTextArea.vue";
import MTextInput from "@mozaic-ds/vue-3/src/components/textinput/MTextInput.vue";
import { PropType } from "vue";

import AutoCompleteSelect from "@/commons/components/AutoCompleteSelect.vue";
import IconTooltip from "@/commons/components/IconTooltip.vue";
import DvpField from "@/commons/components/form/DvpField.vue";

import { Application } from "@/commons/domain/models/application";
import { searchProduct } from "@/commons/services/product/product-service";
import { UpdateAppForm } from "@/dashboard/domain/forms/update-app-form";

import contents from "@/dashboard/contents/create-or-update-app";

export default {
  name: "UpdateApp",
  components: {
    IconTooltip,
    DvpField,
    AutoCompleteSelect,
    MButton,
    MCheckbox,
    MTextInput,
    MTextArea,
    MSelect,
  },
  props: {
    application: {
      type: Object as PropType<Application>,
      required: true,
    },
  },
  data() {
    return {
      contents,
      form: UpdateAppForm.create(),
      productsList: [],
      persistedSettings: undefined,
    };
  },
  computed: {
    isInvalidApplicationName() {
      return this.form.firstError("name") != null;
    },
    isInvalidDescription() {
      return this.form.firstError("description") != null;
    },
    typeOptions() {
      return Object.entries(this.contents.applicationTypes).map(
        ([value, label]) => ({
          value,
          text: label,
        }),
      );
    },
    user() {
      return this.$store.getters["user"];
    },
    products() {
      return this.$store.getters["products"];
    },
    isLoading() {
      return this.$store.getters["isLoading"];
    },
    isSaving() {
      return this.$store.getters["isSaving"];
    },
    secretSyncIsActivated(): boolean {
      return this.application?.secretSynchronization?.isEnabled;
    },
    unableSwitchForTesting(): boolean {
      return this.application.forTesting == false && this.secretSyncIsActivated;
    },
    forTestingTooltipText(): string {
      return this.unableSwitchForTesting
        ? contents.unableToUpdateAppForTesting
        : contents.applicationForTestingTooltip;
    },
  },
  async mounted() {
    await this.searchTerm();

    const { name, description, product, type, forTesting } = this.application;

    const partial: any = {
      name,
      description,
      forTesting,
      type,
      userIsInternal: this.user.isInternal,
      product,
    };

    await this.form.init(partial);

    this.persistedSettings = this.form.data();
  },
  methods: {
    isActionButtonDisabled() {
      return this.isLoading || this.isSaving || this.form.pristine;
    },
    async cancel() {
      await this.form.init(this.persistedSettings);
    },
    async submit() {
      await this.form.validate();

      if (!this.form.errors.length && !this.isSubmitting) {
        const { name, type, description, product, forTesting } =
          this.form.data();

        this.$store.dispatch("dashboardUpdateApplication", {
          id: (this.application && this.application.id) || null,
          name,
          type,
          description,
          productId: product?.id,
          forTesting,
        });
      }
    },
    changeForTesting(forTestingNewValue) {
      const partial: any = {
        forTesting: forTestingNewValue,
      };

      if (partial.forTesting) {
        partial.product = null;
      }

      this.form.update(partial);
    },
    async searchTerm(search = "") {
      this.productsList = await searchProduct(search);
    },
  },
};
</script>

<style lang="scss">
.update-app__fields {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}

.update-app__test-application {
  display: flex;
  column-gap: 0.5rem;
}

.update-app__form-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 2.5rem;
  column-gap: var(--base-button-margin);
}
</style>
