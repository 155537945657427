import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "use-cases__title" }
const _hoisted_2 = { class: "description" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "use-cases__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_use_case_card = _resolveComponent("use-case-card")!
  const _component_contact_us = _resolveComponent("contact-us")!
  const _component_footer_menus = _resolveComponent("footer-menus")!
  const _component_layout = _resolveComponent("layout")!

  return (_openBlock(), _createBlock(_component_layout, { class: "use-cases" }, {
    footer: _withCtx(() => [
      _createVNode(_component_footer_menus)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString($data.contents.description), 1),
        _createElementVNode("h1", {
          class: "header-title",
          innerHTML: $data.contents.title
        }, null, 8, _hoisted_3)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.useCases, (useCase, index) => {
          return (_openBlock(), _createBlock(_component_use_case_card, {
            key: `use-case-${index}`,
            image: useCase.image,
            title: useCase.title,
            description: useCase.description,
            path: `/usecases/${useCase.slug}`
          }, null, 8, ["image", "title", "description", "path"]))
        }), 128))
      ]),
      _createVNode(_component_contact_us)
    ]),
    _: 1
  }))
}