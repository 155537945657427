import store from "@/commons/store";
import router from "@/router";
import moment from "moment/moment";
import { RouteLocationRaw } from "vue-router";

import { Dictionary } from "@/commons/domain/models/dictionary";

export const API_MANAGER_ROUTE_NAME = "managerApiList";
export const DASHBOARD_ROUTE_NAME = "dashboard";
export const API_CATALOG_ROUTE_NAME = "apiDocumentation";
export const PRODUCT_CATALOG_ROUTE_NAME = "productDocumentation";

export function convertRouteToHref(route: RouteLocationRaw): string {
  const resolvedRoute = router.resolve(route);
  return resolvedRoute.href;
}

export async function changeUrlQuery(partial: any) {
  const serializedUrlQuery: Dictionary<string> = serializeUrlQuery(partial);

  const urlQuery = {
    ...(router.currentRoute.value.query || {}),
    ...serializedUrlQuery,
  };

  for (const key in urlQuery) {
    if (urlQuery[key] === "") {
      delete urlQuery[key];
    }
  }

  await router.replace({
    query: urlQuery,
  });
}

export function serializeUrlQuery(urlQuery = {}): Dictionary<string> {
  const serializedUrlQuery: Dictionary<string> = {};

  for (const property in urlQuery) {
    if (Object.prototype.hasOwnProperty.call(urlQuery, property)) {
      let value = urlQuery[property];

      if (Array.isArray(value)) {
        value = value.join(",");
      } else if (value instanceof Date) {
        value = moment(value.toUTCString()).format("YYYY-MM-DDTHH:mm");
      } else if (typeof value === "number" || typeof value === "boolean") {
        value = String(value);
      }

      serializedUrlQuery[property] = value;
    }
  }

  return serializedUrlQuery;
}

export async function replaceUrlQuery(newUrlQuery: Dictionary<string>) {
  await router.replace({
    query: newUrlQuery,
  });
}

export function getPreviousPageRoute(defaultPreviousPageRouteName: string) {
  const savedPreviousPageRoute = store.getters["previousPageRoute"];

  return savedPreviousPageRoute.name === defaultPreviousPageRouteName
    ? { path: savedPreviousPageRoute.path, query: savedPreviousPageRoute.query }
    : { name: defaultPreviousPageRouteName };
}
