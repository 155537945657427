// Dirty functions for properly managing scrolling and menu positioning

export const navbarsElementClass = "navbars";

// Get the computed navbars height in pixels after the 'calc()' has been applied
export function getCurrentNavbarsHeightInPixels(): number {
  const navBarsElement = document.querySelector(
    `.${navbarsElementClass}`,
  ) as HTMLElement;

  return navBarsElement == null ? 0 : navBarsElement.offsetHeight;
}

// Allow you to override the css variable for navbars-height (used for scroll-padding and menu positioning)
export function setCurrentNavbarsHeightCssValue(newValue: string) {
  (document.querySelector(":root") as HTMLElement).style.setProperty(
    "--current-navbars-height",
    newValue,
  );
}
