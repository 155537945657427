<template>
  <div class="hits-table-container">
    <table class="table-light">
      <thead>
        <tr>
          <th>{{ contents.timestamp }}</th>
          <th>
            {{ contents.api }}
          </th>
          <th>{{ contents.method }}</th>
          <th>
            {{ contents.uri }}
            <DvpTooltip v-if="noHitUri()" :text="contents.tooltipUri">
              <MIcon name="NotificationQuestion24" />
            </DvpTooltip>
          </th>
          <th>{{ contents.status }}</th>
          <th>{{ contents.latency }}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(hit, index) in hitsData"
          :key="index"
          class="background-alternate"
        >
          <td>{{ timeFormat(hit.timestamp) }}</td>
          <td>
            <span>{{ hit.api.label }}</span>
          </td>
          <td>{{ hit.method }}</td>
          <td>
            <div
              v-if="(hit.uri && hit.uri.length <= 0) || hit.uri === undefined"
            >
              -
            </div>
            <CroppedText v-else :text="hit.uri" />
          </td>
          <td :class="{ [hit.status < 400 ? 'success' : 'danger']: true }">
            {{ hit.status }}
          </td>
          <td class="center">{{ Math.round(hit.latency) }}</td>
          <td>
            <IconButton
              mozaicIconName="BookStore32"
              @click="openHitDetails(hit)"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import MIcon from "@mozaic-ds/vue-3/src/components/icon/MIcon.vue";
import moment from "moment";
import { markRaw, PropType } from "vue";

import CroppedText from "@/commons/components/CroppedText.vue";
import DvpTooltip from "@/commons/components/DvpTooltip.vue";
import IconButton from "@/commons/components/IconButton.vue";

import HitDetailsModal from "./HitDetailsModal.vue";

import { Hit } from "@/commons/store/modules/reporting/types";

import contents from "@/commons/contents/hits-table";

export default {
  name: "HitsTable",
  components: { CroppedText, DvpTooltip, MIcon, IconButton },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    hitsData: {
      type: Array as PropType<Hit[]>,
      required: true,
    },
  },
  data() {
    return {
      contents,
      selectedHit: null,
    };
  },
  methods: {
    noHitUri() {
      return !this.hitsData.some((hit) => hit.uri && hit.uri.length >= 0);
    },
    timeFormat(timestamp) {
      return moment(timestamp).format("YYYY-MM-DD HH:mm ss.SSS[s]");
    },
    openHitDetails(hit) {
      this.$store.commit("openModal", {
        title: "Hit Details",
        component: markRaw(HitDetailsModal),
        props: { hit },
        listeners: {},
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/commons/assets/styles/vars";

.hits-table-container {
  height: 90vh;
  overflow-y: scroll;
}

td.center {
  text-align: center;
}
td.success {
  color: var(--color-success);
}
td.danger {
  color: var(--color-danger);
}
.btn.hit-details {
  height: 100%;
  padding: 0;
}
</style>
