export const DATE_ALIAS = {
  NOW: "now",
  ONE_HOUR_AGO: "one_hour_ago",
};
export const APPLICATION_DATE_HISTOGRAMS = ["api|status", "latency"];
export const API_DATE_HISTOGRAMS = ["status", "latency"];
export const PARAMS_SEPARATOR = ",";
export const API_REPORTING_AGGREGATIONS = [
  "application",
  "status",
  "method",
  "zone",
];
export const APPLICATION_REPORTING_AGGREGATIONS = ["api", "status", "method"];
export const NUM_HITS = 100;
export const DEFAULT_FILTERS_VALUES = {
  from: DATE_ALIAS.ONE_HOUR_AGO,
  to: DATE_ALIAS.NOW,
  applications: [],
  apis: [],
  status: [],
  methods: [],
  zones: [],
  numHits: NUM_HITS,
};
export const AGGREGATION_TO_FILTER_MAP = Object.freeze({
  application: "applications",
  api: "apis",
  status: "status",
  method: "methods",
  zone: "zones",
});
