import router from "@/router";
import { ActionContext } from "vuex";

import { getGroupWithUsersAclsRepository } from "@/commons/repositories/libs/get-group-with-users-acls-repository";
import { getUserRepository } from "@/commons/repositories/libs/get-user-repository";
import { UnauthorizedHttpError } from "@/commons/request-handlers/HttpResponseError";
import { finishAction } from "@/commons/store/helpers/finish-action";
import { flagSavingProcess } from "@/commons/utils/flagProcess.utils";

import { GroupWithUsersAcls } from "@/commons/domain/models/group-with-users-acls";

import { State } from "./types";
import { State as RootState } from "@/commons/store/types";

export async function acceptTOS({ commit }: ActionContext<State, RootState>) {
  await flagSavingProcess("user", async () => {
    const updatedUser = await getUserRepository().acceptTOS();

    finishAction({
      commit,
      path: "user",
      propertiesToReplace: {
        user: updatedUser,
      },
      toastNotification: {
        title: "Terms of service agreement accepted",
      },
      afterSave: () => {
        commit("closeModal");
        router.push({
          path: (router.currentRoute.value.query.redirect as string) || "/",
        });
      },
    });
  });
}

export async function logout({ commit }: ActionContext<State, RootState>) {
  await flagSavingProcess("user", async () => {
    await getUserRepository().logout();

    finishAction({
      commit,
      path: "user",
      propertiesToReplace: {
        user: undefined,
      },
    });
  });
}

export async function fetchUser({ commit }: ActionContext<State, RootState>) {
  commit("initFetchLoader", "user");

  try {
    const fetchedUser = await getUserRepository().findMe();

    commit("finishFetchLoader", "user");

    finishAction({
      commit,
      path: "user",
      propertiesToReplace: {
        user: fetchedUser,
      },
    });
  } catch (error) {
    // We want to intercept the error here to avoid the error message to be displayed in a toast because getting an error is part of
    // the normal behavior of the application when the user is not logged in (HTTP status 401)
    if (error instanceof UnauthorizedHttpError) {
      commit("finishFetchLoader", "user");
    } else {
      throw error;
    }
  }
}

export async function deleteUser({
  commit,
  dispatch,
}: ActionContext<State, RootState>) {
  await flagSavingProcess("user", async () => {
    await getUserRepository().delete();

    finishAction({
      commit,
      path: "user",
      propertiesToReplace: {
        user: undefined,
      },
      afterSave: async () => {
        commit("closeModal");
        await dispatch("logout");
      },
    });
  });
}

export async function updateUserGroup(
  { commit }: ActionContext<State, RootState>,
  {
    id,
    name,
    description,
  }: {
    id: string;
    name: string;
    description: string;
  },
) {
  await flagSavingProcess("user", async () => {
    const userResponse =
      await getGroupWithUsersAclsRepository().updateAndFindMe(
        id,
        name,
        description,
      );

    finishAction({
      commit,
      path: "user",
      propertiesToReplace: {
        user: userResponse,
      },
      toastNotification: { title: "Group updated" },
    });
  });
}

export async function createUserGroup(
  { commit }: ActionContext<State, RootState>,
  {
    name,
    description,
    administratorId,
    showSuccessNotification = true,
    roleId,
  }: {
    name: string;
    description: string;
    administratorId?: string;
    showSuccessNotification: boolean;
    roleId: string;
  },
) {
  await flagSavingProcess("user", async () => {
    const userResponse =
      await getGroupWithUsersAclsRepository().createAndFindMe(
        name,
        description,
        administratorId,
        roleId,
      );

    finishAction({
      commit,
      path: "user",
      propertiesToReplace: {
        user: userResponse,
      },
      toastNotification: showSuccessNotification
        ? {
            title: "Group created",
          }
        : undefined,
    });
  });
}

export async function removeUserGroup(
  { commit }: ActionContext<State, RootState>,
  group: GroupWithUsersAcls,
) {
  await flagSavingProcess("user", async () => {
    const userResponse =
      await getGroupWithUsersAclsRepository().removeAndFindMe(group.id);

    finishAction({
      commit,
      path: "user",
      propertiesToReplace: {
        user: userResponse,
      },
      toastNotification: { title: "Group deleted" },
    });
  });
}

export default {
  acceptTOS,
  logout,
  fetchUser,
  deleteUser,
  updateUserGroup,
  createUserGroup,
  removeUserGroup,
};
