import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "layer-modal-form__fields" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("form", {
    class: "layer-modal-form",
    autocomplete: "false | random-string",
    onSubmit: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('submit')), ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "fields")
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["layer-modal-form__footer", $options.globalLayerModalFooterClass])
    }, [
      _renderSlot(_ctx.$slots, "formFooter")
    ], 2)
  ], 32))
}