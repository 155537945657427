import { v4 as uuid } from "uuid";

import { Dictionary } from "@/commons/domain/models/dictionary";
import { PolicyOrder } from "@/commons/domain/models/policy-order";
import { PolicyOrderDto } from "@/commons/dtos/policy-order-dto";

export class PoliciesOrderMapper {
  static toDomain(policiesOrderDtos: PolicyOrderDto[] = []) {
    const policiesOrder: Dictionary<PolicyOrder> = {};

    for (const policyOrderDto of policiesOrderDtos) {
      const policyOrderId = uuid();

      policiesOrder[policyOrderDto.name] = {
        id: policyOrderId,
        type: policyOrderDto.type,
        name: policyOrderDto.name,
        order: policyOrderDto.order,
      };
    }

    return policiesOrder;
  }

  static toDto(policiesOrder: Dictionary<PolicyOrder>) {
    const policiesOrderDtos: PolicyOrderDto[] = [];

    for (const policyOrder of Object.values(policiesOrder)) {
      policiesOrderDtos.push({
        type: policyOrder.type,
        name: policyOrder.name,
        order: policyOrder.order,
      });
    }

    return policiesOrderDtos;
  }
}
