<script setup lang="ts">
import MBadge from "@mozaic-ds/vue-3/src/components/badge/MBadge.vue";
import { computed, PropType } from "vue";

import RouteInformation from "@/commons/components/RouteInformation.vue";
import GatewayLabelSymbols from "@/manager/views/ApiDetailRouting/GatewayLabelSymbols.vue";

import { Route } from "@/commons/domain/models/route";
import { Zone } from "@/commons/domain/models/zone";

import contents from "@/commons/contents/zone-information";

const props = defineProps({
  zone: {
    type: Object as PropType<Zone>,
    required: true,
  },
  routes: {
    type: Array as PropType<Route[]>,
    required: true,
  },
});

const isDeprecatedZone = computed((): boolean => {
  return props.routes.some((route: Route) => route.isDeprecated);
});

const sortedRoutes = computed((): Route[] => {
  const internalRoutes = props.routes.filter((route) => !route.isPublic);
  const publicRoutes = props.routes.filter((route) => route.isPublic);

  return [...internalRoutes, ...publicRoutes];
});
</script>

<template>
  <div class="zone-information">
    <div class="zone-information__header">
      <GatewayLabelSymbols :zone="zone" />
      <MBadge v-if="isDeprecatedZone" type="danger">{{
        contents.deprecated
      }}</MBadge>
    </div>
    <div class="zone-information__urls">
      <RouteInformation
        v-for="route in sortedRoutes"
        :key="route.id"
        :route="route"
      />
    </div>
  </div>
</template>

<style lang="scss">
.zone-information {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.zone-information__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.zone-information__icon {
  width: 24px;
  margin-right: 0.5rem;
}

.zone-information__urls {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
}
</style>
