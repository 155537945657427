import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MToggle = _resolveComponent("MToggle")!

  return (_openBlock(), _createBlock(_component_MToggle, {
    id: $options.toggleId,
    modelValue: $props.checked,
    disabled: $props.disabled,
    label: $props.label,
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => ($options.onClick()), ["prevent"]))
  }, null, 8, ["id", "modelValue", "disabled", "label"]))
}