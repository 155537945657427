<template>
  <div v-if="privacyInfo" class="api-access-policy">
    <div class="api-access-policy__icon">
      <img
        v-if="privacyInfo.iconSvg"
        class="api-access-policy__custom-icon"
        :src="privacyInfo.iconSvg"
      />
      <MIcon
        v-else
        class="api-access-policy__mozaic-icon"
        :name="privacyInfo.iconName"
      />
    </div>
    <div class="api-access-policy__label">
      <div data-cy="api-privacy" class="api-access-policy__privacy">
        {{ privacyInfo.privacyLabel }}
      </div>
      <div data-cy="api-access-type" class="api-access-policy__policy">
        {{ accessPolicyLabel }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import MIcon from "@mozaic-ds/vue-3/src/components/icon/MIcon.vue";
import { PropType } from "vue";

import { isRestApi } from "@/commons/libs/utils/apiUtils";

import { Api } from "@/commons/domain/models/api";

import { EPrivacyName } from "@/commons/store/types";

import contents from "@/commons/contents/api-access-policy";

interface PrivacyInfo {
  privacyLabel: string;
  privacy: EPrivacyName;
  iconName?: string;
  iconSvg?: string;
}

export default {
  name: "ApiAccessPolicy",
  components: {
    MIcon,
  },
  props: {
    api: {
      type: Object as PropType<Api>,
      required: true,
    },
  },
  computed: {
    allPrivacyInfoList(): PrivacyInfo[] {
      return [
        {
          privacyLabel: isRestApi(this.api)
            ? contents.privateApi
            : contents.privateTopic,
          privacy: EPrivacyName.PRIVATE,
          iconName: "Security24",
        },
        {
          privacyLabel: contents.internalApi,
          privacy: EPrivacyName.INTERNAL,
          iconSvg: require("@/commons/assets/images/api-access-policy/privacy-internal.svg"),
        },
        {
          privacyLabel: contents.partnerApi,
          privacy: EPrivacyName.PARTNER,
          iconName: "PartnerCompany24",
        },
        {
          privacyLabel: isRestApi(this.api)
            ? contents.publicApi
            : contents.publicTopic,
          privacy: EPrivacyName.PUBLIC,
          iconName: "ContactWebsite24",
        },
      ];
    },
    privacyInfo(): PrivacyInfo {
      return this.allPrivacyInfoList.find(
        (privacyInfo) => privacyInfo.privacy === this.api.privacy,
      );
    },
    accessPolicyLabel(): string {
      return contents.accessPolicy[this.api.accessPolicy];
    },
  },
};
</script>

<style lang="scss">
.api-access-policy {
  @include set-text-s;

  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5rem;
}

.api-access-policy__custom-icon {
  width: 24px; // Same width as Mozaic's icons
}

.api-access-policy__policy {
  @include set-text-xs;
  color: var(--color-text-minor);
}
</style>
