import { ArrayNotEmpty, IsNotEmpty, Matches, MaxLength } from "class-validator";

import { FormData } from "@/commons/domain/forms/form-data";
import { FormFactory } from "@/commons/domain/forms/form-factory";
import { PATH_REGEX_EXPRESSION } from "@/manager/domain/forms/blacklist-policy-form";

export class CreateUpdateBlackListPolicyForm extends FormData {
  @IsNotEmpty()
  @MaxLength(255)
  @Matches(new RegExp(PATH_REGEX_EXPRESSION))
  pathRegex: string = "";

  @ArrayNotEmpty({ message: "should contain at least one HTTP Method" })
  httpMethods: string[] = [];

  $clear() {
    this.pathRegex = "";
    this.httpMethods = [];
  }

  constructor(pathRegex: string = "", httpMethods: string[] = []) {
    super();
    this.pathRegex = pathRegex;
    this.httpMethods = httpMethods;
  }

  static create() {
    return FormFactory.createForm(new CreateUpdateBlackListPolicyForm());
  }
}
