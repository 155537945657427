import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SecondaryNavbarLoading = _resolveComponent("SecondaryNavbarLoading")!
  const _component_ApiSettingsSecondaryNavbar = _resolveComponent("ApiSettingsSecondaryNavbar")!
  const _component_SettingsMenu = _resolveComponent("SettingsMenu")!
  const _component_NotificationForKongSynchronization = _resolveComponent("NotificationForKongSynchronization")!
  const _component_ApiAlerts = _resolveComponent("ApiAlerts")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Stack = _resolveComponent("Stack")!
  const _component_Layout = _resolveComponent("Layout")!

  return (_openBlock(), _createBlock(_component_Layout, { class: "settings" }, {
    secondaryNavbar: _withCtx(() => [
      ($options.isLoading || $options.currentApi === undefined)
        ? (_openBlock(), _createBlock(_component_SecondaryNavbarLoading, { key: 0 }))
        : (_openBlock(), _createBlock(_component_ApiSettingsSecondaryNavbar, {
            key: 1,
            api: $options.currentApi
          }, null, 8, ["api"]))
    ]),
    sideMenu: _withCtx(() => [
      _createVNode(_component_SettingsMenu, { "menu-id": $props.menuId }, null, 8, ["menu-id"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_Stack, null, {
        default: _withCtx(() => [
          (
          $options.currentApi && $options.displayKongInformation && $options.hasApiStatusAsPendingDeploy
        )
            ? (_openBlock(), _createBlock(_component_NotificationForKongSynchronization, {
                key: 0,
                configurationType: "Api"
              }))
            : _createCommentVNode("", true),
          (!$options.isLoading && $options.checkList.length)
            ? (_openBlock(), _createBlock(_component_ApiAlerts, {
                key: 1,
                checkList: $options.checkList
              }, null, 8, ["checkList"]))
            : _createCommentVNode("", true),
          ($options.currentApi)
            ? (_openBlock(), _createBlock(_component_router_view, { key: 2 }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}