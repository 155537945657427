import { IsNotEmpty, Matches } from "class-validator";

import { FormData } from "@/commons/domain/forms/form-data";
import { FormFactory } from "@/commons/domain/forms/form-factory";
import { CustomQueryParam } from "@/commons/domain/models/custom-query-param";

export class CustomQueryParamForm extends FormData {
  id: string | null;

  @IsNotEmpty({
    message: "This field cannot be empty",
  })
  @Matches(/^\$\{request\.query\..*\}$/, {
    message: "Your query key must match this format ${request.query.XXX}",
  })
  name: string;

  @IsNotEmpty({
    message: "This field cannot be empty",
  })
  value: string;

  constructor(customQueryParam: CustomQueryParam) {
    super();
    this.$update(customQueryParam);
  }

  $clear() {
    this.name = "";
    this.value = "";
  }

  static create(
    customQueryParam: CustomQueryParam = {
      id: null,
      name: "",
      value: "",
    },
  ) {
    return FormFactory.createForm(new CustomQueryParamForm(customQueryParam));
  }
}
