import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-165167d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-area" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id", "disabled", "placeholder", "rows", "cols", "value", "spellcheck"]
const _hoisted_4 = {
  key: 0,
  class: "text-area__error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", { for: $options.id }, _toDisplayString($props.label), 9, _hoisted_2),
    _createElementVNode("textarea", {
      id: $options.id,
      class: _normalizeClass([
        $props.developerMode && 'developer-mode',
        $props.error && 'text-area__with-error',
      ]),
      disabled: $props.disabled,
      placeholder: $props.placeholder,
      rows: $props.rows,
      cols: $props.cols,
      value: $props.modelValue,
      spellcheck: $props.spellcheck,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value)))
    }, null, 42, _hoisted_3),
    ($props.error)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString($props.error), 1))
      : _createCommentVNode("", true)
  ]))
}