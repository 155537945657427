module.exports = {
  modalTitle: "Request larger timeout",
  timeoutLabel: "Define timeout in seconds",
  messageLabel: "Why you'd like to have a larger timeout",
  messageHelpId: "resquestTimeoutMessage",
  messageHelpText:
    "Tell us a bit about your request before we grant you access",
  messagePlaceholder: "Your message...",
  cancel: "Cancel",
  submit: "Validate",
  timeoutLimitInformationTitle: "The timeout is limited",
  timeoutLimitPublicApiInformation: (maxTimeout) =>
    `The maximum value is <strong>${maxTimeout} seconds</strong> for APIs exposed on the internet, due to CDN limitation.`,
};
