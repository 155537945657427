import { resolveDynamicComponent as _resolveDynamicComponent, toHandlers as _toHandlers, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "global-layer-modal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MLayer = _resolveComponent("MLayer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MLayer, {
      open: $options.isOpen,
      "onUpdate:open": _cache[0] || (_cache[0] = ($event: any) => (($options.isOpen) = $event)),
      layerTitle: $options.title,
      extended: ""
    }, {
      default: _withCtx(() => [
        ($options.isOpen)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent($options.component), _mergeProps({ key: 0 }, $options.props, _toHandlers($options.listeners), { onOnClose: $options.handleClose }), null, 16, ["onOnClose"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["open", "layerTitle"])
  ]))
}