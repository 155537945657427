<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9 2H13C13.5523 2 14 2.44772 14 3V7C14 7.55228 13.5523 8 13 8C12.4477 8 12 7.55228 12 7V5.41L8.71 8.71C8.52223 8.89931 8.26664 9.0058 8 9.0058C7.73336 9.0058 7.47777 8.89931 7.29 8.71C7.10069 8.52223 6.9942 8.26664 6.9942 8C6.9942 7.73336 7.10069 7.47777 7.29 7.29L10.59 4H9C8.44772 4 8 3.55228 8 3C8 2.44772 8.44772 2 9 2ZM4 12H11V10.5H13V13C13 13.5523 12.5523 14 12 14H3C2.44772 14 2 13.5523 2 13V4C2 3.44772 2.44772 3 3 3H5.5V5H4V12Z"
      fill="black"
    />
  </svg>
</template>
