module.exports = {
  descriptionPlaceholder: "No description provided",
  testApplication: "test application",
  noContract: "There is no contract on this application",
  topicContracts: "Topic contracts",
  apiRestContracts: "API Rest contracts",
  pendingRequest: "pending request waiting for validation",
  apiKeys: "API keys expired",
  from: "from",
};
