import { IsNotEmpty, MaxLength } from "class-validator";

import { FormData } from "@/commons/domain/forms/form-data";
import { FormFactory } from "@/commons/domain/forms/form-factory";

export class CreateOrUpdateIPRangeForm extends FormData {
  @IsNotEmpty()
  address: string = "";

  @MaxLength(30)
  comment: string = "";

  $clear() {
    this.address = "";
    this.comment = "";
  }

  constructor(address: string = "", comment: string = "") {
    super();
    this.address = address;
    this.comment = comment;
  }

  static create() {
    return FormFactory.createForm(new CreateOrUpdateIPRangeForm());
  }
}
