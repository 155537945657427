export default {
  modalTitle: "Information about your flow settings",
  activeCCFSubscriptionTitle:
    "Your Client Credentials Flow cannot be unchecked",
  activeCCFSubscriptionExplanation: (hrefToApiConsumers) =>
    "**Your API has currently valid consumers using Client Credentials flow.\n" +
    "This change would break their use. You cannot do it now. Two solutions:**\n\n" +
    `- You disable their credentials in your [Consumers page](${hrefToApiConsumers}) (they will NOT be able to call you anymore).\n` +
    "- You create a new API with same configuration, but only using Authorization Code Flow.",
  activeACFSubscriptionTitle:
    "Your Authorization Code Flow cannot be unchecked",
  activeACFSubscriptionExplanation: (hrefToApiConsumers) =>
    "**Your API has currently valid consumers using Authorization Code flow.\n" +
    "This change would break their use. You cannot do it now. Two solutions:**\n\n" +
    `- You disable their credentials in your [Consumers page](${hrefToApiConsumers}) (they will NOT be able to call you anymore).\n` +
    "- You create a new API with same configuration, but only using Client Credentials Flow.",
  buttonClose: "Close",
  buttonGoToConsumers: "Go to Consumers page",
};
