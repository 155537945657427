<template>
  <LayerModalFormConfirm
    class="modal-filters-api-manager"
    :confirmBtnLabel="contents.confirmButtonLabel"
    confirmBtnTheme="solid"
    :disabledConfirmBtn="form.pristine"
    @submit="submit"
    @close="close"
  >
    <DvpField
      data-cy="products-filter"
      class="modal-filters-api-manager__products"
      :label="contents.productsLabel"
    >
      <MAutocomplete
        v-model="selectedProductIds"
        :items="products"
        :placeholder="contents.searchProduct"
        dataTextExpr="name"
        dataValueExpr="id"
        :multiple="true"
        :filter="false"
        :input="defaultInput"
        @update:input="debouncedSearchProducts"
      />
    </DvpField>

    <DvpField
      v-if="userIsInternal"
      data-cy="platforms-filter"
      class="modal-filters-api-manager__platforms"
      :label="contents.platformsLabel"
    >
      <MAutocomplete
        v-model="form.platformsIds"
        :items="platforms"
        :placeholder="contents.searchPlatforms"
        dataTextExpr="description"
        dataValueExpr="id"
        :multiple="true"
        :input="defaultInput"
      />
    </DvpField>

    <DvpField
      v-if="userIsInternal"
      data-cy="business-units-filter"
      class="modal-filters-api-manager__business-units"
      :label="contents.buLabel"
    >
      <MAutocomplete
        v-model="form.businessUnitsIds"
        :items="businessUnits"
        :placeholder="contents.searchBusinessUnit"
        dataTextExpr="description"
        dataValueExpr="id"
        :multiple="true"
        :input="defaultInput"
      />
    </DvpField>

    <DvpField
      v-if="userIsAdmin"
      data-cy="publication-status-filter"
      class="modal-filters-api-manager__publication-status"
    >
      <MCheckboxGroup
        v-model="form.publicationStatuses"
        :legend="contents.publicationStatusLabel"
        :options="publicationStatuses"
        :inline="true"
      />
    </DvpField>

    <DvpField
      v-if="userIsAdmin"
      data-cy="publication-zone-filter"
      class="modal-filters-api-manager__publication-zones"
      :label="contents.publicationZoneLabel"
    >
      <MSelect
        id="publication-zone-filter"
        v-model="selectedPublicationZone"
        :options="publicationZones"
        :placeholder="contents.searchPublicationZones"
      />
    </DvpField>

    <DvpField
      v-if="userIsAdmin"
      data-cy="access-types-filter"
      class="modal-filters-api-manager__access-types"
    >
      <MCheckboxGroup
        v-model="form.accessTypes"
        :legend="contents.accessTypeLabel"
        :options="accessTypes"
        :inline="true"
      />
    </DvpField>

    <DvpField
      v-if="userIsAdmin"
      data-cy="privacies-filter"
      class="modal-filters-api-manager__privacy"
    >
      <MCheckboxGroup
        v-model="form.privacies"
        :legend="contents.privacyLabel"
        :options="privacies"
        :inline="true"
      />
    </DvpField>
  </LayerModalFormConfirm>
</template>
<script lang="ts">
import MAutocomplete from "@mozaic-ds/vue-3/src/components/autocomplete/MAutocomplete.vue";
import MCheckboxGroup from "@mozaic-ds/vue-3/src/components/checkbox/MCheckboxGroup.vue";
import MSelect from "@mozaic-ds/vue-3/src/components/select/MSelect.vue";
import { PropType } from "vue";

import DvpField from "@/commons/components/form/DvpField.vue";
import LayerModalFormConfirm from "@/commons/components/form/LayerModalFormConfirm.vue";

import { Debouncer } from "@/commons/libs/utils/debouncer";
import {
  ACCESS_TYPES,
  MODAL_ACCESS_TYPES_FILTER_ID_PREFIX,
  MODAL_PREFIX,
  MODAL_PRIVACIES_FILTER_ID_PREFIX,
  NONE_PUBLICATION_ZONE,
  PRIVACIES,
  PUBLICATIONS,
  getOrderedBuTagsList,
  mergeSearchAndSelectedValues,
} from "@/commons/utils/filter-utils";
import {
  toMSelectOptions,
  valuesToMCheckboxGroupOptions,
} from "@/commons/utils/mozaic-utils";

import {
  MCheckboxGroupOption,
  MSelectOption,
} from "@/commons/domain/models/mozaic";
import { Product } from "@/commons/domain/models/product";
import { Tag } from "@/commons/domain/models/tag";
import {
  FiltersApiManagerForm,
  FiltersApiManagerFormData,
} from "@/commons/forms/filters-api-manager-form";
import {
  getProductsByIds,
  searchProduct,
} from "@/commons/services/product/product-service";

import contents from "@/manager/contents/modal-filters-api-manager";

export default {
  components: {
    LayerModalFormConfirm,
    MSelect,
    MAutocomplete,
    MCheckboxGroup,
    DvpField,
  },
  props: {
    formData: {
      type: Object as PropType<FiltersApiManagerFormData>,
      required: true,
    },
  },
  emits: ["submitted", "onClose"],
  data() {
    return {
      form: FiltersApiManagerForm.create(),
      contents,
      searchedProducts: [],
      selectedProducts: [],
      debouncer: new Debouncer(),
      defaultInput: "",
    };
  },
  computed: {
    userIsInternal() {
      return this.$store.getters["userIsInternal"];
    },
    userIsAdmin() {
      return this.$store.getters["userIsAdmin"];
    },
    accessTypes(): MCheckboxGroupOption[] {
      return valuesToMCheckboxGroupOptions(
        ACCESS_TYPES,
        MODAL_ACCESS_TYPES_FILTER_ID_PREFIX,
      );
    },
    privacies(): MCheckboxGroupOption[] {
      return valuesToMCheckboxGroupOptions(
        PRIVACIES,
        MODAL_PRIVACIES_FILTER_ID_PREFIX,
      );
    },
    publicationStatuses(): MCheckboxGroupOption[] {
      return valuesToMCheckboxGroupOptions(PUBLICATIONS, MODAL_PREFIX);
    },
    publicationZones(): MSelectOption[] {
      return toMSelectOptions(
        [NONE_PUBLICATION_ZONE, ...this.$store.getters["zonesList"]],
        "name",
        "id",
      );
    },
    businessUnits(): Tag[] {
      return getOrderedBuTagsList(this.$store.getters["buTagsList"]);
    },
    platforms(): Tag[] {
      return this.$store.getters["platformTagsList"];
    },
    products(): Product[] {
      return mergeSearchAndSelectedValues(
        this.searchedProducts,
        this.selectedProducts,
      );
    },
    selectedProductIds: {
      get() {
        return this.form.productsIds;
      },
      async set(productIds: string[]) {
        this.form.productsIds = productIds;
        await this.updateSelectedProducts();
      },
    },
    selectedPublicationZone: {
      get() {
        return this.form.publicationZoneId;
      },
      async set(publicationZoneId) {
        if (publicationZoneId === NONE_PUBLICATION_ZONE.id) {
          this.form.publicationZoneId = undefined;
        } else {
          this.form.publicationZoneId = publicationZoneId;
        }
      },
    },
  },
  async mounted() {
    await this.form.init(this.formData);
    await this.searchProducts("");
    await this.updateSelectedProducts();
  },
  methods: {
    debouncedSearchProducts(searchValue: string): void {
      this.debouncer.debounce(() => this.searchProducts(searchValue), 100);
    },
    async searchProducts(name: string) {
      this.searchedProducts = await searchProduct(name);
    },
    async updateSelectedProducts() {
      this.selectedProducts = await getProductsByIds(this.form.productsIds);
    },
    close() {
      this.$emit("onClose");
    },
    async submit() {
      this.$emit("submitted", {
        ...this.form.data(),
        products: this.selectedProducts,
      });
      this.$emit("onClose");
    },
  },
};
</script>
