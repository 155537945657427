import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "request-manager-button-view-slack-messages__label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    href: $props.slackUrl,
    target: "_blank",
    class: "request-manager-button-view-slack-messages__button"
  }, [
    _createElementVNode("img", {
      src: $data.iconSlack,
      class: "request-manager-button-view-slack-messages__icon"
    }, null, 8, _hoisted_2),
    _createElementVNode("span", _hoisted_3, _toDisplayString($data.contents.viewMessages), 1)
  ], 8, _hoisted_1))
}