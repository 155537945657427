import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cookie-array" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("table", _hoisted_1, [
    _createElementVNode("thead", null, [
      _createElementVNode("tr", null, [
        _createElementVNode("th", null, _toDisplayString($data.contents.name), 1),
        _createElementVNode("th", null, _toDisplayString($data.contents.provider), 1),
        _createElementVNode("th", null, _toDisplayString($data.contents.finality), 1),
        _createElementVNode("th", null, _toDisplayString($data.contents.expiration), 1),
        _createElementVNode("th", null, _toDisplayString($data.contents.type), 1)
      ])
    ]),
    _createElementVNode("tbody", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.data, (cookie, index) => {
        return (_openBlock(), _createElementBlock("tr", {
          key: `${cookie.name}-${index}`
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(cookie, (infoCookie, i) => {
            return (_openBlock(), _createElementBlock("td", {
              key: `cell-${i}`
            }, _toDisplayString(infoCookie), 1))
          }), 128))
        ]))
      }), 128))
    ])
  ]))
}