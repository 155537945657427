import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_use_case_main = _resolveComponent("use-case-main")!
  const _component_slide = _resolveComponent("slide")!
  const _component_use_case_feature = _resolveComponent("use-case-feature")!
  const _component_contact_us = _resolveComponent("contact-us")!
  const _component_footer_menus = _resolveComponent("footer-menus")!
  const _component_slides_layout = _resolveComponent("slides-layout")!

  return (_openBlock(), _createBlock(_component_slides_layout, { class: "use-case" }, {
    slides: _withCtx(() => [
      _createVNode(_component_slide, {
        class: "use-case__main",
        anchor: "use-case-main"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_use_case_main, {
            "use-case": $options.useCase,
            onNavigateToAnchor: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigateToAnchor($event)))
          }, null, 8, ["use-case"])
        ]),
        _: 1
      }),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.useCase.features, (feature, index) => {
        return (_openBlock(), _createBlock(_component_slide, {
          key: `use-case-feature-${feature.name}`,
          class: "use-case-feature__detail",
          anchor: `use-case-feature-${feature.name}`
        }, {
          default: _withCtx(() => [
            _createVNode(_component_use_case_feature, _mergeProps({ ref_for: true }, feature, { index: index }), null, 16, ["index"])
          ]),
          _: 2
        }, 1032, ["anchor"]))
      }), 128)),
      _createVNode(_component_slide, { anchor: "use-case-contact-us" }, {
        default: _withCtx(() => [
          _createVNode(_component_contact_us),
          _createVNode(_component_footer_menus)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}