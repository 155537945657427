import { HttpRepository } from "@/commons/repositories/libs/http-repository";

import { Injectable } from "@/commons/domain/di/injectable";
import { InitConfig } from "@/commons/domain/models/init-config";
import { InitConfigRepository } from "@/commons/domain/repositories/init-config-repository";

@Injectable()
export class InitConfigHttpRepository
  extends HttpRepository
  implements InitConfigRepository
{
  public async loadInitConfig() {
    const response = await this.requestHandler.get<InitConfig>(`/init-config`);
    return response.data;
  }
}
