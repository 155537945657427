export default {
  modalTitle: "Delete API Key",
  informationTitle: "Information",
  applicationName: (applicationName) => `Application: ${applicationName}`,
  apiName: (apiName) => `API Name: ${apiName}`,
  expirationDate: (expirationDate, daysRemaining) =>
    `Expiration Date: ${expirationDate} (${daysRemaining} days remaining)`,
  deletionTitle: "Do you want to delete the API Key ?",
  subtitle:
    "If you do not use this API Key anymore, type **'DELETE'** below and confirm to apply the change.\n" +
    "Your other API Key, that you must use to call the API, will not be impacted. ",
  delete: "DELETE",
  notAllowedTitleNotification: "Token deletion not allowed",
  notAllowedMessageNotification: "You are not allowed to delete this token.",
  noManagerRightsMessageNotification:
    "You have no MANAGER right on the application, and are not allowed to delete this token.",
  lastTokenOnSubscriptionMessageNotification: (apiLabel: string) =>
    `You cannot delete the last API Key of your subscription with ${apiLabel}. ` +
    `Please Renew first or Unsubscribe instead.`,
  tokenNotFoundTitleNotification: "Token not found",
  tokenNotFoundMessageNotification: (tokenId) =>
    `Token ${tokenId} does not exist or is already deleted.`,
  toastNotification: {
    title: "API Key deleted",
    warningContent: (secretManager) =>
      `API Key will be removed from Kong Gateway with the next synchronization. However, your ${secretManager} namespace will be synchronized later.`,
  },
};
