import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconAuthorizationRequired = _resolveComponent("IconAuthorizationRequired")!
  const _component_SvgWithLabel = _resolveComponent("SvgWithLabel")!

  return (_openBlock(), _createBlock(_component_SvgWithLabel, { class: "authorization-required" }, {
    right: _withCtx(() => [
      _createTextVNode(_toDisplayString($data.contents.requiredAuthorization), 1)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_IconAuthorizationRequired)
    ]),
    _: 1
  }))
}