<template>
  <form
    class="send-email-modal"
    autocomplete="false | random-string"
    @submit.prevent="onSubmit"
  >
    <Stack class="send-email-modal__form-fields">
      <MField
        v-if="form.needApiSelection"
        id="api-field"
        class="send-email-modal__api-field"
        :label="contents.apiSelectionLabel"
        requirementText="required"
        :isInvalid="isInvalidSelectedApi"
        :errorMessage="form.firstError('api')"
      >
        <MSelect
          id="api-selection"
          v-model="form.api"
          :placeholder="contents.apiSelectionPlaceholder"
          :options="apiOptions"
        />
      </MField>

      <MField
        id="subject"
        class="send-email-modal__subject-field"
        :label="contents.subjectLabel"
        requirementText="required"
        :isInvalid="isInvalidSubject"
        :errorMessage="form.firstError('subject')"
      >
        <MTextInput
          v-model="form.subject"
          :placeholder="contents.subjectPlaceholder"
          :isInvalid="isInvalidSubject"
        />
      </MField>

      <MField
        id="message"
        class="send-email-modal__message-field"
        :label="contents.messageLabel"
        requirementText="required"
        :isInvalid="isInvalidMessage"
        :errorMessage="form.firstError('body')"
      >
        <MTextArea
          v-model="form.body"
          :placeholder="contents.messagePlaceholder"
          :isInvalid="isInvalidMessage"
        />
      </MField>
    </Stack>

    <div class="send-email-modal__form-footer global-layer-modal-footer">
      <MButton
        class="send-email-modal__submit-button"
        type="submit"
        :disabled="!!form.errors.length"
        :label="contents.submit"
      />
    </div>
  </form>
</template>

<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import MField from "@mozaic-ds/vue-3/src/components/field/MField.vue";
import MSelect from "@mozaic-ds/vue-3/src/components/select/MSelect.vue";
import MTextArea from "@mozaic-ds/vue-3/src/components/textarea/MTextArea.vue";
import MTextInput from "@mozaic-ds/vue-3/src/components/textinput/MTextInput.vue";

import Stack from "@/commons/components/Stack.vue";

import { getApiRestLabel } from "@/commons/libs/utils/apiUtils";
import { getApiRepository } from "@/commons/repositories/libs/get-api-repository";

import { Api } from "@/commons/domain/models/api";
import { EmailForm } from "@/commons/forms/email-form";

import contents from "@/documentation/contents/send-email-modal";

export default {
  name: "SendEmailModal",
  components: {
    Stack,
    MTextInput,
    MTextArea,
    MField,
    MButton,
    MSelect,
  },
  props: {
    apis: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["onClose"],
  data() {
    const needApiSelection = this.apis.length > 0;
    return {
      form: EmailForm.create(needApiSelection),
      contents,
    };
  },
  computed: {
    api() {
      return this.$store.getters["api"];
    },
    isInvalidSelectedApi() {
      return this.form.firstError("api") != null;
    },
    isInvalidSubject() {
      return this.form.firstError("subject") != null;
    },
    isInvalidMessage() {
      return this.form.firstError("body") != null;
    },
    apiOptions() {
      return this.apis.map((api: Api) => ({
        value: api.id,
        text: getApiRestLabel(api),
      }));
    },
  },
  async mounted() {
    await this.form.init();
  },
  methods: {
    async onSubmit() {
      if (!this.form.errors.length) {
        const { subject, body, api } = this.form.data();

        const apiId = api !== undefined ? api : this.api.id;

        await getApiRepository().notifyOwners(apiId, subject, body);
        this.$store.commit("postSuccessNotification", {
          title: this.contents.sendingSuccessNotification,
        });
        this.$emit("onClose");
      }
    },
  },
};
</script>

<style lang="scss">
.send-email-modal {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__form-fields {
    flex: 1 1;
  }

  &__form-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
</style>
