import { OAuthToken } from "@/commons/domain/models/oauth-token";

export const getPingEnvironmentFlagTheme = (name: string): string => {
  switch (name) {
    case "Ping PROD":
      return "solid";
    default:
      return "bordered";
  }
};

export const comparePingProviders = (a: OAuthToken, b: OAuthToken): number => {
  return getPingProviderWeight(a.oauthDefinition?.provider) <
    getPingProviderWeight(b.oauthDefinition?.provider)
    ? 1
    : -1;
};

export const getPingProviderWeight = (name: string): number => {
  switch (name) {
    case "Ping":
      return 100;
    default:
      return 1;
  }
};

export const getPingProviderLabel = (name: string): string => {
  switch (name) {
    case "Ping":
      return "Ping PROD";
    case "Ping-prep":
      return "Ping PREP";
    case "Ping-rec":
      return "Ping REC";
    default:
      return name;
  }
};
