import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementVNode as _createElementVNode, renderList as _renderList, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flat-menu" }
const _hoisted_2 = { class: "flat-menu__label" }
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "flat-menu__menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuItem = _resolveComponent("MenuItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!!$props.url)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            href: $props.url,
            class: "flat-menu__link-label"
          }, _toDisplayString($props.label), 9, _hoisted_3))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createTextVNode(_toDisplayString($props.label), 1)
          ], 64))
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, (item, index) => {
        return (_openBlock(), _createBlock(_component_MenuItem, _mergeProps({ ref_for: true }, item, {
          key: `${$options.id}-${index}`,
          isDisplayImage: $props.isDisplayImage,
          isDisplayDescription: $props.isDisplayDescription,
          colorWhite: ""
        }), null, 16, ["isDisplayImage", "isDisplayDescription"]))
      }), 128))
    ])
  ]))
}