import { labelize, labelizeUpperCase } from "@/commons/libs/utils/stringUtils";

import {
  EMTagListType,
  MCheckboxGroupOption,
  MRadioButtonItem,
  MSelectOption,
  MTagListItem,
} from "@/commons/domain/models/mozaic";
import { Tag } from "@/commons/domain/models/tag";

export function toMCheckboxGroupOptions(
  items: any[],
  idPropertyName: string,
  labelPropertyName: string,
  valuePropertyName: string,
): MCheckboxGroupOption[] {
  return items.map((i) => ({
    id: i[idPropertyName],
    label: i[labelPropertyName],
    value: i[valuePropertyName],
  }));
}

export function valuesUpperCaseToMCheckboxGroupOptions(
  items: string[],
  idPrefix?: string,
): MCheckboxGroupOption[] {
  return items.map((i) => ({
    id: idPrefix ? `${idPrefix}-${i}` : i,
    label: labelizeUpperCase(i),
    value: i,
  }));
}

export function valuesToMCheckboxGroupOptions(
  items: string[],
  idPrefix?: string,
): MCheckboxGroupOption[] {
  return items.map((i) => ({
    id: idPrefix ? `${idPrefix}-${i}` : i,
    label: labelize(i),
    value: i,
  }));
}

export function toMSelectOptions(
  items: any[],
  textPropertyName: string,
  valuePropertyName: string,
): MSelectOption[] {
  return items.map((i) => ({
    text: i[textPropertyName],
    value: i[valuePropertyName],
  }));
}

export function valuesUpperCaseToMSelectOptions(
  values: string[],
): MSelectOption[] {
  return values.map((v) => ({
    text: labelizeUpperCase(v),
    value: v,
  }));
}

export function valuesToMSelectOptions(items: string[]): MSelectOption[] {
  return items.map((i) => ({
    text: i,
    value: i,
  }));
}
export function valuesToMTagListItems(
  values: string[],
  type?: EMTagListType,
  idPrefix?: string,
): MTagListItem[] {
  return values.map((v) => ({
    id: idPrefix ? `${idPrefix}-${v}` : v,
    label: labelize(v),
    type,
  }));
}

export function valuesUpperCaseToMTagListItems(
  values: string[],
  type?: EMTagListType,
  idPrefix?: string,
): MTagListItem[] {
  return values.map((v) => ({
    id: idPrefix ? `${idPrefix}-${v}` : v,
    label: labelizeUpperCase(v),
    type,
  }));
}

export function tagsToMTagListItems(
  tags: Tag[],
  type?: EMTagListType,
): MTagListItem[] {
  return tags.map((t) => ({
    id: t.id,
    label: t.description,
    type,
  }));
}

export function valuesUpperCaseToMRadioButtonItems(
  values: string[],
): MRadioButtonItem[] {
  return values.map((v) => ({
    id: v,
    label: labelizeUpperCase(v),
    value: v,
  }));
}
