<template>
  <GlobalModalBody class="partner-login-help-modal">
    <template #content>
      <h5 class="partner-login-help-modal__title">
        {{ contents.partnerLoginHelpTitle }}
      </h5>
      <Markdown :content="contents.partnerLoginHelpContent" />
    </template>
    <template #footer>
      <MButton
        theme="bordered-neutral"
        :label="contents.closeModalButton"
        @click="$emit('onClose')"
      />
    </template>
  </GlobalModalBody>
</template>

<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";

import GlobalModalBody from "@/commons/components/Modal/GlobalModalBody.vue";
import Markdown from "@/documentation/components/commons/Markdown.vue";

import contents from "@/commons/contents/login";

export default {
  components: {
    GlobalModalBody,
    MButton,
    Markdown,
  },
  emits: ["onClose"],
  data() {
    return {
      contents,
    };
  },
};
</script>

<style lang="scss">
.partner-login-help-modal__title {
  margin-bottom: 1rem;
}
</style>
