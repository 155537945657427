import { AppsUrlQuery } from "@/dashboard/routes";
import {
  ApiCatalogListUrlQuery,
  ProductListUrlQuery,
} from "@/documentation/routes";
import { ApiListUrlQuery } from "@/manager/routes";

import { Tag } from "@/commons/domain/models/tag";
import { ApisFetchParams } from "@/commons/domain/repositories/api-repository";

import {
  EAccessPolicy,
  EApiStatusFilter,
  EApiType,
  EContractStatusFilter,
  EPrivacyName,
  EPublicationType,
} from "@/commons/store/types";

export const PRIVACIES = Object.values(EPrivacyName);
export const ACCESS_TYPES = Object.values(EAccessPolicy).sort();
export const API_TYPES = Object.values(EApiType).sort();
export const PUBLICATIONS = Object.values(EPublicationType).sort();
export const SUBSCRIPTION_STATUSES = Object.values(EContractStatusFilter);
export const APIS_STATUSES = Object.values(EApiStatusFilter);

// These filters need explicit prefix for the keys to be distinguishable because of having the same values
export const PRIVACIES_FILTER_ID_PREFIX = "privacy";
export const ACCESS_TYPES_FILTER_ID_PREFIX = "access-type";

// Modal prefixes should be different from MTagList id prefixes
export const MODAL_PREFIX = "modal";
export const MODAL_ACCESS_TYPES_FILTER_ID_PREFIX = `${MODAL_PREFIX}-${ACCESS_TYPES_FILTER_ID_PREFIX}`;
export const MODAL_PRIVACIES_FILTER_ID_PREFIX = `${MODAL_PREFIX}-${PRIVACIES_FILTER_ID_PREFIX}`;
export const SIZE_PER_PAGE_CATALOG = 12;

// NONE value for publication zone MSelect
export const NONE_PUBLICATION_ZONE = {
  name: "None",
  id: "NONE",
};

export function mergeSearchAndSelectedValues(
  searchedItems: { name: string; id: string }[],
  selectedItems: { name: string; id: string }[],
) {
  const searchedWithoutSelectedItems = searchedItems.filter((searchedItem) => {
    return selectedItems.every(
      (selectedItem) => selectedItem.id !== searchedItem.id,
    );
  });

  return [...selectedItems, ...searchedWithoutSelectedItems];
}

export function queryHasChanged(
  newUrlQuery: any,
  previousUrlQuery: any,
  param: string,
): Boolean {
  let result = false;

  if (Array.isArray(newUrlQuery[param])) {
    result =
      JSON.stringify(newUrlQuery[param].sort()) !==
      JSON.stringify(previousUrlQuery[param].sort());
  } else if (
    typeof newUrlQuery[param] === "string" ||
    typeof newUrlQuery[param] === "boolean"
  ) {
    result = newUrlQuery[param] !== previousUrlQuery[param];
  }

  return result;
}

export function getOrderedBuTagsList(tagList: Tag[]): Tag[] {
  const allBuTag = tagList.find((tag: Tag) => tag.label === "ALL");
  if (allBuTag != undefined) {
    const tagListWithoutAllBuTag = tagList.filter(
      (tag: Tag) => tag.label !== "ALL",
    );
    return [allBuTag, ...tagListWithoutAllBuTag];
  }
  return tagList;
}

export function buildApiCatalogFetchParams({
  term,
  productsIds,
  accessTypes,
  privacies,
  publicationZoneId,
  platformsIds,
  businessUnitsIds,
  page,
  types,
  onlySubscribed,
}: ApiCatalogListUrlQuery) {
  const apisFetchParams: ApisFetchParams = {
    page,
    size: SIZE_PER_PAGE_CATALOG,
    products: productsIds,
    name: term,
    accessTypes,
    privacies,
    includeDeployedZoneName: true,
    zones: [publicationZoneId],
    tags: businessUnitsIds,
    platformTags: platformsIds,
    published: true,
    types: types,
    onlySubscribed,
  };

  return apisFetchParams;
}

export function buildApiManagerFetchParams({
  showAllApis,
  term,
  productsIds,
  accessTypes,
  privacies,
  publicationStatuses,
  publicationZoneId,
  platformsIds,
  businessUnitsIds,
  page,
  types,
}: ApiListUrlQuery) {
  const apisFetchParams: ApisFetchParams = {
    page,
    size: 12,
    includeAll: showAllApis,
    products: productsIds,
    name: term,
    actions: "WRITE",
    accessTypes,
    privacies,
    includeDeployedZoneName: true,
    zones: [publicationZoneId],
    tags: businessUnitsIds,
    platformTags: platformsIds,
    types: types,
  };

  if (publicationStatuses.length === 1) {
    apisFetchParams.published = publicationStatuses.includes(
      EPublicationType.PUBLISHED,
    );
  }

  return apisFetchParams;
}

export function buildProductCatalogFetchParams({
  term,
  platformsIds,
  page,
}: ProductListUrlQuery) {
  return {
    page,
    size: SIZE_PER_PAGE_CATALOG,
    name: term,
    platformTags: platformsIds,
  };
}

export function removeIdFromUrlQuery(
  filterId: string,
  urlQuery: ApiCatalogListUrlQuery,
): ApiCatalogListUrlQuery {
  const updatedUrlQuery = structuredClone(urlQuery);

  if (filterId?.startsWith(PRIVACIES_FILTER_ID_PREFIX)) {
    removeValueFromArray(
      updatedUrlQuery.privacies,
      removePrefixFromId(filterId, PRIVACIES_FILTER_ID_PREFIX),
    );
  } else if (filterId?.startsWith(ACCESS_TYPES_FILTER_ID_PREFIX)) {
    removeValueFromArray(
      updatedUrlQuery.accessTypes,
      removePrefixFromId(filterId, ACCESS_TYPES_FILTER_ID_PREFIX),
    );
  } else {
    const matchingParamName = Object.keys(urlQuery).find((paramName) => {
      const value = urlQuery[paramName];
      return value instanceof Array
        ? value.includes(filterId)
        : value === filterId;
    });

    if (matchingParamName != undefined) {
      if (updatedUrlQuery[matchingParamName] instanceof Array) {
        removeValueFromArray(updatedUrlQuery[matchingParamName], filterId);
      } else {
        updatedUrlQuery[matchingParamName] = "";
      }
    }
  }

  return updatedUrlQuery;
}

function removeValueFromArray(array: string[], value: string) {
  array.splice(array.indexOf(value), 1);
}

function removePrefixFromId(id: string, prefix: string) {
  return id.replace(`${prefix}-`, "");
}

export function buildMyAppsFetchParams({
  showAllApps,
  term,
  productsIds,
  usedApisIds,
  subscriptionStatuses,
  apisStatuses,
  page,
}: AppsUrlQuery) {
  return {
    page,
    size: SIZE_PER_PAGE_CATALOG,
    includeAll: showAllApps,
    products: productsIds,
    name: term,
    apis: usedApisIds,
    contractsAboutToExpire: subscriptionStatuses.includes(
      EContractStatusFilter.ABOUT_TO_EXPIRE,
    ),
    contractsDisabled: subscriptionStatuses.includes(
      EContractStatusFilter.DISABLED,
    ),
    contractsExpired: subscriptionStatuses.includes(
      EContractStatusFilter.EXPIRED,
    ),
    contractsDeprecated: apisStatuses.includes(EApiStatusFilter.DEPRECATED),
  };
}
