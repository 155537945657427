import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "oauth-flow-settings__card-title-with-help-button" }
const _hoisted_2 = { class: "oauth-flow-settings__card-title-with-icon" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "oauth-flow-settings__card-title" }
const _hoisted_5 = { class: "oauth-flow-settings__requirement" }
const _hoisted_6 = { class: "oauth-flow-settings__flow-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ViewHelpButton = _resolveComponent("ViewHelpButton")!
  const _component_OauthFlowOptionCard = _resolveComponent("OauthFlowOptionCard")!
  const _component_CardContainer = _resolveComponent("CardContainer")!

  return (_openBlock(), _createBlock(_component_CardContainer, { class: "oauth-flow-settings" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            class: "oauth-flow-settings__card-title-icon",
            src: require('@/commons/assets/icons/ping-logo.png')
          }, null, 8, _hoisted_3),
          _createElementVNode("h4", _hoisted_4, _toDisplayString($data.contents.flowSettingsTitle), 1)
        ]),
        _createVNode(_component_ViewHelpButton, {
          class: "oauth-flow-settings__flow-settings-help-button",
          documentationAnchor: "#configure-supported-flows"
        })
      ]),
      _createElementVNode("p", _hoisted_5, _toDisplayString($data.contents.atLeastOneFlowSelected), 1),
      _createElementVNode("div", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.oauthFlowItems, (flowItem, index) => {
          return (_openBlock(), _createBlock(_component_OauthFlowOptionCard, {
            key: index,
            "data-cy": 'oauth-flow-' + flowItem.value,
            flowItem: flowItem,
            modelValue: $options.flowIsSelected(flowItem.value),
            isDisabled: 
          $options.flowIsSelected(flowItem.value) && $options.selectedFlows.length === 1
        ,
            "onUpdate:modelValue": ($event: any) => ($options.onChangeFlowSelection(flowItem.value, $event))
          }, null, 8, ["data-cy", "flowItem", "modelValue", "isDisabled", "onUpdate:modelValue"]))
        }), 128))
      ])
    ]),
    _: 1
  }))
}