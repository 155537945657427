<template>
  <div class="with-fixed-header fixed-header-layout">
    <slot />
  </div>
</template>

<script lang="ts">
export default {
  name: "WithFixedHeader",
};
</script>

<style lang="scss">
.fixed-header-layout > * {
  position: relative;
  z-index: var(--z-index-middleground);
}

.fixed-header-layout > :first-child {
  position: sticky;
  top: 0;
  z-index: var(--z-index-foreground);
  width: 100%;
}
</style>
