<script setup lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import MNotification from "@mozaic-ds/vue-3/src/components/notification/MNotification.vue";

import contents from "@/manager/contents/reference-new-scope";

const emit = defineEmits(["referenceNewScope"]);
</script>

<template>
  <MNotification :title="contents.title" type="information">
    <p>
      {{ contents.description }}
      <br />
      {{ contents.preferenceMessage }}
    </p>

    <template #footer>
      <MButton
        :label="contents.referenceScopeBtnLabel"
        icon="ControlCircleMore24"
        theme="bordered-neutral"
        size="m"
        @click="emit('referenceNewScope')"
      />
    </template>
  </MNotification>
</template>
