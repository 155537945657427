import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "contract-delete-token-modal__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotificationForKongSynchronization = _resolveComponent("NotificationForKongSynchronization")!
  const _component_Markdown = _resolveComponent("Markdown")!
  const _component_MTextInput = _resolveComponent("MTextInput")!
  const _component_DvpField = _resolveComponent("DvpField")!
  const _component_LayerModalFormConfirm = _resolveComponent("LayerModalFormConfirm")!

  return (_openBlock(), _createBlock(_component_LayerModalFormConfirm, {
    class: "contract-delete-token-modal",
    disabledConfirmBtn: $data.form.errors.length > 0,
    onSubmit: $options.submit,
    onClose: $options.close
  }, {
    default: _withCtx(() => [
      ($props.contract && $props.token)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("h4", null, _toDisplayString($data.contents.informationTitle), 1),
            _createElementVNode("ul", null, [
              _createElementVNode("li", null, _toDisplayString($data.contents.applicationName($props.contract?.application?.name)), 1),
              _createElementVNode("li", null, _toDisplayString($data.contents.apiName($options.getApiLabelWithVersion($props.contract?.api))), 1),
              _createElementVNode("li", null, _toDisplayString($options.expirationInformation), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      ($options.displayKongInformation)
        ? (_openBlock(), _createBlock(_component_NotificationForKongSynchronization, { key: 1 }))
        : _createCommentVNode("", true),
      _createElementVNode("h4", _hoisted_2, _toDisplayString($data.contents.deletionTitle), 1),
      _createVNode(_component_Markdown, {
        content: $data.contents.subtitle
      }, null, 8, ["content"]),
      _createVNode(_component_DvpField, {
        errorMessage: $data.form.firstError('text'),
        required: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_MTextInput, {
            modelValue: $data.form.text,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.form.text) = $event)),
            placeholder: $data.contents.delete,
            isInvalid: $data.form.hasError('text')
          }, null, 8, ["modelValue", "placeholder", "isInvalid"])
        ]),
        _: 1
      }, 8, ["errorMessage"])
    ]),
    _: 1
  }, 8, ["disabledConfirmBtn", "onSubmit", "onClose"]))
}