export function pluralize(
  count: number,
  noun: string,
  options: { suffix?: string; shouldExcludeCount?: boolean } = {},
) {
  const { suffix = "s", shouldExcludeCount = false } = options;

  const label = count <= 1 ? noun : `${noun}${suffix}`;
  return shouldExcludeCount ? label : `${count} ${label}`;
}

export function cropText(text: string, length: number): string {
  return text.length > length ? `${text.substring(0, length)}...` : text;
}
