import { DropdownMenuItem } from "@/commons/store/types";

export function getSupportMenuConf(supportUrl: string): DropdownMenuItem {
  return {
    label: "Support",
    items: [
      {
        label: "Support",
        description: "Need help ? Have a question ? Feel free to ask us",
        urlOrRoute: supportUrl,
        isExternalLink: true,
      },
    ],
  };
}
