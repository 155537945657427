import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cropped-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonCopy = _resolveComponent("ButtonCopy")!
  const _component_DvpTooltip = _resolveComponent("DvpTooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ButtonCopy, { content: $props.text }, null, 8, ["content"]),
    _createVNode(_component_DvpTooltip, {
      text: $props.text,
      position: "bottom"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString($options.croppedText), 1)
      ]),
      _: 1
    }, 8, ["text"])
  ]))
}