export default {
  headerConfirmRemove:
    "Deleting Gateway from this route will impact publication",
  label: "Choose a gateway close to your API deployment",
  placeholder: "-- Select a Zone --",
  buttonAddLabel: "Add",
  buttonConfirmRemoveLabel: "Confirm",
  buttonCancelRemoveLabel: "Cancel",
  published: "Published",
  toPublish: "To publish",
  noGatewaysAvailable:
    "Currently, you aren’t allowed to add a new routing because all available gateways are used.",
  removeNotAllowed: "The route should be unpublished before removing.",
  publishingLinkLabel: "Publication area",
  accessToAnotherGateway: "I need access to another gateway",
  pendingOtherGatewayRequests: "Pending request",
  viewRequest: "View request",
  listOfNonDeprecatedGateways: "The list of all non deprecated gateways",
  availableOnlyForAdmins: "Available only for administrators",
  adminZonesNotificationTitle:
    "To apply changes, leave the new zone checkbox's state checked before saving",
};
