<template>
  <div class="menu-icon">
    <div class="menu-icon__wrapper">
      <MIcon :name="mIconName" />
      <div v-if="withNotifications" class="menu-icon__notifications" />
    </div>
  </div>
</template>

<script lang="ts">
import MIcon from "@mozaic-ds/vue-3/src/components/icon/MIcon.vue";

export default {
  components: {
    MIcon,
  },
  props: {
    mIconName: {
      type: String,
      default: undefined,
    },
    withNotifications: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.menu-icon {
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 32px;

  padding: 16px;

  &:hover {
    background-color: var(--color-m-secondary-light);
    border-radius: 50%;
  }
}

.menu-icon__wrapper {
  position: relative;
}

.menu-icon__notifications {
  position: absolute;
  top: 4px;
  right: 3px;
  width: 12px;
  height: 12px;
  background-color: var(--color-danger);
  border: 2px solid var(--color-white);
  border-radius: 50%;
}
</style>
