import { ValidationError as ClassValidationError } from "class-validator";

import { Injectable } from "@/commons/domain/di/injectable";
import { ValidationError } from "@/commons/domain/validation/validation-error";
import { ValidationErrorMapper } from "@/commons/domain/validation/validation-error-mapper";

@Injectable()
export class ClassValidationErrorMapper implements ValidationErrorMapper {
  toValidationError(rawError: ClassValidationError): ValidationError {
    const { property } = rawError;
    const messages: string[] = [];

    for (const constraint in rawError.constraints) {
      messages.push(rawError.constraints[constraint]);
    }

    return {
      property,
      messages,
    };
  }
}
