import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "menu-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuLink = _resolveComponent("MenuLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.urlOrRoute)
      ? (_openBlock(), _createBlock(_component_MenuLink, {
          key: 0,
          class: "menu-label__link",
          urlOrRoute: $props.urlOrRoute,
          isExternalLink: $props.isExternalLink
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default")
          ]),
          _: 3
        }, 8, ["urlOrRoute", "isExternalLink"]))
      : _renderSlot(_ctx.$slots, "default", { key: 1 })
  ]))
}