<template>
  <div :class="['form-input', { 'form-input--has-error': !!error }]">
    <Label
      v-if="label"
      class="form-input__label"
      :isRequired="isRequired"
      :tooltip="tooltip"
      >{{ label }}</Label
    >
    <slot name="label" />
    <slot />
    <small v-if="error" class="form-input__error">{{ error }}</small>
  </div>
</template>

<script lang="ts">
import Label from "@/commons/components/Label.vue";

export default {
  name: "FormInput",
  // eslint-disable-next-line vue/no-reserved-component-names
  components: { Label },
  props: {
    label: {
      type: String,
      default: undefined,
    },
    tooltip: {
      type: String,
      default: undefined,
    },
    error: {
      type: String,
      default: undefined,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.form-input {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0;

  .form-input__error {
    min-height: 1.5em;
    color: var(--color-danger);

    &::first-letter {
      text-transform: capitalize;
    }
  }
}

.form-input.form-input--has-error > label {
  color: var(--color-danger);
}
.form-input__label {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
</style>
