<script setup lang="ts">
import MIcon from "@mozaic-ds/vue-3/src/components/icon/MIcon.vue";
import MRadioGroup from "@mozaic-ds/vue-3/src/components/radio/MRadioGroup.vue";
import { computed } from "vue";

import DvpTooltip from "@/commons/components/DvpTooltip.vue";

import { valuesUpperCaseToMRadioButtonItems } from "@/commons/utils/mozaic-utils";
import { ROLES_APPLICABLE_TO_GROUP } from "@/commons/utils/role.utils";

import contents from "@/dashboard/contents/access-settings";

const applicableRolesRadioGroup = computed(() => {
  return valuesUpperCaseToMRadioButtonItems(ROLES_APPLICABLE_TO_GROUP);
});
</script>

<template>
  <MRadioGroup
    v-bind="$attrs"
    :legend="contents.defineRightsSubTitle"
    :options="applicableRolesRadioGroup"
    inline
  >
    <template #label1="{ option }">
      <div class="access-settings__role-item">
        <span> {{ option.label }} </span>
        <DvpTooltip :text="contents.tooltip[option.value]">
          <MIcon
            name="NotificationInformation24"
            color="var(--color-m-secondary-blue-grey)"
          />
        </DvpTooltip>
      </div>
    </template>
    <template #label2="{ option }">
      <div class="access-settings__role-item">
        <span> {{ option.label }} </span>
        <DvpTooltip :text="contents.tooltip[option.value]">
          <MIcon
            name="NotificationInformation24"
            color="var(--color-m-secondary-blue-grey)"
          />
        </DvpTooltip>
      </div>
    </template>
  </MRadioGroup>
</template>

<style lang="scss">
.access-settings__role-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
</style>
