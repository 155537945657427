import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "subscription-button__pending-request-info"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MBadge = _resolveComponent("MBadge")!
  const _component_MIcon = _resolveComponent("MIcon")!
  const _component_DvpTooltip = _resolveComponent("DvpTooltip")!
  const _component_MButton = _resolveComponent("MButton")!

  return ($props.api.isAlreadySubscribed)
    ? (_openBlock(), _createBlock(_component_MBadge, {
        key: 0,
        type: "success"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($data.contents.subscribed), 1)
        ]),
        _: 1
      }))
    : ($props.api.hasPendingRequestForAuthTypeChange)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_MBadge, { type: "warning" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($data.contents.pendingRequest), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_DvpTooltip, {
            text: $data.contents.pendingRequestTooltip,
            position: "left"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_MIcon, { name: "NotificationInformation24" })
            ]),
            _: 1
          }, 8, ["text"])
        ]))
      : (_openBlock(), _createBlock(_component_MButton, {
          key: 2,
          class: "subscription-button",
          size: "s",
          label: $data.contents.subscriptionButton,
          onClick: $options.onSubscriptionClick
        }, null, 8, ["label", "onClick"]))
}