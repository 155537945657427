import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MLink = _resolveComponent("MLink")!

  return (_openBlock(), _createBlock(_component_MLink, {
    class: "documentation-link",
    size: "s",
    icon: "Read24",
    href: $options.routeHref,
    target: "_blank"
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString($props.label), 1)
    ]),
    _: 1
  }, 8, ["href"]))
}