interface PollingPromiseHandler<T> {
  resolve: (value: T | PromiseLike<T>) => void;
  reject: (reason?: any) => void;
}

export class PollingCancelError extends Error {
  constructor() {
    super("Polling has been cancelled");
  }
}

class PollingManager {
  private activePollingIds: Record<string, string> = {};
  private pollingPromiseHandlers: Record<string, PollingPromiseHandler<any>> =
    {};

  private static nextIntervalCoef = 2;

  constructor() {}

  async startPolling<T>(
    pollingName: string,
    action: () => Promise<T>,
    resolveCondition: (response?: T) => boolean,
    initialInterval: number = 200,
    timeout: number = 240000,
  ): Promise<T> {
    const endTime = Date.now() + timeout;
    let nextInterval = initialInterval;
    const pollingId = String(Date.now());

    const pollingProcess = async () => {
      if (this.activePollingIds[pollingName] !== pollingId) {
        this.rejectPollingPromise(pollingId, new PollingCancelError());
      } else {
        const response = await action();

        if (resolveCondition(response)) {
          this.resolvePollingPromise(pollingId, response);
        } else if (Date.now() < endTime) {
          nextInterval *= PollingManager.nextIntervalCoef;
          setTimeout(pollingProcess, nextInterval);
        } else {
          this.rejectPollingPromise(pollingId, new Error("Polling timeout"));
        }
      }
    };

    return new Promise((resolve, reject): void => {
      this.pollingPromiseHandlers[pollingId] = {
        resolve: resolve,
        reject: reject,
      };
      this.activePollingIds[pollingName] = pollingId;

      setTimeout(pollingProcess, nextInterval);
    });
  }

  private resolvePollingPromise(pollingId: string, response: any): void {
    this.pollingPromiseHandlers[pollingId].resolve(response);
    delete this.pollingPromiseHandlers[pollingId];
  }

  private rejectPollingPromise(pollingId: string, response: any): void {
    this.pollingPromiseHandlers[pollingId].reject(response);
    delete this.pollingPromiseHandlers[pollingId];
  }

  stopCurrentPollings(): void {
    this.activePollingIds = {};
  }
}

export default new PollingManager();
