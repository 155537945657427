import { DropdownMenuItem, MenuItem } from "@/commons/store/types";

export function getApiCatalogMenuConf(): DropdownMenuItem {
  const items: MenuItem[] = [
    {
      label: "By API",
      description: "Find API and their documentation",
      urlOrRoute: {
        name: "apiDocumentation",
      },
      iconName: "API32",
    },
    {
      label: "By Product",
      description: "Find API by product and their documentation",
      urlOrRoute: {
        name: "productDocumentation",
      },
      iconName: "Tools3d32",
    },
  ];

  return { label: "API Catalog", items, realm: "docs" };
}
