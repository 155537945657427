import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MToggle = _resolveComponent("MToggle")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { class: "user-cookies" }, {
    default: _withCtx(() => [
      _createElementVNode("h4", null, _toDisplayString($data.contents.cookies), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.configurableCookieCategories, (cat, index) => {
        return (_openBlock(), _createBlock(_component_MToggle, {
          id: `${cat.type}-${index}`,
          key: `${cat.type}-${index}`,
          class: "margin-bottom-400",
          label: cat.acceptButtonLabel,
          modelValue: $data.acceptanceByCategoryType[cat.type],
          "onUpdate:modelValue": ($event: any) => ($options.onChange(cat.type, $event))
        }, null, 8, ["id", "label", "modelValue", "onUpdate:modelValue"]))
      }), 128))
    ]),
    _: 1
  }))
}