<template>
  <a
    :href="slackUrl"
    target="_blank"
    class="request-manager-button-view-slack-messages__button"
  >
    <img
      :src="iconSlack"
      class="request-manager-button-view-slack-messages__icon"
    />
    <span class="request-manager-button-view-slack-messages__label">{{
      contents.viewMessages
    }}</span>
  </a>
</template>
<script lang="ts">
import contents from "@/request-manager/contents/request-manager-button-view-slack-messages";

export default {
  props: {
    slackUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      contents,
      iconSlack: require("@/commons/assets/icons/icon-slack.svg"),
    };
  },
};
</script>
<style lang="scss">
.request-manager-button-view-slack-messages {
  text-decoration: none;
}
.request-manager-button-view-slack-messages__button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 10rem;
  height: 2rem;

  text-decoration: none;
  cursor: pointer;
  background-color: var(--color-white);
  border: 2px solid var(--color-brand-primary);
  border-radius: var(--m-border-radius-medium);
}

.request-manager-button-view-slack-messages__icon {
  width: 1rem;
}

.request-manager-button-view-slack-messages__label {
  font-size: 0.875rem;
  font-weight: 700;
  color: var(--color-brand-primary);
  text-decoration: none;
}
</style>
