export default {
  pendingRequest: "PENDING REQUEST",
  privacies: {
    PRIVATE: {
      label: "Private",
      description: "API managers and authorized groups can access your API",
    },
    INTERNAL: {
      label: "Internal",
      description: "All ADEO Internal users have access",
    },
    PARTNER: {
      label: "Partners",
      description:
        "In addition to the ADEO internal users, authorized partners have also access",
    },
    PUBLIC: {
      label: "Public",
      description: "All users (Internal or Partner) have access",
    },
  },
  apiShouldBeAccessibleOnInternet:
    "Your API needs to be published on a gateway accessible on the internet. Go to publication section",
};
