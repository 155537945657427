// organize-imports-ignore
import "reflect-metadata";
import SlidesScreenPlugin from "@/commons/plugins/slides-screen/slides-screen-plugin";
import { createApp } from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import App from "./App.vue";
import store from "./commons/store";
import { Container } from "./container";
import router from "./router";
import { pluralize } from "@/commons/utils/contents-utils";
import { addErrorListeners } from "@/error/error-service";
import { getRequestHandler } from "@/commons/request-handlers/request-handler-axios";
import { setupHotjar } from "@/commons/utils/hotjar.utils";
import contents from "@/commons/contents/modal";

async function loadFrontendConfig() {
  const configFile = process.env.VUE_APP_CONFIG_FILE || "config.json";

  // We use fetch so we don't depend on the Axios requestHandler. Otherwise it would force us to create that handler beforehand.
  // + the requestHandler would have to handle a call without baseUrl configured, making its configuration steps a bit unclear
  const configResponse = await fetch(
    `/${configFile}?timestamp=${new Date().getTime()}`,
  ); // Using unique name because we do not want browsers to cache config
  const frontendConfiguration = await configResponse.json();

  store.commit("config/setFrontendConfig", frontendConfiguration);
}

function startDependencyInjection() {
  Container.instance();
}

function finishRequestHandlerConfiguration() {
  getRequestHandler().setBaseUrl(
    store.getters["config/frontendConfig"].backendUrl,
  );
}

function createAndStartApplication() {
  const app = createApp(App);

  addErrorListeners(app);

  app.use(router);
  app.use(store);

  app.use(SlidesScreenPlugin);

  app.use(VueSweetalert2, {
    showConfirmButton: true,
    showCancelButton: true,
    showCloseButton: true,
    reverseButtons: true,
    focusCancel: true,
    buttonsStyling: false,
    confirmButtonText: contents.confirmBtn,
    cancelButtonText: contents.cancelBtn,
    showClass: {
      backdrop: "swal2-noanimation",
      popup: "",
      icon: "",
    },
    hideClass: {
      popup: "",
    },
  });

  app.mixin({
    methods: {
      pluralize,
    },
  });

  setupHotjar(app, store.getters["config/frontendConfig"]);

  app.mount("#app");
}

loadFrontendConfig()
  .then(startDependencyInjection)
  .then(finishRequestHandlerConfiguration) // RequestHandler has been initialized during the dependency injection process
  .then(createAndStartApplication);
