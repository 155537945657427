import {
  IsInt,
  MaxLength,
  Min,
  MinLength,
  Validate,
  ValidationArguments,
  ValidatorConstraint,
  ValidatorConstraintInterface,
} from "class-validator";

import { FormData } from "@/commons/domain/forms/form-data";
import { FormFactory } from "@/commons/domain/forms/form-factory";
import { MAX_API_TIMEOUT_DEFINED_BY_USER } from "@/manager/domain/forms/timeout";

@ValidatorConstraint({
  name: "MaxTimeout",
  async: false,
})
export class MaxTimeout implements ValidatorConstraintInterface {
  validate(value: number, args: ValidationArguments) {
    return (args.object as RequestTimeoutForm).maxTimeout >= value;
  }
  defaultMessage(args: ValidationArguments) {
    return `The maximum value is ${
      (args.object as RequestTimeoutForm).maxTimeout
    } seconds`;
  }
}

export class RequestTimeoutForm extends FormData {
  maxTimeout: number;
  constructor(maxTimeout: number) {
    super();
    this.maxTimeout = maxTimeout;
  }

  @IsInt({ message: "This value should be an integer" })
  @Min(MAX_API_TIMEOUT_DEFINED_BY_USER + 1, {
    message: `The minimum value for a timeout request is ${
      MAX_API_TIMEOUT_DEFINED_BY_USER + 1
    } seconds`,
  })
  @Validate(MaxTimeout)
  timeoutInSeconds: number;

  @MinLength(50, {
    message:
      "This field is too short. Minimal length is $constraint1 characters",
  })
  @MaxLength(500, {
    message:
      "This field is too long. Maximal length is $constraint1 characters",
  })
  message: string;

  $clear() {
    this.timeoutInSeconds = 1;
    this.message = "";
  }

  static create(maxTimeout: number) {
    return FormFactory.createForm(new RequestTimeoutForm(maxTimeout));
  }
}
