<template>
  <MTooltip
    :id="tooltipId"
    class="icon-tooltip"
    :position="position"
    :label="text"
  >
    <MIcon :name="iconName" :color="iconColor" />
  </MTooltip>
</template>

<script lang="ts">
import MIcon from "@mozaic-ds/vue-3/src/components/icon/MIcon.vue";
import MTooltip from "@mozaic-ds/vue-3/src/components/tooltip/MTooltip.vue";

export default {
  components: {
    MTooltip,
    MIcon,
  },
  props: {
    iconName: {
      type: String,
      default: "NotificationInformation24",
    },
    text: {
      type: String,
      required: true,
    },
    iconColor: {
      type: String,
      default: undefined,
    },
    position: {
      type: String,
      default: "top",
    },
  },
  computed: {
    tooltipId(): string {
      return `icon-tooltip-${this.$.uid}`;
    },
  },
};
</script>
