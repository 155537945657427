import { convertRouteToHref } from "@/commons/utils/route-utils";

export interface BreadcrumbItem {
  text: string;
  href: string;
}

export function getHomeItem(): BreadcrumbItem {
  return {
    text: "Home",
    href: convertRouteToHref({
      name: "home",
    }),
  };
}

export function getLoginItem(): BreadcrumbItem {
  return {
    text: "Login",
    href: convertRouteToHref({ name: "login" }),
  };
}

export function getApiCatalogItem(): BreadcrumbItem {
  return {
    text: "API catalog",
    href: convertRouteToHref({
      name: "apiDocumentation",
    }),
  };
}

export function getApiDocumentationItem(
  apiName: string,
  apiId: string,
): BreadcrumbItem {
  return {
    text: `Documentation of ${apiName}`,
    href: convertRouteToHref({
      name: "apiDocumentationDetail",
      params: {
        id: apiId,
      },
    }),
  };
}
