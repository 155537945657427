<script setup lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import { computed, PropType, ref } from "vue";

import DvpOptionCard from "@/commons/components/DvpOptionCard.vue";

import { cropText } from "@/commons/utils/contents-utils";

import contents from "@/dashboard/contents/scope-selection-card";

interface Scope {
  name: string;
  description: string;
}

const props = defineProps({
  scope: {
    type: Object as PropType<Scope>,
    required: true,
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["onSelect"]);

const minLength = 175;

const selectScope = (): void => {
  emit("onSelect");
};

const displaySeeMoreBtn = computed(() => {
  return props.scope.description && props.scope.description.length > minLength;
});

const seeMoreDescriptionToggleEnabled = ref(false);
</script>

<template>
  <DvpOptionCard
    :id="scope.name"
    class="scope-selection-card"
    :label="`scope-selection-card-${scope.name}`"
    :modelValue="modelValue"
    type="checkbox"
    @update:model-value="selectScope()"
  >
    <template #default>
      <div class="scope-selection-card__container">
        <span class="scope-selection-card__scope-name">{{ scope.name }}</span>
        <p
          v-if="scope.description && displaySeeMoreBtn"
          class="scope-selection-card__scope-description"
        >
          {{
            seeMoreDescriptionToggleEnabled
              ? scope.description
              : cropText(scope.description, minLength)
          }}
        </p>
        <div>
          <MButton
            v-if="displaySeeMoreBtn"
            size="s"
            theme="bordered-neutral"
            :label="
              seeMoreDescriptionToggleEnabled
                ? contents.seeLessBtnLabel
                : contents.seeMoreBtnLabel
            "
            @click="
              seeMoreDescriptionToggleEnabled = !seeMoreDescriptionToggleEnabled
            "
          />
        </div>
      </div>
    </template>
  </DvpOptionCard>
</template>

<style lang="scss">
.scope-selection-card__container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.scope-selection-card__scope-name {
  @include set-text-m;
  font-weight: 700;
}

.scope-selection-card__scope-description {
  @include set-text-s;
}
</style>
