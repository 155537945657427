<template>
  <svg
    version="1.1"
    class="icon-routing"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="icon-routing"
    viewBox="0 -3 30 30"
  >
    <g>
      <path
        d="M19.8613739,16.1340065c-1.3133545,0-2.4415283,0.7896729-2.9475098,1.916748
		c-1.0776978-0.5129395-1.829834-1.6038818-1.829834-2.8747549v-0.4365234c0-1.9719238-1.1183472-3.670166-2.7421265-4.5487061
		c0.5066528-0.6243896,0.8231812-1.4088135,0.8231812-2.27356c0-0.8994141,0.7324219-1.6318359,1.6318359-1.6318359h2.0045776
		c0.4241333,1.2922363,1.6278076,2.2343755,3.0598755,2.2343755c1.7832031,0,3.2333984-1.4511724,3.2333984-3.2343755
		s-1.4501953-3.234375-3.2333984-3.234375c-1.4320679,0-2.6358032,0.9421387-3.0599365,2.234375h-2.0045166
		c-2.0029297,0-3.6318359,1.6289062-3.6318359,3.6318359c0,0.8994145-0.7314453,1.6308599-1.6308594,1.6308599H7.7041473V7.314671
		h-6.46875v6.4677739h6.46875V11.54807h1.8300781c0.1227417,0,0.2387695-0.024292,0.3583984-0.0362549V11.54807
		c1.7597656,0,3.1914062,1.4316406,3.1914062,3.1914062v0.4365234c0,2.3166494,1.5358887,4.2607412,3.6350708,4.9266348
		c0.3348999,1.428833,1.612915,2.4991455,3.1422729,2.4991455c1.7832031,0,3.2333984-1.4511719,3.2333984-3.234375
		S21.644577,16.1340065,19.8613739,16.1340065z M19.8613739,4.0509992c0.6796875,0,1.2333984,0.5537109,1.2333984,1.234375
		s-0.5537109,1.234375-1.2333984,1.234375c-0.6806641,0-1.234375-0.5537109-1.234375-1.234375
		S19.1807098,4.0509992,19.8613739,4.0509992z M5.7041473,11.782445h-2.46875V9.3146715h2.46875V11.782445z M19.8613739,20.6017799
		c-0.6806641,0-1.234375-0.5537109-1.234375-1.234375c0-0.6796875,0.5537109-1.2333984,1.234375-1.2333984
		c0.6796875,0,1.2333984,0.5537109,1.2333984,1.2333984C21.0947723,20.048069,20.5410614,20.6017799,19.8613739,20.6017799z"
      />
    </g>
  </svg>
</template>
