<template>
  <MOptionCard
    :id="optionCardId"
    class="authentication-mode-option-card"
    :label="title"
    :modelValue="true"
    noPadding
  >
    <div class="authentication-mode-option-card__container">
      <h5 class="authentication-mode-option-card__title">
        {{ title }}
      </h5>
      <div class="authentication-mode-option-card__description">
        <p class="authentication-mode-option-card__description-text">
          {{ description }}
        </p>
        <ViewHelpButton
          class="authentication-mode-option-card__view-help-button"
          :documentationAnchor="documentationAnchor"
        />
      </div>
    </div>
  </MOptionCard>
</template>

<script lang="ts">
import MOptionCard from "@mozaic-ds/vue-3/src/components/optioncard/MOptionCard.vue";

import ViewHelpButton from "@/commons/components/UserDocumentationLinks/ViewHelpButton.vue";

export default {
  components: {
    ViewHelpButton,
    MOptionCard,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    documentationAnchor: {
      type: String,
      required: true,
    },
  },
  computed: {
    optionCardId(): string {
      return `authentication-mode-option-card_${this.$.uid}`;
    },
  },
};
</script>

<style lang="scss">
.authentication-mode-option-card__container {
  padding: 1.5rem;
}

.authentication-mode-option-card__title {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.authentication-mode-option-card__view-help-button {
  margin-top: 0.75rem;
}

.authentication-mode-option-card__description-text {
  @include set-text-s;

  margin: 0;
}
</style>
