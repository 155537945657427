export enum EModalType {
  MODAL = "MODAL",
  LAYER_MODAL = "LAYER_MODAL",
}

export class ModalClosedError extends Error {
  constructor() {
    super("Modal has been closed");
  }
}
