<template>
  <div class="app-list">
    <template v-if="isLoading">
      <!-- eslint-disable -->
      <div class="app-list__item" v-for="item in new Array(5)">
        <AppListItemLoadingSkeleton />
      </div>
      <!-- eslint-disable -->
    </template>
    <template v-else>
      <AppListEmptyItem v-if="applications.length === 0" />
      <RouterLink
        v-else
        class="app-list__item"
        :key="index"
        v-for="(application, index) in applications"
        :to="getAppLink(application.id)"
      >
        <AppListItem :application="application" />
      </RouterLink>
    </template>
  </div>
</template>

<script lang="ts">
import AppListEmptyItem from "@/dashboard/views/AppList/AppListEmptyItem.vue";

import AppListItem from "./AppListItem.vue";
import AppListItemLoadingSkeleton from "./AppListItemLoadingSkeleton.vue";

export default {
  name: "AppList",
  components: {
    AppListItem,
    AppListEmptyItem,
    AppListItemLoadingSkeleton,
  },
  props: {
    applications: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getAppLink(appId: string) {
      return {
        name: "application",
        params: { id: appId },
      };
    },
  },
};
</script>

<style lang="scss">
.app-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(22rem, 1fr));
  grid-gap: 1.5rem;
}

.app-list__item {
  overflow: hidden;

  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s ease-in;

  &:hover {
    background-color: var(--color-background-interface);
    border-radius: var(--m-border-radius-large);
    box-shadow: var(--m-shadow-medium);
  }
}
</style>
