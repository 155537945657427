<script setup lang="ts">
import MDataTableColumn from "@mozaic-ds/vue-3/src/components/datatablev2/MDataTableColumn.vue";
import MDataTableV2 from "@mozaic-ds/vue-3/src/components/datatablev2/MDataTableV2.vue";
import { computed, onMounted, reactive } from "vue";
import { useStore } from "vuex";

import AlertModalBody from "@/commons/components/Modal/AlertModalBody.vue";

import { buildScopesProductsFetchParams } from "@/manager/utils/build-scopes-products-fetch-params";

import { Product } from "@/commons/domain/models/product";
import { Tag } from "@/commons/domain/models/tag";

import { ETagType } from "@/commons/store/types";

import contents from "@/manager/contents/scope-products-list-modal";

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  message: {
    type: String,
    required: true,
  },
  scopeName: {
    type: String,
    required: true,
  },
});

const DEFAULT_PAGE_SIZE = 10;
const store = useStore();

onMounted(async () => {
  await products.loadProductPage(1);
});

const products = reactive({
  items: computed(() => store.getters["scopesProductsList"]),
  totalCount: computed((): number => {
    return store.getters["scopesProductsTotalCount"];
  }),
  currentPage: computed((): number => {
    return store.getters["scopesProductsCurrentPage"];
  }),
  loadProductPage: async (page: number) => {
    await store.dispatch(
      "loadScopesProducts",
      buildScopesProductsFetchParams(props.scopeName, page),
    );
  },
  isLoading: computed(() => {
    return store.getters["isLoadingProperty"]("scopesProducts");
  }),
});

const getPlatformName = (product: Product): string => {
  return (
    Object.values(product.tags).find(
      (tag: Tag) => tag.type === ETagType.PLATFORM,
    )?.description || "-"
  );
};
</script>

<template>
  <AlertModalBody confirmBtnTheme="bordered-neutral">
    <div class="scope-products-list-modal__title">
      <h6 class="scope-products-list-modal__title">{{ title }}</h6>
      <p class="scope-products-list-modal__message">{{ message }}</p>
    </div>
    <MDataTableV2
      class="scope-products-list-modal__datatable"
      data-cy="scope-products-data-list"
      :items="products.items"
      pageable
      :itemsPerPage="DEFAULT_PAGE_SIZE"
      :currentPage="products.currentPage"
      :totalItems="products.totalCount"
      :itemsPerPageOptions="[DEFAULT_PAGE_SIZE]"
      :loading="products.isLoading"
      showPartialPages
      hidePageCount
      @update:current-page="products.loadProductPage"
    >
      <MDataTableColumn
        class="scope-products-list-modal__datatable-product-name-column"
        :label="contents.productsTableHeader"
        value="product"
      />
      <MDataTableColumn
        class="scope-products-list-modal__datatable-platform-column"
        :label="contents.platformTableHeader"
        value="platform"
      />
      <template #[`cell.product`]="{ item }">
        <div :data-cy="`product-name-${item.id}`">{{ item.name }}</div>
      </template>
      <template #[`cell.platform`]="{ item }">
        <div :data-cy="`product-platform-${item.id}`">
          {{ getPlatformName(item) }}
        </div>
      </template>
    </MDataTableV2>
  </AlertModalBody>
</template>

<style lang="scss">
.scope-products-list-modal__title {
  margin-bottom: var(--base-spacing);
}
</style>
