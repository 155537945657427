module.exports = {
  noValue: "No value (use default quota)",
  searchForAnApp: "Search for an application",

  tableHeaderApplicationName: "Name",
  tableHeaderRateLimit: "Quota",
  tableHeaderAction: "Action",

  noConsumerFound: "No consumer found",
};
