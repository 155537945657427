<script setup lang="ts">
import isEqual from "lodash-es/isEqual";
import { computed, onMounted, PropType, ref, watch } from "vue";
import { useStore } from "vuex";

import DisabledClientIdNotification from "@/dashboard/views/AppDetailsContracts/DisabledClientIdNotification.vue";
import OAuthCredentialsBar from "@/dashboard/views/AppDetailsContracts/OAuthCredentialsBar.vue";
import ContractsList from "@/dashboard/views/ContractsList/ContractsList.vue";

import { comparePingProviders } from "@/commons/utils/ping-utils";

import { Contract } from "@/commons/domain/models/contract";
import { OAuthToken } from "@/commons/domain/models/oauth-token";
import { checkOAuthTokensStatusesOnPingSide } from "@/commons/services/token/token-service";

const store = useStore();

const props = defineProps({
  contracts: {
    type: Object as PropType<Contract[]>,
    required: true,
  },
  isLoading: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(["contractChanged", "clientIdsChanged"]);

const distinctClientIds = ref(new Map() as Map<String, Contract[]>);

const application = computed(() => store.getters["currentApplication"]);

const oauthTokens = computed((): OAuthToken[] => {
  return props.contracts.map((contract) => {
    const oauthTokens = Object.values(contract?.oAuthTokens);
    if (oauthTokens && oauthTokens.length > 0) {
      return oauthTokens[0];
    }
  });
});

onMounted(() => {
  updateDistinctTokens();
});

const contractWithoutTokens = computed(() => {
  return props.contracts.filter((contract) => {
    const oauthTokens = Object.values(contract.oAuthTokens);
    return !oauthTokens || oauthTokens.length === 0;
  });
});

const updateDistinctTokens = () => {
  distinctClientIds.value = new Map();

  props.contracts.map((contract) => {
    const oauthTokens = Object.values(contract.oAuthTokens);
    if (oauthTokens && oauthTokens.length > 0) {
      const oauthToken = oauthTokens[0];
      if (distinctClientIds.value.has(oauthToken.clientId)) {
        distinctClientIds.value.get(oauthToken.clientId).push(contract);
      } else {
        distinctClientIds.value.set(oauthToken.clientId, [contract]);
      }
    }
  });
};

const distinctOAuthCredentials = computed((): Map<OAuthToken, Contract[]> => {
  const distinctOAuthTokenContractMap: Map<OAuthToken, Contract[]> = new Map();

  Array.from(distinctClientIds.value.keys()).forEach((clientId) => {
    const contracts = distinctClientIds.value.get(clientId);
    const oauthToken = oauthTokens.value.find(
      (token) => token.clientId === clientId,
    );
    distinctOAuthTokenContractMap.set(oauthToken, contracts);
  });

  return distinctOAuthTokenContractMap;
});

const distinctOAuthCredentialsKeys = computed(() =>
  Array.from(distinctOAuthCredentials.value.keys()).sort((a, b) =>
    comparePingProviders(a, b),
  ),
);

const tokenIds = computed(() => {
  return distinctOAuthCredentialsKeys.value.map((token) => token.id);
});

const disabledTokenIds = ref([] as string[]);

watch(
  () => props.contracts,
  (newContracts, prevContracts) => {
    if (!isEqual(newContracts, prevContracts)) {
      updateDistinctTokens();
    }
  },
);

watch(tokenIds, async (newTokenIds, prevTokenIds) => {
  if (!isEqual(newTokenIds, prevTokenIds)) {
    disabledTokenIds.value = await checkOAuthTokensStatusesOnPingSide(
      tokenIds.value,
    );
  }
});

watch(distinctClientIds, async (newClientIds, prevClientIds) => {
  if (newClientIds.size > 0 && !isEqual(newClientIds, prevClientIds)) {
    emit("clientIdsChanged", Array.from(distinctClientIds.value.keys()));
  }
});
</script>

<template>
  <div class="oauth-contracts">
    <template v-if="isLoading">
      <ContractsList :application="application" isLoading />
    </template>
    <template v-else>
      <div
        v-if="contractWithoutTokens.length > 0"
        class="oauth-contracts__container"
      >
        <ContractsList
          :application="application"
          :contracts="contractWithoutTokens"
          @contractChanged="emit('contractChanged')"
        />
      </div>
      <div
        v-for="item in distinctOAuthCredentialsKeys"
        :key="item?.id"
        class="oauth-contracts__container"
      >
        <OAuthCredentialsBar :oauthToken="item" />

        <DisabledClientIdNotification
          v-if="disabledTokenIds.includes(item?.id)"
        />

        <ContractsList
          :application="application"
          :contracts="distinctOAuthCredentials.get(item)"
          @contractChanged="emit('contractChanged')"
        />
      </div>
    </template>
  </div>
</template>

<style lang="scss">
.oauth-contracts,
.oauth-contracts__container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
</style>
