<template>
  <div class="global-layer-modal">
    <MLayer v-model:open="isOpen" :layerTitle="title" extended>
      <!-- v-if="isOpen" on <component/> so underliying element doesn't exist in the DOM
(would cause e2e tests crash because of element duplication) -->
      <component
        :is="component"
        v-if="isOpen"
        v-bind="props"
        v-on="listeners"
        @onClose="handleClose"
      />
    </MLayer>
  </div>
</template>

<script lang="ts">
import MLayer from "@mozaic-ds/vue-3/src/components/layer/MLayer.vue";

import { EModalType } from "@/commons/components/Modal/modal.type";

import { KEY } from "@/commons/utils/key-code";

export default {
  components: { MLayer },
  provide: {
    // Provide the class name in children components
    globalLayerModalFooterClass: "global-layer-modal-footer",
  },
  computed: {
    openModal() {
      return this.$store.state["openModal"];
    },
    props() {
      return this.openModal?.props;
    },
    listeners() {
      return this.openModal?.listeners || {};
    },
    title() {
      // title is required by MLayer so we return an empty value if undefined
      return this.openModal?.title ? this.openModal.title : "";
    },
    component() {
      return this.openModal?.component;
    },
    redirectOnClose() {
      return this.openModal?.redirectOnClose;
    },
    isOpen: {
      get() {
        return (
          this.$store.state["modalType"] === EModalType.LAYER_MODAL &&
          this.$store.getters["isModalOpen"]
        );
      },
      set(newValue) {
        if (newValue === false) {
          this.handleClose();
        }
      },
    },
  },
  created() {
    document.addEventListener("keyup", this.handleKeyup);
  },
  beforeUnmount() {
    document.removeEventListener("keyup", this.handleKeyup);
  },
  methods: {
    handleClose() {
      if (this.redirectOnClose) {
        this.$router.push({ name: this.redirectOnClose });
      }

      this.$store.commit("closeModal");
    },
    handleKeyup(e) {
      if (e.keyCode === KEY.ESCAPE) {
        this.handleClose();
      }
    },
  },
};
</script>

<style lang="scss">
.global-layer-modal {
  // New stacking context to isolate .mc-layer and .mc-layer-overlay crazy z-indexes and directly handle it with .global-layer-modal
  position: fixed;
  z-index: var(--z-index-modal);

  .mc-layer__content {
    height: 100%; // needed for modal buttons getting display at the very bottom
    padding-right: 0.5rem; // For having more space on scrollable blocks

    // padding-bottom is not added when we have a scroll so we set it to 0 and let .global-layer-modal-footer handle it
    padding-bottom: 0;
  }
}

// generic BEM blocks usable in layerModals
.global-layer-modal-footer {
  padding-top: 2rem;
  padding-bottom: 1.5rem;
}
</style>
