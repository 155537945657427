<template>
  <div class="list-apis-catalog">
    <Stack>
      <Grid>
        <template v-if="isLoading"
          ><CardLoading v-for="index in 6" :key="`card-loading-${index}`"
        /></template>
        <template v-else
          ><CardApiCatalog
            v-for="(api, index) of apis"
            :key="api.id"
            :api="api"
            :hide-product="hideProduct"
            :tabindex="index + 1"
        /></template>
      </Grid>
      <div
        v-if="!isLoading && apis.length === 0"
        class="list-apis-catalog__no-content"
      >
        {{ contents.noApiToDisplay }}
      </div>
    </Stack>
  </div>
</template>

<script lang="ts">
import CardApiCatalog from "@/commons/components/CardListed/CardApiCatalog.vue";
import CardLoading from "@/commons/components/CardListed/CardLoading.vue";
import Grid from "@/commons/components/Grid.vue";
import Stack from "@/commons/components/Stack.vue";

import contents from "@/commons/contents/list-apis-catalog";

export default {
  name: "ListApisCatalog",
  components: { Stack, Grid, CardApiCatalog, CardLoading },
  props: {
    apis: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    hideProduct: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      contents,
    };
  },
};
</script>

<style lang="scss">
.list-apis-catalog {
  --grid-layout-item-width: 22rem;
  --grid-layout-gap: 1rem;
}

.list-apis-catalog__no-content {
  font-weight: 700;
}
</style>
