/** Create an object with the path structure. The deepest property will have the {} value
 * Example: buildPayload(["a","b"]) will return { a: { b: {} }} **/
export function buildPayload(path: string[]) {
  const payload = {};
  let pointer = payload;

  for (const key of path) {
    pointer[key] = {};
    pointer = pointer[key];
  }

  return payload;
}
