<template>
  <Box class="card-footer bg-background-interface"><slot /></Box>
</template>

<script lang="ts">
import Box from "@/commons/components/Box.vue";

export default {
  name: "CardFooter",
  components: { Box },
};
</script>

<style lang="scss">
.card-footer {
  border-top: var(--border-default);
  border-bottom-right-radius: var(--m-border-radius-large);
  border-bottom-left-radius: var(--m-border-radius-large);
}
</style>
