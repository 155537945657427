<template>
  <CardLink
    class="card-api-catalog"
    :to="{ name: 'apiDocumentationDetail', params: { id: api.id } }"
  >
    <CardContentApi
      :name="apiName"
      :version="api.version"
      :description="api.description"
      :product-name="productName"
      :apiType="api.type"
      :apiSubtype="api.subtype"
    />
    <template v-if="!hideFooter" #footer>
      <CardFooterApi>
        <AuthorizationRequired
          v-if="authorizationRequired"
          class="card-api-catalog--margin-top-bottom"
        />
        <div v-if="isDeprecated" class="card-api-catalog--margin-top-bottom">
          <Chip>DEPRECATED</Chip>
        </div>
        <BusinessUnitDeployment
          v-if="isLogged"
          :tags="buTags"
          :label="businessUnitsLabel"
          class="card-api-catalog--margin-top-bottom"
        />
      </CardFooterApi>
    </template>
  </CardLink>
</template>

<script lang="ts">
import { apiHasAuthorizationRequired } from "./api-has-authorization-required";
import { getProductNameFromApi } from "./get-product-name-from-api";

import AuthorizationRequired from "@/commons/components/AuthorizationRequired/AuthorizationRequired.vue";
import BusinessUnitDeployment from "@/commons/components/BusinessUnitDeployment/BusinessUnitDeployment.vue";
import CardContentApi from "@/commons/components/CardListed/CardContentApi.vue";
import CardFooterApi from "@/commons/components/CardListed/CardFooterApi.vue";
import CardLink from "@/commons/components/CardListed/CardLink.vue";
import Chip from "@/commons/components/Chip.vue";

import {
  getApiLabel,
  getApiRestTypeLabel,
} from "@/commons/libs/utils/apiUtils";
import { filterBuTags } from "@/commons/libs/utils/businessUnitsUtils";

export default {
  name: "CardApiCatalog",
  components: {
    CardLink,
    CardContentApi,
    CardFooterApi,
    AuthorizationRequired,
    Chip,
    BusinessUnitDeployment,
  },
  props: {
    api: {
      type: Object,
      required: true,
    },
    hideProduct: {
      type: Boolean,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    productName() {
      return getProductNameFromApi(this.api, this.hideProduct);
    },
    authorizationRequired() {
      return apiHasAuthorizationRequired(this.api);
    },
    isDeprecated() {
      return this.api.isDeprecated;
    },
    isLogged() {
      return this.$store.getters["userIsLogged"];
    },
    buTags() {
      return filterBuTags(this.api.tags);
    },
    apiName() {
      return getApiLabel(this.api);
    },
    businessUnitsLabel() {
      return getApiRestTypeLabel(this.api.internal);
    },
  },
};
</script>

<style lang="scss">
.card-api-catalog--margin-top-bottom {
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
