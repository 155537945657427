import { FormFactory } from "./form-factory";
import { IsNotEmpty, MaxLength, MinLength, ValidateIf } from "class-validator";

import { FormData } from "@/commons/domain/forms/form-data";
import { User } from "@/commons/domain/models/user";

export class CreateGroupForm extends FormData {
  needManagerField: boolean;

  @MinLength(3, {
    message:
      "This field is too short. Minimal length is $constraint1 characters",
  })
  @MaxLength(50, {
    message:
      "This field is too long. Maximal length is $constraint1 characters",
  })
  name: string;

  @MaxLength(200, {
    message:
      "This field is too long. Maximal length is $constraint1 characters",
  })
  description: string;

  @ValidateIf((form) => form.needManagerField)
  @IsNotEmpty()
  administrator?: User;

  $clear() {
    this.name = "";
    this.description = "";
    this.administrator = undefined;
  }

  static create() {
    return FormFactory.createForm(new CreateGroupForm());
  }
}
