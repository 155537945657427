module.exports = {
  accessYourAccount: "Access your account",
  adeoEmployees: "You are ADEO employees or partners",
  workingForAdeo: "You are working for ADEO group or you are an ADEO partner.",
  loginWithAdeo: "Login with your ADEO account",
  externalToAdeo: "Partners now have a dedicated LDAP.",
  linkToPartnerLoginHelp: "View more",
  partnerLoginHelpTitle: "Partners authentication",
  partnerLoginHelpContent:
    "Previously, partners used Github and Google SSO, but now their authentication is done using the partner referential and PING SSO.\n\n" +
    "To reference a new partner, you must create an account in the partner repository following the " +
    "[documentation](https://jira.adeo.com/confluence/display/IAM/Third-party+-+How+to+create+or+get+identities+in+the+third+party+Directory) from the Identity team.\n\n" +
    'Once created, partner will receive an LDAP login in TXXXXXXX (and his password) and will be able to authenticate himself through the "Login with your ADEO account" button.',
  closeModalButton: "Close",
};
