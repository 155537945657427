<template>
  <div class="action-buttons-wrapper">
    <slot />
  </div>
</template>
<script setup lang="ts"></script>

<style lang="scss">
.action-buttons-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
</style>
