import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-centered" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "weight-regular gap-1300" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopNavbarEmpty = _resolveComponent("TopNavbarEmpty")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_Layout = _resolveComponent("Layout")!

  return (_openBlock(), _createBlock(_component_Layout, {
    class: "page-forbidden",
    "hide-loader": ""
  }, {
    navbar: _withCtx(() => [
      _createVNode(_component_TopNavbarEmpty)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          class: "responsive-image",
          src: require('@/commons/assets/images/page-not-found.png')
        }, null, 8, _hoisted_2),
        _createElementVNode("h1", _hoisted_3, _toDisplayString($data.contents.pageForbiddenContent), 1),
        _createVNode(_component_RouterLink, {
          class: "gap-1300",
          role: "button",
          to: { name: 'home' },
          title: ($options.userIsInternal && 'Dashboard') || 'Home'
        }, {
          default: _withCtx(() => [
            ($options.userIsInternal)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(_toDisplayString($data.contents.backDashboard), 1)
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(_toDisplayString($data.contents.backHome), 1)
                ], 64))
          ]),
          _: 1
        }, 8, ["title"])
      ])
    ]),
    _: 1
  }))
}