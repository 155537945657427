<template>
  <LayerModalFormConfirm
    class="create-or-update-black-list-route-modal"
    :confirmBtnLabel="submitButtonLabel"
    confirmBtnTheme="solid-primary"
    :disabledConfirmBtn="!!form.errors.length"
    @submit="onSubmit"
    @close="$emit('onClose')"
  >
    <DvpField
      class="create-or-update-black-list-route-modal__http-methods-field"
      :label="contents.httpMethodsLabel"
      required
      :errorMessage="form.firstError('httpMethods')"
    >
      <MDropdown
        v-model="form.httpMethods"
        multiple
        :items="httpMethodsItems"
        :placeholder="contents.httpMethodsPlaceholder"
      />
    </DvpField>
    <DvpField
      class="create-or-update-black-list-route-modal__path-regex-field"
      :label="contents.pathRegexLabel"
    >
      <MTextInput
        v-model="form.pathRegex"
        :placeholder="contents.pathRegexPlaceholder"
        :isInvalid="form.firstError('pathRegex') != null"
      />
    </DvpField>
    <MNotification
      class="create-or-update-black-list-route-modal__notification"
      :type="notificationType"
      :title="contents.notificationTitle"
    >
      <template #default>
        <span v-html="contents.notificationMessage" />
      </template>
      <template #footer>
        <ViewHelpButton documentationAnchor="#manage-route-blacklisting" />
      </template>
    </MNotification>
  </LayerModalFormConfirm>
</template>

<script lang="ts">
import MDropdown from "@mozaic-ds/vue-3/src/components/dropdown/MDropdown.vue";
import MNotification from "@mozaic-ds/vue-3/src/components/notification/MNotification.vue";
import MTextInput from "@mozaic-ds/vue-3/src/components/textinput/MTextInput.vue";

import ViewHelpButton from "@/commons/components/UserDocumentationLinks/ViewHelpButton.vue";
import DvpField from "@/commons/components/form/DvpField.vue";
import LayerModalFormConfirm from "@/commons/components/form/LayerModalFormConfirm.vue";

import { ORDERED_HTTP_METHODS } from "@/commons/utils/http-method";

import { BlackListPolicy } from "@/commons/domain/models/black-list-policy";
import { CreateUpdateBlackListPolicyForm } from "@/commons/forms/create-update-black-list-policy-form";

import contents from "@/manager/contents/create-or-update-blacklist-route-modal";

export default {
  name: "CreateOrUpdateBlackListRouteModal",
  components: {
    LayerModalFormConfirm,
    MTextInput,
    MDropdown,
    MNotification,
    DvpField,
    ViewHelpButton,
  },
  props: {
    api: {
      type: Object,
      required: true,
    },
    submitButtonLabel: {
      type: String,
      required: true,
    },
    blackListPolicy: {
      type: Object,
      default: null,
    },
  },
  emits: ["onSubmit", "onClose"],
  data() {
    return {
      form: CreateUpdateBlackListPolicyForm.create(),
      contents,
    };
  },
  computed: {
    httpMethodsItems() {
      return ORDERED_HTTP_METHODS.map((method) => ({
        id: method,
        value: method,
        label: method,
      }));
    },
    notificationType(): string {
      return this.form.firstError("pathRegex") ? "danger" : "information";
    },
  },
  async mounted() {
    if (this.blackListPolicy) {
      await this.form.init({
        pathRegex: this.blackListPolicy.pathRegex,
        httpMethods: this.blackListPolicy.httpMethods,
      });
    } else {
      await this.form.init({
        httpMethods: ORDERED_HTTP_METHODS,
      });
    }
  },
  methods: {
    async onSubmit() {
      if (!this.form.errors.length) {
        const policy: BlackListPolicy = this.form.data();
        policy.id = this.blackListPolicy && this.blackListPolicy.id;
        await this.$store.dispatch("createOrUpdateBlackListPolicy", policy);
      }
    },
  },
};
</script>

<style lang="scss">
.create-or-update-black-list-route-modal {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__form-fields {
    flex: 1 1;
  }

  &__form-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &__cancel-button {
    margin-right: 1rem;
  }
}
</style>
