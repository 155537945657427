module.exports = {
  applyChanges: "Apply changes",
  requestLargerTimeout: "Request timeout",
  cancel: "Cancel",
  seconds: "seconds",
  timeoutSettings: "Timeout settings",
  timeoutDescription:
    "The timeout represents the global duration before closing the connection.",
  timeout: "Define timeout in seconds",
  timeoutLimitInformationTitle: "The timeout is limited",
  timeoutLimitInformation: (maxTimeout) =>
    `If you choose to put a timeout larger than <strong>${maxTimeout} seconds</strong>, the devportal team will have to verify your use case.`,
  timeoutPendingRequestLabel: "Pending request",
  timeoutLinkLabel: "View request",
  timeoutLimitWarningTitle: "You changed the timeout",
  timeoutLimitWarning: (timeout) =>
    `You chose to put a timeout of <strong>${timeout} seconds</strong>, the devportal team will have to verify your use case.`,
  loweredTimeoutWarningTitle: (maxPublicTimeout) =>
    `Your configured timeout is lowered to ${maxPublicTimeout} sec`,
  loweredTimeoutWarning: (maxPublicTimeout) =>
    `because CDN Fastly limits publicly exposed API's timeout to <strong>${maxPublicTimeout}&nbsp;sec</strong> max.<br />
    Your API User Experience may be impacted.`,
};
