<template>
  <div class="quota-consumers">
    <MTextInput
      v-model="searchApplication"
      class="quota-consumers__search-application"
      icon="DisplaySearch48"
      :placeholder="contents.searchForAnApp"
      @update:modelValue="searchContracts({ applicationName: $event, page: 1 })"
    />
    <div v-if="apiContractsList.length > 0">
      <MDataTable :source="apiContractsList" :sorting="{ mode: 'none' }">
        <MDataTableHeader
          :caption="contents.tableHeaderApplicationName"
          dataFieldExpr="application.name"
          :allowSorting="false"
        />
        <MDataTableHeader
          :caption="contents.tableHeaderRateLimit"
          dataFieldExpr="rateLimits"
          :allowSorting="false"
        />
        <MDataTableHeader
          :caption="contents.tableHeaderAction"
          dataFieldExpr="actions"
          :allowSorting="false"
        />
        <template #[`item.rateLimits`]="{ item }">
          <div>{{ getItemRateLimitLabel(item) }}</div>
        </template>
        <template #[`item.actions`]="{ item }">
          <div
            v-if="item.rateLimits && item.rateLimits.global"
            class="quota-consumers__actions"
          >
            <MToggle
              :id="item.rateLimits.global.id"
              :modelValue="item.rateLimits.global.isEnabled"
              @update:modelValue="toggleRateLimit($event, item)"
            />

            <ButtonEdit
              v-if="item.rateLimits && item.rateLimits.global"
              @click="editContractRateLimit(item)"
            />

            <ButtonDelete @click="deleteContractRateLimit(item)" />
          </div>
          <div v-else>
            <ButtonEdit @click="createContractRateLimit(item)" />
          </div>
        </template>
      </MDataTable>
      <MPagination
        class="quota-consumers__pagination"
        :length="contractsListTotalPages"
        :value="currentContractsPage"
        @on-update-page="searchContracts({ page: $event })"
      />
    </div>
    <h5 v-else class="quota-consumers__no-consumer-result">
      {{ contents.noConsumerFound }}
    </h5>
  </div>
</template>

<script lang="ts">
import MDataTable from "@mozaic-ds/vue-3/src/components/datatable/MDataTable.vue";
import MDataTableHeader from "@mozaic-ds/vue-3/src/components/datatable/MDataTableHeader.vue";
import MPagination from "@mozaic-ds/vue-3/src/components/pagination/MPagination.vue";
import MTextInput from "@mozaic-ds/vue-3/src/components/textinput/MTextInput.vue";
import MToggle from "@mozaic-ds/vue-3/src/components/toggle/MToggle.vue";
import { PropType } from "vue";

import ButtonDelete from "@/commons/components/Buttons/ButtonDelete.vue";
import ButtonEdit from "@/commons/components/Buttons/ButtonEdit.vue";
import QuotaRateLimitEditionModal from "@/manager/views/ApiDetailQuotas/QuotaRateLimitEditionModal.vue";

import {
  buildDefaultRateLimit,
  getRateLimitLabel,
} from "@/commons/utils/quota-utils";

import { Api } from "@/commons/domain/models/api";
import { Contract } from "@/commons/domain/models/contract";
import {
  EWindowLengthUnit,
  RateLimit,
} from "@/commons/domain/models/rate-limit";

import {
  Contracts,
  EApplicationType,
  EContractStatus,
} from "@/commons/store/types";

import contents from "@/manager/contents/quota-consumers";
import contentsForRateLimitEditionModal from "@/manager/contents/quota-rate-limit-edition-modal";

interface ContractSearchCriteria {
  page: number;
  applicationName: string;
}

export default {
  components: {
    MDataTable,
    MDataTableHeader,
    MToggle,
    MTextInput,
    MPagination,
    ButtonEdit,
    ButtonDelete,
  },
  props: {
    allowedWindowLengthUnits: {
      type: Array as PropType<EWindowLengthUnit[]>,
      required: true,
    },
  },
  data() {
    return {
      contents,
      searchCriteria: {
        page: 1,
        applicationName: undefined,
      } as ContractSearchCriteria,
      searchApplication: undefined,
    };
  },
  computed: {
    currentApi(): Api {
      return this.$store.getters["currentApi"];
    },
    apiContracts(): Contracts {
      return this.$store.getters["contracts"]?.[EApplicationType.ALL];
    },
    apiContractsList(): Contract[] {
      return this.apiContracts != undefined
        ? Object.values(this.apiContracts.data)
        : [];
    },
    contractsListTotalPages(): number {
      return this.apiContracts.totalPages;
    },
    currentContractsPage(): number {
      return this.apiContracts.currentPage;
    },
  },
  created() {
    this.searchContracts({ applicationName: "" });
  },
  methods: {
    getItemRateLimitLabel(item: Contract): string {
      return getRateLimitLabel(item.rateLimits?.global);
    },
    createContractRateLimit(contract: Contract): void {
      this.openContractRateLimitEditionModal(
        contract,
        this.buildNewContractRateLimit(this.currentApi),
      );
    },
    buildNewContractRateLimit(api: Api): RateLimit {
      if (api.rateLimitsForNewContracts?.global?.isEnabled) {
        return {
          ...api.rateLimitsForNewContracts.global,
          id: undefined,
        };
      } else {
        return buildDefaultRateLimit();
      }
    },
    editContractRateLimit(contract: Contract): void {
      this.openContractRateLimitEditionModal(
        contract,
        contract.rateLimits.global,
      );
    },
    deleteContractRateLimit(contract: Contract): void {
      this.$store.dispatch("deleteContractRateLimit", {
        appId: contract.application?.id,
        contractId: contract.id,
        rateLimitId: contract.rateLimits?.global?.id,
      });
    },
    openContractRateLimitEditionModal(
      contract: Contract,
      rateLimit: RateLimit,
    ): void {
      this.$store.commit("openLayerModal", {
        title: contentsForRateLimitEditionModal.modalTitleForContractRateLimit(
          contract.application.name,
        ),
        component: QuotaRateLimitEditionModal,
        props: {
          rateLimit,
          allowedWindowLengthUnits: this.allowedWindowLengthUnits,
        },
        listeners: {
          onSubmit: (newRateLimit: RateLimit) => {
            this.createOrUpdateContractRateLimit(contract, newRateLimit);
            this.$store.commit("closeModal");
          },
        },
      });
    },
    toggleRateLimit(isEnabling, contract): void {
      this.createOrUpdateContractRateLimit(contract, {
        ...contract.rateLimits.global,
        isEnabled: isEnabling,
      });
    },
    searchContracts(params: Partial<ContractSearchCriteria>): void {
      this.searchCriteria = {
        ...this.searchCriteria,
        ...params,
      };

      this.loadContractsForSearchCriteria(this.searchCriteria);
    },
    loadContractsForSearchCriteria(
      searchCriteria: ContractSearchCriteria,
    ): void {
      this.$store.dispatch("getContracts", {
        apiId: this.currentApi.id,
        status: [EContractStatus.ENABLED],
        applicationTypes: [EApplicationType.ALL],
        ...searchCriteria,
        cancellable: true,
      });
    },
    createOrUpdateContractRateLimit(
      contract: Contract,
      rateLimit: RateLimit,
    ): void {
      this.$store.dispatch("createOrUpdateContractRateLimit", {
        appId: contract.application.id,
        contractId: contract.id,
        rateLimit,
      });
    },
  },
};
</script>

<style lang="scss">
.quota-consumers {
  margin-top: 2rem;
}

.quota-consumers__actions {
  display: flex;
  align-items: center;
}

.quota-consumers__search-application {
  margin: 2rem 0;
}

.quota-consumers__pagination {
  margin-top: 2rem;
}

.quota-consumers__no-consumer-result {
  text-align: center;
}
</style>
