export default {
  synchronizeWithKongTitle: "Synchronize with Kong",
  synchronizeWithKongDescription:
    "You have an emergency or you don’t want to wait for the next synchronization?\n" +
    "You can now synchronize manually your whole environment with Kong.\n\n" +
    "Make sure the next schedule sync isn’t in a few minutes.\n" +
    "In addition, don’t overlap an ongoing synchronization, not to break any configuration in Kong.",
  readDocumentationLink: "Read documentation",
  followBatchExecutionStatus: "Follow the batch execution status",
  synchronizeBtnLabel: "Synchronize with Kong",

  warningMessage: "This feature is only available for DevPortal team members.",
  launchedSyncToastMessage: "Synchronization launched. Wait for the end...",
  selectTenant: "Select tenant",
};
