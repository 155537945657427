export default [
  {
    title: "Signage in store application",
    slug: "signage-in-store",
    description:
      "Improve employees experience to have a beautiful and clear signage in store for inhabitants",
    headline:
      "Deliver <span class='use-cases__headline--bold'>signage faster</span> and in an <span class='use-cases__headline--bold'>easier way</span>",
    kicker:
      "This application has been designed with users in order to reduce the time spent on requesting and creating signage. We have been accompanied by our partner Piivo who release this awesome application.",
    image: {
      adeo: require("@/commons/assets/images/use-cases/signage-in-store/icon-adeo.svg"),
      lmfr: require("@/commons/assets/images/use-cases/signage-in-store/icon.svg"),
    },
    features: [
      {
        name: "identification",
        title: "Identification",
        kicker: "The user's profile is provided by his authentication",
        keyFeatures: [
          "Thanks to OAuth authentication server and Contact API, we retrieve user's information like his name, department, store or his job.",
          "The application get dynamically from Contact API user's job and attribute the good profile like signage employee or decorator.",
          "With the automatic contextualization, the user knows which jobs are pending for processing for his store.",
        ],
        image: require("@/commons/assets/images/use-cases/signage-in-store/feature1-1.png"),
      },
      {
        name: "create",
        title: "Create",
        kicker:
          "In a flash, users can design a new signage to beautify the store",
        keyFeatures: [
          "Thanks to Product API v2, users search products information to know which pricing policy must be applied.",
          "This feature let us be in compliance with new graphic charter faster.",
          "The graphic pricing charter is deducted from the pricing policy.",
          "Users can preview final result before printing it.",
          "Save 50% of time on a unit signage request compared to the oldest tool.",
          "Data quality is increase on batch requests.",
        ],
        image: require("@/commons/assets/images/use-cases/signage-in-store/feature2-1.png"),
        carousel: [
          require("@/commons/assets/images/use-cases/signage-in-store/feature2-1.png"),
          require("@/commons/assets/images/use-cases/signage-in-store/feature2-2.png"),
        ],
      },
      {
        name: "prepare",
        title: "Prepare",
        kicker:
          "The preparation of commercial operation signage has never been so fast and easy",
        keyFeatures: [
          "Thanks to Commercial Operation API, a user can search commercial operations for his store and by department and view the list of associated products. So it's easier to make signage requests.",
        ],
        image: require("@/commons/assets/images/use-cases/signage-in-store/feature3-1.png"),
        carousel: [
          require("@/commons/assets/images/use-cases/signage-in-store/feature3-1.png"),
          require("@/commons/assets/images/use-cases/signage-in-store/feature3-2.png"),
          require("@/commons/assets/images/use-cases/signage-in-store/feature3-3.png"),
        ],
      },
      {
        name: "charter",
        title: "Charter",
        kicker:
          "Don't worry about the pricing graphic charter, the application does the job for you.",
        keyFeatures: [
          "Price API allows to obtain all prices for a product. The application is smart enough to detect promotions and suggest the best graphic charter to apply.",
          "Signages are in compliance with the law thanks to eco-tax attribute found in Product API.",
        ],
        image: require("@/commons/assets/images/use-cases/signage-in-store/feature4-1.png"),
        carousel: [
          require("@/commons/assets/images/use-cases/signage-in-store/feature4-1.png"),
          require("@/commons/assets/images/use-cases/signage-in-store/feature4-2.png"),
        ],
      },
      {
        name: "applicationapi",
        title: "Application API",
        kicker:
          "This application uses many APIs, so it is now time to expose its capabilities",
        keyFeatures: [
          "A REST API has been created to allow third-party applications to make print requests.",
          "This API allow to retrieve a signage (picture file) to show it everywhere",
        ],
        image: require("@/commons/assets/images/use-cases/signage-in-store/feature5-1.png"),
        carousel: [
          require("@/commons/assets/images/use-cases/signage-in-store/feature5-1.png"),
          require("@/commons/assets/images/use-cases/signage-in-store/feature5-2.png"),
          require("@/commons/assets/images/use-cases/signage-in-store/feature5-3.png"),
          require("@/commons/assets/images/use-cases/signage-in-store/feature5-4.png"),
        ],
      },
    ],
  },
];
