<template>
  <GlobalModalBody class="confirm-modal-body">
    <template #content>
      <slot />
    </template>

    <template #footer>
      <MButton
        data-cy="modal-cancel-btn"
        type="button"
        theme="bordered-neutral"
        :label="cancelBtnLabel"
        @click="$emit('onClose')"
      />
      <MButton
        data-cy="modal-submit-btn"
        :theme="confirmBtnTheme"
        :label="confirmBtnLabel"
        :disabled="disabledConfirmBtn"
        @click="$emit('onSubmit')"
      />
    </template>
  </GlobalModalBody>
</template>

<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";

import GlobalModalBody from "@/commons/components/Modal/GlobalModalBody.vue";

import contents from "@/commons/contents/modal";

export default {
  components: {
    GlobalModalBody,
    MButton,
  },
  props: {
    cancelBtnLabel: {
      type: String,
      default: contents.cancelBtn,
    },
    confirmBtnLabel: {
      type: String,
      default: contents.confirmBtn,
    },
    confirmBtnTheme: {
      type: String,
      default: "solid-danger",
    },
    disabledConfirmBtn: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["onClose", "onSubmit"],
};
</script>
