module.exports = {
  applyChanges: "Apply changes",
  cancel: "Cancel",
  deprecated: "Deprecated",
  description: "Description",
  productsComeFromTangram:
    "Products come from Tangram. If you cannot find your product, make sure it is created in Tangram.",
  relatedProduct: "Related product",
  settings: "settings",
};
