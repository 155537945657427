<template>
  <div class="contract-card-container">
    <slot />
  </div>
</template>

<style lang="scss">
.contract-card-container {
  display: flex;
  flex-direction: column;
  gap: 1.125rem;

  padding: 1.5rem;
}
</style>
