<template>
  <slides-layout class="home">
    <template #slides>
      <slide class="home__main" anchor="home-main">
        <home-main @navigateToAnchor="navigateToAnchor($event)" />
      </slide>

      <slide class="home__use-cases" anchor="home-use-cases">
        <home-use-cases />
      </slide>

      <slide class="home__try-it" anchor="home-try-it">
        <home-try-it />
      </slide>

      <slide class="home__resume" anchor="home-resume">
        <home-resume />
        <footer-menus />
      </slide>
    </template>
  </slides-layout>
</template>

<script lang="ts">
import FooterMenus from "@/commons/components/FooterMenus.vue";
import Slide from "@/commons/components/SlidesLayout/Slide.vue";
import SlidesLayout from "@/commons/components/SlidesLayout/SlidesLayout.vue";
import HomeTryIt from "@/showcase/views/Home/HomeTryIt.vue";

import HomeMain from "./HomeMain.vue";
import HomeResume from "./HomeResume.vue";
import HomeUseCases from "./HomeUseCases.vue";

export default {
  name: "Home",
  components: {
    SlidesLayout,
    Slide,
    HomeMain,
    HomeUseCases,
    HomeTryIt,
    HomeResume,
    FooterMenus,
  },
  extends: SlidesLayout,
  computed: {
    userIsLogged() {
      return this.$store.getters["userIsLogged"];
    },
  },
  created() {
    if (this.userIsLogged) {
      this.$router.push({
        name: "dashboard",
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/commons/assets/styles/vars";

.home__main,
.home__try-it {
  position: relative;
  background-color: var(--color-leroy-merlin);

  &::before {
    position: absolute;
    top: 0;
    right: 10rem;
    width: 42vw;
    height: 100%;
    content: "";
    background: var(--color-background-interface);
    transform: skewX(-25deg);
  }
}

.home__use-cases {
  position: relative;
  background-color: var(--color-leroy-merlin);

  &::before {
    position: absolute;
    top: 0;
    right: 10rem;
    width: 42vw;
    height: 100%;
    content: "";
    background: var(--color-background-interface);
    transform: skewX(25deg);
  }
}

@media only screen and (max-width: map-get($breakpoints, tablet-landscape)) {
  .home__main,
  .home__try-it {
    &::before {
      right: -15rem;
      width: 62vw;
    }
  }

  .home__use-cases {
    &::before {
      right: -15rem;
      width: 62vw;
    }
  }
}

@media only screen and (max-width: map-get($breakpoints, tablet-portrait)) {
  .home__main {
    &::before {
      top: -280px;
      right: 0;
      width: 92vw;
      transform: skewX(-125deg);
    }
  }

  .home__use-cases {
    &::before {
      top: 180px;
      right: 0;
      width: 110vw;
      transform: skewX(-245deg);
    }
  }

  .home__try-it {
    &::before {
      top: -180px;
      right: 0;
      width: 110vw;
      transform: skewX(245deg);
    }
  }
}

@media only screen and (max-width: map-get($breakpoints, mobile)) {
  .home__main {
    &::before {
      top: -280px;
      right: 0;
      width: 92vw;
      transform: skewX(-125deg);
    }
  }

  .home__use-cases {
    background-color: var(--color-background-interface);

    &::before {
      top: 60px;
      right: 0;
      width: 150vw;
      background-color: var(--color-leroy-merlin);
      transform: skewX(-306deg);
    }
  }

  .home__try-it {
    background-color: var(--color-white);

    &::before {
      top: 0;
      right: 0;
      width: 150vw;
      background-color: var(--color-leroy-merlin);
      transform: skewX(306deg);
    }
  }
}
</style>
