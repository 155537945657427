<template>
  <div class="deprecate-api-zones-modal">
    <div class="deprecate-api-zones-modal__wrapper">
      <LayerModalForm data-cy="deprecate-api-zones-modal" @submit="submit">
        <template #fields>
          <DvpField
            required
            :label="contents.zoneIdLabel"
            :helpText="contents.helpTooltipZoneId"
            :errorMessage="form.firstError('zoneId')"
            data-cy="zone-to-deprecate"
          >
            <MSelect
              id="zone-to-deprecate"
              v-model="form.zoneId"
              :placeholder="contents.selectZone"
              :options="zonesList"
              :disabled="isSaving"
            />
          </DvpField>

          <DvpField
            required
            :label="contents.expirationDateLabel"
            :helpText="contents.helpTooltipDate"
            :errorMessage="form.firstError('date')"
            data-cy="expiration-date"
          >
            <DatePicker
              :modelValue="form.date && form.date.toISOString()"
              :label="contents.selectDate"
              :minDate="tomorrow"
              color="#38ada9"
              format="YYYY-MM-DD"
              formatted="YYYY/MM/DD"
              autoClose
              noHeader
              noButtonNow
              noClearButton
              noLabel
              noKeyboard
              onlyDate
              @update:modelValue="form.date = new Date($event)"
            />
          </DvpField>

          <DvpField
            required
            :label="contents.messageLabel"
            :helpText="contents.helpTooltipMessage"
            :errorMessage="form.firstError('message')"
            data-cy="deprecate-zone-message"
          >
            <MTextArea
              id="message"
              v-model="form.message"
              :rows="6"
              :placeholder="contents.messageLabel"
              :disabled="isSaving"
            />
          </DvpField>
        </template>
        <template #formFooter>
          <MButton
            type="button"
            theme="bordered-neutral"
            :label="contents.cancel"
            :disabled="isSaving"
            @click="$emit('onClose')"
          />

          <MButton
            :label="contents.confirm"
            theme="solid-danger"
            type="submit"
          />
        </template>
      </LayerModalForm>
    </div>
  </div>
</template>

<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import MSelect from "@mozaic-ds/vue-3/src/components/select/MSelect.vue";
import MTextArea from "@mozaic-ds/vue-3/src/components/textarea/MTextArea.vue";
import moment from "moment";

import DatePicker from "@/commons/components/DatePicker/index.vue";
import DvpField from "@/commons/components/form/DvpField.vue";
import LayerModalForm from "@/commons/components/form/LayerModalForm.vue";

import { DeprecateApiZoneForm } from "@/manager/domain/forms/deprecate-api-zone-form";

import contents from "@/manager/contents/deprecate-api-zones-modal";

export default {
  name: "DeprecateApiZonesModal",
  components: {
    DvpField,
    DatePicker,
    MTextArea,
    MSelect,
    MButton,
    LayerModalForm,
  },
  props: {
    apiId: {
      type: String,
      required: true,
    },
  },
  emits: ["onClose"],
  data() {
    return {
      contents,
      form: DeprecateApiZoneForm.create(),
    };
  },
  computed: {
    tomorrow() {
      return moment().add(1, "days").toISOString();
    },
    isSaving() {
      return this.$store.getters["isSaving"];
    },
    deprecatableZones() {
      return this.$store.getters["deprecatableZones"];
    },
    zonesList() {
      return this.deprecatableZones.map((zone) => ({
        text: zone.name,
        value: zone.id,
      }));
    },
  },
  async mounted() {
    await this.form.clear();
  },
  methods: {
    async submit() {
      this.form.validate();

      if (!this.isSaving && !this.form.errors.length) {
        const { zoneId, date, message } = this.form.data();

        await this.$store.dispatch("deprecateApiZone", {
          apiId: this.apiId,
          zoneId,
          date,
          message,
        });
        await this.$store.dispatch("deprecatableZones", {
          apiId: this.apiId,
          params: {
            publishable: false,
            deprecatable: true,
          },
        });
      }
    },
  },
};
</script>

<style>
.deprecate-api-zones-modal {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.deprecate-api-zones-modal__wrapper {
  flex: 1;
}
</style>
