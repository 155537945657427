/**
 * clipboard.writeText polyfill
 * https://gist.github.com/lgarron/d1dee380f4ed9d825ca7
 * @param text
 */
export function writeText(text: string) {
  return new Promise((resolve, reject) => {
    let success = false;

    const listener = (e) => {
      e.clipboardData.setData("text/plain", text);
      e.preventDefault();
      success = true;
    };

    document.addEventListener("copy", listener);
    document.execCommand("copy");
    document.removeEventListener("copy", listener);

    success ? resolve(true) : reject();
  });
}
