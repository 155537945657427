<script setup lang="ts">
import MLoader from "@mozaic-ds/vue-3/src/components/loader/MLoader.vue";
import { computed, onMounted, PropType, reactive, ref } from "vue";
import { useStore } from "vuex";

import LayerModalFormConfirm from "@/commons/components/form/LayerModalFormConfirm.vue";
import ScopeSelectionCard from "@/dashboard/views/AppDetailsContracts/ScopeSelectionCard.vue";
import StepperApiSubscription from "@/dashboard/views/AppDetailsContracts/StepperApiSubscription.vue";

import { getApiRepository } from "@/commons/repositories/libs/get-api-repository";
import { flagLoadingProcess } from "@/commons/utils/flagProcess.utils";
import { listContainsAdminScope } from "@/manager/utils/oauth-scopes";

import { Scope } from "@/commons/domain/models/scope";

import contents from "@/dashboard/contents/subscribe-scopes-modal";
import contentSubscriptionStepper from "@/dashboard/contents/subscription-stepper";

const props = defineProps({
  apiId: {
    type: String,
    required: true,
  },
  passedSteps: {
    type: Array as PropType<{ label: string; isCurrent: boolean }[]>,
    required: true,
  },
  requiresManagersValidation: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["submit", "onClose"]);

const store = useStore();

const stepperSteps = computed(() => {
  const steps = [...props.passedSteps];
  steps.push({
    label: contentSubscriptionStepper.stepSelectScopesTitle,
    isCurrent: true,
  });
  if (
    props.requiresManagersValidation ||
    listContainsAdminScope(selectedScopes.value)
  ) {
    steps.push({
      label: contentSubscriptionStepper.stepSendValidationRequestTitle,
      isCurrent: false,
    });
  }
  return steps;
});

const scopes = reactive({
  items: [] as Scope[],
  load: async () => {
    await flagLoadingProcess("apiScopes", async () => {
      scopes.items = await getApiRepository().getApiScopes(props.apiId);
    });
  },
  isLoading: computed(() => store.getters["isLoadingProperty"]("apiScopes")),
  title: computed(() => {
    if (scopes.isLoading) {
      return contents.availableScopesTitle;
    } else {
      return `${contents.availableScopesTitle} (${scopes.items != undefined ? scopes.items.length : 0})`;
    }
  }),
});

onMounted(async () => {
  await scopes.load();
});

const selectedScopes = ref([]);

const toggleScope = (scopeName: string): void => {
  if (selectedScopes.value.includes(scopeName)) {
    selectedScopes.value = selectedScopes.value.filter(
      (selectedScope) => selectedScope !== scopeName,
    );
  } else {
    selectedScopes.value = [...selectedScopes.value, scopeName];
  }
};

const submit = (): void => {
  emit("submit", selectedScopes.value);
};

const close = (): void => {
  emit("onClose");
};
</script>

<template>
  <LayerModalFormConfirm
    class="subscribe-scopes-modal"
    confirmBtnTheme="solid"
    :confirmBtnLabel="contents.confirmBtnLabel"
    @submit="submit"
    @close="close"
  >
    <StepperApiSubscription
      :stepperDescription="contents.stepperDescription"
      :stepperSteps="stepperSteps"
    />

    <h4 class="subscribe-scopes-modal__title">
      {{ scopes.title }}
    </h4>

    <div class="subscribe-scopes-modal__scopes-list-container">
      <MLoader v-if="scopes.isLoading" />
      <div v-else class="subscribe-scopes-modal__scopes-list">
        <ScopeSelectionCard
          v-for="(scope, index) in scopes.items"
          :key="index"
          class="subscribe-scopes-modal__scope-card"
          :scope="scope"
          :modelValue="selectedScopes.includes(scope.name)"
          @onSelect="toggleScope(scope.name)"
        />
      </div>
    </div>
  </LayerModalFormConfirm>
</template>

<style lang="scss">
.subscribe-scopes-modal {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.subscribe-scopes-modal__scopes-list-container {
  flex-basis: 0;
  flex-grow: 1;
  padding-right: 0.8rem; // add some space for the scrollbar
  padding-bottom: 1px; // on some resolution, the border bottom of the last card gets cropped
  overflow-y: auto;
}
.subscribe-scopes-modal__scopes-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.subscribe-scopes-modal__scope-card {
  margin: 2px;
}
</style>
