<template>
  <div
    :class="[
      'toggle-button',
      {
        'toggle-button--active': modelValue,
        'toggle-button--disabled': disabled,
      },
    ]"
    @click.stop="onClick()"
  >
    <div class="toggle-button__cursor"></div>
  </div>
</template>

<script lang="ts">
export default {
  name: "ToggleButton",
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  methods: {
    onClick() {
      if (this.disabled) {
        return;
      }
      const value = !this.modelValue;
      this.$emit("update:modelValue", value);
    },
  },
};
</script>

<style lang="scss">
.toggle-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 52px;
  height: 28px;
  cursor: pointer;
  background-color: var(--color-light-grey);
  border-radius: 2rem;
}

.toggle-button--active {
  background-color: var(--color-primary);
  .toggle-button__cursor {
    margin-left: 25px;
    border-color: var(--color-primary);
  }
}

.toggle-button__cursor {
  width: 26px;
  height: 26px;
  margin-left: 1px;
  background-color: var(--color-white);
  border: var(--border-default);
  border-radius: 2rem;
  transition: all 0.1s ease-in;
}

.toggle-button--disabled {
  pointer-events: none;
  opacity: var(--default-opacity-disabled);
}
</style>
