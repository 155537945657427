export const KEY = {
  TAB: 9,
  SHIFT: 16,
  CTRL: 17,
  ESCAPE: 27,
  END: 35,
  HOME: 36,
  BACKSPACE: 8,
  DELETE: 46,
  ENTER: 13,
  ARROW_LEFT: 37,
  ARROW_UP: 38,
  ARROW_RIGHT: 39,
  ARROW_DOWN: 40,
  MINUS: 109,
  PLUS: 107,
  NUMPAD_ZERO: 96,
  NUMPAD_NINE: 105,
  DIGIT_ZERO: 48,
  DIGIT_NINE: 57,
};
