<script setup lang="ts">
import { computed } from "vue";
import { useStore } from "vuex";

import LayerModalFormConfirm from "@/commons/components/form/LayerModalFormConfirm.vue";
import Markdown from "@/documentation/components/commons/Markdown.vue";

import { convertRouteToHref } from "@/commons/utils/route-utils";

import { forcedDisableSecretSynchronization } from "@/commons/services/application/application-secret-synchronization.service";

import contents from "@/dashboard/contents/deactivate-secret-synchronization-modal";

const props = defineProps({
  applicationId: {
    type: String,
    required: true,
  },
  secretManager: {
    type: String,
    required: true,
  },
  secretPath: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["onClose"]);

const store = useStore();

const userDocumentationLink = computed(() => {
  return convertRouteToHref({
    name: "docsMain",
    hash: contents.userDocumentationAnchor,
  });
});

const submit = async () => {
  await forcedDisableSecretSynchronization(props.applicationId);

  loadApplication();

  emit("onClose");
};

const loadApplication = async (): Promise<void> => {
  await store.dispatch("loadCurrentApplication", {
    appId: props.applicationId,
  });
};
</script>

<template>
  <LayerModalFormConfirm
    class="failed-secret-sync-deactivation"
    :confirmBtnLabel="contents.confirmBtnCleanedSecretNamespace(secretManager)"
    @submit="submit"
    @close="emit('onClose')"
  >
    <div class="failed-secret-sync-deactivation__header">
      <h4>
        {{ contents.impossibleToCleanSecretNamespaceTitle(secretManager) }}
      </h4>
      <span class="failed-secret-sync-deactivation__subtitle">{{
        contents.subtitle
      }}</span>
    </div>

    <div class="failed-secret-sync-deactivation__content">
      <p>
        {{ contents.impossibleToCleanSecretNamespaceDescription(secretPath) }}
      </p>

      <Markdown
        :content="contents.solutions(secretManager, userDocumentationLink)"
      />
    </div>
  </LayerModalFormConfirm>
</template>

<style lang="scss">
.failed-secret-sync-deactivation {
  display: flex;
  flex-direction: column;
  gap: var(--base-spacing);
}
.failed-secret-sync-deactivation__header {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.failed-secret-sync-deactivation__subtitle {
  color: var(--color-status-danger);
}
</style>
