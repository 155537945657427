module.exports = {
  modalTitleForRateLimitForNewContracts:
    "Define a default quota for new consumers",
  modalTitleForApiRateLimit: "Define API quota",
  modalTitleForContractRateLimit: (applicationName) =>
    `Define the rate limit for ${applicationName}`,

  nbrOfRequest: "Number of request",
  nbrOfRequestHelpText: (maxRateLimit) =>
    `It is rounded down to the nearest ten and cannot exceed ${maxRateLimit}.`,
  frequency: "Frequency",
  unit: "Unit",
  cancel: "Cancel",
  submit: "Submit",
};
