<template>
  <div class="group-memberships-modal">
    <div
      v-if="isLocalGroup && userHasWriteAccessOnCurrentGroup"
      class="group-memberships-modal__add-user-section"
    >
      <h5>{{ contents.addUserToTheGroup }}</h5>

      <AddUser
        :roles="applicableRoles"
        :defaultRole="ERole.VIEWER"
        @userSubmitted="addUserToGroup"
      />
    </div>

    <div class="group-memberships-modal__user-list-section">
      <h5>{{ contents.userList }}</h5>

      <UsersList
        :groupId="groupId"
        :group="group"
        :users="usersList"
        :isLoading="isLoading"
        allowActions
        @onSelfRemoval="onSelfRemoval()"
        @groupUpdated="loadUsers()"
      />
    </div>
  </div>
</template>

<script lang="ts">
import AddUser from "@/commons/components/AddUser.vue";
import UsersList from "@/commons/components/ManageGroups/UsersList.vue";

import { getGroupWithUsersAclsRepository } from "@/commons/repositories/libs/get-group-with-users-acls-repository";
import { flagLoadingProcess } from "@/commons/utils/flagProcess.utils";
import {
  isLocalGroup,
  userHasWriteAccessOnGroup,
} from "@/commons/utils/group-utils";
import { ROLES_APPLICABLE_TO_GROUP } from "@/commons/utils/role.utils";

import { AclUser } from "@/commons/domain/models/acl-user";
import { CurrentUser } from "@/commons/domain/models/user";
import {
  findGroup,
  findGroupUsers,
} from "@/commons/services/group/group-users-service";

import { ERole } from "@/commons/store/types";

import contents from "@/commons/contents/group-memberships-modal";

export default {
  components: {
    AddUser,
    UsersList,
  },
  props: {
    groupId: {
      type: String,
      required: true,
    },
  },
  emits: ["onClose", "onSelfRemoval"],
  data() {
    return {
      group: undefined,
      usersList: [] as AclUser[],
      contents,
      ERole,
    };
  },
  computed: {
    user(): CurrentUser {
      return this.$store.getters["user"];
    },
    userHasWriteAccessOnCurrentGroup(): boolean {
      return this.group != undefined && userHasWriteAccessOnGroup(this.group);
    },
    applicableRoles(): ERole[] {
      return ROLES_APPLICABLE_TO_GROUP;
    },
    isLoading(): boolean {
      return (
        this.$store.getters["isLoadingProperty"]("findGroupUsers") ||
        this.$store.getters["isLoadingProperty"]("findGroup")
      );
    },
    isLocalGroup(): boolean {
      return this.group != undefined ? isLocalGroup(this.group) : false;
    },
  },
  async mounted() {
    await flagLoadingProcess("findGroup", async () => {
      this.group = await findGroup(this.groupId);
      this.usersList = Object.values(this.group.users);
    });
  },
  methods: {
    async loadUsers(): Promise<void> {
      this.usersList = await findGroupUsers(this.groupId);
    },
    async addUserToGroup({
      user,
      role,
    }: {
      user: AclUser;
      role: ERole;
    }): Promise<void> {
      this.group = await getGroupWithUsersAclsRepository().addUserAcl(
        this.groupId,
        user.id,
        role,
      );
      this.$store.commit("postSuccessNotification", {
        title: contents.userSuccessfullyAdded,
      });
      await this.loadUsers();
    },
    onSelfRemoval() {
      this.$emit("onSelfRemoval");
      this.$emit("onClose");
    },
  },
};
</script>

<style lang="scss">
.group-memberships-modal {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
</style>
