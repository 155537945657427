<template>
  <svg
    id="Calque_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    style="enable-background: new 0 0 24 24"
    xml:space="preserve"
    width="22px"
    height="22px"
  >
    <path
      d="M24,12c0-6.5-5.2-11.8-11.6-12H12h-0.4C5.2,0.2,0,5.5,0,12l0,0c0,6.6,5.4,12,12,12S24,18.6,24,12z M16.7,15.3
	c0.4,0.4,0.4,1,0,1.4c0,0,0,0,0,0C16.5,16.9,16.3,17,16,17c-0.3,0-0.5-0.1-0.7-0.3L12,13.4l-3.3,3.3C8.5,16.9,8.3,17,8,17
	c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4c0,0,0,0,0,0l3.3-3.3L7.3,8.7c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l3.3,3.3l3.3-3.3
	c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4L13.4,12L16.7,15.3z"
    />
  </svg>
</template>
