import { DropdownMenuItem } from "@/commons/store/types";

export function getUserMenuConf(): DropdownMenuItem {
  const items = [
    {
      label: "My groups",
      iconName: "Group24",
      urlOrRoute: {
        name: "groupsSettings",
      },
    },
    {
      label: "My profile",
      iconName: "AccountProfileView24",
      urlOrRoute: {
        name: "userProfile",
      },
    },
    {
      label: "Disconnect",
      iconName: "DisplayConnect24",
      emitEventOnClick: "logout",
    },
  ];

  return {
    items,
    realm: "user",
    mIconName: "AccountProfileView32",
  };
}
