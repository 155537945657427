import { App } from "vue";
import Hotjar from "vue-hotjar";

import { FrontendConfig } from "@/commons/domain/models/frontendConfig.type";

export async function setupHotjar(
  appInstance: App,
  frontendConfig: FrontendConfig,
) {
  if (frontendConfig.HOTJAR_ID != undefined) {
    try {
      appInstance.use(Hotjar, {
        id: frontendConfig.HOTJAR_ID,
      });
    } catch (error) {
      console.error(error);
    }
  }
}
