<template>
  <MLoader
    v-if="isLoadingRedirectUrisOfCurrentApplication"
    class="redirect-uri-settings-card__loader"
    size="l"
    :text="contents.loadingRedirectUris"
  />
  <CardContainer
    v-else-if="redirectUrisOfCurrentApplication?.length > 0"
    class="redirect-uri-settings-card"
  >
    <h4 class="redirect-uri-settings-card__card-title">
      {{ contents.redirectUrisTitle }}
    </h4>
    <Markdown :content="contents.redirectUrisDescription" />
    <MDataTable
      class="redirect-uri-settings-card__redirect-uris-listing"
      :sorting="{ mode: 'none' }"
      :headers="tableHeaders"
      :source="redirectUriItems"
    >
      <template #[`item.actions`]="{ item }">
        <ActionButtonsWrapper>
          <ButtonEdit
            v-if="userHasWriteAccessOnCurrentApplication"
            @click="onEditUriClick(item.value)"
          />
          <ButtonDelete
            v-if="
              userHasWriteAccessOnCurrentApplication &&
              redirectUriItems.length > 1
            "
            @click="onDeleteUriClick(item.value)"
          />
        </ActionButtonsWrapper>
      </template>
    </MDataTable>
    <div
      v-if="userHasWriteAccessOnCurrentApplication"
      class="redirect-uri-settings-card__redirect-uris-listing-actions"
    >
      <MButton
        :label="contents.addRedirectUriButton"
        type="button"
        theme="bordered"
        size="s"
        @click="onAddUriClick"
      />
    </div>
  </CardContainer>
</template>

<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import MDataTable from "@mozaic-ds/vue-3/src/components/datatable/MDataTable.vue";
import MLoader from "@mozaic-ds/vue-3/src/components/loader/MLoader.vue";

import ActionButtonsWrapper from "@/commons/components/Buttons/ActionButtonsWrapper.vue";
import ButtonDelete from "@/commons/components/Buttons/ButtonDelete.vue";
import ButtonEdit from "@/commons/components/Buttons/ButtonEdit.vue";
import CardContainer from "@/commons/components/CardContainer.vue";
import MessageConfirmModal from "@/commons/components/Modal/MessageConfirmModal.vue";
import { ModalClosedError } from "@/commons/components/Modal/modal.type";
import RedirectUriEditionModal from "@/dashboard/views/AppDetails/RedirectUriEditionModal.vue";
import Markdown from "@/documentation/components/commons/Markdown.vue";

import contentsOfRedirectUriEditionModal from "@/dashboard/contents/redirect-uri-edition-modal";
import contents from "@/dashboard/contents/redirect-uri-settings-card";

export default {
  components: {
    CardContainer,
    Markdown,
    MDataTable,
    ActionButtonsWrapper,
    ButtonEdit,
    ButtonDelete,
    MButton,
    MLoader,
  },
  data() {
    return {
      contents,
    };
  },
  computed: {
    currentApplication() {
      return this.$store.getters["currentApplication"];
    },
    redirectUrisOfCurrentApplication() {
      return this.$store.getters["redirectUrisOfCurrentApplication"];
    },
    isLoadingRedirectUrisOfCurrentApplication() {
      return this.$store.getters["isLoadingProperty"](
        "redirectUrisOfCurrentApplication",
      );
    },
    acfTokenOfCurrentApplication() {
      return this.$store.getters["acfTokenOfCurrentApplication"];
    },
    tableHeaders() {
      return [
        {
          caption: contents.redirectUriHeaderName,
          dataFieldExpr: "value",
        },
        {
          dataFieldExpr: "actions",
          cssClass: "redirect-uri-settings-card__action-column",
        },
      ];
    },
    // For usage with MDataTable. It requires to work with object items
    redirectUriItems() {
      return this.redirectUrisOfCurrentApplication.map((redirectUri) => ({
        value: redirectUri,
      }));
    },
    userHasWriteAccessOnCurrentApplication() {
      return this.$store.getters["userHasWriteAccessOnCurrentApplication"];
    },
  },
  methods: {
    async onEditUriClick(redirectUri: string): Promise<void> {
      const newRedirectUri =
        await this.openRedirectUriEditionModal(redirectUri);
      if (this.redirectUrisOfCurrentApplication.includes(newRedirectUri)) {
        this.$store.commit("postErrorNotification", {
          title: contents.errorRedirectUriAlreadyInList,
        });
      } else {
        const indexToReplace =
          this.redirectUrisOfCurrentApplication.indexOf(redirectUri);
        this.updateRedirectUrisOfCurrentApplication(
          this.redirectUrisOfCurrentApplication.toSpliced(
            indexToReplace,
            1,
            newRedirectUri,
          ),
        );
      }
    },
    async onAddUriClick(): Promise<void> {
      const newRedirectUri = await this.openRedirectUriEditionModal(undefined);
      if (this.redirectUrisOfCurrentApplication.includes(newRedirectUri)) {
        this.$store.commit("postErrorNotification", {
          title: contents.errorRedirectUriAlreadyInList,
        });
      } else {
        this.updateRedirectUrisOfCurrentApplication([
          ...this.redirectUrisOfCurrentApplication,
          newRedirectUri,
        ]);
      }
    },
    async onDeleteUriClick(redirectUri: string): Promise<void> {
      const deletionIsConfirmed = await this.openDeletionConfirmModal();

      if (deletionIsConfirmed) {
        const indexToRemove =
          this.redirectUrisOfCurrentApplication.indexOf(redirectUri);
        this.updateRedirectUrisOfCurrentApplication(
          this.redirectUrisOfCurrentApplication.toSpliced(indexToRemove, 1),
        );
      }
    },
    openRedirectUriEditionModal(
      initialRedirectUri: string | undefined,
    ): Promise<string> {
      return new Promise((resolve, reject) => {
        this.$store.commit("openLayerModal", {
          title: contentsOfRedirectUriEditionModal.modalTitle,
          component: RedirectUriEditionModal,
          props: {
            oauthProvider:
              this.acfTokenOfCurrentApplication.oauthDefinition.provider,
            initialRedirectUri,
          },
          listeners: {
            submit: (redirectUri: string) => resolve(redirectUri),
            onClose: () => reject(new ModalClosedError()),
          },
        });
      });
    },
    openDeletionConfirmModal(): Promise<boolean> {
      return new Promise((resolve, reject) => {
        this.$store.commit("openModal", {
          title: contents.deletionConfirmModalTitle,
          component: MessageConfirmModal,
          props: {
            title: contents.deletionConfirmModalMessageTitle,
            message: contents.deletionConfirmModalMessage,
            confirmBtnLabel: contents.deletionConfirmModalConfirmButtonLabel,
          },
          listeners: {
            onSubmit: () => {
              resolve(true);
              this.$store.commit("closeModal");
            },
            onClose: () => reject(new ModalClosedError()),
          },
        });
      });
    },
    loadRedirectUrisOfCurrentApplication() {
      this.$store.dispatch("loadRedirectUrisOfCurrentApplication");
    },
    updateRedirectUrisOfCurrentApplication(redirectUris: string[]) {
      this.$store.dispatch(
        "updateRedirectUrisOfCurrentApplication",
        redirectUris,
      );
    },
  },
};
</script>

<style lang="scss">
.redirect-uri-settings-card {
  border: 1px solid var(--color-stroke-light);
  border-radius: var(--m-border-radius-medium);
  box-shadow: none;
}
.redirect-uri-settings-card__loader {
  padding-top: 1rem;
}

.redirect-uri-settings-card__card-title {
  margin-top: 0;
  margin-bottom: 1.5rem;
}

.redirect-uri-settings-card__redirect-uris-listing {
  margin-top: 2rem;

  .mc-datatable__container {
    box-shadow: none;
  }
}

.redirect-uri-settings-card__action-column {
  width: 0;
}

.redirect-uri-settings-card__redirect-uris-listing-actions {
  display: flex;
  justify-content: flex-end;

  margin-top: 2rem;
}
</style>
