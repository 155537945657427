import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "product-detail-secondary-navbar__body" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "product-detail-secondary-navbar__apis-number" }
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { class: "product-detail-secondary-navbar__apis-number" }
const _hoisted_8 = ["href"]
const _hoisted_9 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Divider = _resolveComponent("Divider")!
  const _component_SecondaryNavbar = _resolveComponent("SecondaryNavbar")!

  return (_openBlock(), _createBlock(_component_SecondaryNavbar, { class: "product-detail-secondary-navbar" }, {
    "secondary-navbar-header": _withCtx(() => [
      ($props.product)
        ? (_openBlock(), _createElementBlock("h5", _hoisted_1, _toDisplayString($props.product.name), 1))
        : _createCommentVNode("", true)
    ]),
    "secondary-navbar-body": _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        ($props.product && $props.product.logoUrl)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: $props.product.logoUrl,
              alt: $props.product.name,
              class: "product-detail-secondary-navbar__logo"
            }, null, 8, _hoisted_3))
          : _createCommentVNode("", true),
        _createVNode(_component_Divider, {
          vertical: "",
          class: "product-detail-secondary-navbar__divider"
        }),
        ($props.product && $props.product.providedApis)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createTextVNode(_toDisplayString($data.contents.providing) + " ", 1),
              _createElementVNode("span", _hoisted_5, _toDisplayString($options.providedApisLength), 1),
              _createTextVNode(" " + _toDisplayString($options.pluralize($options.providedApisLength, "API", { shouldExcludeCount: true })), 1)
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_Divider, {
          vertical: "",
          class: "product-detail-secondary-navbar__divider"
        }),
        ($props.product && $props.product.usedApis)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createTextVNode(_toDisplayString($data.contents.using) + " ", 1),
              _createElementVNode("span", _hoisted_7, _toDisplayString($options.usedApisLength), 1),
              _createTextVNode(" " + _toDisplayString($options.pluralize($options.usedApisLength, "API", { shouldExcludeCount: true })), 1)
            ]))
          : _createCommentVNode("", true),
        ($options.userIsInternal && $props.product && $props.product.pageUrl)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
              _createVNode(_component_Divider, {
                vertical: "",
                class: "product-detail-secondary-navbar__divider"
              }),
              _createElementVNode("a", {
                target: "_blank",
                href: $props.product.pageUrl,
                class: "product-detail-secondary-navbar__tangram"
              }, [
                _createElementVNode("img", {
                  class: "product-detail-secondary-navbar__tangram-logo",
                  src: $data.tangramLogo,
                  alt: $data.contents.viewOnTangram
                }, null, 8, _hoisted_9),
                _createElementVNode("span", null, _toDisplayString($data.contents.viewOnTangram), 1)
              ], 8, _hoisted_8)
            ], 64))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}