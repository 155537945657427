<template>
  <div class="overlay-loader">
    <MLoader size="l" theme="dark" :text="message" />
  </div>
</template>

<script lang="ts">
import MLoader from "@mozaic-ds/vue-3/src/components/loader/MLoader.vue";

export default {
  name: "OverlayLoader",
  components: { MLoader },
  props: {
    message: {
      type: String,
      default: undefined,
    },
  },
};
</script>

<style lang="scss">
.overlay-loader {
  .mc-loader__spinner {
    width: 10rem;
    height: 10rem;
  }
}
</style>
