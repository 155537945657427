import { IsNotEmpty, MaxLength, MinLength } from "class-validator";

import { FormData } from "@/commons/domain/forms/form-data";
import { FormFactory } from "@/commons/domain/forms/form-factory";

export class CommentForm extends FormData {
  @IsNotEmpty()
  @MinLength(30)
  @MaxLength(500)
  comment: string = "";

  $clear() {
    this.comment = "";
  }

  static create() {
    return FormFactory.createForm(new CommentForm());
  }
}
