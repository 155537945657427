import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "application-settings" }
const _hoisted_2 = { class: "application-settings__col" }
const _hoisted_3 = { class: "application-settings__card-title" }
const _hoisted_4 = { class: "application-settings__col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UpdateApp = _resolveComponent("UpdateApp")!
  const _component_CardContainer = _resolveComponent("CardContainer")!
  const _component_SecretSynchronizationCard = _resolveComponent("SecretSynchronizationCard")!
  const _component_ApplicationSensitiveModifications = _resolveComponent("ApplicationSensitiveModifications")!
  const _component_AccessSettings = _resolveComponent("AccessSettings")!
  const _component_DeclareIPAddressesCard = _resolveComponent("DeclareIPAddressesCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CardContainer, { class: "application-settings__card-container" }, {
        default: _withCtx(() => [
          _createElementVNode("h4", _hoisted_3, _toDisplayString($data.contents.applicationSettings), 1),
          ($options.application)
            ? (_openBlock(), _createBlock(_component_UpdateApp, {
                key: 0,
                application: $options.application
              }, null, 8, ["application"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      ($options.displaySecretSynchronizationCard)
        ? (_openBlock(), _createBlock(_component_SecretSynchronizationCard, {
            key: 0,
            class: "application-settings__card-container",
            application: $options.application
          }, null, 8, ["application"]))
        : _createCommentVNode("", true),
      _createVNode(_component_ApplicationSensitiveModifications, {
        class: "application-settings__card-container",
        application: $options.application
      }, null, 8, ["application"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_AccessSettings),
      _createVNode(_component_DeclareIPAddressesCard, { class: "application-settings__card-container" })
    ])
  ]))
}