<script setup lang="ts">
import { markRaw } from "vue";
import { useStore } from "vuex";

import IconGroup from "@/commons/components/Icons/IconGroup.vue";
import GroupUsersModal from "@/commons/components/ManageGroups/GroupUsersModal.vue";

import contentsGroupUsersModal from "@/commons/contents/group-users-modal";

const props = defineProps({
  groupName: {
    type: String,
    required: true,
  },
  groupId: {
    type: String,
    required: true,
  },
  provider: {
    type: String,
    required: true,
  },
  isUserGroup: {
    type: Boolean,
    default: false,
  },
});

const store = useStore();

const showUsersModal = async () => {
  store.commit("openModal", {
    title: contentsGroupUsersModal.modalTitle,
    component: markRaw(GroupUsersModal),
    props: {
      groupName: props.groupName,
      groupId: props.groupId,
    },
  });
};
</script>

<template>
  <div class="group-name-with-link-to-users">
    <IconGroup :provider="provider" />
    <span class="group-name-with-link-to-users__link" @click="showUsersModal()">
      {{ groupName }}
    </span>
    <span v-if="isUserGroup" class="group-name-with-link-to-users__disabled"
      >(you)</span
    >
  </div>
</template>

<style lang="scss">
.group-name-with-link-to-users {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.group-name-with-link-to-users__link {
  text-decoration: underline;
  cursor: pointer;
}

.group-name-with-link-to-users__disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
</style>
