<template>
  <div class="divider" :class="dynamicClasses"></div>
</template>

<script lang="ts">
const validSizes = ["s", "m", "l"];

export default {
  props: {
    vertical: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "s",
      validator: (value) => validSizes.includes(value),
    },
  },
  computed: {
    dynamicClasses() {
      const typeClass = this.vertical
        ? "divider--vertical"
        : "divider--horizontal";

      const sizeClass = `divider--${this.size}`;

      return [typeClass, sizeClass];
    },
  },
};
</script>

<style lang="scss">
.divider {
  background-color: var(--color-stroke-light);
}

.divider--s {
  --divider-size: 1px;
}

.divider--m {
  --divider-size: 0.25rem;
}

.divider--l {
  --divider-size: 0.5rem;
}

.divider--vertical {
  width: var(--divider-size);
  height: 100%;
}

.divider--horizontal {
  width: 100%;
  height: var(--divider-size);
}
</style>
