<template>
  <button
    class="custom-button flex align-center justify-content-center"
    :class="{
      'is-dark': dark,
      'with-border': withBorder,
      'is-hover': hover,
      'is-selected': selected,
      round: round,
    }"
    tabindex="-1"
    type="button"
    @click.stop="$emit('click')"
    @focus="$emit('focus')"
    @blur="$emit('blur')"
    @mouseover="$emit('mouseover')"
    @mouseleave="$emit('mouseleave')"
  >
    <span :style="[bgStyle]" class="custom-button-effect" />
    <span
      class="custom-button-content flex align-center justify-content-center"
      :style="[colorStyle]"
    >
      <slot :style="[colorStyle]" />
    </span>
  </button>
</template>

<script lang="ts">
export default {
  name: "CustomButton",
  props: {
    color: { type: String, default: "dodgerblue" },
    dark: { type: Boolean, default: false },
    withBorder: { type: Boolean, default: false },
    hover: { type: Boolean, default: false },
    selected: { type: Boolean, default: false },
    round: { type: Boolean, default: false },
  },
  emits: ["click", "focus", "blur", "mouseover", "mouseleave"],
  computed: {
    colorStyle() {
      const color = this.dark ? "white" : this.color;
      return {
        color: color,
        fill: color,
      };
    },
    bgStyle() {
      return {
        backgroundColor: this.color,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-button {
  position: relative;
  height: 30px;
  padding: 0 20px;
  font-size: 13px;
  font-weight: 500;
  color: var(--color-white);
  cursor: pointer;
  background-color: var(--color-white);
  border: 1px solid transparent;
  border-radius: 4px;
  outline: none;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  &-content {
    position: relative;
  }
  svg {
    position: relative;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    fill: dodgerblue;
  }
  .custom-button-effect {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 30px;
    background: dodgerblue;
    border-radius: 4px;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    transform: scale(0);
  }
  &.with-border {
    border: 1px solid #eaeaea;
  }
  &:hover,
  &.is-hover {
    border: 1px solid transparent !important;
    .custom-button-effect {
      opacity: (0.6);
      transform: scale(1);
    }
    svg {
      fill: white !important;
    }
    .custom-button-content {
      color: var(--color-white) !important;
    }
  }
  &.is-selected {
    border: 1px solid transparent !important;
    .custom-button-effect {
      opacity: (1);
      transform: scale(1);
    }
    svg {
      fill: white !important;
    }
    .custom-button-content {
      color: var(--color-white) !important;
    }
  }
  &.is-dark {
    background-color: #424242;
    &.with-border {
      border-color: lighten(#424242, 20%);
    }
    svg {
      fill: white !important;
    }
  }
  &.round {
    width: 24px;
    height: 24px;
    padding: 0;
    border-radius: 50%;
    .custom-button-effect {
      height: 24px;
      border-radius: 50%;
    }
  }
}
</style>
