<template>
  <span class="icon-label" :class="dynamicClasses">
    <MIcon :name="iconName" />
    <span class="icon-label__label">{{ label }}</span>
  </span>
</template>

<script lang="ts">
import MIcon from "@mozaic-ds/vue-3/src/components/icon/MIcon.vue";

const validSizes = ["s", "m", "l"];

export default {
  components: {
    MIcon,
  },
  props: {
    iconName: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "m",
      validator: (value) => validSizes.includes(value),
    },
  },
  computed: {
    dynamicClasses(): string[] {
      const sizeClass = `icon-label--${this.size}`;

      return [sizeClass];
    },
  },
};
</script>

<style lang="scss">
.icon-label {
  display: inline-flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}

.icon-label--s {
  @include set-text-s;
}

.icon-label--m {
  @include set-text-m;
}

.icon-label--l {
  @include set-text-l;
}
</style>
