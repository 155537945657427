import { HttpRepository } from "@/commons/repositories/libs/http-repository";

import { Injectable } from "@/commons/domain/di/injectable";
import { BatchRepository } from "@/commons/domain/repositories/batch-repository";

@Injectable()
export class BatchHttpRepository
  extends HttpRepository
  implements BatchRepository
{
  public async findBatchesNames(): Promise<string[]> {
    const response = await this.requestHandler.get<string[]>(
      `/batch/names`,
      {},
    );
    return response.data;
  }

  public async runBatchManually(batchName: string): Promise<void> {
    await this.requestHandler.get<string[]>(`batch/run/${batchName}`, {});
  }
}
