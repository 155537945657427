import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MNotification = _resolveComponent("MNotification")!
  const _component_MTextInput = _resolveComponent("MTextInput")!
  const _component_MField = _resolveComponent("MField")!
  const _component_MTextArea = _resolveComponent("MTextArea")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_LayerModalForm = _resolveComponent("LayerModalForm")!

  return (_openBlock(), _createBlock(_component_LayerModalForm, {
    class: "request-timeout-modal",
    onSubmit: $options.onSubmit
  }, {
    fields: _withCtx(() => [
      ($options.isApiPublic($props.api))
        ? (_openBlock(), _createBlock(_component_MNotification, {
            key: 0,
            class: "timeout__limit-information",
            type: "information",
            title: $data.contents.timeoutLimitInformationTitle
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", {
                innerHTML: 
            $data.contents.timeoutLimitPublicApiInformation($data.MAX_PUBLIC_API_TIMEOUT)
          
              }, null, 8, _hoisted_1)
            ]),
            _: 1
          }, 8, ["title"]))
        : _createCommentVNode("", true),
      _createVNode(_component_MField, {
        id: "timeout-field",
        class: "request-timeout-modal__timeout-field",
        label: $data.contents.timeoutLabel,
        requirementText: "required",
        isInvalid: $options.isInvalidField('timeoutInSeconds'),
        errorMessage: $data.form.firstError('timeoutInSeconds')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_MTextInput, {
            type: "number",
            icon: "TimeTimeDecline24",
            isInvalid: $options.isInvalidField('timeoutInSeconds'),
            modelValue: $data.form.timeoutInSeconds,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ($data.form.timeoutInSeconds = Number($event)))
          }, null, 8, ["isInvalid", "modelValue"])
        ]),
        _: 1
      }, 8, ["label", "isInvalid", "errorMessage"]),
      _createVNode(_component_MField, {
        id: "message",
        class: "request-timeout-modal__message-field",
        label: $data.contents.messageLabel,
        helpId: $data.contents.messageHelpId,
        helpText: $data.contents.messageHelpText,
        requirementText: "required",
        isInvalid: $options.isInvalidField('message'),
        errorMessage: $data.form.firstError('message')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_MTextArea, {
            modelValue: $data.form.message,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.form.message) = $event)),
            placeholder: $data.contents.messagePlaceholder,
            isInvalid: $options.isInvalidField('message'),
            rows: 10
          }, null, 8, ["modelValue", "placeholder", "isInvalid"])
        ]),
        _: 1
      }, 8, ["label", "helpId", "helpText", "isInvalid", "errorMessage"])
    ]),
    formFooter: _withCtx(() => [
      _createVNode(_component_MButton, {
        class: "mc-button--bordered-neutral request-timeout-modal__cancel-button",
        label: $data.contents.cancel,
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('onClose')))
      }, null, 8, ["label"]),
      _createVNode(_component_MButton, {
        class: "request-timeout-modal__submit-button",
        type: "submit",
        disabled: !!$data.form.errors.length,
        label: $data.contents.submit
      }, null, 8, ["disabled", "label"])
    ]),
    _: 1
  }, 8, ["onSubmit"]))
}