import { Dictionary } from "@/commons/domain/models/dictionary";
import {
  DocumentationPage,
  EDocumentationType,
} from "@/commons/domain/models/documentation-page";
import { CreateDocumentationPageDto } from "@/commons/dtos/create-documentation-page-dto";
import { DocumentationPageDto } from "@/commons/dtos/documentation-page-dto";
import { UpdateDocumentationPageDto } from "@/commons/dtos/update-documentation-page-dto";

export class DocumentationPageMapper {
  static toDomain(
    documentationPagesDtos: DocumentationPageDto[],
  ): Dictionary<DocumentationPage> {
    const documentationPages: Dictionary<DocumentationPage> = {};

    for (const documentationPageDto of documentationPagesDtos) {
      documentationPages[documentationPageDto.id] =
        DocumentationPageMapper.toPageDomain(documentationPageDto);
    }
    return documentationPages;
  }

  static toPageDomain(
    documentationPageDto: DocumentationPageDto,
  ): DocumentationPage {
    return {
      id: documentationPageDto.id,
      updatedAt: documentationPageDto.updatedAt,
      isRemoved: documentationPageDto.isRemoved || false,
      url: documentationPageDto.url || "",
      title: documentationPageDto.title,
      description: documentationPageDto.description || "",
      type: documentationPageDto.type,
      content: documentationPageDto.content,
      extendedContent: documentationPageDto.extendedContent,
      order: documentationPageDto.order,
      isPublished: documentationPageDto.isPublished,
      lastJobStatus: documentationPageDto.lastJobStatus,
      errorMessages: documentationPageDto.errorMessages,
      allowedActions: documentationPageDto.allowedActions,
    };
  }

  static toUpdateDocumentationPageDto(
    type: EDocumentationType,
    title: string,
    description: string,
    content: string,
    url: string,
    isPublished: boolean,
    order: number,
  ): UpdateDocumentationPageDto {
    return {
      type,
      title,
      description,
      content: (!url && content) || null,
      url: (url !== "" && url) || null,
      isPublished,
      order,
    };
  }

  static toCreateDocumentationPageDto(
    apiId: string,
    type: EDocumentationType,
    title: string,
    description: string,
    content: string,
    url: string,
  ): CreateDocumentationPageDto {
    return {
      apiId,
      type,
      title,
      description,
      content: (!url && content) || null,
      url: (url !== "" && url) || null,
    };
  }
}
