import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "reporting-application"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MNotification = _resolveComponent("MNotification")!
  const _component_ReportingApplicationFilters = _resolveComponent("ReportingApplicationFilters")!
  const _component_HistogramApplication = _resolveComponent("HistogramApplication")!

  return (!$options.isLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        ($options.errorMessage)
          ? (_openBlock(), _createBlock(_component_MNotification, {
              key: 0,
              type: "danger"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($options.errorMessage), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (!$options.errorMessage && !$options.isFetchingReporting && $options.hits.length === 0)
          ? (_openBlock(), _createBlock(_component_MNotification, {
              key: 1,
              type: "warning"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($data.contents.errorNoTraffic), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (
        !$options.errorMessage &&
        !$options.isFetchingReporting &&
        $options.currentApplicationContractsList.length === 0
      )
          ? (_openBlock(), _createBlock(_component_MNotification, {
              key: 2,
              type: "warning"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($data.contents.errorNotSubscribeToApi), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_ReportingApplicationFilters, {
          class: "reporting__filters",
          applicationId: $props.id,
          onUpdateSelectedFilters: $options.changeUrlQuery
        }, null, 8, ["applicationId", "onUpdateSelectedFilters"]),
        ($options.hits.length > 0 && !$options.isFetchingReporting)
          ? (_openBlock(), _createBlock(_component_HistogramApplication, {
              key: 3,
              applicationId: $props.id,
              onUpdateSelectedFilters: $options.changeUrlQuery
            }, null, 8, ["applicationId", "onUpdateSelectedFilters"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}