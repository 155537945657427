<script setup lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import { markRaw, PropType } from "vue";
import { useStore } from "vuex";

import CardContainer from "@/commons/components/CardContainer.vue";
import DeleteApplicationModal from "@/dashboard/views/AppDetails/DeleteApplicationModal.vue";

import { Application } from "@/commons/domain/models/application";

import contents from "@/dashboard/contents/application";

const props = defineProps({
  application: {
    type: Object as PropType<Application>,
    required: true,
  },
});

const store = useStore();

const deleteApplication = () => {
  store.commit("openLayerModal", {
    title: contents.deleteApplication,
    component: markRaw(DeleteApplicationModal),
    props: {
      applicationId: props.application.id,
      applicationName: props.application.name,
      secretSynchronization: props.application.secretSynchronization,
    },
  });
};
</script>

<template>
  <CardContainer class="application-sensitive-settings">
    <h4 class="application-sensitive-settings__title">
      {{ contents.sensitiveModifications }}
    </h4>
    <div class="application-sensitive-settings__container">
      <div class="application-sensitive-settings__content">
        <span class="application-sensitive-settings__content-title">
          {{ contents.deleteApplication }}
        </span>
        <span class="application-sensitive-settings__content-subtitle">
          {{ contents.notGoBack }}
        </span>
      </div>
      <div class="application-sensitive-settings__content-button">
        <MButton
          data-cy="btn-delete-application"
          :label="contents.delete"
          theme="bordered-danger"
          @click="deleteApplication"
        />
      </div>
    </div>
  </CardContainer>
</template>

<style lang="scss">
.application-sensitive-settings {
  display: flex;
  flex-direction: column;
  gap: var(--base-spacing);
}

.application-sensitive-settings__content {
  display: flex;
  flex-direction: column;
}

.application-sensitive-settings__container {
  display: flex;
  justify-content: space-between;
}

.application-sensitive-settings__content-title {
  font-weight: 700;
}
</style>
