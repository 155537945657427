import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MStepper = _resolveComponent("MStepper")!

  return (_openBlock(), _createBlock(_component_MStepper, _mergeProps({
    "data-cy": "stepper",
    class: "dvp-stepper",
    steps: $props.steps,
    accessibilityLabels: $options.stepAccessibilityLabels
  }, _ctx.$attrs), null, 16, ["steps", "accessibilityLabels"]))
}