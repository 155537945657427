import { Cors } from "@/commons/domain/models/cors";
import { CorsDto } from "@/commons/dtos/cors-dto";

function buildValueWithId(values: string[]) {
  const valuesWithId: Array<{ id: number; value: string }> = [];

  for (const [id, value] of values.entries()) {
    valuesWithId.push({
      id,
      value,
    });
  }

  return valuesWithId;
}

export class CorsMapper {
  static toDomain(corsDto: CorsDto): Cors {
    return {
      isEnabled: corsDto.isEnabled,
      allowOrigins: buildValueWithId(corsDto.allowOrigins),
      allowMethods: corsDto.allowMethods,
      allowHeaders: buildValueWithId(corsDto.allowHeaders),
      allowCredentials: corsDto.allowCredentials,
    };
  }

  static toDto(cors: Cors): CorsDto {
    return {
      isEnabled: cors.isEnabled,
      allowOrigins: cors.allowOrigins.map((origin) => origin.value),
      allowMethods: cors.allowMethods,
      allowHeaders: cors.allowHeaders.map((header) => header.value),
      allowCredentials: cors.allowCredentials,
    };
  }
}
