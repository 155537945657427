import { Injectable } from "@/commons/domain/di/injectable";
import { HumanReadableUrl } from "@/commons/domain/services/human-readable-url";

@Injectable()
export class SlugifyUrl implements HumanReadableUrl {
  get(rawUrl: string) {
    if (rawUrl) {
      return rawUrl
        .toString()
        .toLowerCase()
        .replace(/\s+/g, "-") // Replace spaces with -
        .replace(/[^\w-]+/g, "") // Remove all non-word chars
        .replace(/--+/g, "-") // Replace multiple - with single -
        .replace(/^-+/, "") // Trim - from start of text
        .replace(/-+$/, ""); // Trim - from end of text
    } else return "";
  }
}
