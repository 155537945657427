import {
  ValidationArguments,
  ValidatorConstraint,
  ValidatorConstraintInterface,
} from "class-validator";

@ValidatorConstraint({ name: "FieldValueIsUniqueInList", async: false })
export class FieldValueIsUniqueInList implements ValidatorConstraintInterface {
  validate(item: { [key: string]: any }, args: ValidationArguments) {
    const itemsListName = args.constraints[1];
    const fieldName = args.constraints[0];
    const items = args.object[itemsListName];

    for (const itemInList of items) {
      if (
        itemInList[fieldName] === item[fieldName] &&
        itemInList.id !== item.id
      ) {
        return false;
      }
    }

    return true;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  defaultMessage(args: ValidationArguments) {
    return `Already exists this value`;
  }
}
