import { IsAscii } from "class-validator";

import { FormData } from "@/commons/domain/forms/form-data";
import { FormFactory } from "@/commons/domain/forms/form-factory";
import { CorsHeader } from "@/commons/domain/models/cors-header";

export class CorsHeaderForm extends FormData {
  id: string;

  @IsAscii({
    message:
      "Please use characters A to Z, 0 to 9, and simple punctuation only.",
  })
  value: string;

  constructor(corsHeader: CorsHeader) {
    super();
    this.$update(corsHeader);
  }

  async $clear() {
    this.value = "";
  }

  static create(
    corsHeader: CorsHeader = {
      id: null,
      value: "",
    },
  ) {
    return FormFactory.createForm(new CorsHeaderForm(corsHeader));
  }
}
