import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "partner-login-help-modal__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Markdown = _resolveComponent("Markdown")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_GlobalModalBody = _resolveComponent("GlobalModalBody")!

  return (_openBlock(), _createBlock(_component_GlobalModalBody, { class: "partner-login-help-modal" }, {
    content: _withCtx(() => [
      _createElementVNode("h5", _hoisted_1, _toDisplayString($data.contents.partnerLoginHelpTitle), 1),
      _createVNode(_component_Markdown, {
        content: $data.contents.partnerLoginHelpContent
      }, null, 8, ["content"])
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_MButton, {
        theme: "bordered-neutral",
        label: $data.contents.closeModalButton,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onClose')))
      }, null, 8, ["label"])
    ]),
    _: 1
  }))
}