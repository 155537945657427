import {
  IsNotEmpty,
  Matches,
  MaxLength,
  MinLength,
  ValidateIf,
} from "class-validator";

import { FormData } from "@/commons/domain/forms/form-data";
import { FormFactory } from "@/commons/domain/forms/form-factory";
import {
  ELEMENT_REGEX_EXPRESSION,
  SUB_ELEMENT_REGEX_EXPRESSION,
} from "@/manager/domain/forms/create-oauth-scope-form";

import contents from "@/manager/contents/add-oauth-scopes";

export class CreateOAuthScopeForm extends FormData {
  @IsNotEmpty()
  @MinLength(3)
  @MaxLength(50)
  name: string = "";

  @IsNotEmpty()
  @MinLength(30)
  @MaxLength(500)
  description: string = "";

  $clear() {
    this.name = "";
    this.description = "";
  }

  static create() {
    return FormFactory.createForm(new CreateOAuthScopeForm());
  }
}

export class CreateScopeNameForm extends FormData {
  @IsNotEmpty({ message: contents.scopeElementRequiredErrorMessage })
  @Matches(new RegExp(ELEMENT_REGEX_EXPRESSION), {
    message: contents.scopeElementCharactersErrorMessage,
  })
  element: string = "";

  @ValidateIf((form) => form.subElement.length > 0)
  @Matches(new RegExp(SUB_ELEMENT_REGEX_EXPRESSION), {
    message: contents.scopeSubElementCharactersErrorMessage,
  })
  subElement: string = "";

  businessUnit: string = "";

  @IsNotEmpty({ message: contents.scopeActionRequiredErrorMessage })
  action: string = "";

  $clear() {
    this.element = "";
    this.subElement = "";
    this.businessUnit = "";
    this.action = "";
  }

  static create() {
    return FormFactory.createForm(new CreateScopeNameForm());
  }
}
