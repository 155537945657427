<template>
  <svg
    version="1.1"
    class="icon-restriction-private"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="icon-restriction-private"
    viewBox="0 -5 16 24"
    height="56"
  >
    <path
      fill="#999"
      d="M12.1,6.05v-2a4.1,4.1,0,0,0-8.2,0v2H2.16v6.64A3.31,3.31,0,0,0,5.47,16h5.06a3.32,3.32,0,0,0,3.31-3.31V6.05Zm-6.2-2a2.1,2.1,0,0,1,4.2,0v2H5.9Zm5.94,8.6A1.31,1.31,0,0,1,10.53,14H5.47a1.31,1.31,0,0,1-1.31-1.31V8.05h7.68Z"
    />
  </svg>
</template>
