import { getScopeRepository } from "@/commons/repositories/libs/get-scope-repository";

import { Scope } from "@/commons/domain/models/scope";

/**
 * Returns an error message if the scope was not updated
 * @param apiId
 * @param name
 * @param description
 */
export async function updateScopeDescription(
  apiId: string,
  name: string,
  description: string,
): Promise<Scope> {
  return await getScopeRepository().updateScopeDescription(
    apiId,
    name,
    description,
  );
}
