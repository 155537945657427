import { ArrayNotEmpty } from "class-validator";
import { v4 as uuid } from "uuid";

import { FormData } from "@/commons/domain/forms/form-data";
import { FormFactory } from "@/commons/domain/forms/form-factory";
import { RoutingPolicy } from "@/commons/domain/models/routing-policy";

export class TargetUrlForm extends FormData {
  id: string;

  routingPolicies: RoutingPolicy[];

  url: string;

  @ArrayNotEmpty({
    message: "You need to select a gateway to save the route.",
  })
  zoneIds: string[];

  constructor() {
    super();
    this.id = uuid();
  }

  $clear() {
    this.routingPolicies = [];
    this.url = "";
    this.zoneIds = [];
  }

  static create() {
    return FormFactory.createForm(new TargetUrlForm());
  }
}
