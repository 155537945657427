<template>
  <LayerModalFormConfirm
    class="group-edition-modal"
    confirmBtnTheme="solid"
    :disabledConfirmBtn="form.pristine || form.errors.length > 0 || isSaving"
    @submit="submit"
    @close="close"
  >
    <DvpField
      required
      :label="contents.nameField"
      :errorMessage="form.firstError('name')"
    >
      <MTextInput
        v-model="form.name"
        :placeholder="contents.namePlaceholder"
        :isInvalid="form.hasError('name')"
      />
    </DvpField>

    <DvpField
      :label="contents.descriptionField"
      :errorMessage="form.firstError('description')"
    >
      <MTextArea
        v-model="form.description"
        class="group-edition-modal__description-textarea"
        :placeholder="contents.descriptionPlaceholder"
        :isInvalid="form.hasError('description')"
      />
    </DvpField>

    <template v-if="form.needManagerField">
      <DvpField
        required
        :label="contents.managerField"
        :errorMessage="form.firstError('administrator')"
      >
        <div
          v-if="form.administrator"
          class="group-edition-modal__selected-group-manager"
        >
          <MIcon name="AccountProfileView24" />
          <span class="group-edition-modal__selected-group-manager-name">{{
            form.administrator.name
          }}</span>
          <IconButton
            class="group-edition-modal__group-manager-removal-button"
            mozaicIconName="ControlCircleCross24"
            color="var(--color-status-danger)"
            @click="form.administrator = undefined"
          />
        </div>

        <AddUser
          v-else
          :canChangeAction="false"
          @userSubmitted="form.administrator = $event.user"
        />
      </DvpField>
    </template>
    <NotificationWarningMCCGroup v-if="displayNotificationMCCGroup" />
  </LayerModalFormConfirm>
</template>

<script lang="ts">
import MIcon from "@mozaic-ds/vue-3/src/components/icon/MIcon.vue";
import MTextArea from "@mozaic-ds/vue-3/src/components/textarea/MTextArea.vue";
import MTextInput from "@mozaic-ds/vue-3/src/components/textinput/MTextInput.vue";
import { PropType } from "vue";

import AddUser from "@/commons/components/AddUser.vue";
import IconButton from "@/commons/components/IconButton.vue";
import NotificationWarningMCCGroup from "@/commons/components/ManageGroups/NotificationWarningMCCGroup.vue";
import DvpField from "@/commons/components/form/DvpField.vue";
import LayerModalFormConfirm from "@/commons/components/form/LayerModalFormConfirm.vue";

import { CreateGroupForm } from "@/commons/domain/forms/create-group-form";
import { Group } from "@/commons/domain/models/group";
import { User } from "@/commons/domain/models/user";

import { ERole } from "@/commons/store/types";

import contents from "@/commons/contents/group-edition-modal";

enum EGroupEditionMode {
  CREATION,
  UPDATE,
}

export default {
  components: {
    NotificationWarningMCCGroup,
    DvpField,
    LayerModalFormConfirm,
    IconButton,
    MTextInput,
    MTextArea,
    AddUser,
    MIcon,
  },
  props: {
    group: {
      type: Object as PropType<Group>,
      default: () => {},
    },
  },
  emits: ["groupUpdated", "onClose"],
  data() {
    return {
      form: CreateGroupForm.create(),
      contents,
    };
  },
  computed: {
    userIsAdmin(): boolean {
      return this.$store.getters["userIsAdmin"];
    },
    userIsInternal(): boolean {
      return this.$store.getters["userIsInternal"];
    },
    isSaving(): boolean {
      return this.$store.getters["isSaving"];
    },
    editionMode(): EGroupEditionMode {
      return this.group?.id != undefined
        ? EGroupEditionMode.UPDATE
        : EGroupEditionMode.CREATION;
    },
    displayNotificationMCCGroup(): boolean {
      return (
        this.userIsInternal && this.editionMode === EGroupEditionMode.CREATION
      );
    },
  },
  created() {
    this.initForm();
  },
  methods: {
    initForm(): void {
      if (this.editionMode === EGroupEditionMode.CREATION) {
        this.form.init({
          needManagerField: this.userIsAdmin,
        });
      } else {
        const { name, description } = this.group;
        this.form.init({
          needManagerField: false,
          name,
          description,
        });
      }
    },
    async createGroup({
      name,
      description,
      administrator,
    }: {
      name: string;
      description?: string;
      administrator?: User;
    }): Promise<void> {
      await this.$store.dispatch("createUserGroup", {
        name,
        description,
        administratorId: administrator?.id,
        roleId: ERole.MANAGER,
      });
    },
    async updateGroup({
      id,
      name,
      description,
    }: {
      id: string;
      name: string;
      description?: string;
    }): Promise<void> {
      await this.$store.dispatch("updateUserGroup", {
        id,
        name,
        description,
      });
    },
    async submit() {
      await this.form.validate();

      if (this.form.errors.length === 0 && !this.isSaving) {
        if (this.editionMode === EGroupEditionMode.CREATION) {
          await this.createGroup(this.form.data());
        } else {
          await this.updateGroup({ ...this.form.data(), id: this.group.id });
        }

        this.$emit("groupUpdated");
        this.close();
      }
    },
    close() {
      this.$emit("onClose");
    },
  },
};
</script>

<style lang="scss">
.group-edition-modal__description-textarea {
  min-height: 10rem;
}

.group-edition-modal__selected-group-manager {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
</style>
