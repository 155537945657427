import { registerDecorator, ValidationOptions } from "class-validator";

import { URLPatternConstraint } from "@/manager/domain/validation/url-pattern-constraint";

export function URLPattern(validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      constraints: [],
      validator: URLPatternConstraint,
    });
  };
}
