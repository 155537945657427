module.exports = {
  moreFiltersHeader: "More filters",
  searchProduct: "Search a product",
  productsLabel: "Tangram Product",
  platformsLabel: "Platform/Community",
  searchPlatforms: "Search a platform",
  buLabel: "Business Units",
  searchBusinessUnit: "All business units",
  privacyLabel: "Privacy",
  publicationZoneLabel: "Publication zone",
  searchPublicationZones: "Search a publication zone",
  accessTypeLabel: "Access type",
  confirmButtonLabel: "Validate",
};
