import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  ref: "subscribable-api-card-list",
  class: "subscribable-api-card-list"
}
const _hoisted_2 = { class: "subscribable-api-card-list__no-api-card-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ApiListItemLoadingSkeleton = _resolveComponent("ApiListItemLoadingSkeleton")!
  const _component_IconEmptyContent = _resolveComponent("IconEmptyContent")!
  const _component_CardListed = _resolveComponent("CardListed")!
  const _component_SubscribableApiCard = _resolveComponent("SubscribableApiCard")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    ($options.isLoadingSubscribableApis)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($options.loadingItems, (item, index) => {
          return (_openBlock(), _createBlock(_component_ApiListItemLoadingSkeleton, {
            key: `loading-subscribable-api-${index}`
          }))
        }), 128))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          ($props.apisList.length === 0)
            ? (_openBlock(), _createBlock(_component_CardListed, {
                key: 0,
                class: "subscribable-api-card-list__no-api-card disabled"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_IconEmptyContent),
                    _createElementVNode("span", null, _toDisplayString($data.contents.emptySubscribableApisList), 1)
                  ])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.apisList, (api) => {
            return (_openBlock(), _createBlock(_component_SubscribableApiCard, {
              key: api.id,
              api: api
            }, null, 8, ["api"]))
          }), 128))
        ], 64))
  ], 512))
}