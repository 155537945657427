<template>
  <ConfirmModalBody
    class="confirm-internet-exposure-modal"
    :confirmBtnLabel="contents.confirm"
    @onSubmit="$emit('onSubmit')"
    @onClose="$emit('onClose')"
  >
    <p class="confirm-internet-exposure-modal__risk-warning">
      {{ contents.exposureRiskWarning }}
    </p>
    <p class="confirm-internet-exposure-modal__policy-mention">
      {{ contents.policyMention }}
    </p>

    <MNotification
      v-if="currentTimeoutInSeconds > MAX_PUBLIC_API_TIMEOUT"
      class="confirm-internet-exposure-modal__max-public-api-timeout-notification"
      :title="
        contents.maxPublicApiTimeoutNotificationTitle(MAX_PUBLIC_API_TIMEOUT)
      "
    >
      <span
        v-html="
          contents.maxPublicApiTimeoutNotificationContent(
            currentTimeoutInSeconds,
            MAX_PUBLIC_API_TIMEOUT,
          )
        "
      />
    </MNotification>
  </ConfirmModalBody>
</template>

<script lang="ts">
import MNotification from "@mozaic-ds/vue-3/src/components/notification/MNotification.vue";

import ConfirmModalBody from "@/commons/components/Modal/ConfirmModalBody.vue";

import { MAX_PUBLIC_API_TIMEOUT } from "@/manager/domain/forms/timeout";

import contents from "@/manager/contents/confirm-internet-exposure-modal";

export default {
  components: {
    MNotification,
    ConfirmModalBody,
  },
  props: {
    currentTimeoutInSeconds: {
      type: Number,
      required: true,
    },
  },
  emits: ["onSubmit", "onClose"],
  data() {
    return {
      contents,
      MAX_PUBLIC_API_TIMEOUT,
    };
  },
};
</script>

<style>
.confirm-internet-exposure-modal__risk-warning {
  font-weight: 700;
}
</style>
