<template>
  <LayerModalFormConfirm
    class="contract-oauth-flow-selection-modal"
    confirmBtnTheme="solid"
    :disabledConfirmBtn="selectedFlow == undefined"
    :confirmBtnLabel="confirmBtnLabel"
    @submit="submit"
    @close="close"
  >
    <StepperApiSubscription
      v-if="selectedFlow != undefined"
      :stepperDescription="contents.stepperDescription"
      :stepperSteps="stepperSteps"
    />
    <div class="contract-oauth-flow-selection-modal__description-section">
      <h4 class="contract-oauth-flow-selection-modal__title">
        {{ contents.title }}
      </h4>

      <Markdown
        class="contract-oauth-flow-selection-modal__description"
        :content="contents.description"
      />
    </div>

    <ViewHelpButton
      class="contract-oauth-flow-selection-modal__view-help-button"
      documentationAnchor="#oauth-flows"
    />

    <OauthFlowOptionCard
      v-for="(flowItem, index) in oauthFlowItems"
      :key="index"
      :flowItem="flowItem"
      checkboxType="radio"
      :modelValue="selectedFlow === flowItem.value"
      @update:modelValue="selectedFlow = flowItem.value"
    />
  </LayerModalFormConfirm>
</template>

<script lang="ts">
import ViewHelpButton from "@/commons/components/UserDocumentationLinks/ViewHelpButton.vue";
import LayerModalFormConfirm from "@/commons/components/form/LayerModalFormConfirm.vue";
import StepperApiSubscription from "@/dashboard/views/AppDetailsContracts/StepperApiSubscription.vue";
import Markdown from "@/documentation/components/commons/Markdown.vue";
import OauthFlowOptionCard from "@/manager/views/ApiDetailAuthentication/OauthFlowOptionCard.vue";

import { OAUTH_FLOW_ITEMS } from "@/manager/utils/oauth-flow-items";

import { EOauthFlow } from "@/commons/types/oauth-flow-types";

import contents from "@/dashboard/contents/contract-oauth-flow-selection-modal";
import contentSubscriptionStepper from "@/dashboard/contents/subscription-stepper";

export default {
  components: {
    StepperApiSubscription,
    ViewHelpButton,
    LayerModalFormConfirm,
    OauthFlowOptionCard,
    Markdown,
  },
  props: {
    apiProvidesScopes: {
      type: Boolean,
      required: true,
    },
    requiresManagersValidation: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["submit", "onClose"],
  data() {
    return {
      contents,
      selectedFlow: undefined as EOauthFlow,
    };
  },
  computed: {
    oauthFlowItems() {
      // Dirty override because items contents is slightly different on this modal.
      // If the modal items become completely custom, it may be more relevant to create separate items
      return OAUTH_FLOW_ITEMS.map((item) => ({
        ...item,
        title: contents.oauthFlowItemsOverride[item.value].title,
      }));
    },
    acfTokenOfCurrentApplication() {
      return this.$store.getters["acfTokenOfCurrentApplication"];
    },
    applicationDoesNotHaveAcfToken(): boolean {
      return this.acfTokenOfCurrentApplication == undefined;
    },
    stepperSteps() {
      const steps = [
        {
          label: contentSubscriptionStepper.stepSelectFlowTitle,
          isCurrent: true,
        },
      ];

      if (
        this.selectedFlow === EOauthFlow.AUTHORIZATION_CODE &&
        this.applicationDoesNotHaveAcfToken
      ) {
        steps.push({
          label: contentSubscriptionStepper.stepSetFirstRedirectUriTitle,
          isCurrent: false,
        });
      }

      if (this.apiProvidesScopes) {
        steps.push({
          label: contentSubscriptionStepper.stepSelectScopesTitle,
          isCurrent: false,
        });
      }

      if (this.requiresManagersValidation) {
        steps.push({
          label: contentSubscriptionStepper.stepSendValidationRequestTitle,
          isCurrent: false,
        });
      }

      return steps;
    },
    confirmBtnLabel() {
      return this.stepperSteps?.length > 1
        ? contentSubscriptionStepper.nextStepBtnLabel
        : undefined; // use default confirm button label
    },
  },
  methods: {
    submit(): void {
      this.$emit("submit", this.selectedFlow);
      this.close();
    },
    close(): void {
      this.$emit("onClose");
    },
  },
};
</script>

<style lang="scss">
.contract-oauth-flow-selection-modal__description-section {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.contract-oauth-flow-selection-modal__title {
  margin: 0;
}

.contract-oauth-flow-selection-modal__view-help-button {
  align-self: flex-start;
}
</style>
