<script setup lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import MNotification from "@mozaic-ds/vue-3/src/components/notification/MNotification.vue";
import MTag from "@mozaic-ds/vue-3/src/components/tags/MTag.vue";
import MTextArea from "@mozaic-ds/vue-3/src/components/textarea/MTextArea.vue";
import { PropType, computed, reactive } from "vue";
import { onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import CardContainer from "@/commons/components/CardContainer.vue";
import ViewDocumentationLink from "@/commons/components/UserDocumentationLinks/ViewDocumentationLink.vue";
import DvpField from "@/commons/components/form/DvpField.vue";
import Markdown from "@/documentation/components/commons/Markdown.vue";

import { pluralize } from "@/commons/utils/contents-utils";
import { buildScopesProductsFetchParams } from "@/manager/utils/build-scopes-products-fetch-params";

import { Product } from "@/commons/domain/models/product";
import { Scope } from "@/commons/domain/models/scope";
import { updateScopeDescription } from "@/commons/services/scope/scope-service";

import contents from "@/manager/contents/add-oauth-scopes";

const props = defineProps({
  scope: {
    type: Object as PropType<Scope>,
    required: true,
  },
});
const emit = defineEmits(["showScopeProducts", "switchEditMode"]);

const store = useStore();
const route = useRoute();
const apiId = route.params.apiId as string;

const scopeHasLinkedProducts = computed(
  (): boolean =>
    props.scope.totalProductsCount != undefined &&
    props.scope.totalProductsCount > 0,
);

const modifyDescriptionHandler = reactive({
  /**
   * Workflow steps:
   * isEditing: description modification form is displayed
   * updatedScope: updated scope entity saved on backend (PING) side, permits to re-modify the description
   */
  isEditing: false as boolean,
  isSaving: false as boolean,
  updatedScope: undefined as Scope | undefined,
  /**
   * Description form validation
   */
  MIN_LENGTH_DESCRIPTION: 30 as number,
  MAX_LENGTH_DESCRIPTION: 500 as number,
  errorMessage: computed((): string | undefined => {
    if (
      modifyDescriptionHandler.content.length <
      modifyDescriptionHandler.MIN_LENGTH_DESCRIPTION
    ) {
      return contents.minLengthDescriptionErrorMessage;
    } else if (
      modifyDescriptionHandler.content.length >
      modifyDescriptionHandler.MAX_LENGTH_DESCRIPTION
    ) {
      return contents.maxLengthDescriptionErrorMessage;
    }
    return undefined;
  }),
  /**
   * Description content model handling
   * content: v-model for the description textarea
   * pristine: true if description content was not modified
   */
  content: "" as string,
  pristine: computed((): boolean => {
    const descriptionToCompare =
      modifyDescriptionHandler.updatedScope == undefined
        ? props.scope.description
        : modifyDescriptionHandler.updatedScope.description;
    return modifyDescriptionHandler.content === descriptionToCompare;
  }),
  /**
   * Actions
   */
  clear: (): void => {
    modifyDescriptionHandler.content = "";
  },
  edit: (scope: Scope): void => {
    modifyDescriptionHandler.switchEditMode(true);

    if (modifyDescriptionHandler.updatedScope != undefined) {
      modifyDescriptionHandler.content =
        modifyDescriptionHandler.updatedScope.description;
    } else {
      modifyDescriptionHandler.content = scope.description;
    }
  },
  validate: async (): Promise<void> => {
    modifyDescriptionHandler.isSaving = true;
    modifyDescriptionHandler.updatedScope = await updateScopeDescription(
      apiId,
      props.scope.name,
      modifyDescriptionHandler.content,
    );
    if (modifyDescriptionHandler.updatedScope != undefined) {
      store.commit("postSuccessNotification", {
        title: contents.successfullyUpdatedScopeDescriptionMessage,
      });
    }
    modifyDescriptionHandler.isSaving = false;
    modifyDescriptionHandler.switchEditMode(false);
  },
  cancel: (): void => {
    modifyDescriptionHandler.switchEditMode(false);
    modifyDescriptionHandler.clear();
  },
  switchEditMode: (value: boolean): void => {
    modifyDescriptionHandler.isEditing = value;
    emit("switchEditMode", value);
  },
});

const impactedProductsHandler = reactive({
  items: computed(() => store.getters["scopesProductsList"]),
  hasItems: computed(
    () =>
      impactedProductsHandler.items != undefined &&
      impactedProductsHandler.items.length > 0,
  ),
  loadProductPage: async () => {
    await store.dispatch(
      "loadScopesProducts",
      buildScopesProductsFetchParams(props.scope.name, 1, 100),
    );
  },
  isLoading: computed(() => {
    return store.getters["isLoadingProperty"]("scopesProducts");
  }),
  warningContent: undefined,
  updateWarningContent: (): void => {
    let content: string = contents.warningImpactedProducts;

    impactedProductsHandler.items.forEach((product: Product) => {
      content += `* ${product.name}\n`;
    });
    impactedProductsHandler.warningContent = content;
  },
});

onMounted(async () => {
  if (scopeHasLinkedProducts.value) {
    await impactedProductsHandler.loadProductPage();
    if (impactedProductsHandler.hasItems) {
      impactedProductsHandler.updateWarningContent();
    }
  }
});
</script>

<template>
  <div class="selected-scope-item">
    <CardContainer
      class="selected-scope-item__card"
      :disabled="modifyDescriptionHandler.isEditing"
    >
      <div>
        {{ contents.idScopeCardLabel }}: <strong>{{ scope.name }}</strong>
      </div>
      <div>
        {{ contents.descriptionCardLabel }}:
        <strong>
          {{
            modifyDescriptionHandler.updatedScope != undefined
              ? modifyDescriptionHandler.updatedScope.description
              : scope.description
          }}
        </strong>
      </div>
      <MTag
        v-if="modifyDescriptionHandler.updatedScope != undefined"
        id="scope-description-updated"
        class="selected-scope-item__updated-description-tag"
        :class="{
          'selected-scope-item__updated-description-tag--disabled':
            modifyDescriptionHandler.isEditing,
        }"
        :label="contents.updatedDescriptionTagLabel"
        theme="light"
        size="s"
      />
      <div>
        {{ contents.usedByProductsCardLabel }}:
        <span
          v-if="scope.totalProductsCount > 0"
          class="selected-scope-item__products-link"
          @click="$emit('showScopeProducts', scope)"
          >{{ pluralize(scope.totalProductsCount, "product") }}
        </span>
        <span v-else> - </span>
      </div>
    </CardContainer>
    <div
      v-if="modifyDescriptionHandler.isEditing"
      class="selected-scope-item__modify-description-active"
    >
      <div class="selected-scope-item__modify-description-form">
        <DvpField
          :label="contents.descriptionLabel"
          :errorMessage="modifyDescriptionHandler.errorMessage"
          :isInvalid="modifyDescriptionHandler.errorMessage != undefined"
        >
          <MTextArea
            v-model="modifyDescriptionHandler.content"
            :placeholder="contents.descriptionPlaceholder"
            :isInvalid="modifyDescriptionHandler.errorMessage != undefined"
            :disabled="modifyDescriptionHandler.isSaving"
            rows="5"
          />
        </DvpField>

        <div class="selected-scope-item__modify-description-form-actions">
          <MButton
            :label="contents.cancelBtnLabel"
            theme="bordered-neutral"
            :disabled="modifyDescriptionHandler.isSaving"
            @click="modifyDescriptionHandler.cancel"
          />
          <MButton
            :label="contents.validateDescriptionBtnLabel"
            theme="solid"
            :disabled="
              modifyDescriptionHandler.errorMessage != undefined ||
              modifyDescriptionHandler.pristine ||
              modifyDescriptionHandler.isSaving
            "
            @click="modifyDescriptionHandler.validate"
          />
        </div>
      </div>
      <div class="selected-scope-item__modify-description-warnings">
        <MNotification
          type="information"
          :title="contents.writingGuidelinesTitle"
        >
          <Markdown :content="contents.writingGuidelinesContent" />
          <template #footer>
            <ViewDocumentationLink
              documentation-anchor="#configure-supported-scopes"
            />
          </template>
        </MNotification>
        <MNotification type="warning" :title="contents.warningTitle">
          <Markdown :content="contents.warningContent" />
          <Markdown
            v-if="scopeHasLinkedProducts"
            :content="impactedProductsHandler.warningContent"
          />
        </MNotification>
      </div>
    </div>
    <div v-else class="selected-scope-item__modify-description-panel">
      <span> {{ contents.editDescriptionTitle }} </span>

      <MButton
        class="selected-scope-item__modify-description-button"
        :label="contents.editDescriptionBtnLabel"
        icon="PublishEdit24"
        theme="bordered"
        @click="modifyDescriptionHandler.edit(scope)"
      />
    </div>
  </div>
</template>

<style lang="scss">
.selected-scope-item {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.selected-scope-item__card {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  gap: 1rem;
}
.selected-scope-item__products-link {
  text-decoration: underline;
  cursor: pointer;
}
.selected-scope-item__updated-description-tag {
  width: 10rem;
}
.selected-scope-item__updated-description-tag--disabled {
  opacity: 0.5;
}
.selected-scope-item__modify-description-panel {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.selected-scope-item__modify-description-active {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.selected-scope-item__modify-description-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.selected-scope-item__modify-description-form-actions {
  display: flex;
  gap: 1rem;
}
.selected-scope-item__modify-description-warnings {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}
.selected-scope-item__modify-description-button {
  width: 12.5rem;
}
</style>
