import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-59484a66"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "conversation" }
const _hoisted_2 = {
  key: 0,
  class: "conversation__no-request"
}
const _hoisted_3 = {
  key: 1,
  class: "conversation__selected-request"
}
const _hoisted_4 = {
  ref: "messages",
  class: "conversation__messages"
}
const _hoisted_5 = { class: "conversation__request-status-bar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MIcon = _resolveComponent("MIcon")!
  const _component_RequestManagerActionBar = _resolveComponent("RequestManagerActionBar")!
  const _component_ConversationItem = _resolveComponent("ConversationItem")!
  const _component_request_status = _resolveComponent("request-status")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!$options.currentRequest)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_MIcon, {
            class: "conversation__no-request-icon",
            name: "CommentCommunity32"
          }),
          _createElementVNode("div", null, _toDisplayString(_ctx.contents.selectRequestLabel), 1)
        ]))
      : _createCommentVNode("", true),
    ($options.currentRequest)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_RequestManagerActionBar, {
            "data-cy": "request-manager-action-bar",
            class: "conversation__request-manager-action-bar",
            urlQuery: $props.urlQuery
          }, null, 8, ["urlQuery"]),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.currentRequest.conversation, (conversationItem) => {
              return (_openBlock(), _createBlock(_component_ConversationItem, {
                key: conversationItem.createdAt,
                "current-user": $props.currentUser,
                conversationItem: conversationItem,
                request: $options.currentRequest
              }, null, 8, ["current-user", "conversationItem", "request"]))
            }), 128))
          ], 512),
          _createElementVNode("div", _hoisted_5, [
            _createTextVNode(_toDisplayString(_ctx.contents.requestStatus) + " ", 1),
            _createVNode(_component_request_status, { request: $options.currentRequest }, null, 8, ["request"])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}