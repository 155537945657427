<template>
  <CardListed class="contract-card-wrapper" :data-cy="contract.id" noMargin>
    <ContractWarningDisabled
      v-if="contract.status === EContractStatus.DISABLED"
      :contract="contract"
      @contractDeleted="emitContractChanged"
    />

    <ContractWarningPendingValidation
      v-else-if="contract.status === EContractStatus.CREATED"
      :contract="contract"
    />

    <ContractWarningApiUnpublished
      v-else-if="apiOfContractIsNotPublished(contract)"
      :contract="contract"
      @contractDeleted="emitContractChanged"
    />

    <ContractCard
      v-else-if="contract.status === EContractStatus.ENABLED"
      :contract="contract"
      @contractChanged="emitContractChanged"
    />

    <ContractWarningDefault v-else :contract="contract" />
  </CardListed>
</template>

<script setup lang="ts">
import { PropType } from "vue";

import CardListed from "@/commons/components/CardListed/CardListed.vue";
import ContractCard from "@/dashboard/views/ContractsList/contractCard/ContractCard.vue";
import ContractWarningApiUnpublished from "@/dashboard/views/ContractsList/contractWarning/ContractWarningApiUnpublished.vue";
import ContractWarningDefault from "@/dashboard/views/ContractsList/contractWarning/ContractWarningDefault.vue";
import ContractWarningDisabled from "@/dashboard/views/ContractsList/contractWarning/ContractWarningDisabled.vue";
import ContractWarningPendingValidation from "@/dashboard/views/ContractsList/contractWarning/ContractWarningPendingValidation.vue";

import { Contract } from "@/commons/domain/models/contract";

import {
  EContractStatus,
  ERouteStatus,
  ERouteTypes,
} from "@/commons/store/types";

defineProps({
  contract: {
    type: Object as PropType<Contract>,
    required: true,
  },
});

const emit = defineEmits(["contractChanged"]);

const apiOfContractIsNotPublished = (contract: Contract) => {
  const downstreamRoutes = Object.values(contract.api.routes).filter(
    (route) => route.type === ERouteTypes.DOWNSTREAM,
  );
  return downstreamRoutes.every((d) => d.status !== ERouteStatus.ENABLED);
};

const emitContractChanged = () => {
  emit("contractChanged");
};
</script>

<style lang="scss">
.contract-card-wrapper {
  background-color: var(--color-background-primary);
}
</style>
