<template>
  <div class="list-products-catalog">
    <div class="list-products-catalog__list">
      <RouterLink
        v-for="product in products"
        :key="product.id"
        class="list-products-catalog__list-item"
        :to="{
          name: 'productDocumentationDetail',
          params: { id: product.id },
        }"
      >
        <ProductListItem :product="product" />
      </RouterLink>
    </div>

    <div v-if="products.length === 0" class="list-products-catalog__no-content">
      {{ contents.noProductsToDisplay }}
    </div>
  </div>
</template>

<script lang="ts">
import ProductListItem from "@/commons/components/ProductListItem.vue";

import contents from "@/commons/contents/list-products-catalog";

export default {
  name: "ListProductsCatalog",
  components: {
    ProductListItem,
  },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      contents,
    };
  },
};
</script>

<style lang="scss">
.list-products-catalog__no-content {
  font-weight: 700;
}

.list-products-catalog__list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(23rem, 1fr));
  grid-gap: 1.5rem;
}

.list-products-catalog__list-item {
  margin-bottom: 2rem;
  text-decoration: none;
  cursor: pointer;
}
</style>
