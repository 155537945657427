<template>
  <div v-if="tags.length !== 0" class="business-unit-deployment">
    <Cluster>
      <SvgWithLabel>
        <IconBUD />
        <span class="business-unit-deployment__deployed">
          {{ contents.isUsedBy(label) }}
        </span>
      </SvgWithLabel>
      <template v-if="tags.length <= 3">
        <Chip
          v-for="tag in tags"
          :key="tag.id"
          data-cy="api-business-unit"
          class="business-unit-deployment__chip"
        >
          {{ tag.label }}
        </Chip>
      </template>
      <div v-else>
        <span
          class="business-unit-deployment__link"
          @click.prevent="toggleBusinessUnits"
        >
          {{ contents.nbrOfBusinessUnit(tags.length) }}
        </span>
      </div>
    </Cluster>
    <div
      v-if="isDisplayBusinessUnit"
      class="business-unit-deployment__panel"
      @click.prevent="toggleBusinessUnits"
    >
      <Box class="business-unit-deployment__panel-header weight-medium">
        {{ contents.deployTitle(label) }}
        <IconClose />
      </Box>
      <Box>
        <Cluster>
          <Chip
            v-for="tag in tags"
            :key="tag.id"
            class="business-unit-deployment__chip"
            >{{ tag.label }}</Chip
          >
        </Cluster>
      </Box>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType } from "vue";

import Box from "@/commons/components/Box.vue";
import Chip from "@/commons/components/Chip.vue";
import Cluster from "@/commons/components/Cluster.vue";
import IconBUD from "@/commons/components/Icons/IconBUD.vue";
import IconClose from "@/commons/components/Icons/IconClose.vue";
import SvgWithLabel from "@/commons/components/SvgWithLabel.vue";

import { Tag } from "@/commons/domain/models/tag";

import contents from "@/commons/contents/business-unit-deployment";

export default {
  name: "BusinessUnitDeployment",
  components: {
    IconBUD,
    IconClose,
    SvgWithLabel,
    Chip,
    Cluster,
    Box,
  },
  props: {
    tags: {
      type: Array as PropType<Tag[]>,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      contents,
      isDisplayBusinessUnit: false,
    };
  },
  methods: {
    toggleBusinessUnits() {
      this.isDisplayBusinessUnit = !this.isDisplayBusinessUnit;
    },
  },
};
</script>

<style lang="scss">
.business-unit-deployment {
  display: block;
  width: 100%;

  --chip-bg-color: var(--color-white);
  --chip-text-color: var(--color-text);
  --chip-border: 1px solid var(--color-text);
  --cluster-layout-glutter-right: 0.5rem;
  --cluster-layout-align-items: center;
}
/* stylelint-disable keyframes-name-pattern */
@keyframes translatePanel {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}
/* stylelint-enable keyframes-name-pattern */
.business-unit-deployment__panel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  user-select: none;
  background-color: var(--color-white);
  border-radius: 0.5rem;
  outline: none;
  animation: 0.2s linear 0s 1 normal translatePanel;
}

.business-unit-deployment__panel-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-text);
}

.business-unit-deployment__link {
  font-size: var(--size-300);
  color: var(--color-link);
  text-decoration: underline;
}

.business-unit-deployment svg {
  width: 1.2rem;
  height: auto;
  overflow: visible;
}

.business-unit-deployment__deployed {
  margin-right: 0;
  margin-left: 4px;
}

.business-unit-deployment__panel .chip {
  margin-right: 10px;
  margin-bottom: 10px;
}

.business-unit-deployment__chip {
  font-size: 0.75rem;
  line-height: 0.75rem;
}
</style>
