<script setup lang="ts">
import MTextArea from "@mozaic-ds/vue-3/src/components/textarea/MTextArea.vue";
import { onMounted } from "vue";

import DvpField from "@/commons/components/form/DvpField.vue";
import LayerModalFormConfirm from "@/commons/components/form/LayerModalFormConfirm.vue";
import Markdown from "@/documentation/components/commons/Markdown.vue";

import { CommentForm } from "@/commons/forms/comment-form";

import contents from "@/manager/contents/request-api-public-access-modal";

const emit = defineEmits(["onSubmit", "onClose"]);

const form = CommentForm.create();

onMounted(async () => {
  await form.init();
});

const submit = async () => {
  await form.validate();
  if (!form.errors.length) {
    emit("onSubmit", form.comment);
    emit("onClose");
  }
};
</script>

<template>
  <LayerModalFormConfirm
    :confirmBtnLabel="contents.submitBtnLabel"
    :disabledConfirmBtn="!!form.errors.length"
    @submit="submit"
    @close="emit('onClose')"
  >
    <h4>{{ contents.title }}</h4>

    <Markdown :content="contents.description" />

    <DvpField required :errorMessage="form.firstError('comment')">
      <MTextArea
        v-model="form.comment"
        :placeholder="contents.placeholder"
        :isInvalid="form.firstError('comment') != undefined"
        :rows="10"
      />
    </DvpField>
  </LayerModalFormConfirm>
</template>
