module.exports = {
  modalTitle: "Validate your Quota type change",

  titleForChangeToApiQuota: "You are going to apply API Quota on your API.",
  subtitleForChangeToApiQuota: `Your current Consumer Quota will not be applied anymore.\n
  But you will be able to rollback easily as we archive your current configuration.`,

  titleForChangeToConsumersQuota:
    "You are going to apply Consumer Quota on your API.",
  subtitleForChangeToConsumersQuota: `Your current API Quota will not be applied anymore.\n
  But you will be able to rollback easily as we archive your current configuration.`,

  confirm: "I understand and confirm my change of quota",
};
