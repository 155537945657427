import moment from "moment";

export function getThematicLogo(year, month, day) {
  let logoPortal = require("@/commons/assets/images/logo-lm-devportal_2020.svg");
  const host = window.location.host;

  // Halloween
  if (
    moment(`${year}-${month}-${day}`).isBetween(
      // period from 25th October to 8th November
      `${year}-10-24`,
      `${year}-11-09`,
    )
  ) {
    logoPortal = require("@/commons/assets/images/devportal-logo/logo-halloween-2021.gif");
  }

  // First Christmas week
  if (
    moment(`${year}-${month}-${day}`).isBetween(
      // period from 1th December to 8th December
      `${year}-11-30`,
      `${year}-12-09`,
    )
  ) {
    logoPortal = require("@/commons/assets/images/devportal-logo/logo-lm-devportal_2021-christmas-ice.gif");
    if (
      host.includes("developer-portal-ref") ||
      host.includes("developer-portal-sit") ||
      host.includes("developer-portal-uat")
    ) {
      logoPortal = require("@/commons/assets/images/devportal-logo/logo-lm-devportal_2021-christmas-tree.gif");
    }
  }

  // Second Christmas week
  if (
    moment(`${year}-${month}-${day}`).isBetween(
      // period from 9th December to 16 December
      `${year}-12-08`,
      `${year}-12-17`,
    )
  ) {
    logoPortal = require("@/commons/assets/images/devportal-logo/logo-lm-devportal_2021-christmas-cookie.gif");
    if (
      host.includes("developer-portal-ref") ||
      host.includes("developer-portal-sit") ||
      host.includes("developer-portal-uat")
    ) {
      logoPortal = require("@/commons/assets/images/devportal-logo/logo-lm-devportal_2021-christmas-snowball.gif");
    }
  }

  // Third Christmas week
  if (
    moment(`${year}-${month}-${day}`).isBetween(
      // period from 17th December to 24 December
      `${year}-12-16`,
      `${year}-12-25`,
    )
  ) {
    logoPortal = require("@/commons/assets/images/devportal-logo/logo-lm-devportal_2021-christmas-santa.gif");
    if (
      host.includes("developer-portal-ref") ||
      host.includes("developer-portal-sit") ||
      host.includes("developer-portal-uat")
    ) {
      logoPortal = require("@/commons/assets/images/devportal-logo/logo-lm-devportal_2021-christmas-gift.png");
    }
  }

  // Forth Christmas week
  if (
    moment(`${year}-${month}-${day}`).isBetween(
      // period from 25th December to 31 December
      `${year}-12-24`,
      moment(`${year}-01-01`).add(1, "year"),
    )
  ) {
    logoPortal = require("@/commons/assets/images/devportal-logo/logo-lm-devportal_2021-christmas-snow.gif");
    if (
      host.includes("developer-portal-ref") ||
      host.includes("developer-portal-sit") ||
      host.includes("developer-portal-uat")
    ) {
      if (getRandomInt(2) == 0) {
        logoPortal = require("@/commons/assets/images/devportal-logo/logo-lm-devportal_2021-christmas-another-tree.png");
      } else {
        logoPortal = require("@/commons/assets/images/devportal-logo/logo-lm-devportal_2021-christmas-another-gift.png");
      }
    }
  }

  // New year
  if (
    moment(`${year}-${month}-${day}`).isBetween(
      // period from 1th to 15th January
      moment(`${year}-12-31`).subtract(1, "year"),
      `${year}-01-15`,
    )
  ) {
    logoPortal = require("@/commons/assets/images/devportal-logo/logo-devportal-new-year.png");
  }

  return logoPortal;
}

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}
