<template>
  <div class="list-api-summaries">
    <Stack>
      <Grid>
        <template v-if="isLoading">
          <CardLoading v-for="index in 6" :key="`card-loading-${index}`"
        /></template>
        <template v-else>
          <CardApiSummary
            v-for="(api, index) of apis"
            :key="api.id"
            :api="api"
            :hide-product="hideProduct"
            :tabindex="index + 1"
          />
        </template>
      </Grid>
      <div
        v-if="!isLoading && apis.length === 0"
        class="list-api-summaries__no-content"
      >
        {{ contents.noApisToDisplay }}
      </div>
    </Stack>
  </div>
</template>

<script lang="ts">
import CardApiSummary from "@/commons/components/CardListed/CardApiSummary.vue";
import CardLoading from "@/commons/components/CardListed/CardLoading.vue";
import Grid from "@/commons/components/Grid.vue";
import Stack from "@/commons/components/Stack.vue";

import contents from "@/commons/contents/list-api-summaries";

export default {
  name: "ListApiSummaries",
  components: {
    CardApiSummary,
    CardLoading,
    Stack,
    Grid,
  },
  props: {
    apis: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    hideProduct: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      contents,
    };
  },
};
</script>

<style lang="scss">
.list-api-summaries {
  --grid-layout-item-width: 22rem;
  --grid-layout-gap: 1rem;
}

.list-api-summaries__no-content {
  font-weight: 700;
}
</style>
