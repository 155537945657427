import { getProductRepository } from "@/commons/repositories/libs/get-product-repository";

import { Product } from "@/commons/domain/models/product";

export async function getProductById(productId: string): Promise<Product> {
  return await getProductRepository().findById(productId);
}

export async function searchProduct(name: string): Promise<Product[]> {
  const pagedProducts = await getProductRepository().cancelAndFind({
    name,
  });
  return Object.values(pagedProducts.data);
}

export async function getProductsByIds(
  productsIds: string[],
): Promise<Product[]> {
  return productsIds?.length > 0
    ? await getProductRepository().findByIds(productsIds)
    : [];
}
