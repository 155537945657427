<template>
  <div class="custom-notification">
    <img :src="pandaNotificationImg" class="custom-notification__panda" />

    <div class="custom-notification__content">
      <div class="custom-notification__title">
        <MIcon v-if="iconName" :name="iconName" />
        <div>{{ title }}</div>
      </div>
      <div class="custom-notification__description">
        {{ description }}
      </div>
      <ViewHelpButton
        v-if="documentationAnchor"
        :documentationAnchor="documentationAnchor"
      />
    </div>
  </div>
</template>

<script lang="ts">
import MIcon from "@mozaic-ds/vue-3/src/components/icon/MIcon.vue";

import ViewHelpButton from "@/commons/components/UserDocumentationLinks/ViewHelpButton.vue";

export default {
  name: "CustomNotification",
  components: { ViewHelpButton, MIcon },
  props: {
    iconName: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    documentationAnchor: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      pandaNotificationImg: require("@/commons/assets/images/panda-notification-icon.png"),
    };
  },
};
</script>

<style lang="scss">
.custom-notification {
  box-sizing: border-box;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-end;

  margin-bottom: 1rem;

  /* duplication from mozaic */
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  background: #daeff7;
  border-radius: 4px;
  box-shadow: inset 0 0 0 1px #0b96cc;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-box-align: start;
}

.custom-notification__title {
  display: flex;
  margin: 0 1rem 0.5rem 0;
  font-size: 1rem;
  font-weight: 700;
}

.custom-notification__description {
  margin-bottom: 1rem;
}

.custom-notification__panda {
  justify-content: flex-end;
}
.custom-notification__content {
  margin: 1rem;
}
</style>
