import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_home_main = _resolveComponent("home-main")!
  const _component_slide = _resolveComponent("slide")!
  const _component_home_use_cases = _resolveComponent("home-use-cases")!
  const _component_home_try_it = _resolveComponent("home-try-it")!
  const _component_home_resume = _resolveComponent("home-resume")!
  const _component_footer_menus = _resolveComponent("footer-menus")!
  const _component_slides_layout = _resolveComponent("slides-layout")!

  return (_openBlock(), _createBlock(_component_slides_layout, { class: "home" }, {
    slides: _withCtx(() => [
      _createVNode(_component_slide, {
        class: "home__main",
        anchor: "home-main"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_home_main, {
            onNavigateToAnchor: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigateToAnchor($event)))
          })
        ]),
        _: 1
      }),
      _createVNode(_component_slide, {
        class: "home__use-cases",
        anchor: "home-use-cases"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_home_use_cases)
        ]),
        _: 1
      }),
      _createVNode(_component_slide, {
        class: "home__try-it",
        anchor: "home-try-it"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_home_try_it)
        ]),
        _: 1
      }),
      _createVNode(_component_slide, {
        class: "home__resume",
        anchor: "home-resume"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_home_resume),
          _createVNode(_component_footer_menus)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}