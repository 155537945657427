import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "search-text-filter" }
const _hoisted_2 = {
  key: 0,
  class: "search-text-filter__label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MTextInput = _resolveComponent("MTextInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($props.label), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_MTextInput, {
      type: "search",
      icon: "DisplaySearch32",
      placeholder: $props.placeholder,
      modelValue: $props.queryParamValue,
      size: $props.size,
      isInvalid: $props.isInvalid,
      textInputFieldClass: "search-text-filter__input",
      "onUpdate:modelValue": $options.debouncedSearch
    }, null, 8, ["placeholder", "modelValue", "size", "isInvalid", "onUpdate:modelValue"])
  ]))
}