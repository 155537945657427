import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "dvp-tooltip" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MTooltip = _resolveComponent("MTooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.disabled === false && $props.text !== undefined)
      ? (_openBlock(), _createBlock(_component_MTooltip, {
          key: 0,
          id: $options.tooltipId,
          position: $props.position,
          label: $props.text
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default")
          ]),
          _: 3
        }, 8, ["id", "position", "label"]))
      : _renderSlot(_ctx.$slots, "default", { key: 1 })
  ]))
}