<template>
  <Layout class="empty-page" hide-loader></Layout>
</template>

<script lang="ts">
import Layout from "@/commons/components/Layout.vue";

export default {
  name: "EmptyPage",
  components: { Layout },
};
</script>
