import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfirmModalBody = _resolveComponent("ConfirmModalBody")!

  return (_openBlock(), _createBlock(_component_ConfirmModalBody, {
    class: "refused-subscription-modal",
    confirmBtnLabel: $data.contents.submitBtn,
    confirmBtnTheme: "solid",
    onOnSubmit: $options.submit,
    onOnClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onClose')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("h5", null, _toDisplayString($data.contents.title), 1),
        _createElementVNode("div", null, _toDisplayString($data.contents.message), 1)
      ])
    ]),
    _: 1
  }, 8, ["confirmBtnLabel", "onOnSubmit"]))
}