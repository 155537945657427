export default {
  clientID: "ClientID",
  clientSecret: "ClientSecret",
  contractPending: "Contract request is pending",
  currentApiKeyExpired: "Current API Key is expired",
  apiNotAvailable: "This API is no longer available on our API gateway",
  itCanTakeFewMinutes: "It can take a few minutes.",
  keyTabsTitles: ["Current API key", "New API key"],
  oauthClientCredentialsFlow: "OAuth - Client Credentials Flow",
  oauthAuthorizationCodeFlow: "OAuth - Authorization Code Flow",
  pleaseWaitGenOfContract:
    "Please wait during the generation of your contract.",
  pleaseWaitForValidation: "Please wait for the validation of your request",
  quotas: "Quotas",
  scopes: "Scopes",
  subscriptionLimitedByQuotas:
    "Your subscription is limited by the following quotas",
  subscriptionNotLimitedByQuotas:
    "Your subscription is not limited by any quota",
  waitSomeMinutes: "It can take a few minutes",
  adminNotified: "Administrators have been notified",
  managersNotified: "API managers have been notified",
  pendingValidation: (apiName) =>
    `Subscription to ${apiName} is waiting for validation`,
  pendingGateway: (apiName) => `Subscription to ${apiName} is in progress`,
  failingCreation: (apiName) => `Subscription to ${apiName} has failed`,
  disabledContract: (apiName) =>
    `Your contract with ${apiName} has been disabled`,
  disabledByApiManager: "Disabled by API manager",
  tokenActions: "Token actions",
  expiredSince: "Expired since",
  expiresAt: "Expires at",
  documentation: "Documentation",
  noScopeDefine: "No scope defined",
  deprecatedSince: "Deprecated since",
  flagKongGateway: "KONG",
  flagOAuthPing: "OAuth (Ping)",
  deprecatedVersion: (version) => `V${version} - Deprecated`,
  apiRateLimit: (rateLimitLabel) => `API ${rateLimitLabel}`,
  appRateLimit: (rateLimitLabel) => `APP ${rateLimitLabel}`,
  noQuota: "No quota",
  rotateTokenNotification: "Please renew token",
  kong: "Kong",

  // Copy clipboard
  copyClipboardSuccess: "Key copied to clipboard",
  copyClipboardTitle: "Copy key to clipboard",
  copyClipboardWarning:
    "Please use it in an API context only, to avoid any side impact when it will be deleted from DevPortal.",

  renewSubscription: "Please, renew subscription",
  flagApiKey: "API Key",
  infoAndLinksLabel: "Info & Links",
};
