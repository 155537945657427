<template>
  <ConfirmModalBody
    class="consumers-deactivation-modal"
    @onSubmit="submit"
    @onClose="$emit('onClose')"
  >
    <h5>
      {{ contents.consumersDeactivationMessage }}
    </h5>
    <template v-if="isPublicSelfServiceApi">
      <Markdown
        data-cy="prevention-message-for-self-service-api"
        :content="
          contents.accessRestrictionPreventionNote(hrefLinkToAccessRestriction)
        "
      />
    </template>
  </ConfirmModalBody>
</template>

<script lang="ts">
import { PropType } from "vue";

import ConfirmModalBody from "@/commons/components/Modal/ConfirmModalBody.vue";
import Markdown from "@/documentation/components/commons/Markdown.vue";

import { isOAuthContract } from "@/commons/libs/utils/contractUtils";
import { convertRouteToHref } from "@/commons/utils/route-utils";

import { Contract } from "@/commons/domain/models/contract";

import { EAccessPolicy } from "@/commons/store/types";

import contents from "@/manager/contents/consumers-deactivation-modal";

export default {
  components: {
    ConfirmModalBody,
    Markdown,
  },
  props: {
    contract: {
      type: Object as PropType<Contract>,
      required: true,
    },
    tokenId: {
      type: String,
      default: undefined,
    },
  },
  emits: ["onClose"],
  data() {
    return {
      contents,
    };
  },
  computed: {
    apiId(): string {
      return this.contract?.api?.id;
    },
    isPublicSelfServiceApi(): boolean {
      return this.contract?.api?.accessPolicy === EAccessPolicy.SELF_SERVICE;
    },
    hrefLinkToAccessRestriction(): string {
      return convertRouteToHref({
        name: "managerAccessRestriction",
        params: { id: this.apiId },
      });
    },
  },
  methods: {
    submit(): void {
      if (isOAuthContract(this.contract)) {
        this.$store.dispatch("disableContract", {
          appId: this.contract.application.id,
          contractId: this.contract.id,
        });
      } else {
        this.$store.dispatch("disableToken", {
          contractId: this.contract.id,
          appId: this.contract.application.id,
          tokenId: this.tokenId,
          apiId: this.apiId,
        });
      }
      this.$emit("onClose");
    },
  },
};
</script>
