import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "contract-ask-validation-modal__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StepperApiSubscription = _resolveComponent("StepperApiSubscription")!
  const _component_MTextArea = _resolveComponent("MTextArea")!
  const _component_DvpField = _resolveComponent("DvpField")!
  const _component_LayerModalFormConfirm = _resolveComponent("LayerModalFormConfirm")!

  return (_openBlock(), _createBlock(_component_LayerModalFormConfirm, {
    class: "contract-ask-validation-modal",
    confirmBtnTheme: "solid",
    disabledConfirmBtn: $data.form.errors.length > 0,
    onSubmit: $options.submit,
    onClose: $options.close
  }, {
    default: _withCtx(() => [
      _createVNode(_component_StepperApiSubscription, {
        stepperDescription: $data.contents.stepperDescription,
        stepperSteps: $options.stepperSteps
      }, null, 8, ["stepperDescription", "stepperSteps"]),
      _createElementVNode("h4", _hoisted_1, _toDisplayString($data.contents.title), 1),
      _createElementVNode("div", null, _toDisplayString($data.contents.description), 1),
      _createVNode(_component_DvpField, {
        required: "",
        label: $data.contents.commentField,
        errorMessage: $data.form.firstError('comment')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_MTextArea, {
            modelValue: $data.form.comment,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.form.comment) = $event)),
            class: "contract-ask-validation-modal__textarea",
            placeholder: $data.contents.placeholder,
            isInvalid: $data.form.firstError('comment') != undefined
          }, null, 8, ["modelValue", "placeholder", "isInvalid"])
        ]),
        _: 1
      }, 8, ["label", "errorMessage"])
    ]),
    _: 1
  }, 8, ["disabledConfirmBtn", "onSubmit", "onClose"]))
}