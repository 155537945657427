export enum EDocumentationType {
  CHANGELOG = "CHANGELOG",
  MARKDOWN = "MARKDOWN",
  REST_API_SPECIFICATIONS = "REST_API_SPECIFICATIONS", // For Swagger and OpenApi
}

export enum EDocumentationJobStatus {
  PENDING = "PENDING",
  CONTENT_UPDATED = "CONTENT_UPDATED",
  PARSE_ERROR = "PARSE_ERROR",
  WORKER_ERROR = "WORKER_ERROR",
}

export enum EDocumentationPageSource {
  URL = "URL",
  CONTENT = "CONTENT",
  FILE = "FILE",
}

export interface DocumentationPage {
  id: string;
  updatedAt: string;
  isRemoved: boolean;
  url: string;
  title: string;
  description: string;
  type: EDocumentationType;
  content: string;
  extendedContent: any;
  order: number;
  isPublished: boolean;
  lastJobStatus: EDocumentationJobStatus;
  errorMessages: string[];
  allowedActions: { [action: string]: boolean };
}
