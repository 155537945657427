import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Markdown = _resolveComponent("Markdown")!
  const _component_ConfirmModalBody = _resolveComponent("ConfirmModalBody")!

  return (_openBlock(), _createBlock(_component_ConfirmModalBody, {
    class: "consumers-deactivation-modal",
    onOnSubmit: $options.submit,
    onOnClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onClose')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("h5", null, _toDisplayString($data.contents.consumersDeactivationMessage), 1),
      ($options.isPublicSelfServiceApi)
        ? (_openBlock(), _createBlock(_component_Markdown, {
            key: 0,
            "data-cy": "prevention-message-for-self-service-api",
            content: 
          $data.contents.accessRestrictionPreventionNote($options.hrefLinkToAccessRestriction)
        
          }, null, 8, ["content"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["onOnSubmit"]))
}