import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Stack = _resolveComponent("Stack")!
  const _component_Layout = _resolveComponent("Layout")!

  return (_openBlock(), _createBlock(_component_Layout, { class: "tos-read" }, {
    default: _withCtx(() => [
      _createVNode(_component_Stack, null, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: "tos__content-body tos",
            innerHTML: $options.termsOfUse()
          }, null, 8, _hoisted_1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}