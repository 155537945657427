export default {
  enabledBadgeLabel: "Enabled",
  disabledBadgeLabel: "Disabled",

  title: (secretManager: string) => `Publish API Keys to ${secretManager}`,
  subtitle:
    "By activating this feature, your Application Vault namespace will be completed with new paths containing the API Keys.",

  activateToggleLabel: "Activate",

  betaVersionLabel: "Beta version",
  betaVersionMessage:
    "This feature is being tested at scale with selected teams. As a Beta version, its Vault JSON output may vary before final release. Contact us before using it.",

  canNotActivateOnTestingApp:
    "You cannot activate this feature while this application is a test one.",
  disabledSecretSyncFeatureTooltipText: (secretManager: string) =>
    `${secretManager} feature is temporarily deactivated. Please retry later.`,
};
