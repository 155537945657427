<template>
  <div v-if="!isLoading" class="create-app">
    <template v-if="currentUserGroupsList.length === 0">
      <CreateGroup />
    </template>
    <template v-else>
      <LayerModalFormConfirm
        :confirmBtnLabel="contents.createButtonLabel"
        confirmBtnTheme="solid"
        :disabledConfirmBtn="form.errors.length > 0 || isSaving"
        @submit="submit"
        @close="close"
      >
        <DvpField v-if="userIsInternal">
          <div class="create-app__test-app">
            <MCheckbox
              id="create-app__to-test"
              v-model="form.forTesting"
              :label="contents.testApplication"
              :disabled="isLoading"
            />
            <MTooltip
              id="create-app__tooltip-test-app"
              :label="contents.applicationForTestingTooltip"
              position="bottom"
            >
              <MIcon
                class="create-app__test-app-icon"
                name="NotificationQuestion24"
              />
            </MTooltip>
          </div>
        </DvpField>

        <DvpField
          :label="contents.applicationName"
          required
          :isInvalid="isInvalidName()"
          :errorMessage="form.firstError('name')"
        >
          <MTextInput
            v-model="appName"
            :placeholder="contents.applicationName"
            :isInvalid="isInvalidName()"
          />
        </DvpField>

        <MNotification
          v-if="appWithSameName !== undefined"
          :title="contents.appWithSameNameWarningTitle"
          type="warning"
        >
          {{ contents.appWithSameNameWarningMessage(appWithSameName.name) }}
          <template #footer>
            <MLink
              size="s"
              :href="
                convertRouteToHref({
                  name: 'application',
                  params: {
                    id: appWithSameName.id,
                  },
                })
              "
            >
              {{ contents.goToApplicationLinkTitle }}
            </MLink>
          </template>
        </MNotification>

        <DvpField
          data-cy="create-app__app-type"
          :label="contents.applicationType"
          required
          :isInvalid="isInvalidType()"
          :errorMessage="form.firstError('type')"
        >
          <MSelect
            v-model="form.type"
            :placeholder="contents.applicationTypeLabel"
            :options="typeOptions"
          />
        </DvpField>

        <DvpField
          v-if="userIsInternal && !form.forTesting"
          :label="contents.relatedProduct"
          required
          :errorMessage="form.firstError('productId')"
        >
          <MAutocomplete
            v-model="form.productId"
            :items="productsList"
            :filter="false"
            dataValueExpr="id"
            dataTextExpr="name"
            :disabled="isLoading"
            :placeholder="contents.applicationProductPlaceholder"
            @update:input="debouncedSearchProduct"
          />
        </DvpField>

        <DvpField
          :label="contents.description"
          required
          :isInvalid="isInvalidDescription()"
          :errorMessage="form.firstError('description')"
        >
          <MTextArea
            v-model="form.description"
            :placeholder="contents.applicationDescLabel"
          />
        </DvpField>

        <DvpField
          :label="contents.managerGroup"
          required
          :errorMessage="form.firstError('groupId')"
        >
          <GroupAutocompleteWrapper
            v-model="form.groupId"
            :items="userGroups"
            dataValueExpr="id"
            dataTextExpr="name"
            :filter="false"
            :disabled="isLoading"
            :placeholder="contents.addManagerGroupPlaceholder"
            @update:input="debouncedSearchUserGroups"
            @update:model-value="onGroupSelection"
          />
          <div class="create-app__btn-manage-groups">
            <router-link :to="{ name: 'groupsSettings' }" class="link">
              {{ contents.linkToGroupsSettings }}
            </router-link>
          </div>
        </DvpField>
        <NotificationWarningMCCGroup v-if="displayMCCWarning" />
      </LayerModalFormConfirm>
    </template>
  </div>
</template>

<script lang="ts">
import MAutocomplete from "@mozaic-ds/vue-3/src/components/autocomplete/MAutocomplete.vue";
import MCheckbox from "@mozaic-ds/vue-3/src/components/checkbox/MCheckbox.vue";
import MIcon from "@mozaic-ds/vue-3/src/components/icon/MIcon.vue";
import MLink from "@mozaic-ds/vue-3/src/components/link/MLink.vue";
import MNotification from "@mozaic-ds/vue-3/src/components/notification/MNotification.vue";
import MSelect from "@mozaic-ds/vue-3/src/components/select/MSelect.vue";
import MTextArea from "@mozaic-ds/vue-3/src/components/textarea/MTextArea.vue";
import MTextInput from "@mozaic-ds/vue-3/src/components/textinput/MTextInput.vue";
import MTooltip from "@mozaic-ds/vue-3/src/components/tooltip/MTooltip.vue";

import CreateGroup from "@/commons/components/CreateGroup.vue";
import NotificationWarningMCCGroup from "@/commons/components/ManageGroups/NotificationWarningMCCGroup.vue";
import DvpField from "@/commons/components/form/DvpField.vue";
import LayerModalFormConfirm from "@/commons/components/form/LayerModalFormConfirm.vue";
import GroupAutocompleteWrapper from "@/commons/components/form/SearchGroupAutocompleteWrapper.vue";

import { Debouncer } from "@/commons/libs/utils/debouncer";
import {
  isLocalGroup,
  isOnlyInternalsGroup,
} from "@/commons/utils/group-utils";
import { convertRouteToHref } from "@/commons/utils/route-utils";

import { Application } from "@/commons/domain/models/application";
import { GroupWithUserAction } from "@/commons/domain/models/group-with-user-action";
import { GroupWithUsersAcls } from "@/commons/domain/models/group-with-users-acls";
import { searchMatchingApplication } from "@/commons/services/application/application.service";
import { searchUserGroupsApplicationCreation } from "@/commons/services/group/group-service";
import { searchProduct } from "@/commons/services/product/product-service";
import { CreateAppForm } from "@/dashboard/domain/forms/create-app-form";

import contents from "@/dashboard/contents/create-or-update-app";

export default {
  name: "CreateApp",
  components: {
    NotificationWarningMCCGroup,
    LayerModalFormConfirm,
    CreateGroup,
    DvpField,
    MCheckbox,
    MTooltip,
    MTextInput,
    MSelect,
    MTextArea,
    MIcon,
    MNotification,
    MLink,
    MAutocomplete,
    GroupAutocompleteWrapper,
  },
  emits: ["onClose"],
  data() {
    return {
      form: CreateAppForm.create(),
      contents,
      debouncer: new Debouncer(),
      productsList: [],
      appWithSameName: undefined as Application,
      isLoading: true as Boolean,
      userGroups: [] as GroupWithUsersAcls[],
      displayMCCWarning: false as Boolean,
    };
  },
  computed: {
    currentUserGroupsList(): GroupWithUserAction[] {
      return this.$store.getters["currentUserGroupsList"];
    },
    typeOptions() {
      return Object.entries(this.contents.applicationTypes).map(
        ([value, label]) => ({
          value,
          text: label,
        }),
      );
    },
    userIsInternal() {
      return this.$store.getters["userIsInternal"];
    },
    isSaving() {
      return this.$store.getters["isSaving"];
    },
    appName: {
      get() {
        return this.form.name;
      },
      set(value: string) {
        this.form.name = value;
        this.debouncedSearchApplication(value);
      },
    },
  },
  async mounted() {
    this.isLoading = true;

    await this.searchProduct();
    await this.searchGroups();

    await this.form.init({ userIsInternal: this.userIsInternal });

    this.isLoading = false;
  },
  methods: {
    convertRouteToHref,
    close() {
      this.$emit("onClose");
    },
    async submit() {
      await this.form.validate();

      if (!this.form.errors.length && !this.isSaving) {
        const formData = this.form.data();

        this.$store.dispatch("createApplication", {
          ...formData,
          productId: formData.forTesting ? null : formData.productId,
        });
      }
    },
    debouncedSearchProduct(searchValue: string): void {
      this.debouncer.debounce(() => this.searchProduct(searchValue), 200);
    },
    async searchProduct(searchValue: string = "") {
      this.productsList = await searchProduct(searchValue);
    },
    debouncedSearchUserGroups(searchValue: string): void {
      this.debouncer.debounce(() => this.searchGroups(searchValue), 200);
    },
    async searchGroups(searchValue: string = "") {
      this.userGroups = await searchUserGroupsApplicationCreation(
        searchValue,
        this.form.productId,
      );
    },
    debouncedSearchApplication(searchValue: string): void {
      if (searchValue.length >= 4) {
        this.debouncer.debounce(
          () => this.searchMatchingApplication(searchValue),
          200,
        );
      }
    },
    async searchMatchingApplication(searchValue: string = "") {
      this.appWithSameName = await searchMatchingApplication(searchValue);
    },
    isInvalidName() {
      return this.form.firstError("name") != null;
    },
    isInvalidType() {
      return this.form.firstError("type") != null;
    },
    isInvalidDescription() {
      return this.form.firstError("description") != null;
    },
    onGroupSelection(groupId: string) {
      const selectedGroup = this.userGroups.find(
        (g: GroupWithUsersAcls) => g.id === groupId,
      );
      this.displayMCCWarning =
        selectedGroup != undefined &&
        this.userIsInternal &&
        isLocalGroup(selectedGroup) &&
        isOnlyInternalsGroup(selectedGroup);
    },
  },
};
</script>

<style lang="scss">
.create-app__test-app {
  display: flex;
}

.create-app__test-app-icon {
  margin-bottom: 0.5rem;
}
</style>
