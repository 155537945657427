<!--suppress CssUnknownTarget -->
<template>
  <nav class="top-navbar-empty">
    <RouterLink :to="{ name: 'home' }" title="Home">
      <img class="top-navbar-empty__logo" :src="logo" />
    </RouterLink>
  </nav>
</template>

<script lang="ts">
import moment from "moment";

import { getThematicLogo } from "@/commons/libs/get-thematic-logo";

export default {
  name: "TopNavbarEmpty",
  data() {
    return {
      logo: getThematicLogo(
        moment().format("YYYY"),
        moment().format("MM"),
        moment().format("DD"),
      ),
    };
  },
};
</script>

<style lang="scss">
.top-navbar-empty {
  display: flex;
  align-items: center;
  height: 5rem;
}

.top-navbar-empty__logo {
  width: 12.3rem;
  height: 4rem;
}
</style>
