<template>
  <ConfirmModalBody
    class="change-to-api-key-modal"
    :confirmBtnLabel="contents.submitBtn"
    @onSubmit="onSubmit()"
    @onClose="$emit('onClose')"
  >
    <div class="change-to-api-key-modal__content">
      <h5 class="change-to-api-key-modal__context-info">
        {{ aboutToChangeDescription }}
      </h5>
      <div>
        <p>{{ contents.newSubscriptionsMessage }}</p>
        <p>{{ contents.impactWarning }}</p>
      </div>

      <MNotification
        v-if="displayDeleteSandboxesWarning"
        :title="contents.previousContractsRemovalNotificationTitle"
        type="warning"
      >
        <Markdown
          :content="
            contents.previousContractsRemovalNotificationMessage(
              hrefLinkToApiConsumers,
            )
          "
        />
      </MNotification>
    </div>
  </ConfirmModalBody>
</template>

<script lang="ts">
import MNotification from "@mozaic-ds/vue-3/src/components/notification/MNotification.vue";
import { PropType } from "vue";

import ConfirmModalBody from "@/commons/components/Modal/ConfirmModalBody.vue";
import Markdown from "@/documentation/components/commons/Markdown.vue";

import { convertRouteToHref } from "@/commons/utils/route-utils";

import { EAuthType } from "@/commons/store/types";

import contents from "@/manager/contents/change-auth-type-modal";

export default {
  components: {
    Markdown,
    MNotification,
    ConfirmModalBody,
  },
  props: {
    apiId: {
      type: String,
      required: true,
    },
    requestedAuthType: {
      type: String as PropType<EAuthType>,
      required: true,
    },
    displayDeleteSandboxesWarning: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["onClose", "onSubmit"],
  data() {
    return {
      contents,
      EAuthType,
    };
  },
  computed: {
    hrefLinkToApiConsumers() {
      return convertRouteToHref({
        name: "managerApiDetailConsumers",
        params: { id: this.apiId },
      });
    },
    aboutToChangeDescription(): string {
      return this.requestedAuthType === EAuthType.OAUTH
        ? contents.aboutToChangeToOAuth
        : contents.aboutToChangeToApiKey;
    },
  },
  methods: {
    onSubmit() {
      this.$emit("onSubmit");
      this.$emit("onClose");
    },
  },
};
</script>

<style lang="scss">
.change-to-api-key-modal__content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.change-to-api-key-modal__context-info {
  margin: 0;
}
</style>
