import { v4 as uuid } from "uuid";

import { RoutingPolicy } from "@/commons/domain/models/routing-policy";
import { RoutingPolicyCondition } from "@/commons/domain/models/routing-policy-condition";
import { RoutingPolicyDto } from "@/commons/dtos/routing-policy-dto";

import { ERoutingPolicyType } from "@/commons/store/types";

export class RoutingPolicyMapper {
  static toDTO(routingPolicy: RoutingPolicy): RoutingPolicyDto {
    const conditions =
      (routingPolicy.conditions && Object.values(routingPolicy.conditions)) ||
      [];

    const routingPolicyDto: RoutingPolicyDto = {
      url: routingPolicy.url,
      path: conditions.find((condition) => condition.type === "path")
        ?.value as string,
      httpMethods: conditions.find(
        (condition) => condition.type === "httpMethods",
      )?.value as string[],
      origin: conditions.find((condition) => condition.type === "origin")
        ?.value as string,
      order: routingPolicy.order,
      escapePath: routingPolicy.escapePath,
    };

    const headers = conditions.filter(
      (condition) => condition.type === "headers",
    );
    if (headers.length) {
      routingPolicyDto.headers = conditions
        .filter((condition) => condition.type === "headers")
        .reduce((acc, item) => {
          acc[item.key] = item.value;
          return acc;
        }, {});
    }
    return routingPolicyDto;
  }

  static toDTOs(routingPolicies: RoutingPolicy[]): RoutingPolicyDto[] {
    return (
      routingPolicies &&
      routingPolicies.map((routingPolicy) =>
        RoutingPolicyMapper.toDTO(routingPolicy),
      )
    );
  }

  static toDomainConditions(
    routingPolicyDto: RoutingPolicyDto,
  ): RoutingPolicyCondition[] {
    const RoutingPolicyConditions = [];

    if (routingPolicyDto.headers) {
      for (const [key, value] of Object.entries(routingPolicyDto.headers)) {
        RoutingPolicyConditions.push(
          this.toCondition(ERoutingPolicyType.HEADERS, key, value),
        );
      }
    }

    if (routingPolicyDto.httpMethods) {
      RoutingPolicyConditions.push(
        this.toCondition(
          ERoutingPolicyType.HTTP_METHODS,
          undefined,
          routingPolicyDto.httpMethods,
        ),
      );
    }

    if (routingPolicyDto.path) {
      RoutingPolicyConditions.push(
        this.toCondition(
          ERoutingPolicyType.PATH,
          undefined,
          routingPolicyDto.path,
        ),
      );
    }

    if (routingPolicyDto.origin) {
      RoutingPolicyConditions.push(
        this.toCondition(
          ERoutingPolicyType.ORIGIN,
          undefined,
          routingPolicyDto.origin,
        ),
      );
    }

    return RoutingPolicyConditions;
  }

  static toCondition(type, key, value) {
    return {
      id: uuid(),
      type,
      key,
      value,
    };
  }

  static toDomain(routingPolicyDtos: RoutingPolicyDto[]): RoutingPolicy[] {
    return (
      routingPolicyDtos &&
      routingPolicyDtos.map((routingPolicyDto) => {
        const routingPolicy = {
          id: uuid(),
          order: routingPolicyDto.order,
          url: routingPolicyDto.url,
          conditions: this.toDomainConditions(routingPolicyDto),
          escapePath: routingPolicyDto.escapePath,
        };

        return routingPolicy;
      })
    );
  }
}
