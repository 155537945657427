export default {
  modalTitle: "Deactivate API Key publication",
  title: (secretManager) =>
    `You are about to delete your API Keys from ${secretManager}`,
  subtitle: "Be aware that this action cannot be undone.",
  notificationTitle: "API Keys will be deleted from Vault",
  notificationDescription: (secretManager, environment) =>
    `All your API Keys will be deleted from your ${secretManager} namespace for the ${environment} environment and will not be usable anymore from your Application. You can impact your instance if your Application still need them to start.`,
  confirmationTitle: (appName) =>
    `If you really want to proceed, type **${appName}**, then confirm.`,
  confirmationLabel: "Type the application name",

  successToastTitle: (secretManager) =>
    `Deletion API Keys from ${secretManager}`,
  successToastDescription: (secretManager) =>
    `Your API keys has been deleted from ${secretManager}.`,

  impossibleToCleanSecretNamespaceTitle: (secretManager) =>
    `Impossible to clean your ${secretManager} namespace`,

  impossibleToCleanSecretNamespaceDescription: (secretPath) =>
    `It seems DevPortal has no more the rights to write on your namespace ${secretPath}.`,

  solutions: (secretManager, userDocLink) =>
    "Three solutions for you:\n\n" +
    `* You already cleaned your ${secretManager} namespace by yourself: just confirm the deactivation.\n` +
    "* You will clean your namespace by yourself (do it now!): just confirm the deactivation.\n" +
    `* You reassign rights to DevPortal on your namespace (see docs + [link](${userDocLink})): cancel the process and retry it later.`,

  confirmBtnCleanedSecretNamespace: (secretManager) =>
    `I confirm my ${secretManager} is already cleaned`,

  userDocumentationAnchor: "#deactivate-vault-publication",
};
