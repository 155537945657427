import { decodeHTML } from "@/commons/libs/utils/html";

/**
 * @author https://alligator.io/vuejs/vue-router-modify-head/
 * @param to
 * @param from
 * @param next
 */

export function updateMeta(meta) {
  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el && el.parentNode && el.parentNode.removeChild(el),
  );

  meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, decodeHTML(tagDef[key]));
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head && document.head.appendChild(tag));
}

export function updatePageTitle(title) {
  document.title = decodeHTML(title);
}

export function routerUpdatePageMeta(to) {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    updatePageTitle(nearestWithTitle.meta.title);
  }

  if (nearestWithMeta != undefined) {
    // Turn the meta tag definitions into actual elements in the head.
    updateMeta(nearestWithMeta.meta);
  }
}
