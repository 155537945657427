<template>
  <ConfirmModalBody
    class="refused-subscription-modal"
    :confirmBtnLabel="contents.submitBtn"
    confirmBtnTheme="solid"
    @onSubmit="submit"
    @onClose="$emit('onClose')"
  >
    <div>
      <h5>
        {{ contents.title }}
      </h5>
      <div>
        {{ contents.message }}
      </div>
    </div>
  </ConfirmModalBody>
</template>

<script lang="ts">
import ConfirmModalBody from "@/commons/components/Modal/ConfirmModalBody.vue";

import contents from "@/dashboard/contents/refused-subscription-modal";

export default {
  components: {
    ConfirmModalBody,
  },
  emits: ["onClose"],
  data() {
    return {
      contents,
    };
  },
  methods: {
    submit() {
      this.$emit("onClose");
      this.$router.push({ name: "applicationSettings" });
    },
  },
};
</script>
