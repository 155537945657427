<template>
  <MToggle
    :id="toggleId"
    :modelValue="checked"
    :disabled="disabled"
    :label="label"
    @click.prevent="onClick()"
  />
</template>

<script lang="ts">
import MToggle from "@mozaic-ds/vue-3/src/components/toggle/MToggle.vue";

export default {
  components: {
    MToggle,
  },
  props: {
    checked: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["click"],
  computed: {
    toggleId(): string {
      return `toggle-${this.$.uid}`;
    },
  },
  methods: {
    onClick() {
      if (this.disabled === false) {
        this.$emit("click");
      }
    },
  },
};
</script>
