import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3eb83866"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "adornment" }
const _hoisted_2 = {
  key: 0,
  class: "adornment__prefix"
}
const _hoisted_3 = {
  key: 1,
  class: "adornment__suffix"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!!_ctx.$slots.prefix)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          _renderSlot(_ctx.$slots, "prefix", {}, undefined, true)
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    (!!_ctx.$slots.suffix)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
          _renderSlot(_ctx.$slots, "suffix", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ]))
}