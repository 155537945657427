<script lang="ts" setup>
import { computed, PropType } from "vue";
import { useStore } from "vuex";

import Divider from "@/commons/components/Divider.vue";
import LayoutContentHeader from "@/commons/components/LayoutContentHeader.vue";
import ContactApiOwnersButton from "@/dashboard/components/ContactApiOwnersButton.vue";
import ReportingLinkButton from "@/dashboard/components/ReportingLinkButton.vue";
import SecretSynchronizationStatus from "@/dashboard/components/SecretSynchronizationStatus.vue";

import { date } from "@/commons/libs/utils/dateUtils";
import {
  DASHBOARD_ROUTE_NAME,
  getPreviousPageRoute,
} from "@/commons/utils/route-utils";

import { Application } from "@/commons/domain/models/application";

import contents from "@/dashboard/contents/application-header";

const props = defineProps({
  application: {
    type: Object as PropType<Application>,
    required: true,
  },
});

const store = useStore();

const previousPageRoute = computed(() =>
  getPreviousPageRoute(DASHBOARD_ROUTE_NAME),
);

const routeToProduct = computed(
  () => `/docs/product/${props.application?.product?.id}`,
);

const userIsInternal = computed(() => {
  return store.getters["userIsInternal"];
});

const displaySecretSynchronizationStatus = computed(() => {
  return (
    userIsInternal.value && props.application?.secretSynchronization?.isEnabled
  );
});
</script>

<template>
  <LayoutContentHeader
    :title="application.name"
    :previousPageRoute="previousPageRoute"
  >
    <div v-if="application" class="application-header__infos">
      <span v-if="application.createdAt" class="application-header__info">
        <span class="application-header__info-label">
          {{ contents.createdAt }}:
        </span>
        <span>
          {{ date(application.createdAt, "DD/MM/YYYY - HH:mm") }}
        </span>
      </span>
      <span class="application-header__info">
        <span
          v-if="application.updatedAt"
          class="application-header__info-label"
        >
          {{ contents.updatedAt }}:
        </span>
        <span>
          {{ date(application?.updatedAt, "DD/MM/YYYY - HH:mm") }}
        </span>
      </span>
      <span v-if="application.product" class="application-header__info">
        <span class="application-header__info-label">
          {{ contents.relatedProduct }}:
        </span>
        <router-link :to="routeToProduct">{{
          application.product.name
        }}</router-link>
      </span>
    </div>
    <template #actions>
      <ReportingLinkButton :application="application" />
      <template v-if="displaySecretSynchronizationStatus">
        <Divider class="application-header__actions-divider" vertical />
        <SecretSynchronizationStatus
          :applicationSecretSynchronization="application?.secretSynchronization"
        />
        <Divider class="application-header__actions-divider" vertical />
      </template>
      <ContactApiOwnersButton :application="application" />
    </template>
  </LayoutContentHeader>
</template>

<style lang="scss">
.application-header__infos {
  @include set-text-s;
  display: flex;

  flex: 1;
  flex-wrap: wrap;
  padding-bottom: 1rem;
  row-gap: 0.75rem;
}

.application-header__info {
  margin-right: 1rem;
}

.application-header__info-label {
  color: var(--color-grey);
}

.application-header__actions-divider {
  height: 2rem;
}
</style>
