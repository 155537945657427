<!--suppress CssUnknownTarget -->
<template>
  <nav class="top-navbar">
    <div class="top-navbar__menu-wrapper">
      <div class="top-navbar__logo">
        <RouterLink :to="{ name: 'home' }" :title="contents.homeTitle">
          <img :src="logo" class="top-navbar__logo--default" />
          <img :src="logoSmall" class="top-navbar__logo--small" />
        </RouterLink>
      </div>
      <div class="top-navbar__menu">
        <DropdownMenu
          v-for="(menu, index) in navigationMenus"
          v-bind="menu"
          :key="`${componentId}-menu-${index}`"
          :isActive="menu.realm === $route.meta.realm"
        />
      </div>
    </div>
    <div v-if="userIsLogged" class="top-navbar__settings-wrapper">
      <div class="top-navbar__settings">
        <DropdownMenu
          v-bind="moreMenu"
          key="more-information-menu"
          :isActive="moreMenu.realm === $route.meta.realm"
          menuPadding="0"
        />
        <RequestManagerMenuLabel />
        <DropdownMenu
          v-bind="userProfileMenu"
          key="user-profile-menu"
          :isActive="userProfileMenu.realm === $route.meta.realm"
          menuPadding="0"
          @logout="logout"
        >
          <template #header>
            <div v-if="user" class="top-navbar__user-profile-header">
              <div class="top-navbar__user-profile-title">{{ user.name }}</div>
              <div
                v-if="userIsInternal"
                class="top-navbar__user-profile-subtitle"
              >
                {{ contents.adeo }}
              </div>
            </div>
          </template>
        </DropdownMenu>
      </div>
      <div class="top-navbar__environment">
        <DeploymentSelector
          v-if="userIsInternal"
          class="top-navbar__env-selector"
          :disabled="$route.meta.crossEnv !== true"
        />
      </div>
    </div>
    <div v-else class="top-navbar__signin-block">
      <span class="top-navbar__devportal-slogan">{{
        contents.devportalSlogan
      }}</span>
      <MButton
        v-if="isNotOnLoginPage"
        class="top-navbar__signin-btn"
        :label="contents.buttonLoginLabel"
        @click="goToLoginPage"
      />
    </div>
  </nav>
</template>

<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import moment from "moment";

import DropdownMenu from "@/commons/components/Menu/DropdownMenu.vue";
import RequestManagerMenuLabel from "@/commons/components/RequestManagerMenuLabel.vue";

import DeploymentSelector from "./DeploymentSelector.vue";

import { getMoreMenuConf } from "@/commons/libs/get-more-menu-conf";
import { getNavigationMenuConf } from "@/commons/libs/get-navigation-menu-conf";
import { getThematicLogo } from "@/commons/libs/get-thematic-logo";
import { getUserMenuConf } from "@/commons/libs/get-user-menu-conf";

import { DropdownMenuItem } from "@/commons/store/types";

import contents from "@/commons/contents/top-navbar";

const logoSmall = require("@/commons/assets/images/logo-portal-small.svg");

export default {
  name: "TopNavbar",
  components: {
    DropdownMenu,
    DeploymentSelector,
    MButton,
    RequestManagerMenuLabel,
  },
  data() {
    return {
      contents,
      logo: getThematicLogo(
        moment().format("YYYY"),
        moment().format("MM"),
        moment().format("DD"),
      ),
      logoSmall,
    };
  },
  computed: {
    componentId() {
      return `top-navbar-${this.$.uid}`;
    },
    supportUrl() {
      return this.$store.getters["config/supportUrl"];
    },
    user() {
      return this.$store.getters["user"];
    },
    frontendConfig() {
      return this.$store.getters["config/frontendConfig"];
    },
    userIsLogged() {
      return this.$store.getters["userIsLogged"];
    },
    userIsInternal() {
      return this.$store.getters["userIsInternal"];
    },
    userIsAdmin() {
      return this.$store.getters["userIsAdmin"];
    },
    navigationMenus(): DropdownMenuItem[] {
      if (!this.userIsLogged) {
        return [];
      }
      return getNavigationMenuConf(this.userIsInternal, this.userIsAdmin);
    },
    moreMenu(): DropdownMenuItem {
      return getMoreMenuConf(
        this.frontendConfig.slackUrl,
        this.supportUrl,
        this.user,
        this.frontendConfig.guidelinesUrl,
      );
    },
    userProfileMenu(): DropdownMenuItem {
      return getUserMenuConf();
    },
    isOnlyAllowingPingLogin(): boolean {
      return this.$store.getters["config/isOnlyAllowingPingLogin"];
    },
    isNotOnLoginPage(): boolean {
      return this.$route.name !== "login";
    },
  },
  methods: {
    async logout() {
      await this.$store.dispatch("logout");
      if (this.$route.meta.secured) {
        this.$router.push({
          name: "login",
        });
      }
    },
    goToLoginPage() {
      if (this.isOnlyAllowingPingLogin) {
        // if we don't have any other choice than ping, we can directly go there
        window.location.href = `${this.frontendConfig.backendUrl}/auth/ping`;
      } else {
        this.$router.push({
          name: "login",
          query: { redirect: this.$route.fullPath },
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/commons/assets/styles/vars";
.top-navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--navbar-height);
  padding: 0 0.5rem;
  border-bottom: var(--border-default);
}
.top-navbar__menu-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
}
.top-navbar__menu {
  display: flex;
  height: 100%;
}
.top-navbar__settings-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
}
.top-navbar__settings {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}
.top-navbar__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: var(--layout-navbar-width);
  min-height: var(--navbar-height);
  margin: 0 auto;
}

.top-navbar__logo {
  padding: 0;
  padding-right: 1rem;
  padding-left: 2rem;
}

.top-navbar__logo--default {
  width: 11rem;
}
.top-navbar__logo--default,
.top-navbar__env-selector {
  display: none;
}

.top-navbar__logo--small {
  height: 4.5rem;
}

.top-navbar__application-menus {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}
.top-navbar__user {
  margin-right: 0.8em;
  margin-left: 2em;
}
.top-navbar__user-buttons {
  display: flex;
}
.top-navbar__user-menu .menu-item__label {
  font-weight: 400;
}
.top-navbar__user .user-menu .user-menu__name strong {
  display: block;
  max-width: 10em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.top-navbar__login {
  max-width: 150px;
}

@media screen and (width >= 72em) {
  .top-navbar__env-selector {
    display: flex;
  }
}

@media screen and (width >= 96em) {
  .top-navbar__logo--small {
    display: none;
  }
  .top-navbar__logo--default {
    display: block;
  }
}

.dropdown-menu {
  position: relative;
}
.top-navbar__login.button.button--primary {
  background-color: var(--color-grey);
  border: 0;
}

.top-navbar__signin-block {
  display: flex;
  align-items: center;
}

.top-navbar__devportal-slogan {
  margin-right: 2rem;
}

.top-navbar__user-profile-header {
  @include set-text-s;
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 1rem;
  cursor: default;
  border-bottom: var(--border-default);
}

.top-navbar__user-profile-title {
  margin-bottom: 0.25rem;
  font-weight: 700;
}

.top-navbar__user-profile-subtitle {
  color: var(--color-text-minor);
}

.top-navbar__signin-btn {
  margin-right: 1rem;
}
</style>
