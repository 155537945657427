import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SettingsForm = _resolveComponent("SettingsForm")!
  const _component_Organization = _resolveComponent("Organization")!
  const _component_Timeout = _resolveComponent("Timeout")!
  const _component_SensitiveModifications = _resolveComponent("SensitiveModifications")!
  const _component_ManageApiGroups = _resolveComponent("ManageApiGroups")!
  const _component_Grid = _resolveComponent("Grid")!

  return (!$options.isLoading)
    ? (_openBlock(), _createBlock(_component_Grid, {
        key: 0,
        class: "settings-api"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_SettingsForm, { currentApi: $options.currentApi }, null, 8, ["currentApi"]),
          _createVNode(_component_Organization, { currentApi: $options.currentApi }, null, 8, ["currentApi"]),
          _createVNode(_component_Timeout, { currentApi: $options.currentApi }, null, 8, ["currentApi"]),
          _createVNode(_component_SensitiveModifications, { currentApi: $options.currentApi }, null, 8, ["currentApi"]),
          _createVNode(_component_ManageApiGroups)
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}