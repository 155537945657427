export function validateComponentProps(component, propsToValidate = {}) {
  const componentProps = component.props;

  const invalidRequiredProps =
    componentProps &&
    Object.keys(componentProps).filter((propName) => {
      return (
        componentProps[propName].required &&
        !Object.prototype.hasOwnProperty.call(propsToValidate, propName)
      );
    });

  const invalidProps =
    propsToValidate &&
    Object.keys(propsToValidate).filter(
      (propToValidateName) =>
        Object.prototype.hasOwnProperty.call(
          componentProps,
          propToValidateName,
        ) &&
        componentProps[propToValidateName].validate &&
        !componentProps[propToValidateName].validate(
          propsToValidate[propToValidateName],
        ),
    );

  return invalidRequiredProps.length === 0 && invalidProps.length === 0;
}
