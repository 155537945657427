export default {
  publicAccessibilityLabel: "Public",
  publicAccessibilityTooltip:
    "This URL is accessible from outside the company network",
  internalAccessibilityLabel: "Internal",
  internalAccessibilityTooltip:
    "This URL is only accessible inside the company network",
  ipFilteredRouteTooltip:
    "This Public Route is reachable only from authorized IPs.",
};
