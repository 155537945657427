import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "acls-manager" }
const _hoisted_2 = { class: "acls-manager__search" }
const _hoisted_3 = { class: "acls-manager__list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchGroup = _resolveComponent("SearchGroup")!
  const _component_NotificationWarningMCCGroup = _resolveComponent("NotificationWarningMCCGroup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h6", null, _toDisplayString($data.contents.searchLabel), 1),
      _createVNode(_component_SearchGroup, {
        suggestions: $data.suggestions,
        addGroupBtnLabel: $data.contents.btnLabel,
        vertical: "",
        onSearchGroups: $options.searchGroups,
        onAddGroup: $options.addGroup,
        onOnSelect: $options.onGroupSelection
      }, null, 8, ["suggestions", "addGroupBtnLabel", "onSearchGroups", "onAddGroup", "onOnSelect"]),
      ($data.displayMCCWarning)
        ? (_openBlock(), _createBlock(_component_NotificationWarningMCCGroup, {
            key: 0,
            class: "acls-manager__warning"
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("h6", null, _toDisplayString($props.listHeader), 1),
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createElementVNode("p", null, _toDisplayString($props.listEmptyStateMessage), 1)
      ])
    ])
  ]))
}