<template>
  <MOptionCard
    :key="keyId"
    class="dvp-option-card"
    v-bind="$attrs"
    @update:modelValue="onUpdateModelValue"
  >
    <slot />
  </MOptionCard>
</template>

<script setup lang="ts">
import MOptionCard from "@mozaic-ds/vue-3/src/components/optioncard/MOptionCard.vue";
import { v4 as uuid } from "uuid";
import { computed, ref } from "vue";

/**
 * Wrapper of MOptionCard allowing us to reset checkbox and radio inputs
 * More info:
 * checkbox and radio inputs can be desynchronized with the value we give them.
 * For example: we click on a checkbox to select it, the checkbox will be checked and an event will be sent but if we decide the value shouldn't change for some reason,
 * the input would still be checked even though we did not change the value.
 * In that case we have to force a re-rendering so the checkbox is synchronized with its value again.
 * This re-rendering is done by updating the key property
 */

const emit = defineEmits(["update:modelValue"]);

const keyCount = ref(0);

const keyId = computed((): string => `${uuid()}-${keyCount.value}`);

function resetInputsToModelValue() {
  keyCount.value++;
}

function onUpdateModelValue(newValue: boolean): void {
  resetInputsToModelValue(); // The input element could have changed its selection state when it was clicked so we force a resynchronization with its modelValue
  emit("update:modelValue", newValue);
}
</script>
