<template>
  <div class="showcase">
    <router-view></router-view>
  </div>
</template>

<script lang="ts">
export default {
  name: "Showcase",
};
</script>
