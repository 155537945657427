import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "pushed-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_AutoCompleteSelect = _resolveComponent("AutoCompleteSelect")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { class: "organization" }, {
    default: _withCtx(() => [
      _createElementVNode("h4", null, _toDisplayString($data.contents.title), 1),
      _createElementVNode("form", {
        class: "organization__form",
        onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => ($options.submit && $options.submit(...args)), ["prevent"]))
      }, [
        _createElementVNode("div", null, [
          ($options.platformDescriptionTag)
            ? (_openBlock(), _createBlock(_component_FormInput, {
                key: 0,
                label: $data.contents.platformLabel,
                "data-cy": "platform",
                tooltip: $data.contents.tooltipPlatform
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_TextInput, {
                    modelValue: $options.platformDescriptionTag,
                    isDisabled: ""
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["label", "tooltip"]))
            : _createCommentVNode("", true),
          _createVNode(_component_FormInput, {
            class: "split-after",
            label: $data.contents.businessUnit,
            tooltip: $data.contents.tooltipBu,
            error: $data.form.firstError('businessUnitTags')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AutoCompleteSelect, {
                modelValue: $data.form.businessUnitTags,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.form.businessUnitTags) = $event)),
                "label-property": "label",
                "key-property": "id",
                multiple: "",
                options: $options.buTagsList,
                placeholder: $data.contents.businessUnitsPlaceholder
              }, null, 8, ["modelValue", "options", "placeholder"])
            ]),
            _: 1
          }, 8, ["label", "tooltip", "error"])
        ]),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_MButton, {
            theme: "bordered-neutral",
            label: $data.contents.cancel,
            onClick: $options.cancel
          }, null, 8, ["label", "onClick"]),
          _createVNode(_component_MButton, {
            label: $data.contents.apply,
            disabled: !!$data.form.errors.length || $data.form.pristine,
            type: "submit"
          }, null, 8, ["label", "disabled"])
        ])
      ], 32)
    ]),
    _: 1
  }))
}