<template>
  <nav class="secondary-navbar">
    <div class="secondary-navbar__header">
      <slot name="secondary-navbar-header" />
    </div>
    <div class="secondary-navbar__body">
      <slot name="secondary-navbar-body" />
    </div>
  </nav>
</template>

<script lang="ts">
export default {
  name: "SecondaryNavbar",
  props: {},
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.secondary-navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--secondary-navbar-height);
  padding: 0 2.5rem;
  border-bottom: var(--border-default);
}
.secondary-navbar__body {
  display: flex;
  align-items: center;
  height: 100%;
}
</style>
