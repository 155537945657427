import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "api-info-for-navbar" }
const _hoisted_2 = { class: "api-info-for-navbar__api-title" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h5", _hoisted_2, _toDisplayString($options.apiTitle), 1),
    ($options.apiProduct)
      ? (_openBlock(), _createElementBlock("em", _hoisted_3, [
          _createTextVNode(_toDisplayString($data.contents.fromProduct) + " ", 1),
          _createVNode(_component_router_link, {
            class: "link",
            to: {
          name: 'productDocumentationDetail',
          params: { id: $options.apiProduct.id },
        }
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($options.apiProduct.name), 1)
            ]),
            _: 1
          }, 8, ["to"])
        ]))
      : _createCommentVNode("", true)
  ]))
}