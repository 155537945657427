<script setup lang="ts">
import contents from "@/manager/contents/toggle-expand-description-button";

defineProps({
  isExpanded: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(["expandScope", "hideScope"]);
</script>

<template>
  <div class="toggle-expand-description-button">
    <div
      v-if="isExpanded"
      class="toggle-expand-description-button__expand-desc"
      @click="emit('hideScope')"
    >
      {{ contents.hideDescLabel }}
    </div>
    <div
      v-else
      class="toggle-expand-description-button__expand-desc"
      @click="emit('expandScope')"
    >
      {{ contents.expandDescLabel }}
    </div>
  </div>
</template>

<style lang="scss">
.toggle-expand-description-button {
  width: 3rem;
}

.toggle-expand-description-button__expand-desc {
  color: var(--color-primary);
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
}
</style>
