import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tags-applied-filters"
}
const _hoisted_2 = { class: "tags-applied-filters__list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Divider = _resolveComponent("Divider")!
  const _component_MTagList = _resolveComponent("MTagList")!
  const _component_MLink = _resolveComponent("MLink")!

  return ($options.items.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Divider),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createVNode(_component_MTagList, {
              items: $options.items,
              max: 10,
              onRemoveTag: $options.removeFilter
            }, null, 8, ["items", "onRemoveTag"])
          ]),
          _createVNode(_component_MLink, {
            "data-cy": "reset-all-filters",
            size: "s",
            onClick: $options.resetFilters
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($data.contents.resetButtonLabel), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]))
    : _createCommentVNode("", true)
}