import { Commit } from "vuex";

import { buildPayload } from "@/commons/store/helpers/build-payload";
import { updatePayload } from "@/commons/store/helpers/update-payload";

import { Dictionary } from "@/commons/domain/models/dictionary";

import { State as RootState } from "@/commons/store/types";

/**
 * Update the store using different replacement modes
 * Note: this function takes data objects as parameters, not responses like deprecatedFinishAction() does
 */
export function saveOnStore({
  commit,
  path = [],
  propertiesToReplace = {},
  propertiesToMerge = {},
  propertiesDictionaryToMerge = {},
}: {
  commit: Commit;
  path?: string | string[];
  propertiesToReplace?: Dictionary<any>;
  propertiesToMerge?: Dictionary<any>;
  propertiesDictionaryToMerge?: Dictionary<Dictionary<{ id: string }>>;
}) {
  const pathToProperties = Array.isArray(path) ? path : [path];

  const payload: Partial<RootState> = buildPayload(pathToProperties);
  // resetPayload is for resetting the payload properties to undefined because otherwise the payload properties will be merged with the new values
  const resetPayload: Partial<RootState> = buildPayload(pathToProperties);

  Object.entries(propertiesToReplace).forEach(([propertyName, value]) => {
    updatePayloadsUsingReplaceMode(
      payload,
      resetPayload,
      pathToProperties,
      propertyName,
      value,
    );
  });

  Object.entries(propertiesToMerge).forEach(([propertyName, value]) => {
    updatePayloadsUsingMergeMode(
      payload,
      resetPayload,
      pathToProperties,
      propertyName,
      value,
    );
  });

  Object.entries(propertiesDictionaryToMerge).forEach(
    ([propertyName, value]) => {
      updatePayloadsUsingDictionaryMergeMode(
        payload,
        resetPayload,
        pathToProperties,
        propertyName,
        value,
      );
    },
  );

  // Commit the payloads
  commit("patchState", resetPayload);
  commit("patchState", payload);
}

/**
 * Will erase a property and completely replace it by the new value
 * stateProp: { currentApi : { id: id1, prop1: p1 }} replaced with newValue = { id: id2, prop2: p2 } will result in stateProp: { currentApi : { id: id2, prop2: p2 } (no prop1 entry)}
 */
function updatePayloadsUsingReplaceMode(
  payload,
  resetPayload,
  pathToProperties,
  propertyName,
  value,
) {
  updatePayload(resetPayload, propertyName, undefined, pathToProperties);
  updatePayload(payload, propertyName, value, pathToProperties);
}

/**
 * Will replace a dictionary id entry by the new value base on the id property
 * stateProp: { contracts : { [id1]: c1, [id2]: c2 }} merged with newValue = { id: id1...} will result in stateProp: { contracts : { [id1]: newValue, [id2]: c2 }}
 */
function updatePayloadsUsingMergeMode(
  payload,
  resetPayload,
  pathToProperties,
  propertyName,
  value,
) {
  updatePayload(
    resetPayload,
    propertyName,
    { [value.id]: undefined },
    pathToProperties,
  );

  updatePayload(payload, propertyName, { [value.id]: value }, pathToProperties);
}

/**
 * Will merge 2 dictionaries together
 * stateProp: { tags : { id1: t1a, id2: t2a }} merged with newValue = { id1: t1b, id3: t3b } will result in stateProp: { tags : { id1: t1b, id2: t2a, id3: t3b }}
 */
function updatePayloadsUsingDictionaryMergeMode(
  payload,
  resetPayload,
  pathToProperties,
  propertyName,
  value,
) {
  const dictionary: Dictionary<{ id: string }> = value;
  const resetDictionary: Dictionary<{ id: string }> = {};

  for (const key in dictionary) {
    resetDictionary[key] = undefined;
  }

  updatePayload(resetPayload, propertyName, resetDictionary, pathToProperties);
  updatePayload(payload, propertyName, dictionary, pathToProperties);
}
