<script setup lang="ts">
import { computed, ref } from "vue";

import ButtonCopyCredentials from "@/commons/components/Buttons/ButtonCopyCredentials.vue";
import IconButton from "@/commons/components/IconButton.vue";

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  content: {
    type: String,
    required: true,
  },
});

const hideContent = ref(true);

const computedContent = computed(() => {
  return hideContent.value
    ? "••••••••••••••••••••••••••••••••••••"
    : props.content;
});
</script>

<template>
  <div class="token-copy-content">
    <div class="token-copy-content__title">
      {{ label }}
    </div>
    <div class="token-copy-content__content">
      <ButtonCopyCredentials :content="content" />
      <div
        class="token-copy-content__secret-container"
        @mouseover="hideContent = false"
        @mouseout="hideContent = true"
      >
        <IconButton mozaicIconName="DisplayView24" />
        <div class="token-copy-content__secret">{{ computedContent }}</div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.token-copy-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  &__title {
    font-size: 14px;
    font-weight: 700;
  }

  &__content {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }

  &__secret-container {
    display: flex;
    gap: 0.5rem;
    align-items: center;

    height: 2.5rem;

    &:hover {
      cursor: pointer;
    }
  }

  &__secret {
    width: 13rem;
  }
}
</style>
