<template>
  <div class="add-policy-button">
    <MButton icon="ControlCircleMore32" :label="label" @click="add" />
  </div>
</template>

<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";

export default {
  name: "AddPolicyButton",
  components: {
    MButton,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
  },
  emits: ["add"],
  methods: {
    add() {
      this.$emit("add");
    },
  },
};
</script>

<style lang="scss">
.add-policy-button {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 1rem 0;
  background-color: var(--color-m-secondary-light);
  border: 1px dashed var(--color-light-grey);
  border-radius: var(--m-border-radius-large);
}
</style>
