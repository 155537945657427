<template>
  <div class="contract-warning">
    <div class="contract-warning__title">
      <MIcon class="contract-warning__title-icon" :name="iconName" />
      <span class="contract-warning__title-text">{{ title }}</span>
    </div>

    <span v-if="subtitle != undefined" class="contract-warning__message">{{
      subtitle
    }}</span>

    <span v-if="message != undefined" class="contract-warning__message">{{
      message
    }}</span>

    <div
      v-if="$slots.default != undefined"
      class="contract-warning__custom-section"
    >
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import MIcon from "@mozaic-ds/vue-3/src/components/icon/MIcon.vue";

import { EContractStatus } from "@/commons/store/types";

export default {
  components: {
    MIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: undefined,
    },
    message: {
      type: String,
      default: undefined,
    },
    iconName: {
      type: String,
      default: "NotificationWarning24",
    },
  },
  data() {
    return {
      EContractStatus,
    };
  },
};
</script>

<style lang="scss">
.contract-warning {
  display: flex;
  flex-direction: column;

  gap: 0.5rem;
}

.contract-warning__title {
  display: flex;
  gap: 0.5rem;

  align-items: center;
}

.contract-warning__message {
  color: var(--color-text-minor);
}

.contract-warning__title-text {
  font-weight: var(--weight-bold);
}

.contract-warning__custom-section {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  gap: 0.75rem;
}
</style>
