export default {
  addScopeTitle: "Add a scope",

  // steps
  selectedItemsLabel: (selectedItemsCount) => `${selectedItemsCount} selected`,
  selectScopeStepLabel: "Select a scope",
  validateScopeStepLabel: "Configure and Validate your scope",

  // Headers
  documentedScopesHeader: "Scopes used in your Swagger documentation",
  referencedScopesHeader: "Scopes from the Scope Referential",

  // Actions
  referenceNewScopeBtnLabel: "Reference a new scope",
  searchScopeLabel: "Scope",
  searchScopePlaceholder: "Ex. orders.items:lmfr:read",

  // datatable
  scopeNameTableHeader: "Scope name",
  scopeDescriptionTableHeader: "Description",
  scopeProductsTableHeader: "Used by",

  scopeAlreadyAddedTooltip: (scopeName) =>
    `The scope ${scopeName} is already added`,

  noAnyResult: "No scope found",
  titleNothingToDisplay: "There is nothing to display",
  uploadDocumentation:
    "Upload your Swagger/OpenAPI documentation first, to then quick add your scopes from it.",
  goToApiDocumentationBtnLabel: "Go to API documentation",
  noScopeInSwaggerDocumentation:
    "Your Swagger/OpenAPI documentation does not contain any scope to add to your API.",
  swaggerDocumentationUpdate:
    "Maybe document them in before, then update it in DevPortal and come back here to have all them synchronized.",

  invalidFormatScopeIdentifierTooltip:
    "This scope identifier does not respect the expected format.",
  referentialDescriptionTooltip:
    "If you select this scope, this description (taken from the Scope Referential) will be used, rather than the one from the Swagger documentation.",

  // footer actions
  cancelBtnLabel: "Cancel",
  nextBtnLabel: "Next",
  backBtnLabel: "Back",
  validateBtnLabel: "Validate",

  // Validate scope card
  idScopeCardLabel: "ID Scope",
  descriptionCardLabel: "Description",
  usedByProductsCardLabel: "Used by",

  // Edit description
  editDescriptionTitle: "Would you like to change the scope description?",
  editDescriptionBtnLabel: "Edit description",
  minLengthDescriptionErrorMessage:
    "Description must be at least 30 characters",
  maxLengthDescriptionErrorMessage:
    "Description must be at most 500 characters",
  validateDescriptionBtnLabel: "Validate description",
  updatedDescriptionTagLabel: "Description updated",
  successfullyUpdatedScopeDescriptionMessage: "Scope description updated",
  writingGuidelinesTitle: "Writing guidelines",
  writingGuidelinesContent:
    "In order to well document your description, please follow these guidelines:\n" +
    "* Describe the functional perimeter covered by your scope\n" +
    "* Do not forget your scope could be used by several APIs and/or Products\n",
  warningTitle: "Warning",
  warningContent:
    "The description will be modified not only for this API but also for all the other ones using it.",
  warningImpactedProducts:
    "If you update this description, it will impact these products:\n",

  // Reference a new scope
  configureYourScopeTitle: "Configure your scope",
  typeYourScopeSubTitle:
    "Type your scope ID respecting the structure: Element, Sub-element, BU, Action.",
  elementLabel: "Element",
  subElementLabel: "Sub-element",
  businessUnitsLabel: "Business units",
  actionLabel: "Action",
  elementPlaceholder: "For example: order",
  subElementPlaceholder: "For example: items",
  businessUnitsPlaceholder: "Select a BU",
  actionPlaceholder: "Select an action",
  yourScopeIdWillBeLabel: "Your scope ID will be:",
  descriptionLabel: "Description",
  descriptionPlaceholder: "Insert your description here",
  scopeBestPracticeNotificationTitle: "Scope best practice",
  scopeBestPracticeNotificationContent:
    "A common way to get started with scopes is to use a combination of the type of resource and the access required on it:\n" +
    "* Resource Type: **order**\n" +
    "* Access Level: **read**\n" +
    "* Example Value: **orders:read**\n",

  // Error messages
  scopeElementRequiredErrorMessage: "Element is required",
  scopeElementCharactersErrorMessage:
    "Element must contain only lowercase letters",
  scopeActionRequiredErrorMessage: "Action is required",
  scopeSubElementCharactersErrorMessage:
    "Sub-element must contain only lowercase letters and dots as separators",

  // Toast messages
  successMessage: "Scope added to your API.",
};
