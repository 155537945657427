import { Dictionary } from "@/commons/domain/models/dictionary";
import { Tag } from "@/commons/domain/models/tag";
import { PagedResourceDto } from "@/commons/dtos/paged-resource-dto";
import { TagDto } from "@/commons/dtos/tag-dto";

export class TagsMapper {
  static toDomain(tagsDtoPaged: PagedResourceDto<TagDto>): Dictionary<Tag> {
    const tags: Dictionary<Tag> = {};

    for (const tagDto of tagsDtoPaged.data) {
      tags[tagDto.id] = TagsMapper.toTagDomain(tagDto);
    }

    return tags;
  }
  static toApiDomain(tagsDtos: TagDto[] = []): Dictionary<Tag> {
    const tags: Dictionary<Tag> = {};

    for (const tagDto of tagsDtos) {
      tags[tagDto.id] = TagsMapper.toTagDomain(tagDto);
    }

    return tags;
  }
  static toTagDomain(tagDto: TagDto): Tag {
    return {
      id: tagDto.id,
      isRemoved: tagDto.isRemoved,
      label: tagDto.label,
      type: tagDto.type,
      isManaged: tagDto.isManaged,
      description: tagDto.description,
      weight: tagDto.weight,
    };
  }
  static toDto(tagsList: Tag[]) {
    return tagsList.map((tag) => tag.id);
  }
}
