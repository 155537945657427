import { v4 as uuid } from "uuid";

import { CustomQueryParam } from "@/commons/domain/models/custom-query-param";
import { Dictionary } from "@/commons/domain/models/dictionary";
import { CustomQueryParamDto } from "@/commons/dtos/custom-query-param-dto";

export class CustomQueryParamsMapper {
  static toDomain(customQueryParamDtos: CustomQueryParamDto[] = []) {
    const customQueryParams: Dictionary<CustomQueryParam> = {};

    for (const customQueryParamDto of customQueryParamDtos) {
      const customQueryParamId = uuid();

      customQueryParams[customQueryParamId] = {
        id: customQueryParamId,
        name: customQueryParamDto.name,
        value: customQueryParamDto.value,
        order: customQueryParamDto.order,
      };
    }

    return customQueryParams;
  }

  static toDto(customQueryParams: Dictionary<CustomQueryParam>) {
    const customQueryParamDtos: CustomQueryParamDto[] = [];

    for (const customQueryParam of Object.values(customQueryParams)) {
      customQueryParamDtos.push({
        name: customQueryParam.name,
        value: customQueryParam.value,
        order: customQueryParam.order,
      });
    }

    return customQueryParamDtos;
  }
}
