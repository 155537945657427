<template>
  <WithFixedHeader
    :class="[
      'layout',
      { 'layout--with-secondary-navbar': hasSecondaryNavbar },
      { 'layout--with-side-menu': hasSideMenu },
      { 'layout--with-side-menu-header': hasSideMenuHeader },
      { 'layout--with-extra-panel': hasExtraPanel },
      { 'layout--extra-panel-collapsed': isCollapsedExtraPanel },
    ]"
  >
    <div
      :class="[
        'layout__navbars',
        { 'layout__navbars--highlighted': !isBegginingOfScreen },
      ]"
    >
      <TopNavbar />
      <slot name="secondaryNavbar" />
    </div>

    <div class="layout__wrapper">
      <div class="layout__panel">
        <div v-if="hasSideMenu && !hideSideMenu" class="layout__sidebar">
          <div v-if="hasSideMenuHeader" class="layout__side-menu-header">
            <slot name="sideMenuHeader" />
          </div>
          <div class="layout__side-menu">
            <slot name="sideMenu" />
          </div>
        </div>

        <div class="layout__content-wrapper">
          <slot name="beforeContent" />
          <Box class="layout__content">
            <slot />
          </Box>
          <div class="layout__footer">
            <slot name="footer" />
          </div>
        </div>
      </div>

      <div class="layout__extra-panel">
        <slot name="extraPanel" />
      </div>
    </div>

    <OverlayLoader
      v-if="!hideLoader && isLoadingAnything"
      class="layout__overlay-loader"
    />
  </WithFixedHeader>
</template>

<script lang="ts">
import Box from "@/commons/components/Box.vue";
import OverlayLoader from "@/commons/components/OverlayLoader.vue";
import TopNavbar from "@/commons/components/TopNavbar.vue";
import WithFixedHeader from "@/commons/components/WithFixedHeader.vue";

// @Deprecated use Layout2 instead
export default {
  name: "Layout",
  components: {
    TopNavbar,
    OverlayLoader,
    WithFixedHeader,
    Box,
  },
  props: {
    hideLoader: {
      type: Boolean,
      default: false,
    },
    hideSideMenu: {
      type: Boolean,
      default: false,
    },
    isCollapsedExtraPanel: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isBegginingOfScreen: true,
    };
  },
  computed: {
    hasSideMenu() {
      return !!this.$slots.sideMenu;
    },
    hasSideMenuHeader() {
      return !!this.$slots.sideMenuHeader;
    },
    hasExtraPanel() {
      return !!this.$slots.extraPanel;
    },
    hasSecondaryNavbar() {
      return !!this.$slots.secondaryNavbar;
    },
    isLoadingAnything(): boolean {
      return this.$store.getters.isLoading;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        const { openModal, openLayerModal } = to.meta;

        if (openModal) {
          this.$store.commit("openModal", openModal);
        }
        if (openLayerModal) {
          this.$store.commit("openLayerModal", openLayerModal);
        }
      },
    },
  },
  mounted() {
    this.updateIsBegginingOfScreen();
    window.addEventListener("wheel", this.updateIsBegginingOfScreen);
    window.addEventListener("scroll", this.updateIsBegginingOfScreen);
  },
  unmounted() {
    window.removeEventListener("wheel", this.updateIsBegginingOfScreen);
    window.removeEventListener("scroll", this.updateIsBegginingOfScreen);
  },
  methods: {
    updateIsBegginingOfScreen() {
      this.isBegginingOfScreen = window.scrollY === 0;
    },
  },
};
</script>

<style lang="scss" scoped>
// 'scoped' is used to prevent style leaking into <layout2>, we will remove <layout> and its style and its scope after migrating everything into <layout2>
.layout {
  --cover-layout-padding: 0;
  position: relative;
  min-height: 100vh;
}

.layout__overlay-loader {
  position: absolute;
  top: calc(50% - (10rem / 2));
  left: calc(50% - (10rem / 2));
  z-index: var(--z-index-foreground);
}

.layout__navbars {
  background-color: var(--color-white);
}

.layout__navbars.layout__navbars--highlighted {
  box-shadow: var(--shadow-default);
}

.layout__panel {
  display: flex;
  width: 65%;
}

.layout__extra-panel {
  position: fixed;
  top: calc(var(--navbar-height) + var(--secondary-navbar-height));
  right: 0;
  bottom: 0;
  width: 35%;
  overflow: hidden auto;
}

.layout__sidebar {
  position: sticky;
  top: calc(var(--navbar-height));
  z-index: var(--z-index-foreground);
  display: flex;
  flex-direction: column;
  height: calc(100vh - var(--navbar-height));
}

.layout__content-wrapper {
  position: relative;
  flex-grow: 1;
  overflow: inherit;
}

.layout__side-menu-header {
  height: var(--layout-side-menu-header-height);
  background-color: var(--color-white);
  border-right: var(--border-default);
  border-bottom: var(--border-default);
}

.layout__side-menu {
  flex-basis: calc(100vh - var(--navbar-height));
}

.layout__content {
  margin-right: auto;
  margin-left: auto;
}

.layout--with-secondary-navbar .layout__sidebar {
  top: calc(var(--navbar-height) + var(--secondary-navbar-height));
}

.layout--with-secondary-navbar .layout__side-menu {
  flex-basis: calc(
    100vh - var(--navbar-height) - var(--secondary-navbar-height)
  );
}

.layout--extra-panel-collapsed .layout__panel {
  width: 100%;
}

.layout--extra-panel-collapsed .layout__extra-panel {
  width: 0;
}

.layout--with-secondary-navbar .layout__extra-panel {
  top: calc(var(--navbar-height) + var(--secondary-navbar-height));
}

@media screen and (width >= 72em) {
  .layout__content {
    max-width: var(--layout-content-width);
  }
}
</style>
