import contents from "./request-manager-contents.js";

import { ERequestType } from "@/commons/store/types";

export function isMine(request, currentUserId) {
  return request.author && request.author.id === currentUserId;
}

export function requestLabel(request, shouldCreateLinkTag = false) {
  const ui = contents.ui.summary.titles;
  switch (request.type) {
    case ERequestType.NEW_TOKEN:
      return ui.newToken(request, shouldCreateLinkTag);
    case ERequestType.NEW_CONTRACT:
      return ui.newContract(request, shouldCreateLinkTag);
    case ERequestType.UPDATE_ACCESS_POLICY:
      return ui.accessPolicyUpdate(request, shouldCreateLinkTag);
    case ERequestType.OTHER_GATEWAYS:
      return ui.getOtherGateways(request, shouldCreateLinkTag);
    case ERequestType.TIMEOUT:
      return ui.timeout(request, shouldCreateLinkTag);
    case ERequestType.SWITCH_TO_API_KEY:
      return ui.switchToApiKey(request, shouldCreateLinkTag);
  }
}

export function requestStatus(request) {
  return contents.ui.status[request.status];
}
