<script setup lang="ts">
import MTextInput from "@mozaic-ds/vue-3/src/components/textinput/MTextInput.vue";
import { computed, onMounted, PropType, ref } from "vue";
import { useStore } from "vuex";

import DvpField from "@/commons/components/form/DvpField.vue";
import LayerModalFormConfirm from "@/commons/components/form/LayerModalFormConfirm.vue";
import Markdown from "@/documentation/components/commons/Markdown.vue";

import { getApiLabelWithVersion } from "@/commons/libs/utils/apiUtils";

import { ConfirmActionForm } from "@/commons/domain/forms/confirm-action-form";
import { Api } from "@/commons/domain/models/api";
import { findContractsWithActivatedSecretSynchronizations } from "@/commons/services/contract/contract.service";

import contents from "@/manager/contents/delete-api-modal";

const props = defineProps({
  api: {
    type: Object as PropType<Api>,
    required: true,
  },
});
const emit = defineEmits(["onClose"]);

const store = useStore();

const apiId = computed(() => props.api?.id);
const apiName = computed(() => props.api?.name);
const apiLabel = computed(() => getApiLabelWithVersion(props.api));

const form = ConfirmActionForm.create();

const applicationsWithEnabledSecretSync = ref([]);

onMounted(async () => {
  await form.init({ confirmationText: apiName.value });

  applicationsWithEnabledSecretSync.value = (
    await findContractsWithActivatedSecretSynchronizations(apiId.value)
  ).map((contract) => contract.application.name);
});

const isSaving = computed(() => {
  return store.getters["isSaving"];
});

const submit = async () => {
  await form.validate();
  if (!isSaving.value && !form.errors.length) {
    await store.dispatch("deleteApi", {
      apiId: apiId.value,
    });
  }
};
</script>

<template>
  <LayerModalFormConfirm
    class="delete-api-modal"
    :disabledConfirmBtn="!!form.errors.length || isSaving"
    @submit="submit"
    @close="emit('onClose')"
  >
    <h4>{{ contents.title(apiLabel) }}</h4>
    <p class="delete-api-modal__warning">
      {{ contents.warning }}
    </p>
    <div class="delete-api-modal__content">
      <p>
        {{ contents.subtitle }}
      </p>
      <div v-if="applicationsWithEnabledSecretSync.length > 0">
        <p>
          {{ contents.applicationsWithActivatedSecretSyncMessage }}
        </p>
        <ul>
          <li
            v-for="application in applicationsWithEnabledSecretSync"
            :key="application"
          >
            {{ application }}
          </li>
        </ul>
      </div>
    </div>

    <Markdown :content="contents.prompt(apiName)" />

    <DvpField :label="contents.confirmApiNameLabel">
      <MTextInput
        v-model="form.text"
        :placeholder="apiName"
        :isDisabled="isSaving"
        :isInvalid="form.firstError('text') != undefined"
      />
    </DvpField>
  </LayerModalFormConfirm>
</template>

<style lang="scss">
.delete-api-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--base-spacing);
  height: 100%;
}

.delete-api-modal__warning {
  color: var(--color-status-danger);
}
</style>
