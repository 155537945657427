<template>
  <div class="responsive-icon-button">
    <MTooltip
      v-if="showSmallButton"
      :id="tooltipId"
      :label="computedTooltipText"
      :position="tooltipPosition"
    >
      <MButton v-bind="$attrs" />
    </MTooltip>
    <MButton v-else v-bind="$attrs" :label="label" />
  </div>
</template>

<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import MTooltip from "@mozaic-ds/vue-3/src/components/tooltip/MTooltip.vue";

import { getCssBreakpointAsNumber } from "@/commons/utils/css-breakpoints-utils";

const defaultBreakpointWidth = getCssBreakpointAsNumber("tablet");

export default {
  // So we don't duplicate attrs on MButton and the root div (fix a problem with duplication of data-cy attribute)
  components: {
    MTooltip,
    MButton,
  },
  inheritAttrs: false,
  props: {
    breakpointWidth: {
      type: Number,
      default: undefined,
    },
    label: {
      type: String,
      required: true,
    },
    tooltipPosition: {
      type: String,
      default: "bottom",
    },
    tooltipText: {
      type: String,
      default: undefined,
    },
    alwaysSmall: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentWindowWidth: window.innerWidth,
    };
  },
  computed: {
    tooltipId(): string {
      return `responsive-icon-button-${this.$.uid}`;
    },
    computedTooltipText(): string {
      return this.tooltipText !== undefined ? this.tooltipText : this.label;
    },
    computedBreakpointWidth(): number {
      return this.breakpointWidth !== undefined
        ? this.breakpointWidth
        : defaultBreakpointWidth;
    },
    showSmallButton(): boolean {
      return (
        this.alwaysSmall ||
        this.currentWindowWidth <= this.computedBreakpointWidth
      );
    },
  },
  beforeMount() {
    window.addEventListener("resize", this.onWindowResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onWindowResize);
  },
  methods: {
    onWindowResize(): void {
      this.currentWindowWidth = window.innerWidth;
    },
  },
};
</script>

<style lang="scss">
.responsive-icon-button {
  display: inline-flex;
}
</style>
