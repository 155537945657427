import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "authentication-mode-settings__card-header" }
const _hoisted_2 = { class: "authentication-mode-settings__card-title" }
const _hoisted_3 = {
  key: 0,
  class: "authentication-mode-settings__pending-request"
}
const _hoisted_4 = { class: "authentication-mode-settings__pending-request-link-label" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "authentication-mode-settings__change-section-description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MBadge = _resolveComponent("MBadge")!
  const _component_MIcon = _resolveComponent("MIcon")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_AuthenticationModeOptionCard = _resolveComponent("AuthenticationModeOptionCard")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_CardContainer = _resolveComponent("CardContainer")!

  return (_openBlock(), _createBlock(_component_CardContainer, { class: "authentication-mode-settings" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h4", _hoisted_2, _toDisplayString($data.contents.authModeTitle), 1),
        ($props.pendingAuthTypeChangeRequest != undefined)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_MBadge, {
                class: "authentication-mode-settings__pending-request-badge",
                type: "warning"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($data.contents.pendingRequestBadge), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_RouterLink, {
                class: "authentication-mode-settings__pending-request-link",
                to: $options.getAuthTypeChangeRequestRoute($props.pendingAuthTypeChangeRequest)
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_4, _toDisplayString($data.contents.viewRequest), 1),
                  _createVNode(_component_MIcon, {
                    name: "DisplayExternalLink16",
                    class: "authentication-mode-settings__pending-request-link-icon"
                  })
                ]),
                _: 1
              }, 8, ["to"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_AuthenticationModeOptionCard, {
        "data-cy": "authentication-mode-option-card",
        title: $props.optionCardTitle,
        description: $props.optionCardDescription,
        documentationAnchor: $props.authTypeDocAnchor
      }, null, 8, ["title", "description", "documentationAnchor"]),
      ($props.pendingAuthTypeChangeRequest == undefined)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("p", _hoisted_6, _toDisplayString($props.authChangeIntro), 1),
            _createVNode(_component_MButton, {
              "data-cy": "change-authentication-mode-button",
              label: $props.authChangeButtonLabel,
              onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.onClickChangeAuthMode()))
            }, null, 8, ["label"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}