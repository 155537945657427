import actions from "./actions";
import getters from "./getters";
import config from "./modules/config";
import notification from "./modules/notification";
import reporting from "./modules/reporting";
import user from "./modules/user";
import mutations from "./mutations";
import requestManager from "@/request-manager/store";
import { createStore } from "vuex";

import { State } from "./types";

const state: State = {
  isLoading: {},
  isSaving: {},
  actionFailing: "",
  openModal: undefined,
  modalType: undefined,
  zones: {},
  products: {
    count: 0,
    currentPage: 0,
    data: {},
    totalCount: 0,
    totalPages: 0,
  },
  scopesProducts: {
    count: 0,
    currentPage: 0,
    data: {},
    totalCount: 0,
    totalPages: 0,
  },
  tags: {},
  apis: {
    count: 0,
    currentPage: 0,
    data: {},
    totalCount: 0,
    totalPages: 0,
  },
  previousRoute: undefined,
};

export default createStore({
  modules: {
    config: {
      namespaced: true,
      ...config,
    },
    reporting: {
      namespaced: true,
      ...reporting,
    },
    user,
    requestManager,
    notification,
  },
  state() {
    return state;
  },
  mutations,
  actions,
  getters,
});
