import {
  ConfigurableCookieAcceptanceMapType,
  cookieCategories,
  CookieCategory,
  ECookieCategoryType,
} from "./cookies-banner";
import moment from "moment";

const DEFAULT_PATH = "/";
const DELETE_COOKIE_EXPIRATION_DATE = "Thu, 01 Jan 1970 00:00:00 GMT";
const COOKIE_ACCEPTANCE_NAME = "cookieAcceptance";

export function hasCookieAcceptance(): boolean {
  return getCookieAcceptance() != undefined;
}

export function statisticIsAccepted(): boolean {
  return isAccepted(ECookieCategoryType.STATISTIC);
}

export function isAccepted(categoryType: ECookieCategoryType): boolean {
  const cookie = getCookieAcceptanceValue();

  return cookie
    ? cookie
        .split(",")
        .includes(convertToValueForCookieAcceptance(categoryType))
    : false;
}

export function applyCookieAcceptance(
  acceptanceByCategory: ConfigurableCookieAcceptanceMapType,
): void {
  const acceptedCategoryTypes = [
    ECookieCategoryType.REQUIRED,
    ...getCookieCategoryTypesHavingAcceptanceValue(acceptanceByCategory, true),
  ];

  document.cookie = composeCookie(
    COOKIE_ACCEPTANCE_NAME,
    acceptedCategoryTypes.map(convertToValueForCookieAcceptance),
    DEFAULT_PATH,
    get1YearExpirationDate(),
  );
}

function convertToValueForCookieAcceptance(
  categoryType: ECookieCategoryType,
): string {
  return categoryType.toLowerCase();
}

function getCookieCategoryTypesHavingAcceptanceValue(
  acceptanceByCategory: ConfigurableCookieAcceptanceMapType,
  acceptanceValue: boolean,
): ECookieCategoryType[] {
  return (
    (Object.entries(acceptanceByCategory) as [ECookieCategoryType, boolean][])
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([categoryType, isAccepted]) => isAccepted === acceptanceValue)
      .map(([categoryType]) => categoryType)
  );
}

function getCookieNamesFromCategories(cookieCategoryTypes: string[]): string[] {
  return cookieCategoryTypes.flatMap(getCookieNamesFromCategory);
}

function getCookieNamesFromCategory(cookieCategoryType: string): string[] {
  const matchingCategory = cookieCategories.find(
    (cookieCategory) => cookieCategory.type === cookieCategoryType,
  );

  return (
    matchingCategory?.cookiesDetails.map((cookieDetail) => cookieDetail.name) ||
    []
  );
}

export function getConfigurableCookieAcceptanceByCategoryType(): ConfigurableCookieAcceptanceMapType {
  const acceptanceByCategoryType: ConfigurableCookieAcceptanceMapType = {};

  cookieCategories.forEach((cookieCategory) => {
    if (cookieCategory.configurable) {
      acceptanceByCategoryType[cookieCategory.type] = isAccepted(
        cookieCategory.type,
      );
    }
  });

  return acceptanceByCategoryType;
}

export function getAcceptedCookies(): string[] {
  return getCookieNamesFromCategories(
    Object.values(ECookieCategoryType).filter(isAccepted),
  );
}

export function getCookiesToKeep(): string[] {
  return [COOKIE_ACCEPTANCE_NAME, ...getAcceptedCookies()];
}

export function getConfigurableCookieCategories(): CookieCategory[] {
  return cookieCategories.filter((category) => category.configurable);
}

export function removeUnnecessaryCookies(): void {
  const cookiesToKeep = getCookiesToKeep();

  const cookiesToDelete = getAllCookies().filter(
    (cookie) => cookiesToKeep.includes(cookie) === false,
  );

  deleteCookies(cookiesToDelete);
}

function deleteCookies(cookieNames: string[]): void {
  cookieNames.forEach(deleteCookie);
}

export function deleteCookie(cookieName: string): void {
  document.cookie = composeCookie(
    cookieName,
    [],
    DEFAULT_PATH,
    DELETE_COOKIE_EXPIRATION_DATE,
  );
}

function composeCookie(
  name: string,
  values: string[],
  path: string,
  expirationDate: string,
): string {
  const value = values && values.length > 0 ? values.join(",") : "";
  return `${name}=${value}; path=${
    path ? path : DEFAULT_PATH
  }; expires=${expirationDate}`;
}

// Cookies duration => One year - the date format have to be in UTC
function get1YearExpirationDate(): string {
  return moment().utc(false).add(1, "year").toString();
}

function getCookieAcceptance(): string | undefined {
  return getCookie(COOKIE_ACCEPTANCE_NAME);
}

function getCookieAcceptanceValue(): string | undefined {
  return getCookieAcceptance()?.split("=")[1];
}

export function getCookie(name): string | undefined {
  const tempArray: string[] = [];
  const matches = document.cookie.match(
    new RegExp(
      "(?:^|; )" + name.replace(/([.$?*|{}()[]\\\/\+^])/g, "\\$1") + "=([^;]*)",
    ),
  );
  if (matches) {
    matches.map((e) => {
      if (e !== "") {
        if (e.startsWith(";")) {
          e = e.substr(1);
        }
        if (e.startsWith(" ")) {
          e = e.substr(1);
        }
        if (e.startsWith(name)) {
          tempArray.push(e);
        }
      }
    });
  }
  return tempArray.length ? decodeURIComponent(tempArray[0]) : undefined;
}

export function getCookieValue(name: string): string | undefined {
  const cookie = getCookie(name);
  return cookie != undefined ? cookie.split("=")[1] : undefined;
}

function getAllCookies(): string[] {
  return document.cookie.split("; ").map((cookie) => cookie.split("=")[0]);
}
