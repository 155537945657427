import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "icon-label__label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MIcon = _resolveComponent("MIcon")!

  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["icon-label", $options.dynamicClasses])
  }, [
    _createVNode(_component_MIcon, { name: $props.iconName }, null, 8, ["name"]),
    _createElementVNode("span", _hoisted_1, _toDisplayString($props.label), 1)
  ], 2))
}