<template>
  <MTabsV2
    v-bind="$attrs"
    :tabs="tabsV2Items"
    :modelValue="tabIndexMatchingCurrentEnumValue"
    @update:modelValue="onTabIndexChange"
  />
</template>

<script setup lang="ts">
import { DvpTabsItem } from "./DvpTabs.type";
import MTabsV2 from "@mozaic-ds/vue-3/src/components/tabs/MTabsV2.vue";
import { computed } from "vue";

import { MTabsV2Item } from "@/commons/domain/models/mozaic";

/**
 * Wrapper of MTabsV2 that makes it possible to work with enum string values instead of index number
 */

interface Props {
  tabs: DvpTabsItem[];
  modelValue: string;
}

const props = defineProps<Props>();
const emit = defineEmits(["update:modelValue"]);

const tabsV2Items = computed((): MTabsV2Item[] =>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  props.tabs.map(({ value, ...propsForTabsV2Item }) => propsForTabsV2Item),
);

const tabIndexMatchingCurrentEnumValue = computed((): number =>
  props.tabs.findIndex((tab) => tab.value === props.modelValue),
);

function onTabIndexChange(newTabIndex: number): void {
  emit("update:modelValue", props.tabs[newTabIndex].value);
}
</script>
