<template>
  <Stack class="api-alerts">
    <MNotification
      v-for="(alert, index) in checkList"
      :key="`alert-message-${index}`"
      :title="alert.label"
      :type="alert.alertType"
      :link="alert.route"
      :link-content="alert.routeLabel"
    >
      {{ alert.message }}
    </MNotification>
  </Stack>
</template>

<script lang="ts">
import MNotification from "@mozaic-ds/vue-3/src/components/notification/MNotification.vue";

import Stack from "@/commons/components/Stack.vue";

import contents from "@/manager/contents/settings";

export default {
  name: "Settings",
  components: { Stack, MNotification },
  props: {
    checkList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      ui: contents,
    };
  },
};
</script>
