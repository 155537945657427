import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Cluster = _resolveComponent("Cluster")!
  const _component_CardFooter = _resolveComponent("CardFooter")!

  return ($options.hasItems)
    ? (_openBlock(), _createBlock(_component_CardFooter, {
        key: 0,
        class: "card-footer-api"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Cluster, null, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _: 3
          })
        ]),
        _: 3
      }))
    : _createCommentVNode("", true)
}