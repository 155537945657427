import { Dictionary } from "@/commons/domain/models/dictionary";
import { EntryPoint } from "@/commons/domain/models/entry-point";
import { OAuthDefinition } from "@/commons/domain/models/oauth-definition";
import { EntryPointDto } from "@/commons/dtos/entry-point-dto";
import { OAuthDefinitionDto } from "@/commons/dtos/oauth-definition-dto";
import { ZoneWithRoutesDto } from "@/commons/dtos/zone-with-routes-dto";

export class EntryPointsMapper {
  static toDomain(zoneWithRoutesDto: ZoneWithRoutesDto) {
    const entryPoints: Dictionary<EntryPoint> = {};

    for (const entryPointDto of zoneWithRoutesDto.entryPoints) {
      entryPoints[entryPointDto.id] = {
        ...entryPointDto,
        oauthDefinitions: this.toOAuthDefinitionDomain(
          entryPointDto.oauthDefinitions,
        ),
      };
    }

    return entryPoints;
  }
  static toDto(routeEntryPointsList: EntryPoint[]) {
    const routeEntryPointsDtos: EntryPointDto[] = [];

    for (const routeEntryPoint of routeEntryPointsList) {
      routeEntryPointsDtos.push({
        id: routeEntryPoint.id,
        label: routeEntryPoint.label,
        isPublic: routeEntryPoint.isPublic,
        isEnabled: routeEntryPoint.isEnabled,
        url: routeEntryPoint.url,
        oauthDefinitions: this.toOAuthDefinitionsDto(
          routeEntryPoint.oauthDefinitions,
        ),
      });
    }

    return routeEntryPointsDtos;
  }
  static toOAuthDefinitionDomain(
    oauthDefinitionDtos: OAuthDefinitionDto[] = [],
  ) {
    const oauthDefinitions: Dictionary<OAuthDefinition> = {};
    oauthDefinitionDtos.forEach((oauthDefinitionDto) => {
      oauthDefinitions[oauthDefinitionDto.provider] = {
        provider: oauthDefinitionDto.provider,
        tokenUrl: oauthDefinitionDto.tokenUrl,
        authorizationUrl: oauthDefinitionDto.authorizationUrl,
      };
    });
    return oauthDefinitions;
  }
  static toOAuthDefinitionsDto(oauthDefinitions: Dictionary<OAuthDefinition>) {
    return Object.values(oauthDefinitions).map((oauthDefinition) => {
      return {
        provider: oauthDefinition.provider,
        tokenUrl: oauthDefinition.tokenUrl,
        authorizationUrl: oauthDefinition.authorizationUrl,
      };
    });
  }
}
