module.exports = {
  api: "API",
  application: "Application",
  latency: "Latency",
  method: "Method",
  status: "Status",
  timestamp: "Timestamp",
  uri: "URI",
  zone: "Zone",
  tooltipUri:
    "Reporting is displaying aggregated logs. URIs are not available. You need to go back on the last 3 days to have standard logs",
};
