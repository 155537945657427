export default {
  batchesManagementTitle: "Batch Manual Runner",
  batchesManagementDescription:
    "Here, you can manually run batches. Simply select your batch name and click on 'Run' button. \n" +
    "For more information about the batch, please go to the documentation in the link bellow.",
  readDocumentationLink: "Read documentation",
  runBtnLabel: "Run Batch",
  warningMessage: "This feature is only available for DevPortal team members.",
  launchedSyncToastTitle: "Batch launched",
  launchedSyncToastMessage: "Wait for the end...",
  selectBatchName: "Select a batch name",
  followBatchExecutionStatus: "Follow the batch execution status",
};
