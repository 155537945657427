import { RouteLocationNormalized, RouteLocationRaw } from "vue-router";

import { EModalType } from "@/commons/components/Modal/modal.type";

import { EHttpMethod } from "@/commons/utils/http-method";

import { Api } from "@/commons/domain/models/api";
import { Contract } from "@/commons/domain/models/contract";
import { Dictionary } from "@/commons/domain/models/dictionary";
import { PagedResource } from "@/commons/domain/models/paged-resource";
import { Product } from "@/commons/domain/models/product";
import { EWindowLengthUnit } from "@/commons/domain/models/rate-limit";
import { Tag } from "@/commons/domain/models/tag";
import { Zone } from "@/commons/domain/models/zone";

import { State as ConfigState } from "./modules/config/types";
import { State as UserState } from "./modules/user/types";
import { State as AdminState } from "@/admin/store/types";
import { State as DashboardState } from "@/dashboard/store/types";
import { State as DocumentationState } from "@/documentation/store/types";
import { State as ApiState } from "@/manager/store/types";
import { State as requestManagerState } from "@/request-manager/store/types";

export interface GlobalModal {
  title: string;
  component: any;
  props: any;
  listeners?: Record<string, Function>;
  redirectOnClose: string;
}

export enum EApiType {
  API_REST = "API_REST",
  TOPIC = "TOPIC",
}

export enum EApiSubtype {
  INTERNAL = "INTERNAL",
  APP = "APP",
  KSTREAM = "KSTREAM",
  EVENT = "EVENT",
  CONNECT = "CONNECT",
  EXCHANGE = "EXCHANGE",
  PIPELINE = "PIPELINE",
  UNKNOWN = "UNKNOWN",
}

export enum EApiTypeLabel {
  API_REST = "API REST",
  TOPIC = "TOPIC",
}

export enum EApiInternalLabel {
  API = "API",
  BACKEND = "BACKEND",
}

export enum EApplicationType {
  SANDBOX = "SANDBOX",
  ALL = "ALL",
}

export enum EContractStatus {
  CREATED = "CREATED", // The contract is not active yet, the validation of an administrator is mandatory
  ENABLED = "ENABLED", // The contract is active and the associated tokens can be used to access the API
  DISABLED = "DISABLED", // The contract is not active anymore
  DENIED = "DENIED", // The contract request was denied by an administrator
  CANCELLED = "CANCELLED", // The contract was cancelled by requester
}

export const CONTRACT_STATUSES_FOR_APPLICATION_CONTRACTS_LISTING = [
  EContractStatus.CREATED,
  EContractStatus.ENABLED,
  EContractStatus.DISABLED,
];

export enum EAccessLevel {
  READ_WRITE = "READ_WRITE",
  READ_ONLY = "READ_ONLY",
}

export interface UserAcl {
  action: EAccessLevel;
  id: string;
  isAdmin: boolean;
  isInternal: boolean;
  name: string;
}

export interface GroupAcl {
  id: string;
  name: string;
  action: EAccessLevel;
  role?: ERole;
}

export interface Acl {
  users: UserAcl[];
  groups: GroupAcl[];
}

export interface IRoute {
  id: string;
  sourceUrl: string;
  targetUrl: string;
}

export interface IZone {
  id: string;
  name: string;
  description: string;
  sourceBaseUrl: string;
  monitored: boolean;
  routes: IRoute[] | null;
  isInternal?: boolean;
}

export enum EAccessPolicy {
  PRIVATE = "PRIVATE",
  SELF_SERVICE = "SELF_SERVICE",
  ON_DEMAND = "ON_DEMAND",
}

export enum EPrivacyName {
  PRIVATE = "PRIVATE",
  INTERNAL = "INTERNAL",
  PARTNER = "PARTNER",
  PUBLIC = "PUBLIC",
}

export interface ICors {
  isEnabled: boolean;
  allowOrigins: string[];
  allowMethods: string[];
  allowHeaders: string[];
  allowCredentials: boolean;
}

export interface IQueryParam {
  id: string;
  name: string;
  value: string;
}

export interface IPolicyOrder {
  id: string;
  type: string;
  name: string;
  order: number;
}

export enum ERateLimitLevel {
  API = "API",
  DEFAULT = "DEFAULT",
  CONTRACT = "CONTRACT",
}

export enum ERateLimitType {
  GLOBAL,
  SPECIFIC,
}

export interface IRateLimit<T extends ERateLimitType> {
  type: T;
  isEnabled?: boolean;
  maxRequests?: number;
  windowLength?: number;
  windowLengthUnit?: EWindowLengthUnit;
  exists?: boolean;
  label?: string;
  description?: string;
}

export interface IGlobalRateLimit extends IRateLimit<ERateLimitType.GLOBAL> {}

export interface ISpecificRateLimit
  extends IRateLimit<ERateLimitType.SPECIFIC> {
  httpMethod: EHttpMethod;
  pathRegex: string;
  range: number; // keeps track of the specific rate limit in the original array
}

export interface IRateLimits {
  global: IGlobalRateLimit;
  specifics: ISpecificRateLimit[];
  isDefault?: boolean; // if set to true, then this is the rate limit definition for new contracts to the API
  exists?: boolean; // if set to true, then this rate limit was fetched from backend, else it needs to be created
  level: ERateLimitLevel;
}

export enum ETokenStatus {
  ENABLED = "ENABLED",
  PENDING = "PENDING",
  EXPIRED = "EXPIRED",
  DISABLED = "DISABLED",
}

export interface IApi {
  id: string;
  name: string;
  label: string;
  description: string;
  type: EApiType;
  internal: boolean;
  accessPolicy: EAccessPolicy;
  deprecated: boolean;
  enabled: boolean;
  used: boolean | null;
  isPublished: boolean | null;
  isRemoved: boolean | null;
  isDeprecated: boolean | null;
  isMonitored: boolean | null;
  isValidationRequired: boolean | null;
  rateLimits?: {
    global: Partial<IGlobalRateLimit>;
    specifics: Array<Partial<ISpecificRateLimit>>;
  };
  rateLimitsForNewContracts: IRateLimits;
  createdAt: string;
  updatedAt: string;
  deprecationDate: string;
  version: number;
  zones: IZone[];
  customProxyHeaders: object;
  cors: ICors;
  customQueryParams: IQueryParam[];
  policiesOrder: IPolicyOrder[];
  acl: Acl;
  currentUserAction: EAccessLevel;
  pendingRequests: any[]; // any because backend model is on its way to change _any_way
  timeout: number;
}

export interface State {
  isLoading: { [propertyName: string]: boolean };
  isSaving: { [propertyName: string]: boolean };
  actionFailing: string;
  openModal?: GlobalModal;
  modalType?: EModalType;
  api?: Partial<ApiState>;
  documentation?: Partial<DocumentationState>;
  dashboard?: Partial<DashboardState>;
  config?: Partial<ConfigState>;
  user?: Partial<UserState>;
  requestManager?: Partial<requestManagerState>;
  admin?: Partial<AdminState>;
  zones: Dictionary<Zone>;
  products: PagedResource<Product>;
  scopesProducts: PagedResource<Product>;
  tags: Dictionary<Tag>;
  apis: PagedResource<Api>;
  previousRoute: RouteLocationNormalized;
}

export enum ETagType {
  BU = "BU",
  PLATFORM = "PLATFORM",
  DATACENTER = "DATACENTER",
  GATEWAY = "GATEWAY",
}

export enum ETextInputType {
  TEXT = "text",
  PASSWORD = "password",
  NUMBER = "number",
}

export enum EAuthType {
  OAUTH = "OAUTH",
  API_KEY = "API_KEY",
}

export enum EPublicationType {
  PUBLISHED = "published",
  NOT_PUBLISHED = "not_published",
}

export enum EApiPublicationStatus {
  ERROR = "ERROR",
  NOT_PUBLISHED = "NOT_PUBLISHED",
  NOT_SYNCHRONIZED = "NOT_SYNCHRONIZED",
  SYNCHRONIZING = "SYNCHRONIZING",
  SYNCHRONIZED = "SYNCHRONIZED",
  WAITING_CONSUMERS = "WAITING_CONSUMERS",
}

export enum ERequestStatus {
  APPROVAL_REQUIRED = "APPROVAL_REQUIRED",
  APPROVED = "APPROVED",
  DENIED = "DENIED",
  CANCELLED = "CANCELLED",
}

export enum ERequestType {
  NEW_CONTRACT = "NEW_CONTRACT",
  UPDATE_ACCESS_POLICY = "UPDATE_ACCESS_POLICY",
  OTHER_GATEWAYS = "OTHER_GATEWAYS",
  TIMEOUT = "TIMEOUT",
  SWITCH_TO_API_KEY = "SWITCH_TO_API_KEY",
}

export enum ERequestFilteringVisibility {
  RECEIVED = "RECEIVED",
  SUBMITTED = "SUBMITTED",
  ALL_VALIDABLE_BY_ADMIN = "ALL_VALIDABLE_BY_ADMIN",
}

export enum EPolicyOrderType {
  HEADER = "HEADER",
  PATH = "PATH",
  QUERY_PARAM = "QUERY_PARAM",
}

export enum ERole {
  ADMIN = "ADMIN",
  INTERNAL = "INTERNAL",
  TECHNICAL = "TECHNICAL",
  MANAGER = "MANAGER",
  VIEWER = "VIEWER",
  PARTNER = "PARTNER",
}

export enum ERouteTypes {
  DOWNSTREAM = "DOWNSTREAM",
  UPSTREAM = "UPSTREAM",
}

export enum ERouteStatus {
  PENDING_GATEWAY = "PENDING_GATEWAY",
  DISABLED = "DISABLED",
  ENABLED = "ENABLED",
  ERROR = "ERROR",
}

export enum EAction {
  READ = "READ",
  WRITE = "WRITE",
}

export interface Contracts {
  [type: string]: {
    data: Array<Contract>;
    currentPage: number;
    count: number;
    totalPages: number;
    totalCount: number;
  };
}

export enum ERoutingPolicyType {
  HEADERS = "headers",
  HTTP_METHODS = "httpMethods",
  ORIGIN = "origin",
  PATH = "path",
}

export interface DropdownMenuItem {
  label?: string;
  urlOrRoute?: string | RouteLocationRaw;
  isExternalLink?: boolean;
  items?: MenuItem[];
  realm?: string;
  mIconName?: string;
}

export interface MenuItem {
  label: string;
  description?: string;
  urlOrRoute?: string | RouteLocationRaw;
  isExternalLink?: boolean;
  iconName?: string;
  iconLocalPath?: string;
  openInNewWindow?: boolean;
  emitEventOnClick?: string;
  withBottomBorder?: boolean;
}

export enum EQuotaType {
  API_QUOTA = "API_QUOTA",
  CONSUMERS_QUOTA = "CONSUMERS_QUOTA",
}

export enum EContractStatusFilter {
  ABOUT_TO_EXPIRE = "ABOUT_TO_EXPIRE",
  DISABLED = "DISABLED",
  EXPIRED = "EXPIRED",
}

export enum EApiStatusFilter {
  DEPRECATED = "DEPRECATED",
}

export enum EOAuthScopeAction {
  READ = "read",
  WRITE = "write",
  ADMIN = "admin",
}

export enum EApplicationTab {
  API_KEY = "API_KEY",
  OAUTH_CCF = "OAUTH_CCF",
  OAUTH_ACF = "OAUTH_ACF",
}

export enum ESecretSynchronizationStatus {
  SYNCHRONIZED = "SYNCHRONIZED",
  NOT_SYNCHRONIZED = "NOT_SYNCHRONIZED",
}
