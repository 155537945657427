import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContractRemoveButton = _resolveComponent("ContractRemoveButton")!
  const _component_MNotification = _resolveComponent("MNotification")!
  const _component_ContractWarning = _resolveComponent("ContractWarning")!
  const _component_ContractCardContainer = _resolveComponent("ContractCardContainer")!

  return (_openBlock(), _createBlock(_component_ContractCardContainer, { class: "contract-warning-disabled" }, {
    default: _withCtx(() => [
      _createVNode(_component_ContractWarning, {
        title: $data.contents.subrscribeDisabled($options.apiLabel($props.contract.api)),
        message: $data.contents.message
      }, {
        default: _withCtx(() => [
          ($options.userHasWriteAccessOnCurrentApplication)
            ? (_openBlock(), _createBlock(_component_ContractRemoveButton, {
                key: 0,
                contract: $props.contract,
                onContractDeleted: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('contractDeleted')))
              }, null, 8, ["contract"]))
            : _createCommentVNode("", true),
          ($options.hasOnlyOneRemainingOAuthContract($props.contract))
            ? (_openBlock(), _createBlock(_component_MNotification, {
                key: 1,
                class: "contract-disabled__warning-notification",
                type: "warning"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($data.contents.additionalWarningMessage), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["title", "message"])
    ]),
    _: 1
  }))
}