import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardListed = _resolveComponent("CardListed")!
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createBlock(_component_RouterLink, {
    class: "card-link",
    to: $props.to
  }, {
    default: _withCtx(() => [
      _createVNode(_component_CardListed, { class: "card-link__card-listed" }, {
        footer: _withCtx(() => [
          _renderSlot(_ctx.$slots, "footer")
        ]),
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 8, ["to"]))
}