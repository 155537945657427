<template>
  <SvgWithLabel class="zones-list">
    <IconGateways v-if="zones.length" class="zones-list__icon-gateway" />
    <IconNoGateways v-else class="zones-list__icon-no-gateway" />

    <template #right>
      <template v-if="zones.length">
        {{ zones.join(", ") }}
      </template>
      <template v-else>{{ contents.notPublishedOnGateways }}</template>
    </template>
  </SvgWithLabel>
</template>

<script lang="ts">
import SvgWithLabel from "@/commons/components/SvgWithLabel.vue";

import IconGateways from "./IconGateways.vue";
import IconNoGateways from "./IconNoGateways.vue";

import contents from "@/commons/contents/zone-list.js";

export default {
  name: "ZonesList",
  components: { SvgWithLabel, IconGateways, IconNoGateways },
  props: {
    zones: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      contents,
    };
  },
};
</script>

<style lang="scss">
svg.zones-list__icon-gateway,
svg.zones-list__icon-no-gateway {
  width: 1.1rem;
  margin-right: 0.3rem;
  overflow: visible;
}
</style>
