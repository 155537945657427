<script lang="ts" setup>
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import { computed, markRaw, PropType } from "vue";
import { useStore } from "vuex";

import SendEmailModal from "@/documentation/views/ApiDetail/SendEmailModal.vue";

import { Application } from "@/commons/domain/models/application";
import { Contract } from "@/commons/domain/models/contract";
import { getAllContractsOfCurrentApplication } from "@/commons/services/contract/contract.service";

import contents from "@/dashboard/contents/contact-api-owners-button";
import sendEmailModalContents from "@/documentation/contents/send-email-modal";

const props = defineProps({
  application: {
    type: Object as PropType<Application>,
    required: true,
  },
});

const store = useStore();

const applicationHasNotContracts = computed(() => {
  return props.application?.metrics?.contracts?.total === 0;
});

const contactOwners = async () => {
  store.commit("openLayerModal", {
    title: sendEmailModalContents.modalTitle,
    props: {
      apis: await getAllApisRelatedToCurrentApplication(),
    },
    component: markRaw(SendEmailModal),
  });
};

const getAllApisRelatedToCurrentApplication = async () => {
  const allContracts = await getAllContractsOfCurrentApplication(
    props.application?.id,
  );
  return allContracts.map((contract: Contract) => contract.api);
};
</script>

<template>
  <MButton
    class="contact-api-owners-button"
    theme="bordered"
    size="s"
    :label="contents.contactApiOwnersButtonLabel"
    :disabled="applicationHasNotContracts"
    icon="ContactMail24"
    iconPosition="right"
    @click="contactOwners"
  />
</template>
