import { CurrentUser } from "@/commons/domain/models/user";

import { DropdownMenuItem, MenuItem } from "@/commons/store/types";

export function getMoreMenuConf(
  slackUrl: string,
  supportUrl: string,
  user: CurrentUser,
  guidelinesUrl: string,
): DropdownMenuItem {
  const items: MenuItem[] = [
    {
      label: "Documentation",
      openInNewWindow: true,
      urlOrRoute: {
        name: "docsMain",
      },
      iconName: "Read24",
    },
    {
      label: "Get help on Slack",
      urlOrRoute: slackUrl,
      isExternalLink: true,
      iconLocalPath: require(
        `@/commons/assets/icons/icon-slack-primary-color.svg`,
      ),
      openInNewWindow: true,
    },
    {
      label: "Get help on our email box",
      urlOrRoute: supportUrl,
      isExternalLink: true,
      openInNewWindow: true,
      iconName: "ContactMail24",
      withBottomBorder: true,
    },
    {
      label: "Use cases",
      urlOrRoute: {
        name: "useCases",
      },
      iconName: "AspectQuantity24",
      withBottomBorder: !user?.isInternal,
    },
  ];

  if (user?.isInternal) {
    items.push({
      label: "API guidelines",
      urlOrRoute: guidelinesUrl,
      isExternalLink: true,
      iconName: "Policies24",
      openInNewWindow: true,
      withBottomBorder: true,
    });
  }

  items.push({
    label: "Privacy and terms",
    urlOrRoute: {
      name: "TOSRead",
    },
    iconName: "Document24",
  });

  return {
    mIconName: "NotificationQuestion32",
    items,
    realm: "more",
  };
}
