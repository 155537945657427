import { IsUrl, Validate } from "class-validator";

import { FormData } from "@/commons/domain/forms/form-data";
import { FormFactory } from "@/commons/domain/forms/form-factory";
import { RoutingPolicyCondition } from "@/commons/domain/models/routing-policy-condition";
import { PolicyHeadersConditionValid } from "@/commons/forms/class-validator/custom-validators/policy-headers-condition-valid";
import { PolicyHttpMethodsConditionValid } from "@/commons/forms/class-validator/custom-validators/policy-httpMethods-condition-valid";
import { PolicyOriginConditionValid } from "@/commons/forms/class-validator/custom-validators/policy-origin-condition-valid";
import { PolicyPathConditionValid } from "@/commons/forms/class-validator/custom-validators/policy-path-condition-valid";
import { ReportingPolicyQueryParamsConditionValid } from "@/commons/forms/class-validator/custom-validators/policy-queryParams-condition-valid";

export class RoutingPolicyForm extends FormData {
  @IsUrl(
    {
      require_protocol: true,
      require_valid_protocol: true,
      protocols: ["http", "https"],
      host_blacklist: ["localhost", "127.0.0.1"],
      disallow_auth: true,
      allow_trailing_dot: true,
      require_tld: false,
    },
    { message: "This URL must be a valid URL address" },
  )
  url: string;

  @Validate(PolicyPathConditionValid, {
    message: "You don't have enter a correct value",
  })
  @Validate(PolicyHeadersConditionValid, {
    message: "You don't have enter a correct header key or header value",
  })
  @Validate(ReportingPolicyQueryParamsConditionValid, {
    message:
      "You don't have enter a correct name or value for query parameters",
  })
  @Validate(PolicyHttpMethodsConditionValid, {
    message: "You don't have select a method",
  })
  @Validate(PolicyOriginConditionValid, {
    message: "You don't have select an origin",
  })
  conditions: RoutingPolicyCondition[];

  $clear() {
    this.url = "";
    this.conditions = [];
  }

  static create() {
    return FormFactory.createForm(new RoutingPolicyForm());
  }
}
