<template>
  <div :class="['with-sidebar', 'sidebar-layout', classes]">
    <div>
      <div><slot name="left" /></div>
      <div><slot name="right" /></div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: "WithSidebar",
  props: {
    sidebarToRight: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return this.sidebarToRight
        ? "sidebar-layout--right"
        : "sidebar-layout--left";
    },
  },
};
</script>

<style lang="scss">
.sidebar-layout > * {
  display: flex;
  flex-wrap: wrap;
}

.sidebar-layout > * > * {
  flex-basis: var(--sidebar-layout-side-width, "content");
  flex-grow: 1;
}

.sidebar-layout--left > * > :last-child,
.sidebar-layout--right > * > :first-child {
  flex-basis: 0;
  flex-grow: 999;
  min-width: calc(
    var(--sidebar-layout-content-width, 50%) - var(
        --sidebar-layout-glutter,
        0px
      )
  );
}
</style>
