<template>
  <div class="app-list-empty-item">
    <div class="app-list-empty-item__icon">
      <MIcon name="Tools3d64" color="var(--color-grey)" />
    </div>
    <div class="app-list-empty-item__content">{{ contents.noAppLabel }}</div>
  </div>
</template>
<script lang="ts">
import MIcon from "@mozaic-ds/vue-3/src/components/icon/MIcon.vue";

import contents from "@/dashboard/contents/app-list-empty-item";

export default {
  components: {
    MIcon,
  },
  data() {
    return {
      contents,
    };
  },
};
</script>
<style lang="scss">
.app-list-empty-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 20rem;

  padding: 3rem;

  pointer-events: none;
  cursor: default;

  background-color: var(--color-background-interface);
  border: var(--border-default);
  border-radius: var(--m-border-radius-large);
  gap: 1.5rem;
}

.app-list-empty-item__content {
  color: var(--color-grey);
  text-align: center;
}
</style>
