import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "use-case-main" }
const _hoisted_2 = { class: "use-case-main__description" }
const _hoisted_3 = { class: "use-case-main__title" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "use-case-main__kicker" }
const _hoisted_6 = { class: "use-case-main__actions" }
const _hoisted_7 = { class: "use-case-main__list-features" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_use_case_actions = _resolveComponent("use-case-actions")!
  const _component_use_case_list_features = _resolveComponent("use-case-list-features")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString($props.useCase.title), 1),
      _createElementVNode("h1", {
        class: "use-case-main__headline",
        innerHTML: $props.useCase.headline
      }, null, 8, _hoisted_4),
      _createElementVNode("div", _hoisted_5, _toDisplayString($props.useCase.kicker), 1),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_use_case_actions, { "is-first": "" })
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_use_case_list_features, {
        features: $props.useCase.features,
        onNavigateToAnchor: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('navigateToAnchor', $event)))
      }, null, 8, ["features"])
    ])
  ]))
}