<script lang="ts" setup>
import MBadge from "@mozaic-ds/vue-3/src/components/badge/MBadge.vue";
import { computed, PropType } from "vue";
import { useStore } from "vuex";

import IconTooltip from "@/commons/components/IconTooltip.vue";

import { toLocaleDate, toLocaleDatetime } from "@/commons/utils/date-utils";

import { SecretSynchronization } from "@/commons/domain/models/secret-synchronization";

import { ESecretSynchronizationStatus } from "@/commons/store/types";

import contents from "@/dashboard/contents/secret-synchronization-status";

const props = defineProps({
  applicationSecretSynchronization: {
    type: Object as PropType<SecretSynchronization>,
    required: true,
  },
});

const store = useStore();

const secretSynchronizationIsEnabled = computed(() => {
  return store.getters["config/secretSynchronizationIsEnabled"];
});

const secretManager = computed(() => {
  return store.getters["config/secretSynchronizationManager"];
});

const applicationIsSynchronized = computed(() => {
  return (
    props.applicationSecretSynchronization?.status ===
    ESecretSynchronizationStatus.SYNCHRONIZED
  );
});

const lastUpdateDate = computed(() => {
  return props.applicationSecretSynchronization?.updatedAt != undefined
    ? toLocaleDate(props.applicationSecretSynchronization.updatedAt)
    : undefined;
});

const lastUpdateOnLabel = computed(() => {
  return lastUpdateDate.value != undefined
    ? contents.lastUpdateOn(lastUpdateDate.value)
    : undefined;
});

const statusLabel = computed(() => {
  return applicationIsSynchronized.value
    ? contents.syncWithSecretManager(secretManager.value)
    : contents.pendingSecretManagerSync(secretManager.value);
});

const tooltipMessage = computed(() => {
  if (applicationIsSynchronized.value) {
    return undefined;
  } else {
    return secretSynchronizationIsEnabled.value
      ? getPendingSyncTooltipMessage()
      : contents.deactivatedSynchronizationTooltipMessage(secretManager.value);
  }
});

const getPendingSyncTooltipMessage = () => {
  const nextSyncTimeDate = toLocaleDatetime(
    props.applicationSecretSynchronization?.nextSynchronization,
  );

  let message = contents.pendingSyncTooltipMessage(secretManager.value);

  return undefined != nextSyncTimeDate
    ? `${message} ${contents.plannedSyncTooltipMessage(nextSyncTimeDate)}`
    : message;
};

const badgeType = computed(() => {
  if (applicationIsSynchronized.value) {
    return "success";
  } else {
    return secretSynchronizationIsEnabled.value ? "warning" : "danger";
  }
});
</script>

<template>
  <div class="secret-synchronization-status">
    <div class="secret-synchronization-status__badge">
      <IconTooltip
        v-if="tooltipMessage != undefined"
        :text="tooltipMessage"
        position="left"
      />
      <MBadge class="secret-synchronization__badge" :type="badgeType">
        {{ statusLabel }}
      </MBadge>
    </div>
    <span
      v-if="lastUpdateOnLabel != undefined"
      class="secret-synchronization-status__last-update"
    >
      {{ lastUpdateOnLabel }}
    </span>
  </div>
</template>

<style lang="scss">
.secret-synchronization-status {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.secret-synchronization-status__badge {
  display: flex;
  align-items: center;
  gap: 0.375rem;
}

.secret-synchronization-status__last-update {
  @include set-text-xs;
  color: var(--color-text-minor);
}
</style>
