<template>
  <Stack class="custom-query-params">
    <MNotification v-if="validationError" type="danger">
      {{ validationError }}
    </MNotification>
    <p>
      <span>{{ message }}</span>
      <a v-if="allowQueryParamTransformation" :href="documentationUrl"
        >{{ documentationMessage }}
      </a>
    </p>
    <table class="table-light">
      <thead>
        <tr>
          <th>{{ contents.queryParamName }}</th>
          <th>
            {{ contents.queryParamValue }}
          </th>
          <th>{{ contents.queryParamActions }}</th>
        </tr>
      </thead>
      <tbody>
        <UpdateOrRemoveCustomQueryParam
          v-for="customQueryParam in form.values"
          :key="customQueryParam.id"
          :is-disabled="isSaving"
          :form-parent="form"
          :form="customQueryParam"
          @update="update"
          @remove="remove"
        />
        <!-- Create custom query param -->
        <CreateCustomQueryParam
          :is-disabled="isSaving"
          :has-error="hasCreationError"
          :form-parent="form"
          :form="form.newValue"
          @create="create"
          @close="removeCreationErrorIfExists"
        />
      </tbody>
    </table>
  </Stack>
</template>

<script lang="ts">
import MNotification from "@mozaic-ds/vue-3/src/components/notification/MNotification.vue";

import Stack from "@/commons/components/Stack.vue";

import CreateCustomQueryParam from "./CreateCustomQueryParam.vue";
import UpdateOrRemoveCustomQueryParam from "./UpdateOrRemoveCustomQueryParam.vue";

import { convertRouteToHref } from "@/commons/utils/route-utils";

import { CustomQueryParamsListForm } from "@/manager/domain/forms/custom-query-params-list-form";

import contents from "@/manager/contents/custom-query-params";

export default {
  name: "CustomQueryParams",
  components: {
    UpdateOrRemoveCustomQueryParam,
    CreateCustomQueryParam,
    Stack,
    MNotification,
  },
  props: {
    apiId: {
      type: String,
      required: true,
    },
    customQueryParams: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      contents,
      form: CustomQueryParamsListForm.create(),
    };
  },
  computed: {
    isSaving() {
      return this.$store.getters["isSaving"];
    },
    documentationUrl() {
      return convertRouteToHref({
        name: "docsMain",
        hash: "#query-parameter-transformation",
      });
    },
    hasCreationError() {
      return this.$store.getters["actionFailing"] === "createCustomQueryParam";
    },
    validationError() {
      return this.form.firstError("values") || this.form.firstError("newValue");
    },
    allowQueryParamTransformation() {
      return this.$store.getters["apiAllowQueryParamsTransformation"];
    },
    message() {
      return this.contents.message;
    },
    documentationMessage() {
      return this.contents.messageDocumentation;
    },
  },
  watch: {
    customQueryParams: {
      async handler(customQueryParams) {
        await this.form.init({ values: customQueryParams });
      },
      deep: true,
    },
  },
  async mounted() {
    await this.form.init({ values: this.customQueryParams });
  },
  methods: {
    async update(customQueryParam) {
      await this.$store.dispatch("updateCustomQueryParam", {
        apiId: this.apiId,
        customQueryParam,
      });
    },
    async create({ name, value }) {
      await this.$store.dispatch("createCustomQueryParam", {
        apiId: this.apiId,
        name,
        value,
      });
    },
    async remove(customQueryParamId) {
      await this.$store.dispatch("removeCustomQueryParam", {
        apiId: this.apiId,
        customQueryParamId,
      });
    },
    removeCreationErrorIfExists() {
      if (this.hasCreationError) {
        this.$store.commit("patchState", { actionFailing: "" });
      }
    },
  },
};
</script>

<style lang="scss">
.custom-query-params {
  p {
    white-space: pre-line;

    a {
      padding-left: 2px;
    }
  }
}
</style>
