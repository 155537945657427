<template>
  <div class="publication-zone-access-urls">
    <TitleWithIconTooltip :tooltipContent="contents.accessUrlsMoreInfo">
      <h5 class="publication-zone-access-urls__title">
        {{ contents.accessUrls }}
      </h5>
    </TitleWithIconTooltip>

    <MNotification
      v-if="showIncompatibleAccessRestrictionWarning"
      class="publication-zone-access-urls__access-restriction-warning"
      type="warning"
      :title="contents.accessRestrictionWarningTitle"
      closable
      :link="accessRestrictionHref"
      :linkContent="contents.accessRestrictionLinkLabel"
      @close="showIncompatibleAccessRestrictionWarning = false"
    >
      <p>{{ contents.accessRestrictionWarningText }}</p>
    </MNotification>

    <div class="publication-zone-access-urls__private-routes">
      <h6 class="publication-zone-access-urls__route-title">
        {{ contents.privateUrlTitle }}
      </h6>
      <EditableRouteUrl
        v-for="privateRoute of privateRoutes"
        :key="`publication-zone-access-urls__route-${privateRoute.id}`"
        :route="privateRoute"
        :canEdit="userIsAdmin"
        :apiId="api.id"
      />
    </div>

    <ToggleField
      class="publication-zone-access-urls__internet-exposure-section"
    >
      <template #label>
        <TitleWithIconTooltip
          :tooltipContent="contents.internetExposureExplanation"
        >
          <label>{{ contents.internetExposureLabel }}</label>
        </TitleWithIconTooltip>
      </template>
      <template #toggleButton>
        <DvpToggle
          :checked="isExposedOnInternet"
          :disabled="isDisabled"
          @click="toggleInternetExposure(publicRoutes[0], !isExposedOnInternet)"
        />
      </template>
    </ToggleField>

    <div
      v-if="isExposedOnInternet"
      class="publication-zone-access-urls__public-routes"
    >
      <h6 class="publication-zone-access-urls__route-title">
        {{ contents.publicUrlTitle }}
      </h6>
      <EditableRouteUrl
        v-for="publicRoute of publicPublishedRoutes"
        :key="`publication-zone-access-urls__route-${publicRoute.id}`"
        :route="publicRoute"
        :canEdit="userIsAdmin"
        :apiId="api.id"
      />
    </div>

    <ToggleField
      v-if="fullFeatureIpFilteringIsEnabled && isExposedOnInternet"
      class="publication-zone-access-urls__ip-filtering-section"
    >
      <template #label>
        <TitleWithIconTooltip :tooltipContent="contents.ipFilteringExplanation">
          <label>{{ contents.ipFilteringLabel }}</label>
        </TitleWithIconTooltip>
      </template>
      <template #toggleButton>
        <DvpToggle
          :checked="isIpFilteringEnabled"
          :disabled="isDisabled"
          @click="toggleIpFiltering(publicRoutes[0], !isIpFilteringEnabled)"
        />
      </template>
    </ToggleField>
  </div>
</template>

<script lang="ts">
import MNotification from "@mozaic-ds/vue-3/src/components/notification/MNotification.vue";
import { markRaw, PropType } from "vue";

import DvpToggle from "@/commons/components/DvpToggle.vue";
import MessageConfirmModal from "@/commons/components/Modal/MessageConfirmModal.vue";
import TitleWithIconTooltip from "@/commons/components/TitleWithIconTooltip.vue";
import ToggleField from "@/commons/components/ToggleField.vue";
import ConfirmInternetExposureModal from "@/manager/views/ApiDetailPublishing/ConfirmInternetExposureModal.vue";
import ConfirmInternetUnexposureModal from "@/manager/views/ApiDetailPublishing/ConfirmInternetUnexposureModal.vue";
import EditableRouteUrl from "@/manager/views/ApiDetailPublishing/EditableRouteUrl.vue";
import ModalConsumersPreventingIPFilteringActivation from "@/manager/views/ApiDetailPublishing/ModalConsumersPreventingIPFilteringActivation.vue";

import { getApiRepository } from "@/commons/repositories/libs/get-api-repository";
import { convertRouteToHref } from "@/commons/utils/route-utils";

import { Api } from "@/commons/domain/models/api";
import { Contract } from "@/commons/domain/models/contract";
import { PayloadUpdateRoute } from "@/commons/domain/models/payload-update-route";
import { Route } from "@/commons/domain/models/route";

import { EApplicationType, EContractStatus } from "@/commons/store/types";

import contentsOfConfirmInternetExposureModal from "@/manager/contents/confirm-internet-exposure-modal";
import contentsOfConfirmInternetUnexposureModal from "@/manager/contents/confirm-internet-unexposure-modal";
import contentsIpFilteringActivation from "@/manager/contents/modal-consumers-preventing-ip-filtering-activation";
import contentsIpFilteringDeactivation from "@/manager/contents/modal-security-risk-ip-filtering-deactivation";
import contents from "@/manager/contents/publication-zone-card";

export default {
  components: {
    TitleWithIconTooltip,
    EditableRouteUrl,
    MNotification,
    DvpToggle,
    ToggleField,
  },
  props: {
    api: {
      type: Object as PropType<Api>,
      required: true,
    },
    privateRoutes: {
      type: Array as PropType<Route[]>,
      default: () => [],
    },
    publicRoutes: {
      type: Array as PropType<Route[]>,
      default: () => [],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      contents,
      showIncompatibleAccessRestrictionWarning: false,
    };
  },
  computed: {
    userIsAdmin(): boolean {
      return this.$store.getters["userIsAdmin"];
    },
    frontendConfig() {
      return this.$store.getters["config/frontendConfig"];
    },
    fullFeatureIpFilteringIsEnabled(): boolean {
      return this.frontendConfig?.ipFiltering?.fullFeatureIsEnabled == true;
    },
    activeConsumersContracts() {
      return (
        this.$store.getters["contracts"] &&
        Object.values(
          this.$store.getters["contracts"][EApplicationType.ALL].data,
        )
      );
    },
    applicationNamesListWithoutDeclaredIPs(): string[] {
      return this.activeConsumersContracts
        .filter(
          (contract: Contract) =>
            contract.application?.ipFiltering?.ipRanges?.length === 0,
        )
        .map((contract: Contract) => contract.application.name);
    },
    hasAnyActiveConsumerWithoutDeclaredIPRange(): boolean {
      return (
        this.activeConsumersContracts.length > 0 &&
        this.activeConsumersContracts?.some(
          (contract: Contract) =>
            contract.application?.ipFiltering?.ipRanges?.length === 0,
        )
      );
    },
    publicPublishedRoutes(): Route[] {
      return this.publicRoutes.filter((route: Route) => route.isPublishable);
    },
    isExposedOnInternet(): boolean {
      return this.publicPublishedRoutes.length > 0;
    },
    isIpFilteringEnabled(): boolean {
      return (
        this.publicPublishedRoutes.filter(
          (route: Route) => route.isIpFilteringEnabled,
        ).length > 0
      );
    },
    accessRestrictionHref(): string {
      return convertRouteToHref({ name: "managerAccessRestriction" });
    },
    otherApiPublicPublishedRoutesWithEnabledIPFiltering(): Route[] {
      return this.$store.getters["downstreamRoutes"].filter(
        (route: Route) =>
          route.isPublic &&
          route.isPublishable &&
          route.isIpFilteringEnabled &&
          this.publicRoutes.some((r: Route) => r.id === route.id) === false,
      );
    },
    defaultIpFilteringValue(): boolean {
      return (
        this.otherApiPublicPublishedRoutesWithEnabledIPFiltering.length > 0
      );
    },
  },
  mounted() {
    this.$store.dispatch("getContracts", {
      apiId: this.api.id,
      status: [EContractStatus.ENABLED],
      applicationTypes: [EApplicationType.ALL],
    });
  },
  methods: {
    updateRouteExposure(route: Route, newExposureValue: boolean): void {
      const payload: PayloadUpdateRoute = {
        apiId: this.api.id,
        routeId: route.id,
        isPublishable: newExposureValue,
      };

      if (
        this.fullFeatureIpFilteringIsEnabled &&
        newExposureValue === true &&
        this.defaultIpFilteringValue === true
      ) {
        payload.isIpFilteringEnabled = true;
      }

      this.$store.dispatch("updateRoute", payload);
    },
    async toggleInternetExposure(
      route: Route,
      isExposing: boolean,
    ): Promise<void> {
      if (isExposing) {
        this.tryExposeOnInternet(route);
      } else {
        if (this.api.allowedActions["api.internet-unexposure"]) {
          await this.tryUnexposeFromInternet(route);
        } else {
          this.showIncompatibleAccessRestrictionWarning = true;
        }
      }
    },
    tryExposeOnInternet(route: Route): void {
      this.$store.commit("openModal", {
        title: contentsOfConfirmInternetExposureModal.modalTitle,
        component: ConfirmInternetExposureModal,
        props: {
          currentTimeoutInSeconds: this.api.timeoutInSeconds,
        },
        listeners: {
          onSubmit: (): void => {
            this.updateRouteExposure(route, true);
            this.$store.commit("closeModal");
          },
        },
      });
    },
    async tryUnexposeFromInternet(route: Route): Promise<void> {
      if (await getApiRepository().hasAnyActiveSubscription(this.api.id)) {
        this.$store.commit("openModal", {
          title: contentsOfConfirmInternetUnexposureModal.modalTitle,
          component: markRaw(ConfirmInternetUnexposureModal),
          props: {
            apiId: this.api.id,
          },
          listeners: {
            onSubmit: async () => {
              this.updateRouteExposure(route, false);
              this.$store.commit("closeModal");
            },
          },
        });
      } else {
        this.updateRouteExposure(route, false);
      }
    },
    async toggleIpFiltering(
      route: Route,
      isIpFilteringEnabling: boolean,
    ): Promise<void> {
      if (isIpFilteringEnabling) {
        this.tryEnableIpFiltering(route);
      } else {
        this.disableIpFiltering(route);
      }
    },
    tryEnableIpFiltering(route: Route): void {
      if (this.hasAnyActiveConsumerWithoutDeclaredIPRange) {
        this.$store.commit("openModal", {
          title: contentsIpFilteringActivation.modalTitle,
          component: markRaw(ModalConsumersPreventingIPFilteringActivation),
          props: {
            api: this.api,
            applicationsWithoutIPs: this.applicationNamesListWithoutDeclaredIPs,
          },
          listeners: {
            onSubmit: async () => {
              this.updateRouteIpFiltering(route, true);
              this.$store.commit("closeModal");
            },
          },
        });
      } else {
        this.updateRouteIpFiltering(route, true);
      }
    },
    disableIpFiltering(route: Route): void {
      this.$store.commit("openModal", {
        title: contentsIpFilteringDeactivation.modalTitle,
        component: markRaw(MessageConfirmModal),
        props: {
          title: contentsIpFilteringDeactivation.title,
          message: contentsIpFilteringDeactivation.explanation,
          confirmBtnLabel: contentsIpFilteringDeactivation.confirmBtnLabel,
        },
        listeners: {
          onSubmit: () => {
            this.updateRouteIpFiltering(route, false);
            this.$store.commit("closeModal");
          },
        },
      });
    },
    updateRouteIpFiltering(route: Route, newIpFilteringValue: boolean): void {
      this.$store.dispatch("updateRoute", {
        apiId: this.api.id,
        routeId: route.id,
        isIpFilteringEnabled: newIpFilteringValue,
      });
    },
  },
};
</script>

<style lang="scss">
.publication-zone-access-urls {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.publication-zone-access-urls__title {
  margin: 0;
}

.publication-zone-access-urls__internet-exposure-label {
  display: flex;
  align-items: center;
}

.publication-zone-access-urls__internet-exposure-info-icon {
  margin-left: 0.5rem;
}

.publication-zone-access-urls__route-title {
  margin: 0;
}
</style>
