import { getGroupWithUsersAclsRepository } from "@/commons/repositories/libs/get-group-with-users-acls-repository";

import { AclUser } from "@/commons/domain/models/acl-user";
import { GroupWithUsersAcls } from "@/commons/domain/models/group-with-users-acls";

export async function findGroupUsers(groupId: string): Promise<AclUser[]> {
  const group: GroupWithUsersAcls = await findGroup(groupId);
  return Object.values(group.users);
}

export async function findGroup(groupId: string): Promise<GroupWithUsersAcls> {
  return await getGroupWithUsersAclsRepository().findById(groupId);
}
