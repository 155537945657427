import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Markdown = _resolveComponent("Markdown")!
  const _component_ConfirmModalBody = _resolveComponent("ConfirmModalBody")!

  return (_openBlock(), _createBlock(_component_ConfirmModalBody, {
    class: "confirm-unpublish-modal",
    confirmBtnLabel: $data.contents.confirmBtn,
    onOnSubmit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onSubmit'))),
    onOnClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('onClose')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("h5", null, _toDisplayString($data.contents.confirmTitle), 1),
      _createVNode(_component_Markdown, {
        content: $data.contents.confirmContent($options.hrefToApiConsumers)
      }, null, 8, ["content"])
    ]),
    _: 1
  }, 8, ["confirmBtnLabel"]))
}