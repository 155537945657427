const { EAccessPolicy } = require("@/commons/store/types");

module.exports = {
  privateApi: "Private API",
  privateTopic: "Private TOPIC",
  internalApi: "Internal API",
  partnerApi: "Partner API",
  publicApi: "Public API",
  publicTopic: "Public TOPIC",

  accessPolicy: {
    [EAccessPolicy.ON_DEMAND]: "On demand",
    [EAccessPolicy.SELF_SERVICE]: "Self service",
    [EAccessPolicy.PRIVATE]: "On demand",
  },
};
