<script setup lang="ts">
import MBadge from "@mozaic-ds/vue-3/src/components/badge/MBadge.vue";
import { computed } from "vue";
import { PropType } from "vue/dist/vue";

import ButtonCopy from "@/commons/components/Buttons/ButtonCopy.vue";
import IconTooltip from "@/commons/components/IconTooltip.vue";

import { Route } from "@/commons/domain/models/route";

import contents from "@/commons/contents/route-information";

const props = defineProps({
  route: {
    type: Object as PropType<Route>,
    required: true,
  },
  publicAccessibilityLabel: {
    type: String,
    default: contents.publicAccessibilityLabel,
  },
  privateAccessibilityLabel: {
    type: String,
    default: contents.internalAccessibilityLabel,
  },
});

const accessibilityLabel = computed((): string => {
  return props.route.isPublic
    ? props.publicAccessibilityLabel
    : props.privateAccessibilityLabel;
});

const accessibilityTooltip = computed((): string => {
  return props.route.isPublic
    ? contents.publicAccessibilityTooltip
    : contents.internalAccessibilityTooltip;
});

const withActivatedIpFiltering = computed((): boolean => {
  return props.route.isPublic && props.route.isIpFilteringEnabled;
});
</script>

<template>
  <div class="route-information">
    <MBadge type="neutral">{{ accessibilityLabel }}</MBadge>
    <IconTooltip :text="accessibilityTooltip" />

    <IconTooltip
      v-if="withActivatedIpFiltering"
      iconName="Policies24"
      :text="contents.ipFilteredRouteTooltip"
    />

    <ButtonCopy
      class="route-information__copy-icon-button"
      :content="route.url"
    />
    <span class="route-information__url">{{ route.url }}</span>
  </div>
</template>

<style lang="scss">
.route-information {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.route-information__copy-icon-button {
  padding: 0;
}

.route-information__url {
  word-break: break-all;
}
</style>
