import { getApiCatalogMenuConf } from "@/commons/libs/get-api-catalog-menu-conf";

import { DropdownMenuItem } from "@/commons/store/types";

export function getNavigationMenuConf(
  userIsInternal: boolean,
  userIsAdmin: boolean,
): DropdownMenuItem[] {
  const navigationMenus: DropdownMenuItem[] = [];
  const myAppsMenu: DropdownMenuItem = {
    label: "My APPs",
    urlOrRoute: {
      name: "dashboard",
    },
    realm: "dashboard",
  };
  const apiManagerMenu: DropdownMenuItem = {
    label: "API Manager",
    urlOrRoute: {
      name: "managerApiList",
    },
    realm: "manager",
  };
  const apiCatalogExternalMenu: DropdownMenuItem = {
    label: "API Catalog",
    urlOrRoute: {
      name: "apiDocumentation",
    },
    realm: "docs",
  };
  const apiCatalogInternalMenu = getApiCatalogMenuConf();
  const adminMenu: DropdownMenuItem = {
    label: "Administration",
    urlOrRoute: {
      name: "admin",
    },
    realm: "admin",
  };
  if (userIsInternal) {
    navigationMenus.push(apiCatalogInternalMenu, myAppsMenu, apiManagerMenu);
  } else {
    navigationMenus.push(apiCatalogExternalMenu, myAppsMenu);
  }
  if (userIsAdmin) {
    navigationMenus.push(adminMenu);
  }
  return navigationMenus;
}
