<template>
  <CardContainer class="publication-zone-card">
    <div class="publication-zone-card__general-info">
      <div
        v-if="zoneIsDeprecated"
        class="publication-zone-card__deprecated-container"
      >
        <span class="publication-zone-card__deprecated-date">{{
          deprecationDateMessage
        }}</span>
        <MBadge class="publication-zone-card__deprecated-badge" type="danger">{{
          contents.deprecatedLabel
        }}</MBadge>
      </div>

      <div class="publication-zone-card__status-container">
        <span
          v-if="formattedLastPublicationDate"
          class="publication-zone-card__last-deploy"
          >{{
            contents.publicationDateMessage(formattedLastPublicationDate)
          }}</span
        >
        <MBadge v-if="zonePublicationStatus" :type="statusBadgeType">
          {{ contents.publicationStatus[zonePublicationStatus] }}
          <IconTooltip
            v-if="errorMessage"
            position="bottom"
            :text="errorMessage"
            iconName="NotificationQuestion24"
          />
        </MBadge>
      </div>

      <h5 class="publication-zone-card__title">
        {{ zone.name }}
      </h5>

      <ToggleField class="publication-zone-card__publish">
        <template #label>
          <label>{{ contents.publishLabel }}</label>
        </template>
        <template #toggleButton>
          <DvpToggle
            :checked="zoneIsPublished"
            :disabled="publicationToggleIsDisabled"
            @click="togglePublication"
          />
        </template>
      </ToggleField>
    </div>

    <Divider class="publication-zone-card__divider" />

    <PublicationZoneAccessUrls
      class="publication-zone-card__zone-access-urls"
      :api="api"
      :privateRoutes="privateRoutes"
      :publicRoutes="publicRoutes"
      :isDisabled="
        isChangingPublicationState || !canPublish || !zoneIsPublished
      "
    />
  </CardContainer>
</template>

<script lang="ts">
import MBadge from "@mozaic-ds/vue-3/src/components/badge/MBadge.vue";
import { PropType } from "vue";

import CardContainer from "@/commons/components/CardContainer.vue";
import Divider from "@/commons/components/Divider.vue";
import DvpToggle from "@/commons/components/DvpToggle.vue";
import IconTooltip from "@/commons/components/IconTooltip.vue";
import { ModalClosedError } from "@/commons/components/Modal/modal.type";
import ToggleField from "@/commons/components/ToggleField.vue";
import ConfirmUnpublishModal from "@/manager/views/ApiDetailPublishing/ConfirmUnpublishModal.vue";
import ModalWaitingConsumers from "@/manager/views/ApiDetailPublishing/ModalWaitingConsumers.vue";

import PublicationZoneAccessUrls from "./PublicationZoneAccessUrls.vue";

import { getApiRepository } from "@/commons/repositories/libs/get-api-repository";
import {
  isBeforeNow,
  localeDateFormats,
  toDateFormat,
  toLocaleDate,
} from "@/commons/utils/date-utils";
import { flagSavingProcess } from "@/commons/utils/flagProcess.utils";

import { Api } from "@/commons/domain/models/api";
import { Route } from "@/commons/domain/models/route";
import { Zone } from "@/commons/domain/models/zone";

import {
  EApiPublicationStatus,
  ERouteStatus,
  ERouteTypes,
} from "@/commons/store/types";

import contentsOfConfirmUnpublishModal from "@/manager/contents/confirm-unpublish-modal";
import contentsOfModalWaitingConsumers from "@/manager/contents/modal-waiting-consumers";
import contents from "@/manager/contents/publication-zone-card";

export default {
  components: {
    CardContainer,
    IconTooltip,
    MBadge,
    PublicationZoneAccessUrls,
    ToggleField,
    DvpToggle,
    Divider,
  },
  props: {
    zone: {
      type: Object as PropType<Zone>,
      required: true,
    },
    routes: {
      type: Array as PropType<Route[]>,
      required: true,
    },
    api: {
      type: Object as PropType<Api>,
      required: true,
    },
  },
  data() {
    return {
      contents,
    };
  },
  computed: {
    isChangingPublicationState(): boolean {
      return this.$store.getters["isSavingProperty"](
        `togglePublication-${this.zone.id}`,
      ); // using zoneId to prevent other publication card to disable their toggle
    },
    publicationToggleIsDisabled(): boolean {
      return this.isChangingPublicationState || !this.canPublish;
    },
    statusBadgeType(): string {
      switch (this.zonePublicationStatus) {
        case ERouteStatus.ENABLED:
          return "success";
        case ERouteStatus.ERROR:
          return "danger";
        case ERouteStatus.DISABLED:
          return "neutral";
        default: // PENDING_GATEWAY
          return "warning";
      }
    },
    privateRoutes(): Route[] {
      return this.routes.filter((route) => !route.isPublic);
    },
    publicRoutes(): Route[] {
      return this.routes.filter((route) => route.isPublic);
    },
    zoneIsDeprecated(): boolean {
      return this.routes.some((route) => route.isDeprecated);
    },
    zoneIsPublished(): boolean {
      return this.routes.some((route) => route.status === ERouteStatus.ENABLED);
    },
    zonePublicationStatus(): ERouteStatus | undefined {
      let downstreamRoutes = this.routes.filter(
        (route) => route.type === ERouteTypes.DOWNSTREAM,
      );

      for (let status of [
        ERouteStatus.ERROR,
        ERouteStatus.PENDING_GATEWAY,
        ERouteStatus.ENABLED,
        ERouteStatus.DISABLED,
      ]) {
        if (downstreamRoutes.some((route) => route.status === status)) {
          return status;
        }
      }
      return undefined;
    },
    errorMessage(): string {
      return this.routes
        .filter((route) => route.statusMessage)
        .map((route) => route.statusMessage)
        .join("; ");
    },
    canPublish(): boolean {
      return this.routes.every(
        (route) =>
          route.allowedActions && route.allowedActions["api.can-publish"],
      );
    },
    deprecationDateMessage(): string {
      const deprecatedRoute = this.routes.find((route) => route.isDeprecated);
      const deprecationDate = deprecatedRoute?.deprecationDate;

      if (deprecationDate != undefined) {
        return isBeforeNow(deprecationDate)
          ? contents.messageForPastDeprecation(toLocaleDate(deprecationDate))
          : contents.messageForFutureDeprecation(toLocaleDate(deprecationDate));
      } else {
        return "";
      }
    },
    formattedLastPublicationDate(): string {
      const publishedRoute = this.routes.find(
        (route) => route.status === ERouteStatus.ENABLED,
      );
      return publishedRoute?.publicationDate != undefined
        ? toDateFormat(
            publishedRoute.publicationDate,
            localeDateFormats.VERBOSE_DATE_MINUTE,
          )
        : "";
    },
  },
  methods: {
    async togglePublication(): Promise<void> {
      flagSavingProcess(`togglePublication-${this.zone.id}`, async () => {
        if (this.zoneIsPublished) {
          await this.unpublish();
        } else {
          await this.publish();
        }
      });
    },
    async publish() {
      const previousPublicationStatus = this.api.publicationStatus;

      await this.publishApiInCurrentZone();

      if (
        this.api.publicationStatus !== previousPublicationStatus &&
        this.api.publicationStatus === EApiPublicationStatus.WAITING_CONSUMERS
      ) {
        this.openModalWaitingConsumers();
      }
    },
    async unpublish() {
      if (await getApiRepository().hasAnyActiveSubscription(this.api.id)) {
        const unpublicationIsConfirmed = await this.openConfirmUnpublishModal();

        if (unpublicationIsConfirmed) {
          await this.unpublishApiInCurrentZone();
        }
      } else {
        await this.unpublishApiInCurrentZone();
      }
    },
    async publishApiInCurrentZone(): Promise<void> {
      await this.$store.dispatch("publishApiInZone", {
        zoneId: this.zone.id,
      });
    },
    async unpublishApiInCurrentZone(): Promise<void> {
      await this.$store.dispatch("unPublishApiInZone", {
        zoneId: this.zone.id,
      });
    },
    openConfirmUnpublishModal(): Promise<boolean> {
      return new Promise((resolve, reject) => {
        this.$store.commit("openModal", {
          title: contentsOfConfirmUnpublishModal.modalTitle,
          component: ConfirmUnpublishModal,
          props: {
            apiId: this.api.id,
          },
          listeners: {
            onSubmit: async () => {
              this.$store.commit("closeModal");
              resolve(true);
            },
            onClose: () => reject(new ModalClosedError()),
          },
        });
      });
    },
    openModalWaitingConsumers(): void {
      this.$store.commit("openModal", {
        title: contentsOfModalWaitingConsumers.modalTitle,
        component: ModalWaitingConsumers,
      });
    },
  },
};
</script>

<style lang="scss">
.publication-zone-card__deprecated-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 0.75rem;
}

.publication-zone-card__deprecated-date {
  @include set-text-s;
  flex: 1;
  color: var(--color-text-minor);
}

.publication-zone-card__status-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 1.5rem;
}

.publication-zone-card__last-deploy {
  @include set-text-s;
  flex: 1;
  color: var(--color-text-minor);
}

.publication-zone-card__title {
  margin-top: 0;
  margin-bottom: 1rem;
}

.publication-zone-card__divider {
  margin: 1.5rem 0;
}
</style>
