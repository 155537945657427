export default {
  deprecateApi: {
    label: "Turn this API to deprecated",
    description:
      "Your API will not be able to get new consumers if it has been turned to deprecated",
    btnTitle: "Deprecate API",
  },
  undeprecateApi: {
    label: "Turn this API to undeprecated",
    description:
      "Consumers will be again able to subscribe to your API if it has been turned to undeprecated",
    btnTitle: "Undeprecate API",
  },
  deprecateApiZone: {
    label: "Turn a published zone deprecated",
    description:
      "Your consumers will be informed that they should use another zone",
    btnTitle: "Deprecate zone",
  },
  deleteApi: {
    label: "Delete API",
    description:
      "Your API will be unpublished (if not already done) and all contracts will be deleted",
    btnTitle: "Delete API",
  },
  title: "Sensitive modifications",
};
