<template>
  <div class="api-info-for-navbar">
    <h5 class="api-info-for-navbar__api-title">
      {{ apiTitle }}
    </h5>
    <em v-if="apiProduct">
      {{ contents.fromProduct }}
      <router-link
        class="link"
        :to="{
          name: 'productDocumentationDetail',
          params: { id: apiProduct.id },
        }"
      >
        {{ apiProduct.name }}
      </router-link>
    </em>
  </div>
</template>

<script lang="ts">
import { PropType } from "vue";

import { getApiLabelWithVersion } from "@/commons/libs/utils/apiUtils";

import { Api } from "@/commons/domain/models/api";
import { Product } from "@/commons/domain/models/product";

import contents from "@/commons/contents/secondary-navbar-api";

export default {
  props: {
    api: {
      type: Object as PropType<Api>,
      required: true,
    },
  },
  data() {
    return {
      contents,
    };
  },
  computed: {
    apiTitle(): string {
      return getApiLabelWithVersion(this.api);
    },
    apiProduct(): Product | undefined {
      return this.api.product;
    },
  },
};
</script>

<style lang="scss">
.api-info-for-navbar__api-title {
  margin: 0;
}
</style>
