import { FormData } from "@/commons/domain/forms/form-data";
import { FormFactory } from "@/commons/domain/forms/form-factory";

export interface FiltersProductCatalogFormData {
  platformsIds?: string[];
}

export class FiltersProductCatalogForm extends FormData {
  platformsIds: string[] = [];

  $clear() {
    this.platformsIds = [];
  }

  constructor(platformsIds: string[] = []) {
    super();
    this.platformsIds = platformsIds;
  }

  static create() {
    return FormFactory.createForm(new FiltersProductCatalogForm());
  }
}
