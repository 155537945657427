<template>
  <LayerModalFormConfirm
    class="multi-acf-providers-prevention-step2-modal"
    confirmBtnTheme="solid-danger"
    :confirmBtnLabel="contents.confirmButtonStep2"
    :disabledConfirmBtn="
      form.pristine || form.errors.length > 0 || isSavingSubmission
    "
    @submit="submit"
    @close="close"
  >
    <div class="multi-acf-providers-prevention-step-2-modal__content">
      <DvpStepper
        class="multi-acf-providers-prevention-step-2-modal__stepper"
        :steps="stepperSteps"
        :stepperDescription="contents.stepperDescription"
      />

      <h4>{{ contents.titleStep2 }}</h4>
      <p class="multi-acf-providers-prevention-step-2-modal__irrevocable-info">
        {{ contents.actionCannotBeUndone }}
      </p>
      <Markdown
        class="multi-acf-providers-prevention-step-2-modal__confirmation-field-description"
        :content="contents.unsubscriptionInputDescription"
      />

      <DvpField
        class="multi-acf-providers-prevention-step-2-modal__confirmation-field"
        required
        :errorMessage="form.firstError('text')"
      >
        <MTextInput
          v-model="form.text"
          type="text"
          :placeholder="contents.unsubscribe"
          :isInvalid="form.hasError('text')"
        />
      </DvpField>

      <div
        class="multi-acf-providers-prevention-step-2-modal__api-list-section"
      >
        <p>{{ contents.listOfImpactedApis }}</p>
        <ul class="multi-acf-providers-prevention-step-2-modal__api-list">
          <li
            v-for="api in apisWithACFSubscription"
            :key="api.id"
            class="multi-acf-providers-prevention-step-2-modal__api-list-item"
          >
            {{ api.label }}
          </li>
          <li
            v-if="
              paginatedAcfContractsOfApplication.totalCount >
              paginatedAcfContractsOfApplication.count
            "
          >
            {{ contents.andMore }}
          </li>
        </ul>
      </div>
    </div>
  </LayerModalFormConfirm>
</template>

<script lang="ts">
import MTextInput from "@mozaic-ds/vue-3/src/components/textinput/MTextInput.vue";
import { PropType } from "vue";

import DvpStepper from "@/commons/components/DvpStepper.vue";
import DvpField from "@/commons/components/form/DvpField.vue";
import LayerModalFormConfirm from "@/commons/components/form/LayerModalFormConfirm.vue";
import Markdown from "@/documentation/components/commons/Markdown.vue";

import { getApiLabelWithVersion } from "@/commons/libs/utils/apiUtils";
import { flagSavingProcess } from "@/commons/utils/flagProcess.utils";

import { ConfirmActionForm } from "@/commons/domain/forms/confirm-action-form";
import { Contract } from "@/commons/domain/models/contract";
import { PagedResource } from "@/commons/domain/models/paged-resource";

import contents from "@/dashboard/contents/multi-acf-providers-prevention-modal";

export default {
  components: {
    LayerModalFormConfirm,
    Markdown,
    DvpField,
    MTextInput,
    DvpStepper,
  },
  props: {
    paginatedAcfContractsOfApplication: {
      type: Object as PropType<PagedResource<Contract>>,
      required: true,
    },
  },
  emits: ["onClose"],
  data() {
    return {
      contents,
      form: ConfirmActionForm.create(contents.unsubscribe),
    };
  },
  computed: {
    isSavingSubmission() {
      return this.$store.getters["isSavingProperty"](
        "deleteAcfContractsOfCurrentApplication",
      );
    },
    apisWithACFSubscription() {
      const acfContracts =
        this.paginatedAcfContractsOfApplication != undefined
          ? Object.values<Contract>(
              this.paginatedAcfContractsOfApplication.data,
            )
          : [];

      return acfContracts.map((contract: any) => ({
        label: getApiLabelWithVersion(contract.api),
        id: contract.api.id,
      }));
    },
    stepperSteps() {
      return [
        { label: contents.step1Label },
        { label: contents.step2Label, isCurrent: true },
      ];
    },
  },
  created() {
    this.form.init();
  },
  methods: {
    submit(): void {
      flagSavingProcess("deleteAcfContractsOfCurrentApplication", async () => {
        await this.$store.dispatch("deleteAcfContractsOfCurrentApplication");
        this.close();
        this.$store.commit("postSuccessNotification", {
          title: contents.acfSubscriptionSuccessfullyDeleted,
        });
      });
    },
    close(): void {
      this.$emit("onClose");
    },
  },
};
</script>

<style lang="scss">
.multi-acf-providers-prevention-step-2-modal__stepper {
  margin-bottom: 1.5rem;
}

.multi-acf-providers-prevention-step-2-modal__irrevocable-info {
  margin: 0;
  color: var(--color-status-danger);
}

.multi-acf-providers-prevention-step-2-modal__confirmation-field-description {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.multi-acf-providers-prevention-step-2-modal__api-list-section {
  margin-top: 2rem;
}

.multi-acf-providers-prevention-step-2-modal__api-list {
  margin: 0;
}

.multi-acf-providers-prevention-step-2-modal__api-list-item {
  font-weight: var(--weight-bold);
}
</style>
