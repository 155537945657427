import { CustomProxyHeaderForm } from "./custom-proxy-header-form";
import { Validate, ValidateNested } from "class-validator";

import { ArrayOfForms } from "@/commons/domain/forms/array-of-forms";
import { Form } from "@/commons/domain/forms/form";
import { FormFactory } from "@/commons/domain/forms/form-factory";
import { CustomProxyHeader } from "@/commons/domain/models/custom-proxy-header";
import { FieldValueIsUniqueInList } from "@/commons/forms/class-validator/custom-validators/field-value-is-unique-in-list";

export class CustomProxyHeadersListForm extends ArrayOfForms<CustomProxyHeaderForm> {
  @Validate(FieldValueIsUniqueInList, ["name", "values"], {
    message: "You are trying to add a custom proxy already existing",
    each: true,
  })
  @ValidateNested()
  values: Form<CustomProxyHeaderForm>[] = [];

  @Validate(FieldValueIsUniqueInList, ["name", "values"], {
    message: "You are trying to add a custom proxy already existing",
  })
  newValue: Form<CustomProxyHeaderForm> = undefined;

  constructor(customProxyHeadersList: {
    values: CustomProxyHeader[];
    newValue?: CustomProxyHeader;
  }) {
    super();
    this.values = this.createCustomProxyHeadersForms(
      customProxyHeadersList.values,
    );
    this.newValue = this.createCustomProxyHeaderForm(
      customProxyHeadersList.newValue,
    );
  }

  $update(
    partial: Partial<{
      values: CustomProxyHeader[];
      newValue: CustomProxyHeader;
    }>,
  ) {
    const { values, newValue } = partial;

    if (values) {
      this.values = this.createCustomProxyHeadersForms(partial.values);
    }

    if (newValue) {
      this.newValue.update(newValue);
    }
  }

  private createCustomProxyHeadersForms(values: CustomProxyHeader[]) {
    const customProxyHeadersForms = [];

    for (const customProxyHeader of values) {
      customProxyHeadersForms.push(
        this.createCustomProxyHeaderForm(customProxyHeader),
      );
    }

    return customProxyHeadersForms;
  }

  private createCustomProxyHeaderForm(customProxyHeader?: CustomProxyHeader) {
    return CustomProxyHeaderForm.create(customProxyHeader);
  }

  static create(
    values: CustomProxyHeader[] = [],
    newValue?: CustomProxyHeader,
  ) {
    return FormFactory.createForm(
      new CustomProxyHeadersListForm({ values, newValue }),
    );
  }
}
