import { Form } from "./form";
import { FormData } from "./form-data";
import { Container } from "@/container";
import { reactive } from "vue";

import { Validator } from "@/commons/domain/validation/validator";

import { TYPES } from "@/types";

export class FormFactory {
  static createForm<T extends FormData>(data: T) {
    const validator: Validator = Container.instance().get(TYPES.VALIDATOR);
    const form = reactive(new Form(validator, data)) as unknown as Form<T>;

    return new Proxy(form, {
      has(target, key) {
        if (key[0] === "_") {
          return false;
        }
        return key in target._formData || key in target;
      },
      get(target, prop, receiver) {
        if (prop in target._formData) {
          return Reflect.get(target._formData, prop, receiver);
        } else {
          return Reflect.get(target, prop, receiver);
        }
      },
      set(target, prop, value, receiver) {
        if (Object.prototype.hasOwnProperty.call(target._formData, prop)) {
          form.update.call(receiver, { [prop]: value });
          return true;
        } else {
          return Reflect.set(target, prop, value, receiver);
        }
      },
    });
  }
}
