<template>
  <svg
    id="Calque_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 17.9485493 10.2454062"
    style="enable-background: new 0 0 17.9485493 10.2454062"
    xml:space="preserve"
  >
    <g>
      <path
        d="M17.0497837,10.2454062c-0.2299576,0-0.4599152-0.0877705-0.6354561-0.2633104L8.9744949,2.5418227L1.5342216,9.9820957
		c-0.3510804,0.3510799-0.9198308,0.3510799-1.2709113,0c-0.3510805-0.3510809-0.3510805-0.9198313,0-1.2709112L8.9744949,0
		l8.7107449,8.7111845c0.3510799,0.3510799,0.3510799,0.9198303,0,1.2709112
		C17.5096989,10.1576357,17.2797413,10.2454062,17.0497837,10.2454062z"
      />
    </g>
  </svg>
</template>
