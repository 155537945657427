import { AclUser } from "@/commons/domain/models/acl-user";
import { EGroupProvider, Group } from "@/commons/domain/models/group";
import { GroupWithUsersAcls } from "@/commons/domain/models/group-with-users-acls";

import { EAction } from "@/commons/store/types";

export function userHasWriteAccessOnGroup(group: Group): boolean {
  return Boolean(group?.currentUserActions?.includes(EAction.WRITE));
}

export function isMCCGroup(group: Group): boolean {
  return group?.provider === EGroupProvider.MCC;
}

export function isLocalGroup(group: Group): boolean {
  return group?.provider === EGroupProvider.LOCAL;
}

export function lastMCCGroupWillBeDeleted(groups, groupId: string) {
  const groupToDelete = groups.find((group) => group.id === groupId);
  return (
    isMCCGroup(groupToDelete) &&
    groups
      .filter((group) => isMCCGroup(group))
      .some((group) => group.id !== groupId) === false
  );
}

export function isOnlyInternalsGroup(group: GroupWithUsersAcls): boolean {
  return group != undefined && group.users != undefined
    ? Object.values(group?.users).some((user: AclUser) => user.isPartner) ===
        false
    : false;
}
