<template>
  <label class="label">
    <slot />
    <Tooltip v-if="tooltip" :tooltipContent="tooltip" class="label__tooltip">
      <MIcon name="NotificationQuestion24" />
    </Tooltip>
    <span v-if="isRequired" class="label__required-message weight-medium">
      required
    </span>
  </label>
</template>

<script lang="ts">
import MIcon from "@mozaic-ds/vue-3/src/components/icon/MIcon.vue";

import Tooltip from "@/commons/components/Tooltip/Tooltip.vue";

export default {
  components: { Tooltip, MIcon },
  props: {
    tooltip: {
      type: String,
      default: undefined,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.label {
  display: flex;
  font-size: var(--size-400);
  font-style: normal;

  &::first-letter {
    text-transform: capitalize;
  }

  .label__tooltip {
    margin-left: 0.2rem;
  }

  .label__required-message {
    margin-left: auto;
    font-style: italic;
  }
}
</style>
