import { getContractRepository } from "@/commons/repositories/libs/get-contract-repository";

import { Contract } from "@/commons/domain/models/contract";
import { ContractMapper } from "@/commons/mappers/contract-mapper";

import { CONTRACT_STATUSES_FOR_APPLICATION_CONTRACTS_LISTING } from "@/commons/store/types";

export async function getContractById(contractId: string): Promise<Contract> {
  return getContractRepository().findById(contractId);
}

export async function findContractsWithActivatedSecretSynchronizations(
  apiId: string,
): Promise<Contract[]> {
  return Object.values(
    (
      await getContractRepository().findByApi({
        apiId,
        withActivatedSecretSync: true,
        size: 100,
      })
    ).data,
  );
}

export const getAllContractsOfCurrentApplication = async (
  applicationId: string,
) => {
  // For contacting owners we have to do a new fetch for contracts of other pages
  const paginatedContractsOfCurrentApplication =
    await getContractRepository().findByApplication(
      ContractMapper.toGetContractDTO({
        applicationId: applicationId,
        size: 100,
        status: CONTRACT_STATUSES_FOR_APPLICATION_CONTRACTS_LISTING,
      }),
    );

  return Object.values(paginatedContractsOfCurrentApplication.data);
};
