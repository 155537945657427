<template>
  <div class="cropped-text">
    <ButtonCopy :content="text" />
    <DvpTooltip :text="text" position="bottom">
      {{ croppedText }}
    </DvpTooltip>
  </div>
</template>

<script lang="ts">
import ButtonCopy from "@/commons/components/Buttons/ButtonCopy.vue";
import DvpTooltip from "@/commons/components/DvpTooltip.vue";

import contents from "@/commons/contents/cropped-text";

export default {
  name: "CroppedText",
  components: {
    ButtonCopy,
    DvpTooltip,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    croppedTextSize: {
      type: Number,
      default: 60,
    },
  },
  data() {
    return { contents };
  },
  computed: {
    croppedText() {
      return this.text.length > this.croppedTextSize
        ? `${this.text.substring(0, this.croppedTextSize - 1)}...`
        : this.text;
    },
  },
};
</script>
