<template>
  <div class="request-action-bar">
    <h5 class="request-action-bar__label" v-html="computeLabel()" />
    <div class="request-action-bar__actions">
      <div
        v-if="canCancel || canDenyOrAccept"
        class="request-action-bar__request-actions"
      >
        <MButton
          v-if="canDenyOrAccept"
          data-cy="deny-request-btn"
          theme="bordered-danger"
          :label="contents.buttons.denyRequest"
          @click="denyRequest"
        />
        <MButton
          v-if="canCancel"
          data-cy="cancel-request-btn"
          theme="bordered-neutral"
          :label="contents.buttons.cancelRequest"
          @click="cancelRequest"
        />
        <MButton
          v-if="canDenyOrAccept"
          data-cy="accept-request-btn"
          :label="contents.buttons.acceptRequest"
          @click="acceptRequest"
        />
      </div>
      <div class="request-action-bar__slack-action">
        <RequestManagerButtonViewSlackMessages
          v-if="slackUrl"
          :slackUrl="slackUrl"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { buildFetchParams } from "./build-fetch-params";
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";

import RequestManagerButtonViewSlackMessages from "@/request-manager/views/RequestManagerButtonViewSlackMessages.vue";

import contents from "@/request-manager/contents/request-manager-contents.js";
import { requestLabel } from "@/request-manager/contents/request-manager-utils";

export default {
  name: "RequestManagerActionBar",
  components: {
    RequestManagerButtonViewSlackMessages,
    MButton,
  },
  props: {
    urlQuery: {
      type: Object,
      required: true,
    },
  },

  data: function () {
    return {
      contents: contents.ui,
    };
  },

  computed: {
    currentRequest() {
      return this.$store.getters["currentRequest"];
    },
    requestAllowedActions() {
      return this.$store.getters["requestAllowedActions"];
    },
    canDenyOrAccept() {
      return this.requestAllowedActions.APPROVE_DENY_REQUEST;
    },
    canCancel() {
      return this.requestAllowedActions.CANCEL_REQUEST;
    },
    slackUrl() {
      return this.currentRequest?.slackUrl || undefined;
    },
  },

  methods: {
    computeLabel() {
      return requestLabel(this.currentRequest, true);
    },
    acceptRequest() {
      this.$store.dispatch("processRequest", {
        decision: "approveRequest",
        requestId: this.currentRequest.id,
        params: buildFetchParams(this.urlQuery),
      });
    },
    denyRequest() {
      this.$store.dispatch("processRequest", {
        decision: "denyRequest",
        requestId: this.currentRequest.id,
        params: buildFetchParams(this.urlQuery),
      });
    },
    cancelRequest() {
      this.$store.dispatch("processRequest", {
        decision: "cancelRequest",
        requestId: this.currentRequest.id,
        params: buildFetchParams(this.urlQuery),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.request-action-bar {
  padding: 0 1.5rem 1.5rem;
  border-bottom: var(--border-default);
}

.request-action-bar__label {
  text-align: left;
}

.request-action-bar__request-actions {
  display: flex;
  justify-content: flex-start;
  gap: var(--base-spacing);
}

.request-action-bar__actions {
  display: flex;
  align-items: center;
}

.request-action-bar__slack-action {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
</style>
