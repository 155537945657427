<template>
  <SecondaryNavbar class="product-detail-secondary-navbar">
    <template #secondary-navbar-header>
      <h5 v-if="product">{{ product.name }}</h5>
    </template>
    <template #secondary-navbar-body>
      <div class="product-detail-secondary-navbar__body">
        <img
          v-if="product && product.logoUrl"
          :src="product.logoUrl"
          :alt="product.name"
          class="product-detail-secondary-navbar__logo"
        />

        <Divider vertical class="product-detail-secondary-navbar__divider" />

        <div v-if="product && product.providedApis">
          {{ contents.providing }}
          <span class="product-detail-secondary-navbar__apis-number">
            {{ providedApisLength }}
          </span>
          {{
            pluralize(providedApisLength, "API", { shouldExcludeCount: true })
          }}
        </div>

        <Divider vertical class="product-detail-secondary-navbar__divider" />

        <div v-if="product && product.usedApis">
          {{ contents.using }}
          <span class="product-detail-secondary-navbar__apis-number">
            {{ usedApisLength }}
          </span>
          {{ pluralize(usedApisLength, "API", { shouldExcludeCount: true }) }}
        </div>

        <template v-if="userIsInternal && product && product.pageUrl">
          <Divider vertical class="product-detail-secondary-navbar__divider" />

          <a
            target="_blank"
            :href="product.pageUrl"
            class="product-detail-secondary-navbar__tangram"
          >
            <img
              class="product-detail-secondary-navbar__tangram-logo"
              :src="tangramLogo"
              :alt="contents.viewOnTangram"
            />
            <span>{{ contents.viewOnTangram }}</span>
          </a>
        </template>
      </div>
    </template>
  </SecondaryNavbar>
</template>

<script lang="ts">
import { PropType } from "vue";

import Divider from "@/commons/components/Divider.vue";
import SecondaryNavbar from "@/commons/components/Navbar/SecondaryNavbar.vue";

import { pluralize } from "@/commons/utils/contents-utils";

import { Product } from "@/commons/domain/models/product";

import contents from "@/commons/contents/product-detail-secondary-navbar";

export default {
  components: {
    SecondaryNavbar,
    Divider,
  },
  props: {
    product: {
      type: Object as PropType<Product>,
      default: undefined,
    },
  },
  data() {
    return {
      contents,
      tangramLogo: require("@/commons/assets/images/logo-tangram.svg"),
    };
  },
  computed: {
    userIsInternal() {
      return this.$store.getters["userIsInternal"];
    },
    providedApisLength() {
      return this.product.providedApis ? this.product.providedApis.length : 0;
    },
    usedApisLength() {
      return this.product.usedApis ? this.product.usedApis.length : 0;
    },
  },
  methods: {
    pluralize,
  },
};
</script>

<style lang="scss">
.product-detail-secondary-navbar__body {
  display: flex;
  align-items: center;
  height: 100%;
  column-gap: 1.25rem;
}

.product-detail-secondary-navbar__divider {
  height: 2rem;
}

.product-detail-secondary-navbar__logo {
  max-width: 10rem;
  height: auto;
  max-height: 100%;
  padding: 0.5rem 0;
}

.product-detail-secondary-navbar__tangram {
  display: flex;
  align-items: center;
}
.product-detail-secondary-navbar__tangram-logo {
  width: 2.5rem;
}

.product-detail-secondary-navbar__apis-number {
  font-weight: 700;
}
</style>
