import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withModifiers as _withModifiers, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomInput = _resolveComponent("CustomInput")!
  const _component_PickersContainer = _resolveComponent("PickersContainer")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    id: `${_ctx.$attrs.id}-wrapper`,
    ref: "parent",
    class: _normalizeClass(["date-time-picker", [$options.cssFieldElementClass]])
  }, [
    ($options.hasInput)
      ? (_openBlock(), _createBlock(_component_CustomInput, _mergeProps({ key: 0 }, _ctx.$attrs, {
          id: `${_ctx.$attrs.id}-input`,
          ref: "custom-input",
          modelValue: $options.dateFormatted,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($options.dateFormatted) = $event)),
          dark: _ctx.dark,
          hint: _ctx.hint,
          "error-hint": _ctx.error,
          "is-focus": $options.hasPickerOpen,
          color: _ctx.color,
          label: _ctx.label,
          "no-label": _ctx.noLabel,
          "input-size": _ctx.inputSize,
          "no-clear-button": _ctx.noClearButton,
          onFocus: _cache[1] || (_cache[1] = ($event: any) => ($options.toggleDatePicker(true))),
          onClear: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:modelValue', null)))
        }), null, 16, ["id", "modelValue", "dark", "hint", "error-hint", "is-focus", "color", "label", "no-label", "input-size", "no-clear-button"]))
      : _renderSlot(_ctx.$slots, "default", { key: 1 }),
    ($options.hasPickerOpen && _ctx.overlay)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "time-picker-overlay",
          onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => ($options.closePicker && $options.closePicker(...args)), ["stop"]))
        }))
      : _createCommentVNode("", true),
    (!$options.isDisabled)
      ? (_openBlock(), _createBlock(_component_PickersContainer, {
          key: 3,
          id: `${_ctx.$attrs.id}-picker-container`,
          ref: "agenda",
          modelValue: $options.dateTime,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($options.dateTime) = $event)),
          visible: $options.hasPickerOpen,
          position: $data.pickerPosition,
          inline: _ctx.inline,
          color: _ctx.color,
          "button-color": _ctx.buttonColor,
          dark: _ctx.dark,
          "no-header": _ctx.noHeader,
          "only-time": _ctx.onlyTime,
          "only-date": $options.hasOnlyDate,
          "minute-interval": _ctx.minuteInterval,
          locale: _ctx.locale,
          "min-date": _ctx.minDate,
          "max-date": _ctx.maxDate,
          format: _ctx.format,
          "no-weekends-days": _ctx.noWeekendsDays,
          "disabled-weekly": _ctx.disabledWeekly,
          "has-button-validate": $options.hasButtonValidate,
          "has-no-button": $options.hasNoButton,
          range: _ctx.range,
          "disabled-dates": _ctx.disabledDates,
          "disabled-hours": _ctx.disabledHours,
          "enabled-dates": _ctx.enabledDates,
          "no-shortcuts": _ctx.noShortcuts,
          "button-now-translation": _ctx.buttonNowTranslation,
          "no-button-now": _ctx.noButtonNow,
          "first-day-of-week": _ctx.firstDayOfWeek,
          shortcut: _ctx.shortcut,
          "custom-shortcuts": _ctx.customShortcuts,
          "no-keyboard": _ctx.noKeyboard,
          right: _ctx.right,
          behaviour: $options._behaviour,
          onValidate: $options.validate,
          onClose: $options.closePicker
        }, null, 8, ["id", "modelValue", "visible", "position", "inline", "color", "button-color", "dark", "no-header", "only-time", "only-date", "minute-interval", "locale", "min-date", "max-date", "format", "no-weekends-days", "disabled-weekly", "has-button-validate", "has-no-button", "range", "disabled-dates", "disabled-hours", "enabled-dates", "no-shortcuts", "button-now-translation", "no-button-now", "first-day-of-week", "shortcut", "custom-shortcuts", "no-keyboard", "right", "behaviour", "onValidate", "onClose"]))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1)), [
    [_directive_click_outside, $options.closePicker]
  ])
}