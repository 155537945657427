<template>
  <div class="home-resume">
    <!--eslint-disable-next-line vue/no-v-html-->
    <h1 class="title" v-html="contents.resumeHeaderTitle" />
    <div class="home-resume__buttons">
      <MButton
        v-if="!isLogged"
        :label="contents.signInButtonLabel"
        @click="
          () =>
            $router.push({
              name: 'login',
              query: { redirect: $route.fullPath },
            })
        "
      />
      <MButton
        v-else
        :label="contents.navigateToDashboardButtonLabel"
        @click="() => $router.push({ name: 'dashboard' })"
      />
    </div>
    <div class="home-resume__footer">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";

import content from "@/showcase/contents/home-resume";

export default {
  name: "HomeResume",
  components: {
    MButton,
  },
  computed: {
    isLogged() {
      return this.$store.getters["userIsLogged"];
    },
    locationSettings() {
      return this.$store.getters["config/locationSettings"];
    },
    contents() {
      return content[this.locationSettings.name];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/commons/assets/styles/vars";
@import "~@/showcase/views/common/home";

.home-resume {
  position: relative;

  display: flex;
  flex-direction: column;

  width: 91.4rem;
  max-width: 52rem;

  margin-top: 18vh;
  margin-bottom: 5vh;

  text-align: center;
}

.home-resume__buttons {
  display: flex;
  justify-content: center;
  text-align: center;

  & > * {
    margin: 2rem 0;
  }
}

@media only screen and (max-width: map-get($breakpoints, tablet-landscape)) {
  .home-resume {
    max-width: 48rem;
  }
}

@media only screen and (max-width: map-get($breakpoints, tablet-portrait)) {
  .home-resume {
    max-width: 36rem;
  }
}

@media only screen and (max-width: map-get($breakpoints, mobile)) {
  .home-resume {
    width: calc(100% - 2rem);
    margin: 0 0 5rem;
  }

  .home-resume__buttons .button {
    display: none;
  }
}
</style>
