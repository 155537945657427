export default {
  modalTitle: (apiLabel: string) => `${apiLabel} - Info & Links`,
  linksTitle: "Links",
  limitationsTitle: "Limitations",
  internal: "Internal",
  public: "Public",
  quotaLabel: (displayQuota) => `Quota: ${displayQuota}`,
  timeoutLabel: (displayTimeout) => `Timeout: ${displayTimeout}`,
  apiRateLimit: (rateLimitLabel) => `API ${rateLimitLabel}`,
  appRateLimit: (rateLimitLabel) => `APP ${rateLimitLabel}`,
  noQuota: "No quota",
  closeBtnLabel: "Close",
};
