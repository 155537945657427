<template>
  <ContractCardContainer class="contract-warning-api-unpublished">
    <ContractInformation :contract="contract" />

    <ContractWarning
      :title="contents.apiHasBeenUnpublished"
      :message="contents.notAvailable"
      iconName="NotificationWarning24"
    >
      <ContractRemoveButton
        v-if="userHasWriteAccessOnCurrentApplication"
        class="contract-warning-api-unpublished__remove-button"
        :contract="contract"
        @contractDeleted="$emit('contractDeleted')"
      />

      <MNotification
        v-if="mustDisplayWarningMessage"
        class="contract-warning-api-unpublished__warning-notification"
        type="warning"
      >
        {{ warningMessage }}
      </MNotification>
    </ContractWarning>
  </ContractCardContainer>
</template>

<script lang="ts">
import MNotification from "@mozaic-ds/vue-3/src/components/notification/MNotification.vue";
import { PropType } from "vue";

import ContractCardContainer from "@/dashboard/views/ContractsList/contractCard/ContractCardContainer.vue";
import ContractInformation from "@/dashboard/views/ContractsList/contractCard/ContractInformation.vue";

import ContractRemoveButton from "./ContractRemoveButton.vue";
import ContractWarning from "./ContractWarning.vue";

import { apiLabel } from "@/commons/libs/utils/apiUtils";
import {
  hasOnlyOneRemainingOAuthContract,
  isOAuthContract,
} from "@/commons/libs/utils/contractUtils";

import { Contract } from "@/commons/domain/models/contract";

import contents from "@/commons/contents/contract-warning-api-unpublished";

export default {
  components: {
    ContractInformation,
    ContractCardContainer,
    MNotification,
    ContractWarning,
    ContractRemoveButton,
  },
  props: {
    contract: {
      type: Object as PropType<Contract>,
      required: true,
    },
  },
  emits: ["contractDeleted"],
  data() {
    return {
      contents,
    };
  },
  computed: {
    // We should check WRITE access on Contract instead of Application but today there is no way to know if the user has write access on Contract object
    userHasWriteAccessOnCurrentApplication() {
      return this.$store.getters["userHasWriteAccessOnCurrentApplication"];
    },
    mustDisplayWarningMessage() {
      return "" !== this.warningMessage;
    },
    warningMessage() {
      let message = "";

      if (isOAuthContract(this.contract)) {
        if (hasOnlyOneRemainingOAuthContract(this.contract)) {
          message = contents.lastOAuthContract;
        }
      } else {
        message = contents.apiKeyWillBeDeleted;
      }

      return message;
    },
  },
  methods: { apiLabel },
};
</script>
<style lang="scss">
.contract-warning-api-unpublished__remove-button {
  width: 14rem;
}
.contract-warning-api-unpublished__warning-notification {
  text-align: left;
}
</style>
