module.exports = {
  modalTitle: "Contact owners",
  apiSelectionLabel: "APIs you consume",
  apiSelectionPlaceholder: "Choose an API",
  subjectLabel: "Subject",
  subjectPlaceholder: "Please specify your request",
  messageLabel: "Your message",
  messagePlaceholder: "Your message...",
  submit: "Send your message",
  sendingSuccessNotification: "Message sent",
  contactOwnersErrorNotification: "No team found for this api",
};
