<template>
  <LayerModalFormConfirm
    class="delete-group-modal"
    :disabledConfirmBtn="!!form.errors.length"
    @submit="submit"
    @close="emit('onClose')"
  >
    <h4>{{ contents.title }}</h4>
    <div>{{ contents.subtitle }}</div>
    <DvpField
      :label="contents.deleteLabel"
      :isInvalid="isInvalidDeletionText"
      :errorMessage="form.firstError('deletionText')"
    >
      <MTextInput
        v-model="form.deletionText"
        :placeholder="contents.delete"
        :isInvalid="isInvalidDeletionText"
      />
    </DvpField>
  </LayerModalFormConfirm>
</template>

<script setup lang="ts">
import MTextInput from "@mozaic-ds/vue-3/src/components/textinput/MTextInput.vue";
import { computed, onMounted } from "vue";

import DvpField from "@/commons/components/form/DvpField.vue";
import LayerModalFormConfirm from "@/commons/components/form/LayerModalFormConfirm.vue";

import { DeletionGroupForm } from "@/dashboard/domain/forms/deletion-group-form";

import contents from "@/commons/contents/delete-group-modal";

const emit = defineEmits(["onSubmit", "onClose"]);

const form = DeletionGroupForm.create();

onMounted(async () => {
  await form.init();
});

const isInvalidDeletionText = computed(() => {
  return form.firstError("deletionText") != null;
});

const submit = async () => {
  await form.validate();

  if (!form.errors.length) {
    emit("onSubmit");
  }

  emit("onClose");
};
</script>

<style lang="scss">
.delete-group-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--base-spacing);
  height: 100%;
}
</style>
