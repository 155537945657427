<template>
  <Stack class="card-content-api">
    <WithSidebar sidebar-to-right>
      <template #left>
        <div v-if="apiType" class="card-content-api__type">
          <span>{{ apiTypeLabel }}</span>
          <span
            v-if="apiSubtype"
            :class="[
              {
                'card-content-api__subtype--unknown': isUnknownSubtype,
              },
            ]"
          >
            {{ ` - ${apiSubtype}` }}
          </span>
        </div>
        <div class="card-content-api__name">{{ name }}</div>
        <div
          v-if="productName"
          class="card-content-api__product"
          data-cy="api-product"
        >
          <i>from "{{ productName }}"</i>
        </div>
      </template>
      <template #right
        ><div class="card-content-api__header-right">
          <MBadge v-if="hasBlockingPendingRequest" type="warning">{{
            contents.pendingRequest
          }}</MBadge>
          <Chip>v{{ version }}</Chip>
        </div>
      </template>
    </WithSidebar>
    <p class="card-content-api__description">
      {{ getDescription(description) }}
    </p>
  </Stack>
</template>

<script lang="ts">
import MBadge from "@mozaic-ds/vue-3/src/components/badge/MBadge.vue";

import Chip from "@/commons/components/Chip.vue";
import Stack from "@/commons/components/Stack.vue";
import WithSidebar from "@/commons/components/WithSidebar.vue";

import { EApiSubtype, EApiType, EApiTypeLabel } from "@/commons/store/types";

import contents from "@/commons/contents/api-card-listing";

export default {
  name: "CardContentApi",
  components: {
    Stack,
    WithSidebar,
    Chip,
    MBadge,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    version: {
      type: Number,
      required: true,
    },
    description: {
      type: String,
      default: undefined,
    },
    productName: {
      type: String,
      default: undefined,
    },
    apiType: {
      type: String,
      default: undefined,
    },
    apiSubtype: {
      default: undefined,
      type: String,
    },
    hasBlockingPendingRequest: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      contents,
    };
  },
  computed: {
    apiTypeLabel() {
      return this.apiType === EApiType.API_REST
        ? EApiTypeLabel[this.apiType]
        : EApiTypeLabel[this.apiType];
    },
    isUnknownSubtype() {
      return this.apiSubtype === EApiSubtype.UNKNOWN;
    },
  },
  methods: {
    getDescription(description) {
      if (description && description.length > 200) {
        return description.substring(0, 200) + "...";
      }
      return description;
    },
  },
};
</script>

<style lang="scss">
.card-content-api {
  --stack-layout-glutter: 0.5rem;
  --chip-bg-color: var(--color-text);
  --chip-space: 1rem;
  --chip-height: var(--size-200);
  --chip-text: var(--size-200);
  --sidebar-layout-side-width: "content";

  > p {
    max-height: 7rem;
  }
}

.card-content-api__type {
  @include set-text-s;
}

.card-content-api__name {
  font-weight: var(--weight-bold);
}

.card-content-api__product {
  @include set-text-s;
  font-weight: var(--weight-light);
}

.card-content-api__header-right {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
}

.card-content-api__description {
  @include set-text-s;
  color: var(--color-text);
  word-break: break-word;
}

.card-content-api .sidebar-layout > * > * {
  margin: 0;
}

.card-content-api__subtype--unknown {
  color: var(--color-text-minor);
}
</style>
