export default {
  modalTitle: (groupName) => `Delete ${groupName}`,
  titleCanDelete: (groupName) =>
    `You are about to delete the group "${groupName}"`,
  descriptionCanDeleteGroup:
    "This action is irreversible. You will not be able to recover this group.",
  titleCantDelete: (groupName) => `You try to delete "${groupName}"`,
  managedBy: (groupName) => `managed by the "${groupName}" group`,
  descriptionCantDeleteGroup:
    "Deleting this group is forbidden. It will cause dependencies issues on:",
  transferOwnership: (groupName) =>
    `You must transfer ownership to another group before deleting "${groupName}"`,
  contactUs:
    'Feel free to contact our team by <a href="mailto:devportal@adeo.com">email</a> or our Slack channel <a href="https://adeo-tech-community.slack.com/archives/CF0UBRD9R">#devportal-run</a>',
  cancel: "Cancel",
  close: "Close",
  confirm: "I understand and I delete the group",
};
