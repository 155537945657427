<template>
  <IconButton
    class="button-copy-credentials"
    mozaicIconName="PublishCopy24"
    @click="copyToClipboard(content)"
  />
</template>

<script setup lang="ts">
import { useStore } from "vuex";

import IconButton from "@/commons/components/IconButton.vue";

import { writeText } from "@/commons/libs/write-text";

import contents from "@/commons/contents/button-copy-credentials";

defineProps({
  content: {
    type: String,
    required: true,
  },
});

const store = useStore();

const copyToClipboard = (value: string) => {
  writeText(value).then(() => {
    store.commit("postWarningNotification", {
      title: contents.copyClipboardSuccess,
      message: contents.copyClipboardWarning,
    });
  });
};
</script>
