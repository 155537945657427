import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "quota-rate-limit-display" }
const _hoisted_2 = { class: "quota-rate-limit-display__number" }
const _hoisted_3 = { class: "quota-rate-limit-display__barometer-icon" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "quota-rate-limit-display__requests-number" }
const _hoisted_6 = { class: "quota-rate-limit-display__unit-number" }
const _hoisted_7 = { class: "quota-rate-limit-display__unit-length" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MIcon = _resolveComponent("MIcon")!
  const _component_MToggle = _resolveComponent("MToggle")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", { src: $data.quotaIcon }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, _toDisplayString($props.rateLimit.maxRequests), 1),
      _createElementVNode("div", null, _toDisplayString($options.pluralize($props.rateLimit.maxRequests, $data.quotaContents.request, {
            shouldExcludeCount: true,
          })), 1),
      _createElementVNode("div", _hoisted_6, " / " + _toDisplayString($props.rateLimit.windowLength), 1),
      _createElementVNode("div", _hoisted_7, _toDisplayString($options.pluralize(
            $props.rateLimit.windowLength,
            $options.getWindowLengthUnitLabel($props.rateLimit.windowLengthUnit),
            { shouldExcludeCount: true },
          )), 1)
    ]),
    _createElementVNode("div", {
      class: "quota-rate-limit-display__modify-quota cursor-pointer",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('editRateLimit', $props.rateLimit)))
    }, [
      _createVNode(_component_MIcon, {
        name: "PublishEdit24",
        class: "quota-rate-limit-display__modify-quota-icon"
      }),
      _createElementVNode("div", null, _toDisplayString($data.contents.modifyGlobalQuota), 1)
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_MToggle, {
        id: $options.toggleId,
        disabled: $options.isSaving,
        modelValue: $props.rateLimit.isEnabled,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('activationChange', $event)))
      }, null, 8, ["id", "disabled", "modelValue"])
    ])
  ]))
}