<template>
  <LayerModalFormConfirm
    class="modal-filters-my-apps"
    :confirmBtnLabel="contents.confirmButtonLabel"
    confirmBtnTheme="solid"
    :disabledConfirmBtn="form.pristine"
    @submit="submit"
    @close="close"
  >
    <DvpField
      data-cy="used-apis-filter"
      class="modal-filters-api-catalog__platforms"
      :label="contents.apiUsedInTheApplicationLabel"
    >
      <MAutocomplete
        v-model="selectedUsedApisIds"
        :items="usedApis"
        :placeholder="contents.searchApiPlaceholder"
        dataTextExpr="name"
        dataValueExpr="id"
        :filter="false"
        :multiple="true"
        :input="defaultInputValue"
        :disabled="noAnyUsedApis"
        @update:input="debouncedSearchUsedApis"
      />
    </DvpField>

    <DvpField
      data-cy="products-filter"
      class="modal-filters-api-catalog__products"
      :label="contents.tangramProductLabel"
    >
      <MAutocomplete
        v-model="selectedProductIds"
        :items="products"
        :placeholder="contents.searchProductPlaceholder"
        dataTextExpr="name"
        dataValueExpr="id"
        :multiple="true"
        :filter="false"
        :input="defaultInputValue"
        @update:input="debouncedSearchProducts"
      />
    </DvpField>

    <DvpField
      data-cy="subscription-status-filter"
      class="modal-filters-my-apps__subscriptions-status"
    >
      <MCheckboxGroup
        v-model="form.subscriptionStatuses"
        :legend="contents.subscriptionStatusLabel"
        :options="subscriptionStatuses"
      />
    </DvpField>

    <DvpField
      data-cy="apis-status-filter"
      class="modal-filters-my-apps__apis-status"
    >
      <MCheckboxGroup
        v-model="form.apisStatuses"
        :legend="contents.apisStatusLabel"
        :options="apisStatuses"
      />
    </DvpField>
  </LayerModalFormConfirm>
</template>
<script lang="ts">
import MAutocomplete from "@mozaic-ds/vue-3/src/components/autocomplete/MAutocomplete.vue";
import MCheckboxGroup from "@mozaic-ds/vue-3/src/components/checkbox/MCheckboxGroup.vue";
import { PropType } from "vue";

import DvpField from "@/commons/components/form/DvpField.vue";
import LayerModalFormConfirm from "@/commons/components/form/LayerModalFormConfirm.vue";

import { Debouncer } from "@/commons/libs/utils/debouncer";
import {
  APIS_STATUSES,
  MODAL_PREFIX,
  SUBSCRIPTION_STATUSES,
  mergeSearchAndSelectedValues,
} from "@/commons/utils/filter-utils";
import { valuesToMCheckboxGroupOptions } from "@/commons/utils/mozaic-utils";

import { MCheckboxGroupOption } from "@/commons/domain/models/mozaic";
import { Product } from "@/commons/domain/models/product";
import {
  FiltersMyAppsForm,
  FiltersMyAppsFormData,
} from "@/commons/forms/filters-my-apps-form";
import {
  getApisByIds,
  searchUsedApi,
} from "@/commons/services/api/api-service";
import {
  getProductsByIds,
  searchProduct,
} from "@/commons/services/product/product-service";

import contents from "@/dashboard/contents/modal-filters-my-apps";

export default {
  components: {
    LayerModalFormConfirm,
    MAutocomplete,
    MCheckboxGroup,
    DvpField,
  },
  props: {
    formData: {
      type: Object as PropType<FiltersMyAppsFormData>,
      required: true,
    },
    onlySubscribed: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["submitted", "onClose"],
  data() {
    return {
      form: FiltersMyAppsForm.create(),
      contents,
      searchedProducts: [],
      selectedProducts: [],
      searchedUsedApis: [],
      selectedUsedApis: [],
      debouncer: new Debouncer(),
      isLoading: true,
      // Default value helps to avoid a "No results found" error on MAutocomplete loading
      defaultInputValue: "",
      noAnyUsedApis: true as Boolean,
    };
  },
  computed: {
    subscriptionStatuses(): MCheckboxGroupOption[] {
      return valuesToMCheckboxGroupOptions(SUBSCRIPTION_STATUSES, MODAL_PREFIX);
    },
    apisStatuses(): MCheckboxGroupOption[] {
      return valuesToMCheckboxGroupOptions(APIS_STATUSES, MODAL_PREFIX);
    },
    products(): Product[] {
      return mergeSearchAndSelectedValues(
        this.searchedProducts,
        this.selectedProducts,
      );
    },
    selectedProductIds: {
      get() {
        return this.form.productsIds;
      },
      async set(productIds: string[]) {
        this.form.productsIds = productIds;
        await this.updateSelectedProducts();
      },
    },
    selectedUsedApisIds: {
      get() {
        return this.form.usedApisIds;
      },
      async set(usedApisIds: string[]) {
        this.form.usedApisIds = usedApisIds;
        await this.updateSelectedUsedApis();
      },
    },
    usedApis() {
      return mergeSearchAndSelectedValues(
        this.searchedUsedApis,
        this.selectedUsedApis,
      );
    },
  },
  async mounted() {
    await this.form.init(this.formData);

    await this.searchProducts("");
    await this.searchUsedApis("");

    this.noAnyUsedApis = this.usedApis.length === 0;

    await this.updateSelectedProducts();
    await this.updateSelectedUsedApis();
    this.isLoading = false;
  },
  methods: {
    debouncedSearchProducts(searchValue: string): void {
      this.debouncer.debounce(() => this.searchProducts(searchValue), 100);
    },
    async searchProducts(name: string) {
      this.searchedProducts = await searchProduct(name);
    },
    async updateSelectedProducts() {
      this.selectedProducts = await getProductsByIds(this.form.productsIds);
    },
    debouncedSearchUsedApis(searchValue: string): void {
      this.debouncer.debounce(() => this.searchUsedApis(searchValue), 100);
    },
    async searchUsedApis(name: string) {
      this.searchedUsedApis = await searchUsedApi(name, this.onlySubscribed);
    },
    async updateSelectedUsedApis() {
      this.selectedUsedApis = await getApisByIds(
        this.form.usedApisIds,
        this.onlySubscribed,
      );
    },
    close() {
      this.$emit("onClose");
    },
    async submit() {
      this.$emit("submitted", this.form.data());
      this.$emit("onClose");
    },
  },
};
</script>
