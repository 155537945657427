import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "menu-item__inner" }
const _hoisted_2 = {
  key: 0,
  class: "menu-item__icon"
}
const _hoisted_3 = {
  key: 1,
  class: "menu-item__icon"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "menu-item__content" }
const _hoisted_6 = { class: "menu-item__label" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  key: 0,
  class: "menu-item__description"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MIcon = _resolveComponent("MIcon")!
  const _component_MenuLink = _resolveComponent("MenuLink")!

  return (_openBlock(), _createBlock(_component_MenuLink, {
    urlOrRoute: $props.urlOrRoute,
    isExternalLink: $props.isExternalLink,
    class: _normalizeClass([
      'menu-item',
      {
        'menu-item--with-description': !!$props.description,
        'menu-item--color-white': $props.colorWhite,
        'menu-item--without-icon': $options.withoutIcon,
        'menu-item--with-border-bottom': $props.withBottomBorder,
      },
    ]),
    target: $props.openInNewWindow ? '_blank' : '_self',
    onClick: $options.onClick
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (!!$props.iconName)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
              _createVNode(_component_MIcon, {
                name: $props.iconName,
                color: "var(--color-brand-minor)"
              }, null, 8, ["name"])
            ]))
          : ($props.iconLocalPath)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("img", { src: $props.iconLocalPath }, null, 8, _hoisted_4)
              ]))
            : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createTextVNode(_toDisplayString($props.label) + " ", 1),
            ($props.isExternalLink)
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                  _createVNode(_component_MIcon, { name: "DisplayExternalLink16" })
                ]))
              : _createCommentVNode("", true)
          ]),
          (!!$props.description && $props.isDisplayDescription)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString($props.description), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["urlOrRoute", "isExternalLink", "class", "target", "onClick"]))
}