import { EOauthFlow } from "@/commons/types/oauth-flow-types";

export default {
  title: "Which flows do you want to use?",
  description:
    "The API you want to subscribe to supports two Ping flows.\n" +
    "Which one do you want to use?",

  oauthFlowItemsOverride: {
    [EOauthFlow.CLIENT_CREDENTIALS]: {
      title: "Client Credentials Flow (Ping)",
    },
    [EOauthFlow.AUTHORIZATION_CODE]: {
      title: "Authorization Code Flow (Ping)",
    },
  },

  stepperDescription: "Select an OAUTH flow",
  nextBtnLabel: "Next",
};
