import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-ab6dd8f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-input" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["for"]
const _hoisted_4 = { class: "text-input__wrapper" }
const _hoisted_5 = { class: "input-container" }
const _hoisted_6 = ["id", "type", "value", "placeholder", "disabled", "min", "max"]
const _hoisted_7 = {
  key: 0,
  class: "arrow"
}
const _hoisted_8 = {
  key: 1,
  class: "text-input__absolute-icon-search"
}
const _hoisted_9 = {
  key: 2,
  class: "text-input__error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconChevronTop = _resolveComponent("IconChevronTop")!
  const _component_IconChevronBottom = _resolveComponent("IconChevronBottom")!
  const _component_IconEyeClose = _resolveComponent("IconEyeClose")!
  const _component_IconEyeOpen = _resolveComponent("IconEyeOpen")!
  const _component_IconSearch = _resolveComponent("IconSearch")!
  const _component_Adornment = _resolveComponent("Adornment")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: $options.computedId
        }, _toDisplayString($props.label), 9, _hoisted_2))
      : (_ctx.$slots.default)
        ? (_openBlock(), _createElementBlock("label", {
            key: 1,
            for: $options.computedId
          }, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ], 8, _hoisted_3))
        : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_Adornment, null, _createSlots({
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("input", {
              id: $options.computedId,
              ref: $props.reference,
              "data-cy": "text-input",
              autocomplete: "off",
              class: _normalizeClass([
              $props.prefix && 'text-input__with-prefix',
              $props.suffix && 'text-input__with-suffix',
              $props.error && 'text-input__with-error',
              $props.type == $data.ETextInputType.NUMBER && 'text-input__with-suffix',
            ]),
              type: $props.type == $data.ETextInputType.PASSWORD ? $data.typePassword : $props.type,
              value: $props.modelValue,
              placeholder: $props.placeholder,
              disabled: $props.isDisabled,
              min: $props.min,
              max: $props.max,
              onInput: _cache[0] || (_cache[0] = ($event: any) => ($options.onInput($event.target.value))),
              onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.$emit('enter')), ["enter"]))
            }, null, 42, _hoisted_6),
            ($props.type == $data.ETextInputType.NUMBER && !$props.isDisabled)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("div", {
                    class: "arrow-icon-top",
                    "data-cy": "text-input-up-arrow",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (
                $props.modelValue < $props.max && _ctx.$emit('update:modelValue', $props.modelValue + 1)
              ))
                  }, [
                    _createVNode(_component_IconChevronTop)
                  ]),
                  _createElementVNode("div", {
                    class: "arrow-icon-bottom",
                    "data-cy": "text-input-down-arrow",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (
                $props.modelValue > $props.min && _ctx.$emit('update:modelValue', $props.modelValue - 1)
              ))
                  }, [
                    _createVNode(_component_IconChevronBottom)
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: _normalizeClass([
              'text-input__absolute-icon-eyes',
              $data.typePassword == $data.ETextInputType.PASSWORD
                ? 'icon-eye-close'
                : 'icon-eye-open',
            ])
            }, [
              (
                $props.type == $data.ETextInputType.PASSWORD &&
                $data.typePassword == $data.ETextInputType.PASSWORD
              )
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    onClick: _cache[4] || (_cache[4] = ($event: any) => ($options.transformType($data.ETextInputType.PASSWORD)))
                  }, [
                    _createVNode(_component_IconEyeClose)
                  ]))
                : (
                $props.type == $data.ETextInputType.PASSWORD &&
                $data.typePassword == $data.ETextInputType.TEXT
              )
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      onClick: _cache[5] || (_cache[5] = ($event: any) => ($options.transformType($data.ETextInputType.TEXT)))
                    }, [
                      _createVNode(_component_IconEyeOpen)
                    ]))
                  : _createCommentVNode("", true)
            ], 2),
            ($props.search)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createVNode(_component_IconSearch)
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 2
      }, [
        ($props.prefix)
          ? {
              name: "prefix",
              fn: _withCtx(() => [
                _renderSlot(_ctx.$slots, "textInputPrefix", {}, undefined, true)
              ]),
              key: "0"
            }
          : undefined,
        ($props.suffix)
          ? {
              name: "suffix",
              fn: _withCtx(() => [
                _renderSlot(_ctx.$slots, "textInputSuffix", {}, undefined, true)
              ]),
              key: "1"
            }
          : undefined
      ]), 1024)
    ]),
    ($props.error)
      ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString($props.error), 1))
      : _createCommentVNode("", true)
  ]))
}