import { HttpRepository } from "@/commons/repositories/libs/http-repository";

import { Injectable } from "@/commons/domain/di/injectable";
import { UserRepository } from "@/commons/domain/repositories/user-repository";
import { PagedResourceDto } from "@/commons/dtos/paged-resource-dto";
import { CurrentUserDto, UserDto } from "@/commons/dtos/user-dto";
import { PageMapper } from "@/commons/mappers/page-mapper";
import { UserMapper } from "@/commons/mappers/user-mapper";

@Injectable()
export class UserHttpRepository
  extends HttpRepository
  implements UserRepository
{
  public async findMe() {
    const response = await this.requestHandler.get<CurrentUserDto>(`/user`);
    return UserMapper.toCurrentUserDomain(response.data);
  }

  public async findUsersBySearch(search: string, activeOnly: boolean = false) {
    const response = await this.requestHandler.cancelAndGet<
      PagedResourceDto<UserDto>
    >(`/users`, { params: { query: search, activeOnly: activeOnly } });

    return PageMapper.toPagedResourceWithDataAsList(
      response.data,
      UserMapper.toUserDomain,
    );
  }

  public async logout() {
    await this.requestHandler.post(`/auth/logout`);
  }

  public async acceptTOS() {
    await this.requestHandler.post(`/terms-of-service/_accept`);
    return this.findMe();
  }

  public async delete() {
    await this.requestHandler.delete(`/user`);
  }
}
