<template>
  <div class="text-input">
    <label v-if="label" :for="computedId">{{ label }}</label>
    <label v-else-if="$slots.default" :for="computedId">
      <slot />
    </label>
    <div class="text-input__wrapper">
      <Adornment>
        <template v-if="prefix" #prefix>
          <slot name="textInputPrefix" />
        </template>
        <div class="input-container">
          <input
            :id="computedId"
            :ref="reference"
            data-cy="text-input"
            autocomplete="off"
            :class="[
              prefix && 'text-input__with-prefix',
              suffix && 'text-input__with-suffix',
              error && 'text-input__with-error',
              type == ETextInputType.NUMBER && 'text-input__with-suffix',
            ]"
            :type="type == ETextInputType.PASSWORD ? typePassword : type"
            :value="modelValue"
            :placeholder="placeholder"
            :disabled="isDisabled"
            :min="min"
            :max="max"
            @input="onInput($event.target.value)"
            @keyup.enter="$emit('enter')"
          />
          <div
            v-if="type == ETextInputType.NUMBER && !isDisabled"
            class="arrow"
          >
            <div
              class="arrow-icon-top"
              data-cy="text-input-up-arrow"
              @click="
                modelValue < max && $emit('update:modelValue', modelValue + 1)
              "
            >
              <IconChevronTop />
            </div>
            <div
              class="arrow-icon-bottom"
              data-cy="text-input-down-arrow"
              @click="
                modelValue > min && $emit('update:modelValue', modelValue - 1)
              "
            >
              <IconChevronBottom />
            </div>
          </div>
          <div
            :class="[
              'text-input__absolute-icon-eyes',
              typePassword == ETextInputType.PASSWORD
                ? 'icon-eye-close'
                : 'icon-eye-open',
            ]"
          >
            <!-- TODO icon eye and icon /eye -->
            <div
              v-if="
                type == ETextInputType.PASSWORD &&
                typePassword == ETextInputType.PASSWORD
              "
              @click="transformType(ETextInputType.PASSWORD)"
            >
              <IconEyeClose />
            </div>
            <div
              v-else-if="
                type == ETextInputType.PASSWORD &&
                typePassword == ETextInputType.TEXT
              "
              @click="transformType(ETextInputType.TEXT)"
            >
              <IconEyeOpen />
            </div>
          </div>
          <div v-if="search" class="text-input__absolute-icon-search">
            <IconSearch />
          </div>
        </div>

        <template v-if="suffix" #suffix>
          <slot name="textInputSuffix" />
        </template>
      </Adornment>
    </div>
    <span v-if="error" class="text-input__error">{{ error }}</span>
  </div>
</template>

<script lang="ts">
import IconChevronBottom from "@/commons/components/Icons/IconChevronBottom.vue";
import IconChevronTop from "@/commons/components/Icons/IconChevronTop.vue";
import IconEyeClose from "@/commons/components/Icons/IconEyeClose.vue";
import IconEyeOpen from "@/commons/components/Icons/IconEyeOpen.vue";
import IconSearch from "@/commons/components/Icons/IconSearch.vue";

import Adornment from "./Adornment.vue";

import { Debouncer } from "@/commons/libs/utils/debouncer";

import { ETextInputType } from "@/commons/store/types";

export default {
  name: "TextInput",
  components: {
    Adornment,
    IconChevronTop,
    IconChevronBottom,
    IconEyeOpen,
    IconEyeClose,
    IconSearch,
  },
  props: {
    error: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    modelValue: {
      type: [String, Number],
      default: null,
    },
    prefix: {
      type: Boolean,
      default: false,
    },
    reference: {
      type: String,
      default: null,
    },
    suffix: {
      type: Boolean,
      default: false,
    },
    search: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: ETextInputType.TEXT,
      validator: (value) => {
        return Object.values(ETextInputType).includes(value);
      },
    },
    min: {
      type: Number,
      default: Number.MIN_SAFE_INTEGER,
    },
    max: {
      type: Number,
      default: Number.MAX_SAFE_INTEGER,
    },
    debounce: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "enter"],
  data() {
    return {
      typePassword: this.type,
      ETextInputType,
      debouncer: new Debouncer(),
    };
  },
  computed: {
    computedId() {
      return this.id ?? `text-input-id-${this.$.uid}`;
    },
    frontendConfig() {
      return this.$store.getters["config/frontendConfig"];
    },
    debounceInterval() {
      return this.frontendConfig.debounceInterval || 500;
    },
  },
  methods: {
    transformType(type) {
      if (type === ETextInputType.TEXT) {
        this.typePassword = ETextInputType.PASSWORD;
      } else if (type === ETextInputType.PASSWORD) {
        this.typePassword = ETextInputType.TEXT;
      }
    },
    onInput(value) {
      const emitEvent = () => {
        this.$emit(
          "update:modelValue",
          this.type === ETextInputType.NUMBER && value ? Number(value) : value,
        );
      };
      if (this.debounce) {
        this.debouncer.debounce(emitEvent, this.debounceInterval);
      } else {
        emitEvent();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.text-input {
  width: 100%;

  label {
    @include set-text-s;
    margin-bottom: 12px;
    color: var(--color-text);
  }

  input {
    box-sizing: inherit;
    display: block;
    width: 100%;
    padding: 10px 20px;
    font-size: var(--text-s);
    color: var(--color-text);
    background-color: var(--color-white);
    border: var(--border-default);
    border-radius: 3px;
  }
  input:focus {
    color: var(--color-text);
    outline: none;
  }
  input:disabled {
    background-color: var(--color-background-interface);
  }
  input.text-input__with-error {
    border-color: var(--color-danger);
  }
  .input-container {
    position: relative;
    display: flex;
    width: 100%;
  }
  .arrow {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    user-select: none;
  }

  .arrow-icon-top,
  .arrow-icon-bottom {
    display: flex;
    align-items: center;
    height: 50%;
    border-top: var(--border-default);
    border-right: var(--border-default);
  }
  .arrow-icon-bottom {
    border-bottom: var(--border-default);
  }
  .arrow-icon-top:hover,
  .arrow-icon-bottom:hover {
    background-color: var(--color-background-interface);
  }

  svg {
    width: 25px;
    padding: 0 0.4rem;
  }

  .text-input__with-prefix {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .text-input__with-suffix {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &__error {
    color: var(--color-danger);
  }
}

.text-input__wrapper {
  display: flex;
  width: 100%;
}

.text-input__prefix {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  color: var(--color-text);
  background-color: var(--color-primary);
  border: var(--border-default);
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

// type=number
input[type="number"] {
  appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  appearance: none;
}

.text-input__absolute-icon-eyes,
.text-input__absolute-icon-search {
  position: absolute;
  right: 10px;

  svg {
    padding: 0;
  }
}
.text-input__absolute-icon-eyes svg {
  width: 25px;
  cursor: pointer;
}
.text-input__absolute-icon-search svg {
  width: 18px;
}

.icon-eye-close {
  top: 10px;
}
.icon-eye-open {
  top: 9px;
}

.text-input__absolute-icon-search {
  top: 11px;
}
</style>
