export enum ECustomProxyHeaderType {
  REQUEST = "REQUEST",
  RESPONSE = "RESPONSE",
}

export interface CustomProxyHeader {
  id: string;
  name: string;
  value: string;
  isOverridingClientHeader: boolean;
  order?: number;
  type: ECustomProxyHeaderType;
}
