import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "editable-route-url" }
const _hoisted_2 = {
  key: 0,
  class: "editable-route-url__labeling"
}
const _hoisted_3 = { class: "editable-route-url__url" }
const _hoisted_4 = {
  key: 1,
  class: "editable-route-url__editing"
}
const _hoisted_5 = { class: "editable-route-url__input-with-error" }
const _hoisted_6 = {
  key: 0,
  class: "input-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonCopy = _resolveComponent("ButtonCopy")!
  const _component_ButtonEdit = _resolveComponent("ButtonEdit")!
  const _component_MTextInput = _resolveComponent("MTextInput")!
  const _component_CancelAndSubmitButtons = _resolveComponent("CancelAndSubmitButtons")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!$data.isEditing)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString($props.route.url), 1),
          _createVNode(_component_ButtonCopy, {
            content: $props.route.url
          }, null, 8, ["content"]),
          ($props.canEdit)
            ? (_openBlock(), _createBlock(_component_ButtonEdit, {
                key: 0,
                onClick: $options.editUrl
              }, null, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_MTextInput, {
              modelValue: $data.urlForm.url,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.urlForm.url) = $event)),
              isInvalid: $data.urlForm.firstError('url') != undefined
            }, null, 8, ["modelValue", "isInvalid"]),
            ($data.urlForm.firstError('url') != undefined)
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString($data.urlForm.firstError("url")), 1))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_CancelAndSubmitButtons, {
            class: "editable-route-url__cancel-and-submit-buttons",
            onCancel: $options.cancelEdition,
            onSubmit: $options.updateUrl
          }, null, 8, ["onCancel", "onSubmit"])
        ]))
  ]))
}