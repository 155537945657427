import { IsNotEmpty, MaxLength, MinLength } from "class-validator";

import { FormData } from "@/commons/domain/forms/form-data";
import { FormFactory } from "@/commons/domain/forms/form-factory";

export class ApiUpdateForm extends FormData {
  @MinLength(50, {
    message:
      "This field is too short. Minimal length is $constraint1 characters",
  })
  @MaxLength(1000, {
    message:
      "This field is too long. Maximal length is $constraint1 characters",
  })
  description: string;

  @IsNotEmpty({
    message: "You must specify a product",
  })
  productId: string;

  $clear() {
    for (const key of ["description", "productId"]) {
      this[key] = "";
    }
  }

  static create() {
    return FormFactory.createForm(new ApiUpdateForm());
  }
}
