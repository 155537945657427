<template>
  <div class="home-try-it">
    <div class="home-try-it__header">
      <!--eslint-disable-next-line vue/no-v-html-->
      <h1 class="header-title" v-html="contents.title" />
      <p class="header-description">{{ contents.description }}</p>
    </div>
    <div class="home-try-it__content">
      <div class="home-try-it__code-example">
        <Editor :value="contents.codeExample" />
        <div class="home-try-it__buttons home-try-it--responsive-desktop">
          <MButton
            theme="solid-neutral"
            :label="contents.helpButtonLabel"
            :href="supportUrl"
          />
          <MButton
            :label="contents.startButtonLabel"
            @click="() => $router.push({ name: 'dashboard' })"
          />
        </div>
      </div>
      <div class="home-try-it__steps">
        <div
          v-for="(step, index) in contents.steps"
          :key="`home-try-it-step-${index}`"
          class="home-try-it__step"
        >
          <div class="home-try-it__step-number">{{ index + 1 }}</div>
          <div>
            <div class="home-try-it__step-image">
              <img :src="step.image" class="home-try-it__img" />
            </div>
            <h3 class="home-try-it__step-title">{{ step.title }}</h3>
            <p class="home-try-it__step-description">{{ step.description }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="home-try-it__buttons home-try-it--responsive-tablet-landscape">
      <MButton
        theme="solid-neutral"
        :label="contents.helpButtonLabel"
        :href="supportUrl"
      />
      <MButton
        :label="contents.startButtonLabel"
        @click="() => $router.push({ name: 'dashboard' })"
      />
    </div>
  </div>
</template>

<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";

import Editor from "./Editor.vue";

import content from "@/showcase/contents/home-try-it";

export default {
  name: "HomeTryIt",
  components: {
    Editor,
    MButton,
  },
  computed: {
    supportUrl() {
      return this.$store.getters["config/supportUrl"];
    },
    locationSettings() {
      return this.$store.getters["config/locationSettings"];
    },
    contents() {
      return content[this.locationSettings.name];
    },
  },
};
</script>

<style lang="scss">
@import "~@/commons/assets/styles/vars";

.home-try-it {
  position: relative;

  display: flex;
  flex-direction: column;

  width: 91.4rem;
  padding: 4rem 5rem 3rem;
  background: var(--color-white);
  border-radius: 10px;
  box-shadow: var(--m-shadow-medium);
}

.home-try-it__img {
  max-width: 84px;
  height: auto;
}

.home-try-it a,
.home-try-it button {
  margin: 3.57rem 1.79rem 1.79rem 0;
}

.home-try-it__header {
  text-align: center;
}

.home-try-it__content {
  display: flex;

  margin-top: 4rem;
}

.home-try-it__code-example {
  display: flex;
  flex: 0.6;
  flex-direction: column;
  margin: 0 2rem 1rem 0;
}

.home-try-it__steps {
  flex: 0.4;
  margin: 0 0 0 2rem;
}

.home-try-it__step {
  display: flex;
  align-items: center;

  margin-bottom: 2rem;
}

.home-try-it__step-number {
  width: 3rem;
  margin-right: 2rem;

  font-size: 4.6rem;
  font-weight: 700;
  line-height: 3.4rem;

  color: var(--color-leroy-merlin);
}

.home-try-it__step-image {
  display: flex;
}

.home-try-it__step-title {
  font-size: 1.43rem;
  font-weight: 700;
  line-height: 2rem;
}

.home-try-it__step-description {
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 2rem;
}

.home-try-it__buttons {
  text-align: center;
}

.home-try-it--responsive-desktop {
  display: flex;
  justify-content: center;
}

.home-try-it--responsive-tablet-landscape {
  display: none;
}

@media only screen and (max-width: map-get($breakpoints, desktop-small)) {
  .home-try-it {
    width: 92%;
    padding: 5rem 3rem 6rem;
  }
}

@media only screen and (max-width: map-get($breakpoints, tablet-landscape)) {
  .home-try-it {
    width: 95%;
    padding: 2rem 3rem 1rem;
  }

  .home-try-it__steps {
    flex: 0.45;
  }

  .home-try-it__code-example {
    flex: 0.55;
    margin: 0 2rem 0 0;
  }

  .home-try-it__content {
    margin-top: 2rem;
  }

  .home-try-it__header h1.header-title,
  .home-try-it__header h1.header-title strong {
    margin: 0 0 1rem;
    font-size: 2.3rem;
    line-height: 3rem;
  }

  .home-try-it__step {
    display: flex;
    align-items: center;

    margin-bottom: 2rem;
  }

  .home-try-it__step-number {
    font-size: 3rem;
    line-height: 3.4rem;
  }

  .home-try-it__step-title {
    font-size: 1.2rem;
    line-height: 2rem;
  }

  .home-try-it__step-description {
    font-size: 1rem;
  }
}

@media only screen and (max-width: map-get($breakpoints, tablet-portrait)) {
  .home-try-it {
    width: 94%;
    padding: 3rem 2.5rem;
    margin: 2rem 0 0;
  }

  .home-try-it__content {
    flex-direction: column;
  }

  .home-try-it__steps {
    display: flex;
    flex-direction: row;
    order: 1;
    margin: 0;
  }

  .home-try-it__step {
    flex-direction: column;
    padding: 0 2rem;
    text-align: center;
  }

  .home-try-it__step-number {
    margin: 0;
  }

  .home-try-it__step-image {
    justify-content: center;
  }

  .home-try-it__code-example {
    order: 2;
    margin: 0;
  }
}

@media only screen and (max-width: map-get($breakpoints, mobile)) {
  #home-try-it {
    background: var(--color-white);
  }

  .home-try-it {
    width: 85%;
    padding: 0;
    margin: 2rem 0 5rem;
    background: transparent;
    border-radius: 0;
    box-shadow: none;
  }

  .home-try-it__content {
    flex-direction: column;
  }

  .home-try-it__steps {
    flex-direction: column;
    order: 1;
    margin: 0 0 3rem;
    background: var(--color-white);
    border-radius: 6px;
    box-shadow: var(--m-shadow-medium);
  }

  .home-try-it__step {
    flex-direction: column;
    padding: 0 1rem;
    text-align: center;
  }

  .home-try-it__code-example {
    order: 2;
    margin: 0;
  }

  .home-try-it .button {
    display: none; // until we have a working interface subscription on mobile
    margin: 2rem 0 0;
  }
}
</style>
