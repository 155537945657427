<script setup lang="ts">
import MNotification from "@mozaic-ds/vue-3/src/components/notification/MNotification.vue";
import { useStore } from "vuex";

import LayerModalFormConfirm from "@/commons/components/form/LayerModalFormConfirm.vue";
import Markdown from "@/documentation/components/commons/Markdown.vue";

import {
  ENotificationToastType,
  NotificationToast,
} from "@/commons/store/modules/notification/types";

import contents from "@/dashboard/contents/contract-rotate-token-modal";

const props = defineProps<{
  applicationId: String;
  contractId: String;
  tokenId: String;
  secretManager: String;
  secretSyncWillOccurLater: Boolean;
}>();

const emit = defineEmits(["submitted", "onClose"]);

const store = useStore();

const close = () => {
  emit("onClose");
};

const submit = async () => {
  await store.dispatch("rotateToken", {
    appId: props.applicationId,
    contractId: props.contractId,
    tokenId: props.tokenId,
    toastNotification: getRotateToastNotification(),
  });
  emit("submitted");
  close();
};

const getRotateToastNotification = (): NotificationToast => {
  if (props.secretSyncWillOccurLater) {
    return {
      title: contents.toastNotification.title,
      type: ENotificationToastType.WARNING,
      message: contents.toastNotification.warningContent(props.secretManager),
    };
  }
  return {
    title: contents.toastNotification.title,
    type: ENotificationToastType.SUCCESS,
  };
};
</script>

<template>
  <LayerModalFormConfirm
    class="contract-rotate-token-modal"
    confirmBtnTheme="solid"
    @submit="submit"
    @close="close"
  >
    <div class="contract-rotate-token-modal__content">
      <h5>{{ contents.title }}</h5>
      <Markdown :content="contents.message" />
      <MNotification :title="contents.warningMessageTitle" type="warning">
        {{ contents.warningMessage }}</MNotification
      >
    </div>
  </LayerModalFormConfirm>
</template>

<style lang="scss">
.contract-rotate-token-modal__content {
  display: flex;
  flex-direction: column;
  gap: var(--base-spacing);
}
</style>
