import { DATE_ALIAS } from "@/commons/store/modules/reporting/constants";
import moment from "moment";

export function getDateValue(dateOrAlias: Date | string): Date {
  let dateValue = null;

  if (dateOrAlias === DATE_ALIAS.NOW) {
    dateValue = new Date();
  } else if (dateOrAlias === DATE_ALIAS.ONE_HOUR_AGO) {
    dateValue = moment().subtract(1, "hour").toDate();
  } else {
    dateValue = dateOrAlias;
  }

  return dateValue;
}
