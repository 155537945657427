import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-ec02f518"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "layout-content-header" }
const _hoisted_2 = { class: "layout-content-header__content" }
const _hoisted_3 = {
  key: 0,
  class: "layout-content-header__back-arrow"
}
const _hoisted_4 = { class: "layout-content-header__title" }
const _hoisted_5 = { "data-cy": "header-title" }
const _hoisted_6 = {
  key: 0,
  class: "layout-content-header__description"
}
const _hoisted_7 = { class: "layout-content-header__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MIcon = _resolveComponent("MIcon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      ($props.previousPageRoute)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_router_link, {
              "data-cy": "navigate-before-button",
              to: $props.previousPageRoute
            }, {
              default: _withCtx(() => [
                _createVNode(_component_MIcon, { name: "ArrowBack24" })
              ]),
              _: 1
            }, 8, ["to"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h4", _hoisted_5, _toDisplayString($props.title), 1),
        _renderSlot(_ctx.$slots, "default", {}, undefined, true),
        ($props.description)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString($props.description), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _renderSlot(_ctx.$slots, "actions", {}, undefined, true)
    ])
  ]))
}