import { FormData } from "./form-data";

import { ValidationError } from "@/commons/domain/validation/validation-error";
import { Validator } from "@/commons/domain/validation/validator";

export class Form<T extends FormData> {
  _formData: T;
  _errors: ValidationError[];
  _touched: Set<string>;

  constructor(
    private readonly validator: Validator,
    formData: T,
  ) {
    this._formData = formData;
    this._errors = [];
    this._touched = new Set();
  }

  get pristine() {
    return this._touched.size === 0;
  }

  get errors() {
    return this._errors;
  }

  async update(partial: Object) {
    this._formData.$update(partial);

    for (const dataKey in partial) {
      this._touched.add(dataKey);
    }

    this._errors = await this.validator.validate(this._formData);
  }

  async clear() {
    this._formData.$clear();
    this._errors = await this.validator.validate(this._formData);
    this._touched.clear();
  }

  async clearGroupOfFields(groupOfFields: string) {
    this._formData.$clear(groupOfFields);
  }

  async init(partial?: Object) {
    this._formData.$clear();
    this._formData.$update(partial);
    this._errors = await this.validator.validate(this._formData);
    this._touched.clear();
  }

  async validate() {
    this._errors = await this.validator.validate(this._formData);
    this._touched.clear();

    for (const error of this._errors) {
      this._touched.add(error.property);
    }
  }

  getErrors(
    dataKey: string,
    shouldGetErrorsIfUntouched: boolean = false,
  ): string[] {
    const error = this._errors.find(
      (error) =>
        error.property === dataKey &&
        (shouldGetErrorsIfUntouched || this._touched.has(dataKey)),
    );

    return (error && error.messages) || [];
  }

  firstError(
    dataKey: string,
    shouldGetErrorsIfUntouched: boolean = false,
  ): string | null {
    return this.getErrors(dataKey, shouldGetErrorsIfUntouched)[0] || null;
  }

  hasError(
    dataKey: string,
    shouldGetErrorsIfUntouched: boolean = false,
  ): boolean {
    return this.getErrors(dataKey, shouldGetErrorsIfUntouched).length > 0;
  }

  defaultError(dataKey: string) {
    const error = this._errors.find((error) => error.property === dataKey);

    return (error && error.messages[0]) || null;
  }

  data() {
    return this._formData.$data();
  }
}
