import { HttpRepository } from "@/commons/repositories/libs/http-repository";

import { Injectable } from "@/commons/domain/di/injectable";
import {
  TagFetchParams,
  TagRepository,
} from "@/commons/domain/repositories/tag-repository";
import { PagedResourceDto } from "@/commons/dtos/paged-resource-dto";
import { TagDto } from "@/commons/dtos/tag-dto";
import { TagsMapper } from "@/commons/mappers/tags-mapper";

@Injectable()
export class TagHttpRepository extends HttpRepository implements TagRepository {
  public async find(params: TagFetchParams = {}) {
    const response = await this.requestHandler.get<PagedResourceDto<TagDto>>(
      `/tags`,
      { params },
    );
    return TagsMapper.toDomain(response.data);
  }
}
