import { HttpRepository } from "@/commons/repositories/libs/http-repository";
import { HEADER_CONTENT_TYPE_TEXT } from "@/commons/request-handlers/request-handler-utils";

import { Injectable } from "@/commons/domain/di/injectable";
import { IPRange } from "@/commons/domain/models/ip-range";
import {
  ApplicationFetchParams,
  ApplicationRepository,
} from "@/commons/domain/repositories/application-repository";
import { ApplicationDto } from "@/commons/dtos/application-dto";
import { ApplicationRedirectUriDto } from "@/commons/dtos/application-redirect-uri-dto";
import { CreatedResourceDto } from "@/commons/dtos/created-resource-dto";
import { OAuthTokenDto } from "@/commons/dtos/oauth-token-dto";
import { PagedResourceDto } from "@/commons/dtos/paged-resource-dto";
import { SecretSynchronizationRequestDto } from "@/commons/dtos/secret-synchronization-request-dto";
import { ValidationDTO } from "@/commons/dtos/validation-dto";
import { ApplicationMapper } from "@/commons/mappers/application-mapper";

import {
  FetchApiReportingResponse,
  FetchApplicationReportingParams,
  FetchFilterOptionsParams,
} from "@/commons/store/modules/reporting/types";

@Injectable()
export class ApplicationHttpRepository
  extends HttpRepository
  implements ApplicationRepository
{
  public async findById(appId: string) {
    const response = await this.requestHandler.get<ApplicationDto>(
      `/applications/${appId}`,
    );
    return ApplicationMapper.toApplicationDomain(response.data);
  }

  public async find(params: ApplicationFetchParams) {
    const response = await this.requestHandler.get<
      PagedResourceDto<ApplicationDto>
    >(`/applications`, { params });

    return response.data;
  }

  public async createApplication(
    name: string,
    type: "IOS" | "ANDROID" | "WEB" | "APPLICATION_SERVER",
    description: string,
    productId: string,
    forTesting: boolean,
    groupId: string,
  ) {
    const createdResource = await this.requestHandler.post<CreatedResourceDto>(
      `/applications`,
      ApplicationMapper.toCreateApplicationDto(
        name,
        type,
        description,
        productId,
        forTesting,
        groupId,
      ),
    );

    return this.findById(createdResource.data.id);
  }

  public async createGroupAcl(appId: string, groupId: string, roleId: string) {
    await this.requestHandler.post(`/applications/${appId}/acl/groups`, {
      id: groupId,
      roleId,
    });

    return this.findById(appId);
  }

  public async updateApplication(
    appId: string,
    name: string,
    type: "IOS" | "ANDROID" | "WEB" | "APPLICATION_SERVER",
    description: string,
    productId: string,
    forTesting: boolean,
  ) {
    await this.requestHandler.patch(
      `/applications/${appId}`,
      ApplicationMapper.toUpdateApplicationDto(
        name,
        type,
        description,
        productId,
        forTesting,
      ),
    );
    return this.findById(appId);
  }

  public async updateGroupAcl(appId: string, groupId: string, roleId: string) {
    await this.requestHandler.put(
      `/applications/${appId}/acl/groups/${groupId}`,
      {
        roleId,
      },
    );
    return this.findById(appId);
  }

  public async removeApplication(id: string) {
    await this.requestHandler.delete(`/applications/${id}`);
    return this.find({ page: 1 });
  }

  public async removeGroupAcl(appId: string, groupId: string) {
    await this.requestHandler.delete(
      `/applications/${appId}/acl/groups/${groupId}`,
    );
    return this.findById(appId);
  }

  public async getApplicationReporting(
    appId: string,
    fetchApplicationReportingParams: FetchApplicationReportingParams,
  ) {
    const response = await this.requestHandler.get<FetchApiReportingResponse>(
      `/applications/${appId}/reporting`,
      {
        params: fetchApplicationReportingParams,
      },
    );
    return response.data;
  }

  public async getApplicationReportingFiltersOptions(
    appId: string,
    fetchFilterOptionsParams: FetchFilterOptionsParams,
  ) {
    const response = await this.requestHandler.get<FetchFilterOptionsParams>(
      `/applications/${appId}/reporting`,
      {
        params: fetchFilterOptionsParams,
      },
    );
    return response.data;
  }

  public async validateRedirectUri(redirectUri: string, oauthProvider: string) {
    const cancellationId = "validateRedirectUri";
    this.requestHandler.cancelRequest(cancellationId);
    const response = await this.requestHandler.post<ValidationDTO>(
      `/applications/_validateRedirectUri`,
      redirectUri,
      {
        headers: { ...HEADER_CONTENT_TYPE_TEXT },
        cancellationId,
        params: { oauthProvider },
      },
    );
    return response.data;
  }

  public async getRedirectUris(applicationId: string) {
    const response = await this.requestHandler.get<ApplicationRedirectUriDto>(
      `/applications/${applicationId}/redirectUris`,
    );
    return response.data;
  }

  public async updateRedirectUris(
    applicationId: string,
    redirectUris: string[],
  ) {
    await this.requestHandler.put<boolean>(
      `/applications/${applicationId}/redirectUris`,
      redirectUris,
      { params: { applicationId } },
    );
  }

  public async getAcfToken(applicationId: string) {
    const response = await this.requestHandler.get<OAuthTokenDto>(
      `/applications/${applicationId}/acfToken`,
    );
    return response.data;
  }

  public async deleteAcfContractsOfApplication(applicationId: string) {
    await this.requestHandler.delete(
      `/applications/${applicationId}/acfContracts`,
    );
  }

  public async addIPRange(applicationId: string, ipRange: IPRange) {
    await this.requestHandler.post(
      `/applications/${applicationId}/ipRanges`,
      ipRange,
    );
  }

  public async updateIPRange(applicationId: string, ipRange: IPRange) {
    await this.requestHandler.put(
      `/applications/${applicationId}/ipRanges/${ipRange.id}`,
      ipRange,
    );
  }

  public async deleteIPRange(applicationId: string, ipRangeId: string) {
    await this.requestHandler.delete(
      `/applications/${applicationId}/ipRanges/${ipRangeId}`,
    );
  }

  public async validateIPRange(
    ipRange: IPRange,
    applicationId: string,
  ): Promise<ValidationDTO> {
    try {
      return (
        await this.requestHandler.post<ValidationDTO>(
          `/applications/${applicationId}/ipRanges/_check`,
          ipRange,
        )
      ).data;
    } catch (error) {
      return { valid: false, message: "Invalid IP address" };
    }
  }

  public async getSecretSynchronizationRequestDetails(
    applicationId: string,
    namespace: string,
  ): Promise<SecretSynchronizationRequestDto> {
    const response =
      await this.requestHandler.cancelAndPost<SecretSynchronizationRequestDto>(
        `/applications/${applicationId}/secret-synchronization/request-details`,
        { namespace },
        {},
        "getSecretSynchronizationRequestDetails",
      );
    return response.data;
  }

  public async enableSecretSynchronization(
    applicationId: string,
    namespace: string,
  ): Promise<string> {
    try {
      await this.requestHandler.post(
        `/applications/${applicationId}/secret-synchronization/_enable`,
        { namespace },
      );
    } catch (error) {
      if (error.message.startsWith("BAD_REQUEST: Error while synchronizing")) {
        return "DevPortal hasn't rights on this namespace";
      } else {
        throw error;
      }
    }
    return undefined;
  }

  public async disableSecretSynchronization(
    applicationId: string,
  ): Promise<boolean> {
    try {
      await this.requestHandler.post(
        `/applications/${applicationId}/secret-synchronization/_disable`,
      );
    } catch (error) {
      if (error.message.startsWith("BAD_REQUEST")) {
        return false;
      } else {
        throw error;
      }
    }
    return true;
  }

  public async forcedDisableSecretSynchronization(
    applicationId: string,
  ): Promise<void> {
    await this.requestHandler.post(
      `/applications/${applicationId}/secret-synchronization/_disable?cleanSecretStorage=false`,
    );
  }
}
