<template>
  <div class="toggle-field">
    <slot name="label" />
    <slot name="toggleButton" />
  </div>
</template>

<style lang="scss">
.toggle-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem;

  margin: 0;

  background-color: var(--color-background-secondary-light);
  border-radius: var(--m-border-radius-medium);
}
</style>
