<template>
  <LayerModalFormConfirm
    class="delete-modal-form"
    :disabledConfirmBtn="isError"
    @submit="submit"
    @close="$emit('onClose')"
  >
    <h4>
      {{ title }}
    </h4>
    <Markdown :content="content" />
    <DvpField
      class="delete-modal-form__confirm-message"
      :label="contents.confirmMessageLabel"
      required
    >
      <MTextInput
        v-model="confirmMessage"
        data-cy="delete-confirm-message"
        :placeholder="contents.confirmMessagePlaceholder"
        :isInvalid="isError"
      />
    </DvpField>
  </LayerModalFormConfirm>
</template>

<script setup lang="ts">
import MTextInput from "@mozaic-ds/vue-3/src/components/textinput/MTextInput.vue";
import { computed, ref } from "vue";

import DvpField from "@/commons/components/form/DvpField.vue";
import LayerModalFormConfirm from "@/commons/components/form/LayerModalFormConfirm.vue";
import Markdown from "@/documentation/components/commons/Markdown.vue";

import contents from "@/manager/contents/delete-blacklist-route-modal";

defineProps({
  title: {
    type: String,
    required: true,
  },
  content: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["onSubmit", "onClose"]);

const confirmMessage = ref("" as String);

const isError = computed((): boolean => {
  return confirmMessage.value !== "DELETE";
});

const submit = () => {
  emit("onSubmit");
  emit("onClose");
};
</script>

<style lang="scss">
.delete-modal-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
</style>
