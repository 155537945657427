import { Acl } from "@/commons/domain/models/acl";
import { AclGroup } from "@/commons/domain/models/acl-group";
import { AclUser } from "@/commons/domain/models/acl-user";
import { Dictionary } from "@/commons/domain/models/dictionary";
import { AclDto } from "@/commons/dtos/acl-dto";
import { AclGroupDto } from "@/commons/dtos/acl-group-dto";
import { AclUserDto } from "@/commons/dtos/acl-user-dto";
import { BulkUpdateAclDto } from "@/commons/dtos/bulk-update-acl-dto";

export class AclMapper {
  static toDomain(aclDto: AclDto): Acl {
    return {
      users: AclMapper.toUsersDomain(aclDto && aclDto.users),
      groups: AclMapper.toGroupsDomain(aclDto && aclDto.groups),
    };
  }

  static toUsersDomain(aclUserDtos: AclUserDto[] = []) {
    const userAcls: Dictionary<AclUser> = {};

    for (const aclUserDto of aclUserDtos) {
      userAcls[aclUserDto.id] = aclUserDto;
    }

    return userAcls;
  }
  static toGroupsDomain(aclGroupDtos: AclGroupDto[] = []) {
    const groupAcls: Dictionary<AclGroup> = {};

    for (const aclGroupDto of aclGroupDtos) {
      groupAcls[aclGroupDto.id] = { ...aclGroupDto, providerId: "group" };
    }

    return groupAcls;
  }
  static toBulkUpdateByAccessRestrictionDto(groupAcls: Dictionary<AclGroup>) {
    const bulkUpdateDto: BulkUpdateAclDto = {
      groups: {},
    };

    for (const groupACL of Object.values(groupAcls)) {
      bulkUpdateDto.groups[groupACL.id] = groupACL.role.id;
    }

    return bulkUpdateDto;
  }
}
