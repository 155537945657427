import { HttpRepository } from "@/commons/repositories/libs/http-repository";

import { Injectable } from "@/commons/domain/di/injectable";
import { ZoneRepository } from "@/commons/domain/repositories/zone-repository";
import { PagedResourceDto } from "@/commons/dtos/paged-resource-dto";
import { ZoneDTO } from "@/commons/dtos/zone-dto";
import { ZonesMapper } from "@/commons/mappers/zones-mapper";

export interface ApiZonesFetchParams {
  publishable?: boolean;
  deprecatable?: boolean;
}

@Injectable()
export class ZoneHttpRepository
  extends HttpRepository
  implements ZoneRepository
{
  public async findByApi(apiId: string, params: ApiZonesFetchParams) {
    const response = await this.requestHandler.get<ZoneDTO[]>(
      `/apis/${apiId}/zones`,
      {
        params,
      },
    );
    return ZonesMapper.toDomainCollection(response.data);
  }

  public async findAll() {
    const response = await this.requestHandler.get<PagedResourceDto<ZoneDTO>>(
      `/zones`,
      {
        params: {
          size: 100,
          includeRoutes: true,
        },
      },
    );
    return ZonesMapper.toDomain(response.data);
  }
}
