import { IsNotEmpty } from "class-validator";

import { FormData } from "@/commons/domain/forms/form-data";
import { FormFactory } from "@/commons/domain/forms/form-factory";
import { User } from "@/commons/domain/models/user";

import { ERole } from "@/commons/store/types";

export class AddUserAclForm extends FormData {
  @IsNotEmpty()
  user: User = undefined;

  role: ERole = undefined;

  $clear() {
    this.user = undefined;
    this.role = undefined;
  }

  static create() {
    return FormFactory.createForm(new AddUserAclForm());
  }
}
