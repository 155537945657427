export default {
  lmfr: {
    title: "<strong>Easy to use</strong> and <strong>flexible</strong>",
    description:
      "Discover APIs provided by Leroy Merlin and build disruptive applications",
    codeExample: `
            import http from 'axios';

            http.request({
              method: 'GET',
              url:'https://apis.fr.corp.leroymerlin.com/api-demo',
              headers: {
                'X-Gateway-APIKey': 'YOUR-API-KEY',
              },
            }).then( response => { console.log(response); });`,
    steps: [
      {
        image: require("@/commons/assets/images/step-icon-01.svg"),
        title: "Read the doc",
        description:
          "Fully documented APIs to help you getting the best of them",
      },
      {
        image: require("@/commons/assets/images/step-icon-02.svg"),
        title: "Get your API Key",
        description:
          "With a minimum clicks, you'll be able to consume our APIs",
      },
      {
        image: require("@/commons/assets/images/step-icon-03.svg"),
        title: "Build an amazing app",
        description: "Now, it's your turn to improve inhabitants experience",
      },
    ],
    helpButtonLabel: "Need help ?",
    startButtonLabel: "Start my App !",
  },
  lmbr: {
    title: "<strong>Easy to use</strong> and <strong>flexible</strong>",
    description:
      "Discover APIs provided by Leroy Merlin and build disruptive applications",
    codeExample: `
            import http from 'axios';

            http.request({
              method: 'GET',
              url:'https://ccdp.br.api.devportal.adeo.cloud/api-demo',
              headers: {
                'X-Gateway-APIKey': 'YOUR-API-KEY',
              },
            }).then( response => { console.log(response); });`,
    steps: [
      {
        image: require("@/commons/assets/images/step-icon-01.svg"),
        title: "Read the doc",
        description:
          "Fully documented APIs to help you getting the best of them",
      },
      {
        image: require("@/commons/assets/images/step-icon-02.svg"),
        title: "Get your API Key",
        description:
          "With a minimum clicks, you'll be able to consume our APIs",
      },
      {
        image: require("@/commons/assets/images/step-icon-03.svg"),
        title: "Build an amazing app",
        description: "Now, it's your turn to improve inhabitants experience",
      },
    ],
    helpButtonLabel: "Need help?",
    startButtonLabel: "Start my App!",
  },
  adeo: {
    title: "<strong>Easy to use</strong> and <strong>flexible</strong>",
    description:
      "Discover APIs provided by Adeo and build disruptive applications",
    codeExample: `
            import http from 'axios';

            http.request({
              method: 'GET',
              url:'https://apis.adeo.com/api-demo',
              headers: {
                'X-Gateway-APIKey': 'YOUR-API-KEY',
              },
            }).then( response => { console.log(response); });`,
    steps: [
      {
        image: require("@/commons/assets/images/step-icon-01-adeo.svg"),
        title: "Read the doc",
        description:
          "Fully documented APIs to help you getting the best of them",
      },
      {
        image: require("@/commons/assets/images/step-icon-02-adeo.svg"),
        title: "Get your API Key",
        description:
          "With a minimum clicks, you'll be able to consume our APIs",
      },
      {
        image: require("@/commons/assets/images/step-icon-03-adeo.svg"),
        title: "Build an amazing app",
        description: "Now, it's your turn to improve inhabitants experience",
      },
    ],
    helpButtonLabel: "Need help ?",
    startButtonLabel: "Start my App !",
  },
};
