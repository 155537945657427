<template>
  <section ref="subscribable-api-card-list" class="subscribable-api-card-list">
    <template v-if="isLoadingSubscribableApis">
      <ApiListItemLoadingSkeleton
        v-for="(item, index) in loadingItems"
        :key="`loading-subscribable-api-${index}`"
      />
    </template>
    <template v-else>
      <CardListed
        v-if="apisList.length === 0"
        class="subscribable-api-card-list__no-api-card disabled"
      >
        <div class="subscribable-api-card-list__no-api-card-container">
          <IconEmptyContent /><span>{{
            contents.emptySubscribableApisList
          }}</span>
        </div>
      </CardListed>

      <SubscribableApiCard v-for="api in apisList" :key="api.id" :api="api" />
    </template>
  </section>
</template>

<script lang="ts">
import { PropType } from "vue";

import { ApiListItemLoadingSkeleton } from "@/commons/components/ApiListItem";
import CardListed from "@/commons/components/CardListed/CardListed.vue";
import IconEmptyContent from "@/commons/components/Icons/IconEmptyContent.vue";
import SubscribableApiCard from "@/dashboard/views/AppDetailsContracts/SubscribableApiCard.vue";

import { Api } from "@/commons/domain/models/api";

import contents from "@/dashboard/contents/subscribable-apis-modal";

export default {
  components: {
    SubscribableApiCard,
    ApiListItemLoadingSkeleton,
    CardListed,
    IconEmptyContent,
  },
  props: {
    apisList: {
      type: Array as PropType<Api[]>,
      required: true,
    },
  },
  data() {
    return {
      contents,
    };
  },
  computed: {
    isLoadingSubscribableApis(): boolean {
      return this.$store.getters["isLoadingProperty"]("subscribableApis");
    },
    loadingItems(): any[] {
      return this.isLoadingSubscribableApis ? new Array(8) : [];
    },
  },
  watch: {
    isLoadingSubscribableApis() {
      // We reset the scroll to the top each time there is a data change on the listing so we're not scrolled half way on new data
      this.$refs["subscribable-api-card-list"].scrollTop = 0;
    },
  },
};
</script>

<style lang="scss">
.subscribable-api-card-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.subscribable-api-card-list__no-api-card-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}
</style>
