<template>
  <MStepper
    data-cy="stepper"
    class="dvp-stepper"
    :steps="steps"
    :accessibilityLabels="stepAccessibilityLabels"
    v-bind="$attrs"
  />
</template>

<script lang="ts">
import MStepper from "@mozaic-ds/vue-3/src/components/stepper/MStepper.vue";
import { PropType } from "vue";

import contents from "@/commons/contents/stepper";

export default {
  components: {
    MStepper,
  },
  props: {
    stepperDescription: {
      type: String,
      required: true,
    },
    steps: {
      type: Array as PropType<
        {
          label: string;
          path?: string;
          isCurrent?: boolean;
          onClick?: boolean; // if true, MStepper will emit an event on click
        }[]
      >,
      required: true,
    },
  },
  computed: {
    stepAccessibilityLabels() {
      return {
        stepperDescription: this.stepperDescription,
        validatedLabel: contents.stepValidatedLabel,
        currentLabel: contents.stepCurrentLabel,
        disabledLabel: contents.stepDisabledLabel,
      };
    },
  },
};
</script>
