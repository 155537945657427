<template>
  <div class="acls-manager">
    <div class="acls-manager__search">
      <h6>{{ contents.searchLabel }}</h6>
      <SearchGroup
        :suggestions="suggestions"
        :addGroupBtnLabel="contents.btnLabel"
        vertical
        @searchGroups="searchGroups"
        @addGroup="addGroup"
        @onSelect="onGroupSelection"
      />
      <NotificationWarningMCCGroup
        v-if="displayMCCWarning"
        class="acls-manager__warning"
      />
    </div>
    <div class="acls-manager__list">
      <h6>{{ listHeader }}</h6>
      <slot
        ><p>{{ listEmptyStateMessage }}</p>
      </slot>
    </div>
  </div>
</template>

<script lang="ts">
import NotificationWarningMCCGroup from "@/commons/components/ManageGroups/NotificationWarningMCCGroup.vue";
import SearchGroup from "@/commons/components/ManageGroups/SearchGroup.vue";

import { Debouncer } from "@/commons/libs/utils/debouncer";
import {
  isLocalGroup,
  isOnlyInternalsGroup,
} from "@/commons/utils/group-utils";

import { GroupWithUsersAcls } from "@/commons/domain/models/group-with-users-acls";
import { searchGroupInApiAccessRestrictions } from "@/commons/services/group/group-service";

import contents from "@/commons/contents/acls-manager";

export default {
  components: {
    NotificationWarningMCCGroup,
    SearchGroup,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    targetId: {
      type: String,
    },
    acls: {
      type: Array,
      default: () => [],
    },
    withPartners: {
      type: Boolean,
      default: false,
    },
    listHeader: {
      type: String,
      default: "Users and groups",
    },
    listEmptyStateMessage: {
      type: String,
      default: "You need to add users or groups in this part",
    },
  },
  emits: ["submit"],
  data() {
    return {
      contents,
      debouncer: new Debouncer(),
      suggestions: [],
      displayMCCWarning: false,
    };
  },
  watch: {
    async withPartners() {
      await this.searchGroups("");
    },
  },
  async mounted() {
    await this.searchGroups("");
  },
  methods: {
    addGroup(groupId: string) {
      const group = this.suggestions.find((group) => group.id === groupId);
      this.$emit("submit", group);
    },
    searchGroups(searchValue: string) {
      this.debouncer.debounce(async () => {
        this.suggestions = await searchGroupInApiAccessRestrictions(
          searchValue,
          this.withPartners,
        );
      }, 100);
    },
    onGroupSelection(groupId: string) {
      const selectedGroup = this.suggestions.find(
        (g: GroupWithUsersAcls) => g.id === groupId,
      );
      this.displayMCCWarning =
        selectedGroup != undefined &&
        isLocalGroup(selectedGroup) &&
        isOnlyInternalsGroup(selectedGroup);
    },
  },
};
</script>

<style lang="scss">
.acls-manager {
  display: flex;
}

.acls-manager__search {
  flex: 1;
  padding-right: 2rem;
  border-right: var(--border-default);
}

.acls-manager__list {
  flex: 1;
  padding-left: 2rem;
  border-left: var(--border-default);

  > * {
    align-items: end;
    padding-bottom: 1rem;
    border-bottom: var(--border-default);
  }

  :last-child {
    border: none;
  }
}

.acls-manager__warning {
  margin-top: 1rem;
}
</style>
