<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.11 17">
    <title id="icon-authorization-required" lang="en">
      Authorization required
    </title>
    <path
      d="M13.77,8.44V5.54a4.07,4.07,0,0,0-4.09-4h0a4.06,4.06,0,0,0-4,4.07V8.44h-2v6.92A3.17,3.17,0,0,0,6.79,18.5h5.82a3.15,3.15,0,0,0,3.14-3.14V8.44ZM6.63,5.57A3.06,3.06,0,0,1,9.7,2.5h0a3.07,3.07,0,0,1,3.06,3.07V8.44H6.63Zm8.12,9.78a2.16,2.16,0,0,1-2.14,2.15H6.79a2.16,2.16,0,0,1-2.15-2.14V9.44H14.75Z"
      transform="translate(-3.64 -1.5)"
    />
  </svg>
</template>
