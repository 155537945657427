import { DropdownMenuItem } from "@/commons/store/types";

export function getUseCasesMenuConf(): DropdownMenuItem {
  return {
    label: "Use cases",
    items: [
      {
        label: "Signage in store application",
        description:
          "Improve employees experience to have a beautiful and clear signage in store for inhabitants",
        urlOrRoute: {
          name: "useCase",
          params: {
            slug: "signage-in-store",
          },
        },
      },
      {
        label: "View all case studies",
        urlOrRoute: { name: "useCases" },
      },
    ],
  };
}
