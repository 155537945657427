import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "batches-management__card-content" }
const _hoisted_2 = { class: "batches-management__card-content-details" }
const _hoisted_3 = { class: "batches-management__select-and-run" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Markdown = _resolveComponent("Markdown")!
  const _component_HelpLink = _resolveComponent("HelpLink")!
  const _component_MSelect = _resolveComponent("MSelect")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_MNotification = _resolveComponent("MNotification")!
  const _component_CardContainer = _resolveComponent("CardContainer")!
  const _component_LayoutSection = _resolveComponent("LayoutSection")!

  return (_openBlock(), _createBlock(_component_LayoutSection, { class: "batches-management" }, {
    default: _withCtx(() => [
      _createVNode(_component_CardContainer, { class: "batches-management__card" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("h5", null, _toDisplayString($data.contents.batchesManagementTitle), 1),
              _createVNode(_component_Markdown, {
                content: $data.contents.batchesManagementDescription
              }, null, 8, ["content"]),
              _createElementVNode("div", null, [
                _createVNode(_component_HelpLink, {
                  href: $options.batchDocumentationUrl,
                  label: $data.contents.readDocumentationLink,
                  openInNewTab: ""
                }, null, 8, ["href", "label"])
              ])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_MSelect, {
                id: "batch-name-select",
                modelValue: $data.selectedBatchName,
                "onUpdate:modelValue": [
                  _cache[0] || (_cache[0] = ($event: any) => (($data.selectedBatchName) = $event)),
                  $options.reset
                ],
                options: $options.batchesNamesOptions,
                placeholder: $data.contents.selectBatchName
              }, null, 8, ["modelValue", "options", "placeholder", "onUpdate:modelValue"]),
              _createVNode(_component_MButton, {
                class: "batches-management__run-button",
                label: $data.contents.runBtnLabel,
                disabled: !$options.enabledRunButton,
                onClick: $options.launchSync
              }, null, 8, ["label", "disabled", "onClick"])
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_HelpLink, {
                class: "batches-management__dashboard-link",
                href: $options.batchDashboardUrl,
                label: $data.contents.followBatchExecutionStatus,
                openInNewTab: ""
              }, null, 8, ["href", "label"])
            ])
          ]),
          _createVNode(_component_MNotification, {
            class: "batches-management__warning-notification",
            type: "warning"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($data.contents.warningMessage), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}