import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-list-empty-item" }
const _hoisted_2 = { class: "app-list-empty-item__icon" }
const _hoisted_3 = { class: "app-list-empty-item__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MIcon = _resolveComponent("MIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_MIcon, {
        name: "Tools3d64",
        color: "var(--color-grey)"
      })
    ]),
    _createElementVNode("div", _hoisted_3, _toDisplayString($data.contents.noAppLabel), 1)
  ]))
}