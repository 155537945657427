import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withModifiers as _withModifiers, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7dd7c977"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "create-target-url__header" }
const _hoisted_2 = {
  key: 0,
  class: "create-target-url__actions"
}
const _hoisted_3 = { for: `create-target-url-input` }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MNotification = _resolveComponent("MNotification")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_MIcon = _resolveComponent("MIcon")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_SelectZones = _resolveComponent("SelectZones")!
  const _component_DynamicRouting = _resolveComponent("DynamicRouting")!
  const _component_CardContainer = _resolveComponent("CardContainer")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'create-target-url',
      { 'create-target-url--disabled': $props.isDisabled },
    ])
  }, [
    (!$options.isLoading && !$data.isEditing && !$options.availableZones.length)
      ? (_openBlock(), _createBlock(_component_MNotification, {
          key: 0,
          type: "information"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($data.contents.noGatewaysAvailable), 1)
          ]),
          _: 1
        }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          ($options.showCreationForm)
            ? (_openBlock(), _createBlock(_component_CardContainer, { key: 0 }, {
                default: _withCtx(() => [
                  _createElementVNode("form", {
                    autocomplete: "false | random-string",
                    onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
                  }, [
                    _createElementVNode("div", _hoisted_1, [
                      _createElementVNode("h4", null, _toDisplayString($data.contents.header), 1),
                      (!$props.isDisabled)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            _createVNode(_component_MButton, {
                              theme: "bordered-neutral",
                              label: $data.contents.buttonCancelLabel,
                              onClick: $options.cancel
                            }, null, 8, ["label", "onClick"]),
                            _createVNode(_component_MButton, {
                              "data-cy": "save-the-route",
                              label: $data.contents.buttonSaveLabel,
                              onClick: $options.create
                            }, null, 8, ["label", "onClick"])
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", {
                      "data-cy": "create-url-endpoint",
                      class: _normalizeClass([
              {
                'has-error': $data.urlValidationError != undefined,
              },
            ])
                    }, [
                      _createElementVNode("label", _hoisted_3, [
                        _createTextVNode(_toDisplayString($data.contents.targetUrl) + " ", 1),
                        (!$props.isDisabled)
                          ? (_openBlock(), _createBlock(_component_Tooltip, {
                              key: 0,
                              tooltipContent: $data.contents.targetUrlTooltip
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_MIcon, { name: "NotificationQuestion24" })
                              ]),
                              _: 1
                            }, 8, ["tooltipContent"]))
                          : _createCommentVNode("", true)
                      ]),
                      _createVNode(_component_TextInput, {
                        modelValue: $props.form.url,
                        "onUpdate:modelValue": [
                          _cache[0] || (_cache[0] = ($event: any) => (($props.form.url) = $event)),
                          $options.onUrlChange
                        ],
                        class: "margin-bottom-400",
                        reference: "inputTargetUrl",
                        placeholder: $data.contents.targetUrlPlaceholder,
                        isDisabled: $props.isDisabled,
                        error: $data.urlValidationError
                      }, null, 8, ["modelValue", "placeholder", "isDisabled", "error", "onUpdate:modelValue"])
                    ], 2),
                    _createElementVNode("div", {
                      class: _normalizeClass([
              'gap-800',
              {
                'has-error': $props.form.hasError('zoneIds'),
              },
            ])
                    }, [
                      _createElementVNode("label", null, [
                        _createTextVNode(_toDisplayString($data.contents.gateways) + " ", 1),
                        (!$props.isDisabled)
                          ? (_openBlock(), _createBlock(_component_Tooltip, {
                              key: 0,
                              tooltipContent: $data.contents.gatewaysTooltip
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_MIcon, { name: "NotificationQuestion24" })
                              ]),
                              _: 1
                            }, 8, ["tooltipContent"]))
                          : _createCommentVNode("", true)
                      ]),
                      _createVNode(_component_SelectZones, {
                        form: $props.form,
                        "is-disabled": $props.isDisabled
                      }, null, 8, ["form", "is-disabled"])
                    ], 2),
                    _createVNode(_component_DynamicRouting, {
                      class: "gap-800",
                      routingPolicies: $data.routingPolicies,
                      isDisabled: !$data.isEditing,
                      onUpdate: $options.updateRoutingPolicies
                    }, null, 8, ["routingPolicies", "isDisabled", "onUpdate"])
                  ], 32)
                ]),
                _: 1
              }))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("button", {
                  class: "create-target-url__show-form-button btn btn-transparent",
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($options.edit && $options.edit(...args)))
                }, [
                  _createVNode(_component_MIcon, {
                    class: "create-target-url__show-form-button--icon",
                    name: "ControlCircleMore32",
                    color: "var(--color-primary)"
                  }),
                  _createTextVNode(" " + _toDisplayString($data.contents.buttonAdd), 1)
                ])
              ]))
        ], 64))
  ], 2))
}