<script setup lang="ts">
import MFlag from "@mozaic-ds/vue-3/src/components/flag/MFlag.vue";
import MNotification from "@mozaic-ds/vue-3/src/components/notification/MNotification.vue";
import { computed, PropType } from "vue";

import AccessTokenUrlCopyContent from "@/dashboard/views/AppDetailsContracts/AccessTokenUrlCopyContent.vue";
import TokenCopyContent from "@/dashboard/views/AppDetailsContracts/TokenCopyContent.vue";

import { capitalize, labelize } from "@/commons/libs/utils/stringUtils";
import {
  getPingEnvironmentFlagTheme,
  getPingProviderLabel,
} from "@/commons/utils/ping-utils";

import { OAuthToken } from "@/commons/domain/models/oauth-token";

import contents from "@/dashboard/contents/oauth-credentials-bar";

const props = defineProps({
  oauthToken: {
    type: Object as PropType<OAuthToken>,
    required: true,
  },
});

const pingEnvironment = computed((): string => {
  const provider = props.oauthToken?.oauthDefinition?.provider;
  return getPingProviderLabel(provider);
});

const flagTheme = computed(() => {
  return getPingEnvironmentFlagTheme(pingEnvironment.value);
});
</script>

<template>
  <div class="oauth-credentials-bar">
    <div class="oauth-credentials-bar__access-notification">
      <MNotification
        v-if="oauthToken?.oauthFlow != undefined"
        type="information"
        :title="
          contents.accessNotification(
            capitalize(labelize(oauthToken?.oauthFlow)),
          )
        "
      />
    </div>
    <div class="oauth-credentials-bar__token">
      <div class="oauth-credentials-bar__token-environment">
        <div class="oauth-credentials-bar__token-environment-label">
          {{ contents.environmentLabel }}
        </div>
        <div class="oauth-credentials-bar__token-environment-flag">
          <MFlag :label="pingEnvironment" :theme="flagTheme" />
        </div>
      </div>
      <AccessTokenUrlCopyContent
        :label="contents.accessTokenUrlLabel"
        :content="oauthToken?.oauthDefinition?.tokenUrl"
      />
      <TokenCopyContent
        :label="contents.clientIDLabel"
        :content="oauthToken.clientId"
        hide
      />
      <TokenCopyContent
        :label="contents.clientSecretLabel"
        :content="oauthToken.clientSecret"
        hide
      />
    </div>
  </div>
</template>

<style lang="scss">
.oauth-credentials-bar {
  display: flex;
  flex-direction: column;

  width: 100%;
  border: 1px solid var(--color-stroke-light);
  border-radius: var(--m-border-radius-medium);

  &__access-notification {
    padding: 0.5rem;
    border-bottom: 1px solid var(--color-stroke-light);
  }

  &__token {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 1.5rem;
  }
}

.oauth-credentials-bar__token-environment {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  &-label {
    font-size: 14px;
    font-weight: 700;
  }

  &-flag {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
  }
}
</style>
