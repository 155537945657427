<template>
  <component
    :is="useLinkElement ? 'a' : 'button'"
    class="icon-button"
    :class="{ 'icon-button--disabled': disabled }"
    :href="useLinkElement ? href : undefined"
    :disabled="disabled"
    @click="$emit('click', $event)"
  >
    <MIcon :name="mozaicIconName" :color="color" />
  </component>
</template>

<script lang="ts">
import MIcon from "@mozaic-ds/vue-3/src/components/icon/MIcon.vue";

export default {
  components: {
    MIcon,
  },
  props: {
    mozaicIconName: {
      type: String,
      required: true,
    },
    color: {
      default: null,
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    href: {
      type: String,
      default: undefined,
    },
  },
  emits: ["click"],
  computed: {
    useLinkElement() {
      return this.href != undefined && !this.disabled;
    },
  },
};
</script>

<style lang="scss">
.icon-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 2rem;

  padding: 0.25rem;

  cursor: pointer;
  background-color: transparent;
}

.icon-button:hover {
  background-color: var(--color-background-secondary-light);
  border-radius: 0.25rem;
}

.icon-button--disabled {
  cursor: not-allowed;
  opacity: 0.2;
}
</style>
