import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "table-light" }
const _hoisted_2 = { class: "text-centered" }
const _hoisted_3 = {
  key: 1,
  colspan: "6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MNotification = _resolveComponent("MNotification")!
  const _component_ToggleButton = _resolveComponent("ToggleButton")!
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_InputTextsList = _resolveComponent("InputTextsList")!
  const _component_MDropdown = _resolveComponent("MDropdown")!
  const _component_CancelAndSubmitButtons = _resolveComponent("CancelAndSubmitButtons")!
  const _component_ButtonEdit = _resolveComponent("ButtonEdit")!
  const _component_AddPolicyButton = _resolveComponent("AddPolicyButton")!
  const _component_Stack = _resolveComponent("Stack")!

  return (_openBlock(), _createBlock(_component_Stack, { class: "cors" }, {
    default: _withCtx(() => [
      ($options.validationErrors.length)
        ? (_openBlock(), _createBlock(_component_MNotification, {
            key: 0,
            type: "danger"
          }, {
            default: _withCtx(() => [
              _createElementVNode("ul", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.validationErrors, (error, index) => {
                  return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(error), 1))
                }), 128))
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createElementVNode("p", null, _toDisplayString($data.contents.description), 1),
      _createElementVNode("table", _hoisted_1, [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _createElementVNode("th", null, _toDisplayString($data.contents.enable), 1),
            _createElementVNode("th", null, _toDisplayString($data.contents.allowedOrigins), 1),
            _createElementVNode("th", null, _toDisplayString($data.contents.allowedMethods), 1),
            _createElementVNode("th", null, _toDisplayString($data.contents.allowedHeaders), 1),
            _createElementVNode("th", null, _toDisplayString($data.contents.allowedCredentials), 1),
            _createElementVNode("th", null, _toDisplayString($data.contents.actions), 1)
          ])
        ]),
        _createElementVNode("tbody", null, [
          _createElementVNode("tr", null, [
            ($options.showCorsForm)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("td", null, [
                    _createVNode(_component_FormInput, {
                      error: $data.form.firstError('isEnabled')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ToggleButton, {
                          modelValue: $data.form.isEnabled,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.form.isEnabled) = $event)),
                          disabled: $options.isDisabled
                        }, null, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["error"])
                  ]),
                  _createElementVNode("td", null, [
                    _createVNode(_component_InputTextsList, {
                      isDisabled: $options.isDisabled,
                      placeholder: $data.contents.origin,
                      form: $data.form.allowOrigins
                    }, null, 8, ["isDisabled", "placeholder", "form"])
                  ]),
                  _createElementVNode("td", null, [
                    _createVNode(_component_FormInput, {
                      error: $data.form.firstError('allowMethods')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_MDropdown, {
                          modelValue: $data.form.allowMethods,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.form.allowMethods) = $event)),
                          items: $options.httpMethods,
                          multiple: "",
                          disabled: $options.isDisabled
                        }, null, 8, ["modelValue", "items", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["error"])
                  ]),
                  _createElementVNode("td", null, [
                    _createVNode(_component_InputTextsList, {
                      isDisabled: $options.isDisabled,
                      placeholder: $data.contents.header,
                      form: $data.form.allowHeaders
                    }, null, 8, ["isDisabled", "placeholder", "form"])
                  ]),
                  _createElementVNode("td", null, [
                    _createVNode(_component_FormInput, {
                      error: $data.form.firstError('allowCredentials')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ToggleButton, {
                          modelValue: $data.form.allowCredentials,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.form.allowCredentials) = $event)),
                          disabled: $options.isDisabled
                        }, null, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["error"])
                  ]),
                  _createElementVNode("td", _hoisted_2, [
                    ($data.isEditing)
                      ? (_openBlock(), _createBlock(_component_CancelAndSubmitButtons, {
                          key: 0,
                          onCancel: $options.cancel,
                          onSubmit: $options.update
                        }, null, 8, ["onCancel", "onSubmit"]))
                      : (_openBlock(), _createBlock(_component_ButtonEdit, {
                          key: 1,
                          onClick: $options.edit
                        }, null, 8, ["onClick"]))
                  ])
                ], 64))
              : (_openBlock(), _createElementBlock("td", _hoisted_3, [
                  _createVNode(_component_AddPolicyButton, {
                    label: $data.contents.addCors,
                    onAdd: $options.edit
                  }, null, 8, ["label", "onAdd"])
                ]))
          ])
        ])
      ])
    ]),
    _: 1
  }))
}