<template>
  <img
    class="icon-local-group"
    alt="Local Group icon"
    :src="require('@/commons/assets/icons/icon-devportal.svg')"
  />
</template>

<style lang="scss">
.icon-local-group {
  width: 24px;
  height: 24px;
}
</style>
