<template>
  <div :class="['select-zones', { 'select-zones--disabled': isDisabled }]">
    <div class="select-zones__wrapper">
      <div v-if="!isDisabled">
        <p>{{ contents.label }}</p>
        <MNotification
          v-if="!isLoading && selectableZones.length === 0"
          type="information"
        >
          {{ contents.noGatewaysAvailable }}
        </MNotification>
      </div>

      <div :class="[{ 'select-zones__selected-zones': !isDisabled }]">
        <div>
          <div
            v-for="selectableZone in selectableZones"
            :key="selectableZone.id"
            class="select-zones__selected-zone"
          >
            <MCheckbox
              :id="selectableZone.id"
              :modelValue="isZoneSelected(selectableZone)"
              :disabled="isDisabled || !!isPublished(selectableZone.id)"
              @change="onZoneCheckboxChange($event, selectableZone)"
            >
              <template #label>
                <GatewayLabelSymbols :zone="selectableZone" />
              </template>
            </MCheckbox>
            <span
              v-if="isZoneSelected(selectableZone)"
              :class="[
                'select-zones__publishing',
                {
                  'select-zones__publishing--published': isPublished(
                    selectableZone.id,
                  ),
                },
              ]"
            >
              <template v-if="isPublished(selectableZone.id)">
                {{ contents.published }}
              </template>
              <template v-else>
                {{ contents.toPublish }}
              </template>
            </span>
          </div>
        </div>
        <div
          v-if="!isDisabled && !apiHasPendingOtherGatewaysRequest"
          class="select-zones__request-access-gateways"
          @click="requestOtherGateways"
        >
          {{ contents.accessToAnotherGateway }}
        </div>
        <div
          v-else-if="!isDisabled && apiHasPendingOtherGatewaysRequest"
          class="select-zones__request-access-gateways--pending"
        >
          <div class="select-zones__request-access-gateways-tag">
            {{ contents.pendingOtherGatewayRequests }}
          </div>
          <router-link
            :to="otherGatewaysPendingRequestsRoute"
            class="select-zones__request-access-gateways-view-request"
            target="_blank"
          >
            {{ contents.viewRequest }}
            <IconExternalLink />
          </router-link>
        </div>
      </div>
      <div v-if="form.firstError('zoneIds')" class="small input-errors">
        {{ form.firstError("zoneIds") }}
      </div>

      <div v-if="!isDisabled && userIsAdmin" class="select-zones__admin-zones">
        <MNotification
          v-if="isShowingAdminNotification"
          :title="contents.adminZonesNotificationTitle"
          type="warning"
        />
        <div class="select-zones__admin-zones-header">
          <p>
            {{ contents.listOfNonDeprecatedGateways }} <br />
            <span class="weight-bold"
              >({{ contents.availableOnlyForAdmins }})</span
            >
          </p>
        </div>
        <div class="select-zones__admin-zones-container">
          <div
            v-for="zone in adminZones"
            :key="zone.id"
            class="select-zones__admin-zones-item"
          >
            <MCheckbox
              v-if="zone"
              :id="zone.id"
              :modelValue="false"
              @change="onAdminZoneCheckboxChange($event, zone)"
            >
              <template #label>
                <GatewayLabelSymbols :zone="zone" />
              </template>
            </MCheckbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import MCheckbox from "@mozaic-ds/vue-3/src/components/checkbox/MCheckbox.vue";
import MNotification from "@mozaic-ds/vue-3/src/components/notification/MNotification.vue";
import { markRaw } from "vue";

import IconExternalLink from "@/commons/components/Icons/IconExternalLink.vue";
import GatewayLabelSymbols from "@/manager/views/ApiDetailRouting/GatewayLabelSymbols.vue";

import RequestOtherGatewaysLayerModal from "./RequestOtherGatewaysLayerModal.vue";

import { Route } from "@/commons/domain/models/route";
import { Zone } from "@/commons/domain/models/zone";

import {
  ERequestFilteringVisibility,
  ERequestType,
  ERouteStatus,
} from "@/commons/store/types";

import contentsOfRequestOtherGatewaysModal from "@/manager/contents/request-other-gateways-layer-modal";
import contents from "@/manager/contents/select-zones";

export default {
  name: "SelectZones",
  components: {
    GatewayLabelSymbols,
    MCheckbox,
    IconExternalLink,
    MNotification,
  },
  props: {
    targetUrl: {
      type: String,
      default: "",
    },
    form: {
      type: Object,
      default: () => ({}),
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      contents,
      initiallySelectedZoneIds: [],
      selectedZonesByAdmin: [],
    };
  },
  computed: {
    otherGatewaysPendingRequestsRoute() {
      return {
        name: "requestManager",
        query: {
          types: ERequestType.OTHER_GATEWAYS,
          visibility: ERequestFilteringVisibility.SUBMITTED,
          page: 1,
        },
      };
    },
    allZones() {
      return Object.values(this.$store.getters["zones"]);
    },
    adminZones(): Zone[] {
      const selectableZoneIds = this.selectableZones.map(
        (zone: Zone) => zone.id,
      );

      return this.allZones.filter(
        (zone: Zone) =>
          !zone.deprecated &&
          !selectableZoneIds.includes(zone.id) &&
          // exclude zones that are already added in other API's routes
          !this.downstreamRoutes.find((route: Route) =>
            route.zoneIds.includes(zone.id),
          ),
      );
    },
    availableZones() {
      return this.$store.getters["availableZones"];
    },
    selectableZones() {
      const selectableZones = [
        ...this.availableZones,
        ...this.selectedZonesByAdmin,
      ].sort((a, b) => a.name.localeCompare(b.name));

      // availableZones only contains zones you can add to the settings, not already selected zones, so we add them too
      this.initiallySelectedZoneIds.forEach((zoneId) => {
        if (selectableZones.find((zone) => zone.id === zoneId) === undefined) {
          selectableZones.unshift(
            this.allZones.find((zone) => zone.id === zoneId),
          );
        }
      });

      return selectableZones;
    },
    downstreamRoutes() {
      return this.$store.getters["downstreamRoutes"];
    },
    isLoading() {
      return this.$store.getters["isLoading"];
    },
    currentApi() {
      return this.$store.getters["currentApi"];
    },
    apiHasPendingOtherGatewaysRequest() {
      return this.$store.getters["apiHasPendingOtherGatewaysRequest"];
    },
    userIsAdmin() {
      return this.$store.getters["userIsAdmin"];
    },
    isShowingAdminNotification() {
      return this.selectedZonesByAdmin.length > 0;
    },
  },
  async mounted() {
    await this.$store.dispatch(
      "fetchPendingOtherGatewayRequests",
      this.currentApi.id,
    );

    this.initiallySelectedZoneIds = this.form.zoneIds
      ? [...this.form.zoneIds]
      : [];
  },
  methods: {
    isZoneSelected(zone) {
      const { zoneIds } = this.form.data();
      return zoneIds.includes(zone.id);
    },
    async onZoneCheckboxChange(event, zone) {
      const { zoneIds } = this.form.data();
      const newZoneIds = zoneIds.filter((zoneId) => zoneId !== zone.id);

      if (event.target.checked === true) {
        newZoneIds.push(zone.id);
      }

      await this.form.update({
        zoneIds: newZoneIds,
      });
    },
    isPublished(zoneId) {
      let currentDownstreamRoute = this.downstreamRoutes.find(
        (route) =>
          route.zoneIds.includes(zoneId) &&
          route.status === ERouteStatus.ENABLED,
      );
      return currentDownstreamRoute !== undefined;
    },
    requestOtherGateways() {
      this.$store.commit("openLayerModal", {
        title: contentsOfRequestOtherGatewaysModal.modalTitle,
        component: markRaw(RequestOtherGatewaysLayerModal),
        props: {
          apiId: this.currentApi.id,
        },
        listeners: {},
      });
    },
    onAdminZoneCheckboxChange(event, zone: Zone) {
      if (event.target.checked === true) {
        this.selectedZonesByAdmin.push(zone);
        this.onZoneCheckboxChange(event, zone);
      }
    },
  },
};
</script>

<style lang="scss">
.select-zones--disabled {
  background-color: var(--color-white);
}

.select-zones__select-inner {
  flex: 1;
  min-width: 90%;
}

.select-zones__add-zone-container {
  cursor: pointer;
}

.select-zones__add-zone {
  display: flex;
  align-items: center;
  height: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  font-style: normal;
  color: var(--color-white);
  text-transform: uppercase;
  cursor: pointer;
}

.select-zones__selected-zones {
  display: flex;
  justify-content: space-between;
  padding: 24px;
  margin-top: 16px;
  border: var(--border-default);
  border-radius: 4px;
}

.select-zones__selected-zone {
  display: flex;
  align-items: center;
  margin-right: 16px;
  gap: 0.375rem;

  &:not(:last-child) {
    margin-bottom: 5px;
  }
}

.select-zones__accessibility,
.select-zones__publishing {
  margin-right: 1rem;
}

.select-zones__accessibility {
  display: flex;
}

.select-zones__publishing {
  padding: 1px 8px;
  font-weight: 700;
  border: var(--border-default);
  border-radius: 15px;
}

.select-zones__publishing--published {
  color: var(--color-primary);
  border-color: var(--color-primary);
}

.select-zones__remove {
  margin-left: auto;
}

.select-zones__request-access-gateways,
.select-zones__request-access-gateways-tag {
  height: fit-content;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 700;
  color: var(--color-white);
}

.select-zones__request-access-gateways {
  padding: 7px 16px;
  cursor: pointer;
  background-color: var(--color-text);
  border-radius: 4px;
}
.select-zones__request-access-gateways-tag {
  padding: 3px 16px;
  cursor: default;
  background-color: var(--color-primary);
  border-radius: 14px;
}

.select-zones__request-access-gateways--pending {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.select-zones__request-access-gateways-view-request {
  display: flex;
  align-items: center;
  text-decoration: underline;
  & > * {
    margin-left: 6px;
  }
}

.select-zones__admin-zones {
  margin-top: var(--base-spacing);
}

.select-zones__admin-zones-container {
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
  padding: 1.5rem;
  border: var(--border-default);
  border-radius: var(--m-border-radius-medium);
}
</style>
