import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "layer-title" }
const _hoisted_2 = { class: "add-documentation-first-step__doc-types" }
const _hoisted_3 = ["data-cy-value", "onClick"]
const _hoisted_4 = { class: "add-documentation-type-button__label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MIcon = _resolveComponent("MIcon")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_LayerModalForm = _resolveComponent("LayerModalForm")!

  return (_openBlock(), _createBlock(_component_LayerModalForm, {
    class: "add-documentation-first-step",
    onSubmit: $options.next
  }, {
    fields: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString($data.contents.chooseDocType), 1),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.filteredDocumentationTypeItems, (itemType) => {
          return (_openBlock(), _createElementBlock("div", {
            key: itemType.value,
            class: _normalizeClass([
            'add-documentation-first-step__doc-type',
            'cursor-pointer',
            {
              'add-documentation-first-step__doc-type--active': $options.isSelected(
                itemType.value,
              ),
            },
          ]),
            "data-cy-value": itemType.value,
            onClick: ($event: any) => ($options.onTypeChange(itemType))
          }, [
            _createVNode(_component_MIcon, {
              name: itemType.icon,
              color: "var(--color-text-minor)"
            }, null, 8, ["name"]),
            _createElementVNode("div", _hoisted_4, _toDisplayString(itemType.label), 1)
          ], 10, _hoisted_3))
        }), 128))
      ])
    ]),
    formFooter: _withCtx(() => [
      _createVNode(_component_MButton, {
        theme: "bordered-neutral",
        label: $data.contents.cancel,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onClose')))
      }, null, 8, ["label"]),
      _createVNode(_component_MButton, {
        "data-cy": "add-doc-fist-step-next",
        type: "submit",
        label: $data.contents.next
      }, null, 8, ["label"])
    ]),
    _: 1
  }, 8, ["onSubmit"]))
}