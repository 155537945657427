<template>
  <div class="histogram-application">
    <HitsTable :hits-data="visibleApplicationHits" :interval="interval" />
  </div>
</template>

<script lang="ts">
import HitsTable from "@/commons/components/Graph/HitsTable.vue";

export default {
  name: "HistogramApplication",
  components: {
    HitsTable,
  },
  props: {
    applicationId: {
      type: String,
      default: null,
    },
  },
  computed: {
    visibleApplicationHits() {
      return this.$store.getters["reporting/visibleApplicationHits"];
    },
    interval() {
      return this.$store.getters["reporting/interval"];
    },
  },
};
</script>
