import { HttpRepository } from "@/commons/repositories/libs/http-repository";

import { Injectable } from "@/commons/domain/di/injectable";
import { Scope } from "@/commons/domain/models/scope";
import {
  ScopeFetchParams,
  ScopeRepository,
} from "@/commons/domain/repositories/scope-repository";
import { PagedResourceDto } from "@/commons/dtos/paged-resource-dto";
import { ValidationDTO } from "@/commons/dtos/validation-dto";

@Injectable()
export class ScopeHttpRepository
  extends HttpRepository
  implements ScopeRepository
{
  public async fetchScopes(params: ScopeFetchParams) {
    const { page, size, name } = params;
    const response = await this.requestHandler.get<PagedResourceDto<Scope>>(
      `/scopes/${params.apiId}`,
      { params: { page, size, name } },
    );
    return response.data;
  }

  public async validateScopeName(
    name: string,
    apiId: string,
  ): Promise<ValidationDTO> {
    const response = await this.requestHandler.cancelAndGet<ValidationDTO>(
      `/scopes/${apiId}/validate-scope-name/${name}`,
    );
    return response.data;
  }

  public async createScope(
    apiId: string,
    name: string,
    description: string,
  ): Promise<void> {
    await this.requestHandler.post(`/scopes/${apiId}`, { name, description });
  }

  public async updateScopeDescription(
    apiId: string,
    name: string,
    description: string,
  ): Promise<Scope> {
    const response = await this.requestHandler.patch<Scope>(
      `/scopes/${apiId}/${name}`,
      {
        description,
      },
    );
    return response.data;
  }

  public async getDocumentedScopes(apiId: string) {
    const response = await this.requestHandler.get<Scope[]>(
      `/scopes/${apiId}/documented`,
    );
    return response.data;
  }
}
