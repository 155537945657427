module.exports = {
  title: "Quotas",
  numberOne: "1",
  numberTwo: "2",
  chooseQuotaType: "Choose a quota type for your API",

  apiQuotaOptionLabel: "API quota",
  apiQuotaOptionDescription:
    "Represents the maximum number of requests that an API supports.",
  consumersQuotaOptionLabel: "Consumers quota",
  consumersQuotaOptionDescription:
    "Represents the maximum number of requests that an application can make.",

  defineQuotaYouNeed: "Define the quota you need",
  apiQuotaExplanation:
    "The API quota is the maximum of calls on your API regardless of the consumer. Beware that this limit is always enforced, even when a resource exception applies. In that case, both the global and the resource-specific rate limits will be enforced.",
  consumerQuotaExplanation:
    "The default rate limit is set on all new token generated on your API. If you change this value, it does not affect the existing tokens.",
  addQuotaForNewConsumer: "Add quota for new consumers",
  addQuotaForApi: "Add quota for API",
  quotaConsumerModalTitle: "Define a default quota for new consumers",
};
