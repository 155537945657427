<template>
  <CardListed class="card-loading with-loader">
    <Stack>
      <WithSidebar sidebar-to-right>
        <template #left
          ><div class="card-loading__header bg-grey radius"
        /></template>
        <template #right
          ><div class="card-loading__version bg-grey radius"
        /></template>
      </WithSidebar>
      <div class="card-loading__subheader bg-light-grey radius" />
      <div class="card-loading__description bg-light-grey radius" />
      <div class="card-loading__description bg-light-grey radius" />
    </Stack>
    <template v-if="!hideFooter" #footer>
      <CardFooter class="card-footer-api"
        ><div class="card-loading__footer bg-light-grey radius" />
        <div class="card-loading__footer bg-light-grey radius" />
        <div
          class="card-loading__footer bg-light-grey radius" /></CardFooter></template
  ></CardListed>
</template>

<script lang="ts">
import CardFooter from "@/commons/components/CardListed/CardFooter.vue";
import CardListed from "@/commons/components/CardListed/CardListed.vue";
import Stack from "@/commons/components/Stack.vue";
import WithSidebar from "@/commons/components/WithSidebar.vue";

export default {
  name: "CardApiSummary",
  components: { CardListed, CardFooter, WithSidebar, Stack },
  props: {
    hideFooter: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.card-loading {
  --sidebar-layout-side-width: "content";
  min-height: 15rem;

  .card-loading__header {
    width: 50%;
    height: 1.3rem;
  }

  .card-loading__version {
    width: 2.5rem;
    height: 1.3rem;
  }

  .card-loading__subheader {
    width: 80%;
    height: 1rem;
  }

  .card-loading__description {
    height: 1rem;
  }

  .card-loading__footer {
    display: inline-block;
    width: 3rem;
    height: 1rem;
    margin-right: 1rem;
  }

  &:hover {
    cursor: default;
    box-shadow: none;
  }
}
</style>
