import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "label" }
const _hoisted_2 = {
  key: 1,
  class: "label__required-message weight-medium"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MIcon = _resolveComponent("MIcon")!
  const _component_Tooltip = _resolveComponent("Tooltip")!

  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default"),
    ($props.tooltip)
      ? (_openBlock(), _createBlock(_component_Tooltip, {
          key: 0,
          tooltipContent: $props.tooltip,
          class: "label__tooltip"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_MIcon, { name: "NotificationQuestion24" })
          ]),
          _: 1
        }, 8, ["tooltipContent"]))
      : _createCommentVNode("", true),
    ($props.isRequired)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, " required "))
      : _createCommentVNode("", true)
  ]))
}