import {
  Validate,
  ValidationArguments,
  ValidatorConstraint,
  ValidatorConstraintInterface,
} from "class-validator";

import { FormData } from "@/commons/domain/forms/form-data";
import { FormFactory } from "@/commons/domain/forms/form-factory";

@ValidatorConstraint({
  name: "EqualToConfirmationText",
  async: false,
})
export class EqualToConfirmationText implements ValidatorConstraintInterface {
  validate(value: string, args: ValidationArguments) {
    return value === (args.object as ConfirmActionForm).confirmationText;
  }
  defaultMessage(args: ValidationArguments) {
    return `You have to write the text "${
      (args.object as ConfirmActionForm).confirmationText
    }"`;
  }
}

export class ConfirmActionForm extends FormData {
  confirmationText: string;

  @Validate(EqualToConfirmationText)
  text: string;

  constructor(confirmationText?: string) {
    super();
    this.confirmationText = confirmationText;
  }

  $clear() {
    this.text = "";
  }

  static create(confirmationText?: string) {
    return FormFactory.createForm(new ConfirmActionForm(confirmationText));
  }
}
