<template>
  <MField
    :id="computedId"
    class="dvp-field"
    :class="dynamicClasses"
    :label="label"
    :required="required"
    :requirementText="internalRequirementText"
    :helpId="computedHelpTextId"
    :helpText="helpText"
    :errorId="errorId"
    :errorMessage="errorMessage"
    :isInvalid="isInvalid"
  >
    <slot />
  </MField>
</template>

<script lang="ts">
import MField from "@mozaic-ds/vue-3/src/components/field/MField.vue";

import contents from "@/commons/contents/form";

export default {
  components: {
    MField,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    requirementText: {
      type: String,
      default: undefined,
    },
    helpText: {
      type: String,
      default: null,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    errorId: {
      type: String,
      default: null,
    },
  },
  computed: {
    computedId(): String {
      return this.id ?? `id-${this.$.uid}`;
    },
    computedHelpTextId(): String {
      return this.computedId + "-help-text";
    },
    internalRequirementText(): string | undefined {
      return this.requirementText ?? this.defaultRequirementText;
    },
    defaultRequirementText(): string | undefined {
      return this.required ? contents.required : undefined;
    },
    isInvalid(): boolean {
      return this.errorMessage != null;
    },
    dynamicClasses(): string[] {
      return this.label === "" ? ["dvp-field__no-label"] : [];
    },
  },
};
</script>

<style lang="scss">
// label prop is mandatory on <MField> and always displayed but we sometime don't need it on devportal so we make it
// optional on <DvpField>, generate an empty "" value for <MField> and force a display none
.dvp-field__no-label {
  .mc-field__label {
    display: none;
  }

  .mc-field__element,
  .mc-field__input {
    margin-top: 0 !important;
  }
}
</style>
