<template>
  <Stack class="policies-order">
    <p>
      <span>{{ message }}</span>
      <a v-if="allowTransformationPoliciesOrder" :href="documentationUrl"
        >{{ documentationMessage }}
      </a>
    </p>

    <div v-if="policiesOrderList.length > 0" class="row row-padded">
      <div class="col">
        <table
          class="table-light policies-order__policies"
          :class="{ dragging }"
        >
          <thead>
            <tr>
              <th
                v-for="(name, index) in [
                  'Order',
                  'Rank',
                  'Transformation Policy Type',
                  'Transformation Policy Name',
                  'Transformation Policy Value',
                ]"
                :key="index"
              >
                {{ name }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(policy, index) in policiesOrderList"
              :key="index"
              draggable
              @dragstart="dragStart(policy)"
              @dragover.prevent
              @dragenter.prevent
              @drop.prevent="onDrop(policy)"
              @dragend="dragEnd"
            >
              <td class="cell-move">
                <img
                  :src="iconSwitch"
                  color="var(--color-background-secondary-light)"
                />
              </td>
              <td>{{ policy.order }}</td>
              <td>{{ policy.type }}</td>
              <td>{{ policy.name }}</td>
              <td>{{ policy.value }}</td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </Stack>
</template>

<script lang="ts">
import Stack from "@/commons/components/Stack.vue";

import { dragOrder } from "@/commons/libs/utils/drag-order";
import { convertRouteToHref } from "@/commons/utils/route-utils";

import contents from "@/manager/contents/policies-order";

export default {
  name: "PoliciesOrder",
  components: {
    Stack,
  },
  props: {
    apiId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      contents,
      dragging: false,
      showTooltip: false,
      draggedPolicy: undefined,
      iconSwitch: require("@/commons/assets/icons/icon-switch.svg"),
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters["isLoading"];
    },
    documentationUrl() {
      return convertRouteToHref({
        name: "docsMain",
        hash: "#transformation-policies-order",
      });
    },
    currentApi() {
      return this.$store.getters["currentApi"];
    },
    allowedActions() {
      return this.$store.getters["apiAllowedActions"];
    },
    allowTransformationPoliciesOrder() {
      return this.$store.getters["apiAllowTransformationPoliciesOrder"];
    },
    policiesOrder() {
      const policies = this.$store.getters["policiesOrder"];
      return policies.sort((policy1, policy2) => policy1.order - policy2.order);
    },
    policiesOrderList() {
      return this.policiesOrder;
    },
    documentationMessage() {
      return this.contents.messageDocumentation;
    },
    message() {
      return this.contents.message;
    },
  },
  methods: {
    dragStart(policy) {
      this.dragging = true;
      this.draggedPolicy = policy;
    },
    dragEnd() {
      this.dragging = false;
      this.draggedPolicy = undefined;
    },
    async onDrop(policyToSwitch) {
      const { customProxyHeaders, customQueryParams } = this.currentApi;

      dragOrder(
        this.policiesOrder,
        policyToSwitch.order,
        this.draggedPolicy.order,
      ).forEach((policy: any) => {
        if (customProxyHeaders[policy.id]) {
          customProxyHeaders[policy.id].order = policy.order;
        }
        if (customQueryParams[policy.id]) {
          customQueryParams[policy.id].order = policy.order;
        }
      });

      await this.$store.dispatch("updateApi", {
        id: this.currentApi.id,
        customProxyHeaders,
        customQueryParams,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/commons/assets/styles/vars";

.policies-order {
  & .empty-state-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 280px);

    .empty-state {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  p {
    white-space: pre-line;

    a {
      padding-left: 2px;
    }
  }

  &__policies {
    margin-top: 2rem;
    margin-bottom: 3rem;

    tbody tr {
      cursor: grab;
      transition: all 0.5s ease-in;
    }

    .cell-move {
      padding-left: 1rem;
      font-size: 1.3rem;
      color: var(--color-light-grey);
      text-align: left;
    }
  }

  .table-light {
    td:nth-child(1),
    th:nth-child(1),
    td:nth-child(2),
    th:nth-child(2) {
      width: 6.5%;
    }
    td:nth-child(3),
    th:nth-child(3),
    td:nth-child(4),
    th:nth-child(4),
    td:nth-child(5),
    th:nth-child(5) {
      width: 29%;
    }
    th:last-child {
      text-align: left;
    }
  }
}
</style>
