<template>
  <ConfirmModalBody
    class="modal-consumers-preventing-ip-filtering-activation"
    :confirmBtnLabel="contents.confirmBtnLabel"
    @onSubmit="emit('onSubmit')"
    @onClose="emit('onClose')"
  >
    <h6 class="modal-consumers-preventing-ip-filtering-activation__title">
      {{ contents.title }}
    </h6>
    <Markdown :content="enrichedContent" />
    <HelpLink
      class="modal-consumers-preventing-ip-filtering-activation__consumers-help-link"
      :href="hrefToConsumersPage"
      :label="contents.consumersBtnLabel"
      :withIcon="false"
    />
    <Markdown :content="contents.managerChecksText" />
  </ConfirmModalBody>
</template>

<script setup lang="ts">
import { computed, PropType } from "vue";

import ConfirmModalBody from "@/commons/components/Modal/ConfirmModalBody.vue";
import HelpLink from "@/commons/components/UserDocumentationLinks/HelpLink.vue";
import Markdown from "@/documentation/components/commons/Markdown.vue";

import { convertRouteToHref } from "@/commons/utils/route-utils";

import { Api } from "@/commons/domain/models/api";

import contents from "@/manager/contents/modal-consumers-preventing-ip-filtering-activation";

const props = defineProps({
  api: {
    type: Object as PropType<Api>,
    required: true,
  },
  applicationsWithoutIPs: {
    type: Array as PropType<string[]>,
    required: true,
  },
});

const emit = defineEmits(["onClose", "onSubmit"]);

const enrichedContent = computed((): string => {
  const appList = props.applicationsWithoutIPs
    .map((appName) => `- ${appName}`)
    .join("\n");

  return `${contents.explanation}\n\n${appList}`;
});

const hrefToConsumersPage = computed((): string =>
  convertRouteToHref({
    name: "managerApiDetailConsumers",
    params: {
      id: props.api.id,
    },
  }),
);
</script>
<style lang="scss">
.modal-consumers-preventing-ip-filtering-activation__consumers-help-link {
  margin: 1rem;
}
</style>
