import { Dictionary } from "@/commons/domain/models/dictionary";
import { OAuthToken } from "@/commons/domain/models/oauth-token";
import { OAuthTokenDto } from "@/commons/dtos/oauth-token-dto";

export class OAuthTokenMapper {
  static toDomain(
    oauthTokensDtos: OAuthTokenDto[] = [],
  ): Dictionary<OAuthToken> {
    const oauthTokens: Dictionary<OAuthToken> = {};

    for (const oauthTokenDto of oauthTokensDtos) {
      oauthTokens[oauthTokenDto.id] = {
        id: oauthTokenDto.id,
        isRemoved: oauthTokenDto.isRemoved || false,
        clientId: oauthTokenDto.clientId,
        clientSecret: oauthTokenDto.clientSecret,
        oauthFlow: oauthTokenDto.oauthFlow,
        tokenUrl: oauthTokenDto.tokenUrl,
        shared: oauthTokenDto.shared,
        multiLined: oauthTokenDto.multiLined,
        scopes: oauthTokenDto.oauthScopes,
        oauthDefinition: oauthTokenDto.oauthDefinition,
        enabled: oauthTokenDto.enabled,
      };
    }

    return oauthTokens;
  }
}
