import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "group-memberships-modal" }
const _hoisted_2 = {
  key: 0,
  class: "group-memberships-modal__add-user-section"
}
const _hoisted_3 = { class: "group-memberships-modal__user-list-section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AddUser = _resolveComponent("AddUser")!
  const _component_UsersList = _resolveComponent("UsersList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($options.isLocalGroup && $options.userHasWriteAccessOnCurrentGroup)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("h5", null, _toDisplayString($data.contents.addUserToTheGroup), 1),
          _createVNode(_component_AddUser, {
            roles: $options.applicableRoles,
            defaultRole: $data.ERole.VIEWER,
            onUserSubmitted: $options.addUserToGroup
          }, null, 8, ["roles", "defaultRole", "onUserSubmitted"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("h5", null, _toDisplayString($data.contents.userList), 1),
      _createVNode(_component_UsersList, {
        groupId: $props.groupId,
        group: $data.group,
        users: $data.usersList,
        isLoading: $options.isLoading,
        allowActions: "",
        onOnSelfRemoval: _cache[0] || (_cache[0] = ($event: any) => ($options.onSelfRemoval())),
        onGroupUpdated: _cache[1] || (_cache[1] = ($event: any) => ($options.loadUsers()))
      }, null, 8, ["groupId", "group", "users", "isLoading"])
    ])
  ]))
}