import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "authentication-settings"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AuthenticationApiKeySettings = _resolveComponent("AuthenticationApiKeySettings")!
  const _component_AuthenticationOauthSettings = _resolveComponent("AuthenticationOauthSettings")!

  return (!$options.isLoadingApi)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        ($options.currentApi.authType == $data.EAuthType.API_KEY)
          ? (_openBlock(), _createBlock(_component_AuthenticationApiKeySettings, { key: 0 }))
          : (_openBlock(), _createBlock(_component_AuthenticationOauthSettings, { key: 1 }))
      ]))
    : _createCommentVNode("", true)
}