import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-71affcc2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home-use-cases" }
const _hoisted_2 = { class: "home-use-cases__description" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "description" }
const _hoisted_5 = { class: "home-use-cases__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MButton = _resolveComponent("MButton")!
  const _component_use_case_card = _resolveComponent("use-case-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", {
        class: "title",
        innerHTML: $data.contents.allUseCasesTitle
      }, null, 8, _hoisted_3),
      _createElementVNode("p", _hoisted_4, _toDisplayString($data.contents.allUseCasesDescription), 1),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_MButton, {
          label: $data.contents.allUseCasesButtonLabel,
          theme: "solid-neutral",
          onClick: _cache[0] || (_cache[0] = () => _ctx.$router.push({ name: 'useCases' }))
        }, null, 8, ["label"])
      ])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.useCases, (useCase, index) => {
      return (_openBlock(), _createBlock(_component_use_case_card, {
        key: `use-case-${index}`,
        image: useCase.image[$options.locationSettings.name],
        title: useCase.title,
        description: useCase.description,
        path: `/usecases/${useCase.slug}`
      }, null, 8, ["image", "title", "description", "path"]))
    }), 128))
  ]))
}