import { isTopic } from "@/commons/libs/utils/apiUtils";
import { getApiRepository } from "@/commons/repositories/libs/get-api-repository";

import { Quota } from "@/commons/domain/models/quota";
import { ValidationDTO } from "@/commons/dtos/validation-dto";

import { IApi } from "@/commons/store/types";

export async function canContactOwners(api: IApi): Promise<boolean> {
  return isTopic(api)
    ? await getApiRepository().canContactOwners(api.id)
    : true; // we can always contact owners on rest APIs
}

export async function getActiveQuota(apiId: string): Promise<Quota> {
  return await getApiRepository().getActiveQuota(apiId);
}

export async function searchUsedApi(
  name: string,
  onlySubscribed: boolean = true,
): Promise<any> {
  const pagedApis = await getApiRepository().cancelAndFind({
    name,
    onlySubscribed,
  });

  return Object.values(pagedApis.data);
}

export async function getApisByIds(
  apiIds: string[],
  onlySubscribed: boolean = false,
): Promise<any> {
  if (apiIds?.length > 0) {
    const pagedApis = await getApiRepository().cancelAndFind({
      apiIds,
      onlySubscribed,
    });
    return Object.values(pagedApis.data);
  } else {
    return [];
  }
}

export async function validateRouteTargetUrl(
  targetUrl: string,
): Promise<ValidationDTO> {
  return await getApiRepository().validateRouteTargetUrl(targetUrl);
}
