<template>
  <div
    v-if="!(isDisabled && routingPoliciesSortedByOrderList.length == 0)"
    :class="[
      'dynamic-routing',
      isDisabled
        ? 'dynamic-routing--is-disabled'
        : 'dynamic-routing--is-enabled',
    ]"
  >
    <!-- Header of the list -->
    <template
      v-if="routingPoliciesSortedByOrderList.length || isAddingNewCondition"
    >
      <div class="dynamic-routing__title">
        <h4 class="dynamic-routing__title-text">
          {{ contents.dynamicRouting }}
        </h4>
        <ViewHelpButton
          v-if="!isDisabled"
          documentationAnchor="#manage-dynamic-routing"
        />
      </div>
      <MNotification
        v-if="isDisplayDynRoutingNotification"
        class="dynamic-routing__notification"
        type="warning"
        :title="contents.notificationTitle"
      />
      <div class="dynamic-routing__headers">
        <div class="dynamic-routing__header">{{ contents.conditions }}</div>
        <div class="dynamic-routing__header">{{ contents.key }}</div>
        <div class="dynamic-routing__header">{{ contents.value }}</div>
        <div class="dynamic-routing__header">{{ contents.targetUrl }}</div>
        <div v-if="!isDisabled" class="dynamic-routing__header">
          {{ contents.actions }}
        </div>
      </div>
    </template>

    <!-- List of routing policies -->
    <div
      v-for="routingPolicy in routingPoliciesSortedByOrderList"
      :key="routingPolicy.id"
      :draggable="!isDisabled && stopDrag"
      @dragstart="dragStart(routingPolicy, $event)"
      @dragover.prevent
      @drop.prevent="drop(routingPolicy, $event)"
    >
      <UpdateOrRemoveRoutingPolicy
        :routingPolicy="routingPolicy"
        :isDisabled="isDisabled"
        :routeEntryPoints="routeEntryPoints"
        @update="update"
        @remove="remove"
        @stopDraggable="stopDraggable"
        @allowDraggable="allowDraggable"
        @displayNotification="displayNotification"
      />
    </div>

    <!-- New routing policy -->
    <CreateRoutingPolicy
      v-if="routingPoliciesSortedByOrderList.length || isAddingNewCondition"
      :isDisabled="isDisabled"
      :isEditing="isAddingNewCondition"
      :routeEntryPoints="routeEntryPoints"
      @create="create"
      @close="isAddingNewCondition = false"
      @edit="createOrUpdateDynamicRoutingCondition"
      @stopDraggable="stopDraggable"
      @allowDraggable="allowDraggable"
    />

    <!-- Show list of routing policies button -->
    <div
      v-if="!routingPoliciesSortedByOrderList.length && !isAddingNewCondition"
      class="dynamic-routing__btn"
      @click="createOrUpdateDynamicRoutingCondition"
    >
      <MIcon
        class="dynamic-routing__icon"
        name="ControlCircleMore32"
        color="var(--color-primary)"
      >
      </MIcon>
      {{ contents.addDynamicRoute }}
    </div>
  </div>
</template>

<script lang="ts">
import MIcon from "@mozaic-ds/vue-3/src/components/icon/MIcon.vue";
import MNotification from "@mozaic-ds/vue-3/src/components/notification/MNotification.vue";
import orderBy from "lodash-es/orderBy";
import { v4 as uuid } from "uuid";

import ViewHelpButton from "@/commons/components/UserDocumentationLinks/ViewHelpButton.vue";

import CreateRoutingPolicy from "./CreateRoutingPolicy.vue";
import UpdateOrRemoveRoutingPolicy from "./UpdateOrRemoveRoutingPolicy.vue";

import { dragOrder } from "@/commons/libs/utils/drag-order";

import { RoutingPolicy } from "@/commons/domain/models/routing-policy";

import contents from "@/manager/contents/dynamic-routing";

export default {
  name: "DynamicRouting",
  components: {
    UpdateOrRemoveRoutingPolicy,
    CreateRoutingPolicy,
    MIcon,
    MNotification,
    ViewHelpButton,
  },
  props: {
    routingPolicies: {
      type: Array,
      default: () => [],
    },
    routeEntryPoints: {
      type: Array,
      default: () => [],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isDisplayDynRoutingNotification: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update", "displayNotification"],
  data() {
    return {
      contents,
      showListRoutingPolicies: false,
      stopDrag: true,
      isAddingNewCondition: false,
    };
  },
  computed: {
    routingPoliciesSortedByOrderList() {
      return orderBy(Object.values(this.routingPolicies), [
        (routingPolicy: RoutingPolicy) => routingPolicy.order,
      ]);
    },
  },
  methods: {
    createOrUpdateDynamicRoutingCondition() {
      this.isAddingNewCondition = true;
      this.displayNotification();
    },
    displayNotification() {
      this.$emit("displayNotification");
    },
    stopDraggable() {
      this.stopDrag = false;
    },
    allowDraggable() {
      this.stopDrag = true;
    },
    dragStart(routingPolicy, event) {
      event.dataTransfer.setData(
        "routingPolicy",
        JSON.stringify(routingPolicy),
      );
    },
    drop(targetRoutingPolicy, event) {
      const draggedRoutingPolicy = JSON.parse(
        event.dataTransfer.getData("routingPolicy"),
      );

      const routingPolicies = dragOrder(
        this.routingPolicies,
        draggedRoutingPolicy.order,
        targetRoutingPolicy.order,
      );

      this.$emit("update", routingPolicies);
    },
    async update(routingPolicy) {
      this.displayNotification();
      const newPolicies = this.routingPolicies.map((policy) => {
        if (policy.id === routingPolicy.id) {
          return routingPolicy;
        }
        return policy;
      });
      this.$emit("update", newPolicies);
    },
    async create({ url, conditions }) {
      const newRoutingPolicy: RoutingPolicy = {
        id: uuid(),
        order: Object.keys(this.routingPolicies).length + 1,
        url,
        conditions,
        escapePath: false,
      };
      this.$emit("update", [...this.routingPolicies, newRoutingPolicy]);
    },
    async remove(routingPolicyId) {
      this.$emit(
        "update",
        this.routingPolicies.filter(
          (routingPolicy) => routingPolicy.id !== routingPolicyId,
        ),
      );
    },
  },
};
</script>

<style lang="scss">
.dynamic-routing__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.2rem 0;
  margin-top: 32px;
  cursor: pointer;
  border: dashed 1px var(--color-light-grey);
}

.dynamic-routing__icon {
  margin-right: 0.5rem;
}

.dynamic-routing--is-disabled {
  .dynamic-routing__title-text {
    color: var(--color-grey);
  }
}

.dynamic-routing__title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dynamic-routing__title-text {
  margin-right: 1rem;
}

.dynamic-routing--is-enabled {
  .dynamic-routing__header:nth-child(2),
  .dynamic-routing__header:nth-child(3) {
    opacity: 0;
  }
}

.dynamic-routing__headers {
  display: flex;
  border-bottom: 2px solid var(--color-light-grey);
}
.dynamic-routing__header {
  font-size: 1rem;
  font-weight: 700;
  color: var(--color-grey);
  text-transform: uppercase;
}
.dynamic-routing__header:nth-child(1),
.dynamic-routing__header:nth-child(2) {
  width: 15%;
}

.dynamic-routing__header:nth-child(3),
.dynamic-routing__header:nth-child(4) {
  width: 30%;
}

.dynamic-routing__header:nth-child(5) {
  width: 10%;
}

.dynamic-routing__notification {
  margin-bottom: 1rem;
}
</style>
