import { EDocumentationType } from "@/commons/domain/models/documentation-page";

export const documentationTypeItems = [
  {
    value: EDocumentationType.MARKDOWN,
    label: "Markdown",
    icon: "PublishEdit64",
  },
  {
    value: EDocumentationType.CHANGELOG,
    label: "Changelog",
    icon: "TimeRead64",
  },
  {
    value: EDocumentationType.REST_API_SPECIFICATIONS,
    label: "Swagger/OpenAPI",
    icon: "BookStore64",
  },
];

export const contents = {
  cancel: "Cancel",
  chooseDocType: "Choose a type",
  next: "Next",
};

export function getTypeLabel(documentationType: EDocumentationType): string {
  const documentationTypeItem = documentationTypeItems.find(
    (documentationTypeItem) =>
      documentationTypeItem.value === documentationType,
  );
  return documentationTypeItem.label;
}
