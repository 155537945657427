import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MIcon = _resolveComponent("MIcon")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent($options.useLinkElement ? 'a' : 'button'), {
    class: _normalizeClass(["icon-button", { 'icon-button--disabled': $props.disabled }]),
    href: $options.useLinkElement ? $props.href : undefined,
    disabled: $props.disabled,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click', $event)))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_MIcon, {
        name: $props.mozaicIconName,
        color: $props.color
      }, null, 8, ["name", "color"])
    ]),
    _: 1
  }, 8, ["class", "href", "disabled"]))
}