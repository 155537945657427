<template>
  <div class="title-with-number">
    <div class="title-with-number__title-number">
      <span class="title-with-number__number">{{ number }}</span>
      <div class="title-with-number__label">
        {{ title }}
      </div>
      <MIcon v-if="helpText" name="NotificationQuestion24" />
    </div>
    <div v-if="description" class="title-with-number__description">
      {{ description }}
    </div>
  </div>
</template>

<script>
import MIcon from "@mozaic-ds/vue-3/src/components/icon/MIcon.vue";

export default {
  name: "TitleWithNumber",
  components: {
    MIcon,
  },
  props: {
    number: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: undefined,
    },
    helpText: {
      type: String,
      default: undefined,
    },
  },
};
</script>

<style lang="scss">
.title-with-number__title-number {
  display: flex;
}
.title-with-number__number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-right: 0.5rem; /* 8px */
  line-height: 1.6; /* center text */
  color: var(--color-white);
  background-color: var(--color-text);
  border-radius: 12px;
}
.title-with-number__label {
  margin-right: 0.625rem; /* 10px */
}
.title-with-number__description {
  margin-top: var(--base-spacing);
  margin-bottom: var(--base-spacing);
}
</style>
