import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "business-unit-deployment"
}
const _hoisted_2 = { class: "business-unit-deployment__deployed" }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconBUD = _resolveComponent("IconBUD")!
  const _component_SvgWithLabel = _resolveComponent("SvgWithLabel")!
  const _component_Chip = _resolveComponent("Chip")!
  const _component_Cluster = _resolveComponent("Cluster")!
  const _component_IconClose = _resolveComponent("IconClose")!
  const _component_Box = _resolveComponent("Box")!

  return ($props.tags.length !== 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Cluster, null, {
          default: _withCtx(() => [
            _createVNode(_component_SvgWithLabel, null, {
              default: _withCtx(() => [
                _createVNode(_component_IconBUD),
                _createElementVNode("span", _hoisted_2, _toDisplayString($data.contents.isUsedBy($props.label)), 1)
              ]),
              _: 1
            }),
            ($props.tags.length <= 3)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($props.tags, (tag) => {
                  return (_openBlock(), _createBlock(_component_Chip, {
                    key: tag.id,
                    "data-cy": "api-business-unit",
                    class: "business-unit-deployment__chip"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(tag.label), 1)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("span", {
                    class: "business-unit-deployment__link",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => ($options.toggleBusinessUnits && $options.toggleBusinessUnits(...args)), ["prevent"]))
                  }, _toDisplayString($data.contents.nbrOfBusinessUnit($props.tags.length)), 1)
                ]))
          ]),
          _: 1
        }),
        ($data.isDisplayBusinessUnit)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "business-unit-deployment__panel",
              onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => ($options.toggleBusinessUnits && $options.toggleBusinessUnits(...args)), ["prevent"]))
            }, [
              _createVNode(_component_Box, { class: "business-unit-deployment__panel-header weight-medium" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($data.contents.deployTitle($props.label)) + " ", 1),
                  _createVNode(_component_IconClose)
                ]),
                _: 1
              }),
              _createVNode(_component_Box, null, {
                default: _withCtx(() => [
                  _createVNode(_component_Cluster, null, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.tags, (tag) => {
                        return (_openBlock(), _createBlock(_component_Chip, {
                          key: tag.id,
                          class: "business-unit-deployment__chip"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(tag.label), 1)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}