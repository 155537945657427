<script setup lang="ts">
import IconButton from "@/commons/components/IconButton.vue";

defineProps({
  label: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["click"]);
</script>

<template>
  <IconButton
    mozaic-icon-name="NotificationInformation24"
    @click="emit('click', $event)"
  />
  {{ label }}
</template>
