import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "card-api-catalog--margin-top-bottom"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardContentApi = _resolveComponent("CardContentApi")!
  const _component_AuthorizationRequired = _resolveComponent("AuthorizationRequired")!
  const _component_Chip = _resolveComponent("Chip")!
  const _component_BusinessUnitDeployment = _resolveComponent("BusinessUnitDeployment")!
  const _component_CardFooterApi = _resolveComponent("CardFooterApi")!
  const _component_CardLink = _resolveComponent("CardLink")!

  return (_openBlock(), _createBlock(_component_CardLink, {
    class: "card-api-catalog",
    to: { name: 'apiDocumentationDetail', params: { id: $props.api.id } }
  }, _createSlots({
    default: _withCtx(() => [
      _createVNode(_component_CardContentApi, {
        name: $options.apiName,
        version: $props.api.version,
        description: $props.api.description,
        "product-name": $options.productName,
        apiType: $props.api.type,
        apiSubtype: $props.api.subtype
      }, null, 8, ["name", "version", "description", "product-name", "apiType", "apiSubtype"])
    ]),
    _: 2
  }, [
    (!$props.hideFooter)
      ? {
          name: "footer",
          fn: _withCtx(() => [
            _createVNode(_component_CardFooterApi, null, {
              default: _withCtx(() => [
                ($options.authorizationRequired)
                  ? (_openBlock(), _createBlock(_component_AuthorizationRequired, {
                      key: 0,
                      class: "card-api-catalog--margin-top-bottom"
                    }))
                  : _createCommentVNode("", true),
                ($options.isDeprecated)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      _createVNode(_component_Chip, null, {
                        default: _withCtx(() => [
                          _createTextVNode("DEPRECATED")
                        ]),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true),
                ($options.isLogged)
                  ? (_openBlock(), _createBlock(_component_BusinessUnitDeployment, {
                      key: 2,
                      tags: $options.buTags,
                      label: $options.businessUnitsLabel,
                      class: "card-api-catalog--margin-top-bottom"
                    }, null, 8, ["tags", "label"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["to"]))
}