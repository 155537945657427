import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "api-deprecation-info-for-navbar" }
const _hoisted_2 = {
  key: 0,
  class: "api-deprecation-info-for-navbar__api-deprecation-date"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MBadge = _resolveComponent("MBadge")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MBadge, { type: "danger" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString($data.contents.deprecated), 1)
      ]),
      _: 1
    }),
    ($props.api.deprecationDate)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($data.contents.since) + " " + _toDisplayString($options.toLocaleDate($props.api.deprecationDate)), 1))
      : _createCommentVNode("", true)
  ]))
}