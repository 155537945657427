import { GroupWithUserAction } from "@/commons/domain/models/group-with-user-action";

import { State } from "./types";

export function userIsLogged(state: State) {
  return !!state.user;
}

export function userIsInternal(state: State, getters) {
  return (getters.user && getters.user.isInternal) || false;
}

export function userHasAcceptedTOS(state: State) {
  return state.user && state.user.hasAcceptedCurrentTermsOfService;
}

export function user(state: State) {
  return state.user;
}

export function userIsAdmin(state: State, getters) {
  return (getters.user && getters.user.isAdmin) || false;
}

export function currentUserGroups(state: State, getters) {
  return getters.user?.groups || {};
}

export function currentUserGroupsList(state: State, getters) {
  return Object.values<GroupWithUserAction>(getters.currentUserGroups).sort(
    (a, b) => a.name.localeCompare(b.name),
  );
}

export default {
  userIsLogged,
  userIsInternal,
  userIsAdmin,
  userHasAcceptedTOS,
  user,
  currentUserGroups,
  currentUserGroupsList,
};
