<template>
  <svg
    class="icon-restriction-public"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="icon-restriction-public"
    viewBox="0 0 24 24"
    height="46"
  >
    <path
      fill="#999"
      d="M22 5.3A12 12 0 0 0 12.3 0h-.8a12 12 0 0 0 0 24h.8a12 12 0 0 0 9.5-18.7zM19.1 5a8 8 0 0 1-2.2 1.3c-.5-1.5-1.3-2.8-2.2-4A10 10 0 0 1 19.1 5zM16 12c0 1.2-.2 2.4-.4 3.5a15.3 15.3 0 0 0-7.1 0 14.6 14.6 0 0 1-.1-6.6 15.3 15.3 0 0 0 7.3 0c.2 1 .3 2 .3 3.1zm-1 5.4a12 12 0 0 1-3 4.3 12 12 0 0 1-3-4.3 13.5 13.5 0 0 1 6 0zM9 7c.6-1.9 1.7-3.5 3-4.7A12 12 0 0 1 15.1 7a13.4 13.4 0 0 1-6.2 0zm.2-4.6c-.9 1.2-1.7 2.5-2.2 4a8 8 0 0 1-2.2-1.3 10 10 0 0 1 4.4-2.7zM2 12c0-2 .6-3.8 1.5-5.3.9.6 1.9 1.2 3 1.6a16.6 16.6 0 0 0 0 7.8c-1 .4-2 1-2.8 1.5A10 10 0 0 1 2 12zm3 7.1L7.2 18c.5 1.3 1.2 2.5 2 3.6A10 10 0 0 1 5 19.1zm9.8 2.5a14 14 0 0 0 2-3.6l2.2 1.1a10 10 0 0 1-4.2 2.5zm5.5-4c-.9-.6-1.8-1-2.8-1.5a16.6 16.6 0 0 0 0-7.8c1.1-.4 2.1-1 3-1.6a10 10 0 0 1-.2 11z"
    />
  </svg>
</template>
