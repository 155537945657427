<template>
  <svg
    version="1.1"
    class="icon-add-input"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="icon-add-input"
    viewBox="0 -3 20 20"
    width="20px"
    height="20px"
  >
    <title lang="en">Add input</title>
    <polygon
      points="15 6 9 6 9 0 6 0 6 6 0 6 0 9 6 9 6 15 9 15 9 9 15 9 15 6"
    />
  </svg>
</template>
