import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "checkboxes-list-filter" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MCheckboxGroup = _resolveComponent("MCheckboxGroup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MCheckboxGroup, {
      legend: $props.label,
      disabled: $props.isLoading,
      options: $props.items,
      modelValue: $props.queryParamValue,
      inline: $props.inline,
      "onUpdate:modelValue": $options.onChange
    }, null, 8, ["legend", "disabled", "options", "modelValue", "inline", "onUpdate:modelValue"])
  ]))
}