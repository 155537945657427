import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Multiselect = _resolveComponent("Multiselect")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["auto-complete-select", [$options.cssFieldElementClass]])
  }, [
    _createVNode(_component_Multiselect, {
      multiple: $props.multiple,
      modelValue: $data.selectedOption,
      options: $props.options,
      trackBy: $props.keyProperty,
      label: $props.labelProperty,
      disabled: $props.disabled,
      placeholder: $props.placeholder,
      selectLabel: "",
      deselectLabel: "",
      selectedLabel: "",
      maxHeight: 200,
      allowEmpty: !$props.required,
      "onUpdate:modelValue": $options.updateSelected,
      onSearchChange: $options.asyncFind
    }, {
      singleLabel: _withCtx(({ option }) => [
        _createTextVNode(_toDisplayString(option.name), 1)
      ]),
      _: 1
    }, 8, ["multiple", "modelValue", "options", "trackBy", "label", "disabled", "placeholder", "allowEmpty", "onUpdate:modelValue", "onSearchChange"])
  ], 2))
}