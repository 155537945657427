import { getDateValue } from "./helpers/get-date-value";

import { DateRange, State } from "./types";

export function interval(state: State) {
  return (state.graph && state.graph.interval) || "";
}

export function hits(state: State) {
  return state.hits;
}

export function visibleApiHits(state: State) {
  const hiddenDatasets = (state.graph && state.graph.hiddenDatasets) || [];
  return state.hits.filter((hit) => !hiddenDatasets.includes(`${hit.status}`));
}

export function visibleApplicationHits(state: State) {
  const hiddenDatasets = (state.graph && state.graph.hiddenDatasets) || [];
  return state.hits.filter(
    (hit) => !hiddenDatasets.includes(`${hit.api.label}`),
  );
}

export function filterOptions(state: State) {
  return state.filterOptions;
}

export function selectedFilters(state: State) {
  return state.selectedFilters;
}

export function selectedDateRange(state: State): DateRange {
  return {
    from: getDateValue(state.selectedFilters.from),
    to: getDateValue(state.selectedFilters.to),
  };
}

export function isFetchingFilters(state: State) {
  return state.isFetchingFilters;
}

export function isFetchingReporting(state: State) {
  return state.isFetchingReporting;
}

export function errorMessage(state: State) {
  return state.errorMessage;
}

export function selectedApisOptions(state: State) {
  return state.filterOptions.apis.filter((api) =>
    state.selectedFilters.apis.includes(api.id),
  );
}

export function selectedStatusOptions(state: State) {
  return state.filterOptions.status.filter((statusCode) =>
    state.selectedFilters.status.includes(statusCode.id),
  );
}

export function selectedMethodsOptions(state: State) {
  return state.filterOptions.methods.filter((method) =>
    state.selectedFilters.methods.includes(method.id),
  );
}

export default {
  interval,
  hits,
  visibleApplicationHits,
  filterOptions,
  selectedFilters,
  selectedDateRange,
  isFetchingFilters,
  isFetchingReporting,
  errorMessage,
  selectedApisOptions,
  selectedStatusOptions,
  selectedMethodsOptions,
};
