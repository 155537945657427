import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "use-case-card" }
const _hoisted_2 = { class: "use-case-card__title" }
const _hoisted_3 = { class: "use-case-card__description" }
const _hoisted_4 = { class: "use-case-card__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MIcon = _resolveComponent("MIcon")!
  const _component_MButton = _resolveComponent("MButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MIcon, {
      name: "MonochromeRelayLocation64",
      color: "var(--color-primary)"
    }),
    _createElementVNode("h2", _hoisted_2, _toDisplayString($props.title), 1),
    _createElementVNode("p", _hoisted_3, _toDisplayString($props.description), 1),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_MButton, {
        label: $data.contents.buttonLabel,
        onClick: _cache[0] || (_cache[0] = () => _ctx.$router.push({ path: $props.path }))
      }, null, 8, ["label"])
    ])
  ]))
}