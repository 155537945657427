<template>
  <div class="text-area">
    <label :for="id">{{ label }}</label>
    <textarea
      :id="id"
      :class="[
        developerMode && 'developer-mode',
        error && 'text-area__with-error',
      ]"
      :disabled="disabled"
      :placeholder="placeholder"
      :rows="rows"
      :cols="cols"
      :value="modelValue"
      :spellcheck="spellcheck"
      @input="$emit('update:modelValue', $event.target.value)"
    ></textarea>
    <span v-if="error" class="text-area__error">{{ error }}</span>
  </div>
</template>

<script lang="ts">
export default {
  name: "TextArea",
  props: {
    cols: {
      type: Number,
      default: 50,
    },
    developerMode: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    rows: {
      type: Number,
      default: 2,
    },
    modelValue: {
      type: String,
      default: null,
    },
    spellcheck: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    id() {
      return this.$.uid;
    },
  },
};
</script>

<style scoped>
.text-area {
  width: 100%;
}
textarea {
  box-sizing: border-box;
  width: 100%;
  padding: 12px 20px;
  font-size: var(--size-400);
  color: var(--color-text);
  resize: var(--text-area-resize, both);
  border: var(--border-default);
  border-radius: 3px;
  outline: none;
}
textarea:disabled {
  background-color: var(--color-background-interface);
}
textarea:disabled:hover {
  border-color: var(--color-light-grey);
}
label {
  margin-bottom: 12px;
  font-size: var(--size-400);
  color: var(--color-text);
}

.text-area .developer-mode {
  font-family: revert;
  line-height: 1.5;
}

.text-area__error {
  color: var(--color-danger);
}

.text-area__with-error {
  border-color: var(--color-danger);
}
</style>
