<template>
  <form
    autocomplete="false | random-string"
    class="tos"
    @submit.prevent="confirm"
  >
    <Stack>
      <div class="tos__content-body" v-html="termsOfUse()" />

      <div class="pushed-right">
        <button class="btn btn-primary btn-lg" type="submit">
          I accept the T&Cs
        </button>
      </div>
    </Stack>
  </form>
</template>

<script lang="ts">
import Stack from "@/commons/components/Stack.vue";

import getTermsOfUse from "@/commons/libs/get-terms-of-use";

export default {
  name: "TOS",
  components: { Stack },
  computed: {
    locationSettings() {
      return this.$store.getters["config/locationSettings"];
    },
  },
  methods: {
    confirm() {
      this.$store.dispatch("acceptTOS");
    },
    termsOfUse() {
      return getTermsOfUse(this.locationSettings);
    },
  },
};
</script>
