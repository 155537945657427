import { AGGREGATION_TO_FILTER_MAP } from "@/commons/store/modules/reporting/constants";

import { buildFilterOptions } from "@/commons/store/modules/reporting/helpers/build-filter-options";
import { removeNonExistingOptions } from "@/commons/store/modules/reporting/helpers/remove-non-existing-options";

import {
  FetchFilterOptionsResponse,
  State,
} from "@/commons/store/modules/reporting/types";

export function fetchReportingFiltersOptions(state: State) {
  state.isFetchingFilters = true;
  state.errorMessage = null;
}

export function fetchReportingFiltersOptionsSuccess(
  state: State,
  { aggregations }: FetchFilterOptionsResponse,
) {
  for (const aggregationName in aggregations) {
    const filterName = AGGREGATION_TO_FILTER_MAP[aggregationName];
    state.filterOptions[filterName] = buildFilterOptions(
      aggregationName,
      aggregations[aggregationName],
    );
    state.selectedFilters[filterName] = removeNonExistingOptions(
      state.selectedFilters[filterName],
      state.filterOptions[filterName],
    );
  }
  state.isFetchingFilters = false;
}

export function fetchReportingFiltersOptionsFail(state: State, error: Error) {
  if (error && error.message) {
    state.errorMessage = error.message;
  }
  state.filterOptions.applications = [];
  state.filterOptions.status = [];
  state.filterOptions.methods = [];
  state.filterOptions.zones = [];
  state.isFetchingFilters = false;
}

export default {
  fetchReportingFiltersOptions,
  fetchReportingFiltersOptionsSuccess,
  fetchReportingFiltersOptionsFail,
};
