<template>
  <div class="conditions">
    <!-- List of conditions -->
    <div
      v-for="condition in value"
      :key="`${condition.id}-${condition.type}`"
      class="conditions__condition-row"
    >
      <!-- Type selector -->
      <MSelect
        id="conditions__condition-type-select"
        class="conditions__condition-type-select"
        :disabled="isDisabled"
        :options="conditionTypeOptions"
        :modelValue="condition.type"
        @update:modelValue="updateConditionType(condition, $event)"
      />

      <MTextInput
        v-if="condition.type === ERoutingPolicyType.HEADERS"
        :placeholder="getKeyPlaceholder(condition.type)"
        :disabled="isDisabled"
        :modelValue="condition.key"
        @update:modelValue="
          updateCondition({
            ...condition,
            key: $event,
          })
        "
      />

      <MTextInput
        v-if="
          [ERoutingPolicyType.PATH, ERoutingPolicyType.HEADERS].includes(
            condition.type,
          )
        "
        :placeholder="getValuePlaceholder(condition.type)"
        :disabled="isDisabled"
        :modelValue="condition.value"
        @update:modelValue="
          updateCondition({
            ...condition,
            value: $event,
          })
        "
      />

      <!-- Methods value -->
      <MDropdown
        v-if="condition.type === ERoutingPolicyType.HTTP_METHODS"
        class="conditions__http-methods-select"
        data-cy="condition-http-methods"
        :modelValue="condition.value"
        :placeholder="getValuePlaceholder(condition.type)"
        :items="httpMethodItems"
        multiple
        :disabled="isDisabled"
        @update:modelValue="updateCondition({ ...condition, value: $event })"
      />

      <!-- Remove button -->
      <IconButton
        v-if="!isDisabled"
        class="conditions__icon"
        mozaicIconName="PublishTrashbin24"
        @click="removeCondition(condition.id)"
      />
    </div>

    <p v-if="formHasErrorOnConditions" class="conditions__errors">
      {{ errors[0].messages[0] }}
    </p>

    <!-- Add condition -->
    <div v-if="!isDisabled" class="conditions__condition-row">
      <MSelect
        v-if="isAddingNewCondition"
        id="conditions__add-condition-type-select"
        class="conditions__add-condition-type-select"
        :disabled="isDisabled"
        :placeholder="contents.selectCondition"
        :options="conditionTypeOptions"
        @update:modelValue="addCondition"
      />

      <MButton
        v-else
        class="conditions__add-condition-button"
        theme="bordered"
        :label="contents.combineWithAnotherCondition"
        icon="ControlCircleMore24"
        @click="isAddingNewCondition = true"
      />
    </div>
  </div>
</template>

<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import MDropdown from "@mozaic-ds/vue-3/src/components/dropdown/MDropdown.vue";
import MSelect from "@mozaic-ds/vue-3/src/components/select/MSelect.vue";
import MTextInput from "@mozaic-ds/vue-3/src/components/textinput/MTextInput.vue";
import { v4 as uuid } from "uuid";
import { PropType } from "vue";

import IconButton from "@/commons/components/IconButton.vue";

import { EHttpMethod, ORDERED_HTTP_METHODS } from "@/commons/utils/http-method";

import { Api } from "@/commons/domain/models/api";
import { EntryPoint } from "@/commons/domain/models/entry-point";
import { MDropdownItem, MSelectOption } from "@/commons/domain/models/mozaic";
import { RoutingPolicyCondition } from "@/commons/domain/models/routing-policy-condition";
import { Zone } from "@/commons/domain/models/zone";

import { ERoutingPolicyType } from "@/commons/store/types";

import contents from "@/manager/contents/conditions";

export default {
  components: {
    MSelect,
    MButton,
    MTextInput,
    MDropdown,
    IconButton,
  },
  props: {
    value: {
      type: Array as PropType<RoutingPolicyCondition[]>,
      default: () => [],
    },
    isDisabled: {
      type: Boolean,
    },
    errors: {
      type: Array as PropType<any[]>,
      default: undefined,
    },
    routeEntryPoints: {
      type: Array as PropType<EntryPoint[]>,
      default: () => [],
    },
  },
  emits: ["input"],
  data() {
    return {
      isAddingNewCondition: false,
      contents,
      ERoutingPolicyType,
    };
  },
  computed: {
    currentApi(): Api {
      return this.$store.getters["currentApi"];
    },
    zones(): Zone[] {
      return this.$store.getters["zones"];
    },
    conditionTypeOptions(): MSelectOption[] {
      const options = [
        this.buildConditionTypeOption(ERoutingPolicyType.HEADERS),
        this.buildConditionTypeOption(ERoutingPolicyType.HTTP_METHODS),
        this.buildConditionTypeOption(ERoutingPolicyType.PATH),
      ];

      return options;
    },
    httpMethodItems(): MDropdownItem[] {
      const methods = ORDERED_HTTP_METHODS.map((method) => ({
        label: method,
        value: method,
      }));
      return methods.filter(
        (method) =>
          this.currentApi.allowedActions[
            "routing.method." + method.label.toLowerCase()
          ],
      );
    },
    formHasErrorOnConditions(): boolean {
      return this.errors?.[0]?.property === "conditions";
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler() {
        this.isAddingNewCondition = this.value.length === 0;
      },
    },
  },
  methods: {
    addCondition(type): void {
      this.$emit("input", [...this.value, this.buildCondition(uuid(), type)]);
      this.isAddingNewCondition = false;
    },
    removeCondition(conditionId: string): void {
      this.$emit(
        "input",
        this.value.filter((condition) => condition.id !== conditionId),
      );
    },
    updateCondition(condition: RoutingPolicyCondition): void {
      const newConditions = this.value.map((originalCondition) => {
        if (originalCondition.id === condition.id) {
          return condition;
        }
        return originalCondition;
      });
      this.$emit("input", newConditions);
    },
    updateConditionType(
      condition: RoutingPolicyCondition,
      newconditionType: ERoutingPolicyType,
    ): void {
      // We want value and key to be coherent with the selected type so when changing type we reinit the condition
      const conditionWithResetValues = this.buildCondition(
        condition.id,
        newconditionType,
      );
      this.updateCondition(conditionWithResetValues);
    },
    buildCondition(
      conditionId: string,
      conditionType: ERoutingPolicyType,
    ): RoutingPolicyCondition {
      return {
        id: conditionId,
        type: conditionType,
        key: "",
        value: this.buildInitialValueOfCondition(conditionType),
      };
    },
    buildInitialValueOfCondition(
      conditionType: ERoutingPolicyType,
    ): string | EHttpMethod[] {
      return conditionType === ERoutingPolicyType.HTTP_METHODS ? [] : "";
    },
    buildConditionTypeOption(conditionType: ERoutingPolicyType): MSelectOption {
      return {
        text: contents.conditionTypeLabels[conditionType].option,
        value: conditionType,
      };
    },
    getKeyPlaceholder(conditionType: ERoutingPolicyType): string {
      return contents.conditionTypeLabels[conditionType].keyPlaceholder;
    },
    getValuePlaceholder(conditionType: ERoutingPolicyType): string {
      return contents.conditionTypeLabels[conditionType].valuePlaceholder;
    },
  },
};
</script>

<style lang="scss">
.conditions {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.conditions__condition-row {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-right: 1rem;
}

.conditions__condition-type-select {
  max-width: 10rem;
}

.conditions__errors {
  color: var(--color-danger);
}
</style>
