<template>
  <tr
    :class="['update-or-remove-custom-query-param', { disabled: isDisabled }]"
  >
    <td>
      <FormInput :error="form.firstError('name')">
        <TextInput
          v-model="form.name"
          reference="inputCustomQueryParamName"
          :placeholder="contents.namePlaceholder"
          :isDisabled="!isEditing"
        />
      </FormInput>
    </td>
    <td>
      <FormInput :error="form.firstError('value')">
        <TextInput
          v-model="form.value"
          :placeholder="contents.valuePlaceholder"
          :isDisabled="!isEditing"
        />
      </FormInput>
    </td>
    <td class="text-centered">
      <!-- update and cancel buttons -->
      <template v-if="isEditing">
        <CancelAndSubmitButtons @cancel="cancel" @submit="update" />
      </template>

      <!-- Edit and remove buttons -->
      <template v-else>
        <ActionButtonsWrapper>
          <ButtonEdit @click="edit" />
          <ButtonDelete @click="remove" />
        </ActionButtonsWrapper>
      </template>
    </td>
  </tr>
</template>

<script lang="ts">
import ActionButtonsWrapper from "@/commons/components/Buttons/ActionButtonsWrapper.vue";
import ButtonDelete from "@/commons/components/Buttons/ButtonDelete.vue";
import ButtonEdit from "@/commons/components/Buttons/ButtonEdit.vue";
import CancelAndSubmitButtons from "@/commons/components/Buttons/CancelAndSubmitButtons.vue";
import FormInput from "@/commons/components/FormInput.vue";
import TextInput from "@/commons/components/TextInput.vue";

import contents from "@/manager/contents/update-or-remove-custom-query-param";

export default {
  name: "UpdateOrRemoveCustomQueryParam",
  components: {
    ButtonDelete,
    ButtonEdit,
    ActionButtonsWrapper,
    TextInput,
    FormInput,
    CancelAndSubmitButtons,
  },
  props: {
    formParent: {
      type: Object,
      required: true,
    },
    form: {
      type: Object,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update", "remove"],
  data() {
    return {
      contents,
      isEditing: false,
      persistedCustomQueryParam: null,
    };
  },
  methods: {
    async update() {
      await this.form.validate();
      await this.formParent.validate();

      if (!this.form.errors.length && !this.formParent.errors.length) {
        const customQueryParam = this.form.data();
        this.$emit("update", customQueryParam);
        this.persistedCustomQueryParam = customQueryParam;
        this.isEditing = false;
      }
    },
    async cancel() {
      await this.form.init(this.persistedCustomQueryParam);
      await this.formParent.validate();
      this.isEditing = false;
    },
    async remove() {
      if (!this.isDisabled) {
        const result = await this.$swal({
          titleText: this.contents.confirmRemoveMessage,
        });
        if (result.isConfirmed) {
          this.$emit("remove", this.form.id);
        }
      }
    },
    edit() {
      if (!this.isDisabled) {
        this.persistedCustomQueryParam = this.form.data();
        this.isEditing = true;
      }
    },
  },
};
</script>
