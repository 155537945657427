import Pageable from "./Pageable";

export default {
  install: (vueApp) => {
    vueApp.directive("slides", {
      mounted: (el, binding) => {
        var opts = binding.value || {};

        el.$slides = new Pageable(el, opts);
      },
      unmounted: (el) => {
        el.$slides.destroy();
      },
    });
  },
};
