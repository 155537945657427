<template>
  <LayerModalFormConfirm
    class="subscribable-apis-modal"
    confirmBtnIsHidden
    @close="$emit('onClose')"
  >
    <h4 class="subscribable-apis-modal__title">
      {{ `${contents.availableApiTitle} (${nbrOfAvailableApis})` }}
    </h4>
    <MTextInput
      data-cy="subscribable-apis-search-input"
      type="search"
      icon="DisplaySearch32"
      :placeholder="contents.searchTermPlaceholder"
      :modelValue="apiSearchTerm"
      @update:modelValue="debounceChangeApiSearchTerm($event)"
    />
    <div class="subscribable-apis-modal__filters">
      <div class="subscribable-apis-modal__authentication-filter">
        <label class="subscribable-apis-modal__authentication-filter-legend">{{
          contents.authenticationFilterLegend
        }}</label>
        <MCheckboxGroup
          :disabled="isLoading"
          :options="authenticationTypeOptions"
          :modelValue="selectedAuthenticationTypes"
          inline
          @update:modelValue="selectAuthenticationTypes"
        />
      </div>
      <MPagination
        v-if="subscribableApisTotalPages > 0"
        class="subscribable-apis-modal__pagination"
        :length="subscribableApisTotalPages"
        :value="currentPage"
        @on-update-page="changePage"
      />
    </div>
    <SubscribableApiCardList
      class="subscribable-apis-modal__api-card-list"
      :apisList="subscribableApisList"
    />
  </LayerModalFormConfirm>
</template>

<script lang="ts">
import MCheckboxGroup from "@mozaic-ds/vue-3/src/components/checkbox/MCheckboxGroup.vue";
import MPagination from "@mozaic-ds/vue-3/src/components/pagination/MPagination.vue";
import MTextInput from "@mozaic-ds/vue-3/src/components/textinput/MTextInput.vue";

import LayerModalFormConfirm from "@/commons/components/form/LayerModalFormConfirm.vue";
import SubscribableApiCardList from "@/dashboard/views/AppDetailsContracts/SubscribableApiCardList.vue";

import { debounce } from "@/commons/libs/utils/debouncer";
import { getApiRepository } from "@/commons/repositories/libs/get-api-repository";
import { flagLoadingProcess } from "@/commons/utils/flagProcess.utils";

import { Api } from "@/commons/domain/models/api";
import { Application } from "@/commons/domain/models/application";
import { MCheckboxGroupOption } from "@/commons/domain/models/mozaic";
import { PagedResource } from "@/commons/domain/models/paged-resource";
import { ApisFetchParams } from "@/commons/domain/repositories/api-repository";

import { EApiType, EAuthType } from "@/commons/store/types";

import contents from "@/dashboard/contents/subscribable-apis-modal";

export default {
  components: {
    LayerModalFormConfirm,
    SubscribableApiCardList,
    MTextInput,
    MPagination,
    MCheckboxGroup,
  },
  emits: ["onClose"],
  data() {
    return {
      contents,
      apiSearchTerm: "",
      selectedAuthenticationTypes: [] as EAuthType[],
      pagedSubscribableApis: undefined as PagedResource<Api>,
    };
  },
  computed: {
    isLoading(): boolean {
      return this.$store.getters.isLoading;
    },
    currentApplication(): Application {
      return this.$store.getters["currentApplication"];
    },
    nbrOfAvailableApis(): number {
      return this.pagedSubscribableApis?.totalCount;
    },
    subscribableApisTotalPages(): number {
      return this.pagedSubscribableApis?.totalPages;
    },
    currentPage(): number {
      return this.pagedSubscribableApis?.currentPage;
    },
    authenticationTypeOptions(): MCheckboxGroupOption[] {
      return [
        {
          id: `api-type-filter-${EAuthType.API_KEY}`,
          label: contents.authenticationFilterOptionLabel[EAuthType.API_KEY],
          value: EAuthType.API_KEY,
        },
        {
          id: `api-type-filter-${EAuthType.OAUTH}`,
          label: contents.authenticationFilterOptionLabel[EAuthType.OAUTH],
          value: EAuthType.OAUTH,
        },
      ];
    },
    subscribableApisList(): Api[] {
      return this.pagedSubscribableApis
        ? Object.values(this.pagedSubscribableApis.data)
        : [];
    },
  },
  watch: {
    apiSearchTerm() {
      this.loadSubscribableApisPage(1);
    },
    selectedAuthenticationTypes: {
      handler() {
        this.loadSubscribableApisPage(1);
      },
      deep: true,
    },
  },
  created() {
    this.debounceChangeApiSearchTerm = debounce(this.changeApiSearchTerm);
    this.loadSubscribableApisPage(1);
  },
  methods: {
    loadSubscribableApisPage(page: number): void {
      this.loadSubscribableApis({
        term: this.apiSearchTerm,
        authTypes: this.selectedAuthenticationTypes,
        page,
      });
    },
    loadSubscribableApis({
      term,
      authTypes,
      page,
    }: {
      term: string;
      authTypes: EAuthType[];
      page: number;
    }): void {
      flagLoadingProcess("subscribableApis", async () => {
        this.pagedSubscribableApis = await getApiRepository().cancelAndFind(
          this.buildFetchParams({ term, authTypes, page }),
        );
      });
    },
    buildFetchParams({
      term,
      authTypes,
      page,
    }: {
      term: string;
      authTypes: EAuthType[];
      page: number;
    }): ApisFetchParams {
      return {
        page: page,
        size: 5,
        deprecated: false,
        published: true,
        sortBy: "name",
        subscribableBy: this.currentApplication.id,
        types: [EApiType.API_REST], // we don't have implemented subscription for TOPIC
        authTypes: authTypes,
        name: term != "" ? term : undefined,
      };
    },
    changeApiSearchTerm(term: string): void {
      this.apiSearchTerm = term;
    },
    changePage(newPage: number, oldPage: number): void {
      if (newPage !== oldPage) {
        this.loadSubscribableApisPage(newPage);
      }
    },
    selectAuthenticationTypes(selectedTypes: EAuthType[]): void {
      this.selectedAuthenticationTypes = selectedTypes;
    },
  },
};
</script>

<style lang="scss">
.subscribable-apis-modal {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.subscribable-apis-modal__title {
  margin: 0;
}

.subscribable-apis-modal__filters {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}

.subscribable-apis-modal__authentication-filter {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.subscribable-apis-modal__authentication-filter-legend {
  font-weight: var(--weight-bold);
}

.subscribable-apis-modal__api-card-list {
  flex-basis: 0;
  flex-grow: 1;
  padding-right: 0.25rem; // add some space for the scrollbar
  padding-bottom: 1px; // on some resolution, the border bottom of the last card gets cropped
  overflow-y: auto;
}
</style>
