import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "update-or-remove-routing-policy__wrapper-conditions--disabled" }
const _hoisted_3 = { class: "update-or-remove-routing-policy__conditions--disabled" }
const _hoisted_4 = { class: "update-or-remove-routing-policy__condition-type--disabled" }
const _hoisted_5 = { class: "update-or-remove-routing-policy__condition-key--disabled" }
const _hoisted_6 = { class: "update-or-remove-routing-policy__condition-value--disabled" }
const _hoisted_7 = { class: "update-or-remove-routing-policy__url--disabled" }
const _hoisted_8 = {
  key: 0,
  class: "update-or-remove-routing-policy__actions--disabled"
}
const _hoisted_9 = {
  key: 1,
  class: "update-or-remove-routing-policy__conditions"
}
const _hoisted_10 = {
  key: 2,
  class: "update-or-remove-routing-policy__target-url"
}
const _hoisted_11 = { class: "update-or-remove-routing-policy__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonEdit = _resolveComponent("ButtonEdit")!
  const _component_ButtonDelete = _resolveComponent("ButtonDelete")!
  const _component_ActionButtonsWrapper = _resolveComponent("ActionButtonsWrapper")!
  const _component_Conditions = _resolveComponent("Conditions")!
  const _component_MTextInput = _resolveComponent("MTextInput")!
  const _component_DvpField = _resolveComponent("DvpField")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_MIcon = _resolveComponent("MIcon")!

  return ($data.form)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass([
      'update-or-remove-routing-policy',
      { 'update-or-remove-routing-policy--enabled': $data.isUpdating },
    ])
      }, [
        (!$data.isUpdating)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.form.conditions, (condition) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: condition.id,
                      class: "update-or-remove-routing-policy__condition--disabled"
                    }, [
                      _createElementVNode("div", _hoisted_4, _toDisplayString($options.conditionsLabels(condition.type)), 1),
                      _createElementVNode("div", _hoisted_5, _toDisplayString(condition.key ? condition.key : "-"), 1),
                      _createElementVNode("div", _hoisted_6, _toDisplayString($options.getConditionValue(condition)), 1)
                    ]))
                  }), 128))
                ]),
                _createElementVNode("div", _hoisted_7, _toDisplayString($options.url), 1),
                (!$props.isDisabled)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createVNode(_component_ActionButtonsWrapper, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ButtonEdit, { onClick: $options.edit }, null, 8, ["onClick"]),
                          _createVNode(_component_ButtonDelete, { onClick: $options.remove }, null, 8, ["onClick"])
                        ]),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true),
        ($data.isUpdating)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createVNode(_component_Conditions, {
                value: $data.form.conditions,
                errors: $data.form.errors,
                routeEntryPoints: $props.routeEntryPoints,
                onInput: _cache[0] || (_cache[0] = ($event: any) => ($data.form.conditions = $event))
              }, null, 8, ["value", "errors", "routeEntryPoints"])
            ]))
          : _createCommentVNode("", true),
        ($data.isUpdating)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createVNode(_component_Tooltip, {
                class: "update-or-remove-routing-policy__target-url-tooltip",
                tooltipContent: $options.url
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DvpField, { errorMessage: $data.urlValidationError }, {
                    default: _withCtx(() => [
                      _createVNode(_component_MTextInput, {
                        modelValue: $options.url,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($options.url) = $event)),
                        reference: "inputTargetUrl",
                        placeholder: "Target URL",
                        isDisabled: !$data.isUpdating,
                        isInvalid: $data.urlValidationError != undefined
                      }, null, 8, ["modelValue", "isDisabled", "isInvalid"])
                    ]),
                    _: 1
                  }, 8, ["errorMessage"])
                ]),
                _: 1
              }, 8, ["tooltipContent"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_11, [
          ($data.isUpdating)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("span", {
                  class: "update-or-remove-routing-policy__action-cancel",
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($options.cancel && $options.cancel(...args)))
                }, [
                  _createVNode(_component_MIcon, {
                    name: "ControlCircleCross24",
                    color: "var(--color-danger)"
                  })
                ]),
                _createElementVNode("span", {
                  class: "update-or-remove-routing-policy__action-update",
                  "data-cy": "save-dynamic-routing",
                  onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => ($options.update && $options.update(...args)))
                }, [
                  _createVNode(_component_MIcon, {
                    name: "NotificationCircleAvailable24",
                    color: "var(--color-primary)"
                  })
                ])
              ], 64))
            : _createCommentVNode("", true)
        ])
      ], 2))
    : _createCommentVNode("", true)
}