<template>
  <ConfirmModalBody
    class="quota-type-change-modal"
    :confirmBtnLabel="contents.confirm"
    @onSubmit="$emit('onSubmit')"
    @onClose="$emit('onClose')"
  >
    <h5>{{ title }}</h5>
    <Markdown :content="subtitle" />
  </ConfirmModalBody>
</template>

<script lang="ts">
import { PropType } from "vue";

import ConfirmModalBody from "@/commons/components/Modal/ConfirmModalBody.vue";
import Markdown from "@/documentation/components/commons/Markdown.vue";

import { EQuotaType } from "@/commons/store/types";

import contents from "@/manager/contents/quota-type-change-modal";

export default {
  components: {
    ConfirmModalBody,
    Markdown,
  },
  props: {
    newQuotaType: {
      type: String as PropType<EQuotaType>,
      required: true,
    },
  },
  emits: ["onSubmit", "onClose"],
  data() {
    return {
      contents,
    };
  },
  computed: {
    isChangingToConsumerQuota(): boolean {
      return this.quotaType === EQuotaType.CONSUMERS_QUOTA;
    },
    title(): string {
      return this.isChangingToConsumerQuota
        ? this.contents.titleForChangeToConsumersQuota
        : this.contents.titleForChangeToApiQuota;
    },
    subtitle(): string {
      return this.isChangingToConsumerQuota
        ? this.contents.subtitleForChangeToConsumersQuota
        : this.contents.subtitleForChangeToApiQuota;
    },
  },
};
</script>
