<script setup lang="ts">
import { PropType } from "vue";

import LayerModalFormConfirm from "@/commons/components/form/LayerModalFormConfirm.vue";

import { Application } from "@/commons/domain/models/application";

import contentsModal from "@/commons/contents/modal";
import contents from "@/manager/contents/consumer-ips-modal";

defineProps({
  application: {
    type: Object as PropType<Application>,
    required: true,
  },
});

const emit = defineEmits(["onClose"]);
</script>

<template>
  <LayerModalFormConfirm
    class="consumers-ips-modal"
    :cancelBtnLabel="contentsModal.closeBtn"
    cancelBtnTheme="solid"
    confirmBtnIsHidden
    @close="emit('onClose')"
  >
    <div>
      <h5>{{ contents.title(application?.name) }}</h5>
      <span class="consumers-ips-modal__subtitle">
        {{ contents.subtitle }}</span
      >
    </div>

    <div class="consumers-ips-modal__list">
      <div
        v-for="item in application?.ipFiltering?.ipRanges"
        :key="item.id"
        class="consumers-ips-modal__list-item"
      >
        <span class="consumers-ips-modal__list-item-address">{{
          item.address
        }}</span>
        <span
          v-if="item.comment != undefined"
          class="consumers-ips-modal__list-item-comment"
          >{{ item.comment }}</span
        >
      </div>
    </div>
  </LayerModalFormConfirm>
</template>

<style lang="scss">
.consumers-ips-modal {
  display: flex;
  gap: 1rem;
}

.consumers-ips-modal__subtitle {
  @include set-text-s;
}
.consumers-ips-modal__list-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 4.5rem;

  padding: 0.75rem 1rem;

  border-bottom: 1px solid var(--color-stroke-light);
}

.consumers-ips-modal__list {
  display: flex;
  flex-direction: column;
}

.consumers-ips-modal__list-item-address {
  @include set-text-s;
}

.consumers-ips-modal__list-item-comment {
  @include set-text-s;
  color: var(--color-text-minor);
}
</style>
