import { FormData } from "@/commons/domain/forms/form-data";
import { FormFactory } from "@/commons/domain/forms/form-factory";
import { URLPattern } from "@/manager/domain/validation/url-pattern-validator";

export class UrlForm extends FormData {
  @URLPattern()
  url: string;

  $clear() {
    this.url = "";
  }

  static create() {
    return FormFactory.createForm(new UrlForm());
  }
}
