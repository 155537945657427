import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0912f686"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "layout__wrapper" }
const _hoisted_2 = { class: "layout__panel" }
const _hoisted_3 = {
  key: 0,
  class: "layout__sidebar"
}
const _hoisted_4 = {
  key: 0,
  class: "layout__side-menu-header"
}
const _hoisted_5 = { class: "layout__side-menu" }
const _hoisted_6 = { class: "layout__content-wrapper" }
const _hoisted_7 = { class: "layout__footer" }
const _hoisted_8 = { class: "layout__extra-panel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopNavbar = _resolveComponent("TopNavbar")!
  const _component_Box = _resolveComponent("Box")!
  const _component_OverlayLoader = _resolveComponent("OverlayLoader")!
  const _component_WithFixedHeader = _resolveComponent("WithFixedHeader")!

  return (_openBlock(), _createBlock(_component_WithFixedHeader, {
    class: _normalizeClass([
      'layout',
      { 'layout--with-secondary-navbar': $options.hasSecondaryNavbar },
      { 'layout--with-side-menu': $options.hasSideMenu },
      { 'layout--with-side-menu-header': $options.hasSideMenuHeader },
      { 'layout--with-extra-panel': $options.hasExtraPanel },
      { 'layout--extra-panel-collapsed': $props.isCollapsedExtraPanel },
    ])
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass([
        'layout__navbars',
        { 'layout__navbars--highlighted': !$data.isBegginingOfScreen },
      ])
      }, [
        _createVNode(_component_TopNavbar),
        _renderSlot(_ctx.$slots, "secondaryNavbar", {}, undefined, true)
      ], 2),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          ($options.hasSideMenu && !$props.hideSideMenu)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                ($options.hasSideMenuHeader)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _renderSlot(_ctx.$slots, "sideMenuHeader", {}, undefined, true)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_5, [
                  _renderSlot(_ctx.$slots, "sideMenu", {}, undefined, true)
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_6, [
            _renderSlot(_ctx.$slots, "beforeContent", {}, undefined, true),
            _createVNode(_component_Box, { class: "layout__content" }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ]),
              _: 3
            }),
            _createElementVNode("div", _hoisted_7, [
              _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _renderSlot(_ctx.$slots, "extraPanel", {}, undefined, true)
        ])
      ]),
      (!$props.hideLoader && $options.isLoadingAnything)
        ? (_openBlock(), _createBlock(_component_OverlayLoader, {
            key: 0,
            class: "layout__overlay-loader"
          }))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["class"]))
}