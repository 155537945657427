<template>
  <Layout2 class="admin-settings">
    <LayoutSection background="primary" noVerticalPadding>
      <MTabsV2
        :tabs="tabsHandler.items"
        :modelValue="tabsHandler.activeIndex"
      />
    </LayoutSection>
    <RouterView />
  </Layout2>
</template>

<script setup lang="ts">
import MTabsV2 from "@mozaic-ds/vue-3/src/components/tabs/MTabsV2.vue";
import { computed, reactive } from "vue";

import Layout2 from "@/commons/components/Layout2.vue";
import LayoutSection from "@/commons/components/LayoutSection.vue";

import { convertRouteToHref } from "@/commons/utils/route-utils";

import contents from "@/admin/contents/admin-settings";

const props = defineProps({
  menuId: {
    type: String,
    default: "",
  },
});

const tabsHandler = reactive({
  items: [
    {
      label: contents.manageGroups,
      menuId: "manageGroups",
      href: convertRouteToHref({ name: "adminManageGroups" }),
    },
    {
      label: contents.gatewaySynchronization,
      menuId: "gatewaySynchronization",
      href: convertRouteToHref({ name: "adminGatewaySynchronization" }),
    },
    {
      label: contents.batchesManagement,
      menuId: "batchesManagement",
      href: convertRouteToHref({ name: "adminBatchesManagement" }),
    },
  ],
  activeIndex: computed(() => {
    const index = tabsHandler.items.findIndex(
      (tab) => tab.menuId === props.menuId,
    );
    return index >= 0 ? index : 0;
  }),
});
</script>
