import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "contract-warning" }
const _hoisted_2 = { class: "contract-warning__title" }
const _hoisted_3 = { class: "contract-warning__title-text" }
const _hoisted_4 = {
  key: 0,
  class: "contract-warning__message"
}
const _hoisted_5 = {
  key: 1,
  class: "contract-warning__message"
}
const _hoisted_6 = {
  key: 2,
  class: "contract-warning__custom-section"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MIcon = _resolveComponent("MIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_MIcon, {
        class: "contract-warning__title-icon",
        name: $props.iconName
      }, null, 8, ["name"]),
      _createElementVNode("span", _hoisted_3, _toDisplayString($props.title), 1)
    ]),
    ($props.subtitle != undefined)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString($props.subtitle), 1))
      : _createCommentVNode("", true),
    ($props.message != undefined)
      ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString($props.message), 1))
      : _createCommentVNode("", true),
    (_ctx.$slots.default != undefined)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _renderSlot(_ctx.$slots, "default")
        ]))
      : _createCommentVNode("", true)
  ]))
}