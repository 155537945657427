import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "gateway-label-symbols"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "gateway-label-symbols__zone-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.zone && $props.zone.name)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("img", {
          src: $options.datacenterIcon,
          class: "gateway-label-symbols__icon"
        }, null, 8, _hoisted_2),
        _createElementVNode("img", {
          src: $options.zoneFlag,
          class: "gateway-label-symbols__icon"
        }, null, 8, _hoisted_3),
        _createElementVNode("span", _hoisted_4, _toDisplayString($props.zone.name), 1)
      ]))
    : _createCommentVNode("", true)
}