import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuIcon = _resolveComponent("MenuIcon")!
  const _component_MenuLabel = _resolveComponent("MenuLabel")!

  return (_openBlock(), _createBlock(_component_MenuLabel, { urlOrRoute: { name: 'requestManager' } }, {
    default: _withCtx(() => [
      _createVNode(_component_MenuIcon, {
        withNotifications: $options.numberOfRequestPending > 0,
        mIconName: "NotificationBell32"
      }, null, 8, ["withNotifications"])
    ]),
    _: 1
  }))
}