import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "group-edition-modal__selected-group-manager"
}
const _hoisted_2 = { class: "group-edition-modal__selected-group-manager-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MTextInput = _resolveComponent("MTextInput")!
  const _component_DvpField = _resolveComponent("DvpField")!
  const _component_MTextArea = _resolveComponent("MTextArea")!
  const _component_MIcon = _resolveComponent("MIcon")!
  const _component_IconButton = _resolveComponent("IconButton")!
  const _component_AddUser = _resolveComponent("AddUser")!
  const _component_NotificationWarningMCCGroup = _resolveComponent("NotificationWarningMCCGroup")!
  const _component_LayerModalFormConfirm = _resolveComponent("LayerModalFormConfirm")!

  return (_openBlock(), _createBlock(_component_LayerModalFormConfirm, {
    class: "group-edition-modal",
    confirmBtnTheme: "solid",
    disabledConfirmBtn: $data.form.pristine || $data.form.errors.length > 0 || $options.isSaving,
    onSubmit: $options.submit,
    onClose: $options.close
  }, {
    default: _withCtx(() => [
      _createVNode(_component_DvpField, {
        required: "",
        label: $data.contents.nameField,
        errorMessage: $data.form.firstError('name')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_MTextInput, {
            modelValue: $data.form.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.form.name) = $event)),
            placeholder: $data.contents.namePlaceholder,
            isInvalid: $data.form.hasError('name')
          }, null, 8, ["modelValue", "placeholder", "isInvalid"])
        ]),
        _: 1
      }, 8, ["label", "errorMessage"]),
      _createVNode(_component_DvpField, {
        label: $data.contents.descriptionField,
        errorMessage: $data.form.firstError('description')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_MTextArea, {
            modelValue: $data.form.description,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.form.description) = $event)),
            class: "group-edition-modal__description-textarea",
            placeholder: $data.contents.descriptionPlaceholder,
            isInvalid: $data.form.hasError('description')
          }, null, 8, ["modelValue", "placeholder", "isInvalid"])
        ]),
        _: 1
      }, 8, ["label", "errorMessage"]),
      ($data.form.needManagerField)
        ? (_openBlock(), _createBlock(_component_DvpField, {
            key: 0,
            required: "",
            label: $data.contents.managerField,
            errorMessage: $data.form.firstError('administrator')
          }, {
            default: _withCtx(() => [
              ($data.form.administrator)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_MIcon, { name: "AccountProfileView24" }),
                    _createElementVNode("span", _hoisted_2, _toDisplayString($data.form.administrator.name), 1),
                    _createVNode(_component_IconButton, {
                      class: "group-edition-modal__group-manager-removal-button",
                      mozaicIconName: "ControlCircleCross24",
                      color: "var(--color-status-danger)",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => ($data.form.administrator = undefined))
                    })
                  ]))
                : (_openBlock(), _createBlock(_component_AddUser, {
                    key: 1,
                    canChangeAction: false,
                    onUserSubmitted: _cache[3] || (_cache[3] = ($event: any) => ($data.form.administrator = $event.user))
                  }))
            ]),
            _: 1
          }, 8, ["label", "errorMessage"]))
        : _createCommentVNode("", true),
      ($options.displayNotificationMCCGroup)
        ? (_openBlock(), _createBlock(_component_NotificationWarningMCCGroup, { key: 1 }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["disabledConfirmBtn", "onSubmit", "onClose"]))
}