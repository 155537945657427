export function shortenWords(str: string, maxLen: number = 60) {
  const separator = " ";
  if (!str) {
    return;
  }
  if (str.length <= maxLen) {
    return str;
  }
  return str.substr(0, str.lastIndexOf(separator, maxLen)) + "...";
}

export function shortenString(str: string, maxLen: number = 60) {
  if (!str) {
    return;
  }
  if (str.length <= maxLen) {
    return str;
  }
  return str.substr(0, maxLen) + "...";
}

export function capitalize(name: string) {
  return (
    name &&
    name
      .toLowerCase()
      .split(" ")
      .map((w) => w.replace(/./, w.toUpperCase()[0]))
      .join(" ")
  );
}

export function labelize(value: string) {
  const lowercased = value.replace(/_/g, " ").toLowerCase();
  return lowercased.charAt(0).toUpperCase() + lowercased.slice(1);
}

export function labelizeUpperCase(value: string) {
  return labelize(value).toUpperCase();
}

export function username(user?: { firstname: string; lastname: string }) {
  return user ? capitalize(user.firstname + " " + user.lastname) : "N/A";
}
