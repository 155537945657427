module.exports = {
  description:
    "Define here the Cross-origin resource sharing (CORS) headers which will be injected into your API response. These headers will be concatenated with those who are already sent by your API.",
  enable: "ENABLE",
  allowedOrigins: "ALLOWED ORIGINS",
  allowedMethods: "ALLOWED METHODS",
  allowedHeaders: "ALLOWED HEADERS (optional)",
  allowedCredentials: "ALLOW CREDENTIALS",
  actions: "ACTIONS",
  origin: "Origin",
  header: "Header",
  addCors: "Add CORS",
};
