import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "deprecate-api-zones-modal" }
const _hoisted_2 = { class: "deprecate-api-zones-modal__wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MSelect = _resolveComponent("MSelect")!
  const _component_DvpField = _resolveComponent("DvpField")!
  const _component_DatePicker = _resolveComponent("DatePicker")!
  const _component_MTextArea = _resolveComponent("MTextArea")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_LayerModalForm = _resolveComponent("LayerModalForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_LayerModalForm, {
        "data-cy": "deprecate-api-zones-modal",
        onSubmit: $options.submit
      }, {
        fields: _withCtx(() => [
          _createVNode(_component_DvpField, {
            required: "",
            label: $data.contents.zoneIdLabel,
            helpText: $data.contents.helpTooltipZoneId,
            errorMessage: $data.form.firstError('zoneId'),
            "data-cy": "zone-to-deprecate"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_MSelect, {
                id: "zone-to-deprecate",
                modelValue: $data.form.zoneId,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.form.zoneId) = $event)),
                placeholder: $data.contents.selectZone,
                options: $options.zonesList,
                disabled: $options.isSaving
              }, null, 8, ["modelValue", "placeholder", "options", "disabled"])
            ]),
            _: 1
          }, 8, ["label", "helpText", "errorMessage"]),
          _createVNode(_component_DvpField, {
            required: "",
            label: $data.contents.expirationDateLabel,
            helpText: $data.contents.helpTooltipDate,
            errorMessage: $data.form.firstError('date'),
            "data-cy": "expiration-date"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DatePicker, {
                modelValue: $data.form.date && $data.form.date.toISOString(),
                label: $data.contents.selectDate,
                minDate: $options.tomorrow,
                color: "#38ada9",
                format: "YYYY-MM-DD",
                formatted: "YYYY/MM/DD",
                autoClose: "",
                noHeader: "",
                noButtonNow: "",
                noClearButton: "",
                noLabel: "",
                noKeyboard: "",
                onlyDate: "",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ($data.form.date = new Date($event)))
              }, null, 8, ["modelValue", "label", "minDate"])
            ]),
            _: 1
          }, 8, ["label", "helpText", "errorMessage"]),
          _createVNode(_component_DvpField, {
            required: "",
            label: $data.contents.messageLabel,
            helpText: $data.contents.helpTooltipMessage,
            errorMessage: $data.form.firstError('message'),
            "data-cy": "deprecate-zone-message"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_MTextArea, {
                id: "message",
                modelValue: $data.form.message,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.form.message) = $event)),
                rows: 6,
                placeholder: $data.contents.messageLabel,
                disabled: $options.isSaving
              }, null, 8, ["modelValue", "placeholder", "disabled"])
            ]),
            _: 1
          }, 8, ["label", "helpText", "errorMessage"])
        ]),
        formFooter: _withCtx(() => [
          _createVNode(_component_MButton, {
            type: "button",
            theme: "bordered-neutral",
            label: $data.contents.cancel,
            disabled: $options.isSaving,
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('onClose')))
          }, null, 8, ["label", "disabled"]),
          _createVNode(_component_MButton, {
            label: $data.contents.confirm,
            theme: "solid-danger",
            type: "submit"
          }, null, 8, ["label"])
        ]),
        _: 1
      }, 8, ["onSubmit"])
    ])
  ]))
}