import { Zone } from "@/commons/domain/models/zone";

import { ETagType } from "@/commons/store/types";

export enum EDatacenterType {
  AZURE = "AZURE",
  GCP = "GCP",
  ADEO = "ADEO",
}

interface DatacenterIconInfo {
  keywords: string[];
  icon: string;
  type: EDatacenterType;
}

const datacenterIconInfoList: DatacenterIconInfo[] = [
  {
    keywords: ["south africa"],
    icon: require("@/commons/assets/images/access-url/icon-azure.svg"),
    type: EDatacenterType.AZURE,
  },
  {
    keywords: ["gcp", "brazil", "europe"],
    icon: require("@/commons/assets/images/access-url/icon-gcp.svg"),
    type: EDatacenterType.GCP,
  },
  {
    keywords: [],
    icon: require("@/commons/assets/images/access-url/icon-adeo.svg"),
    type: EDatacenterType.ADEO,
  },
];

export function getDatacenterIcon(zone: Zone): string {
  let datacenterIcon;

  if (zone) {
    const datacenterTag = zone.tags.find(
      (tag) => tag.type === ETagType.DATACENTER,
    );

    if (datacenterTag?.label) {
      datacenterIcon = findDatacenterIconByTagLabel(datacenterTag.label);
    }
  }

  return datacenterIcon || getDefaultDatacenterIcon();
}

function getDefaultDatacenterIcon(): string {
  const defaultDatacenterIconInfo = datacenterIconInfoList.find(
    (datacenterIconInfo) => datacenterIconInfo.type === EDatacenterType.ADEO,
  );
  return defaultDatacenterIconInfo.icon;
}

function findDatacenterIconByTagLabel(
  datacenterTagLabel: string,
): string | undefined {
  const lowerCaseLabel = datacenterTagLabel.toLowerCase();

  for (const currentDatacenterIconInfo of datacenterIconInfoList) {
    if (
      currentDatacenterIconInfo.keywords.some((keyword) =>
        lowerCaseLabel.includes(keyword),
      )
    ) {
      return currentDatacenterIconInfo.icon;
    }
  }

  return undefined;
}
