<script setup lang="ts">
import MNotification from "@mozaic-ds/vue-3/src/components/notification/MNotification.vue";
import { computed } from "vue";
import { useStore } from "vuex";

import ViewHelpButton from "@/commons/components/UserDocumentationLinks/ViewHelpButton.vue";

import contents from "@/commons/contents/notification-warning-mcc-group";

const store = useStore();

const frontendConfig = computed(() => store.getters["config/frontendConfig"]);
const fullFeatureIsEnabled = computed(
  () => frontendConfig.value?.mccGroups?.fullFeatureIsEnabled === true,
);
</script>

<template>
  <MNotification v-if="fullFeatureIsEnabled" type="warning" title="Warning">
    <p>{{ contents.localGroupUsageWarningMessage }}</p>
    <p>{{ contents.mccGroupAdviseMessage }}</p>
    <template #footer>
      <ViewHelpButton documentationAnchor="#mycloudconsole-groups" size="m" />
    </template>
  </MNotification>
</template>
