module.exports = {
  title: "Find the API you need",
  description:
    "Access the documentation for all APIs or add one instantly to one of your applications.",
  moreFiltersButtonLabel: "More filters",
  resetButtonLabel: "Reset",
  searchProduct: "Search a product as filter",
  productsLabel: "By related product",
  platformsLabel: "By Platform/Community",
  searchPlatforms: "Search a platform as filter",
  buLabel: "By business units",
  searchBusinessUnit: "All business units",
  noApiFallbackMessage: "Sorry, no APIs to display",
  loadingMessage: "Looking for the APIs list",
  searchPlaceholder: "Search API by name and description",
  privacyLabel: "Privacy",
  publicationLabel: "Publication",
  publicationZoneLabel: "Publication zone",
  accessTypeLabel: "Access type",
  apiTypeLabel: "API type",
  onlySubscribedTitle: "Subscribed APIs",
  onlySubscribedLabel: "Show all my subscribed APIs",
};
