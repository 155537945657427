import { RouteLocationNormalized } from "vue-router";

import { EModalType } from "@/commons/components/Modal/modal.type";

import { buildPayload } from "@/commons/store/helpers/build-payload";
import { patchState as patchStateHelper } from "@/commons/store/helpers/patch-state";
import { updatePayload } from "@/commons/store/helpers/update-payload";

import { GlobalModal, State } from "@/commons/store/types";

function patchState(state: State, partial: Partial<State>) {
  patchStateHelper(partial, state);
}

function overwriteStateProperty(
  state: State,
  { path, property, value }: { path: string[]; property: string; value: any },
) {
  const patchPayload = buildPayload(path);

  // Forces a property to be undefined to prevent a following patchState to merge properties (making it impossible to remove a property)
  updatePayload(patchPayload, property, undefined, path);
  patchStateHelper(patchPayload, state);

  updatePayload(patchPayload, property, value, path);
  patchStateHelper(patchPayload, state);
}

function openModal(state: State, payload: GlobalModal) {
  state.openModal = payload;
  state.modalType = EModalType.MODAL;
}

function openLayerModal(state: State, payload: GlobalModal) {
  state.openModal = payload;
  state.modalType = EModalType.LAYER_MODAL;
}

function closeModal(state: State) {
  state.openModal = undefined;
}

function initSaveLoader(state: State, key: string) {
  const partial: Partial<State> = {
    isSaving: {
      [key]: true,
    },
  };
  patchStateHelper(partial, state);
}

function finishSaveLoader(state: State, key: string) {
  const partial: Partial<State> = {
    isSaving: {
      [key]: false,
    },
  };
  patchStateHelper(partial, state);
}

function initFetchLoader(state: State, key: string) {
  const partial: Partial<State> = {
    isLoading: {
      [key]: true,
    },
  };
  patchStateHelper(partial, state);
}

function finishFetchLoader(state: State, key: string) {
  const partial: Partial<State> = {
    isLoading: {
      [key]: false,
    },
  };
  patchStateHelper(partial, state);
}

function updatePreviousPage(state: State, from: RouteLocationNormalized) {
  overwriteStateProperty(state, {
    path: [],
    property: "previousRoute",
    value: from,
  });
}

export default {
  patchState,
  overwriteStateProperty,
  openModal,
  openLayerModal,
  closeModal,
  initSaveLoader,
  finishSaveLoader,
  initFetchLoader,
  finishFetchLoader,
  updatePreviousPage,
};
