<template>
  <CardContainer class="oauth-flow-settings">
    <div class="oauth-flow-settings__card-title-with-help-button">
      <div class="oauth-flow-settings__card-title-with-icon">
        <img
          class="oauth-flow-settings__card-title-icon"
          :src="require('@/commons/assets/icons/ping-logo.png')"
        />
        <h4 class="oauth-flow-settings__card-title">
          {{ contents.flowSettingsTitle }}
        </h4>
      </div>
      <ViewHelpButton
        class="oauth-flow-settings__flow-settings-help-button"
        documentationAnchor="#configure-supported-flows"
      />
    </div>
    <p class="oauth-flow-settings__requirement">
      {{ contents.atLeastOneFlowSelected }}
    </p>
    <div class="oauth-flow-settings__flow-list">
      <OauthFlowOptionCard
        v-for="(flowItem, index) in oauthFlowItems"
        :key="index"
        :data-cy="'oauth-flow-' + flowItem.value"
        :flowItem="flowItem"
        :modelValue="flowIsSelected(flowItem.value)"
        :isDisabled="
          flowIsSelected(flowItem.value) && selectedFlows.length === 1
        "
        @update:modelValue="onChangeFlowSelection(flowItem.value, $event)"
      />
    </div>
  </CardContainer>
</template>

<script lang="ts">
import CardContainer from "@/commons/components/CardContainer.vue";
import ViewHelpButton from "@/commons/components/UserDocumentationLinks/ViewHelpButton.vue";
import OauthFlowOptionCard from "@/manager/views/ApiDetailAuthentication/OauthFlowOptionCard.vue";
import ModalConsumersPreventingOauthFlowChange from "@/manager/views/ApiDetailAuthentication/modal/ModalConsumersPreventingOauthFlowChange.vue";

import { getApiRepository } from "@/commons/repositories/libs/get-api-repository";
import {
  OAUTH_FLOW_ITEMS,
  OauthFlowItem,
} from "@/manager/utils/oauth-flow-items";

import { Api } from "@/commons/domain/models/api";
import { EOauthFlow } from "@/commons/types/oauth-flow-types";

import contents from "@/manager/contents/authentication";
import contentsOfModalConsumersPreventingOauthFlowChange from "@/manager/contents/modal-consumers-preventing-oauth-flow-change";

export default {
  components: {
    CardContainer,
    OauthFlowOptionCard,
    ViewHelpButton,
  },
  data() {
    return {
      contents,
    };
  },
  computed: {
    currentApi(): Api {
      return this.$store.getters["currentApi"];
    },
    oauthFlowItems(): OauthFlowItem[] {
      return OAUTH_FLOW_ITEMS;
    },
    selectedFlows(): OauthFlowItem[] {
      return this.currentApi.oauthFlows;
    },
  },
  methods: {
    async updateOauthFlows(newFlowsConfiguration: EOauthFlow[]): Promise<void> {
      this.$store.dispatch(
        "updateOauthFlowOfCurrentApi",
        newFlowsConfiguration,
      );
    },
    flowIsSelected(value: EOauthFlow): boolean {
      return this.selectedFlows.includes(value);
    },
    async onChangeFlowSelection(changedFlow: EOauthFlow, isSelecting) {
      isSelecting
        ? this.addOauthFlow(changedFlow)
        : this.tryRemoveOauthFlow(changedFlow);
    },
    async addOauthFlow(flowToAdd: EOauthFlow) {
      this.updateOauthFlows([...this.selectedFlows, flowToAdd]);
    },
    async tryRemoveOauthFlow(flowToRemove: EOauthFlow) {
      if (
        await getApiRepository().hasAnyActiveSubscription(
          this.currentApi.id,
          false,
          flowToRemove,
        )
      ) {
        this.openModalConsumersPreventingOauthFlowChange(flowToRemove);

        //else: there is no active subscription using this flow, we can unselect it
      } else {
        const indexOfFlowToRemove = this.selectedFlows.findIndex(
          (flow) => flow === flowToRemove,
        );
        this.updateOauthFlows(
          this.selectedFlows.toSpliced(indexOfFlowToRemove, 1),
        );
      }
    },
    openModalConsumersPreventingOauthFlowChange(
      oauthFlowRelatedToChange: EOauthFlow,
    ) {
      this.$store.commit("openModal", {
        title: contentsOfModalConsumersPreventingOauthFlowChange.modalTitle,
        component: ModalConsumersPreventingOauthFlowChange,
        props: {
          api: this.currentApi,
          oauthFlowRelatedToChange,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.oauth-flow-settings__card-title-with-help-button {
  display: flex;
  align-items: flex-start;
  gap: 1rem;

  margin-bottom: 1.5rem;
}

.oauth-flow-settings__card-title-with-icon {
  display: flex;
  gap: 0.5rem;
}

.oauth-flow-settings__card-title {
  margin-top: 0;
  margin-bottom: 0;
}

.oauth-flow-settings__requirement {
  @include set-text-s;
  margin-bottom: 1.5rem;
  font-weight: var(--weight-bold);
}

.oauth-flow-settings__flow-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(23rem, 1fr));
  grid-gap: 1.5rem;
}
</style>
