import { getDateValue } from "./get-date-value";
import {
  APPLICATION_DATE_HISTOGRAMS,
  PARAMS_SEPARATOR,
} from "@/commons/store/modules/reporting/constants";

import {
  FetchApplicationReportingParams,
  Filters,
} from "@/commons/store/modules/reporting/types";

export function buildFetchApplicationReportingParams(
  filters: Filters,
  separator: string = PARAMS_SEPARATOR,
): FetchApplicationReportingParams {
  const { from, to, apis, status, methods, numHits } = filters;

  return {
    from: getDateValue(from).toISOString(),
    to: getDateValue(to).toISOString(),
    apis: apis.join(separator),
    status: status.join(separator),
    method: methods.join(separator),
    hits: `${numHits}`,
    dateHistograms: APPLICATION_DATE_HISTOGRAMS.join(separator),
  };
}
