import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MNotification = _resolveComponent("MNotification")!
  const _component_NotificationWarningMCCGroup = _resolveComponent("NotificationWarningMCCGroup")!
  const _component_MTextInput = _resolveComponent("MTextInput")!
  const _component_MField = _resolveComponent("MField")!
  const _component_MTextArea = _resolveComponent("MTextArea")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_LayerModalForm = _resolveComponent("LayerModalForm")!

  return (_openBlock(), _createBlock(_component_LayerModalForm, {
    class: "create-group create-group__form",
    onSubmit: $options.onFormSubmit
  }, {
    fields: _withCtx(() => [
      _createElementVNode("h4", null, _toDisplayString($data.contents.title), 1),
      _createVNode(_component_MNotification, {
        type: "danger",
        title: $data.contents.notificationTitle
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($data.contents.notificationMessage), 1)
        ]),
        _: 1
      }, 8, ["title"]),
      ($options.userIsInternal)
        ? (_openBlock(), _createBlock(_component_NotificationWarningMCCGroup, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_MField, {
        id: "create-group__group-name",
        label: "Group name",
        isInvalid: $options.isInvalidGroupName,
        errorMessage: $data.form.firstError('name')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_MTextInput, {
            modelValue: $data.form.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.form.name) = $event)),
            placeholder: $data.contents.groupNamePlaceholder,
            isInvalid: $options.isInvalidGroupName
          }, null, 8, ["modelValue", "placeholder", "isInvalid"])
        ]),
        _: 1
      }, 8, ["isInvalid", "errorMessage"]),
      _createVNode(_component_MField, {
        id: "create-group__group-description",
        label: "Group description",
        isInvalid: $options.isInvalidGroupDescription,
        errorMessage: $data.form.firstError('description')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_MTextArea, {
            modelValue: $data.form.description,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.form.description) = $event)),
            placeholder: "Group description",
            isInvalid: $options.isInvalidGroupDescription
          }, null, 8, ["modelValue", "isInvalid"])
        ]),
        _: 1
      }, 8, ["isInvalid", "errorMessage"])
    ]),
    formFooter: _withCtx(() => [
      _createVNode(_component_MButton, {
        type: "submit",
        disabled: !!$data.form.errors.length,
        label: "Create group"
      }, null, 8, ["disabled"])
    ]),
    _: 1
  }, 8, ["onSubmit"]))
}