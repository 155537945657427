import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card-content-api__type"
}
const _hoisted_2 = { class: "card-content-api__name" }
const _hoisted_3 = {
  key: 1,
  class: "card-content-api__product",
  "data-cy": "api-product"
}
const _hoisted_4 = { class: "card-content-api__header-right" }
const _hoisted_5 = { class: "card-content-api__description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MBadge = _resolveComponent("MBadge")!
  const _component_Chip = _resolveComponent("Chip")!
  const _component_WithSidebar = _resolveComponent("WithSidebar")!
  const _component_Stack = _resolveComponent("Stack")!

  return (_openBlock(), _createBlock(_component_Stack, { class: "card-content-api" }, {
    default: _withCtx(() => [
      _createVNode(_component_WithSidebar, { "sidebar-to-right": "" }, {
        left: _withCtx(() => [
          ($props.apiType)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("span", null, _toDisplayString($options.apiTypeLabel), 1),
                ($props.apiSubtype)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      class: _normalizeClass([
              {
                'card-content-api__subtype--unknown': $options.isUnknownSubtype,
              },
            ])
                    }, _toDisplayString(` - ${$props.apiSubtype}`), 3))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_2, _toDisplayString($props.name), 1),
          ($props.productName)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("i", null, "from \"" + _toDisplayString($props.productName) + "\"", 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        right: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            ($props.hasBlockingPendingRequest)
              ? (_openBlock(), _createBlock(_component_MBadge, {
                  key: 0,
                  type: "warning"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString($data.contents.pendingRequest), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_Chip, null, {
              default: _withCtx(() => [
                _createTextVNode("v" + _toDisplayString($props.version), 1)
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }),
      _createElementVNode("p", _hoisted_5, _toDisplayString($options.getDescription($props.description)), 1)
    ]),
    _: 1
  }))
}