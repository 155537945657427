import { AccessPolicyPendingRequest } from "@/commons/domain/models/access-policy-pending-request";
import { PendingRequestDto } from "@/commons/dtos/pending-request-dto";

export class AccessPolicyPendingRequestMapper {
  static toDomain(
    pendingRequestsDtos: PendingRequestDto[] = [],
  ): AccessPolicyPendingRequest {
    return pendingRequestsDtos[0] && pendingRequestsDtos[0].diff.accessPolicy;
  }
}
