<template>
  <div :class="['tooltip', { 'tooltip--disabled': isDisabled }]">
    <slot />
    <div
      class="tooltip__content bg-white radius shadow"
      :data-position="position"
    >
      <div>{{ tooltipContent }}</div>
      <slot name="content" />
      <i></i>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: "Tooltip",
  props: {
    position: {
      type: String,
      default: "top",
    },
    tooltipContent: {
      type: String,
      default: undefined,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/commons/assets/styles/vars";

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip__content {
  @include set-text-s;
  position: absolute;
  z-index: var(--z-index-foreground);
  display: none;
  width: max-content;
  max-width: 350px;
  padding: 12px 16px;
  line-height: 1.5;
  color: var(--color-text);
  text-transform: none;
  border: var(--border-default);
}

.tooltip:hover .tooltip__content {
  display: block;
}

.tooltip.tooltip--disabled .tooltip__content {
  display: none;
}

.tooltip__content i {
  position: absolute;
  overflow: hidden;
}

.tooltip__content i::after {
  position: absolute;
  width: 12px;
  height: 12px;
  content: "";
  background-color: var(--color-white);
  border: var(--border-default);
}

.tooltip__content[data-position="top"] {
  top: -10px;
  left: 50%;
  min-width: 240px;
  transform: translate(-50%, -100%);

  i {
    top: 100%;
    left: 50%;
    width: 24px;
    height: 12px;
    margin-left: -12px;
  }

  i::after {
    left: 50%;
    transform: translate(-45%, -50%) rotate(45deg);
  }
}

.tooltip__content[data-position="bottom"] {
  top: 30px;
  left: 50%;
  min-width: 200px;
  transform: translate(-50%, 0);

  i {
    bottom: 100%;
    left: 50%;
    width: 24px;
    height: 12px;
    margin-left: -12px;
  }

  i::after {
    left: 50%;
    transform: translate(-50%, 50%) rotate(45deg);
  }
}

.tooltip__content[data-position="left"] {
  top: 45%;
  right: 100%;
  width: max-content;
  margin-right: 10px;
  transform: translate(0, -50%);

  i {
    top: 50%;
    left: 100%;
    width: 12px;
    height: 24px;
    margin-top: -12px;
  }

  i::after {
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.tooltip__content[data-position="right"] {
  top: 50%;
  left: 100%;
  width: max-content;
  margin-left: 10px;
  transform: translate(0, -50%);

  i {
    top: 50%;
    right: 100%;
    width: 12px;
    height: 24px;
    margin-top: -12px;
  }

  i::after {
    top: 50%;
    left: 0;
    transform: translate(50%, -50%) rotate(-45deg);
  }
}
</style>
