import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "list-api-summaries" }
const _hoisted_2 = {
  key: 0,
  class: "list-api-summaries__no-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardLoading = _resolveComponent("CardLoading")!
  const _component_CardApiSummary = _resolveComponent("CardApiSummary")!
  const _component_Grid = _resolveComponent("Grid")!
  const _component_Stack = _resolveComponent("Stack")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Stack, null, {
      default: _withCtx(() => [
        _createVNode(_component_Grid, null, {
          default: _withCtx(() => [
            ($props.isLoading)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, _renderList(6, (index) => {
                  return _createVNode(_component_CardLoading, {
                    key: `card-loading-${index}`
                  })
                }), 64))
              : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList($props.apis, (api, index) => {
                  return (_openBlock(), _createBlock(_component_CardApiSummary, {
                    key: api.id,
                    api: api,
                    "hide-product": $props.hideProduct,
                    tabindex: index + 1
                  }, null, 8, ["api", "hide-product", "tabindex"]))
                }), 128))
          ]),
          _: 1
        }),
        (!$props.isLoading && $props.apis.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($data.contents.noApisToDisplay), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}