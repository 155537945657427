<template>
  <div
    class="dropdown-menu"
    :class="{
      'dropdown-menu--active': isVisible || isActive,
    }"
    @mouseleave="isVisible = false"
  >
    <div class="dropdown-menu__label" @mouseover="isVisible = true">
      <MenuLabel :urlOrRoute="urlOrRoute">
        <span v-if="label" class="dropdown-menu__label-title">{{ label }}</span>
        <MenuIcon v-else :mIconName="mIconName" />
      </MenuLabel>
    </div>
    <transition name="fade-and-slide">
      <div
        v-show="isVisible && hasItems"
        class="dropdown-menu__menu"
        :style="menuInlineStyles"
      >
        <slot name="header" />
        <!-- prettier-ignore -->
        <MenuItem
          v-for="(item, index) in items"
          v-bind="$attrs, item"
          :key="`${componentId}-${index}`"
        />
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import MenuIcon from "./MenuIcon.vue";
import MenuItem from "./MenuItem.vue";
import MenuLabel from "./MenuLabel.vue";

export default {
  name: "DropdownMenu",
  components: {
    MenuItem,
    MenuIcon,
    MenuLabel,
  },
  props: {
    label: {
      type: String,
      default: undefined,
    },
    urlOrRoute: {
      type: [String, Object],
      default: null,
    },
    mIconName: {
      type: String,
      default: undefined,
    },
    menuPadding: {
      type: String,
      default: "1.2rem 0 1.2rem 0",
    },
    menuMaxWidth: {
      type: String,
      default: "35rem",
    },
    items: {
      type: Array,
      default: () => [],
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVisible: false,
    };
  },
  computed: {
    componentId() {
      return `dropdown-menu-id-${this.$.uid}`;
    },
    menuInlineStyles() {
      return {
        padding: this.menuPadding,
        maxWidth: this.menuMaxWidth,
      };
    },
    hasItems() {
      return Object.keys(this.items).length > 0;
    },
  },
};
</script>

<style lang="scss">
.dropdown-menu {
  position: relative;
  height: 100%;
  padding: 0 var(--base-spacing);
}

.dropdown-menu__label {
  display: flex;
  align-items: center;
  height: 100%;
}

.dropdown-menu__label-title {
  @include set-text-s;
  color: var(--color-text-minor);
}

.dropdown-menu--active {
  .dropdown-menu__label {
    position: relative;
  }

  .dropdown-menu__label::after {
    position: absolute;
    bottom: 0;
    width: 100%;
    content: "";
    border-bottom: 4px solid var(--color-brand-primary);
  }

  .dropdown-menu__label-title {
    color: var(--color-text-primary);
  }
}

.dropdown-menu__menu {
  position: absolute;
  top: calc(
    var(--navbar-height) - 1px
  ); // -1px matches the topbar border. If we put the cursor on the border, the menu goes away
  left: 50%;
  z-index: 1; // so it's above non static elements without zIndex that could follow in the DOM
  width: max-content;
  min-width: 13rem;

  padding: 0.5rem;
  background-color: var(--color-white);
  border-radius: var(--m-border-radius-large);
  box-shadow: var(--m-shadow-medium);
  transform: translate(-50%, 0);
}
</style>
