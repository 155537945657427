import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "confirm-internet-exposure-modal__risk-warning" }
const _hoisted_2 = { class: "confirm-internet-exposure-modal__policy-mention" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MNotification = _resolveComponent("MNotification")!
  const _component_ConfirmModalBody = _resolveComponent("ConfirmModalBody")!

  return (_openBlock(), _createBlock(_component_ConfirmModalBody, {
    class: "confirm-internet-exposure-modal",
    confirmBtnLabel: $data.contents.confirm,
    onOnSubmit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onSubmit'))),
    onOnClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('onClose')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString($data.contents.exposureRiskWarning), 1),
      _createElementVNode("p", _hoisted_2, _toDisplayString($data.contents.policyMention), 1),
      ($props.currentTimeoutInSeconds > $data.MAX_PUBLIC_API_TIMEOUT)
        ? (_openBlock(), _createBlock(_component_MNotification, {
            key: 0,
            class: "confirm-internet-exposure-modal__max-public-api-timeout-notification",
            title: 
        $data.contents.maxPublicApiTimeoutNotificationTitle($data.MAX_PUBLIC_API_TIMEOUT)
      
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", {
                innerHTML: 
          $data.contents.maxPublicApiTimeoutNotificationContent(
            $props.currentTimeoutInSeconds,
            $data.MAX_PUBLIC_API_TIMEOUT,
          )
        
              }, null, 8, _hoisted_3)
            ]),
            _: 1
          }, 8, ["title"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["confirmBtnLabel"]))
}