import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MPagination = _resolveComponent("MPagination")!

  return ($props.totalPagesNumber > 1)
    ? (_openBlock(), _createBlock(_component_MPagination, {
        key: 0,
        class: "query-param-pagination",
        length: $props.totalPagesNumber,
        value: $options.currentPage,
        onOnUpdatePage: $options.changePage
      }, null, 8, ["length", "value", "onOnUpdatePage"]))
    : _createCommentVNode("", true)
}