<template>
  <Card class="user-cookies">
    <h4>{{ contents.cookies }}</h4>
    <MToggle
      v-for="(cat, index) in configurableCookieCategories"
      :id="`${cat.type}-${index}`"
      :key="`${cat.type}-${index}`"
      class="margin-bottom-400"
      :label="cat.acceptButtonLabel"
      :modelValue="acceptanceByCategoryType[cat.type]"
      @update:modelValue="onChange(cat.type, $event)"
    ></MToggle>
  </Card>
</template>

<script lang="ts">
import MToggle from "@mozaic-ds/vue-3/src/components/toggle/MToggle.vue";

import Card from "@/commons/components/Card.vue";
import { ECookieCategoryType } from "@/commons/components/CookieBanner/cookies-banner";
import {
  applyCookieAcceptance,
  getConfigurableCookieAcceptanceByCategoryType,
  getConfigurableCookieCategories,
} from "@/commons/components/CookieBanner/cookies-utils";

import contents from "@/commons/contents/user-cookies";

export default {
  name: "UserCookies",
  components: {
    Card,
    MToggle,
  },
  data() {
    return {
      contents,
      configurableCookieCategories: getConfigurableCookieCategories(),
      acceptanceByCategoryType: getConfigurableCookieAcceptanceByCategoryType(),
    };
  },
  methods: {
    onChange(
      cookieCategoryType: ECookieCategoryType,
      isChecked: boolean,
    ): void {
      this.acceptanceByCategoryType[cookieCategoryType] = isChecked;
      applyCookieAcceptance(this.acceptanceByCategoryType);
      window.location.reload();
    },
  },
};
</script>
