import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "contracts-application" }
const _hoisted_2 = { class: "application-contracts" }
const _hoisted_3 = { class: "contracts-application__filters" }
const _hoisted_4 = { class: "contracts-application__filters-on-right" }
const _hoisted_5 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotificationForKongSynchronization = _resolveComponent("NotificationForKongSynchronization")!
  const _component_ContractsFilters = _resolveComponent("ContractsFilters")!
  const _component_SearchTextFilter = _resolveComponent("SearchTextFilter")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_ContractsTabs = _resolveComponent("ContractsTabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($options.displayKongInformation && $options.hasApiStatusAsPendingDeploy)
      ? (_openBlock(), _createBlock(_component_NotificationForKongSynchronization, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        ($options.totalContractsCount > 0)
          ? (_openBlock(), _createBlock(_component_ContractsFilters, {
              key: 0,
              disabled: $options.isLoading,
              class: "contracts-application__filters-on-left"
            }, null, 8, ["disabled"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, [
          ($options.totalContractsCount > 0)
            ? (_openBlock(), _createBlock(_component_SearchTextFilter, {
                key: 0,
                class: "contracts-application__search-filter",
                placeholder: $data.contents.searchSubscribedApi,
                queryParamName: "term",
                queryParamValue: $options.searchTerm,
                size: "s"
              }, null, 8, ["placeholder", "queryParamValue"]))
            : _createCommentVNode("", true),
          ($options.userHasWriteAccessOnCurrentApplication)
            ? (_openBlock(), _createBlock(_component_MButton, {
                key: 1,
                class: "contracts-application__show-subscribable-apis-button",
                "data-cy": "show-subscribable-apis-button",
                size: "s",
                label: $data.contents.showSubscribableApisButton,
                onClick: $options.openSubscribableApisModal
              }, null, 8, ["label", "onClick"]))
            : _createCommentVNode("", true)
        ])
      ]),
      ($options.totalContractsCount === 0)
        ? (_openBlock(), _createElementBlock("header", _hoisted_5, [
            _createElementVNode("h4", null, _toDisplayString($data.contents.noApiTitle), 1),
            _createElementVNode("p", null, _toDisplayString($options.noApiGuideline), 1)
          ]))
        : (_openBlock(), _createBlock(_component_ContractsTabs, { key: 1 }))
    ])
  ]))
}