import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cancel-and-submit-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MIcon = _resolveComponent("MIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", {
      class: "cursor-pointer",
      "data-cy": "cancel-button",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.cancel && $options.cancel(...args)))
    }, [
      _createVNode(_component_MIcon, {
        name: "ControlCircleCross32",
        color: "var(--color-status-danger)"
      })
    ]),
    _createElementVNode("span", {
      class: "cursor-pointer",
      "data-cy": "submit-button",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.submit && $options.submit(...args)))
    }, [
      _createVNode(_component_MIcon, {
        name: "NotificationCircleAvailable32",
        color: "var(--color-status-success)"
      })
    ])
  ]))
}