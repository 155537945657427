import { pluralize } from "@/commons/utils/contents-utils";

import { Api } from "@/commons/domain/models/api";
import {
  EWindowLengthUnit,
  RateLimit,
} from "@/commons/domain/models/rate-limit";

import contents from "@/commons/contents/quotas";

export function buildDefaultRateLimit(): RateLimit {
  return {
    maxRequests: 10000,
    windowLength: 10,
    windowLengthUnit: EWindowLengthUnit.SECONDS,
    isEnabled: true,
    isGlobal: true,
  };
}

export function getRateLimitLabel(rateLimit: RateLimit): string {
  if (rateLimit == undefined) {
    return contents.noQuotaLimit;
  } else {
    const requestLabel = pluralize(rateLimit.maxRequests, contents.request);
    const windowLengthLabel = pluralize(
      rateLimit.windowLength,
      getWindowLengthUnitLabel(rateLimit.windowLengthUnit),
    );
    return contents.rateLimitLabel(requestLabel, windowLengthLabel);
  }
}

export function getRateLimitShortLabel(rateLimit: RateLimit): string {
  if (rateLimit == undefined) {
    return contents.noQuotaLimit;
  } else {
    const windowLengthLabel = `${
      rateLimit.windowLength
    }${getWindowLengthUnitShortLabel(rateLimit.windowLengthUnit)}`;
    return contents.rateLimitShortLabel(
      String(rateLimit.maxRequests),
      windowLengthLabel,
    );
  }
}

export function getWindowLengthUnitLabel(
  windowLengthUnit: EWindowLengthUnit,
): string {
  return contents.windowLengthUnit[windowLengthUnit] || windowLengthUnit;
}

export function getWindowLengthUnitShortLabel(
  windowLengthUnit: EWindowLengthUnit,
): string {
  return (
    contents.windowLengthUnitShortLabel[windowLengthUnit] || windowLengthUnit
  );
}

export function getAllowedWindowLengthUnits(api: Api): EWindowLengthUnit[] {
  return Object.values(EWindowLengthUnit).filter(
    (windowLengthUnit) =>
      api.allowedActions[`rate-limit.${windowLengthUnit.toLowerCase()}`],
  );
}
