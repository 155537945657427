module.exports = {
  documentation: "Documentation",
  addDocumentation: "Add documentation",
  noDocumentationYet: "No documentation yet",
  tableHeaderOrder: "Order",
  tableHeaderName: "Name",
  tableHeaderType: "Type",
  tableHeaderSource: "Source",
  tableHeaderLastUpdate: "Last update",
  tableHeaderActions: "Actions",
  openDocumentationPage: "Open the documentation page",
  pagesList: "Pages list",
  cannotFetchUrl:
    "Wrong URL: Unable to fetch the documentation from the given URL",
  cannotParseDocumentationFromUrl:
    "Wrong documentation syntax: Unable to parse the documentation from the given URL",
  cannotPublishInvalidContent:
    "Cannot publish the documentation because no valid content has been found.",
  cannotUnpublishDocumentationOfPublishedApi:
    "You need to unpublish the API before unpublishing or removing its documentation.",
  fetchSuccess: "Documentation successfully fetched",
  pendingFetch: "Fetching documentation...",
  viewAPIDocumentation: "View API documentation",
  published: "Published",
  notPublished: "Not published",
  urlMethod: "URL Method is the best one",
  switchToUrl:
    "We advise you to switch your 'Content' or 'File' documentation to 'URL' one, to make it fetched every hour and always be up to date.",
  preferUrlMethod:
    "Prefer URL Method. Like this, your documentation will always be up-to-date.",
  reachedDocumentationLimitTooltip:
    "You have reached the limit of 10 documentations. You cannot add a new documentation",
  removePageModalTitle: (pageTitle) =>
    `Are you sure you want to delete the ${pageTitle} ?`,
};
