<script setup lang="ts">
import { onMounted, ref } from "vue";

import UsersList from "@/commons/components/ManageGroups/UsersList.vue";

import { AclUser } from "@/commons/domain/models/acl-user";
import { findGroupUsers } from "@/commons/services/group/group-users-service";

const props = defineProps({
  groupId: {
    type: String,
    required: true,
  },
});

const usersList = ref([] as AclUser[]);

const loadUsers = async () => {
  usersList.value = await findGroupUsers(props.groupId);
};

const isLoading = ref(false as boolean);

onMounted(async () => {
  // TODO: use flagLoadingProcess when API Settings will be moved to Layout2
  // Because Layout component displays Loader at any (even small) loading process
  isLoading.value = true;
  await loadUsers();
  isLoading.value = false;
});
</script>

<template>
  <!-- UsersList component with loading users data -->
  <UsersList
    :groupId="groupId"
    :users="usersList"
    :isLoading="isLoading"
    v-bind="$attrs"
  />
</template>
