export default {
  modalTitle: "Unsubscribe",
  confirmationTitle: "Do you want to unsubscribe from this API?",
  apiKeyContractConfirmationDescription: (apiLabel) =>
    `By typing **'UNSUBSCRIBE'** below and then confirming, you will definitively unsubscribe your application from **${apiLabel}**. All the existing API Keys on this subscription will be definitively deleted. This action cannot be rollbacked.`,
  oAuthContractConfirmationDescription: (apiLabel) =>
    `By typing **'UNSUBSCRIBE'** below and then confirming, you will unsubscribe your application from **${apiLabel}**. This operation can not be rollbacked.`,
  oAuthContractWithSharedOrMultiLinedTokenConfirmationDescription: (apiLabel) =>
    `By typing **'UNSUBSCRIBE'** below and then confirming, you will permanently delete this subscription between your application and the **${apiLabel}**. However, your ClientID is still used on another subscription and will be kept.`,
  unsubscribe: "UNSUBSCRIBE",
  notSharedNorMultilinedTokenNotificationTitle: "ClientID will be deleted",
  notSharedNorMultilinedTokenNotificationMessage:
    "This action will definitively delete (after a safety deactivation period) this ClientID from Ping, which will not be usable anymore to obtain a new access token. Be sure you do not use this ClientID elsewhere.",
  secretSynchronizationMessage: (secretManager) =>
    `${secretManager} configuration will be synchronized, and the application could not start anymore if it depends on this secret.`,
  toastNotification: {
    title: "Subscription deleted",
    warningContent: (secretManager) =>
      `API Key will be removed from Kong Gateway with the next synchronization. However, your ${secretManager} namespace will be synchronized later.`,
  },
};
