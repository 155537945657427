export default {
  modalTitle: "Renew API Key",
  title: "You are going to renew your subscription, creating a new API Key.",

  message:
    "Once confirmed, this is the rest of the process you have to follow:\n\n" +
    "- **Configure your new token** in your application\n" +
    "- **Restart** your POD to be sure the new token is used\n" +
    "- **Delete** the **old** token",
  warningMessageTitle: "In real case of emergency",
  warningMessage:
    "You can extend your token expiration date, but it will degrade your application Security.",
  toastNotification: {
    title: "API Key renewed",
    warningContent: (secretManager) =>
      `API Key will be sent to Kong Gateway with the next synchronization. However, your ${secretManager} namespace will be synchronized later. In case of emergency, you can create yourself in ${secretManager} a new version of the related key with the new value. Be really careful in your action and wait for Kong Synchronization before restarting your application.`,
  },
};
