import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "apis-catalog__filters-container" }
const _hoisted_2 = { class: "apis-catalog__filters" }
const _hoisted_3 = { class: "apis-catalog__filters-on-left-side" }
const _hoisted_4 = { class: "apis-catalog__filters-on-right-side" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LayoutContentHeader = _resolveComponent("LayoutContentHeader")!
  const _component_LayoutSection = _resolveComponent("LayoutSection")!
  const _component_CheckboxFilter = _resolveComponent("CheckboxFilter")!
  const _component_CheckboxesListFilter = _resolveComponent("CheckboxesListFilter")!
  const _component_SearchTextFilter = _resolveComponent("SearchTextFilter")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_TagsAppliedFilters = _resolveComponent("TagsAppliedFilters")!
  const _component_ListApisCatalog = _resolveComponent("ListApisCatalog")!
  const _component_QueryParamPagination = _resolveComponent("QueryParamPagination")!
  const _component_Layout2 = _resolveComponent("Layout2")!

  return (_openBlock(), _createBlock(_component_Layout2, {
    class: "apis-catalog",
    isLoading: false
  }, {
    default: _withCtx(() => [
      _createVNode(_component_LayoutSection, {
        background: "primary",
        noVerticalPadding: "",
        fullWidthContent: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_LayoutContentHeader, {
            title: $data.contents.title,
            description: $data.contents.description
          }, null, 8, ["title", "description"])
        ]),
        _: 1
      }),
      _createVNode(_component_LayoutSection, {
        background: "secondary",
        class: "apis-catalog__content"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_CheckboxFilter, {
                  "data-cy": "only-subscribed-apis-filter",
                  queryParamName: "onlySubscribed",
                  label: $data.contents.onlySubscribedLabel,
                  queryParamValue: $props.urlQuery.onlySubscribed
                }, null, 8, ["label", "queryParamValue"]),
                _createVNode(_component_CheckboxesListFilter, {
                  "data-cy": "api-types-filter",
                  queryParamName: "types",
                  queryParamValue: $props.urlQuery.types,
                  items: $options.apiTypes,
                  inline: ""
                }, null, 8, ["queryParamValue", "items"])
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_SearchTextFilter, {
                  "data-cy": "search-filter",
                  class: "apis-catalog__search-filter",
                  queryParamName: "term",
                  size: "s",
                  placeholder: $data.contents.searchPlaceholder,
                  queryParamValue: $props.urlQuery.term
                }, null, 8, ["placeholder", "queryParamValue"]),
                _createVNode(_component_MButton, {
                  "data-cy": "more-filters-button",
                  icon: "DisplayFilter24",
                  theme: "bordered",
                  size: "s",
                  label: $data.contents.moreFiltersButtonLabel,
                  onClick: $options.openFilters
                }, null, 8, ["label", "onClick"])
              ])
            ]),
            _createVNode(_component_TagsAppliedFilters, {
              "data-cy": "filters-taglist",
              appliedFiltersTagList: $options.appliedFiltersTagList,
              onFilterRemoved: $options.removeFilter
            }, null, 8, ["appliedFiltersTagList", "onFilterRemoved"])
          ]),
          _createVNode(_component_ListApisCatalog, {
            class: "apis-catalog__list-apis",
            apis: $options.apiList,
            isLoading: $options.isLoading,
            hideProduct: !$options.userIsInternal
          }, null, 8, ["apis", "isLoading", "hideProduct"]),
          ($options.apisTotalPages)
            ? (_openBlock(), _createBlock(_component_QueryParamPagination, {
                key: 0,
                totalPagesNumber: $options.apisTotalPages
              }, null, 8, ["totalPagesNumber"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}