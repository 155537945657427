<template>
  <div class="use-case-card">
    <MIcon name="MonochromeRelayLocation64" color="var(--color-primary)" />
    <h2 class="use-case-card__title">{{ title }}</h2>
    <p class="use-case-card__description">{{ description }}</p>
    <div class="use-case-card__buttons">
      <MButton
        :label="contents.buttonLabel"
        @click="() => $router.push({ path })"
      />
    </div>
  </div>
</template>

<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import MIcon from "@mozaic-ds/vue-3/src/components/icon/MIcon.vue";

const contents = {
  buttonLabel: "View case study",
};

export default {
  name: "UseCaseCard",
  components: {
    MButton,
    MIcon,
  },
  props: {
    image: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    path: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      contents,
    };
  },
};
</script>

<style lang="scss">
@import "~@/commons/assets/styles/vars";

.use-case-card {
  display: flex;
  flex: 0.33;
  flex-direction: column;
  align-items: center;

  height: 32.14rem;
  padding: 3rem;
  margin-right: 2rem;
  margin-bottom: 2rem;

  background-color: var(--color-white);
  border-radius: 6px;

  box-shadow: var(--m-shadow-medium);

  &:last-child {
    margin-right: 0;
  }
}

.use-case-card__title {
  margin-top: 2rem;
  margin-bottom: 2rem;

  font-size: 1.43rem;
  font-weight: 700;
  line-height: 2rem;
}

.use-case-card__description {
  font-size: 1.14rem;
  font-weight: 300;
  line-height: 2rem;
  text-align: center;
}

.use-case-card__buttons {
  margin-top: auto;
}

.use-case-card__buttons .button {
  width: auto;
  height: 3.57rem;
  padding: 0 2.14rem;
  font-size: 1.29rem;
}

@media only screen and (max-width: map-get($breakpoints, desktop-small)) {
  .use-case-card {
    flex: 0.33 calc(33% - 8rem);
    padding: 1.5rem;
  }

  .use-case-card__title {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    text-align: center;
  }
}
</style>
