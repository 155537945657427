import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, mergeProps as _mergeProps, vShow as _vShow, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, Transition as _Transition, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "dropdown-menu__label-title"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuIcon = _resolveComponent("MenuIcon")!
  const _component_MenuLabel = _resolveComponent("MenuLabel")!
  const _component_MenuItem = _resolveComponent("MenuItem")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["dropdown-menu", {
      'dropdown-menu--active': $data.isVisible || $props.isActive,
    }]),
    onMouseleave: _cache[1] || (_cache[1] = ($event: any) => ($data.isVisible = false))
  }, [
    _createElementVNode("div", {
      class: "dropdown-menu__label",
      onMouseover: _cache[0] || (_cache[0] = ($event: any) => ($data.isVisible = true))
    }, [
      _createVNode(_component_MenuLabel, { urlOrRoute: $props.urlOrRoute }, {
        default: _withCtx(() => [
          ($props.label)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString($props.label), 1))
            : (_openBlock(), _createBlock(_component_MenuIcon, {
                key: 1,
                mIconName: $props.mIconName
              }, null, 8, ["mIconName"]))
        ]),
        _: 1
      }, 8, ["urlOrRoute"])
    ], 32),
    _createVNode(_Transition, { name: "fade-and-slide" }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", {
          class: "dropdown-menu__menu",
          style: _normalizeStyle($options.menuInlineStyles)
        }, [
          _renderSlot(_ctx.$slots, "header"),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, (item, index) => {
            return (_openBlock(), _createBlock(_component_MenuItem, _mergeProps({ ref_for: true }, _ctx.$attrs, item, {
              key: `${$options.componentId}-${index}`
            }), null, 16))
          }), 128))
        ], 4), [
          [_vShow, $data.isVisible && $options.hasItems]
        ])
      ]),
      _: 3
    })
  ], 34))
}