import {
  PagedResource,
  PagedResourceList,
} from "@/commons/domain/models/paged-resource";
import { PagedResourceDto } from "@/commons/dtos/paged-resource-dto";

export class PageMapper {
  static toPagedResourceWithDataAsList<DtoType, DomainType>(
    pagedDto: PagedResourceDto<DtoType>,
    dataDomainMapper: (dto: DtoType) => DomainType,
  ): PagedResourceList<DomainType> {
    return {
      count: pagedDto.count,
      currentPage: pagedDto.currentPage,
      data: pagedDto.data.map(dataDomainMapper),
      totalCount: pagedDto.totalCount,
      totalPages: pagedDto.totalPages,
    };
  }

  static toPagedResource<DtoType, DomainType extends { id: string }>(
    pagedDto: PagedResourceDto<DtoType>,
    dataDomainMapper: (dto: DtoType) => DomainType,
  ): PagedResource<DomainType> {
    const pagedResourceWithDataAsList =
      PageMapper.toPagedResourceWithDataAsList(pagedDto, dataDomainMapper);

    const dataAsDictionary = {};
    pagedResourceWithDataAsList.data.forEach((singleData) => {
      dataAsDictionary[singleData.id] = singleData;
    });

    return {
      ...pagedResourceWithDataAsList,
      data: dataAsDictionary,
    };
  }
}
