import {
  IsNotEmpty,
  IsUrl,
  MaxLength,
  MinLength,
  ValidateIf,
} from "class-validator";

import { FormData } from "@/commons/domain/forms/form-data";
import { FormFactory } from "@/commons/domain/forms/form-factory";
import {
  EDocumentationPageSource,
  EDocumentationType,
} from "@/commons/domain/models/documentation-page";

export class CreateUpdateDocumentationForm extends FormData {
  type: EDocumentationType = EDocumentationType.CHANGELOG;

  inputMethod: EDocumentationPageSource = EDocumentationPageSource.URL;

  @ValidateIf((form) =>
    [EDocumentationType.MARKDOWN, EDocumentationType.CHANGELOG].includes(
      form.type,
    ),
  )
  @MinLength(5)
  @MaxLength(30)
  title: string = "";

  @ValidateIf((form) => form.inputMethod === EDocumentationPageSource.CONTENT)
  @IsNotEmpty()
  content: string = "";

  @ValidateIf(
    (form) =>
      form.inputMethod === EDocumentationPageSource.URL &&
      // An empty URL is valid when there is already a content, it allows the user to remove an URL afterwards while preventing a creation with empty content AND empty URL
      // (Meaning we want validation when url is not empty or when url is empty AND content is empty)
      (form.url || !form.content),
  )
  @IsUrl(
    {
      require_protocol: true,
      require_valid_protocol: true,
      protocols: ["http", "https"],
      host_blacklist: ["localhost", "127.0.0.1"],
      disallow_auth: true,
      allow_trailing_dot: true,
      require_tld: false,
    },
    { message: "This URL must be a valid URL address" },
  )
  url: string = "";

  $clear() {
    this.title = "";
    this.content = "";
    this.url = "";
  }

  static create() {
    return FormFactory.createForm(new CreateUpdateDocumentationForm());
  }
}
