<script setup lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import MDataTableColumn from "@mozaic-ds/vue-3/src/components/datatablev2/MDataTableColumn.vue";
import MDataTableV2 from "@mozaic-ds/vue-3/src/components/datatablev2/MDataTableV2.vue";
import { computed, onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import CardContainer from "@/commons/components/CardContainer.vue";
import ToggleExpandDescriptionButton from "@/manager/components/ToggleExpandDescriptionButton.vue";

import { getApiRepository } from "@/commons/repositories/libs/get-api-repository";
import { pluralize, cropText } from "@/commons/utils/contents-utils";
import { flagLoadingProcess } from "@/commons/utils/flagProcess.utils";

import { Scope } from "@/commons/domain/models/scope";

import contents from "@/manager/contents/authentication";

const props = defineProps({
  apiId: {
    type: String,
    required: true,
  },
  hasScopes: {
    type: Boolean,
    default: false,
  },
});

const router = useRouter();
const store = useStore();

const scopes = reactive({
  items: [] as Scope[],
  load: async () => {
    await flagLoadingProcess("apiScopes", async () => {
      scopes.items = (await getApiRepository().getApiScopes(props.apiId)).map(
        (scope: Scope) => {
          return {
            ...scope,
            displayedDescription: scope.description
              ? cropText(scope.description, 200)
              : "-",
            descriptionCouldBeExpanded:
              scope.description && scope.description.length > 200,
          };
        },
      );
    });
  },
  isLoading: computed(() => store.getters["isLoadingProperty"]("apiScopes")),
});

onMounted(async () => {
  if (props.hasScopes) {
    await scopes.load();
  }
});

function addScope() {
  router.push({
    name: "addOAuthScopes",
    params: {
      apiId: props.apiId,
    },
  });
}

/**
 * Handle expanded scopes descriptions.
 */
const expandedScopes = ref([]);
const isScopeExpanded = (scopeName: string) =>
  expandedScopes.value.includes(scopeName);
const expandScope = (scopeName: string) => {
  expandedScopes.value = [...expandedScopes.value, scopeName];
};
const hideScope = (scopeName: string) => {
  expandedScopes.value = expandedScopes.value.filter(
    (expandedScopeName) => expandedScopeName !== scopeName,
  );
};
</script>

<template>
  <CardContainer data-cy="manage-scopes" class="scopes-settings">
    <div class="scopes-settings__title-with-add-button">
      <h4 data-cy="manage-scopes-title" class="scopes-settings__card-title">
        {{ contents.scopesSettingsTitle }}
      </h4>
      <MButton
        data-cy="add-new-scope-button"
        theme="bordered"
        size="s"
        icon="ControlCircleMore24"
        :label="contents.addScopeButtonLabel"
        @click="addScope"
      />
    </div>
    <MDataTableV2
      v-if="hasScopes && scopes.items?.length > 0"
      class="scopes-settings__datatable"
      data-cy="scopes-data-list"
      :items="scopes.items"
      :loading="scopes.isLoading"
    >
      <MDataTableColumn
        class="scopes-settings__datatable-name-column"
        :label="contents.scopeIdTableHeader"
        value="name"
      />
      <MDataTableColumn
        class="scopes-settings__datatable-description-column"
        :label="contents.scopeDescriptionTableHeader"
        value="description"
      />
      <MDataTableColumn value="expandDescription" />
      <MDataTableColumn
        class="scopes-settings__datatable-consumers-column"
        :label="contents.scopeConsumersTableHeader"
        value="usedByConsumers"
      />
      <template #[`cell.name`]="{ item }">
        <div :data-cy="`scope-id-${item.name}`">{{ item.name }}</div>
      </template>
      <template #[`cell.description`]="{ item }">
        <div :data-cy="`scope-description-${item.name}`">
          {{
            item.descriptionCouldBeExpanded && isScopeExpanded(item.name)
              ? item.description
              : item.displayedDescription
          }}
        </div>
      </template>
      <template #[`cell.expandDescription`]="{ item }">
        <ToggleExpandDescriptionButton
          v-if="item.descriptionCouldBeExpanded"
          :isExpanded="isScopeExpanded(item.name)"
          @expandScope="expandScope(item.name)"
          @hideScope="hideScope(item.name)"
        />
      </template>
      <template #[`cell.usedByConsumers`]="{ item }">
        <div
          v-if="item.totalConsumersCount > 0"
          :data-cy="`scope-consumers-${item.name}`"
        >
          {{ pluralize(item.totalConsumersCount, "consumer") }}
        </div>
        <span v-else> - </span>
      </template>
    </MDataTableV2>
  </CardContainer>
</template>

<style lang="scss">
.scopes-settings__title-with-add-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.scopes-settings__datatable {
  margin-top: var(--base-spacing);
}

.scopes-settings__datatable-name-column {
  width: 22rem;
}

.scopes-settings__datatable-description-column {
  padding: 1rem;
}

.scopes-settings__datatable-consumers-column {
  width: 8rem;
}
</style>
