import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "documentation-api"
}
const _hoisted_2 = { class: "documentation-api__header" }
const _hoisted_3 = { class: "documentation-api__page-source" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "documentation-api__page-lastupdate" }
const _hoisted_6 = { class: "documentation-api__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomNotification = _resolveComponent("CustomNotification")!
  const _component_IconButton = _resolveComponent("IconButton")!
  const _component_MIcon = _resolveComponent("MIcon")!
  const _component_DvpTooltip = _resolveComponent("DvpTooltip")!
  const _component_DvpToggle = _resolveComponent("DvpToggle")!
  const _component_ButtonEdit = _resolveComponent("ButtonEdit")!
  const _component_ButtonDelete = _resolveComponent("ButtonDelete")!
  const _component_ActionButtonsWrapper = _resolveComponent("ActionButtonsWrapper")!
  const _component_MDataTable = _resolveComponent("MDataTable")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_ActionZone = _resolveComponent("ActionZone")!
  const _component_CardContainer = _resolveComponent("CardContainer")!

  return (!$options.isLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h3", null, _toDisplayString($data.contents.documentation), 1)
        ]),
        ($options.haveDocWithoutUrl)
          ? (_openBlock(), _createBlock(_component_CustomNotification, {
              key: 0,
              title: $data.contents.urlMethod,
              iconName: "PromiseCertification24",
              description: $data.contents.switchToUrl,
              documentationAnchor: "#add-documentation"
            }, null, 8, ["title", "description"]))
          : _createCommentVNode("", true),
        _createVNode(_component_CardContainer, null, {
          default: _withCtx(() => [
            ($options.pagesList.length > 0)
              ? (_openBlock(), _createBlock(_component_MDataTable, {
                  key: 0,
                  class: "documentation-api__pages",
                  headers: $options.tableHeaders,
                  source: $options.pagesList,
                  sorting: { mode: 'none' }
                }, {
                  [`item.order`]: _withCtx(({ item, rowIndex }) => [
                    _createVNode(_component_IconButton, {
                      disabled: $options.isFirstItem(rowIndex),
                      mozaicIconName: "ArrowArrowTop24",
                      onClick: ($event: any) => ($options.moveUp(item, rowIndex))
                    }, null, 8, ["disabled", "onClick"]),
                    _createVNode(_component_IconButton, {
                      disabled: $options.isLastItem(rowIndex),
                      mozaicIconName: "ArrowArrowBottom24",
                      onClick: ($event: any) => ($options.moveDown(item, rowIndex))
                    }, null, 8, ["disabled", "onClick"])
                  ]),
                  [`item.name`]: _withCtx(({ item }) => [
                    _createElementVNode("div", null, _toDisplayString(item.title), 1)
                  ]),
                  [`item.type`]: _withCtx(({ item }) => [
                    _createElementVNode("div", null, _toDisplayString($options.getTypeLabel(item.type)), 1)
                  ]),
                  [`item.source`]: _withCtx(({ item }) => [
                    _createElementVNode("span", _hoisted_3, [
                      _createTextVNode(_toDisplayString($options.getPageSource(item)) + " ", 1),
                      ($options.getPageSource(item) !== $data.EDocumentationPageSource.URL)
                        ? (_openBlock(), _createBlock(_component_DvpTooltip, {
                            key: 0,
                            text: $data.contents.preferUrlMethod
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_MIcon, {
                                name: "NotificationWarning24",
                                color: "var(--color-status-warning)"
                              })
                            ]),
                            _: 1
                          }, 8, ["text"]))
                        : _createCommentVNode("", true),
                      ($options.getPageSource(item) === $data.EDocumentationPageSource.URL)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            ($options.isWorkerPending(item))
                              ? (_openBlock(), _createBlock(_component_DvpTooltip, {
                                  key: 0,
                                  class: "documentation-api__page-source-status",
                                  text: $data.contents.pendingFetch
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("img", {
                                      src: $data.autoRefreshIcon,
                                      class: "documentation-api__status-pending-icon"
                                    }, null, 8, _hoisted_4)
                                  ]),
                                  _: 1
                                }, 8, ["text"]))
                              : ($options.isWorkerError(item))
                                ? (_openBlock(), _createBlock(_component_DvpTooltip, {
                                    key: 1,
                                    class: "documentation-api__page-source-status",
                                    text: $options.getPageError(item)
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_MIcon, {
                                        name: "NotificationWarning24",
                                        color: "var(--color-danger)"
                                      })
                                    ]),
                                    _: 2
                                  }, 1032, ["text"]))
                                : (_openBlock(), _createBlock(_component_DvpTooltip, {
                                    key: 2,
                                    class: "documentation-api__page-source-status",
                                    text: $data.contents.fetchSuccess
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_MIcon, {
                                        name: "NotificationCircleAvailable24",
                                        color: "var(--color-primary)"
                                      })
                                    ]),
                                    _: 1
                                  }, 8, ["text"]))
                          ], 64))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  [`item.lastUpdate`]: _withCtx(({ item }) => [
                    _createElementVNode("div", _hoisted_5, _toDisplayString($options.formatDate(item.updatedAt)), 1)
                  ]),
                  [`item.actions`]: _withCtx(({ item }) => [
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_DvpTooltip, {
                        text: $options.publishingToggleError(item),
                        disabled: $options.canTogglePublish(item)
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_DvpToggle, {
                            label: $options.getPublishStatus(item.isPublished),
                            checked: item.isPublished,
                            disabled: !$options.canTogglePublish(item),
                            onClick: ($event: any) => ($options.togglePublish(item))
                          }, null, 8, ["label", "checked", "disabled", "onClick"])
                        ]),
                        _: 2
                      }, 1032, ["text", "disabled"]),
                      _createVNode(_component_ActionButtonsWrapper, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_IconButton, {
                            mozaicIconName: "DisplayRefresh24",
                            disabled: !$options.hasUrl(item) || $options.isWorkerPending(item),
                            onClick: ($event: any) => ($options.refreshPage(item))
                          }, null, 8, ["disabled", "onClick"]),
                          _createVNode(_component_ButtonEdit, {
                            onClick: ($event: any) => ($options.editApiDocumentationPage(item))
                          }, null, 8, ["onClick"]),
                          _createVNode(_component_ButtonDelete, {
                            disabled: !$options.canUnpublish(item),
                            onClick: ($event: any) => ($options.removePage(item))
                          }, null, 8, ["disabled", "onClick"])
                        ]),
                        _: 2
                      }, 1024)
                    ])
                  ]),
                  _: 2
                }, 1032, ["headers", "source"]))
              : _createCommentVNode("", true),
            _createVNode(_component_ActionZone, null, {
              default: _withCtx(() => [
                _createVNode(_component_DvpTooltip, {
                  text: $data.contents.reachedDocumentationLimitTooltip,
                  disabled: !$options.hasReachedDocumentationLimit
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_MButton, {
                      class: "action-zone__button",
                      label: $data.contents.addDocumentation,
                      size: "l",
                      icon: "ControlCircleMore24",
                      disabled: $options.hasReachedDocumentationLimit,
                      onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.addApiDocumentation()))
                    }, null, 8, ["label", "disabled"])
                  ]),
                  _: 1
                }, 8, ["text", "disabled"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}