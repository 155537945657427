import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, renderSlot as _renderSlot, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "expansion-panel radius" }
const _hoisted_2 = { class: "header-title" }
const _hoisted_3 = {
  key: 0,
  class: "gap-400"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconChevronDown = _resolveComponent("IconChevronDown")!
  const _component_IconChevronUp = _resolveComponent("IconChevronUp")!
  const _component_ToggleButton = _resolveComponent("ToggleButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "expansion-panel__header",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.openPanel && $options.openPanel(...args)))
    }, [
      _createElementVNode("div", _hoisted_2, [
        (!$data.isOpen)
          ? (_openBlock(), _createBlock(_component_IconChevronDown, { key: 0 }))
          : (_openBlock(), _createBlock(_component_IconChevronUp, { key: 1 })),
        _createElementVNode("h5", null, _toDisplayString($props.title), 1)
      ]),
      ($props.title !== 'Required')
        ? (_openBlock(), _createBlock(_component_ToggleButton, {
            key: 0,
            disabled: false,
            modelValue: $data.toggleValue,
            "onUpdate:modelValue": $options.sendToggleValue,
            onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
          }, null, 8, ["modelValue", "onUpdate:modelValue"]))
        : _createCommentVNode("", true)
    ]),
    ($data.isOpen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "default")
        ]))
      : _createCommentVNode("", true)
  ]))
}