import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "conversation-item__text-and-date" }
const _hoisted_2 = { class: "conversation-item__text" }
const _hoisted_3 = {
  key: 0,
  class: "conversation-item__text-title"
}
const _hoisted_4 = {
  key: 1,
  class: "conversation-item__message"
}
const _hoisted_5 = { class: "conversation-item__author-and-timestamp" }
const _hoisted_6 = ["href"]
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "conversation-item__date" }
const _hoisted_9 = { class: "conversation-item__avatar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["conversation-item", [
      $options.fromMe($props.conversationItem)
        ? 'conversation-item--from-me'
        : 'conversation-item--from-them',
    ]])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        ($props.conversationItem.title)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString($props.conversationItem.title), 1))
          : _createCommentVNode("", true),
        ($props.conversationItem.message)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString($props.conversationItem.message), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        ($props.conversationItem.author && $props.conversationItem.author.email)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              href: `mailto:${$props.conversationItem.author.email}`,
              target: "_blank"
            }, _toDisplayString($props.conversationItem.author.name), 9, _hoisted_6))
          : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString($props.conversationItem.author.name), 1)),
        _createTextVNode(" , "),
        _createElementVNode("span", _hoisted_8, _toDisplayString($options.requestDate($props.conversationItem.createdAt)), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_9, _toDisplayString($options.authorInitial), 1)
  ], 2))
}