<template>
  <MLink
    class="documentation-link"
    size="s"
    icon="Read24"
    :href="routeHref"
    target="_blank"
  >
    {{ label }}
  </MLink>
</template>

<script lang="ts">
import MLink from "@mozaic-ds/vue-3/src/components/link/MLink.vue";

import { convertRouteToHref } from "@/commons/utils/route-utils";

import contents from "@/commons/contents/documentation";

export default {
  components: {
    MLink,
  },
  props: {
    documentationAnchor: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      contents,
    };
  },
  computed: {
    routeHref() {
      return convertRouteToHref({
        name: "docsMain",
        hash: this.documentationAnchor,
      });
    },
  },
};
</script>
