export default {
  userNameColumnName: "User name",
  ldapColumnName: "LDAP",
  you: "(You)",

  unknownLdap: "unknown LDAP",
  blockedBadgeLabel: "Blocked",

  noMembers: "There is no member in the group",

  userRoleSuccessfullyChanged: "User role edited",
  areYouSureYouWantToRemove: (userName: string) =>
    `Are you sure you want to remove ${userName} from current group?`,
  userSuccessfullyRemoved: "User removed from the group",

  mccGroupInfo:
    "This group is managed by MyCloudConsole, and cannot be edited there.",
  goToMyCloudConsole: "Go to My Cloud Console",
};
