<template>
  <SecondaryNavbar class="secondary-navbar-loading">
    <template #secondary-navbar-header>
      <h3 class="secondary-navbar-loading__header-title with-loader"></h3>
      <p class="secondary-navbar-loading__header-subtitle with-loader"></p>
    </template>
  </SecondaryNavbar>
</template>

<script lang="ts">
import SecondaryNavbar from "@/commons/components/Navbar/SecondaryNavbar.vue";

export default {
  name: "SecondaryNavbarLoading",
  components: {
    SecondaryNavbar,
  },
};
</script>

<style lang="scss">
.secondary-navbar-loading__header-title {
  width: 250px;
  height: 15px;
  margin: 0;
}

.secondary-navbar-loading__header-title,
.secondary-navbar-loading__header-subtitle {
  background-color: var(--color-disabled-content);
  border-radius: 6px;
}
.secondary-navbar-loading__header-subtitle {
  width: 200px;
  height: 10px;
  margin-bottom: 0;
}
</style>
