<template>
  <RouterLink class="card-link" :to="to">
    <CardListed class="card-link__card-listed">
      <slot />
      <template #footer>
        <slot name="footer" />
      </template>
    </CardListed>
  </RouterLink>
</template>

<script lang="ts">
import CardListed from "@/commons/components/CardListed/CardListed.vue";

export default {
  components: { CardListed },
  props: {
    to: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.card-link {
  position: relative;
  text-decoration: none;
  border-radius: var(--m-border-radius-large);
}

.card-link:hover {
  border-radius: var(--m-border-radius-large);
  box-shadow: var(--shadow-default);
}

.card-link__card-listed {
  height: 100%;
}
</style>
