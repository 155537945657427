export default {
  title: "Redirect URIs to use",
  description:
    "**The Authorization Code Flow requires to know to which application URI you want your application to be called to continue the authentication process.**\n\n" +
    "Indeed, your URI's endpoint will be in charge to retrieve the final access token for your authenticated user. You will be able to modify this URI or add new one later.\n\n" +
    "**This URI is common to all your application's subscriptions.**",
  redirectUriField: "Set the first redirect URI",
  redirectUriPlaceholder: "https://yourApp/callback",

  stepperDescription: "Set the first redirect URI",
};
