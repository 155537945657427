import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MField = _resolveComponent("MField")!

  return (_openBlock(), _createBlock(_component_MField, {
    id: $options.computedId,
    class: _normalizeClass(["dvp-field", $options.dynamicClasses]),
    label: $props.label,
    required: $props.required,
    requirementText: $options.internalRequirementText,
    helpId: $options.computedHelpTextId,
    helpText: $props.helpText,
    errorId: $props.errorId,
    errorMessage: $props.errorMessage,
    isInvalid: $options.isInvalid
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["id", "class", "label", "required", "requirementText", "helpId", "helpText", "errorId", "errorMessage", "isInvalid"]))
}