<template>
  <GlobalModalBody class="consumers-on-api-error-modal">
    <template #content>
      <h5 class="consumers-on-api-error-modal__error-message">
        {{ contents.consumersOnApi }}
      </h5>
      <Markdown :content="solutionsForConsumersOnApi" />
    </template>
    <template #footer>
      <MButton
        data-cy="modal-close-btn"
        theme="bordered-neutral"
        :label="contents.closeBtn"
        @click="$emit('onClose')"
      />
    </template>
  </GlobalModalBody>
</template>

<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import { PropType } from "vue";

import GlobalModalBody from "@/commons/components/Modal/GlobalModalBody.vue";
import Markdown from "@/documentation/components/commons/Markdown.vue";

import { EAuthType } from "@/commons/store/types";

import contents from "@/manager/contents/consumers-on-api-error-modal";

export default {
  components: {
    GlobalModalBody,
    MButton,
    Markdown,
  },
  props: {
    requestedAuthType: {
      type: String as PropType<EAuthType>,
      required: true,
    },
  },
  emits: ["onClose"],
  data() {
    return {
      contents,
    };
  },
  computed: {
    solutionsForConsumersOnApi() {
      return this.requestedAuthType == EAuthType.API_KEY
        ? contents.solutionsForConsumersOnApiForChangeToApiKey
        : contents.solutionsForConsumersOnApiForChangeToOauth;
    },
  },
};
</script>

<style lang="scss">
.consumers-on-api-error-modal__error-message {
  color: var(--color-status-danger);
}
</style>
