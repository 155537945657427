import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "authentication-mode-option-card__container" }
const _hoisted_2 = { class: "authentication-mode-option-card__title" }
const _hoisted_3 = { class: "authentication-mode-option-card__description" }
const _hoisted_4 = { class: "authentication-mode-option-card__description-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ViewHelpButton = _resolveComponent("ViewHelpButton")!
  const _component_MOptionCard = _resolveComponent("MOptionCard")!

  return (_openBlock(), _createBlock(_component_MOptionCard, {
    id: $options.optionCardId,
    class: "authentication-mode-option-card",
    label: $props.title,
    modelValue: true,
    noPadding: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h5", _hoisted_2, _toDisplayString($props.title), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("p", _hoisted_4, _toDisplayString($props.description), 1),
          _createVNode(_component_ViewHelpButton, {
            class: "authentication-mode-option-card__view-help-button",
            documentationAnchor: $props.documentationAnchor
          }, null, 8, ["documentationAnchor"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["id", "label"]))
}