<template>
  <Grid v-if="!isLoading" class="settings-api">
    <SettingsForm :currentApi="currentApi" />
    <Organization :currentApi="currentApi" />
    <Timeout :currentApi="currentApi" />
    <SensitiveModifications :currentApi="currentApi" />
    <ManageApiGroups />
  </Grid>
</template>

<script lang="ts">
import Grid from "@/commons/components/Grid.vue";

import ManageApiGroups from "./ManageApiGroups.vue";
import Organization from "./Organization.vue";
import SensitiveModifications from "./SensitiveModifications.vue";
import SettingsForm from "./SettingsForm.vue";
import Timeout from "./Timeout.vue";

export default {
  name: "SettingsApi",
  components: {
    Grid,
    SettingsForm,
    Timeout,
    SensitiveModifications,
    Organization,
    ManageApiGroups,
  },
  props: {
    apiId: {
      type: Boolean,
    },
  },
  computed: {
    currentApi() {
      return this.$store.getters["currentApi"];
    },
    isLoading() {
      return this.$store.getters["isLoading"];
    },
  },
};
</script>

<style lang="scss" scoped>
.settings-api {
  --grid-layout-item-width: 30rem;
  --grid-auto-rows: auto;
}
</style>
