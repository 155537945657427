<template>
  <Card class="organization">
    <h4>{{ contents.title }}</h4>
    <form class="organization__form" @submit.prevent="submit">
      <div>
        <FormInput
          v-if="platformDescriptionTag"
          :label="contents.platformLabel"
          data-cy="platform"
          :tooltip="contents.tooltipPlatform"
        >
          <TextInput :modelValue="platformDescriptionTag" isDisabled />
        </FormInput>
        <FormInput
          class="split-after"
          :label="contents.businessUnit"
          :tooltip="contents.tooltipBu"
          :error="form.firstError('businessUnitTags')"
        >
          <AutoCompleteSelect
            v-model="form.businessUnitTags"
            label-property="label"
            key-property="id"
            multiple
            :options="buTagsList"
            :placeholder="contents.businessUnitsPlaceholder"
          />
        </FormInput>
      </div>

      <div class="pushed-right">
        <MButton
          theme="bordered-neutral"
          :label="contents.cancel"
          @click="cancel"
        />
        <MButton
          :label="contents.apply"
          :disabled="!!form.errors.length || form.pristine"
          type="submit"
        />
      </div>
    </form>
  </Card>
</template>

<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";

import AutoCompleteSelect from "@/commons/components/AutoCompleteSelect.vue";
import Card from "@/commons/components/Card.vue";
import FormInput from "@/commons/components/FormInput.vue";
import TextInput from "@/commons/components/TextInput.vue";

import { getOrderedBuTagsList } from "@/commons/utils/filter-utils";

import { Dictionary } from "@/commons/domain/models/dictionary";
import { Tag } from "@/commons/domain/models/tag";
import { ApiUpdateOrganization } from "@/manager/domain/forms/api-update-organization";

import { ETagType } from "@/commons/store/types";

import contents from "@/manager/contents/organization";

export default {
  name: "Organization",
  components: {
    Card,
    FormInput,
    AutoCompleteSelect,
    TextInput,
    MButton,
  },
  props: {
    currentApi: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      contents,
      form: ApiUpdateOrganization.create(),
      persistedOrganization: undefined,
    };
  },
  computed: {
    isSaving() {
      return this.$store.getters["isSaving"];
    },
    currentApiBussinesUnits() {
      return this.$store.getters["currentApiBussinesUnits"];
    },
    platformDescriptionTag() {
      const platformTag: Tag = Object.values(
        this.currentApi.tags as Dictionary<Tag>,
      ).find((tag) => tag.type === ETagType.PLATFORM);
      return platformTag?.description;
    },
    buTagsList() {
      let businessUnits = getOrderedBuTagsList(
        this.$store.getters["buTagsList"],
      );

      return businessUnits.map(({ id, label, description }) => {
        return description
          ? { id, label: `${label} - ${description}` }
          : { id, label };
      });
    },
  },
  async mounted() {
    const currentApiBussinesUnits: Tag[] = this.currentApiBussinesUnits;

    await this.form.init({
      businessUnitTags: currentApiBussinesUnits.map(
        ({ id, label, description }) => {
          return description
            ? { id, label: `${label} - ${description}` }
            : { id, label };
        },
      ),
    });

    this.persistedOrganization = this.form.data();
  },
  methods: {
    async submit() {
      await this.form.validate();

      if (!this.form.errors.length && !this.isSaving) {
        const { businessUnitTags } = this.form.data();

        const buTagIds = businessUnitTags.map(
          (businessUnitTag) => businessUnitTag.id,
        );
        this.persistedSettings = this.form.data();

        await this.$store.dispatch("updateApiOrganization", {
          buTagIds,
        });
      }
    },
    async cancel() {
      await this.form.init(this.persistedOrganization);
    },
  },
};
</script>

<style>
.organization__form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.organization__form .form-input {
  margin-bottom: var(--base-spacing);
}
</style>
