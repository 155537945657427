<template>
  <div class="request-manager-list">
    <div v-if="hasNoRequests" class="list--without-items">
      <div class="no-items-glasses">
        <img
          :src="
            require('@/commons/assets/images/requests/anim_thug-panda-glasses.svg')
          "
        />
      </div>
      <div class="no-items-panda">
        <img
          :src="require('@/commons/assets/images/requests/anim_thug-panda.svg')"
        />
      </div>
      <div class="no-items-label">
        You are the boss,<br />
        everything is done here
      </div>
    </div>
    <div v-else-if="!isLoadingRequestSummariesList" class="list--with-items">
      <div
        v-if="
          !isLoadingPreselectedRequest &&
          preselectedRequestIsOutOfRequestsListScope
        "
      >
        <div @click="selectItem(preselectedRequest)">
          <RequestManagerListItem
            data-cy="preselected-request"
            :request="preselectedRequest"
            :selected="preselectedRequest.id === currentRequest.id"
            :mine="
              preselectedRequest.author &&
              preselectedRequest.author.id === currentUserId
            "
            @vue:mounted="scrollToSelectedItem()"
          />
        </div>
        <Divider class="request-manager-list__divider" size="m"></Divider>
      </div>
      <div
        v-for="request in requestSummariesList"
        :key="request.id"
        @click="selectItem(request)"
      >
        <RequestManagerListItem
          :request="request"
          :selected="request.id === currentRequest.id"
          :mine="request.author && request.author.id === currentUserId"
          @vue:mounted="scrollToSelectedItem()"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Divider from "@/commons/components/Divider.vue";

import RequestManagerListItem from "./RequestManagerListItem.vue";

export default {
  name: "RequestManagerList",
  components: { RequestManagerListItem, Divider },
  computed: {
    currentUserId() {
      return this.$store.getters["user"]?.id;
    },
    requestSummariesList() {
      return this.$store.getters["requestSummariesList"];
    },
    preselectedRequest() {
      return this.$store.getters["preselectedRequest"];
    },
    isLoadingRequestSummariesList() {
      return this.$store.getters["isLoadingProperty"]("requestSummaries");
    },
    isLoadingPreselectedRequest() {
      return this.$store.getters["isLoadingProperty"]("preselectedRequest");
    },
    hasNoRequests() {
      return (
        this.requestSummariesList && this.requestSummariesList.length === 0
      );
    },
    preselectedRequestIsOutOfRequestsListScope() {
      return (
        this.preselectedRequest &&
        this.requestSummariesList &&
        this.requestSummariesList.find(
          (s) => s.id === this.preselectedRequest.id,
        ) == undefined
      );
    },
    currentRequest() {
      return this.$store.getters["currentRequest"];
    },
  },
  watch: {
    isLoadingPreselectedRequest(isLoading) {
      if (!isLoading && !this.isLoadingRequestSummariesList) {
        this.selectFirstItem();
      }
    },
    isLoadingRequestSummariesList(isLoading) {
      if (!isLoading && !this.isLoadingPreselectedRequest) {
        this.selectFirstItem();
      }
    },
  },

  methods: {
    selectFirstItem() {
      if (this.preselectedRequest != undefined) {
        this.selectItem(this.preselectedRequest);
      } else {
        this.selectItem(this.requestSummariesList[0]);
      }
    },
    selectItem(request) {
      this.$store.commit("patchState", {
        requestManager: {
          currentRequest: request,
        },
      });
      this.$nextTick(() => {
        this.scrollToSelectedItem();
      });
    },
    scrollToSelectedItem() {
      const el = document.getElementsByClassName("item__selected")[0];
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.list--without-items {
  position: relative;
  height: 30rem;

  $panda-size: 6rem;
  $panda-margin-top: 8rem;
  /* stylelint-disable-next-line keyframes-name-pattern */
  @keyframes thugLife {
    0% {
      opacity: 0;
      transform: translateY(-$panda-margin-top);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .no-items-panda {
    position: absolute;
    top: $panda-margin-top;
    left: 50%;
    z-index: var(--z-index-middleground);
    width: $panda-size;
    height: $panda-size;
    margin-left: -0.5 * $panda-size;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .no-items-glasses {
    position: absolute;
    top: $panda-margin-top;
    left: 50%;
    z-index: var(--z-index-foreground);
    width: $panda-size;
    margin-top: -0.2rem;
    margin-left: -0.5 * $panda-size;
    animation: 2s ease-out 0s 1 thugLife;
  }

  .no-items-label {
    position: absolute;
    top: $panda-margin-top + $panda-size;
    width: 100%;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
  }
}

.list--with-items {
  display: block;
}

.request-manager-list__divider {
  margin-top: 3px;
  margin-bottom: 3px;
  background-color: black;
}
</style>
