import { Dictionary } from "@/commons/domain/models/dictionary";
import { PayloadUpdateRoute } from "@/commons/domain/models/payload-update-route";
import { Route } from "@/commons/domain/models/route";
import { PayloadUpdateRouteDTO } from "@/commons/dtos/payload-update-route-dto";
import { RouteDTO } from "@/commons/dtos/route-dto";
import { OauthDefinitionMapper } from "@/commons/mappers/oauth-definition-mapper";
import { RoutingPolicyMapper } from "@/commons/mappers/routing-policies-mapper";

export class RoutesMapper {
  static toDomain(routeDTOs: RouteDTO[]): Dictionary<Route> {
    return routeDTOs.reduce((routeDTODictionary, routeDTO) => {
      routeDTODictionary[routeDTO.id] = {
        id: routeDTO.id,
        zoneIds: routeDTO.zoneIds,
        url: routeDTO.url,
        isPublic: routeDTO.isPublic,
        isIpFilteringEnabled: routeDTO.isIpFilteringEnabled,
        status: routeDTO.status,
        type: routeDTO.type,
        publicationDate: routeDTO.publicationDate,
        isRemovable: routeDTO.isRemovable,
        isDeprecated: routeDTO.isDeprecated,
        deprecationDate: routeDTO.deprecationDate,
        monitoring: routeDTO.monitoring
          ? {
              isEnabled: routeDTO.monitoring && routeDTO.monitoring.isEnabled,
              url: routeDTO.monitoring && routeDTO.monitoring.url,
            }
          : undefined,
        isPublishable: routeDTO.isPublishable,
        label: routeDTO.label,
        routingPolicies: RoutingPolicyMapper.toDomain(routeDTO.routingPolicies),
        oauthDefinitions: OauthDefinitionMapper.toDomain(
          routeDTO.oauthDefinitions,
        ),
        allowedActions: routeDTO.allowedActions,
      };
      return routeDTODictionary;
    }, {});
  }

  static toUpdateRouteDTO(
    payloadUpdateRoute: PayloadUpdateRoute,
  ): PayloadUpdateRouteDTO {
    return {
      routeId: payloadUpdateRoute.routeId,
      url: payloadUpdateRoute.url,
      apiId: payloadUpdateRoute.apiId,
      isPublishable: payloadUpdateRoute.isPublishable,
      label: payloadUpdateRoute.label,
      status: payloadUpdateRoute.status,
      zoneIds: payloadUpdateRoute.zoneIds,
      routingPolicies: RoutingPolicyMapper.toDTOs(
        payloadUpdateRoute.routingPolicies,
      ),
      isIpFilteringEnabled: payloadUpdateRoute.isIpFilteringEnabled,
    };
  }
}
