<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import MNotification from "@mozaic-ds/vue-3/src/components/notification/MNotification.vue";
import MSelect from "@mozaic-ds/vue-3/src/components/select/MSelect.vue";

import CardContainer from "@/commons/components/CardContainer.vue";
import LayoutSection from "@/commons/components/LayoutSection.vue";
import HelpLink from "@/commons/components/UserDocumentationLinks/HelpLink.vue";
import Markdown from "@/documentation/components/commons/Markdown.vue";

import { valuesToMSelectOptions } from "@/commons/utils/mozaic-utils";

import { fetchBatchesNames } from "@/commons/services/batch/batch-service";
import { runBatchManually } from "@/commons/services/batch/batch-service";

import contents from "@/admin/contents/batches-management";

export default {
  components: {
    MButton,
    MNotification,
    CardContainer,
    Markdown,
    HelpLink,
    MSelect,
    LayoutSection,
  },

  data() {
    return {
      contents,
      batchesNames: [],
      selectedBatchName: undefined,
      batchLaunched: false,
    };
  },
  computed: {
    enabledRunButton(): boolean {
      return this.selectedBatchName !== undefined && !this.batchLaunched;
    },
    batchesNamesOptions() {
      return valuesToMSelectOptions([...this.batchesNames]);
    },
    batchDashboardUrl(): string {
      const baseUrl = this.$store.getters["config/batchDashboardUrl"];
      return this.selectedBatchName
        ? `${baseUrl}&tpl_var_batch[0]=${this.selectedBatchName.toLowerCase()}`
        : baseUrl;
    },
    batchDocumentationUrl(): string {
      return this.$store.getters["config/batchDocumentationUrl"];
    },
  },
  async mounted() {
    this.batchesNames = await fetchBatchesNames();
  },
  methods: {
    async launchSync() {
      if (this.selectedBatchName == undefined) {
        return;
      }
      await runBatchManually(this.selectedBatchName);
      this.$store.commit("postSuccessNotification", {
        title: this.contents.launchedSyncToastTitle,
        message: this.contents.launchedSyncToastMessage,
      });
      this.batchLaunched = true;
    },
    reset() {
      this.batchLaunched = false;
    },
  },
};
</script>

<template>
  <LayoutSection class="batches-management">
    <CardContainer class="batches-management__card">
      <div class="batches-management__card-content">
        <div class="batches-management__card-content-details">
          <h5>{{ contents.batchesManagementTitle }}</h5>
          <Markdown :content="contents.batchesManagementDescription" />

          <div>
            <HelpLink
              :href="batchDocumentationUrl"
              :label="contents.readDocumentationLink"
              openInNewTab
            />
          </div>
        </div>
        <div class="batches-management__select-and-run">
          <MSelect
            id="batch-name-select"
            v-model="selectedBatchName"
            :options="batchesNamesOptions"
            :placeholder="contents.selectBatchName"
            @update:modelValue="reset"
          />
          <MButton
            class="batches-management__run-button"
            :label="contents.runBtnLabel"
            :disabled="!enabledRunButton"
            @click="launchSync"
          />
        </div>
        <div>
          <HelpLink
            class="batches-management__dashboard-link"
            :href="batchDashboardUrl"
            :label="contents.followBatchExecutionStatus"
            openInNewTab
          />
        </div>
      </div>
      <MNotification
        class="batches-management__warning-notification"
        type="warning"
      >
        {{ contents.warningMessage }}
      </MNotification>
    </CardContainer>
  </LayoutSection>
</template>

<style lang="scss">
.batches-management__card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.batches-management__card-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.batches-management__card-content-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.batches-management__run-button {
  width: 10rem;
}

.batches-management__warning-notification {
  width: 18rem;
  height: 5.5rem;
}

.batches-management__select-and-run {
  display: flex;
  column-gap: 2rem;
}

.batches-management__dashboard-link {
  float: right;
}
</style>
