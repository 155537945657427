import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "product-list-item" }
const _hoisted_2 = { class: "product-list-item__header" }
const _hoisted_3 = ["alt", "src"]
const _hoisted_4 = { class: "product-list-item__body" }
const _hoisted_5 = { class: "product-list-item__content" }
const _hoisted_6 = { class: "product-list-item__title" }
const _hoisted_7 = { class: "product-list-item__description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "product-list-item__image",
        alt: $props.product.name,
        src: $options.productIcon
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, _toDisplayString($props.product.name), 1),
        _createElementVNode("div", _hoisted_7, _toDisplayString($props.product.description), 1)
      ])
    ])
  ]))
}