<script setup lang="ts">
import { computed, PropType, reactive, onMounted } from "vue";
import { useStore } from "vuex";

import CardContainer from "@/commons/components/CardContainer.vue";
import IconList from "@/commons/components/Icons/IconList.vue";
import ScopesList from "@/manager/views/AddOAuthScopes/ScopesList.vue";

import { Scope } from "@/commons/domain/models/scope";

import contents from "@/manager/contents/add-oauth-scopes";

const props = defineProps({
  apiId: {
    type: String,
    required: true,
  },
  selectedScopes: {
    type: Array as PropType<Scope[]>,
    required: true,
  },
  selectionDisabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  "selectScope",
  "showScopeProducts",
  "changeSelectedScopesVisibility",
]);

const store = useStore();

const documentedScopes = computed(() => {
  return store.getters["documentedScopes"];
});

/**
 * Load scopes from the API (currently fetched from PING).
 * Descriptions are not saved on devportal's side, they are fetched each time from PING.
 * That is why we need to fetch them each time when the page is loaded.
 */

const scopes = reactive({
  items: computed(() => {
    return store.getters["referencedScopesList"].filter(
      (scope: Scope) =>
        !documentedScopes.value.find(
          (documentedScope: Scope) => documentedScope.name === scope.name,
        ),
    );
  }),
  isLoading: computed(() =>
    store.getters["isLoadingProperty"]("referencedScopes"),
  ),
  load: async (
    params: { search?: string; page?: number; size?: number } = {},
  ) => {
    await store.dispatch("loadReferencedScopes", {
      params: {
        apiId: props.apiId,
        name: params.search,
        page: params.page,
        size: params.size,
      },
    });
    emit("changeSelectedScopesVisibility", scopes.items);
  },
  totalCount: computed(() => store.getters["referencedScopesTotalCount"]),
  currentPage: computed(() => store.getters["referencedScopesCurrentPage"]),
});

const handleSelectScope = (scope: Scope, isSelected: boolean) => {
  emit("selectScope", scope, isSelected);
  emit("changeSelectedScopesVisibility", scopes.items);
};

onMounted(async () => {
  await scopes.load();
});
</script>

<template>
  <CardContainer class="referenced-scopes-list">
    <div class="referenced-scopes-list__header">
      <IconList />
      <h5>{{ contents.referencedScopesHeader }}</h5>
    </div>

    <ScopesList
      :apiId="apiId"
      :selectedScopes="selectedScopes"
      :scopes="scopes.items"
      :isLoading="scopes.isLoading"
      :totalCount="scopes.totalCount"
      :currentPage="scopes.currentPage"
      :selectionDisabled="selectionDisabled"
      @loadScopes="scopes.load($event)"
      @selectScope="handleSelectScope"
      @showScopeProducts="emit('showScopeProducts', $event)"
    />
  </CardContainer>
</template>

<style lang="scss">
.referenced-scopes-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  &__header {
    display: flex;
    gap: 0.5rem;

    align-items: center;
  }
}
</style>
