import { resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "responsive-icon-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MButton = _resolveComponent("MButton")!
  const _component_MTooltip = _resolveComponent("MTooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($options.showSmallButton)
      ? (_openBlock(), _createBlock(_component_MTooltip, {
          key: 0,
          id: $options.tooltipId,
          label: $options.computedTooltipText,
          position: $props.tooltipPosition
        }, {
          default: _withCtx(() => [
            _createVNode(_component_MButton, _normalizeProps(_guardReactiveProps(_ctx.$attrs)), null, 16)
          ]),
          _: 1
        }, 8, ["id", "label", "position"]))
      : (_openBlock(), _createBlock(_component_MButton, _mergeProps({ key: 1 }, _ctx.$attrs, { label: $props.label }), null, 16, ["label"]))
  ]))
}