import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "menu-icon" }
const _hoisted_2 = { class: "menu-icon__wrapper" }
const _hoisted_3 = {
  key: 0,
  class: "menu-icon__notifications"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MIcon = _resolveComponent("MIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_MIcon, { name: $props.mIconName }, null, 8, ["name"]),
      ($props.withNotifications)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3))
        : _createCommentVNode("", true)
    ])
  ]))
}