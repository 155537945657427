import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sensitive-modifications-list" }
const _hoisted_2 = { class: "sensitive-modifications-list__description" }
const _hoisted_3 = { class: "sensitive-modifications-list__header" }
const _hoisted_4 = { class: "sensitive-modifications-list__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MButton = _resolveComponent("MButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h5", _hoisted_3, _toDisplayString($props.title), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString($props.description), 1)
    ]),
    _createVNode(_component_MButton, {
      "data-cy": "sensitive-modification-btn",
      theme: "bordered-danger",
      label: $props.btnTitle,
      disabled: $props.isDisabled,
      onClick: $props.btnAction
    }, null, 8, ["label", "disabled", "onClick"])
  ]))
}