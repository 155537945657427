module.exports = {
  deprecateModalTitle: "Deprecate API",
  undeprecateModalTitle: "Undeprecate API",
  deprecateTitle: (apiLabel) => `You are about to deprecate ${apiLabel}`,
  undeprecateTitle: (apiLabel) => `You are about to undeprecate ${apiLabel}`,
  prompt: (apiName) =>
    `If you really want to proceed, type <b>${apiName}</b>, then confirm.`,
  cancel: "Cancel",
  confirm: "Confirm",
  confirmApiNameErrorMessage: "You have to write the text ",
  confirmApiNameLabel: "Type your api name",
  confirmApiName: "Api Name",
};
