import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "declare-ip-addresses-card__card-title" }
const _hoisted_2 = { class: "declare-ip-addresses-card__ip-addresses-listing-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Markdown = _resolveComponent("Markdown")!
  const _component_ButtonEdit = _resolveComponent("ButtonEdit")!
  const _component_ButtonDelete = _resolveComponent("ButtonDelete")!
  const _component_ActionButtonsWrapper = _resolveComponent("ActionButtonsWrapper")!
  const _component_MDataTable = _resolveComponent("MDataTable")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_CardContainer = _resolveComponent("CardContainer")!

  return ($options.appHasSubscriptionWithPublicApi)
    ? (_openBlock(), _createBlock(_component_CardContainer, {
        key: 0,
        class: "declare-ip-addresses-card",
        "data-cy": "ip-filtering"
      }, {
        default: _withCtx(() => [
          _createElementVNode("h4", _hoisted_1, _toDisplayString($data.contents.publicIPAddressesTitle), 1),
          _createVNode(_component_Markdown, {
            content: $data.contents.publicIPAddressesDescription
          }, null, 8, ["content"]),
          ($options.ipAddresses?.length > 0)
            ? (_openBlock(), _createBlock(_component_MDataTable, {
                key: 0,
                "data-cy": "ip-filtering-datatable",
                class: "declare-ip-addresses-card__ip-addresses-listing",
                sorting: { mode: 'none' },
                headers: $options.tableHeaders,
                source: $options.ipAddresses
              }, {
                [`item.actions`]: _withCtx(({ item }) => [
                  _createVNode(_component_ActionButtonsWrapper, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ButtonEdit, {
                        "data-cy": "edit-ip-address-button",
                        onClick: ($event: any) => ($options.onEditIPAddressClick(item))
                      }, null, 8, ["onClick"]),
                      _createVNode(_component_ButtonDelete, {
                        "data-cy": "delete-ip-address-button",
                        onClick: ($event: any) => ($options.onDeleteIPAddressClick(item))
                      }, null, 8, ["onClick"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["headers", "source"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_MButton, {
              "data-cy": "declare-new-ip-address",
              label: $data.contents.addNewIPAddressBtnLabel,
              type: "button",
              onClick: $options.onAddIPAddressClick
            }, null, 8, ["label", "onClick"])
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}