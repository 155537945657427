<template>
  <slides-layout class="use-case">
    <template #slides>
      <slide class="use-case__main" anchor="use-case-main">
        <use-case-main
          :use-case="useCase"
          @navigateToAnchor="navigateToAnchor($event)"
        />
      </slide>

      <slide
        v-for="(feature, index) in useCase.features"
        :key="`use-case-feature-${feature.name}`"
        class="use-case-feature__detail"
        :anchor="`use-case-feature-${feature.name}`"
      >
        <use-case-feature v-bind="feature" :index="index" />
      </slide>

      <slide anchor="use-case-contact-us">
        <contact-us />
        <footer-menus />
      </slide>
    </template>
  </slides-layout>
</template>

<script lang="ts">
import ContactUs from "@/commons/components/ContactUs.vue";
import FooterMenus from "@/commons/components/FooterMenus.vue";
import Slide from "@/commons/components/SlidesLayout/Slide.vue";
import SlidesLayout from "@/commons/components/SlidesLayout/SlidesLayout.vue";

import UseCaseFeature from "./UseCaseFeature.vue";
import UseCaseMain from "./UseCaseMain.vue";

import useCases from "@/showcase/contents/use-cases";

export default {
  name: "UseCase",
  components: {
    SlidesLayout,
    Slide,
    UseCaseMain,
    UseCaseFeature,
    ContactUs,
    FooterMenus,
  },
  extends: SlidesLayout,
  data() {
    return { useCases };
  },
  computed: {
    useCase() {
      return this.useCases.find(
        (useCase) => useCase.slug === this.$route.params.slug,
      );
    },
  },
};
</script>

<style lang="scss">
@import "~@/commons/assets/styles/vars";

.use-case__main {
  position: relative;
  background-color: var(--color-leroy-merlin);

  &::before {
    position: absolute;
    bottom: -6rem;
    left: 0;
    width: 100%;
    height: 20vh;
    content: "";
    background: var(--color-background-interface);
    transform: skewY(5deg);
  }
}

.use-case-feature__detail {
  position: relative;
  background: var(--color-background-interface);

  &::before {
    position: absolute;
    bottom: -6rem;
    left: 0;
    width: 100%;
    height: 20vh;
    content: "";
    background: var(--color-white);
    transform: skewY(-5deg);
  }

  &:nth-child(odd) {
    background-color: var(--color-white);

    &::before {
      position: absolute;
      bottom: -6rem;
      left: 0;
      width: 100%;
      height: 20vh;
      content: "";
      background: var(--color-background-interface);
      transform: skewY(5deg);
    }
  }
}

#use-case-contact-us {
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background: var(--color-white);
}

@media only screen and (max-width: map-get($breakpoints, tablet-portrait)) {
  .use-case-feature__detail {
    justify-content: center;

    &::before {
      bottom: -35vh;
      height: 100vh;
    }

    &:nth-child(odd) {
      background-color: var(--color-white);

      &::before {
        bottom: -35vh;
        height: 100vh;
      }
    }
  }
}

@media only screen and (max-width: map-get($breakpoints, mobile)) {
  .use-case__main {
    position: relative;
    background-color: var(--color-leroy-merlin);

    &::before {
      display: none;
    }
  }

  .use-case-feature__detail {
    position: relative;
    background: var(--color-background-interface);

    &::before {
      bottom: -6rem;
      height: 20vh;
    }

    &:nth-child(odd) {
      background-color: var(--color-white);

      &::before {
        bottom: -6rem;
        height: 20vh;
      }
    }
  }
}
</style>
