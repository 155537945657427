<template>
  <div class="box box-layout">
    <slot />
  </div>
</template>

<script lang="ts">
export default {
  name: "Box",
  props: {
    isCentered: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
