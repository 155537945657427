import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconGateways = _resolveComponent("IconGateways")!
  const _component_IconNoGateways = _resolveComponent("IconNoGateways")!
  const _component_SvgWithLabel = _resolveComponent("SvgWithLabel")!

  return (_openBlock(), _createBlock(_component_SvgWithLabel, { class: "zones-list" }, {
    right: _withCtx(() => [
      ($props.zones.length)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createTextVNode(_toDisplayString($props.zones.join(", ")), 1)
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createTextVNode(_toDisplayString($data.contents.notPublishedOnGateways), 1)
          ], 64))
    ]),
    default: _withCtx(() => [
      ($props.zones.length)
        ? (_openBlock(), _createBlock(_component_IconGateways, {
            key: 0,
            class: "zones-list__icon-gateway"
          }))
        : (_openBlock(), _createBlock(_component_IconNoGateways, {
            key: 1,
            class: "zones-list__icon-no-gateway"
          }))
    ]),
    _: 1
  }))
}