<template>
  <div class="home-use-cases">
    <div class="home-use-cases__description">
      <!--eslint-disable-next-line vue/no-v-html-->
      <h1 class="title" v-html="contents.allUseCasesTitle" />
      <p class="description">{{ contents.allUseCasesDescription }}</p>
      <div class="home-use-cases__buttons">
        <MButton
          :label="contents.allUseCasesButtonLabel"
          theme="solid-neutral"
          @click="() => $router.push({ name: 'useCases' })"
        />
      </div>
    </div>
    <use-case-card
      v-for="(useCase, index) in useCases"
      :key="`use-case-${index}`"
      :image="useCase.image[locationSettings.name]"
      :title="useCase.title"
      :description="useCase.description"
      :path="`/usecases/${useCase.slug}`"
    />
  </div>
</template>

<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";

import UseCaseCard from "@/commons/components/UseCaseCard.vue";

import useCases from "@/showcase/contents/use-cases";

const contents = {
  allUseCasesTitle:
    "<strong>Meet our awesome use cases</strong> created by our partners and users",
  allUseCasesDescription:
    "Discover how and why they use our APIs to help them build incredible apps",
  allUseCasesButtonLabel: "See all case studies",
};

export default {
  name: "HomeUseCases",
  components: {
    UseCaseCard,
    MButton,
  },
  data() {
    return {
      contents,
      useCases,
    };
  },
  computed: {
    locationSettings() {
      return this.$store.getters["config/locationSettings"];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/commons/assets/styles/vars";
@import "~@/showcase/views/common/home";

.home-use-cases {
  position: relative;

  display: flex;
  flex-wrap: wrap;
  align-items: center;

  width: 91.4rem;
}

.home-use-cases__buttons {
  display: flex;
  justify-content: center;
}

.home-use-cases .button {
  width: auto;
  height: 3.57rem;
  padding: 0 2.14rem;
  margin: 3.57rem 1.79rem 1.79rem 0;
  font-size: 1.29rem;
}

.home-use-cases__description {
  display: flex;
  flex: 0.34;
  flex-direction: column;

  height: 32.14rem;
  padding: 3rem;
  margin-right: 2rem;

  &:last-child {
    margin: 0;
  }
}

@media only screen and (max-width: map-get($breakpoints, desktop-small)) {
  .home-use-cases {
    flex-wrap: nowrap;
    width: 92%;
  }

  .home-use-cases__description {
    flex: 0.3 30%;
  }
}

@media only screen and (max-width: map-get($breakpoints, tablet-landscape)) {
  .home-use-cases {
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;

    width: 90%;
  }

  .home-use-cases .button {
    margin: 2rem 0 3rem;
  }

  .home-use-cases__description {
    flex: 1 100%;

    height: auto;
    padding: 0;
    margin: 0;

    text-align: center;
  }

  .use-case-card {
    display: flex;
    flex: 0.33 calc(33% - 4rem);

    max-height: 26rem;
    padding: 1.5rem 3rem;
  }
}

@media only screen and (max-width: map-get($breakpoints, tablet-portrait)) {
  .home-use-cases .button {
    margin: 2rem 0 4rem;
  }

  .home-use-cases__description {
    flex: 1 100%;

    height: auto;
    padding: 0;
    margin: 4rem 0 0;

    text-align: center;
  }

  .use-case-card {
    display: flex;
    flex: 0.4 calc(40% - 4rem);

    max-height: 30rem;
    padding: 2rem;
  }
}

@media only screen and (max-width: map-get($breakpoints, mobile)) {
  .home-use-cases {
    margin: 0 0 3rem;
  }

  .home-use-cases .button {
    width: 100%;
    margin: 2rem 0 4rem;
  }

  .home-use-cases__description {
    margin: 0;
  }

  .use-case-card {
    display: flex;

    flex: 1 90%;
    max-height: auto;
    padding: 3rem 2rem;
    margin: 0 0 2rem;
  }
}
</style>
