export function _instanceof(left, right) {
  if (
    right != null &&
    typeof Symbol !== "undefined" &&
    right[Symbol.hasInstance]
  ) {
    return right[Symbol.hasInstance](left);
  } else {
    return left instanceof right;
  }
}

export function noop() {}

export function getDataAttr(el, prop) {
  return el.dataset ? el.dataset[prop] : el.getAttribute("data-" + prop);
}

export function isObject(val) {
  return Object.prototype.toString.call(val) === "[object Object]";
}

export function extend(src, props) {
  for (const prop in props) {
    // eslint-disable-next-line no-prototype-builtins
    if (props.hasOwnProperty(prop)) {
      const val = props[prop];

      if (val && isObject(val)) {
        src[prop] = src[prop] || {};
        extend(src[prop], val);
      } else {
        src[prop] = val;
      }
    }
  }

  return src;
}

export function throttle(fn, limit, context) {
  let wait;
  return function () {
    context = context || this;

    if (!wait) {
      fn.apply(context, arguments);
      wait = true;
      return setTimeout(() => {
        wait = false;
      }, limit);
    }
  };
}
