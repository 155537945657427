import store from "@/commons/store";
import { Container } from "@/container";
import axios, { AxiosError, AxiosInstance, CancelTokenSource } from "axios";

import { getCookieValue } from "@/commons/components/CookieBanner/cookies-utils";

import {
  BackendHttpError,
  BackendHttpErrorData,
  CancelledHttpError,
  UnauthorizedHttpError,
  UnknownHttpError,
  UnreachableServerHttpError,
} from "@/commons/request-handlers/HttpResponseError";
import { EHttpStatusCode } from "@/commons/request-handlers/httpResponse.type";
import { RequestHandlerRequestConfig } from "@/commons/request-handlers/requestHandlerAxios.type";
import { EHttpMethod } from "@/commons/utils/http-method";

import { Injectable } from "@/commons/domain/di/injectable";

import { TYPES } from "@/types";

@Injectable()
export class RequestHandlerAxios {
  private readonly httpSingleton: AxiosInstance;

  private readonly pendingRequests: Map<String, CancelTokenSource>;

  constructor() {
    this.httpSingleton = axios.create({
      withCredentials: true,
      paramsSerializer: RequestHandlerAxios.customParamsSerializer,
    });
    this.pendingRequests = new Map();

    this.setupRequestInterceptors();
    this.setupResponseInterceptors();
  }

  private static customParamsSerializer(params): string | undefined {
    function serializeParameter(key: string, value: unknown) {
      return Array.isArray(value)
        ? `${key}=${value.join(",")}`
        : `${key}=${value}`;
    }

    return (
      Object.entries(params)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([key, value]) => value != undefined)
        .map(([key, value]) => serializeParameter(key, value))
        .join("&")
    );
  }

  private setupRequestInterceptors() {
    // Interceptor for adding/deleting PING OAUTH token on the Authorization header
    this.httpSingleton.interceptors.request.use((requestConfig) => {
      const backendUrl = store.getters["config/frontendConfig"].backendUrl;
      const pingAccessToken = getCookieValue("PING");
      if (
        pingAccessToken != undefined &&
        requestConfig.baseURL === backendUrl
      ) {
        requestConfig.headers["Authorization"] = "Bearer " + pingAccessToken;
      }

      return requestConfig;
    });
  }

  private setupResponseInterceptors() {
    // Interceptor for handling errors happening during an HTTP request
    this.httpSingleton.interceptors.response.use(
      (response) => response,
      (error: AxiosError<BackendHttpErrorData>) => {
        if (error.code === AxiosError.ERR_CANCELED) {
          return Promise.reject(new CancelledHttpError());
        } else if (error.code === AxiosError.ERR_NETWORK) {
          return Promise.reject(new UnreachableServerHttpError(error));
        } else if (error.response?.status === EHttpStatusCode.UNAUTHORIZED) {
          return Promise.reject(new UnauthorizedHttpError(error));
        } else if ((error.response?.data as any)?.status != undefined) {
          // The response data format matches a custom error sent by the backend
          return Promise.reject(
            new BackendHttpError(error.response.data as BackendHttpErrorData),
          );
        } else {
          return Promise.reject(new UnknownHttpError(error));
        }
      },
    );
  }

  setBaseUrl(baseUrl: string) {
    this.httpSingleton.defaults.baseURL = baseUrl;
  }

  requestIsCancelled(thrown: any) {
    return axios.isCancel(thrown);
  }

  buildDefaultCancellationId(httpMethod: EHttpMethod, url: string): string {
    return `${httpMethod}_${url}`;
  }

  cancelRequest(cancellationId: string) {
    if (this.pendingRequests.has(cancellationId)) {
      this.pendingRequests.get(cancellationId).cancel();
    }
  }

  makeRequestCancellable(
    httpMethod: EHttpMethod,
    url: string,
    config: RequestHandlerRequestConfig,
  ): void {
    const cancellationId =
      config.cancellationId || this.buildDefaultCancellationId(httpMethod, url);
    const cancelTokenSource = axios.CancelToken.source();
    this.pendingRequests.set(cancellationId, cancelTokenSource);
    config.cancelToken = cancelTokenSource.token;
  }

  async cancelAndGet<T>(
    url: string,
    config: RequestHandlerRequestConfig = {},
    cancellationId?: string,
  ) {
    this.cancelRequest(
      cancellationId || this.buildDefaultCancellationId(EHttpMethod.GET, url),
    );
    return this.get<T>(url, { ...config, cancellationId });
  }

  async get<T>(url: string, config: RequestHandlerRequestConfig = {}) {
    this.makeRequestCancellable(EHttpMethod.GET, url, config);
    return this.httpSingleton.get<T>(url, config);
  }

  async delete<T>(url: string, config: RequestHandlerRequestConfig = {}) {
    this.makeRequestCancellable(EHttpMethod.DELETE, url, config);
    return this.httpSingleton.delete<T>(url, config);
  }

  async post<T>(
    url: string,
    data?: any,
    config: RequestHandlerRequestConfig = {},
  ) {
    this.makeRequestCancellable(EHttpMethod.POST, url, config);
    return this.httpSingleton.post<T>(url, data, config);
  }

  async cancelAndPost<T>(
    url: string,
    data?: any,
    config: RequestHandlerRequestConfig = {},
    cancellationId?: string,
  ) {
    this.cancelRequest(
      cancellationId || this.buildDefaultCancellationId(EHttpMethod.POST, url),
    );
    return this.post<T>(url, data, { ...config, cancellationId });
  }

  async put<T>(
    url: string,
    data?: any,
    config: RequestHandlerRequestConfig = {},
  ) {
    this.makeRequestCancellable(EHttpMethod.PUT, url, config);
    return this.httpSingleton.put<T>(url, data, config);
  }

  async patch<T>(
    url: string,
    data?: any,
    config: RequestHandlerRequestConfig = {},
  ) {
    this.makeRequestCancellable(EHttpMethod.PATCH, url, config);
    return this.httpSingleton.patch<T>(url, data, config);
  }
}

export function getRequestHandler(): RequestHandlerAxios {
  return Container.instance().get(TYPES.REQUEST_HANDLER);
}
