<template>
  <span :class="['svg-with-label', classes]">
    <slot />
    <slot name="right" />
  </span>
</template>

<script lang="ts">
export default {
  name: "SvgWithLabel",
  props: {
    hasIconLarge: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return this.hasIconLarge ? "svg-with-label--large" : "";
    },
  },
};
</script>

<style lang="scss">
.svg-with-label {
  display: flex;
  align-items: center;
  font-size: var(--svg-with-label-font-size, var(--size-300));

  svg {
    flex-shrink: 0;
    width: var(--svg-with-label-icon-size, 2rem);
    fill: var(--svg-with-label-color, var(--color-gray-dark));
  }

  g {
    stroke: var(--svg-with-label-color, var(--color-gray-dark));
  }
}

.svg-with-label.svg-with-label--large svg {
  width: 4rem;
}
</style>
